import React from "react";

export class HelpPreAgendamento extends React.Component {

	render() {
		return (
			<div>
				<p>
					O pré-agendamento é o mecanismo utilizado pelo Safety para autorizar o acesso de pessoas à uma Unidade em uma data determinada. Ele permite que um responsável pela Unidade crie uma agenda antecipada, bastando apenas inserir o nome e um documento da visita no sistema. Ao se apresentar na portaria,
                    a autorização da visita será alertada na tela, permitindo o acesso sem a necessidade de confirmar novamente.
				</p>
				<p>
					Uma pessoa pode ter uma ou várias agendas (ou até mesmo nenhuma), que garantirão a ela diferentes perfis de acesso, em lugares diferentes e períodos diferentes, conforme definido pelos modelos de acesso
					listados nas agendas que ele possui. Uma forma simples de conferir se uma pessoa possui acesso liberado no sistema é procurar por ela utilizando o campo Pessoa na listagem de agendas.
				</p>
				<h1>Como fazer um pré-agendamento?</h1>
				<ul>
					<li>
						<em>1</em>. Defina uma data específica para a visita, para isso selecione a data no calendário localizado na tela.
					</li>
                    <li>
						<em>2</em>. Selecione uma Unidade a ser visitada. Só o responsável pela Unidade pode selecioná-la.
					</li>
                    <li>
						<em>3</em>. Selecione uma pessoa que irá visitar. Caso não esteja no sistema, será necessário criar o cadastro dela, inserindo o nome completo e pelo menos 1 documento.
					</li>
				</ul>
				<h1>Perguntas frequentes</h1>
				<ol>
					<li>
						<em>Eu fiz o cadastro de uma pessoa, preciso criar um novo cadastro?</em>
						<div>
							Caso ela esteja cadatrada no sistema, basta selecionar o nome dela com o complemento automático de "Pessoas" para fazer a pré-agenda.
						</div>
					</li> 
					<li>
						<em>Quero cancelar um pré-agendamento. Como faço?</em>
						<div>
							Basta encontrar a visita na tabela de liberação, e clicar no ícone da lixeira encontrado na mesma linha.
						</div>
					</li>
				</ol>
			</div>
		);
	}

}

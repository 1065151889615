import {Editor} from "primereact/components/editor/Editor";
import React from "react";
import {Label} from "./Label";

export class RichEditor extends React.Component {

  onChange = (event) => {
    this.props.onChange({name: this.props.name, value: event.htmlValue, ...event});
  }

  render() {
    return (
      <div className={`ui-g-${this.props.grid}`}>
				<Label help={this.props.help}>{this.props.label}</Label>
        <Editor {...this.props} onTextChange={this.onChange} />
      </div>
    );
  }

}

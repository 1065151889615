import React from "react";
import {scorePassword} from "../../utils/passwordUtils";
import "./PasswordScorer.css";

export class PasswordScorer extends React.Component {

    render() {
        const score = scorePassword(this.props.password, this.props.username).score;
        return (
            <div className={`ui-g-${this.props.grid} password-scorer-container`}>
                <div className={`password-scorer score-${score >= 1 ? score : ""}`}/>
                <div className={`password-scorer score-${score >= 2 ? score : ""}`}/>
                <div className={`password-scorer score-${score >= 3 ? score : ""}`}/>
                <div className={`password-scorer score-${score >= 4 ? score : ""}`}/>
                <div className={`password-scorer score-${score >= 5 ? score : ""}`}/>
            </div>
        );
    }

}

import React from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {TextField} from "../commons/TextField";
import {PasswordScorer} from "../commons/PasswordScorer";
import moment from "moment";
import {Shortcut} from "../commons/Shortcut";
import {scorePassword} from "../../utils/passwordUtils";
import {ModalRealm} from "../commons/ModalRealm";
import {UsuarioService} from "../servicos/UsuarioService";
import {SetupSistemaService} from "../servicos/SetupSistemaService";

export class UrgenteTrocaSenha extends React.Component {

    state = {
        visible: true,
        senha1: "",
        senha2: "",
        setupSistema: null
    };

    usuarioService = new UsuarioService();
    setupSistemaService = new SetupSistemaService();

    async componentDidMount() {
        this.setState({setupSistema: await this.setupSistemaService.buscar()});
    }

    handleSave = () => {
        const senha1 = this.state.senha1?.trim();
        const senha2 = this.state.senha2?.trim();
        if (senha1?.length && senha2?.length) {
            if (senha1 === senha2) {
                const score = scorePassword(this.state.senha1).score;
                switch (score) {
                    case 0:
                    case 5:
                        this.handleSalvar();
                        break;
                    case 4:
                        ModalRealm.showConfirmacao("Esta senha não é segura o suficiente. Deseja continuar mesmo assim?", () => {
                            this.handleSalvar();
                        });
                        break;
                    default:
                        if (this.state.setupSistema.scoreMinimoSenha > score) {
                            ModalRealm.showInformacao("warn", ["Senha não atinge os requisitos mínimos de segurança."]);
                        } else {
                            ModalRealm.showConfirmacao("Esta senha não é segura o suficiente. Deseja continuar mesmo assim?", () => {
                                this.handleSalvar();
                            });
                        }
                        break;
                }
            } else {
                ModalRealm.showInformacao("warn", ["As senhas não conferem"]);
            }
        } else {
            ModalRealm.showInformacao("warn", ["Preencha os dois campos corretamente"]);
        }
    }

    handleSalvar = () => {
        this.usuarioService.buscar(this.props.usuario).then(usuario => {
            usuario.senha = this.state.senha1;
            this.usuarioService.salvar(usuario).then(usuario => {
                if (this.props.onModalClose) {
                    this.props.onModalClose(usuario);
                }
                this.setState({visible: false});
                window.location.reload();
            });
        });
    }

    handleClose = () => this.setState({visible: false});

    handleChange = e => this.setState({[e.name]: e.value});

    render() {
        const expiracao = moment(this.props.usuario.ultimaTrocaSenha).add(this.props.usuario.diasTrocaSenha, "days");
        return (
            <Dialog modal header="Atualizar Senha" visible={this.state.visible} closable={false} style={{width: "400px"}} onHide={this.handleClose}>
                <DialogContent>
                    <div className="ui-g-12">
                        Sua senha expirará em {expiracao.format("DD/MM/YYYY HH:mm")}.<br/>Por favor, troque-a o quanto antes:
                    </div>
                    <TextField label="Digite a nova senha" grid={12} required type="password" name="senha1" value={this.state.senha1} onChange={this.handleChange}/>
                    <PasswordScorer username={this.props.usuario.login} password={this.state.senha1} grid={12}/>
                    <TextField label="Repita a mesma senha" grid={12} required type="password" name="senha2" value={this.state.senha2} onChange={this.handleChange}/>
                    <PasswordScorer username={this.props.usuario.login} password={this.state.senha2} grid={12}/>
                </DialogContent>
                <DialogFooter>
                    <Action label="Atualizar Senha" mode="post" icon="fal fa-key" onClick={this.handleSave}/>
                    <Action disabled={expiracao.add(-1, "days").isBefore(moment())} label="Deixar para Depois" mode="secondary" icon="fal fa-times" onClick={this.handleClose}/>
                </DialogFooter>
                <Shortcut onEscape={this.handleClose} onCtrlS={this.handleSave}/>
            </Dialog>
        );
    }

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MMensagem {

	static Modelo() {
		return {
			texto: "",
			tipo: "GERAL"
		};
	}

	static cleanupBeforeSave(mensagem) {
		if (mensagem.foto != null && mensagem.foto.conteudo != null) {
			mensagem.foto.conteudo = mensagem.foto.conteudo.split(",").pop();
		}
		if (!Validator.isEntidade(mensagem.pessoa)) mensagem.pessoa = null;
		if (!Validator.isEntidade(mensagem.etiqueta)) mensagem.etiqueta = null;
		return MEntidade.cleanupBeforeSave(mensagem);
	}

	static validarParaSalvar(mensagem) {
		let messages = [];
		if (Validator.isEmpty(mensagem.texto) && (mensagem.foto == null || (mensagem.foto.path == null && mensagem.foto.conteudo == null))) messages.push("A mensagem deve ter ao menos um texto ou imagem");
		return messages;
	}

}

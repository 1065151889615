import React from "react";
import {EditarModeloAcesso} from "../components/ModeloAcesso/EditarModeloAcesso";
import {ModeloAcessoService} from "../components/servicos/ModeloAcessoService";
import {MModeloAcesso} from "../utils/models/MModeloAcesso";
import {Select} from "./Select";
import {ModalRealm} from "../components/commons/ModalRealm";
import {UserData} from "../utils/UserData";

export class SelectModeloAcesso extends React.Component {

	service = new ModeloAcessoService();

	security = UserData.security("MAC");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={{...MModeloAcesso.Modelo(), descricao: event.value.descricao.replace(`Criar modelo de acesso `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select name={this.props.name || "modeloAcesso"} label={this.props.label || "Modelo de Acesso"} insertable={this.security.create && this.props.insertable} entidade="modelo de acesso" service={this.service} field="descricao" query="descricao" onSelect={this.onSelect} {...this.props} />;
	}

}

import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {TextField} from "../commons/TextField";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {UserData} from "../../utils/UserData";
import {VagaService} from "../servicos/VagaService";
import {SelectLugar} from "../../select/SelectLugar";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {SuggestionBox} from "../commons/SuggestionBox";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import iconeRemover from "../../media/icones/remover.png";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {PanelContent} from "../commons/PanelContent";
import moment from "moment";
import {LogVagaService} from "../servicos/LogVagaService";
import {ModalRealm} from "../commons/ModalRealm";
import {SelecionarPessoas} from "../PessoaFisica/SelecionarPessoas";
import {byNome} from "../../utils/SortUtils";
import {Button} from "primereact/components/button/Button";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";

export class EditarVaga extends Component {

	state = {
		vaga: this.props.vaga,
		visible: true,
		tabIndex: 0
	};

	vagaService = new VagaService();
	logVagaService = new LogVagaService();
	pessoaService = new PessoaFisicaService();
	security = UserData.security("UNI", true);

	tabChange = event => {
		this.setState({tabIndex: event.index}, () => {
			if (event.index === 1 && this.state.vaga.id) {
				this.logVagaService.listar(`vaga=id:${this.state.vaga.id}&sort=entrada+desc`, {page: 0, size: 50}).then(logsVaga => this.setState({logsVaga}));
			}
		});
	}

	salvarVaga = () => {
		let messages = [];

		if (!this.state.vaga.descricao) {
			messages.push("A descrição é obrigatória");
		}

		if (!this.state.vaga.lugar) {
			messages.push("O lugar é obrigatório");
		}

		if (messages) {
			ModalRealm.showInformacao("warning", messages);
			return;
		}

		const {vaga} = this.state;
		this.vagaService.salvar(vaga).then(vaga => {
			if (this.props.onModalClose) {
				this.props.onModalClose(vaga);
			}
			this.onClose();
		});
	}

	handleChange = event => {
		const {vaga} = this.state;
		vaga[event.name] = event.value;
		this.setState({vaga});
	}

	onClose = () => this.setState({visible: false});

	listarTitulares = event => {
		this.pessoaService.listar(`search=nome,rg,cpf,nis,passaporte~${event.query}`, {page: 0, size: 10}).then(pessoas => this.setState({pessoas}));
	}

	adicionarTitular = event => {
		if (event.value?.id) {
			const {vaga} = this.state;
			vaga.titulares.push(event.value);
			this.setState({vaga, pessoa: ""});
		}
	}

	removerTitular = titular => {
		const {vaga} = this.state;
		vaga.titulares = vaga.titulares.filter(t => t.id !== titular.id);
		this.setState({vaga});
	}

	selecionarPessoas = () => {
		ModalRealm.showDialog(<SelecionarPessoas except={this.state.vaga.titulares} onModalClose={pessoas => {
			const {vaga} = this.state;
			vaga.titulares = vaga.titulares.concat(pessoas).sort(byNome);
			this.setState({vaga});
		}} />);
	}

	render() {
		return (
			<Dialog modal key={this.props.key} header="Vaga" visible={this.state.visible} style={{width: "800px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView activeIndex={this.state.tabIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField grid={6} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.vaga.descricao}/>
								<SelectLugar grid={6} label="Lugar" required name="lugar" onChange={this.handleChange} value={this.state.vaga.lugar}/>
								<SuggestionBox placeholder="Pesquise por nome ou qualquer documento"
								               style={{position: "relative"}}
								               appendTo={document.body}
								               label="Titulares"
								               grid={12}
								               field="nome"
								               completeMethod={this.listarTitulares}
								               suggestions={this.state.pessoas}
								               onChange={(e) => this.setState({pessoa: e.value})}
								               onSelect={this.adicionarTitular}
								               value={this.state.pessoa}/>
								<div className="ui-g-12">
									<DataTable rows={10} paginator paginatorLeft={(
										<div style={{textAlign: "left"}}>
											<Button title="Busca Avançada" className="ui-tzm-pagleft-icon" icon="fa-search" onClick={this.selecionarPessoas}/>
										</div>
									)} emptyMessage="Nenhum titular adicionado" value={this.state.vaga.titulares}>
										<Column header="Nome" field="nome"/>
										<Column style={{width: "10em"}} header="Documento" body={t => t.rg || t.cpf || t.nis || t.passaporte}/>
										<Column style={{width: "10em"}} header="Telefone" body={t => t.telefone || t.celular}/>
										<Column style={{width: "4em", textAlign: "center"}} header="Ações" body={t => (
											<div>
												{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Titular" onClick={() => this.removerTitular(t)} /> : null}
											</div>
										)}/>
									</DataTable>
								</div>
								<MultiSelectEtiqueta grid={12} name="etiquetas" label="Etiquetas" value={this.state.vaga.etiquetas} onSelect={this.handleChange}/>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Histórico da Vaga">
							<PanelContent>
								<div className="ui-g-12">
									<DataTable rows={10} paginator emptyMessage="Nenhum registro encontrado" value={this.state.logsVaga}>
										<Column style={{width: "9em"}} header="Status" body={lv => lv.status}/>
										<Column header="Ocupante" body={lv => lv.pessoa.nome}/>
										<Column style={{width: "8em", textAlign: "center"}} header="Entrada" body={lv => moment(lv.entrada).format("DD/MM/YYYY HH:mm:ss")}/>
										<Column style={{width: "8em", textAlign: "center"}} header="Saída" body={lv => lv.saida ? moment(lv.saida).format("DD/MM/YYYY HH:mm:ss") : ""}/>
									</DataTable>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.vaga.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/vagas", this.state.vaga)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.vaga.id)) || this.state.lockSave} onClick={this.salvarVaga} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarVaga} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React, {Component} from "react";
import {UserData} from "../../utils/UserData";
import {handleLogout} from "../../Application";
import {secondsToString} from "../../utils/StringUtils";
import moment from "moment";

export class TimeoutObserver extends Component {

	state = {
		counter: 0,
		disconnected: false
	};

	componentDidMount() {
		this.countInterval = setInterval(() => {
			if (!this.state.disconnected && UserData.usuario && UserData.usuario.timeout > 0) {
				let {counter} = this.state;
				++counter;
				if (counter / 60 > UserData.usuario.timeout) {
					console.log("User disconnected due to inactivity at " + moment().format("HH:mm:ss"));
					handleLogout();
					clearInterval(this.countInterval);
					this.setState({counter: 0, disconnected: true});
				} else {
					this.setState({counter});
				}
			}
		}, 1000);
		document.addEventListener("mousemove", this.zerarContador);
		document.addEventListener("mousedown", this.zerarContador);
		document.addEventListener("keydown", this.zerarContador);
		document.addEventListener("focus", this.refreshOnFocus);
	}

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.zerarContador);
		document.removeEventListener("mousedown", this.zerarContador);
		document.removeEventListener("keydown", this.zerarContador);
		document.removeEventListener("focus", this.refreshOnFocus);
		clearInterval(this.countInterval);
	}

	zerarContador = () => {
		if (!this.state.disconnected && UserData.usuario && UserData.usuario.timeout > 0 && this.state.counter) {
			this.setState({counter: 0});
		}
	}

	refreshOnFocus = () => {
		if (this.state.disconnected) {
			window.location.reload();
		}
	}

	render() {
		const remaining = UserData.usuario.timeout * 60 - this.state.counter;
		return UserData.usuario && UserData.usuario.timeout > 0 && remaining < 180 ? <div title="Tempo até que o usuário seja automaticamente desconectado por inatividade" className="user-timeout">{secondsToString(remaining)}</div> : null;
	}

}

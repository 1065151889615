import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Tree} from 'primereact/components/tree/Tree';
import React from "react";
import {Validator} from "../../utils/Validator";
import {HistoricoService} from "../servicos/HistoricoService";
import {Action} from "./Action";
import {DialogContent} from "./DialogContent";
import {DialogFooter} from "./DialogFooter";

export class Historico extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true,
			history: [],
			revisions: [],
			rev: null,
			audit: null,
			index: 0
		};
		this.historicoService = new HistoricoService();
	}

	componentDidMount() {
		this.historicoService.versoes(this.props.path, this.props.entidade).then((history) => {
			history = history.map(audit => this.mountEntityAudit(audit));
			let audit = history.length > 0 ? history[0] : null;
			this.setState({history, audit});
		});
		this.historicoService.revisoes(this.props.path, this.props.entidade).then((revisions) => {
			let rev = revisions.length > 0 ? revisions[0] : null;
			this.setState({revisions, rev});
		});
	}

	ignoreFields = [
		"id",
		"registro",
		"versao",
		"chaveIntegracao"
	];

	static acronyms = [
		"rg", "cpf", "nis", "cnh", "ip", "uf", "cnpj", "cei", "ie", "cep"
	];

	mountEntityAudit(entity) {
		return Object.keys(entity).filter(k => !this.ignoreFields.includes(k)).sort((a, b) => a.localeCompare(b)).map(key => {
			if (entity[key] != null && entity[key].id != null) {
				return {
					label:  this.entityLabel(entity[key], key, 0),
					data: key,
					expandedIcon: "fa-folder-open",
					collapsedIcon: "fa-folder",
					children: this.mountEntityAudit(entity[key])
				};
			} else if (Array.isArray(entity[key])) {
				return {
					label: this.keyAsLabel(key),
					data: key,
					expandedIcon: "fa-folder-open",
					collapsedIcon: "fa-folder",
					children: entity[key].sort((a, b) => a.id != null && b.id != null ? a.id - b.id : 0).map((item, index) => {
						if (item != null && item.id != null) {
							return {
								label: this.entityLabel(item, key, index),
								data: key,
								expandedIcon: "fa-folder-open",
								collapsedIcon: "fa-folder",
								children: this.mountEntityAudit(item)
							};
						} else {
							return {
								label: this.formatValue(item),
								data: key,
								collapsedIcon: this.iconFor(item)
							};
						}
					})
				};
			} else {
				return {
					label: `${this.keyAsLabel(key)}: ${this.formatValue(entity[key])}`,
					data: entity[key],
					collapsedIcon: this.iconFor(entity[key])
				};
			}
		});
	}

	entityLabel(entity, key, index) {
		return `${this.keyAsLabel(key)}: ${entity.descricao || entity.nome || index + 1}`;
	}

	iconFor(value) {
		if (value == null) return "fa-ellipsis-h";
		if (value === true) return "fa-toggle-on";
		if (value === false) return "fa-toggle-off";
		if (Validator.isTimestamp(value)) return "fa-calendar-alt";
		if (Validator.isDate(value)) return "fa-calendar-alt";
		if (Validator.isTime(value)) return "fa-clock";
		if (!isNaN(value)) return "fa-asterisk";
		return "fa-font";
	}

	formatValue(value) {
		if (value == null) return "<vazio>";
		if (value === true) return "Sim";
		if (value === false) return "Não";
		if (Validator.isTimestamp(value)) return moment(value).format("DD/MM/YYYY HH:mm");
		if (Validator.isDate(value)) return moment(value).format("DD/MM/YYYY");
		if (Validator.isTime(value)) return value.formatTime();
		return value;
	}

	keyAsLabel(key) {
		return key.split(/(?=[A-Z])/g).map(function(s) {
			return Historico.acronyms.includes(s) ? s.toUpperCase() : s.toCapitalCase();
		}).join(" ");
	}

	onClose = () => {
		this.setState({visible: false});
	}

	incIndex = (value) => {
		let currentIndex = value !== undefined ? value : this.state.index + 1;
		this.setState({audit: this.state.history[currentIndex], rev: this.state.revisions[currentIndex], index: currentIndex});
	}

	decIndex = (value) => {
		let currentIndex = value !== undefined ? value : this.state.index - 1;
		this.setState({audit: this.state.history[currentIndex], rev: this.state.revisions[currentIndex], index: currentIndex});
	}

	render() {
		return (
			<Dialog key={Math.random()} header="Histórico de Alterações" modal visible={this.state.visible} onHide={this.onClose} style={{width: "900px"}} {...this.props}>
				<DialogContent>
					<div className="ui-g">
						<div className="ui-g-12">
							{this.state.rev != null ? <div><i className="fa fa-info-circle" /> Revisão feita por <b>{this.state.rev.usuario || "sistema"}</b>{` às ${moment(this.state.rev.revisionDate).format("DD/MM/YYYY HH:mm:ss")}`}</div> : null}
						</div>
						<div className="ui-g-12">
							<Tree style={{overflowY: "auto", maxHeight: "500px"}} value={this.state.audit} />
						</div>
						<div className="ui-g-2" />
						<div style={{textAlign: "center"}} className="ui-g-8">
							<Button className="ui-button-secondary" disabled={this.state.index <= 0} icon="fa-fast-backward" onClick={() => this.decIndex(0)} />
							<Button className="ui-button-secondary" disabled={this.state.index <= 0} icon="fa-step-backward" onClick={() => this.decIndex()} />
							<Button className="ui-button-secondary" disabled={this.state.index > this.state.history.length - 2} icon="fa-step-forward" onClick={() => this.incIndex()} />
							<Button className="ui-button-secondary" disabled={this.state.index > this.state.history.length - 2} icon="fa-fast-forward" onClick={() => this.incIndex(this.state.revisions.length - 1)} />
						</div>
						<div className="ui-g-2" style={{textAlign: "right"}}>{`${this.state.index + 1} de ${this.state.revisions.length}`}</div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa fa-times" onClick={this.onClose} />
				</DialogFooter>
			</Dialog>
		);
	}

}

import React from "react";

export class HelpModeloAcesso extends React.Component {

	render() {
		return (
			<div>
				<p>
					O modelo de acesso é um conjunto de regras que será usado pelas agendas para determinar se a pessoa tem ou não acesso ao lugar num determinado horário.
					Nele é possível criar uma tabela de horários com projeção semanal, e listar os lugares aos quais será possível ter acesso.
					Em resumo, o modelo de acesso define <em>quando</em> e <em>onde</em> as pessoas das agendas onde ele está poderão passar pelos equipamentos de acesso.
				</p>
				<p>
					Quando um modelo de acesso é alterado, é importante ressaltar que essa alteração afetará todas as agendas pré-existentes onde ele estiver vinculado.
					Por isso é preciso ter certo cuidado quando alterar um modelo de acesso.
				</p>
				<h1>Como preencher os campos?</h1>
				<h2>Dados Principais</h2>
				<ul>
					<li><em>Descrição</em>: texto único que será usado para identificar o modelo de acesso noutras telas do sistema.</li>
					<li><em>Lugares</em>: a lista com os lugares aos quais este modelo de acesso dará permissão, com base nos horários definidos.</li>
				</ul>
				<h2>Horários</h2>
				<ul>
					<li>
						<em>Períodos</em>:
						define os intervalos (início e fim) e os dias da semana em que o modelo de acesso permitirá que as pessoas entrem nos lugares relacionados.
						É possível também dizer se esses horários serão válidos ou não em feriados cadastrados.
					</li>
				</ul>
				<h1>Perguntas frequentes</h1>
				<ol>
					<li>
						<em>Como eu cadastro um modelo de acesso com permissão para todos os lugares em qualquer horário?</em>
						<div>
							Para isso, basta criar um modelo de acesso, adicionar todos os lugares cadastrados na lista de lugares e criar um único período, das 00:00 às 23:59, com a opção feriados marcada.
							Este modelo, quando colocado em uma agenda, permitirá acesso em tempo integral a todos os lugares.
						</div>
					</li>
					<li>
						<em>
							Já existe um modelo de acesso com a tabela de horários igual à que eu preciso, mas com lugares diferentes. Preciso cadastrar outro modelo de acesso para permitir
							que um grupo diferente de pessoas acesse outros lugares?
						</em>
						<div>
							Neste caso, sim. Se dois grupos distintos de pessoas tem acesso a lugares diferentes, é necessário criar modelos de acesso diferentes para eles.
						</div>
					</li>
				</ol>
			</div>
		);
	}

}

import React, {Component} from "react";
import {AgendaService} from "../servicos/AgendaService";
import {httpDelete, httpGet} from "../../utils/Request";
import {EditarAutorizacaoAcesso} from "./EditarAutorizacaoAcesso";
import {ModalRealm} from "../commons/ModalRealm";
import {MAgenda} from "../../utils/models/MAgenda";
import moment from "moment";
import {Panel} from "primereact/components/panel/Panel";
import {PanelContent} from "../commons/PanelContent";
import {DataTable} from "primereact/components/datatable/DataTable";
import {GUI} from "../../utils/Constants";
import {Shortcut} from "../commons/Shortcut";
import {ActionFloat} from "../commons/ActionFloat";
import {Column} from "primereact/components/column/Column";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import {UserData} from "../../utils/UserData";
import {DatePicker} from "../commons/DatePicker";
import {EditarAgenda} from "../Agenda/EditarAgenda";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";

export class AutorizacaoAcesso extends Component {

	state = {
		firstResult: 0,
		agendas: [],
		status: "ATIVADO",
		data: moment().format("YYYY-MM-DD")
	};

	security = UserData.security("AAC", true);
	securityPessoa = UserData.security("PES");
	securityCredencial = UserData.security("CRD");

	agendaService = new AgendaService();

	componentDidMount() {
		this.listarAgendas();
	}

	listarAgendas = () => {
		const query = [];
		query.push(`gestor=${UserData.operador?.pessoa?.id}`);
		if (this.state.status?.length) query.push(`status=${this.state.status}`);
		if (this.state.data?.length) query.push(`inicio=${moment(this.state.data).format("YYYY-MM-DD")}`);
		if (this.state.data?.length) query.push(`fim=${moment(this.state.data).format("YYYY-MM-DD")}`);
		httpGet(`/agendas/gestor?${query.join("&")}`).then(agendas => this.setState({agendas}));
	}

	editarAutorizacaoAcesso = agenda => {
		ModalRealm.showDialog(<EditarAgenda gestor={UserData.operador?.pessoa} agenda={agenda} onModalClose={this.listarAgendas}/>);
	}

	excluirAutorizacaoAcesso = agenda => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta autorização de acesso?", () => {
			const chave = agenda.chaveIntegracao?.split(";")[1];
			if (chave?.length) {
				httpGet(`/agendas?search=status:ATIVADO;chaveIntegracao~${chave}&page=0&size=100`).then(agendas => {
					if (agendas?.length > 1) {
						ModalRealm.showConfirmacao(`Esta autorização de acesso foi criada em um grupo com outras ${agendas.length - 1}. Deseja excluir todas ou somente essa?`, async () => {
							for (const agenda of agendas) {
								await httpDelete(`/agendas/${agenda.id}`);
							}
							this.listarAgendas();
						}, () => {
							this.agendaService.excluir(agenda).then(this.listarAgendas);
						}, {yes: "Todas", no: "Somente Esta"});
					} else {
						this.agendaService.excluir(agenda).then(this.listarAgendas);
					}
				});
			} else {
				this.agendaService.excluir(agenda).then(this.listarAgendas);
			}
		});
	}

	visualizarHistorico = agenda => {
		EntidadeNextGenService.listarHistorico("/agendas", agenda);
	}

	restaurarAutorizacaoAcesso = agenda => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta agenda?", () => {
			if (agenda != null && agenda.id != null) {
				this.agendaService.buscar(agenda).then((agenda) => {
					agenda.status = "ATIVADO";
					this.agendaService.salvar(agenda).then(this.listarAgendas);
				});
			}
		});
	}

	eliminarAutorizacaoAcesso = agenda => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta agenda?", () => this.agendaService.eliminar(agenda).then((result) => {
			if (result)
				this.listarAgendas();
			else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta agenda: há outros registros dependentes dela no sistema."]);
		}));
	}

	criarAutorizacaoAcesso = () => {
		const agenda = {
			...MAgenda.Modelo(),
			validade: this.state.data
		};
		ModalRealm.showDialog(<EditarAutorizacaoAcesso key={Math.random()} agenda={agenda} onModalClose={this.listarAgendas}/>);
	}

	handleChange = event => {
		this.setState({[event.name]: event.value}, this.listarAgendas);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<Panel header="Autorizações de Acesso">
				<PanelContent>
					<DatePicker minDate={moment().toDate()} inline name="data" grid={3} value={this.state.data} onChange={this.handleChange}/>
					<div className="ui-g-9">
						<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.agendas} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage}>
							<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id"/>
							<Column style={{width: "4em"}} key="status" header="Status" field="status" body={MAgenda.SwitchStatus}/>
							<Column style={{width: "7em", textAlign: "center"}} key="inicio" header="Início" field="inicio" body={a => a.inicio ? moment(a.inicio).format("DD/MM/YYYY HH:mm") : ""}/>
							<Column style={{width: "7em", textAlign: "center"}} key="fim" header="Fim" field="validade" body={a => a.validade ? moment(a.validade).format("DD/MM/YYYY HH:mm") : ""}/>
							<Column style={{width: "*"}} key="pessoas" header="Pessoas" body={agenda => (
								agenda.pessoas.map(p => <div key={p.id}>{p.nome}</div>)
							)}/>
							<Column style={{width: "7em"}} key="Ações" header="Ações" field="acoes" body={agenda => {
								return (
									<div style={{textAlign: "center"}}>
										{this.security.edit && agenda.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Autorização de Acesso" onClick={() => this.editarAutorizacaoAcesso(agenda)}/> : null}
										{this.security.remove && agenda.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Encerrar Autorização de Acesso" onClick={() => this.excluirAutorizacaoAcesso(agenda)}/> : null}
										{this.security.remove && agenda.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(agenda)}/> : null}
										{this.security.remove && agenda.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Autorização de Acesso" onClick={() => this.restaurarAutorizacaoAcesso(agenda)}/> : null}
										{this.security.remove && agenda.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Autorização de Acesso" onClick={() => this.eliminarAutorizacaoAcesso(agenda)}/> : null}
									</div>
								);
							}}/>
						</DataTable>
					</div>
				</PanelContent>
				<Shortcut
					onEnter={this.listarAgendas}
					onCtrlN={() => window.location.pathname === "/autorizacoes-acesso" && this.security.create && this.criarAutorizacaoAcesso()}
					onCtrlE={() => window.location.pathname === "/autorizacoes-acesso" && this.security.edit && this.state.agendas.length === 1 && this.editarAutorizacaoAcesso(this.state.agendas[0])}
				/>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button title="Nova Autorização de Acesso" mode="post" icon="fa-plus" onClick={this.criarAutorizacaoAcesso}/> : null}
				</ActionFloat>
				<ModalRealm/>
			</Panel>
		);
	}

}

import {MEntidade} from "./MEntidade";
import {Validator} from "../Validator";

export class MAnimal extends MEntidade {

	static Modelo() {
		return {
			nome: "",
            especie: "CACHORRO",
            etiquetas: []
		};
	}

	static Especies = [
        {label: "Outro", value: "OUTRO"},
        {label: "Cachorro", value: "CACHORRO"},
        {label: "Gato", value: "GATO"},
    ];
    
    static MapEspecies = {
        "OUTRO": "Outro",
        "CACHORRO": "Cachorro",
        "GATO": "Gato"
    };

	static cleanupBeforeSave(animal) {
		return MEntidade.cleanupBeforeSave(animal);
	}

	static validarParaSalvar(animal) {
		let messages = [];
		if (Validator.isEmpty(animal.nome)) messages.push("O nome é obrigatório");
        if (Validator.isEmpty(animal.especie)) messages.push("A espécie é obrigatória");
        if (!Validator.isEntidade(animal.responsavel)) messages.push("O responsável é obrigatório");
		return messages;
	}

}

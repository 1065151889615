import React, {Component} from "react";
import {httpGet} from "../utils/Request";
import {Combobox} from "../components/commons/Combobox";

export class ComboboxModeloAcessoAutorizacao extends Component {

    state = {
        modelosAcesso: []
    };

    componentDidMount() {
        httpGet("/modelos-acesso/autorizacao").then(modelosAcesso => {
            this.setState({modelosAcesso: modelosAcesso.map(ma => ({label: ma.descricao, value: ma}))});
            if (this.props.onLoaded) {
                this.props.onLoaded(modelosAcesso);
            }
        });
    }

    render() {
        return (
            <Combobox {...this.props} options={this.state.modelosAcesso}/>
        );
    }

}

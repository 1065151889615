const basePrintUrl = process.env.REACT_APP_ZEBRA_PRINT_SERVER || "";

export const impressaoEtiquetaService = {

	async buscarImpressoras() {
		return await fetch(basePrintUrl + "/impressoras").then(async res => await res.json()).catch(() => []);
	},

	async imprimirEtiqueta(impressao) {
		return await fetch(basePrintUrl + "/imprimir", {method: "POST", body: JSON.stringify(impressao), headers: {"Content-Type": "application/json"}});
	}

};

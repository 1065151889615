import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {SelectEtiqueta} from "../../select/SelectEtiqueta";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import "../../utils/Format";
import {MAviso} from "../../utils/models/MAviso";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ColorPanel} from "../commons/ColorPanel";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {AvisoService} from "../servicos/AvisoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MultiCombobox} from "../commons/MultiCombobox";
import {IconPanel} from "../commons/IconPanel";

export class EditarAviso extends React.Component {

	state = {
		visible: true,
		aviso: Object.assign(MAviso.Modelo(), this.props.aviso),
		activeIndex: 0,
		lockSave: false
	};

	service = new AvisoService();
	security = UserData.security("AVI", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarAviso = () => {
		if (this.security.edit) {
			let aviso = MAviso.cleanupBeforeSave(Object.assign({}, this.state.aviso));
			let messages = MAviso.validarParaSalvar(aviso);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (!aviso.pessoa.id) {
					if (this.props.onSave) {
						this.props.onSave(aviso);
					}
					this.onClose();
				} else {
					this.setState({lockSave: true});
					this.service.salvar(aviso).then((aviso) => {
						this.setState({lockSave: false});
						if (this.props.onSave) {
							this.props.onSave(aviso);
						}
						this.onClose();
					}).catch(() => this.setState({lockSave: false}));
				}
			}
		}
	}

	handleChange = (event) => {
		let aviso = this.state.aviso;
		aviso[event.name] = event.value;
		this.setState({aviso});
	}

	render() {
		return (
			<Dialog modal header="Aviso" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox grid={3} label="Status" appendTo={document.body} name="status" onChange={this.handleChange} value={this.state.aviso.status} options={MAviso.Status} />
					<Combobox grid={3} label="Tipo" appendTo={document.body} name="tipo" onChange={this.handleChange} value={this.state.aviso.tipo} options={MAviso.Tipos} />
					<DatePicker appendTo={document.body} showTime showSeconds grid={3} label="Período" name="inicio" onChange={this.handleChange} value={this.state.aviso.inicio} />
					<DatePicker appendTo={document.body} showTime showSeconds grid={3} label="&nbsp;" name="fim" onChange={this.handleChange} value={this.state.aviso.fim} />
					<SelectPessoaFisica disabled={this.props.locked} grid={6} required label="Pessoa" name="pessoa" onChange={this.handleChange} value={this.state.aviso.pessoa} />
					<SelectEtiqueta grid={6} label="Unidade" name="etiqueta" onChange={this.handleChange} value={this.state.aviso.etiqueta} />
					{this.state.aviso.tipo === "LIBERACAO" ? <MultiCombobox options={MAviso.Flags} grid={12} label="Opções" value={this.state.aviso.flags} onChange={this.handleChange} name="flags"/> : null}
					<ColorPanel grid={12} label="Cor do Texto" name="fonte" value={this.state.aviso.fonte} onChange={this.handleChange} />
					<ColorPanel grid={12} label="Cor do Aviso" name="fundo" value={this.state.aviso.fundo} onChange={this.handleChange} />
					<TextField grid={12} required label="Texto" value={this.state.aviso.texto} onChange={this.handleChange} name="texto" multiline style={{height: "6em"}} />
					<IconPanel grid={12} label="Ícone" value={this.state.aviso.icone} onChange={this.handleChange} name="icone"/>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.aviso.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/avisos", this.state.aviso)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.aviso.id)) || this.state.lockSave} onClick={this.salvarAviso} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarAviso} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

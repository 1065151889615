import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MSetupPortaria {

	static Modelo() {
		return {
			descricao: "",
			modelosAgenda: [],
			destinos: []
		};
	}

	static cleanupBeforeSave(setupPortaria) {
		if (setupPortaria.etiquetas != null) setupPortaria.etiquetas = setupPortaria.etiquetas.filter(e => Validator.isEntidade(e));
		if (setupPortaria.setores != null) setupPortaria.setores = setupPortaria.setores.filter(e => Validator.isEntidade(e));
		return MEntidade.cleanupBeforeSave(setupPortaria);
	}

	static validarParaSalvar(setupPortaria) {
		let messages = [];
		if (Validator.isEmpty(setupPortaria.descricao)) messages.push("A descrição é obrigatória");
		if (setupPortaria.modelosAgenda == null || !setupPortaria.modelosAgenda.length) messages.push("Deve haver ao menos um modelo de agenda para a portaria");
		if (setupPortaria.etiquetas == null || setupPortaria.etiquetas.length === 0) messages.push("Deve haver ao menos uma etiqueta para a portaria");
		return messages;
	}

}

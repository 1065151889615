import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {DataView} from "primereact/components/dataview/DataView";
import React from "react";
import iconeDesvincular from "../../media/icones/desvincular.png";
import {MAgenda} from "../../utils/models/MAgenda";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {MVeiculo} from "../../utils/models/MVeiculo";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelFooter} from "../commons/PanelFooter";
import {TextField} from "../commons/TextField";
import {AgendaService} from "../servicos/AgendaService";
import {AvisoService} from "../servicos/AvisoService";
import {BloqueioService} from "../servicos/BloqueioService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {PortariaService} from "../servicos/PortariaService";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {SetupPortariaService} from "../servicos/SetupPortariaService";
import {UnidadeService} from "../servicos/UnidadeService";
import {VeiculoService} from "../servicos/VeiculoService";
import "./TabletAtivo.css";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";
import {ComboboxFabricante} from "../Veiculo/ComboboxFabricante";
import {httpGet, httpPost} from "../../utils/Request";
import {MCredencial} from "../../utils/models/MCredencial";
import {WebCamera} from "../commons/Webcam";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectLocalidade} from "../../select/SelectLocalidade";
import {Action} from "../commons/Action";
import {EnderecoService} from "../servicos/EnderecoService";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {handleLogout} from "../../Application";

export class TabletAtivo extends React.Component {

	equipamentoService = new EquipamentoService();
	setupPortariaService = new SetupPortariaService();
	unidadeService = new UnidadeService();
	pessoaFisicaService = new PessoaFisicaService();
	setupCondominioService = new SetupCondominioService();
	veiculoService = new VeiculoService();
	portariaService = new PortariaService();
	agendaService = new AgendaService();
	avisoService = new AvisoService();
	bloqueioService = new BloqueioService();
	securityAviso = UserData.security("AVI");

	state = {
		step: 0,
		portarias: [],
		pessoas: [],
		categorias: [],
		modelosAgenda: [],
		unidades: [],
		ids: [],
		passageiros: [],
		blocos: {},
		query: "",
		placa: "",
		nomesBlocos: [],
		equipamentosFaciais: []
	};

	componentDidMount() {
		this.equipamentoService.me().then((equipamento) => {
			this.setupPortariaService.listar(`search=descricao~`, {page: 0, size: 10, sort: "descricao"}).then(portarias => {
				if (portarias?.length) {
					const etiquetasOperador = UserData.operador.etiquetas;
					if (etiquetasOperador?.length && etiquetasOperador.some(e => e.flags.includes("PORTARIA"))) {
						const ids = etiquetasOperador.filter(e => e.flags.includes("PORTARIA")).map(e => e.id);
						portarias = portarias.filter(p => p.etiquetas?.some(e => ids.includes(e.id)));
					}
					this.unidadeService.listar(`search=status:ATIVADO`, {page: 0, size: 10000, sort: "bloco, length:descricao, descricao"}).then((unidades) => {
						this.setupCondominioService.buscar().then(async (condominio) => {
							const categorias = condominio.categoriasGuarita.map(c => ({label: c.descricao, value: c}));
							const modelosAgenda = condominio.modelosAgenda.map(ma => ({label: ma.descricao, value: ma}));
							const blocos = {}, nomesBlocos = [];
							unidades.forEach(u => {
								if (blocos[u.bloco]) {
									blocos[u.bloco].push(u);
								} else {
									nomesBlocos.push(u.bloco);
									blocos[u.bloco] = [u];
								}
							});
							let catid = Number(window.localStorage.getItem("safety-categoria-last") || 0);
							const portaria = portarias?.length ? portarias[0] : null;
							if (!equipamento?.id && portaria.equipamentoPadrao) {
								equipamento = portaria.equipamentoPadrao;
							}
							const categoria = categorias.filter(c => c.value.id === catid).map(c => c.value)[0] || null;
							const destino = equipamento.dispositivos.filter(d => d.destino).map(d => d.destino)[0] || null;
							let equipamentosFaciais = [];
							if (condominio?.categoriasFacialPortaria) {
								const etiquetas = condominio.categoriasFacialPortaria.map(e => e.id);
								equipamentosFaciais = await httpGet("/equipamentos?search=familia:INTELBRAS_FRT,HIKVISION_FRT,CONTROLID_FACE;status:ATIVADO&page=0&size=100").then(equipamentosFaciais => {
									return equipamentosFaciais.filter(ef => ef.etiquetas?.some(e => etiquetas.includes(e.id)));
								});
							}
							this.setState({
								condominio,
								nomesBlocos,
								destino,
								categorias,
								categoria,
								unidades,
								blocos,
								modelosAgenda,
								equipamento,
								equipamentosFaciais,
								portarias: portarias.map((portaria) => ({label: portaria.descricao, value: portaria})),
								portaria
							}, () => {
								if (categoria) {
									this.swapModeloAgenda(categoria.descricao);
								}
							});
						});
					});
				}
			});
		});
	}

	templateUnidade = (unidade) => {
		return (
			<div key={unidade.id} onClick={() => this.selecionarUnidade(unidade)} style={unidade._selecionada ? {} : {color: unidade.etiqueta.fonte, backgroundColor: unidade.etiqueta.fundo}} className={`ui-pattern-${unidade.etiqueta.padrao} tablet-ativo-unidade ${unidade._selecionada ? "selecionada" : ""}`}>
				<span>{unidade.etiqueta.descricao}</span>
			</div>
		);
	}

	templateBloco = (descricao, unidades) => {
		return (
			<div key={descricao} className="tablet-ativo-bloco">
				<div className="tablet-ativo-bloco-descricao">{descricao}</div>
				{unidades.map(this.templateUnidade)}
			</div>
		);
	}

	lancarPassageiros = () => {
		this.setState({pessoas: this.state.passageiros, step: 1, ids: this.state.passageiros.map(p => p.id)});
	}

	templateUnidadesSelecionadas = (unidade) => {
		let contato = "";
		if (unidade.contato) {
			contato = ` - ${unidade.contato.nome}`;
			let telefones = [];
			if (unidade.contato.telefone) telefones.push(unidade.contato.telefone);
			if (unidade.contato.celular) telefones.push(unidade.contato.celular);
			if (telefones.length) {
				contato = <>{contato} {telefones.map(t => <span><i className="fal fa-phone"/> {t}</span>)}</>;
			}
		}
		return (
			<div className={`tablet-unidade ui-pattern-${unidade.etiqueta.padrao}`} style={{backgroundColor: unidade.etiqueta.fundo, color: unidade.etiqueta.fonte}}>
				{unidade.etiqueta.descricao}
				{contato}
			</div>
		);
	}

	templateVeiculo = () => {
		if (this.state.veiculo) {
			if (this.state.veiculo.id) {
				return (
					<div className="tablet-ativo-veiculo">
						<div className="ui-g">
							<TextField grid={4} label="Placa" name="placa" onChange={this.handleVeiculoChange} value={this.state.veiculo.placa} disabled/>
							<TextField grid={8} label="Modelo" name="modelo" onChange={this.handleVeiculoChange} value={this.state.veiculo.modelo} disabled/>
							<ComboboxFabricante grid={6} label="Fabricante" name="fabricante" onChange={this.handleVeiculoChange} value={this.state.veiculo.fabricante} disabled/>
							<Combobox grid={6} label="Cor" options={MVeiculo.Cores} name="cor" onChange={this.handleVeiculoChange} value={this.state.veiculo.cor} disabled/>
						</div>
						{this.state.passageiros.length ? <PanelFooter style={{textAlign: "center"}}><Button icon="fa fa-arrow-right" className="ui-button-success ui-tzm-button" label="Lançar Saída" onClick={this.lancarPassageiros}/></PanelFooter> : null}
					</div>
				);
			} else {
				return (
					<div className="tablet-ativo-veiculo">
						<div className="ui-g">
							<TextField grid={4} label="Placa" name="placa" onChange={this.handleVeiculoChange} value={this.state.veiculo.placa} disabled/>
							<TextField grid={8} label="Modelo" name="modelo" onChange={this.handleVeiculoChange} value={this.state.veiculo.modelo}/>
							<ComboboxFabricante grid={6} label="Fabricante" name="fabricante" onChange={this.handleVeiculoChange} value={this.state.veiculo.fabricante}/>
							<Combobox grid={6} label="Cor" options={MVeiculo.Cores} name="cor" onChange={this.handleVeiculoChange} value={this.state.veiculo.cor}/>
						</div>
					</div>
				);
			}
		} else {
			return null;
		}
	}

	selecionarUnidade = (unidade) => {
		unidade._selecionada = !unidade._selecionada;
		this.forceUpdate();
	}

	listarVeiculo = (event) => {
		this.veiculoService.listar(`search=placa:${event.value};status:ATIVADO`, {page: 0, size: 1}).then((veiculos) => {
			if (veiculos.length) {
				const veiculo = veiculos[0];
				this.agendaService.listar(`search=status:ATIVADO&veiculos=id:${veiculo.id}`, {page: 0, size: 10}).then((agendas) => {
					if (agendas.length) {
						const passageiros = [], ids = [], uids = [];
						agendas.forEach((a) => {
							a.pessoas.forEach((p) => {
								if (p.lugar && !ids.includes(p.id)) {
									p._selecionada = true;
									ids.push(p.id);
									passageiros.push(p);
								}
							});
							a.etiquetas.forEach((e) => {
								if (e.flags.includes("UNIDADE") && !uids.includes(e.ids)) {
									uids.push(e.id);
								}
							});
						});
						const unidades = this.state.unidades;
						unidades.forEach(u => {
							if (uids.includes(u.etiqueta.id)) {
								u._selecionada = true;
							}
						});
						this.setState({[event.name]: event.value, veiculo, passageiros, unidades});
					} else {
						this.setState({[event.name]: event.value, veiculo, passageiros: []});
					}
				});
			} else {
				this.setState({veiculo: {...MVeiculo.Modelo(), placa: event.value}, passageiros: []});
			}
		});
	}

	handleChange = (event) => {
		switch (event.name) {
			case "placa":
				event.value = event.value.toUpperCase();
				if (event.value.length === 7) {
					if (this.veiculoTimeout) {
						clearTimeout(this.veiculoTimeout);
					}
					this.veiculoTimeout = setTimeout(() => this.listarVeiculo(event), 300);
				}
				this.setState({placa: event.value});
				break;
			case "categoria":
				window.localStorage.setItem("safety-categoria-last", event.value.id);
				this.setState({[event.name]: event.value});
				this.swapModeloAgenda(event.value.descricao);
				break;
			case "portaria":
				const equipamento = event.value?.equipamentoPadrao;
				this.setState({portaria: event.value, equipamento});
				break;
			default:
				this.setState({[event.name]: event.value});
				break;
		}
	}

	swapModeloAgenda = (descricao) => {
		setTimeout(() => {
			let modeloAgenda = this.state.modelosAgenda.filter(ma => ma.value.descricao === descricao).map(ma => ma.value)[0] || null;
			if (!modeloAgenda) {
				modeloAgenda = this.state.modelosAgenda.filter(ma => ma.value.descricao === this.state.portaria.value.descricao).map(ma => ma.value) || null;
			}
			this.setState({modeloAgenda});
		}, 50);
	}

	handleVeiculoChange = (event) => {
		const {veiculo} = this.state;
		veiculo[event.name] = event.value;
		this.setState({veiculo});
	}

	handleQueryChange = (event) => {
		if (event.value.length > 5) {
			if (this.queryTimeout) {
				clearTimeout(this.queryTimeout);
			}
			this.queryTimeout = setTimeout(() => {
				if (event.value.length > 2) {
					this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.value};status:ATIVADO`, {page: 0, size: 50}).then((pessoas) => {
						if (!pessoas.length) {
							pessoas.push({...MPessoaFisica.Modelo(), id: null, nome: `Criar cadastro ${event.value}`});
						}
						let selecionadas = this.state.pessoas.filter(p => p._selecionada);
						let ids = selecionadas.map(p => p.id);
						pessoas = pessoas.filter(p => !ids.includes(p.id));
						pessoas = selecionadas.concat(pessoas);
						this.setState({pessoas, ids});
					});
				}
			}, 1000);
		}
		this.setState({[event.name]: event.value});
	}

	templatePessoa = (pessoa) => {
		return (
			<div className="tablet-ativo-pessoa" onClick={() => this.selecionarPessoa(pessoa)}>
				{pessoa.nome}<br/>
				{pessoa.cpf || pessoa.rg || pessoa.passaporte}
			</div>
		);
	}

	selecionarPessoa = (pessoa) => {
		let pessoas = this.state.pessoas;
		if (pessoa.id) {
			pessoas.filter(p => p.id === pessoa.id).forEach(p => p._selecionada = !p._selecionada);
			const ids = pessoas.filter(p => p._selecionada).map(p => p.id);
			this.setState({pessoas, ids, query: ""});
			this.buscarAvisos(pessoa);
		} else {
			pessoas = pessoas.filter(p => p.id);
			this.setState({pessoas, query: ""}, () => this.editarPessoaFisica(pessoa));
		}
	}

	buscarAvisos = (pessoa) => {
		this.bloqueioService.isBloqueado(pessoa.id).then(bloqueado => {
			this.avisoService.listarAlt([pessoa.id], this.state.unidades.filter(u => u._selecionada).map(u => u.etiqueta.id)).then((avisos) => {
				const pessoas = this.state.pessoas;
				pessoas.filter(p => p.id === pessoa.id).forEach(p => {
					p._bloqueio = bloqueado;
					p._avisos = avisos;
				});
				this.setState({pessoas});
			});
		});
	}

	templatePessoaSelecionada = (pessoa) => {
		const fotos = pessoa.fotos ? pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc) : [];
		return (
			<div className="tablet-ativo-pessoa-selecionada">
				<img className="foto" alt="" src={fotos.length ? fotos[0].path : null} onClick={() => this.editarPessoa(pessoa)}/><br/>
				<img className="remover" alt="" src={iconeDesvincular} onClick={() => this.desselecionarPessoa(pessoa)}/>
				{pessoa.nome}
				<div className="tablet-ativo-position-dot" style={{backgroundColor: pessoa.lugar ? "#4CAF50" : "#F44336"}}/>
				{pessoa._bloqueio ? <div className="portaria-aviso red">O acesso desta pessoa está bloqueado</div> : null}
				{this.securityAviso.view && pessoa._avisos != null ? pessoa._avisos.map(a => {
					switch (a.tipo) {
						case "ALERTA":
							return <div style={{color: a.fonte, backgroundColor: a.fundo}} className="portaria-aviso" key={a.id}>{a.texto}{this.securityAviso.remove ? <i className="fa fa-times ui-chip-remove" onClick={(event) => this.excluirAviso(event, a)}/> : null}</div>;
						case "LIBERACAO":
							return (
								<div style={{color: a.fonte, backgroundColor: a.fundo}} className="portaria-aviso" key={a.id}>
									{Validator.isEmpty(a.texto) ? `Pré-autorizado para a unidade ${a.etiqueta.descricao}` : a.texto}{this.securityAviso.remove ? <i className="fa fa-times ui-chip-remove" onClick={(event) => this.excluirAviso(event, a)}/> : null}
								</div>
							);
						default:
							return null;
					}
				}) : null}
			</div>
		);
	}

	editarPessoa = pessoa => {
		ModalRealm.showDialog(<CadastroPessoaTablet key={Math.random()} pessoa={pessoa} onModalClose={(pessoa) => {
			pessoa._selecionada = true;
			const pessoas = this.state.pessoas.filter(p => p.id !== pessoa.id);
			pessoas.unshift(pessoa);
			const ids = pessoas.filter(p => p._selecionada).map(p => p.id);
			this.setState({pessoas, ids});
		}}/>);
	}

	desselecionarPessoa = (pessoa) => {
		const pessoas = this.state.pessoas;
		pessoas.filter(p => p.id === pessoa.id).forEach(p => delete (p._selecionada));
		const ids = pessoas.filter(p => p._selecionada).map(p => p.id);
		this.setState({pessoas, ids});
	}

	renderCurrentPage = () => {
		switch (this.state.step) {
			case 0: {
				return (
					<div>
						<div className="ui-g" style={{margin: ".5em"}}>
							<Combobox label="Portaria" options={this.state.portarias} value={this.state.portaria} name="portaria" onChange={this.handleChange} grid={6}/>
							<TextField label="Veículo" grid={6} placeholder="Digite a placa do veículo" value={this.state.placa} onChange={this.handleChange} name="placa"/>
						</div>
						<div className="tablet-unidade-container">{this.state.unidades.filter(u => u._selecionada).map(this.templateUnidadesSelecionadas)}</div>
						{this.templateVeiculo()}
						<div className="tablet-ativo-unidades">
							{this.state.nomesBlocos.map(k => this.templateBloco(k, this.state.blocos[k]))}
						</div>
					</div>
				);
			}
			case 1: {
				return (
					<div>
						<div className="ui-g" style={{margin: ".5em"}}>
							<Combobox label="Categoria" options={this.state.categorias} value={this.state.categoria} name="categoria" onChange={this.handleChange} grid={6}/>
							<Combobox label="Modelo de Agenda" options={this.state.modelosAgenda} value={this.state.modeloAgenda} name="modeloAgenda" onChange={this.handleChange} grid={6}/>
							<TextField label="Pessoa" placeholder="Pesquise por nome ou qualquer documento da pessoa; ou placa do veículo" value={this.state.query} name="query" onChange={this.handleQueryChange} grid={12}/>
						</div>
						<div style={{margin: "1em"}}>
							{this.state.ids.length ? <DataView style={{marginBottom: "1em"}} header="Selecionadas" emptyMessage="" layout="grid" rows={5} value={this.state.pessoas.filter(p => p._selecionada)} itemTemplate={this.templatePessoaSelecionada}/> : null}
							<DataTable emptyMessage="" value={this.state.pessoas.filter(p => !this.state.ids.includes(p.id))}>
								<Column header="Nome" style={{width: "*"}} body={this.templatePessoa}/>
							</DataTable>
						</div>
					</div>
				);
			}
			case 2: {
				return null;
			}
			default: {
				return null;
			}
		}
	}

	render() {
		return (
			<div className="tablet-ativo">
				<div className="tablet-mainbar">
					{UserData.usuario.pessoa?.nome || UserData.usuario.login}
				</div>
				{this.renderCurrentPage()}
				<div className="tablet-ativo-acoes">
					<div style={{visibility: this.state.step > 0 || this.state.unidades.some(u => u._selecionada) ? "visible" : "hidden"}} onClick={this.restartSteps}><i className="fa fa-times"/></div>
					<div style={{visibility: this.state.step > 0 ? "visible" : "hidden"}} onClick={this.decreaseStep}><i className="fa fa-arrow-left"/></div>
					<div style={{visibility: this.state.step === 0 && this.state.unidades.some(u => u._selecionada) ? "visible" : "hidden"}} onClick={this.increaseStep}><i className="fa fa-arrow-right"/></div>
					<div style={{visibility: this.state.step === 1 && this.state.ids.length && this.state.destino && this.state.categoria && this.state.modeloAgenda && !this.state.pessoas.some(p => p._selecionada && p._bloqueio) ? "visible" : "hidden"}} className="entrada" onClick={this.lancarEntrada}><i className="fa fa-arrow-down rotate-45"/></div>
					<div style={{visibility: this.state.step === 1 && this.state.ids.length && this.state.categoria && this.state.modeloAgenda && !this.state.pessoas.some(p => p._selecionada && p._bloqueio) ? "visible" : "hidden"}} className="saida" onClick={this.lancarSaida}><i className="fa fa-arrow-up rotate-45"/></div>
				</div>
				<ModalRealm/>
				<div className="tablet-ativo-equipamento">{this.state.equipamento?.descricao}</div>
				<i className="fa fa-sign-out-alt tablet-logout" onClick={handleLogout}/>
			</div>
		);
	}

	lancarEntrada = () => this.lancarAcesso(this.state.destino);

	lancarSaida = () => this.lancarAcesso(null);

	lancarAcesso = async destino => {
		const {equipamento} = this.state;
		let etiquetas = this.state.unidades.filter(u => u._selecionada).map(u => u.etiqueta);
		etiquetas.push(this.state.categoria);
		etiquetas = etiquetas.concat(this.state.portaria.etiquetas);
		const eids = etiquetas.map(e => e.id);
		etiquetas = etiquetas.filter((e, i) => eids.indexOf(e.id) === i);
		const pessoas = this.state.pessoas.filter(p => p._selecionada);
		const criterio = {equipamento, etiquetas, destino};
		const veiculos = [];
		if (this.state.veiculo) {
			veiculos.push(this.state.veiculo);
		}
		if (destino) {
			const modeloAgenda = this.state.modeloAgenda;
			const agenda = {...MAgenda.Modelo(), etiquetas, pessoas, veiculos, modelosAcesso: modeloAgenda.modelosAcesso};
			switch (modeloAgenda.duracao) {
				case -1:
					agenda.validade = moment().format("YYYY-MM-DDT23:59:59");
					break;
				case 0:
					agenda.validade = null;
					break;
				default:
					agenda.validade = moment().add(modeloAgenda.duracao, "minutes").format("YYYY-MM-DDTHH:mm:59");
					break;
			}
			agenda.flags = modeloAgenda.agendaFlags;
			if (modeloAgenda.flags.includes("PERMITIR_DOCUMENTO")) {
				for (const pessoa of agenda.pessoas) {
					if (pessoa.rg?.length) {
						const codigo = Number(pessoa.rg.replace(/[\D]/g, ""));
						await httpGet(`/credenciais?search=forma:SENHA;codigo:${codigo}&pessoa=id:${pessoa.id}`).then(async credenciais => {
							if (!credenciais?.length) {
								const credencial = {
									...MCredencial.Modelo(),
									descricao: `Senha RG ${pessoa.id}`,
									forma: "SENHA",
									flags: ["DOCUMENTO"],
									pessoa,
									codigo
								};
								await httpPost("/credenciais", credencial);
							}
						});
					}
					if (pessoa.cpf?.length) {
						const codigo = Number(pessoa.cpf.replace(/[\D]/g, ""));
						await httpGet(`/credenciais?search=forma:SENHA;codigo:${codigo}&pessoa=id:${pessoa.id}`).then(async credenciais => {
							if (!credenciais?.length) {
								const credencial = {
									...MCredencial.Modelo(),
									descricao: `Senha CPF ${pessoa.id}`,
									forma: "SENHA",
									flags: ["DOCUMENTO"],
									pessoa,
									codigo
								};
								await httpPost("/credenciais", credencial);
							}
						});
					}
				}
			}
			if (this.state.condominio?.equipamentosFaciais?.length) {
				const ae = agenda.etiquetas.map(e => e.id);
				const equipamentos = this.state.equipamentosFaciais?.filter(ef => ef.etiquetas?.some(e => ae.includes(e.id)));
				if (equipamentos?.length) {
					const manutencao = {
						pessoas: agenda.pessoas,
						equipamentos
					};
					await httpPost("/manutencoes", manutencao);
				}
			}
			if (agenda.veiculos?.length && !agenda.veiculos[0].id) {
				this.veiculoService.salvar(agenda.veiculos[0]).then(veiculo => {
					agenda.veiculos = [veiculo];
					this.agendaService.salvar(agenda);
				});
			} else {
				this.agendaService.salvar(agenda);
			}
		}
		this.confirmarAcesso(criterio, pessoas);
	}

	confirmarAcesso = (criterio, pessoas, index = 0) => {
		if (index < pessoas.length) {
			this.portariaService.lancarAcesso({...criterio, pessoa: pessoas[index]}).then(() => this.confirmarAcesso(criterio, pessoas, ++index));
		} else {
			this.restartSteps();
		}
	}

	editarPessoaFisica = pessoa => {
		pessoa.nome = pessoa.nome.replace("Criar cadastro ", "");
		if (Validator.isCPF(pessoa.nome)) {
			pessoa.cpf = pessoa.nome;
			pessoa.nome = null;
		} else if (!isNaN(Number(pessoa.nome))) {
			pessoa.rg = pessoa.nome;
			pessoa.nome = null;
		}
		this.editarPessoa(pessoa);
	}

	restartSteps = () => {
		const unidades = this.state.unidades;
		unidades.forEach(u => delete (u._selecionada));
		this.setState({unidades, step: 0, veiculo: null, placa: "", pessoas: [], ids: [], query: ""});
	}

	increaseStep = () => this.setState({step: this.state.step + 1});

	decreaseStep = () => this.setState({step: this.state.step - 1});

}

class CadastroPessoaTablet extends React.Component {

	localidadeService = new LocalidadeService();
	pessoaFisicaService = new PessoaFisicaService();
	enderecoService = new EnderecoService();

	state = {
		visible: true,
		pessoa: Object.assign({fotos: []}, this.props.pessoa)
	};

	componentDidMount() {
		const {pessoa} = this.state;
		if (this.state.pessoa.enderecos?.length) {
			const index = this.state.pessoa.enderecos.findIndex(e => e.tipo === "PRINCIPAL");
			if (index > -1) {
				const endereco = this.state.pessoa.enderecos[index];
				pessoa._logradouro = endereco.logradouro;
				pessoa._numero = endereco.numero;
				pessoa._complemento = endereco.complemento;
				pessoa._bairro = endereco.bairro;
				pessoa._localidade = endereco.localidade;
				pessoa._eid = endereco.id;
			}
		}
		const mediaDevices = navigator.mediaDevices;
		if (mediaDevices) {
			mediaDevices.enumerateDevices().then(webcams => {
				const cameras = webcams.filter(w => w.kind === "videoinput").map(w => ({label: w.label, value: {deviceId: w.deviceId}}));
				let camera = null;
				if (cameras?.length) {
					const lastCamera = window.localStorage.getItem("X-Foto-WebcamID");
					if (lastCamera?.length) {
						const index = cameras.findIndex(c => c.value.deviceId === lastCamera);
						if (index > -1) {
							camera = cameras[index].value;
						}
					} else {
						camera = cameras[0].value;
					}
				}
				this.setState({cameras, camera, pessoa}, this.updateWebcam);
			});
		} else {
			this.setState({pessoa});
		}
	}

	updateWebcam = () => {
		this.stopWebcam();
		if (this.state.camera && this.webcam) {
			navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: this.state.camera.deviceId}}).then(stream => {
				this.webcam.setStream(stream);
				this.stream = stream;
			});
		}
	}

	stopWebcam = () => {
		if (this.stream) {
			this.stream.getTracks().forEach(track => track.stop());
		}
	}

	handleChange = (event) => {
		const pessoa = this.state.pessoa;
		pessoa[event.name] = event.value;
		this.setState({pessoa});
	}

	handleChangeRoot = event => {
		this.setState({[event.name]: event.value}, () => {
			if (event.name === "camera") {
				window.localStorage.setItem("X-Foto-WebcamID", event.value.deviceId);
				this.updateWebcam();
			}
		});
	}

	onSalvar = () => {
		let {pessoa} = this.state;
		const messages = MPessoaFisica.validarParaSalvar(pessoa);
		if (messages && messages.length) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			if (pessoa._cep?.length && pessoa._logradouro?.length) {
				if (pessoa._eid) {
					pessoa.enderecos.filter(e => e.id === pessoa._eid).forEach(e => {
						e.logradouro = pessoa._logradouro;
						e.numero = pessoa._numero;
						e.bairro = pessoa._bairro;
						e.localidade = pessoa._localidade;
						e.cep = pessoa._cep;
						e.complemento = pessoa._complemento;
					});
				} else {
					pessoa.enderecos.push({
						tipo: "PRINCIPAL",
						logradouro: pessoa._logradouro,
						numero: pessoa._numero,
						bairro: pessoa._bairro,
						localidade: pessoa._localidade,
						cep: pessoa._cep,
						complemento: pessoa._complemento
					});
				}
			}
			pessoa = MPessoaFisica.cleanupBeforeSave(pessoa);
			this.pessoaFisicaService.salvar(pessoa).then((pessoa) => {
				if (this.props.onModalClose) {
					this.props.onModalClose(pessoa);
				}
				this.onFechar();
			});
		}
	}

	takeSnapshot = async () => {
		if (this.webcam) {
			let {pessoa} = this.state;
			const conteudo = this.webcam.takeScreenshot().split(",")[1];
			const foto = await httpPost("/fotos", {status: "ATIVADO", finalidade: "IDENTIFICACAO", conteudo});
			pessoa.fotos.push(foto);
			this.setState({pessoa}, this.stopWebcam);
		}
	}

	onFechar = () => {
		this.setState({visible: false});
	}

	refWebcam = (el) => this.webcam = el;

	buscarEndereco = () => {
		this.enderecoService.buscarCEP(this.state.pessoa._cep).then(result => {
			if (result.status === 200) {
				result.json().then(json => {
					const {pessoa} = this.state;
					pessoa._cep = json.cep;
					pessoa._logradouro = json.logradouro;
					pessoa._bairro = json.bairro;
					this.localidadeService.listar(`search=nome:${json.localidade};uf:${json.uf}`, {size: 1, page: 0}).then(localidades => {
						if (localidades.length === 1) {
							pessoa._localidade = localidades[0];
						} else {
							pessoa._localidade = {nome: json.localidade, uf: json.uf};
						}
						this.setState({pessoa});
					});
				});
			}
		});
	}

	excluirFoto = () => {
		const {pessoa} = this.state;
		pessoa.fotos = [];
		this.setState({pessoa}, this.updateWebcam);
	}

	render() {
		const fotos = this.state.pessoa.fotos.filter(isFinalidadeIdentificacao);
		if (this.state.visible) {
			return (
				<div>
					<div className="tablet-ativo-cadastro">
						<DialogContent>
							<Combobox grid={12} label="Câmera" disabled={this.state.imagem?.length} appendTo={document.body} value={this.state.camera} options={this.state.cameras} onChange={this.handleChangeRoot} name="camera"/>
							<div className="ui-g-12 tablet-ativo-webcam" onClick={this.takeSnapshot}>
								{fotos?.length ? <img src={toSrc(fotos[0])} onClick={this.excluirFoto} alt=""/> : <WebCamera style={{width: "480px", height: "480px", border: "1px solid rgba(0, 0, 0, .2)", borderRadius: "3px"}} screenshotFormat="image/jpeg" ref={this.refWebcam} audio={false}/>}
							</div>
							<TextField type="uppercase" grid={12} name="nome" label="Nome" value={this.state.pessoa.nome} onChange={this.handleChange}/>
							<TextField grid={4} name="rg" label="RG" value={this.state.pessoa.rg} onChange={this.handleChange}/>
							<TextField grid={4} name="cpf" label="CPF" value={this.state.pessoa.cpf} onChange={this.handleChange}/>
							<TextField grid={4} name="passaporte" label="Outro Documento" value={this.state.pessoa.passaporte} onChange={this.handleChange}/>
							<TextField type="uppercase" grid={6} name="nomeMae" label="Nome da Mãe" value={this.state.pessoa.nomeMae} onChange={this.handleChange}/>
							<TextField type="uppercase" grid={6} name="nomePai" label="Nome do Pai" value={this.state.pessoa.nomePai} onChange={this.handleChange}/>
							<TextField type="telefone" grid={4} name="telefone" label="Telefone" value={this.state.pessoa.telefone} onChange={this.handleChange}/>
							<TextField type="telefone" grid={4} name="celular" label="Celular" value={this.state.pessoa.celular} onChange={this.handleChange}/>
							<TextField grid={4} name="email" label="E-mail" value={this.state.pessoa.email} onChange={this.handleChange}/>
							<MultiSelectEtiqueta grid={12} name="etiquetas" label="Etiquetas" value={this.state.pessoa.etiquetas} onSelect={this.handleChange}/>
							<TextField onBlur={this.buscarEndereco} type="cep" grid={4} name="_cep" label="CEP" value={this.state.pessoa._cep} onChange={this.handleChange}/>
							<TextField grid={6} name="_logradouro" label="Logradouro" value={this.state.pessoa._logradouro} onChange={this.handleChange}/>
							<TextField grid={2} name="_numero" label="Número" value={this.state.pessoa._numero} onChange={this.handleChange}/>
							<TextField grid={4} name="_complemento" label="Complemento" value={this.state.pessoa._complemento} onChange={this.handleChange}/>
							<TextField grid={4} name="_bairro" label="Bairro" value={this.state.pessoa._bairro} onChange={this.handleChange}/>
							<SelectLocalidade grid={4} name="_localidade" label="Localidade" value={this.state.pessoa._localidade} onChange={this.handleChange}/>
						</DialogContent>
					</div>
					<DialogFooter className="tablet-ativo-cadastro-footer">
						<Action className="tablet-button" mode="post" label="Salvar" icon="fa-save" onClick={this.onSalvar}/>
						<Action className="tablet-button" mode="secondary" label="Fechar" icon="fa-times" onClick={this.onFechar}/>
					</DialogFooter>
				</div>
			);
		} else {
			return null;
		}
	}

}

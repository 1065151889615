import React from "react";

export class HelpEncomenda extends React.Component {

	render() {
		return (
			<div>
				<p>Encomenda é o mecanismo utilizado pelo Safety para registrar e ao mesmo tempo notificar por e-mail o destinatário da chegada de uma encomenda.</p>
				<p>Esta tela permite adicionar, editar e consultar uma encomenda, permitindo o registro e a notificação da chegada e da recepção da encomenda. Uma vez entregue ao destinatário, é necessário registrar a <em>Entrega</em>;  editando uma encomenda existente.</p>
				<h2>Como registrar a Encomenda?</h2>
				<li><em>Dados Principais</em>:
					inicialmente, as encomendas devem ser registradas no sistema com os dados de remetente, destinatário e a discriminação. É possível detalhar os dados da encomenda com a data, ou então com etiqueta para classificação.
				</li>
				<li><em>Ocorrências</em>:
					são os textos que registram alguma observação quanto à uma ocorrência após a chegada da encomenda, ou registram as tentativas de contato com o destinatário.
				</li>
				<li><em>Entrega</em>:
                    é utilizada para registrar que a encomenda foi entregue ao destinatário. Pode armazenar uma foto como comprovante de que a encomenda foi entregue em mãos.
                </li>
				<h2>Filtros de Busca</h2>
				<li><em>Status</em>: é possível definir o filtro da encomenda como: todos, novo, entregue ou cancelado.
				</li>
				<li><em>Período</em>: filtra um perído da encomenda entre duas datas, bastando clicar no campo e selecionando duas datas.
				</li>
				<li><em>Discriminação</em>: filtra uma encomenda por uma discriminação, bastando inserir um trecho da discriminação.
                </li>
				<li><em>Etiquetas</em>: filtra uma encomenda por etiquetas, bastando inseri-las.
                </li>
				<li><em>Destinatário</em>: filtra uma encomenda através de um destinatário.
                </li>
				<h2>Perguntas frequentes</h2>
				<em>Cadastrei outro destinatário, o que devo fazer?</em>
				<div>
				Inicialmente envie um e-mail ou entre em contato com o destinatário que foi notificado erroneamente, explicando a falha ocorrida. 
				Após esse processo, ao invéz de editar a encomenda, crie uma nova com os dados corretos, e exclua o cadastro de encomenda feito incorretamente.
				</div>

			</div>
		);
	}
}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";
import {TarifaSaldoService} from "../servicos/TarifaSaldoService";
import {SelectTarifaConta} from "../../select/SelectTarifaConta";
import {Validator} from "../../utils/Validator";
import {ModalRealm} from "../commons/ModalRealm";
import {TextField} from "../commons/TextField";

export class TransferirSaldo extends React.Component {

	state = {
		contaOrigem: Object.assign({}, this.props.origem),
		contaDestino: null,
		visible: true,
		activeIndex: 0,
		pessoaOrigem: null,
		pessoaDestino: null,
		valor: 0,
		lockSave: false
	};

	tarifaSaldoService = new TarifaSaldoService();
	security = UserData.security("TSD", true);

	onClose = () => {
		this.setState({visible: false});
	}

	efetuarTransferencia = () => {
		const messages = [];
		if (!Validator.isEntidade(this.state.contaOrigem)) messages.push("Por favor, selecione uma conta de origem");
		if (!Validator.isEntidade(this.state.contaDestino)) messages.push("Por favor, selecione uma conta de destino");
		if (this.state.contaOrigem && this.state.contaDestino && this.state.contaOrigem.id === this.state.contaDestino.id) messages.push("A conta de origem precisa ser diferente da conta de destino");
		if (!this.state.valor) messages.push("Por favor, informe a quantia a ser transferida");
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			const {observacao} = this.state;
			const responsavel = UserData.usuario;
			const tarifaSaldo1 = {observacao: `Ref. Dest: ${this.state.contaDestino.id} - ${observacao}`, responsavel, conta: this.state.contaOrigem, valor: -this.state.valor};
			const tarifaSaldo2 = {observacao: `Ref. Orig: ${this.state.contaOrigem.id } - ${observacao}`, responsavel, conta: this.state.contaDestino, valor: this.state.valor};
			this.setState({lockSave: true});
			this.tarifaSaldoService.salvarBatch({saldos: [tarifaSaldo1, tarifaSaldo2]}).then(() => {
				this.setState({lockSave: false});
				if (this.props.onModalClose) {
					this.props.onModalClose();
				}
				this.onClose();
			}).catch(() => this.setState({lockSave: false}));
		}
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleChange = (event) => this.setState({[event.name]: event.value});

	numberFormat = Intl.NumberFormat("pt-BR");

	render() {
		return (
			<Dialog modal header="Transferência de Saldo" visible={this.state.visible} style={{width: "720px"}} onHide={this.onClose}>
				<DialogContent>
					<SelectTarifaConta disabled grid={5} label="Nº da Conta de Origem" value={this.state.contaOrigem} onChange={this.handleChange} />
					<SelectTarifaConta grid={5} label="Nº da Conta de Destino" name="contaDestino" value={this.state.contaDestino} onChange={this.handleChange} />
					<TextField unit="Cr" grid={2} name="valor" type="decimal" minValue={0} maxValue={this.state.contaOrigem.saldo} value={this.state.valor} onChange={this.handleChange} label="Valor (Cr)" />
					<div className="ui-g-5">
						<b>Dados da Conta de Origem</b>
						<div>Titular: {this.state.contaOrigem.pessoa.nome}</div>
						<div>{this.state.contaOrigem.pessoa.cpf ? `CPF: ${this.state.contaOrigem.pessoa.cpf.formatCpf()}` : this.state.contaOrigem.pessoa.cnpj ? `CNPJ: ${this.state.contaOrigem.pessoa.cnpj}` : ""}</div>
					</div>
					{this.state.contaDestino && this.state.contaDestino.id ? (
						<div className="ui-g-5">
							<b>Dados da Conta de Destino</b>
							<div>Titular: {this.state.contaDestino.pessoa.nome}</div>
							<div>{this.state.contaDestino.pessoa.cpf ? `CPF: ${this.state.contaDestino.pessoa.cpf.formatCpf()}` : this.state.contaDestino.pessoa.cnpj ? `CNPJ: ${this.state.contaDestino.pessoa.cnpj}` : ""}</div>
						</div>
					) : null}
					<TextField maxLength={200} grid={12} label="Identificação no Extrato" name="observacao" value={this.state.observacao} onChange={this.handleChange} />
				</DialogContent>
				<DialogFooter>
					<Action disabled={!this.security.edit || this.state.lockSave} mode="post" label="Transferir" onClick={this.efetuarTransferencia} icon="fa-random" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.efetuarTransferencia} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {DayOfWeek} from "../../utils/DateUtils";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {ContratoTrabalhoService} from "../servicos/ContratoTrabalhoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarContratoTrabalho} from "./EditarContratoTrabalho";

export class ContratoTrabalho extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	contratoTrabalhoService = new ContratoTrabalhoService();
	security = UserData.security("CTB", true);

	listarContratosTrabalho = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.contratoTrabalhoService.listar(`funcionario=nome,rg,cpf,nis,passaporte~${this.state.descricao}${this.state.excluidos ? "" : "&search=status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((contratosTrabalho) => {
				contratosTrabalho = contratosTrabalho.sort((a, b) => a.funcionario.nome.localeCompare(b.funcionario.nome));
				this.setState({contratosTrabalho, firstResult: 0});
			});
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarContratoTrabalho = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.contratoTrabalhoService.buscar(rowData).then((contratoTrabalho) => {
				ModalRealm.showDialog(<EditarContratoTrabalho key={Math.random()} contratoTrabalho={contratoTrabalho} onModalClose={this.listarContratosTrabalho} />);
			});
		} else {
			ModalRealm.showDialog(<EditarContratoTrabalho key={Math.random()} contratoTrabalho={null} onModalClose={this.listarContratosTrabalho} />);
		}
	}

	excluirContratoTrabalho = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este contrato de trabalho?", () => this.contratoTrabalhoService.excluir(rowData).then(this.listarContratosTrabalho));
	}

	restaurarContratoTrabalho = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este contrato de trabalho?", () => {
			if (rowData != null && rowData.id != null) {
				this.contratoTrabalhoService.buscar(rowData).then((contratoTrabalho) => {
					contratoTrabalho.status = "ATIVADO";
					this.contratoTrabalhoService.salvar(contratoTrabalho).then(this.listarContratosTrabalho);
				});
			}
		});
	}

	eliminarContratoTrabalho = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este contrato de trabalho?", () => this.contratoTrabalhoService.eliminar(rowData).then((result) => {
			if (result) this.listarContratosTrabalho(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este contrato de trabalho: há outros registros dependentes dele no sistema."]);
		}));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} header="Contratado"  style={{width: "*"}} body={(c) => {
			return (
				<div>
					<div><b>{c.funcionario.nome}</b></div>
					<div>{c.funcionario.nis ? `NIS: ${c.funcionario.nis}` : <span style={{color: "#F44336"}}>NIS: não preenchido</span>}</div>
				</div>
			);
		}} />,
		<Column key={1} header="Contratante"  style={{width: "*"}} body={(c) => {
			let endereco = <span style={{color: "#F44336"}}>Sem endereço cadastrado</span>;
			if (c.empresa.enderecos && c.empresa.enderecos.length) {
				endereco = `${c.empresa.enderecos[0].logradouro}, ${c.empresa.enderecos[0].numero}. ${c.empresa.enderecos[0].localidade.nome}/${c.empresa.enderecos[0].localidade.uf}`;
			}
			return (
				<div>
					<div><b>{c.empresa.nome}</b></div>
					<div>{c.empresa.cnpj ? `CNPJ: ${c.empresa.cnpj.formatCnpj()}` : <span style={{color: "#F44336"}}>CNPJ: não preenchido</span>}</div>
					<div>{endereco}</div>
				</div>
			);
		}} />,
		<Column key={2} header="Matrizes de Horário"  style={{width: "*"}} body={(c) => {
			return c.periodos.map(p => {
				const date = moment(p.matrizHorario.inicio);
				return (
					<div key={p.id}>
						<div>
							<b>{`Início: ${moment(p.inicio).format("DD/MM/YYYY")}`}</b>
							<b>{p.fim ? ` - Fim: ${moment(p.fim).format("DD/MM/YYYY")}` : ""}</b>
						</div>
						<div>{` ${p.matrizHorario.descricao} (iniciada em ${DayOfWeek.from(date.day())})`}</div>
						{p.matrizHorario.periodos.map(x => <div>{`${x.inicio ? x.inicio.formatTime() : "Folga"}${x.fim ? ` às ${x.fim.formatTime()}` : ""} em ${x.dias.join(", ")}`}</div>)}
					</div>
				);
			});
		}} />,
		<Column key={3} header="Início"  style={{width: "8em"}} body={(c) => moment(c.inicio).format("DD/MM/YYYY")} />,
		<Column key={4} header="Fim"  style={{width: "8em"}} body={(c) => c.fim ? moment(c.inicio).format("DD/MM/YYYY") : ""} />,
		<Column key={5} header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Contrato de Trabalho" onClick={(event) => this.editarContratoTrabalho(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Contrato de Trabalho" onClick={(event) => this.excluirContratoTrabalho(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Contrato de Trabalho" onClick={(event) => this.restaurarContratoTrabalho(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Contrato de Trabalho" onClick={(event) => this.eliminarContratoTrabalho(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/contratos-trabalho", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Contratos de Trabalho">
					<PanelContent>
						<TextField grid={12} placeholder="Pesquise por nome ou qualquer documento" label="Funcionário" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarContratosTrabalho} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.contratosTrabalho} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Contrato de Trabalho" icon="fa-plus" onClick={this.editarContratoTrabalho} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarContratosTrabalho}
					onCtrlN={() => window.location.pathname === "/contratos-trabalho" && this.security.create && this.editarContratoTrabalho()}
					onCtrlE={() => window.location.pathname === "/contratos-trabalho" && this.state.contratosTrabalho.length === 1 && this.security.edit && this.editarContratoTrabalho(this.state.contratosTrabalho[0])}
				/>
				<ModalRealm/>
			</div>
		);
	}

}

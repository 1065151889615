export class ImageUtils {

	static rotateBase64Image(base64data, width, height, degrees, flip, callback) {
		let canvas = document.createElement("canvas");
		let realWidth = degrees === 90 || degrees === 270 ? height : width;
		let realHeight = degrees === 90 || degrees === 270 ? width : height;
		canvas.setAttribute("width", realWidth);
		canvas.setAttribute("height", realHeight);
		let context = canvas.getContext("2d");
		let image = new Image(width, height);
		image.src = base64data;
		image.onload = function() {
			if (flip) {
				context.translate(realWidth, 0);
				context.scale(-1, 1);
				switch (degrees) {
					case 90:
						degrees = 270;
						break;
					case 270:
						degrees = 90;
						break;
					default:
				}
			}
			context.rotate(degrees * Math.PI / 180);
			switch (degrees) {
				case 0:
					context.drawImage(image, 0, 0, width, height);
					break;
				case 90:
					context.drawImage(image, 0, -image.height, width, height);
					break;
				case 180:
					context.drawImage(image, -image.width, -image.height, width, height);
					break;
				case 270:
					context.drawImage(image, -image.width, 0, width, height);
					break;
				default:
			}
			if (callback) {
				callback(canvas.toDataURL("image/jpeg"));
			}
		};
	}

}

export function toDataURL(camera, callback) {
	const xhr = new XMLHttpRequest();
	xhr.onload = function() {
		const reader = new FileReader();
		reader.onloadend = function() {
			callback(reader.result);
		}
		reader.readAsDataURL(xhr.response);
	};
	xhr.open("GET", camera.ip);
	if (camera.login && camera.senha) {
		xhr.setRequestHeader("Authorization", `Basic ${btoa(`${camera.login}:${camera.senha}`)}`);
	}
	xhr.responseType = "blob";
	xhr.send();
}

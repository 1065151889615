import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeCredenciais from "../../media/icones/credenciais.png";
import iconeCriarSenha from "../../media/icones/criar-senha.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeRemover from "../../media/icones/remover.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {AppletUtils} from "../../utils/AppletUtils";
import {LocalTime} from "../../utils/DateUtils";
import "../../utils/Format";
import {MAgenda} from "../../utils/models/MAgenda";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {MModeloAcesso} from "../../utils/models/MModeloAcesso";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {iconVehicle, MVeiculo} from "../../utils/models/MVeiculo";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {CriarSenha} from "../Credencial/CriarSenha";
import {EditarEtiqueta} from "../Etiqueta/EditarEtiqueta";
import {EditarModeloAcesso} from "../ModeloAcesso/EditarModeloAcesso";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {SelecionarPessoas} from "../PessoaFisica/SelecionarPessoas";
import {AgendaService} from "../servicos/AgendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {ModeloAcessoService} from "../servicos/ModeloAcessoService";
import {PeriodoService} from "../servicos/PeriodoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {SetupSistemaService} from "../servicos/SetupSistemaService";
import {VeiculoService} from "../servicos/VeiculoService";
import {EditarVeiculo} from "../Veiculo/EditarVeiculo";
import {Placa} from "../Veiculo/PlacaMercosul";
import {MultiCombobox} from "../commons/MultiCombobox";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {veiculoTemplate} from "../../select/SelectVeiculo";
import {SelectPessoa} from "../../select/SelectPessoa";
import {GUI} from "../../utils/Constants";
import {MDocumento} from "../../utils/models/MDocumento";
import {EditarDocumento} from "../Documento/EditarDocumento";
import iconeExcluir from "../../media/icones/excluir.png";
import {SelectButton} from "../commons/SelectButton";

export class EditarAgenda extends React.Component {

	state = {
		visible: true,
		agenda: this.props.agenda || MAgenda.Modelo(),
		activeIndex: 0,
		setup: {
			flags: []
		},
		_desativados: (window.localStorage.getItem("safety-agenda-pessoa-desativados") || "false") === "true",
		lockSave: false,
		modelosAgenda: []
	};

	service = new AgendaService();
	pessoaFisicaService = new PessoaFisicaService();
	etiquetaService = new EtiquetaService();
	modeloAcessoService = new ModeloAcessoService();
	periodoService = new PeriodoService();
	setupSistemaService = new SetupSistemaService();
	veiculoService = new VeiculoService();
	modeloAgendaService = new ModeloAgendaService();

	security = UserData.security("SCH", true);
	securityPessoa = UserData.security("PES");
	securityVeiculo = UserData.security("VEI");
	securityModeloAcesso = UserData.security("MAC");
	securityCredencial = UserData.security("CRD");
	securityDocumento = UserData.security("DOC");

	componentDidMount() {
		this.setupSistemaService.buscar().then(setup => {
			this.modeloAgendaService.listar("", {page: 0, size: 100}).then(modelosAgenda => {
				this.setState({setup, modelosAgenda, activeIndex: this.props.activeIndex || 0});
			});
		});
		const {agenda} = this.state;
		let lockEtiquetas = false;
		let hasPrincipal = agenda.principalPessoa;
		if (this.props.etiquetas) {
			agenda.etiquetas = this.props.etiquetas;
			lockEtiquetas = true;
		}
		this.setState({agenda, lockEtiquetas, hasPrincipal});
		if (this.state.agenda._filter?.length) {
			const pessoa = MPessoaFisica.Modelo();
			if (isNaN(this.state.agenda._filter)) {
				pessoa.nome = this.state.agenda._filter;
			} else if (Validator.isCPF(this.state.agenda._filter)) {
				pessoa.cpf = this.state.agenda._filter;
			} else {
				pessoa.rg = this.state.agenda._filter;
			}
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={pessoa} onModalClose={pessoa => {
				agenda.pessoas.push(pessoa);
				this.setState({agenda});
			}}/>);
		}
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarAgenda = () => {
		if (this.security.edit) {
			let agenda = MAgenda.cleanupBeforeSave(Object.assign({}, this.state.agenda));
			let messages = MAgenda.validarParaSalvar(agenda);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.service.salvar(agenda).then((agenda) => {
					this.setState({lockSave: false});
					if (this.props.onSave) {
						this.props.onSave(agenda);
					}
					if (this.props.onModalClose) {
						this.props.onModalClose(agenda);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	veiculoColumns = [
		<Column key={-1} header="Tipo" style={{width: "3em", textAlign: "center"}} body={iconVehicle} />,
		<Column key={0} header="Placa" style={{width: "8em", textAlign: "center"}} body={(rowData) => <Placa veiculo={rowData} />} />,
		<Column key={1} header="Fabricante" style={{width: "10em"}} body={(rowData) => rowData.fabricante?.nome || "Fabricante"} />,
		<Column key={2} header="Modelo" field="modelo" style={{width: "12em"}} />,
		<Column key={3} header="Cor" style={{width: "8em"}} body={(rowData) => MVeiculo.MapCores[rowData.cor] || ""} />,
		<Column key={4} header="Proprietário" style={{width: "*"}} body={(rowData) => rowData.proprietario ? rowData.proprietario.nome : ""} />,
		<Column key={5} header="Ações" field="acoes" style={{width: "6em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.securityVeiculo.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Veículo" onClick={() => this.editarVeiculo(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Veículo" onClick={() => this.removerVeiculo(rowData)} /> : null}
				</div>
			);
		}} />
	];

	cadastrarDigital = (rowData) => {
		AppletUtils.downloadFingerprint(rowData);
	}

	criarSenha = (rowData) => {
		ModalRealm.showDialog(<CriarSenha key={Math.random()} pessoa={rowData} />);
	}

	modeloAcessoFooter = (
		<div style={{textAlign: "left"}}>
			<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarModeloAcesso(null)} />
		</div>
	);

	toggleDesativados = () => {
		window.localStorage.setItem("safety-agenda-pessoa-desativados", String(!this.state._desativados));
		this.setState({_desativados: !this.state._desativados});
	}

	selecionarPessoas = () => {
		ModalRealm.showDialog(<SelecionarPessoas except={this.state.agenda.pessoas} onModalClose={(pessoas) => {
			const agenda = this.state.agenda;
			agenda.pessoas = agenda.pessoas.concat(pessoas).sort((a, b) => a.nome.localeCompare(b.nome));
			this.setState({agenda});
		}} />);
	}

	removerPessoas = () => {
		const agenda = this.state.agenda;
		agenda.pessoas = agenda.pessoas.filter(p => !p._selecionada);
		this.setState({agenda});
	}

	veiculoFooter = (
		<div style={{textAlign: "left"}}>
			<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarVeiculo(null)} />
		</div>
	);

	modeloAcessoColumns = [
		<Column header="Descrição" key="descricao" field="descricao" style={{width: "15em"}} />,
		<Column header="Horários" key="horarios" field="horarios" body={(rowData) => {
			return rowData.periodos.map((periodo) => {
				return <div key={Math.random()} style={{whiteSpace: "nowrap"}}>{this.periodoService.simplificarDiasSemana(periodo) + ", das " + LocalTime.withoutSeconds(periodo.inicio) + " às " + LocalTime.withoutSeconds(periodo.fim)}</div>;
			});
		}} />,
		<Column header="Permissões" key="permissoes" field="lugares" body={(rowData) => {
			return `${rowData.lugares.map((lugar) => lugar.lugar.descricao).join(", ")} ${rowData.equipamentos?.length ? `(+${rowData.equipamentos.length} equipamentos)` : ""}`;
		}} />,
		<Column header="Ações" key="acoes" field="acoes" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.securityModeloAcesso.edit && (UserData.usuario.login === "admin" || rowData.descricao !== "Acesso Geral") ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Modelo de Acesso" onClick={(event) => this.editarModeloAcesso(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Modelo de Acesso" onClick={(event) => this.removerModeloAcesso(rowData)} /> : null}
				</div>
			);
		}} />
	];

	listarPessoas = (event) => {
		let query = `search=status:ATIVADO;nome,rg,cpf,passaporte~${event.query};id!${this.state.agenda.pessoas.map(p => p.id).join(",")}`;
		if (this.props.gestor) {
			query += `&gestor=id:${this.props.gestor.id}`;
		}
		this.pessoaFisicaService.listar(query, {size: 10, page: 0}).then((pessoas) => {
			if (pessoas.length === 0) {
				this.setState({pessoas: [{id: -1, nome: `Criar pessoa ${event.query}`}]});
			} else {
				this.setState({pessoas});
			}
		});
	}

	listarVeiculos = (event) => {
		this.veiculoService.listar(`search=status:ATIVADO;placa~${event.query}`, {size: 10, page: 0}).then((veiculos) => {
			if (veiculos.length === 0) {
				this.setState({veiculos: [{id: -1, placa: `Criar veículo ${event.query}`}]});
			} else {
				this.setState({veiculos});
			}
		});
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((etiquetas) => {
			if (etiquetas.length === 0) {
				this.setState({etiquetas: [{...MEtiqueta.Modelo(), id: -1, descricao: `Criar etiqueta ${event.query}`}]});
			} else {
				this.setState({etiquetas});
			}
		});
	}

	removerPessoa = (event) => {
		let pessoas = this.state.agenda.pessoas.filter((pessoa) => event.id !== pessoa.id);
		this.setState({agenda: {...this.state.agenda, pessoas}});
	}

	removerVeiculo = (event) => {
		let veiculos = this.state.agenda.veiculos.filter((veiculo) => event.id !== veiculo.id);
		this.setState({agenda: {...this.state.agenda, veiculos}});
	}

	removerModeloAcesso = (event) => {
		let modelosAcesso = this.state.agenda.modelosAcesso.filter((modeloAcesso) => event.id !== modeloAcesso.id);
		this.setState({agenda: {...this.state.agenda, modelosAcesso}});
	}

	listarModelosAcesso = (event) => {
		this.modeloAcessoService.listar(`search=status:ATIVADO;descricao~${event.query};id!${this.state.agenda.modelosAcesso.map(ma => ma.id).join(",")}`, {size: 10, page: 0}).then((modelosAcesso) => {
			if (modelosAcesso.length === 0) {
				this.setState({modelosAcesso: [{descricao: `Criar modelo de acesso ${event.query}`, id: -1}]});
			} else {
				this.setState({modelosAcesso});
			}
		});
	}

	handleUpdate = (event) => {
		let agenda = this.state.agenda;
		agenda[event.name] = event.value;
		this.setState({agenda});
	}

	handleEntityListChange = (event) => {
		let agenda = this.state.agenda;
		event.value = event.value.filter(e => e.id !== -1);
		agenda[event.name] = event.value;
		this.setState({agenda});
	}

	selecionarEtiquetas = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={{...MEtiqueta.Modelo(), descricao: event.value.descricao.replace("Criar etiqueta ", "")}} onModalClose={(etiqueta) => {
				let agenda = this.state.agenda;
				agenda.etiquetas.push(etiqueta);
				this.setState({agenda});
			}} />);
		}
	}

	handleAutoSuggestionChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	handleAutoSuggestionSelect = (event) => {
		let agenda = this.state.agenda;
		agenda[event.name].push(event.value);
		this.setState({agenda});
	}

	editarModeloAcesso = (modeloAcesso) => {
		if (modeloAcesso && modeloAcesso.id) {
			this.modeloAcessoService.buscar(modeloAcesso).then((modeloAcesso) => {
				ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={modeloAcesso} onModalClose={(modeloAcesso) => {
					let agenda = this.state.agenda;
					if (agenda.modelosAcesso == null) agenda.modelosAcesso = [];
					agenda.modelosAcesso = agenda.modelosAcesso.filter(a => a.id !== modeloAcesso.id);
					agenda.modelosAcesso.push(modeloAcesso);
					this.setState({agenda});
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={null} onModalClose={(modeloAcesso) => {
				let agenda = this.state.agenda;
				if (agenda.modelosAcesso == null) agenda.modelosAcesso = [];
				agenda.modelosAcesso.push(modeloAcesso);
				this.setState({agenda});
			}} />);
		}
	}

	adicionarPessoa = (event) => {
		if (event.value.id === -1) {
			let value = event.value.nome.replace("Criar pessoa ", "");
			let property = Validator.isCPF(value) ? "cpf" : !isNaN(Number(value)) ? "rg" : "nome";
			if (property === "cpf") value = value.formatCpf();
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={{...MPessoaFisica.Modelo(), [property]: event.value.nome.replace("Criar pessoa ", "")}} onModalClose={(pessoa) => {
				let agenda = this.state.agenda;
				agenda.pessoas.unshift(pessoa);
				this.setState({agenda});
			}} />);
		} else {
			let agenda = this.state.agenda;
			agenda.pessoas.unshift(event.value);
			this.setState({agenda});
		}
		this.setState({pessoa: ""});
	}

	adicionarVeiculo = (event) => {
		if (event.value.id === -1) {
			let placa = event.value.placa.replace("Criar veículo ", "");
			ModalRealm.showDialog(<EditarVeiculo veiculo={{...MVeiculo.Modelo(), placa}} onModalClose={(veiculo) => {
				let agenda = this.state.agenda;
				agenda.veiculos.unshift(veiculo);
				this.setState({agenda});
			}} />);
		} else {
			let agenda = this.state.agenda;
			agenda.veiculos.unshift(event.value);
			this.setState({agenda});
		}
		this.setState({veiculo: ""});
	}

	adicionarVisitado = (event) => {
		if (event.value.id === -1) {
			let value = event.value.nome.replace("Criar pessoa ", "");
			let property = Validator.isCPF(value) ? "cpf" : !isNaN(Number(value)) ? "rg" : "nome";
			if (property === "cpf") value = value.formatCpf();
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={{...MPessoaFisica.Modelo(), [property]: event.value.nome.replace("Criar pessoa ", "")}} onModalClose={(pessoa) => {
				let agenda = this.state.agenda;
				agenda.visitados.unshift(pessoa);
				this.setState({agenda});
			}} />);
		} else {
			let agenda = this.state.agenda;
			agenda.visitados.unshift(event.value);
			this.setState({agenda});
		}
		this.setState({visitado: ""});
	}

	editarPessoa = (pessoa) => {
		const etiquetas = this.state.agenda.etiquetas?.filter(e => !e.flags?.includes("UNIDADE")).map(e => e.descricao);
		let modeloAgenda;
		if (this.state.modelosAgenda?.length) {
			modeloAgenda = this.state.modelosAgenda.filter(ma => etiquetas.includes(ma.descricao))[0];
		}
		if (pessoa?.id) {
			this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
				ModalRealm.showDialog(<EditarPessoaFisica obrigatorios={modeloAgenda?.obrigatorios} pessoa={pessoa} onModalClose={(pessoa) => {
					let agenda = this.state.agenda;
					agenda.pessoas = agenda.pessoas.filter(p => p.id !== pessoa.id);
					agenda.pessoas.unshift(pessoa);
					this.setState({agenda});
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarPessoaFisica obrigatorios={modeloAgenda?.obrigatorios} pessoa={null} onModalClose={(pessoa) => {
				let agenda = this.state.agenda;
				agenda.pessoas.unshift(pessoa);
				this.setState({agenda});
			}} />);
		}
	}

	editarVeiculo = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.veiculoService.buscar(rowData).then((veiculo) => {
				ModalRealm.showDialog(<EditarVeiculo veiculo={veiculo} onModalClose={(veiculo) => {
					let agenda = this.state.agenda;
					agenda.veiculos = agenda.veiculos.filter(v => v.id !== veiculo.id);
					agenda.veiculos.push(veiculo);
					this.setState({agenda});
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarVeiculo veiculo={MVeiculo.Modelo()} onModalClose={(veiculo) => {
				let agenda = this.state.agenda;
				agenda.veiculos.push(veiculo);
				this.setState({agenda});
			}} />);
		}
	}

	adicionarModeloAcesso = (event) => {
		if (event.value.id === -1) {
			ModalRealm.showDialog(<EditarModeloAcesso modeloAcesso={{...MModeloAcesso.Modelo(), descricao: event.value.descricao.replace("Criar modelo de acesso ", "")}} onModalClose={(modeloAcesso) => {
				let agenda = this.state.agenda;
				agenda.modelosAcesso.push(modeloAcesso);
				this.setState({agenda});
			}} />);
		} else {
			let agenda = this.state.agenda;
			agenda.modelosAcesso.push(event.value);
		}
		this.setState({modeloAcesso: ""});
	}

	handleChangeInicio = (event) => {
		const agenda = this.state.agenda;
		if (agenda.inicio) {
			agenda.inicio = event.value;
		} else {
			agenda.inicio = moment(event.value).hour(0).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ss");
		}
		this.setState({agenda});
	}

	handleChangeValidade = (event) => {
		const agenda = this.state.agenda;
		if (agenda.validade) {
			agenda.validade = event.value;
		} else {
			agenda.validade = moment(event.value).hour(23).minute(59).second(59).format("YYYY-MM-DDTHH:mm:ss");
		}
		this.setState({agenda});
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	toggleAllPessoas = () => {
		const agenda = this.state.agenda;
		agenda.pessoas.forEach((p) => p._selecionada = !p._selecionada);
		this.setState({agenda});
	}

	togglePessoa = (e, r) => {
		return (
			<Checkbox checked={this.state.agenda.pessoas[r.rowIndex]._selecionada} onChange={() => {
				const agenda = this.state.agenda;
				agenda.pessoas[r.rowIndex]._selecionada = !agenda.pessoas[r.rowIndex]._selecionada;
				this.setState({agenda});
			}} />
		);
	}

	handleCheckFlags = (event) => {
		const {agenda} = this.state;
		if (event.value) {
			agenda.flags.push(event.name);
		} else {
			agenda.flags = agenda.flags.filter(f => f !== event.name);
		}
		this.setState({agenda});
	}

	visitadosColumns = [
		<Column header="Nome" key="nome" field="nome" style={{width: "*"}} />,
		<Column header="CPF" key="cpf" field="cpf" style={{width: "10em"}} />,
		<Column header="RG" key="rg" field="rg" style={{width: "7em"}} />,
		<Column header="Ações" key="acoes" field="acoes" style={{width: "4em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Visitado" onClick={(event) => this.removerVisitado(rowData)} /> : null}
				</div>
			);
		}} />
	];

	removerVisitado = (visitado) => {
		const {agenda} = this.state;
		agenda.visitados = agenda.visitados.filter(v => v.id !== visitado.id);
		this.setState({agenda});
	}

	separarAgendas = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja separar as pessoas em agendas individuais? ATENCÃO: este processo é irreversível.", () => {
			this.separarAgendasIndividuais(this.state.agenda.pessoas, async () => {
				const {agenda} = this.state;
				agenda.status = "EXCLUIDO";
				await this.service.salvar(agenda);
				this.onClose();
				ModalRealm.showInformacao("info", ["Agendas criadas com sucesso!"]);
			});
		});
	}

	separarAgendasIndividuais = (pessoas, callbackFn, index = 0) => {
		const {agenda} = this.state;
		const {inicio, validade, flags, etiquetas, modelosAcesso} = agenda;
		if (pessoas.length > index) {
			const a = {
				inicio,
				validade,
				flags,
				etiquetas,
				modelosAcesso,
				pessoas: [pessoas[index]]
			};
			this.service.salvar(a).then(() => this.separarAgendasIndividuais(pessoas, callbackFn, ++index));
		} else {
			return callbackFn?.();
		}
	}

	editarDocumento = (documento) => {
		if (!documento) {
			documento = MDocumento.Modelo();
		}
		if (!documento.id && !documento._key) {
			documento._key = Math.random();
		}
		ModalRealm.showDialog(<EditarDocumento modo="agenda" key={Math.random()} documento={documento} onModalClose={documento => {
			const {agenda} = this.state;
			if (!agenda.documentos) {
				agenda.documentos = [];
			} else {
				agenda.documentos = agenda.documentos.filter((d) => d.id !== documento.id || d._key !== documento._key);
			}
			agenda.documentos.push(documento);
			this.setState({agenda, _mod: true});
		}} />);
	}

	detailsDocumentos = [
		<Column style={{width: "*"}} key={0} header="Tipo" body={documento => documento.tipo?.descricao} />,
		<Column style={{width: "*"}} key={1} header="Número" field="numero" />,
		<Column style={{width: "10em"}} key={2} header="Validade" body={documento => {
			const expired = documento.validade && moment(documento.validade).add(1, "days").isBefore(moment());
			return documento.validade ? <div className={expired ? "ui-expired-doc" : ""}>{moment(documento.validade).format("DD/MM/YYYY")}</div> : "Indeterminada";
		}} />,
		<Column style={{width: "6em", textAlign: "center"}} key={3} header="Ações" body={(documento) => {
			return (
				<div>
					{this.securityDocumento?.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Documento" onClick={() => this.editarDocumento(documento)} /> : null}
					{this.securityDocumento?.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Documento" onClick={() => this.excluirDocumento(documento)} /> : null}
				</div>
			);
		}} />
	];

	excluirDocumento = (documento) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja remover este documento?", () => {
			const {agenda} = this.state;
			if (agenda.documentos) {
				agenda.documentos = agenda.documentos.filter(d => d.id !== documento.id || d._key !== documento._key);
			}
			this.setState({agenda});
		});
	}

	changePrincipalPessoa = id => {
		const {agenda} = this.state;
		if (agenda.principalPessoa === id && !this.state.hasPrincipal) {
			agenda.principalPessoa = null;
		} else {
			agenda.principalPessoa = id;
		}
		this.setState({agenda});
	}

	render() {
		const hasVehicle = this.state.agenda.veiculos?.length;
		const mostrarVisitados = this.state.setup?.flags?.includes("MOSTRAR_VISITADO");
		return (
			<Dialog modal header="Agenda" visible={this.state.visible} style={{width: "1100px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<Combobox label="Status" grid={2} appendTo={document.body} name="status" onChange={this.handleUpdate} value={this.state.agenda.status} options={MAgenda.Status} />
								<DatePicker showTime showSeconds appendTo={document.body} label="Início" grid={3} name="inicio" onChange={this.handleChangeInicio} value={this.state.agenda.inicio} />
								<DatePicker showTime showSeconds appendTo={document.body} label="Validade" grid={3} name="validade" onChange={this.handleChangeValidade} value={this.state.agenda.validade} />
								<MultiCombobox defaultLabel="Nenhuma" grid={4} name="flags" label="Opções" value={this.state.agenda.flags} onChange={this.handleUpdate} options={MAgenda.Flags}/>
								<MultiSelectEtiqueta disabled={this.props.lockEtiquetas} label="Etiquetas" grid={7} onSelect={this.handleUpdate} name="etiquetas" value={this.state.agenda.etiquetas} />
								<SelectPessoa grid={5} name="empresa" value={this.state.agenda.empresa} onChange={this.handleUpdate} label="Empresa"/>
								<TextField grid={12} label="Observações" name="observacao" value={this.state.agenda.observacao} multiline rows={4} style={{height: "6em"}} onChange={this.handleUpdate} />
								{UserData.hasRole("XX0C") ? <TextField grid={12} label="Chave de Integração" name="chaveIntegracao" onChange={this.handleUpdate} value={this.state.agenda.chaveIntegracao} /> : null}
							</PanelContent>
						</TabPanel>
						<TabPanel header="Pessoas">
							<PanelContent>
								<SuggestionBox placeholder="Pesquise por nome ou qualquer documento" style={{position: "relative"}} appendTo={document.body} label={mostrarVisitados ? "Vistantes" : "Pessoas"} grid={12} field="nome" completeMethod={this.listarPessoas} suggestions={this.state.pessoas} onChange={(e) => this.setState({pessoa: e.value})} onSelect={this.adicionarPessoa} value={this.state.pessoa} />
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhuma pessoa adicionada" paginatorLeft={
										<div style={{textAlign: "left"}}>
											<Button title="Busca Avançada" className="ui-tzm-pagleft-icon" icon="fa-search" onClick={() => this.selecionarPessoas()} />
											<Button title="Nova Pessoa" className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarPessoa()} />
											<Button title="Remover Pessoas Selecionadas" className="ui-tzm-pagleft-icon danger" icon="fa-minus" onClick={() => this.removerPessoas()} />
											<Button title="Visualizar Desativados" className="ui-tzm-pagleft-icon white" icon={`fa-eye${this.state._desativados ? "" : "-slash"}`} onClick={() => this.toggleDesativados()} />
										</div>
									} paginatorRight={
										<div style={{textAlign: "right", paddingRight: "2px"}}>
											{this.state.agenda.pessoas?.length > 1 ? <Button title="Separar Agendas" className="ui-tzm-pagleft-icon danger" icon="fa-page-break" onClick={this.separarAgendas} /> : null}
										</div>
									} rows={10} rowClassName={(p) => ({"agenda-pessoa-desativada": p.status !== "ATIVADO"})} paginator value={this.state.agenda.pessoas.filter(p => p.status === "ATIVADO" || this.state._desativados)}>
										<Column style={{width: "3em", textAlign: "center"}} header={<Checkbox checked={this.state.agenda.pessoas.every(p => p._selecionada)} onChange={this.toggleAllPessoas} />} body={this.togglePessoa} />
										<Column header="&nbsp;" style={{width: "2.5em", textAlign: "center"}} body={pessoa => (
											<i
												onClick={() => this.changePrincipalPessoa(pessoa.id)}
												style={{cursor: "pointer", color: this.state.agenda.principalPessoa === pessoa.id ? hasVehicle ? "#1565C0" : "#FFA000" : "#BBBBBB"}}
												className={`fad ${hasVehicle ? "fa-steering-wheel" : "fa-star"}`}
												title={hasVehicle ? "Motorista" : "Visitante Principal"}
											/>
										)}/>
										<Column header="Nome" style={{width: "*"}} field="nome"/>
										<Column header="CPF" style={{width: "11em"}} body={(pessoa) => pessoa.cpf?.formatCpf()} />
										<Column header="RG" field="rg" style={{width: "11em"}} />
										<Column header="Documento" field="passaporte" style={{width: "11em"}} />
										<Column header="Ações" field="acoes" style={{width: "10em"}} body={(rowData) => {
											return (
												<div style={{textAlign: "center"}}>
													{this.securityPessoa.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Pessoa" onClick={(event) => this.editarPessoa(rowData)} /> : null}
													{this.securityPessoa.edit && this.securityCredencial.create ? <img alt="" className="ui-action-icon" src={iconeCriarSenha} title="Cadastrar Senha" onClick={(event) => this.criarSenha(rowData)} /> : null}
													{this.securityPessoa.edit && this.securityCredencial.create ? <img alt="" className="ui-action-icon" src={iconeCredenciais} title="Cadastrar Digital" onClick={(event) => this.cadastrarDigital(rowData)} /> : null}
													{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Pessoa" onClick={(event) => this.removerPessoa(rowData)} /> : null}
												</div>
											);
										}} />
									</DataTable>
								</div>
								{
									mostrarVisitados
									? (
										<div>
											<SuggestionBox style={{position: "relative"}} appendTo={document.body} label="Visitados" grid={12} field="nome" completeMethod={this.listarPessoas} suggestions={this.state.pessoas} onChange={(e) => this.setState({visitado: e.value})} onSelect={this.adicionarVisitado} value={this.state.visitado} />
											<div className="ui-g-12">
												<DataTable emptyMessage="Nenhuma pessoa adicionada" rows={10} paginator value={this.state.agenda.visitados} children={this.visitadosColumns} />
											</div>
										</div>
									)
									: null
								}
							</PanelContent>
						</TabPanel>
						<TabPanel
							disabled={!this.securityDocumento?.view}
							header="Documentos"
						>
							<PanelContent>
								<DataTable
									emptyMessage="Nenhum documento cadastrado"
									value={this.state.agenda.documentos}
									rows={GUI.defaultRows}
									rowsPerPageOptions={GUI.rowsPerPage}
									paginator
									paginatorLeft={this.securityDocumento?.create ? <Button onClick={this.editarDocumento} icon="fa-plus" className="ui-tzm-pagleft-icon" /> : null}
									children={this.detailsDocumentos}
								/>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Veículos">
							<PanelContent>
								<SuggestionBox itemTemplate={veiculoTemplate} style={{position: "relative"}} appendTo={document.body} label="Veículos" grid={8} field="placa" completeMethod={this.listarVeiculos} suggestions={this.state.veiculos} onChange={(e) => this.setState({veiculo: e.value})} onSelect={this.adicionarVeiculo} value={this.state.veiculo} />
								<SelectButton options={MAgenda.OptionsOperacaoNoIcon} grid={4} label="Operação" name="operacao" onChange={this.handleUpdate} value={this.state.agenda.operacao}/>
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhum veículo adicionado" paginatorLeft={this.veiculoFooter} rows={10} paginator value={this.state.agenda.veiculos} children={this.veiculoColumns} />
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Modelos de Acesso">
							<PanelContent>
								<SuggestionBox appendTo={document.body} grid={12} label="Modelos de Acesso" field="descricao" completeMethod={this.listarModelosAcesso} suggestions={this.state.modelosAcesso} onChange={(e) => this.setState({modeloAcesso: e.value})} onSelect={this.adicionarModeloAcesso} value={this.state.modeloAcesso} />
								<div className="ui-g-12">
									<DataTable paginator paginatorLeft={this.modeloAcessoFooter} emptyMessage="Nenhum modelo de acesso adicionado" rows={10} value={this.state.agenda.modelosAcesso} children={this.modeloAcessoColumns} />
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.agenda.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/agendas", this.state.agenda)} /> : null}
					<Action disabled={!(this.security.edit || (this.security.create && !this.state.agenda.id)) || this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarAgenda} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarAgenda} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React from 'react';
import {Tooltip} from 'primereact/components/tooltip/Tooltip';

export class Label extends React.Component {

	constructor() {
		super();
		this.state = {
			uid: "id_" + Math.round(Math.random() * 100000000)
		}
	}

	render() {
		return (
			<label className="ui-tzm-label" {...this.props}>
				{this.props.children}
				{this.props.help ? <i id={this.state.uid} className="fa fa-question-circle ui-tzm-label-help" /> : null}
				{this.props.help ? <Tooltip for={`#${this.state.uid}`} title={this.props.help} /> : null}
			</label>
		);
	}

}

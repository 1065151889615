import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpGet, httpPost} from "../../utils/Request";

export class UnidadeService extends EntidadeNextGenService {

	constructor() {
		super("/unidades");
	}

	async listarDTO(params) {
		return await httpGet(`/unidades/listar-dto`,params);
	}

	inicializar(params) {
		params.blocos = Number(params.blocos);
		params.unidades = Number(params.unidades);
		return httpPost("/unidades/inicializar", params).then((response) => response);
	}

	async buscarUnidadePorEtiqueta(etiqueta) {
		return await httpGet(`/unidades/etiqueta/${etiqueta.id}`);
	}

	async portalCondomino(unidade) {
		return await httpPost(`/portal/unidades/ativar`, unidade);		
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ColorPanel} from "../commons/ColorPanel";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {PatternPanel} from "../commons/PatternPanel";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {IconPanel} from "../commons/IconPanel";

export class EditarEtiqueta extends React.Component {

	state = {
		etiqueta: Object.assign(MEtiqueta.Modelo(), this.props.etiqueta),
		visible: true,
		lockSave: false
	};

	etiquetaService = new EtiquetaService();
	security = UserData.security("ETQ", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarEtiqueta = () => {
		if (this.security.edit) {
			let etiqueta = MEtiqueta.cleanupBeforeSave(Object.assign({}, this.state.etiqueta));
			let messages = MEtiqueta.validarParaSalvar(etiqueta);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.etiquetaService.salvar(etiqueta).then((etiqueta) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(etiqueta);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleUpdate = (event) => {
		let etiqueta = this.state.etiqueta;
		etiqueta[event.name] = event.value;
		this.setState({etiqueta});
	}

	render() {
		return (
			<Dialog modal header="Etiqueta" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={6} label="Descrição" required value={this.state.etiqueta.descricao} name="descricao" onChange={this.handleUpdate} />
					<MultiCombobox grid={6} label="Opções" appendTo={document.body} name="flags" defaultLabel="Nenhuma" onChange={this.handleUpdate} value={this.state.etiqueta.flags} options={MEtiqueta.Flags} />
					<ColorPanel grid={12} label="Cor do Texto" name="fonte" value={this.state.etiqueta.fonte} onChange={this.handleUpdate} />
					<ColorPanel effect={`ui-pattern-a${this.state.etiqueta.efeito}`} grid={12} label="Cor da Etiqueta" name="fundo" value={this.state.etiqueta.fundo} onChange={this.handleUpdate} />
					<PatternPanel grid={12} label="Padrão de Fundo" name="padrao" value={this.state.etiqueta.padrao} onChange={this.handleUpdate} />
					<IconPanel grid={12} label="Ícone" name="icone" value={this.state.etiqueta.icone} onChange={this.handleUpdate}/>
					<Combobox grid={12} label="Efeitos" appendTo={document.body} name="efeito" onChange={this.handleUpdate} value={this.state.etiqueta.efeito} options={MEtiqueta.Efeitos} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.etiqueta.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/etiquetas", this.state.etiqueta)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.etiqueta.id)) || this.state.lockSave} onClick={this.salvarEtiqueta} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarEtiqueta} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

export class Validator {

	static isEmpty(text) {
		return text == null || text.trim().length === 0;
	}

	static isNotZero(text){
		return parseInt(text,10) === 0;
	}

	static isCPF(cpf) {
		if (!cpf)
			return false;
		let soma = 0;
		let resto;
		cpf = cpf.replace(/[^0-9]/g, "");
		if (cpf.length !== 11) return false;
		if (this.INVALID_CPFS.includes(cpf)) return false;
		for (let i = 1; i <= 9; ++i) soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
		resto = (soma * 10) % 11;
		if ((resto === 10) || (resto === 11))  resto = 0;
		if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;
		soma = 0;
		for (let i = 1; i <= 10; ++i) soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
		resto = (soma * 10) % 11;
		if ((resto === 10) || (resto === 11))  resto = 0;
		if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;
		return true;
	}

	static isCNH(cnh) {
		cnh = cnh.replace(/[^0-9]/g, "");
		if (cnh.length !== 11) {
			return false;
		}
		let v = 0, j = 9;
		for (let i = 0; i < 9; ++i, --j) {
			v += Number(cnh[i]) * j;
		}
		let dsc = 0, vl1 = v % 11;
		if (vl1 >= 10) {
			vl1 = 0;
			dsc = 2;
		}
		v = 0;
		j = 1;
		for (let i = 0; i < 9; ++i, ++j) {
			v += Number(cnh[i]) * j;
		}
		let x = v % 11;
		let vl2 = (x >= 10) ? 0 : x - dsc;
		return (vl1.toString() + vl2.toString()) === cnh.substring(cnh.length - 2);
	}

	static INVALID_CPFS = [
		"12345678909",
		"00000000000",
		"11111111111",
		"22222222222",
		"33333333333",
		"44444444444",
		"55555555555",
		"66666666666",
		"77777777777",
		"88888888888",
		"99999999999"
	];

	static INVALID_CNPJS = [
		"00000000000000",
        "11111111111111",
        "22222222222222",
        "33333333333333",
        "44444444444444",
        "55555555555555",
        "66666666666666",
        "77777777777777",
        "88888888888888",
        "99999999999999"
	];

	static isCNPJ(cnpj) {
		cnpj = cnpj.replace(/[^\d]+/g,'');
    	if (cnpj === "") return false;
    	if (cnpj.length !== 14) return false;
		if (this.INVALID_CNPJS.includes(cnpj)) return false;
    	let tamanho = cnpj.length - 2
    	let numeros = cnpj.substring(0, tamanho);
    	let digitos = cnpj.substring(tamanho);
    	let soma = 0;
    	let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
    	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    	if (resultado !== Number(digitos.charAt(0))) return false;
    	tamanho = tamanho + 1;
    	numeros = cnpj.substring(0,tamanho);
    	soma = 0;
    	pos = tamanho - 7;
    	for (let i = tamanho; i >= 1; i--) {
      		soma += numeros.charAt(tamanho - i) * pos--;
      		if (pos < 2) pos = 9;
    	}
    	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    	if (resultado !== Number(digitos.charAt(1))) return false;
    	return true;
	}

	static NISMTAB = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

	static isNIS(nis) {
		let dv = 0;
		if (this.isEmpty(nis) || nis.length !== 11) return false;
		for(let i = 0; i <= 9; ++i) {
			dv += nis.charAt(i) * (this.NISMTAB[i]);
		}
		dv = 11 - (dv % 11);
		if (dv > 9) dv = 0;
		return Number(nis.charAt(nis.length - 1)) === dv;
	}

	static isEntidadeDependente(object) {
		if (object == null) return false;
		return object === Object(object);
	}

	static isEntidade(object) {
		if (object == null) return false;
		if (object !== Object(object)) return false;
		return object.id !== null && !isNaN(Number(object.id));
	}

	static isTimestamp(object) {
		return /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/g.test(object);
	}

	static isDate(object) {
		return /[0-9]{4}-[0-9]{2}-[0-9]{2}/g.test(object);
	}

	static isTime(object) {
		return /[0-9]{2}:[0-9]{2}:[0-9]{2}/g.test(object);
	}

	static isEmail(object) {
		return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(object);
	}

	static isNumeric(str){
		return /^\d+$/.test(str);
	}

}

import React from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {TextField} from "../commons/TextField";
import cronstrue from "cronstrue/i18n";

const cronSamples = [
	"0 0/30 * * * ?",
	"0 30 18 * * ?",
	"0 0 12 1 * ?",
	"0 0 10 1 1/3 ?",
	"0 0 9 * * MON-FRI",
	"0 0 12 1 6 *",
	"0 0 8-18 * * ?",
	"0 0 8 L * ?",
	"0 0 12,18 * * MON-FRI",
	"0 0 12 1 1,7 ?"
];

export class HelpCronExpression extends React.Component {

	state = {
		visible: true
	};

	handleClose = () => this.setState({visible: false});

	handleSelect = value => {
		if (this.props.onModalClose) {
			this.props.onModalClose(value);
		}
		this.handleClose();
	}

	printCronSample = value => {
		return (
			<>
				<div className="ui-g-2">
					<TextField style={{cursor: "pointer"}} onChange={e => e} onClick={() => this.handleSelect(value)}
					           value={value}/>
				</div>
				<div className="ui-g-4">{cronstrue.toString(value, {
					locale: "pt_BR",
					verbose: true,
					use24HourTimeFormat: true
				})}</div>
			</>
		);
	}

	render() {
		return (
			<Dialog header="Como Preencher a Frequência" onHide={this.handleClose} modal visible={this.state.visible} style={{width: "1100px"}}>
				<DialogContent>
					<div className="ui-g-12">
						<b>Exemplo de como preencher a expressão temporal</b><br/>
						<table className="cron-sample-table">
							<tr>
								<td>0</td><td>0</td><td>9</td><td>1</td><td>1/3</td><td>?</td>
							</tr>
							<tr>
								<td>Segundo</td><td>Minuto</td><td>Hora</td><td>Dia</td><td>Mês</td><td>Ano/Outros</td>
							</tr>
						</table>
						<i>O exemplo acima significa "{cronstrue.toString("0 0 9 1 1/3 ?", {locale: "pt_BR", verbose: true, use24HourTimeFormat: true})}</i>"<br/>
						<i>Os campos são separados por um espaço simples</i><br/><br/>
						<b>Outros Exemplos:</b><br/>
						<i>(Você pode clicar sobre uma das opções abaixo para selecioná-la)</i><br/>
					</div>
					<div className="ui-g-12">
						<div className="ui-g">{cronSamples.map(this.printCronSample)}</div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa fa-times" onClick={this.handleClose}/>
				</DialogFooter>
			</Dialog>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {SelectLocalidade} from "../../select/SelectLocalidade";
import {MLugar} from "../../utils/models/MLugar";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {LugarService} from "../servicos/LugarService";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Label} from "../commons/Label";
import {Button} from "primereact/components/button/Button";
import {MLugarTarifa} from "../../utils/models/MLugarTarifa";
import {GUI} from "../../utils/Constants";
import {SuggestionBox} from "../commons/SuggestionBox";
import {EtiquetaService} from "../servicos/EtiquetaService";
import iconeExcluir from "../../media/icones/excluir.png";

export class EditarLugar extends React.Component {

	state = {
		lugar: Object.assign(MLugar.Modelo(), this.props.lugar),
		visible: true,
		lockSave: false
	};

	lugarService = new LugarService();
	etiquetaService = new EtiquetaService();
	localidadeService = new LocalidadeService();
	security = UserData.security("LUG", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarLugar = () => {
		if (this.security.edit) {
			let lugar = MLugar.cleanupBeforeSave(Object.assign({}, this.state.lugar));
			let messages = MLugar.validarParaSalvar(lugar);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.lugarService.salvar(lugar).then((lugar) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(lugar);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let lugar = this.state.lugar;
		lugar[event.name] = event.value;
		this.setState({lugar});
	}

	listarLocalidades = (event) => {
		this.localidadeService.listar(`search=nome~${event.query}`, {page: 0, size: 10, sort: "nome"}).then(localidades => this.setState({localidades}));
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page: 0, size: 10, sort: "descricao"}).then((etiquetas) => this.setState({etiquetas}));
	}

	columnsTarifa = [
		<Column header="Etiqueta" key={0} body={(e, i) => <SuggestionBox placeholder="<Todos>" field="descricao" index={i.rowIndex} name="etiqueta" style={{position: "relative"}} appendTo={document.body} completeMethod={this.listarEtiquetas} suggestions={this.state.etiquetas} onChange={this.handleChangeTarifa} onSelect={this.handleChangeTarifa} value={e.etiqueta} />} />,
		<Column header="Valor" style={{width: "8em"}} key={1} body={(e, i) => <TextField index={i.rowIndex} onChange={this.handleChangeTarifa} unit="Cr" value={e.valor} name="valor" />} />,
		<Column header="Ações" key={2} style={{width: "5em", textAlign: "center"}} body={(e, i) => {
			return (
				<div>
					<img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Tarifa" onClick={() => this.removerTarifa(i.rowIndex)} />
				</div>
			);
		}} />
	];

	removerTarifa = (index) => {
		const {lugar} = this.state;
		lugar.tarifas.splice(index, 1);
		this.setState({lugar});
	}

	handleChangeTarifa = (event) => {
		const {lugar} = this.state;
		lugar.tarifas[event.index][event.name] = event.value;
		this.setState({lugar});
	}

	adicionarTarifa = () => {
		const {lugar} = this.state;
		lugar.tarifas.push(MLugarTarifa.Modelo());
		this.setState({lugar});
	}

	render() {
		return (
			<Dialog modal header="Lugar" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={8} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.lugar.descricao} />
					<Combobox grid={4} label="Tipo" appendTo={document.body} name="tipo" options={MLugar.Tipos} onChange={this.handleChange} value={this.state.lugar.tipo} />
					<TextField grid={3} label="Lotaçao" type="number" name="lotacao" onChange={this.handleChange} value={this.state.lugar.lotacao} />
					<SelectLocalidade grid={9} label="Localidade" appendTo={document.body} name="localidade" onChange={this.handleChange} value={this.state.lugar.localidade} />
					{
						this.state.lugar.tipo === "TARIFADO"
						? (
							<div className="ui-g-12">
								<Label>Tarifas</Label>
								<DataTable rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator paginatorLeft={
			                        <div style={{textAlign: "left"}}>
				                        <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={this.adicionarTarifa} />
			                        </div>
		                        } emptyMessage="Nenhuma tarifa definida" value={this.state.lugar.tarifas} children={this.columnsTarifa} />
							</div>
						) : null
					}
					{UserData.hasRole("XX0C") ? <TextField grid={12} label="Chave de Integração" name="chaveIntegracao" onChange={this.handleChange} value={this.state.lugar.chaveIntegracao} /> : null}
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.lugar.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/lugares", this.state.lugar)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.lugar.id)) || this.state.lockSave} onClick={this.salvarLugar} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarLugar} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React from "react";
import iconeCameras from "../../media/icones/cameras.png";
import iconeControladores from "../../media/icones/controladores.png";
import iconeCredenciais from "../../media/icones/credenciais.png";
import iconeEmpresas from "../../media/icones/empresas.png";
import iconeEquipamentos from "../../media/icones/equipamentos.png";
import iconeEspacos from "../../media/icones/espacos.png";
import iconeFeriados from "../../media/icones/feriados.png";
import iconeLugares from "../../media/icones/lugares.png";
import iconeModelosAcesso from "../../media/icones/modelos-acesso.png";
import iconePessoaDentro from "../../media/icones/pessoa-dentro.png";
import iconePessoaFora from "../../media/icones/pessoa-fora.png";
import iconePerfis from "../../media/icones/perfis.png";
import iconeTelefone from "../../media/icones/telefone.png";
import iconeUnidades from "../../media/icones/unidades.png";
import iconeVeiculos from "../../media/icones/veiculos.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {EditarCamera} from "../Camera/EditarCamera";
import {EditarControlador} from "../Controlador/EditarControlador";
import {EditarCredencial} from "../Credencial/EditarCredencial";
import {EditarEquipamento} from "../Equipamento/EditarEquipamento";
import {EditarEspaco} from "../Espaco/EditarEspaco";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EditarFeriado} from "../Feriado/EditarFeriado";
import {EditarLugar} from "../Lugar/EditarLugar";
import {EditarModeloAcesso} from "../ModeloAcesso/EditarModeloAcesso";
import {EditarPerfil} from "../Perfil/EditarPerfil";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {EditarPessoaJuridica} from "../PessoaJuridica/EditarPessoaJuridica";
import {CameraService} from "../servicos/CameraService";
import {ControladorService} from "../servicos/ControladorService";
import {CredencialService} from "../servicos/CredencialService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {EspacoService} from "../servicos/EspacoService";
import {FeriadoService} from "../servicos/FeriadoService";
import {LugarService} from "../servicos/LugarService";
import {ModeloAcessoService} from "../servicos/ModeloAcessoService";
import {PerfilService} from "../servicos/PerfilService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {UnidadeService} from "../servicos/UnidadeService";
import {VeiculoService} from "../servicos/VeiculoService";
import {EditarUnidade} from "../Unidade/EditarUnidade";
import {EditarVeiculo} from "../Veiculo/EditarVeiculo";
import {ModalRealm} from "./ModalRealm";
import {SuggestionBox} from "./SuggestionBox";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";
import moment from "moment";
import {isDentro, isDentroOuGaragem} from "../../utils/PessoaFisicaUtils";

export class QuickSearch extends React.Component {

	constructor() {
		super();
		this.state = {
			entidade: "",
			entidades: []
		};
		this.pessoaFisicaService = new PessoaFisicaService();
		this.equipamentoService = new EquipamentoService();
		this.modeloAcessoService = new ModeloAcessoService();
		this.unidadeService = new UnidadeService();
		this.credencialService = new CredencialService();
		this.pessoaJuridicaService = new PessoaJuridicaService();
		this.feriadoService = new FeriadoService();
		this.lugarService = new LugarService();
		this.espacoService = new EspacoService();
		this.veiculoService = new VeiculoService();
		this.controladorService = new ControladorService();
		this.cameraService = new CameraService();
		this.perfilService = new PerfilService();
	}

	listarPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=status:ATIVADO;nome,rg,cpf,passaporte~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "nome"}).then((pessoas) => {
			this.credencialService.listarAlt({query: event.query, status: "TRANSFERIVEL", page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((credenciais) => {
				this.equipamentoService.listar(`search=status:ATIVADO;descricao,ip~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((equipamentos) => {
					this.modeloAcessoService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((modelosAcesso) => {
						this.unidadeService.listar(`search=status:ATIVADO&etiqueta=descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((unidades) => {
							this.feriadoService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((feriados) => {
								this.pessoaJuridicaService.listar(`search=status:ATIVADO;nome,cnpj~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "nome"}).then((empresas) => {
									this.cameraService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((cameras) => {
										this.lugarService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((lugares) => {
											this.espacoService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((espacos) => {
												this.controladorService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((controladores) => {
													this.veiculoService.listar(`search=status:ATIVADO;placa~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "placa"}).then((veiculos) => {
														this.perfilService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: GUI.maxSuggestion / 2, sort: "descricao"}).then((perfis) => {
															pessoas.forEach(entidade => entidade.class = "PESSOA");
															credenciais.forEach(entidade => entidade.class = "CREDENCIAL");
															modelosAcesso.forEach(entidade => entidade.class = "MODELO_ACESSO");
															equipamentos.forEach(entidade => entidade.class = "EQUIPAMENTO");
															unidades.forEach(entidade => entidade.class = "UNIDADE");
															feriados.forEach(entidade => entidade.class = "FERIADO");
															espacos.forEach(entidade => entidade.class = "ESPACO");
															cameras.forEach(entidade => entidade.class = "CAMERA");
															controladores.forEach(entidade => entidade.class = "CONTROLADOR");
															empresas.forEach(entidade => entidade.class = "EMPRESA");
															lugares.forEach(entidade => entidade.class = "LUGAR");
															veiculos.forEach(entidade => entidade.class = "VEICULO");
															perfis.forEach(entidade => entidade.class = "PERFIL");
															let entidades = [];
															if (UserData.edit("PES")) entidades = entidades.concat(pessoas);
															if (UserData.edit("EQP")) entidades = entidades.concat(equipamentos);
															if (UserData.edit("MAC")) entidades = entidades.concat(modelosAcesso);
															if (UserData.edit("CRD")) entidades = entidades.concat(credenciais);
															if (UserData.edit("UNI")) entidades = entidades.concat(unidades);
															if (UserData.edit("FER")) entidades = entidades.concat(feriados);
															if (UserData.edit("ESP")) entidades = entidades.concat(espacos);
															if (UserData.edit("CAM")) entidades = entidades.concat(cameras);
															if (UserData.edit("CTR")) entidades = entidades.concat(controladores);
															if (UserData.edit("EMP")) entidades = entidades.concat(empresas);
															if (UserData.edit("LUG")) entidades = entidades.concat(lugares);
															if (UserData.edit("VEI")) entidades = entidades.concat(veiculos);
															if (UserData.edit("PRF")) entidades = entidades.concat(perfis);
															this.setState({entidades});
														});
													});
												});
											});
										});
									});
								});
							});
						});
					});
				});
			});
		});
	}

	selecionarPessoa = (event) => {
		if (Validator.isEntidade(event.value)) {
			switch (event.value.class) {
				case "PESSOA":
					this.pessoaFisicaService.buscar(event.value).then((pessoa) => {
						ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={pessoa} />);
					});
					break;
				case "CREDENCIAL":
					this.credencialService.buscar(event.value).then((credencial) => {
						ModalRealm.showDialog(<EditarCredencial key={Math.random()} credencial={credencial} />);
					});
					break;
				case "EQUIPAMENTO":
					this.equipamentoService.buscar(event.value).then((equipamento) => {
						ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={equipamento} />);
					});
					break;
				case "MODELO_ACESSO":
					this.modeloAcessoService.buscar(event.value).then((modeloAcesso) => {
						ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={modeloAcesso} />);
					});
					break;
				case "UNIDADE":
					this.unidadeService.buscar(event.value).then((unidade) => {
						ModalRealm.showDialog(<EditarUnidade key={Math.random()} unidade={unidade} />);
					});
					break;

				case "VEICULO":
					this.veiculoService.buscar(event.value).then((veiculo) => {
						ModalRealm.showDialog(<EditarVeiculo key={Math.random()} veiculo={veiculo} />);
					});
					break;
				case "CONTROLADOR":
					this.controladorService.buscar(event.value).then((controlador) => {
						ModalRealm.showDialog(<EditarControlador key={Math.random()} controlador={controlador} />);
					});
					break;
				case "CAMERA":
					this.cameraService.buscar(event.value).then((camera) => {
						ModalRealm.showDialog(<EditarCamera key={Math.random()} camera={camera} />);
					});
					break;
				case "FERIADO":
					this.feriadoService.buscar(event.value).then((feriado) => {
						ModalRealm.showDialog(<EditarFeriado key={Math.random()} feriado={feriado} />);
					});
					break;
				case "EMPRESA":
					this.pessoaJuridicaService.buscar(event.value).then((pessoa) => {
						ModalRealm.showDialog(<EditarPessoaJuridica key={Math.random()} pessoa={pessoa} />);
					});
					break;
				case "LUGAR":
					this.lugarService.buscar(event.value).then((lugar) => {
						ModalRealm.showDialog(<EditarLugar key={Math.random()} lugar={lugar} />);
					});
					break;
				case "ESPACO":
					this.espacoService.buscar(event.value).then((espaco) => {
						ModalRealm.showDialog(<EditarEspaco key={Math.random()} espaco={espaco} />);
					});
					break;
				case "PERFIL":
					this.perfilService.buscar(event.value).then((perfil) => {
						ModalRealm.showDialog(<EditarPerfil key={Math.random()} perfil={perfil} />);
					});
					break;
				default:
			}
			this.setState({entidade: ""});
		}
	}

	pessoaTemplate = (entidade) => {
		switch (entidade.class) {
			case "PESSOA": {
				let fones = [];
				const documentos = [];
				if (entidade.rg) documentos.push(`RG: ${entidade.rg}`);
				if (entidade.cpf) documentos.push(`CPF: ${entidade.cpf}`);
				if (entidade.passaporte) documentos.push(`Documento: ${entidade.passaporte}`);
				if (entidade.nis) documentos.push(`NIS: ${entidade.nis}`);
				if (!Validator.isEmpty(entidade.telefone)) fones.push(entidade.telefone);
				if (!Validator.isEmpty(entidade.celular)) fones.push(entidade.celular);
				fones = fones.map((fone) => <span key={Math.random()} style={{marginRight: "4px"}}><img src={iconeTelefone} alt="" />{fone}</span>);
				let etiquetas = entidade.etiquetas ? entidade.etiquetas.map(etiqueta => <EtiquetaChip key={etiqueta.id} etiqueta={etiqueta} />) : [];
				const foto = entidade.fotos?.filter(isFinalidadeIdentificacao).sort(byRegistroDesc)[0];
				return (
					<table className="ui-portaria-frame">
						<tbody>
							<tr>
								<td>
									{foto ? <img className="quick-search-foto" src={toSrc(foto, true)} alt="" /> : <span className="logs-acesso-v2-foto sem-foto quick-search"/>}
									<div style={{padding: "3px 0 0 3px"}}>
										<div>
											<img src={isDentro(entidade) ? iconePessoaDentro : iconePessoaFora} alt=""/>
											{`${entidade.nome} `}
											({isDentroOuGaragem(entidade) ? `em ${entidade.lugar.descricao}` : "Fora"}{entidade.ultimaPassagem ? ` desde ${moment(entidade.ultimaPassagem).format("DD/MM/YY HH:mm")}` : ""})
										</div>
										<div>
											<span style={{display: "inline-block", paddingLeft: "1.75em"}}>{documentos.length ? ` ${documentos.join(", ")}` : ""}</span>
										</div>
										<div>{fones}</div>
										<div>{etiquetas}</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				);
			}
			case "CREDENCIAL":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeCredenciais} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "EQUIPAMENTO":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeEquipamentos} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "MODELO_ACESSO":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeModelosAcesso} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "UNIDADE":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeUnidades} alt="" />}{entidade.etiqueta.descricao}</div></td></tr></tbody></table>;
			case "EMPRESA": {
				let fones = [];
				if (!Validator.isEmpty(entidade.telefone)) fones.push(entidade.telefone);
				if (!Validator.isEmpty(entidade.celular)) fones.push(entidade.celular);
				fones = fones.map((fone) => <span key={fone} style={{marginRight: "4px"}}><img src={iconeTelefone} alt="" />{fone}</span>);
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeEmpresas} alt="" />}{entidade.nome}<span style={{display: "inline-block", width: "10px"}} />{fones}</div></td></tr></tbody></table>;
			}	
			case "VEICULO":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeVeiculos} alt="" />}{`(${entidade.fabricante} - ${entidade.modelo}) ${entidade.placa}`}</div></td></tr></tbody></table>;
			case "LUGAR":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeLugares} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "CAMERA":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeCameras} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "CONTROLADOR":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeControladores} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "FERIADO":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeFeriados} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "ESPACO":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconeEspacos} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			case "PERFIL":
				return <table className="ui-portaria-frame"><tbody><tr><td className="ui-portaria-pessoa-frame"><div className="ui-portaria-pessoa-nome">{<img src={iconePerfis} alt="" />}{entidade.descricao}</div></td></tr></tbody></table>;
			default:
				return null;
		}
	}

	handleChange = (event) => this.setState({[event.name]: event.value});

	render() {
		return (
			<div className="ui-neosidebar-card-text" style={{color: "#000000", padding: 0, width: "calc(100% - 416px)"}}>
				<SuggestionBox validate={false} id="ui-quicksearch" placeholder="Pesquisa rápida" itemTemplate={this.pessoaTemplate} field="nome" suggestions={this.state.entidades} value={this.state.entidade} onChange={this.handleChange} name="entidade" completeMethod={this.listarPessoas} onSelect={this.selecionarPessoa} />
			</div>
		);
	}

}

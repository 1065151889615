import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";
import React from "react";

export class MEquipamento {

	static Modelo() {
		return {
			status: "ATIVADO",
			descricao: "",
			familia: "NENHUMA",
			ip: "",
			serial: "",
			etiquetas: [],
			antidupla: 0,
			limiteObstrucao: 0,
			leitores: [],
			flags: [],
			categoria: "ACESSO",
			dispositivos: [],
			botoeiras: [],
			mestre: null,
			fusoHorario: 0
		};
	}

	static ModeloPC() {
		return {
			status: "ATIVADO",
			descricao: "",
			familia: "NENHUMA",
			ip: "",
			serial: "",
			etiquetas: [],
			antidupla: 0,
			categoria: "ACESSO",
			limiteObstrucao: 0,
			flags: [],
			leitores: [
				{...MLeitor.Modelo(), auxiliares: [{...MAuxiliar.Modelo(), atuadores: ["SOLENOID1", "SOLENOID2"]}]}
			],
			dispositivos: [
				{...MDispositivo.Modelo(), sensorizacao: "GIRO", atuador: "SOLENOID1"},
				{...MDispositivo.Modelo(), sensorizacao: "GIRO", atuador: "SOLENOID2"}
			]
		};
	}

	static Atuadores = [
		{label: "Nenhum", value: "NENHUM"},
		{label: "Solenóide 1", value: "SOLENOID1"},
		{label: "Solenóide 2", value: "SOLENOID2"},
		{label: "Solenóide 3", value: "SOLENOID3"},
		{label: "Saída Digital 1", value: "OUTPUT1"},
		{label: "Saída Digital 2", value: "OUTPUT2"},
		{label: "Saída Digital 3", value: "OUTPUT3"},
		{label: "Saída Digital 4", value: "OUTPUT4"},
		{label: "Saída Digital 5", value: "OUTPUT5"},
		{label: "Saída Digital 6", value: "OUTPUT6"},
		{label: "Saída Digital 7", value: "OUTPUT7"},
		{label: "Saída Digital 8", value: "OUTPUT8"},
		{label: "Relé 1", value: "RELAY1"},
		{label: "Relé 2", value: "RELAY2"},
		{label: "Origem", value: "ORIGEM"}
	];

	static Familias = [
		{label: "ControlID Fit", value: "CONTROLID_FIT"},
		{label: "ControlID Flex", value: "CONTROLID_FLEX"},
		{label: "ControlID Block", value: "CONTROLID_BLOCK"},
		{label: "ControlID Block Next", value: "CONTROLID_BLOCK_NEXT"},
		{label: "ControlID Access", value: "CONTROLID_ACCESS"},
		{label: "ControlID Access Pro", value: "CONTROLID_ACCESS_PRO"},
		{label: "ControlID UHF", value: "CONTROLID_UHF"},
		{label: "ControlID Face", value: "CONTROLID_FACE"},
		{label: "Trix", value: "TRIX"},
		{label: "Neokoros", value: "NEOKOROS"},
		{label: "Neokoros GAM", value: "NEOKOROS_GAM"},
		{label: "Suprema", value: "SUPREMA"},
		{label: "Digicon", value: "DIGICON"},
		{label: "Linear-HCS Guarita", value: "LINEAR"},
		{label: "TopData", value: "TOPDATA"},
		{label: "Linear-HCS LN30ID", value: "LN30ID"},
		{label: "Hikvision LPR", value: "HIKVISION_LPR"},
		{label: "Hikvision FRT", value: "HIKVISION_FRT"},
		{label: "Intelbras FRT", value: "INTELBRAS_FRT"},
		{label: "Hikvision TCT", value: "HIKVISION_TCT"},
		{label: "ControlID Class", value: "CONTROLID_CLASS"},
		{label: "TZM Catraca", value: "TZM_CATRACA"},
		{label: "Tablet para Formulário", value: "FORMULARIO"},
		{label: "Câmera Dahua", value: "DAHUA"},
	].sort((a, b) => a.label.localeCompare(b.label));

	static Status = [
		{label: "Ativado", value: "ATIVADO"},
		{label: "Desativado", value: "EXCLUIDO"}
	];

	static Categorias = [
		{label: "Acesso", value: "ACESSO"},
		{label: "Ponto", value: "PONTO"}
	];

	static MapFamilias = {
		"NENHUMA": "Nenhuma",
		"CONTROLID_ACCESS": "ControlID Access",
		"CONTROLID_ACCESS_PRO": "ControlID Access Pro",
		"CONTROLID_FIT": "ControlID Fit",
		"CONTROLID_FLEX": "ControlID Flex",
		"CONTROLID_BLOCK": "ControlID Block",
		"CONTROLID_BLOCK_NEXT": "ControlID Block Next",
		"CONTROLID_CLASS": "ControlID Class",
		"CONTROLID_UHF": "ControlID UHF",
		"CONTROLID_FACE": "ControlID Face",
		"TZM_CATRACA": "TZM Catraca",
		"TRIX": "Trix",
		"NEOKOROS": "Neokoros",
		"NEOKOROS_GAM": "Neokoros GAM",
		"SUPREMA": "Suprema",
		"DIGICON": "Digicon",
		"LINEAR": "Linear-HCS Guarita",
		"TOPDATA": "TopData",
		"LN30ID": "Linear-HCS LN30ID",
		"HIKVISION_LPR": "Hikvision LPR",
		"HIKVISION_FRT": "Hikvision FRT",
		"INTELBRAS_FRT": "Intelbras FRT",
		"HIKVISION_TCT": "Hikvision TCT",
		"DAHUA": "Câmera Dahua"
	};

	static cleanupBeforeSave(equipamento) {
		if (equipamento.controlador != null && !Validator.isEntidade(equipamento.controlador)) equipamento.controlador = null;
		if (equipamento.etiquetas != null) equipamento.etiquetas = equipamento.etiquetas.filter(e => Validator.isEntidade(e));
		if (equipamento.leitores != null) equipamento.leitores = equipamento.leitores.filter(l => Validator.isEntidadeDependente(l));
		if (equipamento.dispositivos != null) equipamento.dispositivos = equipamento.dispositivos.filter(d => Validator.isEntidadeDependente(d));
		if (equipamento.leitores != null) equipamento.leitores.forEach(leitor => {
			Object.keys(leitor).forEach(property => leitor[property] === "" && delete leitor[property]);
			if (leitor.auxiliares != null) leitor.auxiliares.forEach(auxiliar => {
				Object.keys(auxiliar).forEach(property => auxiliar[property] === "" && delete auxiliar[property]);
			});
		});
		if (equipamento.dispositivos != null) equipamento.dispositivos.forEach(dispositivo => {
			Object.keys(dispositivo).forEach(property => dispositivo[property] === "" && delete dispositivo[property]);
		});
		return MEntidade.cleanupBeforeSave(equipamento);
	}

	static validarParaSalvar(equipamento) {
		let messages = [];
		if (Validator.isEmpty(equipamento.descricao)) messages.push("A descrição é obrigatória");
		if (Validator.isEmpty(equipamento.ip)) messages.push("O IP é obrigatório");
		if (equipamento.categoria === "ACESSO") {
			if (!equipamento.mestre && equipamento.familia !== "FORMULARIO") {
				if (!equipamento.leitores || !equipamento.leitores.length) messages.push("O equipamento deve ter ao menos um leitor");
				if (!equipamento.dispositivos || !equipamento.dispositivos.length) messages.push("O equipamento deve ter ao menos um dispositivo");
			}
			if (equipamento.controlID) {
				if (Validator.isEmpty(equipamento.login)) messages.push("O login é obrigatório");
				if (Validator.isEmpty(equipamento.senha)) messages.push("A senha é obrigatória");
				if (Validator.isEmpty(equipamento.serial)) messages.push("O número de série é obrigatório");
				if (!Validator.isEmpty(equipamento.senhaMestre) && !Validator.isNumeric(equipamento.senhaMestre)) messages.push("A senha mestre deve ser numérica");
			}
		}
		if (equipamento.categoria === "PONTO") {
			if (Validator.isEmpty(equipamento.serial)) messages.push("O número de série é obrigatório");
			if (!Validator.isEntidade(equipamento.empresa)) messages.push("A empresa é obrigatória");
		}
		return messages;
	}

}

export class MLeitor {

	static Modelo() {
		return {
			forma: "MANUAL",
			porta: 0,
			auxiliares: [],
			chave: Math.random(),
			flags: []
		};
	}

	static Flags = [
		{label: "Coletor", value: "COLETOR"},
		{label: "ByteFlip", value: "FLIP_BYTES"},
		{label: "Wiegand", value: "WIEGAND"},
		{label: "Multi-Leitor", value: "MULTI"}
	];

	static MapFlags = {
		"COLETOR": "Coletor",
		"FLIP_BYTES": "ByteFlip",
		"WIEGAND": "Wiegand",
		"MULTI": "Multi-Leitor"
	};

}

export class MAuxiliar {

	static Modelo() {
		return {
			auxiliar: -1,
			atuadores: [],
			chave: Math.random()
		};
	}

}

export class MBotoeira {

	static Modelo() {
		return {
			finalidade: "NENHUMA",
			porta: 0,
			valor: 0,
			chave: Math.random()
		};
	}

	static Finalidades = [
		{label: "Nenhuma", value: "NENHUMA"},
		{label: "Acionamento", value: "ACIONAMENTO"},
		{label: "Alarme de Temperatura", value: "ALARME_TEMPERATURA"},
		{label: "Alarme de Fumaça", value: "ALARME_FUMACA"}
	];

	static MapFinalidades = {
		"NENHUMA": "Nenhuma",
		"ACIONAMENTO": "Acionamento",
		"ALARME_TEMPERATURA": "Alarme de Temperatura",
		"ALARME_FUMACA": "Alarme de Fumaça"
	}

}

export class MDispositivo {

	static Modelo() {
		return {
			gatilhos: ["LIBERACAO"],
			acionamento: 500,
			atuador: "NENHUM",
			sensorizacao: "NENHUMA",
			chave: Math.random(),
			antidupla: 0,
			sequencia: []
		};
	}

	static MapAtuadores = {
		"SOLENOID1": "Solenóide 1",
		"SOLENOID2": "Solenóide 2",
		"SOLENOID3": "Solenóide 3",
		"OUTPUT1": "Saída Digital 1",
		"OUTPUT2": "Saída Digital 2",
		"OUTPUT3": "Saída Digital 3",
		"OUTPUT4": "Saída Digital 4",
		"OUTPUT5": "Saída Digital 5",
		"OUTPUT6": "Saída Digital 6",
		"OUTPUT7": "Saída Digital 7",
		"OUTPUT8": "Saída Digital 8",
		"RELAY1": "Relé 1",
		"RELAY2": "Relé 2",
		"ORIGEM": "Origem"
	}

	static Sensorizacoes = [
		{label: "Nenhuma", value: "NENHUMA"},
		{label: "Componente de Giro", value: "GIRO"},
		{label: "Sensor de Abertura", value: "ABERTURA"},
		{label: "Sensor de Fechamento", value: "FECHAMENTO"},
		{label: "Manual", value: "MANUAL"}
	];

	static MapSensorizacoes = {
		"NENHUMA": "Nenhuma",
		"GIRO": "Componente de Giro",
		"ABERTURA": "Sensor de Abertura",
		"FECHAMENTO": "Sensor de Fechamento",
		"MANUAL": "Manual"
	};

}

export const optionsEquipamentoFlags = [
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/vagas.png")}/> Controlar Garagem</div><span>Ativa o controle de vaga para passagem</span></div>, value: "GARAGEM"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/tarifas.png")}/> Controlar Tarifação</div><span>Ativa a tarifação para passagem</span></div>, value: "TARIFADO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/monitor-presenca.png")}/> Controlar Presença</div><span>Ativa a geração de bloqueio de retorno para lugar</span></div>, value: "CONTROLADOR"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/direcoes.png")}/> Equipamento Livre</div><span>O equipamento deve operar sempre liberado</span></div>, value: "LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/rele.png")}/> Leitor Online</div><span>O equipamento é um leitor que opera em modo online</span></div>, value: "ONLINE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/equipamentos.png")}/> Individual</div><span>O equipamento só libera se estiver no Modelo de Acesso</span></div>, value: "INDIVIDUAL"}
];

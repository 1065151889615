import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {httpGet, httpPost} from "../../utils/Request";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ColorPanel} from "../commons/ColorPanel";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PatternPanel} from "../commons/PatternPanel";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {UsuarioService} from "../servicos/UsuarioService";
import {PasswordScorer} from "../commons/PasswordScorer";
import {scorePassword} from "../../utils/passwordUtils";
import moment from "moment";
import {SetupSistemaService} from "../servicos/SetupSistemaService";

export class Opcoes extends React.Component {

	state = {
		visible: true
	};

	onClose = () => this.setState({visible: false});

	trocarSenha = () => {
		ModalRealm.showDialog(<AlteradorSenha usuario={this.props.operador.usuario} />);
	}

	setPattern = (pattern) => {
		window.localStorage.setItem("safety-neomainbar-bgpattern", pattern);
		window.location.replace("/");
	}

	setColor = (color) => {
		window.localStorage.setItem("safety-neomainbar-bgcolor", color);
		window.location.replace("/");
	}

	forceRefresh = () => httpGet("/frontend/refresh");

	forceLogout = () => httpGet("/frontend/logout");

	sendMessage = () => {
		let mensagem = window.prompt("Informe a mensagem a ser enviada por broadcast");
		if (mensagem) {
			httpPost("/frontend/mensagem", [mensagem]).then(() => {
				ModalRealm.showInformacao("info", ["Mensagem enviada com sucesso!"]);
			});
		}
	}

	render() {
		return (
			<Dialog modal header="Informações" visible={this.state.visible} style={{width: "600px", background: "#FFFFFF"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-12">
						{
							this.props.operador.pessoa ? (
								<div className="opcoes-user-card">
									<i className="fal fa-user"/> {this.props.operador.pessoa.nome}<br/>
									{this.props.usuario.login}<br/>
									<span style={{fontWeight: "normal", fontSize: ".8em"}}>{this.props.usuario.ultimaTrocaSenha ? `Última alteração da senha: ${moment(this.props.usuario.ultimaTrocaSenha).format("DD/MM/YYYY HH:mm")}` : ""}</span>
									<p/>
								</div>
							) : this.props.usuario.name
						}
					</div>
					{
						UserData.usuario.login !== "admin" ? null : (
							<div className="ui-g-12">
								<div style={{height: "6px"}} />
								<Label help="Executa ações que são propagadas para todos os usuários ativos no sistema">Admin Powertools</Label>
								<div className="ui-g" style={{margin: "4px 0"}}>
									<div className="ui-g-4"><Action mode="secondary" style={{width: "100%"}} icon="fa-comments" label="Enviar Mensagem" onClick={this.sendMessage}/></div>
									<div className="ui-g-4"><Action mode="secondary" style={{width: "100%"}} icon="fa-sync-alt" label="Recarregar Página" onClick={this.forceRefresh}/></div>
									<div className="ui-g-4"><Action mode="secondary" style={{width: "100%"}} icon="fa-times" label="Fazer Logout" onClick={this.forceLogout}/></div>
								</div>
							</div>
						)
					}
					<div className="ui-g-12" style={{lineHeight: "10px", marginTop: "8px"}}>
						<PatternPanel label="Selecione um padrão e cor para a barra superior" name="pattern" onChange={(event) => this.setPattern(event.value)}/>
						<ColorPanel name="color" onChange={(event) => this.setColor(event.value)}/>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" style={{float: "left"}} label="Trocar Senha" icon="fa-key" onClick={this.trocarSenha}/>
					<Action label="OK" icon="fa-check" onClick={this.onClose}/>
				</DialogFooter>
				<Shortcut active={this.state.visible} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

export class AlteradorSenha extends React.Component {

	state = {
		visible: true
	};

	usuarioService = new UsuarioService();
	setupSistemaService = new SetupSistemaService();

	async componentDidMount() {
		this.setState({setupSistema: await this.setupSistemaService.buscar()});
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarSenha = () => {
		const senha1 = this.state.senha1?.trim();
		const senha2 = this.state.senha2?.trim();
		if (senha1?.length && senha2?.length) {
			if (senha1 === senha2) {
				const score = scorePassword(this.state.senha1).score;
				switch (score) {
					case 0:
					case 5:
						this.handleSalvar();
						break;
					case 4:
						ModalRealm.showConfirmacao("Esta senha não é segura o suficiente. Deseja continuar mesmo assim?", () => {
							this.handleSalvar();
						});
						break;
					default:
						if (this.state.setupSistema.scoreMinimoSenha > score) {
							ModalRealm.showInformacao("warn", ["Senha não atinge os requisitos mínimos de segurança."]);
						} else {
							ModalRealm.showConfirmacao("Esta senha não é segura o suficiente. Deseja continuar mesmo assim?", () => {
								this.handleSalvar();
							});
						}
						break;
				}
			} else {
				ModalRealm.showInformacao("warn", ["As senhas não conferem"]);
			}
		} else {
			ModalRealm.showInformacao("warn", ["Preencha os dois campos corretamente"]);
		}
	}

	handleSalvar = () => {
		this.usuarioService.buscar(this.props.usuario).then(usuario => {
			usuario.senha = this.state.senha1;
			this.usuarioService.salvar(usuario).then(usuario => {
				if (this.props.onModalClose) {
					this.props.onModalClose(usuario);
				}
				this.setState({visible: false});
				window.location.reload();
			});
		});
	}

	handleChange = event => this.setState({[event.name]: event.value});

	render() {
		return (
			<Dialog modal header="Alteração de Senha" visible={this.state.visible} style={{width: "450px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField label="Digite a nova senha" grid={12} required type="password" name="senha1" value={this.state.senha1} onChange={this.handleChange}/>
					<PasswordScorer username={this.props.usuario.login} password={this.state.senha1} grid={12}/>
					<TextField label="Repita a mesma senha" grid={12} required type="password" name="senha2" value={this.state.senha2} onChange={this.handleChange}/>
					<PasswordScorer username={this.props.usuario.login} password={this.state.senha2} grid={12}/>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Alterar" icon="fa-save" onClick={this.salvarSenha}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarSenha} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

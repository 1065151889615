import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {DayOfWeek} from "../../utils/DateUtils";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MatrizHorarioService} from "../servicos/MatrizHorarioService";
import {EditarMatrizHorario} from "./EditarMatrizHorario";

export class MatrizHorario extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	matrizHorarioService = new MatrizHorarioService();
	security = UserData.security("MTH", true);

	listarMatrizesHorario = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.matrizHorarioService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((matrizesHorario) => this.setState({matrizesHorario, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarMatrizHorario = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.matrizHorarioService.buscar(rowData).then((mh) => {
				ModalRealm.showDialog(<EditarMatrizHorario key={Math.random()} matrizHorario={mh} onModalClose={this.listarMatrizesHorario} />);
			});
		} else {
			ModalRealm.showDialog(<EditarMatrizHorario key={Math.random()} matrizHorario={null} onModalClose={this.listarMatrizesHorario} />);
		}
	}

	excluirMatrizHorario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta matriz de horário?", () => this.matrizHorarioService.excluir(rowData).then(this.listarMatrizesHorario));
	}

	restaurarMatrizHorario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta matriz de horário?", () => {
			if (rowData != null && rowData.id != null) {
				this.matrizHorarioService.buscar(rowData).then((matrizHorario) => {
					matrizHorario.status = "ATIVADO";
					this.matrizHorarioService.salvar(matrizHorario).then(this.listarMatrizesHorario);
				});
			}
		});
	}

	eliminarMatrizHorario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta matriz de horário?", () => this.matrizHorarioService.eliminar(rowData).then((result) => {
			if (result) this.listarMatrizesHorario(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta matriz de horário: há outros registros dependentes dela no sistema."]);
		}));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column header="Descrição"  style={{width: "*"}} sortable field="descricao" />,
		<Column header="Início"  style={{width: "12em"}} body={(mh) => `${moment(mh.inicio).format("DD/MM/YYYY")}, ${DayOfWeek.from(moment(mh.inicio).day())}`} />,
		<Column header="Períodos"  style={{width: "*"}} body={(mh) => {
			return mh.periodos.map(x => <div style={{color: x.folga ? "#888888" : "#000000"}}>{`${x.folga ? "Folga (" : ""}${x.inicio.formatTime()} às ${x.fim.formatTime()}${x.folga ? ")" : ""} em ${x.dias.join(", ")}`}</div>);
		}} />,
		<Column header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Matriz de Horário" onClick={(event) => this.editarMatrizHorario(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Matriz de Horário" onClick={(event) => this.excluirMatrizHorario(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Matriz de Horário" onClick={(event) => this.restaurarMatrizHorario(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Matriz de Horário" onClick={(event) => this.eliminarMatrizHorario(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/matrizes-horario", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Matrizes de Horário">
					<PanelContent>
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarMatrizesHorario} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.matrizesHorario} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Matriz de Horário" icon="fa-plus" onClick={this.editarMatrizHorario} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarMatrizesHorario}
					onCtrlN={() => window.location.pathname === "/matrizes-horario" && this.security.create && this.editarMatrizHorario()}
					onCtrlE={() => window.location.pathname === "/matrizes-horario" && this.state.matrizesHorario.length === 1 && this.security.edit && this.editarMatrizHorario(this.state.matrizesHorario[0])}
				/>
				<ModalRealm/>
			</div>
		);
	}

}

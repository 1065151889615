import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {FormularioService} from "../servicos/FormularioService";
import {EditarFormulario} from "./EditarFormulario";

export class Formulario extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	formularioService = new FormularioService();
	security = UserData.security("FRM", true);

	listarFormularios = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.formularioService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((formularios) => this.setState({formularios, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarFormulario = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.formularioService.buscar(rowData).then((formulario) => {
				ModalRealm.showDialog(<EditarFormulario key={Math.random()} formulario={formulario} onModalClose={this.listarFormularios} />);
			});
		} else {
			ModalRealm.showDialog(<EditarFormulario key={Math.random()} formulario={null} onModalClose={this.listarFormularios} />);
		}
	}

	excluirFormulario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este formulário?", () => this.formularioService.excluir(rowData).then(this.listarFormularios));
	}

	restaurarFormulario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este formulário?", () => {
			if (rowData != null && rowData.id != null) {
				this.formularioService.buscar(rowData).then((formulario) => {
					formulario.status = "ATIVADO";
					this.formularioService.salvar(formulario).then(this.listarFormularios);
				});
			}
		});
	}

	eliminarFormulario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este formulário?", () => this.formularioService.eliminar(rowData).then((result) => {
			if (result) this.listarFormularios(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este formulário: há outros registros dependentes dele no sistema."]);
		}));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id"/>,
		<Column header="Descrição"  style={{width: "*"}} sortable field="descricao"/>,
		<Column header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Formulário" onClick={(event) => this.editarFormulario(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Formulário" onClick={(event) => this.excluirFormulario(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Formulário" onClick={(event) => this.restaurarFormulario(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Formulário" onClick={(event) => this.eliminarFormulario(rowData)} /> : null}
				</div>
			);
		}}/>
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/formularios", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Formulários">
					<PanelContent>
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate}/>
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate}/> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarFormularios}/>
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.formularios} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes}/>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Formulario" icon="fa-plus" onClick={this.editarFormulario}/> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarFormularios}
					onCtrlN={() => window.location.pathname === "/formularios" && this.security.create && this.editarFormulario()}
					onCtrlE={() => window.location.pathname === "/formularios" && this.state.formularios.length === 1 && this.security.edit && this.editarFormulario(this.state.formularios[0])}
				/>
				<ModalRealm/>
			</div>
		);
	}

}

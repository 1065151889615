import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";

export class ConfirmarRevogacao extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes();
		}
		this.onClose();
	}

	onNo = () => {
		if (this.props.onNo) {
			this.props.onNo();
		}
		this.onClose();
	}

	onClose = () => {
		this.setState({visible: false});
	}

	render() {
		return (
			<Dialog className="tzm-danger-dialog" closable={false} modal header={this.props.header != null ? this.props.header : "ATENÇÃO"} visible={this.state.visible} style={{with: "450px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						<div className="ui-g-12">
                            Revogar o acesso da pessoa significa excluí-la de <b>TODAS</b> as agendas às quais ela pertence.<br />
							Isto fará com que o acesso dela seja bloqueado em todos os equipamentos.<br />
							Tem certeza de que deseja manter esta opção?
                        </div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="delete" label="Sim" icon="fa-check" onClick={this.onYes} />
					<Action mode="post" label="Não" icon="fa-times" onClick={this.onNo} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onS={this.onYes} onN={this.onNo} onEscape={this.onNo}/> : null}
			</Dialog>
		);
	}

}

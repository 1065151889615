import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {MExpiracao} from "../../utils/models/MExpiracao";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";

export class EditarExpiracao extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			expiracao: this.fillNulls(props.expiracao),
			visible: true,
			activeIndex: 0
		};
		this.security = UserData.security("CFG", true);
	}

	fillNulls = (expiracao) => {
		if (!expiracao) {
			expiracao = MExpiracao.Modelo();
		}
		return expiracao;
	}

	onClose = (expiracao) => {
		if (expiracao && this.props.onModalClose) {
			this.props.onModalClose(expiracao);
		}
		this.setState({visible: false});
	}

	saveExpiracao = () => {
		if (this.security.edit) {
			let expiracao = MExpiracao.cleanupBeforeSave(Object.assign({}, this.state.expiracao));
			let messages = MExpiracao.validarParaSalvar(expiracao);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.onClose(expiracao);
			}
		}
	}

	handleChange = (event) => {
		let expiracao = this.state.expiracao;
		expiracao[event.name] = event.value;
		this.setState({expiracao});
	}

	render() {
		return (
			<Dialog modal header="Configuração de Expiração" visible={this.state.visible} style={{width: "850px"}} onHide={this.onClose}>
				<DialogContent>
          			<MultiSelectEtiqueta value={this.state.expiracao.etiquetas} name="etiquetas" onSelect={this.handleChange} grid={10} label="Etiquetas" />
          			<TextField label="Dias" value={this.state.expiracao.dias} name="dias" onChange={this.handleChange} grid={2} />
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" disabled={!this.security.edit} onClick={this.saveExpiracao} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={() => this.onClose()} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.saveExpiracao} onEscape={() => this.onClose()}/> : null}
			</Dialog>
		);
	}

}

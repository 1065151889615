import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {TextField} from "../commons/TextField";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {GrupoTrabalhoService} from "../servicos/GrupoTrabalhoService";
import {UserData} from "../../utils/UserData";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {SuggestionBox} from "../commons/SuggestionBox";
import {EquipamentoService} from "../servicos/EquipamentoService";
import iconeRemover from "../../media/icones/remover.png";

export class EditarGrupoTrabalho extends Component {

	state = {
		grupoTrabalho: this.props.grupoTrabalho,
		visible: true,
		equipamentos: [],
		equipamento: null
	};

	security = UserData.security("GRT", true);
	grupoTrabalhoService = new GrupoTrabalhoService();
	equipamentoService = new EquipamentoService();

	salvarGrupoTrabalho = () => {
		const {grupoTrabalho} = this.state;
		this.grupoTrabalhoService.salvar(grupoTrabalho).then(grupoTrabalho => {
			if (this.props.onModalClose) {
				this.props.onModalClose(grupoTrabalho);
			}
			this.onClose();
		});
	}

	handleChange = event => {
		const {grupoTrabalho} = this.state;
		grupoTrabalho[event.name] = event.value;
		this.setState({grupoTrabalho});
	}

	onClose = () => this.setState({visible: false});

	listarEquipamentos = event => {
		this.equipamentoService.listar(`search=categoria:PONTO;id!${this.state.grupoTrabalho.equipamentos.map(e => e.id).join(",")};descricao,serial~${event.query}`, {page: 0, size: 10}).then(equipamentos => this.setState({equipamentos}));
	}

	adicionarEquipamento = event => {
		const {grupoTrabalho} = this.state;
		grupoTrabalho.equipamentos.push(event.value);
		this.setState({grupoTrabalho, equipamento: null});
	}

	removerEquipamento = equipamento => {
		const {grupoTrabalho} = this.state;
		grupoTrabalho.equipamentos = grupoTrabalho.equipamentos.filter(e => e.id !== equipamento.id);
		this.setState({grupoTrabalho});
	}

	render() {
		return (
			<Dialog modal key={this.props.key} header="Grupo de Trabalho" visible={this.state.visible} style={{width: "800px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={12} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.grupoTrabalho.descricao}/>
					<SuggestionBox placeholder="Pesquise pela descrição ou número de série" style={{position: "relative"}} appendTo={document.body} label="Equipamento" grid={12} field="descricao" completeMethod={this.listarEquipamentos} suggestions={this.state.equipamentos} onChange={(e) => this.setState({equipamento: e.value})} onSelect={this.adicionarEquipamento} value={this.state.equipamento} />
					<div className="ui-g-12">
						<DataTable value={this.state.grupoTrabalho.equipamentos} emptyMessage="Nenhum equipamento adicionado">
							<Column header="Descricao" field="descricao"/>
							<Column style={{width: "4em", textAlign: "center"}} header="Ações" body={e =>
								(this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Equipamento" onClick={() => this.removerEquipamento(e)} /> : null)
							}/>
						</DataTable>
					</div>
					<TextField grid={12} label="Chave de Integração" required name="chaveIntegracao" onChange={this.handleChange} value={this.state.grupoTrabalho.chaveIntegracao}/>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.grupoTrabalho.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/grupos-trabalho", this.state.grupoTrabalho)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.grupoTrabalho.id)) || this.state.lockSave} onClick={this.salvarGrupoTrabalho} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarGrupoTrabalho} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import { httpGet } from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class EncomendaService extends EntidadeNextGenService {

	constructor() {
		super("/encomendas");
	}

	async listarDTO(params) {
		return await httpGet(`${this.basePath}/dto`,params);
	}

}


/*eslint no-extend-native: ["error", { "exceptions": ["Object", "Number", "String"] }]*/

Number.prototype.format = function(n, x, s, c) {
	const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')', num = this.toFixed(Math.max(0, ~~n));
	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};


Number.prototype.formatMoney = function() {
	return 'R$ ' + this.format(2, 3, '.', ',');
};

Number.prototype.formatPercent = function() {
	return this.format(0, 3, '.', ',') + '%';
};

Number.prototype.formatInteger = function() {
	return this.format(0, 3, '.', ',');
};

Number.prototype.formatDecimal = function() {
	return this.format(2, 3, '.', ',');
};

const pt_BR = Intl.NumberFormat("pt-BR");

Number.prototype.toLocale = function() {
	return pt_BR.format(this);
}

String.prototype.formatCpf = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	let res = num.substr(0, 3);
	if (num.length > 3) res += '.' + num.substr(3, 3);
	if (num.length > 6) res += '.' + num.substr(6, 3);
 	if (num.length > 9) res += '-' + num.substr(9, 2);
 	return res;
};

String.prototype.toDate = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	const res = num.toString();
	switch (num.length) {
		case 0:
		case 1:
		case 2:
			return res;
		case 3:
			return res.substring(0, 2) + "/" + res.substring(2, 3);
		case 4:
			return res.substring(0, 2) + "/" + res.substring(2, 4);
		case 5:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 5);
		case 6:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 6);
		case 7:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 7);
		default:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8);
	}
}

String.prototype.toDateTime = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	const res = num.toString();
	switch (num.length) {
		case 0:
		case 1:
		case 2:
			return res;
		case 3:
			return res.substring(0, 2) + "/" + res.substring(2, 3);
		case 4:
			return res.substring(0, 2) + "/" + res.substring(2, 4);
		case 5:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 5);
		case 6:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 6);
		case 7:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 7);
		case 8:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8);
		case 9:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 9);
		case 10:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10);
		case 11:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 11);
		case 12:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 12);
		case 13:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 12) + ":" + res.substring(12, 13);
		default:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 12) + ":" + res.substring(12, 14);
	}
}

String.prototype.formatCnpj = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	let res = num.substr(0, 2);
	if (num.length > 2) res += '.' + num.substr(2, 3);
	if (num.length > 5) res += '.' + num.substr(5, 3);
	if (num.length > 8) res += '/' + num.substr(8, 4);
	if (num.length > 12) res += '-' + num.substr(12, 2);
 	return res;
};

String.prototype.formatDocument = function() {
	if (this.length >= 14) {
		return this.formatCnpj();
	} else {
		return this.formatCpf();
	}
};

String.prototype.formatRg = function() {
	const num = this;
	return num.substr(0, 2) + '.' +
		num.substr(2, 3) + '.' +
		num.substr(5, 3) + '-' +
		num.substr(8, 1);
};

String.prototype.toCapitalCase = function() {
	let word = this.toLowerCase();
	word = word.charAt(0).toUpperCase() + word.slice(1);
	return word;
};

String.prototype.formatPhone = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	if (num.length === 0) return "";
	let res = '(' + num.substr(0, 2);
	if (num.length > 2) res += ') ' + num.substr(2, 4);
	if (num.length > 6) res += '-' + num.substr(6, 5);

	if (num.length > 10) res = '('+num.substr(0, 2)+') ' + num.substr(2, 5)+'-'+num.substr(7, 4);

	return res;
};

String.prototype.maskNumber = function() {
	const num = this.replace('.', '');
	return num.split( /(?=(?:\d{3})+(?:,|$))/g ).join( "." );
};

String.prototype.formatCep = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	let res = num.substr(0, 5);
	if (num.length > 5) res += '-' + num.substr(5, 3);
 	return res;
};

String.prototype.formatDate = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	let res = num.substr(0, 2);
	if (num.length > 2) res += '/' + num.substr(2, 2);
	if (num.length > 4) res += '/' + num.substr(4, 4);
 	return res;
};

String.prototype.formatDateTime = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	let res = num.substr(0, 2);
	if (num.length > 2) res += '/' + num.substr(2, 2);
	if (num.length > 4) res += '/' + num.substr(4, 4);
	if (num.length > 8) res += ' ' + num.substr(8, 2);
	if (num.length > 10) res += ':' + num.substr(10, 2);
 	return res;
};

String.prototype.formatTime = function() {
	const num = this.replace(new RegExp('[^0-9]', 'g'), '');
	let res = num.substr(0, 2);
	if (num.length > 2) res += ':' + num.substr(2, 2);
 	return res;
};

export const brTimeFormat = Intl.DateTimeFormat("pt-BR", {second: "2-digit", hour: "2-digit", minute: "2-digit"});
export const brDateFormat = Intl.DateTimeFormat("pt-BR", {day: "2-digit", month: "2-digit", year: "numeric"});

export function toTime(value) {
	return value ? brTimeFormat.format(Date.parse(value)) : null;
}

export function toDate(value) {
	return value ? brDateFormat.format(Date.parse(value)) : null;
}

export class Convert {

	static toDate(data) {
		let date = data;
		let parts = date.split(" ");
		let dmy = parts[0].split("/");
		date = dmy[2] + "-" + dmy[1] + "-" + dmy[0] + "T" + parts[1];
		return date;
	}

}

import React from "react";
import iconeAgendas from "../../media/icones/agendas.png";
import iconeAjuda from "../../media/icones/ajuda.png";
import iconeBloqueios from "../../media/icones/bloqueios.png";
import iconeConfiguracoes from "../../media/icones/configuracoes.png";
import iconeEtiquetas from "../../media/icones/etiquetas.png";
import iconeLugares from "../../media/icones/lugares.png";
import iconeModeloAcesso from "../../media/icones/modelos-acesso.png";
import iconePerfis from "../../media/icones/perfis.png";
import iconePortaria from "../../media/icones/portaria.png";
import iconeUnidades from "../../media/icones/unidades.png";
import iconeUsuarios from "../../media/icones/usuarios.png";
import {HelpAgenda} from "../../utils/help/Agenda";
import {HelpAvaliacaoServico} from "../../utils/help/AvaliacaoServico";
import {HelpEncomenda} from "../../utils/help/Encomenda";
import {HelpEspaco} from "../../utils/help/Espaco";
import {HelpEtiqueta} from "../../utils/help/Etiqueta";
import {HelpInicio} from "../../utils/help/Inicio";
import {HelpLugar} from "../../utils/help/Lugar";
import {HelpModeloAcesso} from "../../utils/help/ModeloAcesso";
import {HelpPortaria} from "../../utils/help/Portaria";
import {HelpPreAgendamento} from "../../utils/help/PreAgendamento";
import {HelpReserva} from "../../utils/help/Reserva";
import {HelpUnidade} from "../../utils/help/Unidade";

export class SafetyHelp extends React.Component {

	componentDidMount() {
		switch (window.location.pathname) {
			case "/":
				this.setState({ selected: 0 });
				break;
			case "/lugares":
				this.setState({ selected: 1 });
				break;
			case "/modelos-acesso":
				this.setState({ selected: 2 });
				break;
			case "/agendas":
				this.setState({ selected: 6 });
				break;
			case "/portaria":
				this.setState({ selected: 7 });
				break;
			case "/etiquetas":
				this.setState({ selected: 8 });
				break;
			case "/avaliacao-servicos":
				this.setState({ selected: 9 });
				break;
			case "/pre-agendamentos":
				this.setState({ selected: 10 });
				break;
			case "/espacos":
				this.setState({ selected: 11 });
				break;
			case "/reservas":
				this.setState({ selected: 12 });
				break;
			case "/unidades":
				this.setState({ selected: 13 });
				break;
			case "/encomendas":
				this.setState({ selected: 14 });
				break;
			default:
		}
	}

	state = {
		selected: 0
	};

	changeItem = (index) => this.setState({ selected: index });

	displayContent = () => {
		switch (this.state.selected) {
			case 0:
				return <SafetyHelpPanel content={<HelpInicio />} title="Ajuda do Safety 4" />;
			case 1:
				return <SafetyHelpPanel content={<HelpLugar />} title="Lugares" />;
			case 2:
				return <SafetyHelpPanel content={<HelpModeloAcesso />} title="Modelos de Acesso" />;
			case 6:
				return <SafetyHelpPanel content={<HelpAgenda />} title="Agendas" />;
			case 7:
				return <SafetyHelpPanel content={<HelpPortaria />} title="Portaria" />;
			case 8:
				return <SafetyHelpPanel content={<HelpEtiqueta />} title="Etiquetas" />;
			case 9:
				return <SafetyHelpPanel content={<HelpAvaliacaoServico />} title="Avaliações de Serviço" />;
			case 10:
				return <SafetyHelpPanel content={<HelpPreAgendamento />} title="Pré-Agendamentos" />;
			case 11:
				return <SafetyHelpPanel content={<HelpEspaco />} title="Espaços" />;
			case 12:
				return <SafetyHelpPanel content={<HelpReserva />} title="Reservas" />;
			case 13:
				return <SafetyHelpPanel content={<HelpUnidade />} title="Unidades" />;
			case 14:
				return <SafetyHelpPanel content={<HelpEncomenda />} title="Encomendas" />;
			default:
				return null;
		}
	}

	render() {
		return (
			<div className="ui-helppanel">
				<div className="ui-helppanel-title">
					<i style={{ marginRight: "10px" }} className="fa fa-question-circle" />Safety - Tópicos de Ajuda
					<i className="fa fa-times ui-helppanel-close" onClick={this.props.onHide} />
				</div>
				<div className="ui-helppanel-content">
					<div className="ui-helppanel-item-content">
						{this.displayContent()}
					</div>
					<div className="ui-helppanel-item-tabview">
						<SafetyHelpItem selected={this.state.selected === 0} onClick={this.changeItem} index={0} icone={iconeAjuda} />
						<SafetyHelpItem selected={this.state.selected === 7} onClick={this.changeItem} index={7} icone={iconePortaria} />
						<SafetyHelpItem selected={this.state.selected === 1} onClick={this.changeItem} index={1} icone={iconeLugares} />
						<SafetyHelpItem selected={this.state.selected === 2} onClick={this.changeItem} index={2} icone={iconeModeloAcesso} />
						<SafetyHelpItem selected={this.state.selected === 3} onClick={this.changeItem} index={3} icone={iconeUnidades} />
						<SafetyHelpItem selected={this.state.selected === 4} onClick={this.changeItem} index={4} icone={iconePerfis} />
						<SafetyHelpItem selected={this.state.selected === 5} onClick={this.changeItem} index={5} icone={iconeUsuarios} />
						<SafetyHelpItem selected={this.state.selected === 6} onClick={this.changeItem} index={6} icone={iconeAgendas} />
						<SafetyHelpItem selected={this.state.selected === 8} onClick={this.changeItem} index={8} icone={iconeEtiquetas} />
						<SafetyHelpItem selected={this.state.selected === 9} onClick={this.changeItem} index={9} icone={iconeBloqueios} />
						<SafetyHelpItem selected={this.state.selected === 10} onClick={this.changeItem} index={10} icone={iconeConfiguracoes} />
						<SafetyHelpItem selected={this.state.selected === 11} onClick={this.changeItem} index={11} icone={iconeConfiguracoes} />
						<SafetyHelpItem selected={this.state.selected === 12} onClick={this.changeItem} index={12} icone={iconeConfiguracoes} />
						<SafetyHelpItem selected={this.state.selected === 13} onClick={this.changeItem} index={13} icone={iconeConfiguracoes} />
						<SafetyHelpItem selected={this.state.selected === 14} onClick={this.changeItem} index={14} icone={iconeConfiguracoes} />
					</div>
				</div>
			</div>
		);
	}

}

class SafetyHelpItem extends React.Component {

	render() {
		return (
			<div onClick={() => this.props.onClick(this.props.index)} className={`ui-helppanel-item${this.props.selected ? " ui-helppanel-item-selected" : ""}`}><img src={this.props.icone} alt="" /></div>
		);
	}

}

class SafetyHelpPanel extends React.Component {

	render() {
		return (
			<div className="ui-helppanel-item-content-panel">
				<div className="ui-helppanel-item-content-panel-title">{this.props.title}</div>
				<div className="ui-helppanel-item-content-panel-text">{this.props.content}</div>
			</div>
		);
	}

}

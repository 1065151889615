import React from "react";
import {CheckSquare} from "../../commons/CheckSquare";
import moment from "moment";
import "./DeclaracaoPessoalSaude.css";
import {Action} from "../../commons/Action";
import {ModalRealm} from "../../commons/ModalRealm";
import {Signature} from "../../commons/Signature";

const questions = [
    {label: "Febre?"},
    {label: "Tosse?"},
    {label: "Coriza?"},
    {label: "Dores no Corpo ou Garganta?"},
    {label: "Dificuldade para Respirar?"},
    {label: "Vermelhidão e Secreção Ocular?"},
    {label: "Diarreia?"}
];

function printOptionsAsObject(options) {
    return {
        febre: options[0] === "Y",
        tosse: options[1] === "Y",
        coriza: options[2] === "Y",
        doresCorpoGarganta: options[3] === "Y",
        dificuldadeRespirar: options[4] === "Y",
        vermelhidaoSecrecaoOcular: options[5] === "Y",
        diarreia: options[6] === "Y"
    };
}

export class DeclaracaoPessoalSaude extends React.Component {

    state = {
        options: Array(questions.length),
        signature: null
    };

    handlePost = () => {
        const messages = [];
        if (!this.state.options.some(o => ["Y", "N"].includes(o))) {
            messages.push("Por favor, responda a todas as questões.");
        }
        if (!this.state.signature) {
            messages.push("Por favor, assine o formulário.");
        }
        if (messages.length) {
            ModalRealm.showInformacao("warn", messages);
        } else {
            if (this.props.onPost) {
                this.props.onPost({
                    ...this.props.requisicao,
                    entidade: {
                        ...this.props.requisicao.entidade,
                        options: printOptionsAsObject(this.state.options)
                    },
                    assinatura: this.state.signature
                });
            }
        }
    }

    handleDiscard = () => {
        ModalRealm.showConfirmacao("Tem certeza de que deseja descartar este formulário?", () => {
            if (this.props.onDiscard) {
                this.props.onDiscard();
            }
        });
    }

    handleChange = e => this.setState({[e.name]: e.value});

    handleChangeCheck = e => {
        const {options} = this.state;
        options[e.index] = e.name;
        this.setState({options});
    }

    render() {
        return (
            <div className="form-container">
                <div className="form-title">
                    DECLARAÇÃO DE CONDIÇÃO GERAL DE SAÚDE PARA<br/>
                    VISITANTES E TRANSPORTADORES
                </div>
                <div className="form-body">
                    <label>Nome</label>
                    <div>{this.props.requisicao.entidade?.nome}</div>
                    <label>Local de Destino na Cacique</label>
                    <div>???</div>
                    <label>Apresenta atualmente algum destes sintomas?</label>
                    <div className="ui-g">
                        {
                            questions.map((q, i) => (
                                <>
                                    <div className="form-question ui-g-6">{q.label}</div>
                                    <div className="form-answer ui-g-6">
                                        <CheckSquare label="Sim" checked={this.state.options[i] === "Y"} onChange={this.handleChangeCheck} index={i} name="Y"/>
                                        <CheckSquare label="Não" checked={this.state.options[i] === "N"} onChange={this.handleChangeCheck} index={i} name="N"/>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    <label>Assinatura</label>
                    <div className="form-signature ui-g">
                        <Signature name="signature" value={this.state.signature} onChange={this.handleChange}/>
                    </div>
                    <div className="ui-g">
                        <div className="ui-g-3">
                            <label>Data</label>
                            <div>{moment().format("DD/MM/YYYY")}</div>
                        </div>
                        <div className="ui-g-9">
                            <label>Autorizado por</label>
                            <div>{this.props.requisicao.responsavel?.pessoa?.nome}</div>
                        </div>
                    </div>
                </div>
                <div className="form-footer">
                    <Action mode="post" label="Entregar" onClick={this.handlePost} icon="fal fa-save"/>
                    <Action iconOnly mode="delete" label="Descartar" onClick={this.handleDiscard} icon="fal fa-times"/>
                </div>
            </div>
        );
    }

}

export class MEndereco {

	static Modelo() {
		return {
			logradouro: "",
			numero: "",
			cep: "",
			complemento: "",
			bairro: "",
			localidade: {nome: "", uf: ""},
			tipo: "PRINCIPAL"
		};
	}

	static Tipos = [
		{label: "Principal", value: "PRINCIPAL"},
		{label: "Cobrança", value: "COBRANCA"}
	];

	static UFs = [
		{label: "Acre", value: "AC"},
		{label: "Alagoas", value: "AL"},
		{label: "Amazonas", value: "AM"},
		{label: "Amapá", value: "AP"},
		{label: "Bahia", value: "BA"},
		{label: "Ceará", value: "CE"},
		{label: "Distrito Federal", value: "DF"},
		{label: "Espírito Santo", value: "ES"},
		{label: "Goiás", value: "GO"},
		{label: "Maranhão", value: "MA"},
		{label: "Minas Gerais", value: "MG"},
		{label: "Mato Grosso do Sul", value: "MS"},
		{label: "Mato Grosso", value: "MT"},
		{label: "Pará", value: "PA"},
		{label: "Paraíba", value: "PB"},
		{label: "Pernambuco", value: "PE"},
		{label: "Piauí", value: "PI"},
		{label: "Paraná", value: "PR"},
		{label: "Rio de Janeiro", value: "RJ"},
		{label: "Rio Grande do Norte", value: "RN"},
		{label: "Rondônia", value: "RO"},
		{label: "Roraima", value: "RR"},
		{label: "Rio Grande do Sul", value: "RS"},
		{label: "Santa Catarina", value: "SC"},
		{label: "Sergipe", value: "SE"},
		{label: "São Paulo", value: "SP"},
		{label: "Tocantins", value: "TO}"}
	];

}

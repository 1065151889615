import {Fetch} from "../../utils/Fetch";
import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpGet} from "../../utils/Request";

const basePath = process.env.REACT_APP_API_URL;

export class AgendaService extends EntidadeNextGenService {

	constructor() {
		super("/agendas");
	}

	enviarEmail(agenda) {
		Fetch.Post(`${basePath}/agendas/email/${agenda.id}`);
	}

	countByModeloAcesso(id) {
		return httpGet(`/agendas/count/modelo-acesso/${id}`);
	}

	async listarAlt(query) {
		return await httpGet(`/agendas/alt?${query}`);
	}

}

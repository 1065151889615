import React, {Component} from "react";
import {UnidadeService} from "../servicos/UnidadeService";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {WizardAtivacaoCloud} from "./WizardAtivacaoCloud";
import {ModalRealm} from "../commons/ModalRealm";
import {SelectModeloAcesso} from "../../select/SelectModeloAcesso";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";
import {SelectControlador} from "../../select/SelectControlador";

export class PortalCondomino extends Component {

	state = {
		unidades: [],
		activeIndex: 0
	};

	unidadeService = new UnidadeService();

	componentDidMount() {
		this.listarUnidades();
	}

	listarUnidades = () => {
		this.unidadeService.listar("", {sort: "bloco,descricao", page: 0, size: 1000}).then(unidades => {
			this.setState({unidades});
		});
	}

	handleUploadToCloud = unidade => {
		ModalRealm.showDialog(
			<WizardAtivacaoCloud unidade={unidade} setup={this.props.setup} onModalClose={this.listarUnidades}/>
		);
	}

	templateUnidade = unidade => {
		const isSynched = unidade.flags?.includes("CLOUD");
		return (
			<div className="portal-condomino-item">
				<div className="portal-condomino-item-header">
					<EtiquetaChip etiqueta={unidade.etiqueta}/>
					<div className="manutencoes-interromper-container">
						<div
							className={`manutencoes-interromper ${isSynched ? "disabled" : ""}`}
							onClick={() => this.handleUploadToCloud(unidade)}
						>
							<img
								title="Ativar unidade no portal do condômino"
								alt=""
								src={require("../../media/icones/cloud-upload.png")}
							/>
						</div>
						<div
							className={`manutencoes-interromper ${!isSynched ? "disabled" : ""}`}
						>
							<img
								title="Desativar unidade no portal"
								alt=""
								src={require("../../media/icones/cloud-delete.png")}
							/>
						</div>
					</div>
				</div>
				<div>
					<div>{unidade.contato?.nome}</div>
				</div>
			</div>
		);
	}

	handleChangeTab = e => {
		this.setState({activeIndex: e.index});
	}

	changeSetupTimeout = null;

	render() {
		return (
			<>
				<div className="ui-g-12">
					<div className="portal-condomino-container">
						{this.state.unidades.map(this.templateUnidade)}
					</div>
				</div>
				<SelectPessoaJuridica
					grid={3}
					label="Cliente"
					name="cliente"
					value={this.props.setup?.cliente}
					onChange={this.props.onChangeSetup}
				/>
				<SelectModeloAcesso
					grid={3}
					label="Modelo de Acesso Padrão de Visitantes"
					name="padraoVisitante"
					value={this.props.setup?.padraoVisitante}
					onChange={this.props.onChangeSetup}
				/>
				<SelectModeloAcesso
					grid={3}
					label="Modelo de Acesso Padrão de Permanentes"
					name="padraoPermanente"
					value={this.props.setup?.padraoPermanente}
					onChange={this.props.onChangeSetup}
				/>
				<SelectControlador
					grid={3}
					value={this.props.setup?.controlador}
					onChange={this.props.onChangeSetup}
				/>
			</>
		);
	}

}

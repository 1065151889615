import React from "react";
import {httpDelete, httpGet, httpPost, httpWipe} from "../../utils/Request";
import {Historico} from "../commons/Historico";
import {ModalRealm} from "../commons/ModalRealm";

export class EntidadeNextGenService {

	static listarHistorico(path, entidade) {
		ModalRealm.showDialog(<Historico entidade={entidade} path={path} />);
	}
	
	constructor(basePath) {
		this.basePath = basePath;
	}

	async listar(query, offset) {
		return await httpGet(`${this.basePath}?${query}&page=${offset.page}&size=${offset.size}${offset.sort != null ? `&sort=${offset.sort}` : ""}`);
	}

	async listarAlt(query, offset) {
		return await httpGet(`${this.basePath}/alt?${query}`);
	}

	async contagens() {
		return await httpGet(`/setup-condominio/contagem-categorias`);
	}

	async buscar(entidade) {
		return await httpGet(`${this.basePath}/${entidade.id}`);
	}

	async salvar(entidade) {
		return await httpPost(this.basePath, entidade);
	}

	async excluir(entidade) {
		return await httpDelete(`${this.basePath}/${entidade.id}`);
	}

	async eliminar(entidade) {
		return await httpWipe(`${this.basePath}/${entidade.id}`);
	}

}

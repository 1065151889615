import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import {default as iconeEliminar, default as iconeNOK} from "../../media/icones/eliminar.png";
import iconeEncomendas from "../../media/icones/encomendas.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeOK from "../../media/icones/ok.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {GUI} from "../../utils/Constants";
import {GUIUtils} from "../../utils/GUIUtils";
import {MEncomenda} from "../../utils/models/MEncomenda";
import {StringUtils} from "../../utils/StringUtils";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EncomendaService} from "../servicos/EncomendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarEncomenda} from "./EditarEncomenda";
import { SelectPessoaDTO } from "../../select/SelectPessoaDTO";

export class Encomenda extends React.Component {

	state = {
		encomendas: [],
		discriminacao: "",
		firstResult: 0,
		status: "ATIVADO"
	};

	encomendaService = new EncomendaService();
	security = UserData.security("ENC", true);

	editarEncomenda = (rowData) => {
		if (rowData && rowData.id) {
			this.encomendaService.buscar(rowData).then((encomenda) => {
				ModalRealm.showDialog(<EditarEncomenda key={Math.random()} encomenda={encomenda} onModalClose={this.listarEncomendas} />);
			});
		} else {
			ModalRealm.showDialog(<EditarEncomenda key={Math.random()} encomenda={null} onModalClose={this.listarEncomendas} />);
		}
	}

	restaurarEncomenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta encomenda?", () => {
			if (rowData && rowData.id) {
				this.encomendaService.buscar(rowData).then((encomenda) => {
					encomenda.status = "ATIVADO";
					encomenda.dataEntrega = null	
					this.encomendaService.salvar(encomenda).then(this.listarEncomendas);
				});
			}
		});
	}

	eliminarEncomenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta encomenda?", () => this.encomendaService.eliminar(rowData).then((result) => {
			if (result) this.listarEncomendas(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta encomenda: há outros registros dependentes dela no sistema."]);
		}));
    }

	switchStatus = (encomenda) => {
		switch (encomenda.status) {
			case "ATIVADO":
			case "PENDENTE":
			default:
				return GUIUtils.imageWithTooltip(iconeEncomendas, "Pendente");
			case "CANCELADO":
			case "EXCLUIDO":
				return encomenda.receptor ? GUIUtils.imageWithTooltip(iconeOK, "Entregue") : GUIUtils.imageWithTooltip(iconeNOK, "Cancelado");
		}
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key="status" header="Status" style={{width: "5em", textAlign: "center"}} body={this.switchStatus} />,
		<Column key="data" header="Data" sortable field="data" style={{width: "7em"}} body={(encomenda) => encomenda.data ? moment(encomenda.data).format("DD/MM/YYYY") : ""} />,
		<Column key="destinatario" header="Pessoa" style={{width: "*"}} body={(encomenda) => {
			return (
				<div>
					{encomenda.destinatario ? <div className="tzm-encomenda-pessoa destinatario">{StringUtils.simplificarNome(encomenda.destinatario)}</div> : ""}
					{encomenda.remetente ? <div className="tzm-encomenda-pessoa remetente">{StringUtils.simplificarNome(encomenda.remetente)}</div> : ""}
					<div style={{borderBottom: "1px solid rgba(0, 0, 0, .2)"}} />
				</div>
			);
		}} />,
		<Column key="etiquetas" header="Etiquetas" style={{width: "15em", textAlign: "center"}} body={(encomenda) => encomenda.etiquetas.map((etiqueta) => <EtiquetaChip etiqueta={etiqueta} />)} />,
    	<Column key="entrega" header="Entrega" sortable field="dataEntrega" style={{width: "10em"}} body={(encomenda) => encomenda.dataEntrega ? moment(encomenda.dataEntrega).format("DD/MM/YYYY HH:mm") : ""} />,
    	<Column sortable key="discriminacao" field="discriminacao" header="Discriminação" style={{width: "*"}} />,
		<Column key="acoes" field="acoes" header="Ações" style={{width: "10em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Encomenda" onClick={(event) => this.editarEncomenda(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Encomenda" onClick={(event) => this.excluirEncomenda(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Encomenda" onClick={(event) => this.restaurarEncomenda(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Encomenda" onClick={(event) => this.eliminarEncomenda(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/encomendas", entidade);
	}

	listarEncomendas = () => {

		let params = Object.assign({}, this.state.params);
		if (this.state.status) params.status  = this.state.status;
		if (this.state.inicio) params.inicio = this.state.inicio;
		if (this.state.fim) params.fim = this.state.fim;
		if (this.state.discriminacao) params.discriminacao = this.state.discriminacao;
		if (this.state.destinatario) params.destinatario = this.state.destinatario.id;
		if (this.state.etiquetas && this.state.etiquetas.length) params.etiquetas = this.state.etiquetas.map(e => e.id).join(",");
		this.encomendaService.listarDTO(params).then((encomendas) => this.setState({encomendas, firstResult: 0}));
		
	}

	excluirEncomenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta encomenda?", () => this.encomendaService.excluir(rowData).then(() => this.listarEncomendas()));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Encomendas">
					<PanelContent className="ui-g">
						<Combobox name="status" value={this.state.status} options={MEncomenda.Status} onChange={this.handleUpdate} grid={2} label="Status" />
						<DatePicker name="inicio" value={this.state.inicio} onChange={this.handleUpdate} grid={2} label="Período" />
						<DatePicker name="fim" value={this.state.fim} onChange={this.handleUpdate} grid={2} label="&nbsp;" />
						<TextField grid={6} label="Discriminação" autofocus name="discriminacao" value={this.state.discriminacao} onChange={this.handleUpdate} />
						<MultiSelectEtiqueta grid={6} label="Etiquetas" name="etiquetas" value={this.state.etiquetas} onSelect={this.handleUpdate} />
            			<SelectPessoaDTO grid={6} label="Destinatário" name="destinatario" value={this.state.destinatario} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarEncomendas} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.encomendas} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Nova Encomenda" icon="fa-plus" onClick={this.editarEncomenda} /> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarEncomendas}
						onCtrlN={() => window.location.pathname === "/encomendas" && this.security.create && this.editarEncomenda()}
						onCtrlE={() => window.location.pathname === "/encomendas" && this.state.encomendas.length === 1 && this.security.edit && this.editarEncomenda(this.state.encomendas[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}

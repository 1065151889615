import {RadioButton} from 'primereact/components/radiobutton/RadioButton';
import React from 'react';

export class Radio extends React.Component {

	render() {
		return (
			<span>
				<RadioButton inputId={this.props.id} {...this.props} />
				<label htmlFor={this.props.id} className="ui-tzm-radio-label">{this.props.label}</label>
			</span>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MAuxiliar, MDispositivo, MEquipamento, MLeitor} from "../../utils/models/MEquipamento";
import {Action} from "../commons/Action";
import {CheckSquare} from "../commons/CheckSquare";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {Radio} from "../commons/Radio";
import {LugarService} from "../servicos/LugarService";
import {EditarEquipamento} from "./EditarEquipamento";
import {SelectLugar} from "../../select/SelectLugar";

export class WizardEquipamento extends React.Component {

	state = {
		_tipo: "CATRACA",
		visible: true,
		step: 0,
		destinoEntrada: null,
		destinoSaida: null,
		familia: "NENHUMA",
		leitores: []
	};

	lugarService = new LugarService();

	onClose = () => {
		this.setState({visible: false});
	}

	pularWizard = () => {
		ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={null} onModalClose={this.props.onModalClose} />);
		this.onClose();
	}

	hasGiro = ["CATRACA", "TORNIQUETE"];

	portaLeitor = (tipo) => {
		switch (this.state.familia) {
			case "NEOKOROS":
				switch (tipo) {
					case "SENHA": return 14;
					case "PROXIMIDADE": return 20;
					case "URNA": return 19;
					case "DIGITAL": return 7;
					default:
				}
				return 0;
			case "CONTROLID_ACCESS":
			case "CONTROLID_ACCESS_PRO":
			case "CONTROLID_FLEX":
			case "CONTROLID_FIT":
			case "CONTROLID_BLOCK":
			case "CONTROLID_BLOCK_NEXT":
			case "CONTROLID_UHF":
			case "CONTROLID_FACE":	
				switch (tipo) {
					case "SENHA": return 1735747840;
					case "PROXIMIDADE": return 1835623936;					
					case "RFID": return 1919314176;
					case "URNA": return 1919314176;
					case "DIGITAL": return 1651076864;
					case "FACIAL": return 1717658368;
					case "QRCODE": return 1903321856;
					default:
				}
				break;
			case "SUPREMA":
				switch (tipo) {
					case "FACIAL": return 0;
					case "PROXIMIDADE": return 1;
					default:
				}
				break;
			default:
				return 0;
		}
	}

	proximidades = ["PROXIMIDADE", "RFID", "URNA"];

	avancarEtapa = () => {
		if (this.state.step === 2) {
			let equipamento = MEquipamento.Modelo();
			equipamento.familia = this.state.familia;
			switch (this.state._tipo) {
				case "PC":
					equipamento.leitores = [{...MLeitor.Modelo(), forma: "MANUAL", auxiliares: [{...MAuxiliar.Modelo(), atuadores: ["SOLENOID1", "SOLENOID2"]}]}];
					equipamento.dispositivos = [
						{...MDispositivo.Modelo(), sensorizacao: "GIRO", atuador: "SOLENOID1", destino: this.state.destinoEntrada},
						{...MDispositivo.Modelo(), sensorizacao: "GIRO", atuador: "SOLENOID2", destino: this.state.destinoSaida}
					];
					break;
				case "PONTO":
					equipamento.categoria = "PONTO";
					break;
				default:
					equipamento.leitores = this.state.leitores.map((leitor) => {
						return {
							...MLeitor.Modelo(),
							porta: this.portaLeitor(leitor),
							flags: leitor === "URNA" ? ["COLETOR"] : [],
							forma: this.proximidades.includes(leitor) ? "PROXIMIDADE" : leitor,
							auxiliares: [
								{
									...MAuxiliar.Modelo(),
									atuadores: this.hasGiro.includes(this.state._tipo) ? ["SOLENOID1", "SOLENOID2"] : ["RELAY1"]
								}
							]
						};
					});
					switch (this.state._tipo) {
						case "CATRACA":
							equipamento.dispositivos = [
								{...MDispositivo.Modelo(), acionamento: 15000, sensorizacao: "GIRO", atuador: "SOLENOID1", destino: this.state.destinoEntrada},
								{...MDispositivo.Modelo(), acionamento: 15000, sensorizacao: "GIRO", atuador: "SOLENOID2", destino: this.state.destinoSaida}
							];
							break;
						case "CANCELA":
							equipamento.dispositivos = [
								{...MDispositivo.Modelo(), sensorizacao: "NENHUMA", atuador: "RELAY1", destino: this.state.destinoEntrada}
							];
							break;
						case "PORTA":
							equipamento.limiteObstrucao = 120000;
							equipamento.dispositivos = [
								{...MDispositivo.Modelo(), sensorizacao: "ABERTURA", atuador: "RELAY1", destino: this.state.destinoEntrada}
							];
							break;
						default:
					}
					break;
			}
			ModalRealm.showDialog(<EditarEquipamento equipamento={equipamento} key={Math.random()} onModalClose={this.props.onModalClose} />);
			this.onClose();
		} else {
			this.setState({step: this.state.step + 1});
		}
	}

	voltarEtapa = () => {
		this.setState({step: this.state.step - 1});
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}
	listarLugares = (event) => {
		this.lugarService.listar(`search=descricao~${event.query}`, {size: 10, page: 0, sort: "descricao"}).then((destinos) => this.setState({destinos}));
	}

	handleChangeLeitores = (event) => {
		let {leitores} = this.state;
		if (event.value) {
			leitores.push(event.name);
		} else {
			leitores = leitores.filter(l => l !== event.name);
		}
		this.setState({leitores});
	}

	render() {
		let panelValue = null;
		switch (this.state.step) {
			case 0:
				panelValue = (
					<div className="ui-g">
						<div className="ui-g-12 ui-wizard-message">Qual o fabricante e tipo de equipamento você está cadastrando?</div>
						<div className="ui-g-12">
							<Label>Fabricante</Label>
							<Combobox appendTo={document.body} options={MEquipamento.Familias} name="familia" onChange={this.handleChange} value={this.state.familia} />
						</div>
						<div className="ui-g-12">
							<div><Radio checked={this.state._tipo === "CATRACA"} onChange={() => this.setState({_tipo: "CATRACA"})} label="Catraca ou Torniquete" /></div>
							<div><Radio checked={this.state._tipo === "CANCELA"}  onChange={() => this.setState({_tipo: "CANCELA"})} label="Cancela" /></div>
							<div><Radio checked={this.state._tipo === "PORTA"}  onChange={() => this.setState({_tipo: "PORTA"})} label="Porta ou Portão" /></div>
							<div><Radio checked={this.state._tipo === "PC"}  onChange={() => this.setState({_tipo: "PC"})} label="Computador ou Tablet" /></div>
							<div><Radio checked={this.state._tipo === "PONTO"} onChange={() => this.setState({_tipo: "PONTO"})} label="Relógio-Ponto" /></div>
						</div>
					</div>
				);
			break;
			case 1:
				switch (this.state._tipo) {
					case "PONTO":
						panelValue = (
							<div className="ui-g">
								<div className="ui-g-12 ui-wizard-message">Quais os lugares que este equipamento divide?</div>
								<div className="ui-g-12">Relógios-ponto não fazem controle de acesso. Clique em próximo.</div>
							</div>
						);
						break;
					default:
						panelValue = (
							<div className="ui-g">
								<div className="ui-g-12 ui-wizard-message">Quais os lugares que este equipamento divide?</div>
								<SelectLugar grid={12} label="Destino de Entrada" value={this.state.destinoEntrada} appendTo={document.body} name="destinoEntrada" onChange={this.handleChange}/>
								{this.hasGiro.includes(this.state._tipo) ? <SelectLugar grid={12} label="Destino de Saída" value={this.state.destinoSaida} appendTo={document.body} name="destinoSaida" onChange={this.handleChange}/> : <div className="ui-g-12"/>}
							</div>
						);
						break;
				}
			break;
			case 2:
				switch (this.state._tipo) {
					case "PC":
						panelValue = (
							<div className="ui-g">
								<div className="ui-g-12 ui-wizard-message">Que tipos de leitores este equipamento possui?</div>
								<div className="ui-g-12">Computadores e tablets só fazem acesso manualmente. Clique em próximo.</div>
							</div>
						);
						break;
					case "PONTO":
						panelValue = (
							<div className="ui-g">
								<div className="ui-g-12 ui-wizard-message">Que tipos de leitores este equipamento possui?</div>
								<div className="ui-g-12">Relógios-ponto não precisam de configuração. Clique em próximo.</div>
							</div>
						);
						break;
					default:
						panelValue = (
							<div className="ui-g">
								<div className="ui-g-12 ui-wizard-message">Que tipos de leitores este equipamento possui?</div>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("SENHA")} name="SENHA" label="Teclado"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("PROXIMIDADE")} name="PROXIMIDADE" label="Leitor de Cartões MiFare"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("RFID")} name="RFID" label="Leitor de Cartões RFID"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("URNA")} name="URNA" label="Urna Coletora de Cartões"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("DIGITAL")} name="DIGITAL" label="Leitor de Biometria Digital"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("FACIAL")} name="FACIAL" label="Leitor de Biometria Facial"/>								
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("FACIAL_W24")} name="FACIAL_W24" label="Leitor de Biometria Facial (W24)"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("CONTROLE")} name="CONTROLE" label="Antena de Controle Remoto"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("TAG")} name="TAG" label="Antena de Tag Veicular"/>
								<CheckSquare grid={6} onChange={this.handleChangeLeitores} checked={this.state.leitores.includes("QRCODE")} name="QRCODE" label="Leitor de QR Code"/>
							</div>
						);
						break;
				}
				break;
			default:
		}
		return (
			<Dialog modal header="Assistente para Cadastro de Equipamento" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					{panelValue}
				</DialogContent>
				<DialogFooter>
					<Action style={{float: "left"}} onClick={this.pularWizard} label="Pular Assistente" icon="fa-times" />
					<Action mode="secondary" disabled={this.state.step === 0} onClick={this.voltarEtapa} label="Anterior" icon="fa-arrow-left" />
					<Action onClick={this.avancarEtapa} label="Próximo" icon="fa-arrow-right" />
				</DialogFooter>
			</Dialog>
		);
	}

}

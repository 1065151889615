import React from "react";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {Combobox} from "../commons/Combobox";
import {Button} from "primereact/components/button/Button";
import {optionsFinalidade} from "../../utils/models/MFoto";
import {Action} from "../commons/Action";
import { Fetch } from "../../utils/Fetch";
import { ModalRealm } from "../commons/ModalRealm";

export class FotoControliD extends React.Component {

    state = {
        equipamento: null,
        equipamentos: [],
        flip: false,
        rotation: 0,
        webcamKey: null,
        className: "",
        imagem: null,
        finalidade: window.localStorage.getItem("X-Foto-Finalidade") || "IDENTIFICACAO",
        frameWidth: ((Math.max(this.props.width, this.props.height) - Math.min(this.props.width, this.props.height)) / 2) + 10,
        frameHeight: Math.max(this.props.width, this.props.height) + 20,
        disableCaptura: false
    };

    equipamentoService = new EquipamentoService();

    componentDidMount() {
        this.equipamentoService.listar("search=familia:CONTROLID_FACE", {page: 0, size: 1000}).then(equipamentos => {
            const equipamento = equipamentos.filter(e => e.id === Number(window.localStorage.getItem("X-Foto-CameraID") || "0"))[0];
            this.setState({equipamento, equipamentos: equipamentos.map(e => ({label: e.descricao, value: e}))});
        });
    }

    handleChange = e => {
        this.setState({[e.name]: e.value});
        if (e.name === "equipamento") {
            window.localStorage.setItem("X-Foto-CameraID", e.value?.id);
        }
    }

    iniciarCaptura = () => {
        const equipamento = this.state.equipamento;
        this.setState({disableCaptura: true});
        const endereco = (
            equipamento.controlador.alias || equipamento.controlador.standalone || equipamento.controlador.endereco
        );
        Fetch.Post(`${endereco}/snapshot`, equipamento).then((response) => {
            if (response.status === 200) {
                response.text().then(conteudo => {
                    if (conteudo?.length) {
                        this.setState({imagem: `data:image/jpeg;base64,${conteudo}`, disableCaptura: false});
                        if (this.props.onPhotoTaken) {
                            this.props.onPhotoTaken({
                                finalidade: this.state.finalidade,
                                conteudo
                            });
                        }
                    } else {
                        ModalRealm.showInformacao("warn", ["Não foi possível capturar a foto no equipamento. Por favor, tente novamente."]);
                        this.setState({disableCaptura: false});
                    }
                });
            } else {
                ModalRealm.showInformacao("warn", ["Falha na comunicação com o equipamento!"]);
                this.setState({disableCaptura: false});
            }
        }).catch(() => {
            ModalRealm.showInformacao("warn", ["Falha na comunicação com o equipamento!"]);
            this.setState({disableCaptura: false});
        });
    }

    render() {
        const styles = {
            border: this.state.imagem?.length ? "3px solid #4CAF50" : "3px solid #F44336",
            width: `${this.props.width}px`,
            height: `${this.props.height}px`
        };
        return (
            <div className="ui-g">
                <div className="ui-g-12 ui-g ui-g-nopad" style={{textAlign: "left"}}>
                    <Combobox
                        appendTo={document.body}
                        label="Equipamento"
                        grid={8}
                        options={this.state.equipamentos}
                        value={this.state.equipamento}
                        name="equipamento"
                        onChange={this.handleChange}
                    />
                    <Combobox grid={4} label="Finalidade" disabled={this.state.imagem?.length} appendTo={document.body} value={this.state.finalidade} options={optionsFinalidade} onChange={this.handleChange} name="finalidade"/>
                </div>
                <div className="ui-g-12" style={{paddingTop: `${this.state.frameWidth}px`, height: `${this.state.frameHeight}px`}}>
                    <div className="ui-tzm-webcam-container-image with-button">
                        <div className={`foto-camera-frame ${this.state.finalidade?.toLowerCase()}`}>
                            <img className={`ui-webcam-camtotem${this.state.className}${this.state.flip ? "-flip" : ""}`} src={this.state.imagem || this.state.preview} alt="" style={styles}/>
                        </div>
                        <Action disabled={this.state.disableCaptura} label="Iniciar Captura" icon="fad fa-camera" onClick={this.iniciarCaptura}/>
                        {
                            this.state.disableCaptura
                                ? (
                                    <div className="controlid-spinner">
                                        <i className="fad fa-spin fa-spinner"/><br/>
                                        <span>Por favor, aguarde. Foto sendo capturada.</span>
                                    </div>
                                )
                                : null
                        }
                    </div>
                </div>
                <div className="ui-g-12" style={{textAlign: "center"}}>
                    <Button disabled={!this.state.imagem?.length} title="Tentar novamente" onClick={() => this.setState({imagem: null}, this.updateCamera)} label=" " icon="fa-plus"/>
                    <Button disabled={this.state.imagem?.length} title="Girar para a esquerda" onClick={() => this.setState({rotation: (this.state.rotation + 270) % 360, className: ` ui-tzm-webcam-${(this.state.rotation + 270) % 360}`})} label=" " icon="fa-undo"/>
                    <Button disabled={this.state.imagem?.length} title="Inverter horizontalmente" onClick={() => this.setState({flip: !this.state.flip})} label=" " icon="fa-arrows-alt-h"/>
                    <Button disabled={this.state.imagem?.length} title="Girar para a direita" onClick={() => this.setState({rotation: (this.state.rotation + 90) % 360, className: ` ui-tzm-webcam-${(this.state.rotation + 90) % 360}`})} label=" " icon="fa-redo"/>
                </div>
            </div>
        );
    }

}

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";
import {GrupoTrabalhoService} from "../components/servicos/GrupoTrabalhoService";
import {MGrupoTrabalho} from "../utils/models/MGrupoTrabalho";
import {EditarGrupoTrabalho} from "../components/GrupoTrabalho/EditarGrupoTrabalho";

export class SelectGrupoTrabalho extends React.Component {

	service = new GrupoTrabalhoService();

	security = UserData.security("GRT");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarGrupoTrabalho key={Math.random()} grupoTrabalho={{...MGrupoTrabalho.Modelo(), descricao: event.value.descricao.replace(`Criar grupo de trabalho `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}}/>);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select entidade="grupo de trabalho" insertable={this.security.create && this.props.insertable} service={this.service} field="descricao" query="descricao" onSelect={this.onSelect} {...this.props} />;
	}

}

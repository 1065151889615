import {httpPost} from "../../utils/Request";

const baseUrl = process.env.REACT_APP_API_URL;

export class LoginService {

	login(form) {
		return fetch(baseUrl + "/login", {method: "post", body: JSON.stringify({login: form.login, senha: form.senha})}).then((response) => {
			switch (response.status) {
				case 200:
					window.localStorage.setItem("safety-authorization-token", response.headers.get("Authorization") || "");
					window.localStorage.setItem("safety-schema-name", response.headers.get("Schema-Name") || "");
					break;
				case 403:
					window.alert("Credenciais inválidas. Por favor, tente novamente.");
					break;
				default:
			}
			return response;
		});
	}

	registrar(loginValues) {
		return httpPost("/register", loginValues).then(response => response);
	}

}

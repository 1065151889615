import {Tooltip} from "primereact/components/tooltip/Tooltip";
import React from "react";
import iconeAgendaAtivado from "../../media/icones/agenda-ativado.png";
import iconeAgendaCancelado from "../../media/icones/agenda-cancelado.png";
import iconeAgendaExcluido from "../../media/icones/agenda-excluido.png";
import iconeAgendaExpirado from "../../media/icones/agenda-expirado.png";
import iconeAgendaPendente from "../../media/icones/agenda-pendente.png";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MAviso {

	static Modelo() {
		return {
			status: "ATIVADO",
			tipo: "ALERTA",
			fonte: "#000000",
			fundo: "#FFC107"
		};
	}

	static Tipos = [
		{label: "Alerta", value: "ALERTA"},
		{label: "Liberação", value: "LIBERACAO"}
	];

	static MapTipos = {
		ALERTA: "Alerta",
		LIBERACAO: "Liberação"
	};

	static Status = [
		{label: "Ativado", value: "ATIVADO"},
		{label: "Pendente", value: "PENDENTE"},
		{label: "Cancelado", value: "CANCELADO"},
		{label: "Excluído", value: "EXCLUIDO"}
	];

	static Flags = [
		{label: "Dispensa confirmação", value: "IMEDIATO"},
		{label: "Pode entrar acompanhado", value: "ACOMPANHAMENTO"}
	];

	static cleanupBeforeSave(aviso) {
		if (!Validator.isEntidadeDependente(aviso.pessoa)) aviso.pessoa = null;
		if (!Validator.isEntidade(aviso.etiqueta)) aviso.etiqueta = null;
		return MEntidade.cleanupBeforeSave(aviso);
	}

	static validarParaSalvar(aviso) {
		let messages = [];
		if (aviso.pessoa == null) messages.push("A pessoa relacionada ao aviso é obrigatória");
		if (aviso.tipo === "ALERTA" && Validator.isEmpty(aviso.texto)) messages.push("Para alertas, o texto é obrigatório");
		return messages;
	}

	static SwitchStatus = (rowData) => {
		let status = rowData.status;
		let title = "Desativado";
		let icone;
		if (status) {
			switch (status) {
				case "PENDENTE":
					icone = iconeAgendaPendente;
					title = "Pendente";
					break;
				case "EXCLUIDO":
					icone = iconeAgendaExcluido;
					title = "Excluído";
					break;
				case "CANCELADO":
					icone = iconeAgendaCancelado;
					title = "Cancelado";
					break;
				case "ATIVADO":
					if (rowData.fim && Date.parse(rowData.fim) < new Date()) {
						icone = iconeAgendaExpirado;
						title = "Expirado";
					} else {
						icone = iconeAgendaAtivado;
						title = "Ativado";
					}
					break;
				default:
			}
		}
		let id = "statusAviso" + Math.round(Math.random() * 10000);
		return (
			<div id={id} style={{textAlign: "center"}}>
				<img alt="" style={{verticalAlign: "middle"}} src={icone} />
				<Tooltip for={`#${id}`} title={title} tooltipPosition="top" />
			</div>
		);
	}

}

import React from "react";

export class HelpAvaliacaoServico extends React.Component {

    render() {
        return (
            <div>
                <p>A avaliação de serviço permite que um contratante faça uma avaliação à um serviço prestado internamente, registrando o no sistema, podendo ser consultado para uma seleção de serviços já prestados, de maneira que melhore a qualidade e promova uma fluidez nos contratos.
                    </p>
                <p>
                    Esta tela permite consultar avaliações de serviços existentes no sistema. A avaliação é feita somente por link enviado no e-mail do contratante após o encerramento de uma agenda de serviço.
				</p>

                <h2>Como utilizar os campos de filtragem?</h2>
                <ul>
                    <li><em>Prestador</em>:
						é o campo utilizado para consultar por prestadores, basta inserir um pedaço do nome e pressionar "Enter" ou clicar no botão "Procurar".
					</li>
                    <li><em>Contratante</em>:
                        é o campo utilizado para consultar por contratantes, basta inserir um pedaço do nome e pressionar "Enter" ou clicar no botão "Procurar".
					</li>
                    <li><em>Nota Mínima</em>:
                         é o campo que determina a nota mínima dos serviços a serem procurados, bastando inserir uma quantidade de estrelas de 1 a 5 e pressionar "Enter" ou clicar no botão "Procurar".
					</li>
                    <li><em>Etiquetas</em>:
						busca por etiquetas de unidade, a fim de identificar qual unidade será responsável pelo acesso da(s) pessoa(s) desta agenda. Essa identificação é importante para condomínios onde o acesso
						ao perímetro depende de autorização do responsável pela unidade.
					</li>
                </ul>  
                <h1>Perguntas frequentes</h1>
                <ol>
                    <li>
                        <em>Como faço uma avaliação de serviço?</em>
                        <div>
                            Caso seja responsável por uma Unidade local, ao término de uma agenda após uma visita de um prestador com a etiqueta com descrição de serviço, será enviado um link para o e-mail do contratante, que permite acessar e avaliar um serviço por meio de uma tela específica.
						</div>
                    </li>
                </ol>
            </div>
        );
    }
}

import React from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";

const modalIndex = observable({value: 0});

export const Shortcut = observer(class extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modalIndex: 0
		};
		document.addEventListener("keydown", this.keyPress);
		++modalIndex.value;
	}

	componentDidMount() {
		if (!this.props.master) {
			this.setState({modalIndex: modalIndex.value});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyPress);
		--modalIndex.value;
	}

	keyPress = (event) => {
		if (this.props.disabled) {
			return;
		}
		if (event.ctrlKey) {
			if (event.shiftKey) {
				switch (event.keyCode) {
					case 112: // F1
						return this.dispatchEvent(event, "onShiftCtrlF1");
					case 113: // F2
						return this.dispatchEvent(event, "onShiftCtrlF2");
					case 114: // F3
						return this.dispatchEvent(event, "onShiftCtrlF3");
					case 115: // F4
						return this.dispatchEvent(event, "onShiftCtrlF4");
					case 116: // F5
						return this.dispatchEvent(event, "onShiftCtrlF5");
					case 117: // F6
						return this.dispatchEvent(event, "onShiftCtrlF6");
					case 118: // F7
						return this.dispatchEvent(event, "onShiftCtrlF7");
					case 119: // F8
						return this.dispatchEvent(event, "onShiftCtrlF8");
					case 120: // F9
						return this.dispatchEvent(event, "onShiftCtrlF9");
					case 121: // F10
						return this.dispatchEvent(event, "onShiftCtrlF10");
					case 122: // F11
						return this.dispatchEvent(event, "onShiftCtrlF11");
					case 123: // F12
						return this.dispatchEvent(event, "onShiftCtrlF12");
					default:
				}
			} else {
				switch (event.keyCode) {
					case 48: // 0
						return this.dispatchEvent(event, "onCtrl0");
					case 49: // 1
						return this.dispatchEvent(event, "onCtrl1");
					case 50: // 2
						return this.dispatchEvent(event, "onCtrl2");
					case 52: // 3
						return this.dispatchEvent(event, "onCtrl3");
					case 53: // 4
						return this.dispatchEvent(event, "onCtrl4");
					case 54: // 5
						return this.dispatchEvent(event, "onCtrl5");
					case 55: // 6
						return this.dispatchEvent(event, "onCtrl6");
					case 56: // 7
						return this.dispatchEvent(event, "onCtrl7");
					case 57: // 8
						return this.dispatchEvent(event, "onCtrl8");
					case 58: // 9
						return this.dispatchEvent(event, "onCtrl9");
					case 69: // E
						return this.dispatchEvent(event, "onCtrlE");
					case 78: // N
						return this.dispatchEvent(event, "onCtrlN");
					case 81: // S
						return this.dispatchEvent(event, "onCtrlQ");
					case 83: // S
						return this.dispatchEvent(event, "onCtrlS");
					case 112: // F1
						return this.dispatchEvent(event, "onCtrlF1");
					case 113: // F2
						return this.dispatchEvent(event, "onCtrlF2");
					case 114: // F3
						return this.dispatchEvent(event, "onCtrlF3");
					case 115: // F4
						return this.dispatchEvent(event, "onCtrlF4");
					case 116: // F5
						return this.dispatchEvent(event, "onCtrlF5");
					case 117: // F6
						return this.dispatchEvent(event, "onCtrlF6");
					case 118: // F7
						return this.dispatchEvent(event, "onCtrlF7");
					case 119: // F8
						return this.dispatchEvent(event, "onCtrlF8");
					case 120: // F9
						return this.dispatchEvent(event, "onCtrlF9");
					case 121: // F10
						return this.dispatchEvent(event, "onCtrlF10");
					case 122: // F11
						return this.dispatchEvent(event, "onCtrlF11");
					case 123: // F12
						return this.dispatchEvent(event, "onCtrlF12");
					default:
				}
			}
		} else {
			switch (event.keyCode) {
				case 13: // Enter
					return this.dispatchEvent(event, "onEnter");
				case 78: // N
					return this.dispatchEvent(event, "onN");
				case 83: // S
					return this.dispatchEvent(event, "onS");
				case 27: // Esc
					return this.dispatchEvent(event, "onEscape");
				case 112: // F1
					return this.dispatchEvent(event, "onF1");
				case 113: // F2
					return this.dispatchEvent(event, "onF2");
				case 114: // F3
					return this.dispatchEvent(event, "onF3");
				case 115: // F4
					return this.dispatchEvent(event, "onF4");
				case 116: // F5
					return this.dispatchEvent(event, "onF5");
				case 117: // F6
					return this.dispatchEvent(event, "onF6");
				case 118: // F7
					return this.dispatchEvent(event, "onF7");
				case 119: // F8
					return this.dispatchEvent(event, "onF8");
				case 120: // F9
					return this.dispatchEvent(event, "onF9");
				case 121: // F10
					return this.dispatchEvent(event, "onF10");
				case 122: // F11
					return this.dispatchEvent(event, "onF11");
				case 123: // F12
					return this.dispatchEvent(event, "onF12");
				default:
			}
		}
	}

	dispatchEvent = (event, eventName) => {
		if ((this.props.master || modalIndex.value === this.state.modalIndex) && this.props[eventName]) {
			event.preventDefault();
			event.stopPropagation();
			this.props[eventName]();
		}
	}

	render() {
		return <div/>;
	}

});

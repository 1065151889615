import React from "react";

export class WebCamera extends React.Component {

    state = {
        intervalHandler: null
    };

    refVideo = (el) => this.video = el;

    takeScreenshot = () => {
        let canvas = document.createElement("canvas");
        canvas.width = 640;
        canvas.height = 480;
        canvas.getContext("2d").drawImage(this.video, 0, 0, 640, 480);
        return canvas.toDataURL();
    }

    setStream = (stream) => {
        this.video.srcObject = stream;
    }

    onPlay = (handler) => {
        this.video.addEventListener("play", async () => {
            const intervalHandler = setInterval(() => {
                handler();
            }, 300);
            this.setState({intervalHandler: intervalHandler});
        });
    }

    render() {
        return <video {...this.props} autoPlay ref={this.refVideo} width={640} height={480} />;
    }

}

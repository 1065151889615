import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarFormulario} from "../components/Formulario/EditarFormulario";
import {FormularioService} from "../components/servicos/FormularioService";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";
import {criarFormulario} from "../utils/models/MFormulario";

export class SelectFormulario extends React.Component {

	service = new FormularioService();

	security = UserData.security("FRM");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarFormulario key={Math.random()} formulario={{...criarFormulario(), descricao: event.value.descricao.replace(`Criar formulário `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select label={this.props.label || "Formulário"} entidade="formulário" insertable={this.security.create && this.props.insertable} service={this.service} field="descricao" query="descricao" onSelect={this.onSelect} {...this.props} />;
	}

}

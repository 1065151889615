import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {LocalTime} from "../../utils/DateUtils";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EspacoService} from "../servicos/EspacoService";
import {PeriodoService} from "../servicos/PeriodoService";
import {EditarEspaco} from "./EditarEspaco";

export class Espaco extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	periodoService = new PeriodoService();
	espacoService = new EspacoService();
	security = UserData.security("ESP", true);

	listarEspacos = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.espacoService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((espacos) => this.setState({espacos, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarEspaco = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.espacoService.buscar(rowData).then((espaco) => {
				ModalRealm.showDialog(<EditarEspaco key={Math.random()} espaco={espaco} onModalClose={this.listarEspacos} />);
			});
		} else {
			ModalRealm.showDialog(<EditarEspaco key={Math.random()} espaco={null} onModalClose={this.listarEspacos} />);
		}
	}

	excluirEspaco = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este espaço?", () => this.espacoService.excluir(rowData).then(this.listarEspacos));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column header="Descrição"  style={{width: "*"}} sortable field="descricao" />,
		<Column header="Períodos" style={{width: "*"}} body={(espaco) => espaco.periodos ? espaco.periodos.map(p => <div>{this.periodoService.simplificarDiasSemana(p) + ", das " + LocalTime.withoutSeconds(p.inicio) + " às " + LocalTime.withoutSeconds(p.fim)}</div>) : null} />,
		<Column header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Espaço" onClick={(event) => this.editarEspaco(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Espaço" onClick={(event) => this.excluirEspaco(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Espaço" onClick={(event) => this.restaurarEspaco(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Espaço" onClick={(event) => this.eliminarEspaco(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/espacos", entidade);
	}

	restaurarEspaco = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este espaço?", () => {
			if (rowData != null && rowData.id != null) {
				this.espacoService.buscar(rowData).then((espaco) => {
					espaco.status = "ATIVADO";
					this.espacoService.salvar(espaco).then(this.listarEspacos);
				});
			}
		});
	}

	eliminarEspaco = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este espaço?", () => this.espacoService.eliminar(rowData).then((result) => {
			if (result) this.listarEspacos(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este espaço: há outros registros dependentes dele no sistema."]);
		}));
	}

	render() {
		return (
			<div>
				<Panel header="Espaços">
					<PanelContent>
						<TextField label="Descrição" grid={12} autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarEspacos} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.espacos} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Espaço" icon="fa-plus" onClick={this.editarEspaco} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarEspacos}
					onCtrlN={() => window.location.pathname === "/espacos" && this.editarEspaco()}
					onCtrlE={() => window.location.pathname === "/espacos" && this.state.espacos.length === 1 && this.editarEspaco(this.state.espacos[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

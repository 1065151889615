import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MLugar {

	static Modelo() {
		return {
			descricao: "",
			tipo: "NORMAL",
			lotacao: 0,
			tarifas: []
		};
	}

	static Tipos = [
		{label: "Normal", value: "NORMAL"},
		{label: "Garagem", value: "GARAGEM"},
		{label: "Externo", value: "EXTERNO"},
		{label: "Tarifado", value: "TARIFADO"}
	];

	static cleanupBeforeSave(lugar) {
		if (!Validator.isEntidade(lugar.localidade)) lugar.localidade = null;
		return MEntidade.cleanupBeforeSave(lugar);
	}

	static validarParaSalvar(lugar) {
		let messages = [];
		if (Validator.isEmpty(lugar.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeCartao from "../../media/icones/cartao.png";
import iconeCredenciais from "../../media/icones/credenciais.png";
import iconeCriarSenha from "../../media/icones/criar-senha.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeEmpresa from "../../media/icones/empresas.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeAutorizacao from "../../media/icones/autorizacao.png";
import iconePessoaDentro from "../../media/icones/pessoa-dentro.png";
import iconePessoaFora from "../../media/icones/pessoa-fora.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {AppletUtils} from "../../utils/AppletUtils";
import {GUI} from "../../utils/Constants";
import {LocalTime} from "../../utils/DateUtils";
import {MAgenda} from "../../utils/models/MAgenda";
import {iconVehicle, MVeiculo} from "../../utils/models/MVeiculo";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CriarSenha} from "../Credencial/CriarSenha";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {VCard} from "../Portaria";
import {AgendaService} from "../servicos/AgendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {PeriodoService} from "../servicos/PeriodoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {EditarAgenda} from "./EditarAgenda";
import {WizardAgenda} from "./WizardAgenda";
import {EditarAutorizacao} from "./EditarAutorizacao";
import {LugarService} from "../servicos/LugarService";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {isDentro} from "../../utils/PessoaFisicaUtils";
import {httpGet} from "../../utils/Request";
import {EditarPessoaJuridica} from "../PessoaJuridica/EditarPessoaJuridica";

export class Agenda extends React.Component {

	state = {
		pessoa: "",
		empresa: "",
		status: "ATIVADO",
		etiquetas: [],
		validade: "ATIVA",
		firstResult: 0,
		modelosAgenda: [],
		veiculo: "",
		expanded: (window.localStorage.getItem("X-Agenda-Expanded") || "false") === "true",
		lockEtiquetas: false
	};

	agendaService = new AgendaService();
	etiquetaService = new EtiquetaService();
	pessoaFisicaService = new PessoaFisicaService();
	periodoService = new PeriodoService();
	security = UserData.security("SCH", true);
	securityPessoa = UserData.security("PES");
	securityEmpresa = UserData.security("EMP");
	securityCredencial = UserData.security("CRD");
	modeloAgendaService = new ModeloAgendaService();
	lugarService = new LugarService();

	componentDidMount() {
		this.modeloAgendaService.listar("", {page: 0, size: 100}).then(modelosAgenda => {
			const etiquetas = UserData.operador.etiquetas;
			this.setState({modelosAgenda, etiquetas, lockEtiquetas: etiquetas.length});
		});
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page: 0, size: 10}).then((setiquetas) => this.setState({setiquetas}));
	}

	listarAgendas = () => {
		const params = [];
		if (this.state.status?.length) params.push(`status=${this.state.status}`);
		if (this.state.pessoa?.length) params.push(`pessoa=${this.state.pessoa}`);
		if (this.state.empresa?.length) params.push(`empresa=${this.state.empresa}`);
		if (this.state.etiquetas?.length) params.push(`etiquetas=${this.state.etiquetas.map(e => e.id).join(",")}`);
		if (this.state.inicio?.length) params.push(`inicio=${this.state.inicio}`);
		if (this.state.fim?.length) params.push(`fim=${this.state.fim}`);
		if (this.state.iniciov?.length) params.push(`v_inicio=${this.state.iniciov}`);
		if (this.state.fimv?.length) params.push(`v_fim=${this.state.fimv}`);
		if (this.state.veiculo?.length) params.push(`veiculo=${this.state.veiculo}`);
		switch (this.state.validade) {
			case "ATIVA":
				params.push("ativa=true");
				break;
			case "EXPIRADA":
				params.push("expirada=true");
				break;
			case "INDETERMINADA":
				params.push("indeterminada=true");
				break;
			default:
				break;
		}
		this.agendaService.listarAlt(`${params.join("&")}&size=${400}`).then(agendas => this.setState({agendas, firstResult: 0}));
	}

	editarAgendaComFocoEmPessoa = agenda => {
		this.editarAgenda(agenda, true);
	}

	editarAgenda = (agenda, focoEmPessoa = false) => {
		if (agenda?.id) {
			this.agendaService.buscar(agenda).then(agenda => {
				ModalRealm.showDialog(<EditarAgenda activeIndex={focoEmPessoa ? 1 : 0} key={Math.random()} agenda={agenda} onSave={this.listarAgendas}/>);
			});
		} else {
			agenda = MAgenda.Modelo();
			if (this.state.lockEtiquetas) {
				agenda.etiquetas = this.state.etiquetas;
			}
			ModalRealm.showDialog(<EditarAgenda activeIndex={focoEmPessoa ? 1 : 0} key={Math.random()} agenda={agenda} onSave={this.listarAgendas}/>);
		}
	}

	excluirAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta agenda?", () => this.agendaService.excluir(rowData).then(() => this.listarAgendas()));
	}

	editarPessoa = (pessoa, agenda) => {
		const etiquetas = agenda?.etiquetas?.filter(e => !e.flags?.includes("UNIDADE")).map(e => e.descricao);
		const modeloAgenda = this.state.modelosAgenda?.filter(ma => etiquetas.includes(ma.descricao))[0];
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(<EditarPessoaFisica obrigatorios={modeloAgenda?.obrigatorios} key={Math.random()} pessoa={pessoa} onModalClose={(pessoa) => {
				let agendas = this.state.agendas;
				agendas.filter((a) => a.pessoas.some((p) => p.id === pessoa.id)).forEach((agenda) => {
					let index = agenda.pessoas.map(p => p.id).indexOf(pessoa.id);
					agenda.pessoas[index] = pessoa;
				});
				this.setState({agendas});
			}} />);
		});
	}

	gerenciarCredenciais = (pessoa) => {
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(<EditarPessoaFisica activeTab={3} params={{flags: "TEMPORARIA"}} key={Math.random()} pessoa={pessoa} />);
		});
	}

	criarSenha = (rowData) => {
		ModalRealm.showDialog(<CriarSenha key={Math.random()} pessoa={rowData} />);
	}

	cadastrarDigital = (rowData) => {
		AppletUtils.downloadFingerprint(rowData);
	}

	editarEmpresa = empresa => {
		httpGet(`/pessoas-juridicas/${empresa.id}`).then(empresa => {
			ModalRealm.showDialog(<EditarPessoaJuridica pessoa={empresa} onModalClose={empresa => {
				const {agendas} = this.state;
				for (const agenda of agendas) {
					for (const pessoa of agenda.pessoas) {
						if (pessoa.empresa?.id === empresa.id) {
							pessoa.empresa = empresa;
						}
					}
				}
				this.setState({agendas});
			}}/>);
		});
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column style={{width: "4em"}} key="status" header="Status" field="status" body={MAgenda.SwitchStatus} />,
		<Column style={{width: "*"}} key="pessoas" header="Dados Principais" body={(rowData, column) => {
			const pessoasAtivas = rowData.pessoas.filter((p) => p.status === "ATIVADO" || this.state.excluidos).sort((a, b) => a.nome.localeCompare(b.nome));
			let exceed = 0;
			if (pessoasAtivas.length > (this.state.expanded ? 1000 : 5)) {
				exceed = pessoasAtivas.length - 5;
				pessoasAtivas.length = 5;
			}
			let pessoas = pessoasAtivas.map((pessoa) => {
				let id = `${pessoa.id}_${column.rowIndex}`;
				return (
					<div key={id} style={{position: "relative"}}>
						<div onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})} onMouseLeave={() => this.setState({vcard: 0})} className={`ui-portaria-item-pessoa visitante ${pessoa.status === "ATIVADO" ? "" : "agenda-pessoa-desativada"}`}>
							<img className="ui-portaria-icone-posicao" src={isDentro(pessoa) ? iconePessoaDentro : iconePessoaFora} alt="" />
							{pessoa.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}} /> </span> : null}
							{pessoa.flags.includes("MASTER") ? <span><i title="Acesso Livre" className="fad fa-crown crown-color" /> </span> : null}
							{pessoa.nascimento && pessoa.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}} /> </span> : null}
							{pessoa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}} /> </span>)}
							<span>{pessoa.nome}</span>
							<br />
							<div className="ui-portaria-pessoa-acao-container">
								{this.securityPessoa.edit ? <img title="Editar Pessoa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarPessoa(pessoa, rowData)} /> : null}
								{this.securityCredencial.create ? <img title="Cadastrar Senha" className="ui-portaria-pessoa-acao" src={iconeCriarSenha} alt="" onClick={() => this.criarSenha(pessoa)} /> : null}
								{this.securityCredencial.edit ? <img title="Gerenciar Credenciais" className="ui-portaria-pessoa-acao" src={iconeCartao} alt="" onClick={() => this.gerenciarCredenciais(pessoa)} /> : null}
								{this.securityCredencial.create ? <img title="Cadastrar Digital" className="ui-portaria-pessoa-acao" src={iconeCredenciais} alt="" onClick={() => this.cadastrarDigital(pessoa)} /> : null}
							</div>
						</div>
						<VCard pessoa={pessoa} active={this.state.vcard === id} />
					</div>
				);
			});
			if (exceed) {
				pessoas.push(
					<div className="ui-portaria-item-pessoa visitante exceed">
						<span onClick={() => this.editarAgendaComFocoEmPessoa(rowData)} style={{cursor: "pointer"}}>... e mais {exceed} pessoas</span>
					</div>
				);
			}
			if (rowData.veiculos && rowData.veiculos.length) {
				rowData.veiculos.forEach((v) => {
					const id = `${v.placa}_vc${column.rowIndex}`;
					pessoas.push(
						<div key={id} style={{position: "relative"}}>
							<div className="ui-portaria-item-pessoa veiculo" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
								{iconVehicle(v)}
								{v.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								<span>{`${v.placa} (${v.fabricante?.nome || "Fabricante"} - ${v.modelo || "Modelo"}${(" " + (MVeiculo.MapCores[v.cor] || "")).trimEnd()})`}</span>
							</div>
						</div>
					);
				});
			}
			if (rowData.visitados && rowData.visitados.length) {
				pessoas.push(<div key={-3} className="ui-portaria-item-pessoa-end spaced" />);
				pessoas.push(...rowData.visitados.map((pessoa) => {
					let id = `${pessoa.id}_${column.rowIndex}`;
					return (
						<div key={pessoa.id} style={{position: "relative"}}>
							<div onMouseOver={() => this.setState({vcard: id})} onMouseOut={() => this.setState({vcard: 0})} className={`ui-portaria-item-pessoa visitado`}>
								<img className="ui-portaria-icone-posicao" src={isDentro(pessoa) ? iconePessoaDentro : iconePessoaFora} alt="" />
								{pessoa.nome}
							</div>
							<VCard pessoa={pessoa} active={this.state.vcard === id} />
						</div>
					);
				}));
			}
			if (rowData.empresa?.id) {
				const id = `${column.rowIndex}_age${rowData.empresa.id}`;
				pessoas.unshift(
					<div key={id} style={{position: "relative"}}>
						<div key={id} className="ui-portaria-item-pessoa empresa" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
							<img className="ui-portaria-icone-posicao" style={{marginTop: "0"}} src={iconeEmpresa} alt="" />
							{rowData.empresa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
							<span>{rowData.empresa.nome}</span>
							{rowData.empresa.telefone ? <div className="fone-empresa">{rowData.empresa.telefone}</div> : null}
							<div className="ui-portaria-pessoa-acao-container">
								{this.securityEmpresa.edit ? <img title="Editar Empresa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarEmpresa(rowData.empresa)} /> : null}
							</div>
						</div>
					</div>
				);
			} else {
				if (rowData.pessoas.some(p => p.empresa)) {
					const es = rowData.pessoas.filter((p) => p.empresa).map((p) => p.empresa).filter((e, i, a) => a.map((e) => e.id).indexOf(e.id) === i);
					pessoas.unshift(...es.map((e) => {
						const id = `${e.id}_ve${column.rowIndex}`;
						return (
							<div key={id} style={{position: "relative"}}>
								<div key={e.id} className="ui-portaria-item-pessoa empresa" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
									<img className="ui-portaria-icone-posicao" style={{marginTop: "0"}} src={iconeEmpresa} alt="" />
									{e.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
									<span>{e.nome}</span>
									{e.telefone ? <div className="fone-empresa">{e.telefone}</div> : null}
									<div className="ui-portaria-pessoa-acao-container">
										{this.securityEmpresa.edit ? <img title="Editar Empresa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarEmpresa(e)} /> : null}
									</div>
								</div>
							</div>
						);
					}));
				}
			}
			if (rowData.flags?.length || rowData.etiquetas?.length) {
				pessoas.unshift(
					<div className="panel-agenda-etiquetas">
						{rowData.flags?.map(f => MAgenda.IconesOpcoes[f])}
						{rowData.etiquetas?.map((e) => <EtiquetaChip key={e.id} etiqueta={e}/>)}
					</div>
				);
			}
			if (!pessoas.length) {
				pessoas.push(<div>Nenhuma pessoa adicionada</div>);
			} else {
				pessoas.push(<div key={-1} className="ui-portaria-item-pessoa-end" />);
			}
			return pessoas;
		}} />,
		<Column key="modelosAcesso" header="Controle de Acesso" style={{maxWidth: "25em"}} body={(a) => {
			let equipamentos = [];
			a.modelosAcesso.forEach(ma => equipamentos.push(...ma.equipamentos));
			equipamentos = equipamentos.filter((e, i) => equipamentos.indexOf(e) === i);
			return (
				<div>
					<div className="agenda-modelos-acesso-top"/>
					<b>Validade</b>: {a.validade ? moment(a.validade).format("DD/MM/YYYY HH:mm") :
					<span style={{color: "#888888"}}>Indeterminada</span>}
					<div className="agenda-modelos-acesso-top"/>
					{
						a.modelosAcesso.filter(ma => ma.lugares.length).map(ma => (
							<div className="agenda-modelos-acesso" key={ma.id}>
								<b>{ma.descricao}</b>
								{ma.periodos.map(h => <div>{this.periodoService.simplificarDiasSemana(h) + ", das " + LocalTime.withoutSeconds(h.inicio) + " às " + LocalTime.withoutSeconds(h.fim)}</div>)}
								{ma.lugares?.length ? <div>em {ma.lugares.map(l => l.lugar.descricao).join(", ")}</div> : null}
							</div>
						))
					}
					{equipamentos.length ? <div>{`+${equipamentos.length} equipamentos`}</div> : null}
				</div>
			);
		}} />,
		<Column style={{width: "7em"}} key="Ações" header="Ações" field="acoes" body= {(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Agenda" onClick={() => this.editarAgenda(rowData)} /> : null}
					{UserData.hasRole("XX0E") && this.security.edit && rowData.status !== "EXCLUIDO" && !rowData.flags?.includes("AUTORIZACAO") ? <img alt="" className="ui-action-icon" src={iconeAutorizacao} title="Criar Autorização de Acesso" onClick={() => this.novaAutorizacao(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Encerrar Agenda" onClick={() => this.excluirAgenda(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Agenda" onClick={() => this.restaurarAgenda(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Agenda" onClick={() => this.eliminarAgenda(rowData)} /> : null}
				</div>
			);
		}} />
	];

	novaAutorizacao = (agenda) => {
		ModalRealm.showDialog(<EditarAutorizacao agenda={agenda} onSave={this.listarAgendas}/>);
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/agendas", entidade);
	}

	restaurarAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta agenda?", () => {
			if (rowData != null && rowData.id != null) {
				this.agendaService.buscar(rowData).then((agenda) => {
					agenda.status = "ATIVADO";
					this.agendaService.salvar(agenda).then(this.listarAgendas);
				});
			}
		});
	}

	eliminarAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta agenda?", () => this.agendaService.eliminar(rowData).then((result) => {
			if (result)
				this.listarAgendas();
			else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta agenda: há outros registros dependentes dela no sistema."]);
		}));
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	removerEtiqueta = (rowData) => {
		this.setState({etiquetas: this.state.etiquetas.filter(e => e.id !== rowData.id)});
		this.listarAgendas();
	}

	validades = [
		{label: "Todas", value: null},
		{label: "Ativa", value: "ATIVA"},
		{label: "Expirada", value: "EXPIRADA"},
		{label: "Indeterminada", value: "INDETERMINADA"}
	];

	changePage = (event) => this.setState({firstResult: event.first});

	toggleExpanded = () => {
		this.setState({expanded: !this.state.expanded}, () => {
			window.localStorage.setItem("X-Agenda-Expanded", this.state.expanded);
		});
	}

	render() {
		return (
			<div>
				<Panel header="Agendas">
					<PanelContent>
						<Combobox grid={2} label="Status" name="status" value={this.state.status} options={[{label: "Todas", value: null}, ...MAgenda.Status]} onChange={this.handleChange} />
						<DatePicker grid={2} label="Período do Registro" type="timestamp" name="inicio" value={this.state.inicio} onChange={this.handleChange} />
						<DatePicker grid={2} label="&nbsp;" type="timestamp" name="fim" value={this.state.fim} onChange={this.handleChange} />
						<DatePicker grid={2} label="Período da Validade" type="timestamp" name="iniciov" value={this.state.iniciov} onChange={this.handleChange} />
						<DatePicker grid={2} label="&nbsp;" type="timestamp" name="fimv" value={this.state.fimv} onChange={this.handleChange} />
						<Combobox grid={2} label="Validade" name="validade" value={this.state.validade} options={this.validades} onChange={this.handleChange} />
						<TextField grid={3} label="Pessoa" autofocus placeholder="Nome, RG, CPF ou documento" name="pessoa" value={this.state.pessoa} onChange={this.handleChange} />
						<TextField grid={3} label="Empresa" placeholder="Nome fantasia, razão social ou CNPJ" name="empresa" value={this.state.empresa} onChange={this.handleChange} />
						<MultiSelectEtiqueta disabled={this.state.lockEtiquetas} appendTo={null} label="Etiquetas" grid={4} name="etiquetas" value={this.state.etiquetas} onSelect={this.handleChange} />
						<TextField placeholder="Placa" label="Veículo" grid={2} name="veiculo" value={this.state.veiculo} onChange={this.handleChange}/>
					</PanelContent>
					<PanelFooter>
						<Action label="Expandido" style={{float: "left"}} icon={`fa fa-${this.state.expanded ? "check-" : ""}square`} onClick={this.toggleExpanded}/>
						<Action label="Procurar" icon="fa-search" onClick={this.listarAgendas}/>
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.agendas} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
				<Shortcut
					onEnter={this.listarAgendas}
					onCtrlN={() => window.location.pathname === "/agendas" && this.security.create && this.novaAgenda()}
					onCtrlE={() => window.location.pathname === "/agendas" && this.security.edit && this.state.agendas.length === 1 && this.editarAgenda(this.state.agendas[0])}
				/>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button title="Nova Autorização de Acesso" mode="secondary small" icon="fa-key" onClick={this.novaAutorizacaoAcesso} /> : null}
					{this.security.create ? <ActionFloat.Button title="Nova Agenda" mode="post" icon="fa-plus" onClick={this.novaAgenda} /> : null}
				</ActionFloat>
				<ModalRealm/>
			</div>
		);
	}

	novaAgenda = () => {
		ModalRealm.showDialog(<WizardAgenda key={Math.random()} etiquetas={this.state.lockEtiquetas ? this.state.etiquetas : []} onSave={this.listarAgendas}/>);
	}

	novaAutorizacaoAcesso = () => {
		this.lugarService.listar(`search=status:ATIVADO`, {page: 0, size: 100}).then(lugares => {
			ModalRealm.showDialog(<EditarAutorizacao agenda={{
				pessoas: [],
				modelosAcesso: [
					{lugares: lugares.map(lugar => ({lugar}))}
				]
			}} onSave={this.listarAgendas}/>);
		});
	}

}

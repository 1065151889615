import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MFeriado} from "../../utils/models/MFeriado";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {CheckGroup} from "../commons/CheckGroup";
import {CheckSquare} from "../commons/CheckSquare";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {FeriadoService} from "../servicos/FeriadoService";
import {LocalidadeService} from "../servicos/LocalidadeService";

export class EditarFeriado extends React.Component {

	state = {
		feriado: Object.assign(MFeriado.Modelo(), this.props.feriado),
		visible: true,
		lockSave: false
	};

	feriadoService = new FeriadoService();
	localidadeService = new LocalidadeService();
	security = UserData.security("FER", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarFeriado = () => {
		if (this.security.edit) {
			let feriado = MFeriado.cleanupBeforeSave(Object.assign({}, this.state.feriado));
			let messages = MFeriado.validarParaSalvar(feriado);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.feriadoService.salvar(feriado).then((feriado) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(feriado);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let feriado = this.state.feriado;
		feriado[event.name] = event.value;
		this.setState({feriado});
	}

	listarLocalidades = (event) => {
		this.localidadeService.listar(`search=nome~${event.query}`, {page: 0, size: 10, sort: "nome"}).then(localidades => this.setState({localidades}));
	}

	render() {
		return (
			<Dialog modal header="Feriado" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={8} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.feriado.descricao} />
					<DatePicker grid={4} appendTo={document.body} label="Data" required name="data" onChange={this.handleChange} value={this.state.feriado.data} />
					<SuggestionBox grid={9} label="Localidade" appendTo={document.body} name="localidade" value={this.state.feriado.localidade} suggestions={this.state.localidades} field="nome" completeMethod={this.listarLocalidades} onChange={this.handleChange} />
					<CheckGroup grid={3} label="Opções">
						<CheckSquare checked={this.state.feriado.anual} name="anual" onChange={this.handleChange} label="Anual" />
					</CheckGroup>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.feriado.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/feriados", this.state.feriado)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.feriado.id)) || this.state.lockSave} onClick={this.salvarFeriado} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarFeriado} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React, {Component} from "react";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {DialogContent} from "../commons/DialogContent";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogFooter} from "../commons/DialogFooter";
import {Combobox} from "../commons/Combobox";
import {Action} from "../commons/Action";
import {MAgenda} from "../../utils/models/MAgenda";
import {ModalRealm} from "../commons/ModalRealm";
import {EditarAgenda} from "./EditarAgenda";
import {EtiquetaService} from "../servicos/EtiquetaService";
import moment from "moment";

export class WizardAgenda extends Component {

    state = {
        visible: true,
        modelosAgenda: [],
        modeloAgenda: null
    };

    etiquetaService = new EtiquetaService();
    modeloAgendaService = new ModeloAgendaService();

    componentDidMount() {
        let query = "";
        if (this.props.etiquetas?.length) {
            query = `search=descricao:${this.props.etiquetas.map(e => e.descricao).join(",")}`;
        }
        this.modeloAgendaService.listar(query, {page: 0, size: 100}).then(modelosAgenda => {
            if (modelosAgenda.length === 1) {
                this.setState({modeloAgenda: modelosAgenda[0]}, this.criarAgenda);
            } else {
                this.setState({modelosAgenda: [{label: "<Criar agenda em branco>", value: null}, ...modelosAgenda.map(ma => ({label: ma.descricao, value: ma}))]});
            }
        });
    }

    render() {
        return (
            <Dialog modal header="Assistente para Nova Agenda" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
                <DialogContent>
                    <div className="ui-g-12">
                        Selecione um modelo de agenda abaixo ou crie uma agenda em branco.
                    </div>
                    <Combobox appendTo={document.body} grid={12} name="modeloAgenda" options={this.state.modelosAgenda} value={this.state.modeloAgenda} onChange={this.handleChange} />
                </DialogContent>
                <DialogFooter>
                    <Action mode="post" label="Criar Agenda" onClick={this.criarAgenda} icon="fa-plus"/>
                </DialogFooter>
            </Dialog>
        );
    }

    handleChange = (event) => this.setState({[event.name]: event.value});

    onClose = () => {
        this.setState({visible: false});
    }

    criarAgenda = async () => {
        const agenda = MAgenda.Modelo();
        if (this.state.modeloAgenda) {
            const {modeloAgenda} = this.state;
            agenda.flags = [...modeloAgenda.agendaFlags];
            agenda.modelosAcesso = [...modeloAgenda.modelosAcesso];
            if (this.props.pessoas) {
                agenda.pessoas = this.props.pessoas;
            }
            if (this.props.etiquetas?.length) {
                agenda.etiquetas = this.props.etiquetas;
            } else {
                agenda.etiquetas = await this.etiquetaService.listar(`search=descricao:${modeloAgenda.descricao}`, {page: 0, size: 1});
            }
            switch (modeloAgenda.duracao) {
                case 0:
                    agenda.validade = null;
                    break;
                case -1:
                    agenda.validade = moment().format("YYYY-MM-DDT23:59:59");
                    break;
                default:
                    agenda.validade = moment().add(modeloAgenda.duracao, "minutes").format("YYYY-MM-DDTHH:mm:ss");
                    break;
            }
        }
        ModalRealm.showDialog(<EditarAgenda lockEtiquetas={this.props.etiquetas?.length} key={Math.random()} agenda={agenda} onSave={this.props.onSave || this.props.onModalClose}/>);
        this.onClose();
    }

}

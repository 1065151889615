import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MEspaco {

	static Modelo() {
		return {
			descricao: "",
			periodos: []
		};
	}

	static cleanupBeforeSave(espaco) {
		return MEntidade.cleanupBeforeSave(espaco);
	}

	static validarParaSalvar(espaco) {
		let messages = [];
		if (Validator.isEmpty(espaco.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MOperador {

	static Modelo() {
		return {
			tipo: "NORMAL",
			perfis: [],
			etiquetas: []
		};
	}

	static cleanupBeforeSave(operador) {
		if (!Validator.isEntidade(operador.pessoa)) operador.pessoa = null;
		if (operador.perfis != null) operador.perfis = operador.perfis.filter(p => Validator.isEntidade(p));
		if (operador.etiquetas != null) operador.etiquetas = operador.etiquetas.filter(e => Validator.isEntidade(e));
		return MEntidade.cleanupBeforeSave(operador);
	}

	static validarParaSalvar(operador) {
		return [];
	}

}

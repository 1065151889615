import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeLugar from "../../media/icones/lugares.png";
import iconeEquipamento from "../../media/icones/equipamentos.png";
import iconeDocumento from "../../media/icones/documentos.png";
import iconeFormulario from "../../media/icones/formularios.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {ModeloAcessoService} from "../servicos/ModeloAcessoService";
import {PeriodoService} from "../servicos/PeriodoService";
import {EditarModeloAcesso} from "./EditarModeloAcesso";
import {byDescricao} from "../../utils/StringUtils";
import {
	detalharModeloAcesso,
	labelModeloAcessoFlag,
	labelTipoDocumentoObrigatoriedade
} from "../../utils/models/MModeloAcesso";

export class ModeloAcesso extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	modeloAcessoService = new ModeloAcessoService();
	periodoService = new PeriodoService();
	security = UserData.security("MAC", true);

	listarModelosAcesso = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.modeloAcessoService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {sort: "descricao", page: 0, size: GUI.maxResults}).then((modelosAcesso) => this.setState({modelosAcesso, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarModeloAcesso = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.modeloAcessoService.buscar(rowData).then((modeloAcesso) => {
				ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={modeloAcesso} onModalClose={() => {
					this.listarModelosAcesso();
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={null} onModalClose={() => {
				this.listarModelosAcesso();
			}} />);
		}
	}

	excluirModeloAcesso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este modelo de acesso?", () => this.modeloAcessoService.excluir(rowData).then(this.listarModelosAcesso));
	}

	restaurarModeloAcesso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este modelo de acesso?", () => {
			if (rowData != null && rowData.id != null) {
				this.modeloAcessoService.buscar(rowData).then((modeloAcesso) => {
					modeloAcesso.status = "ATIVADO";
					this.modeloAcessoService.salvar(modeloAcesso).then(this.listarModelosAcesso);
				});
			}
		});
	}

	eliminarModeloAcesso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este modelo de acesso?", () => this.modeloAcessoService.eliminar(rowData).then((result) => {
			if (result) this.listarModelosAcesso(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este modelo de acesso: há outros registros dependentes dele no sistema."]);
		}));
	}

	detalhes = [
		<Column key={0} style={{width: "8em"}} header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={1} header="Descrição" style={{width: "*"}} sortable field="descricao" body={ma => (
			<div>
				<div>{ma.descricao}</div>
				<div>{ma.flags.map(f => labelModeloAcessoFlag[f])}</div>
			</div>
		)}/>,
		<Column key={2} header="Períodos" style={{width: "*"}} body={detalharModeloAcesso} />,
		<Column key={3} header="Permissões" style={{width: "25em"}} body={(rowData) => {
			return (
				<div>
					{rowData.lugares.sort((a, b) => a.lugar.descricao.localeCompare(b.lugar.descricao)).map(l => <div key={l.id} ><img title="Lugar" className="ui-action-icon" src={iconeLugar} alt=""/> {l.lugar.descricao}</div>)}
					{rowData.equipamentos.sort(byDescricao).map(e => <div key={e.id}><img title="Equipamento" className="ui-action-icon" src={iconeEquipamento} alt=""/> {e.equipamento.descricao}</div>)}
				</div>
			);
		}} />,
		<Column key={4} header="Documentos Obrigatórios" style={{width: "25em"}} body={ma => (
			ma.documentosObrigatorios?.map(mado => (
				<div key={mado.id}>
					<img
						title={mado.tipo.formulario?.id ? "Formulário" : "Documento"}
						className="ui-action-icon"
						src={mado.tipo.formulario?.id ? iconeFormulario : iconeDocumento}
						alt=""
					/> {mado.tipo.descricao} ({labelTipoDocumentoObrigatoriedade[mado.obrigatoriedade]})
				</div>
			))
		)}/>,
		<Column key={5} header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{(UserData.usuario.login === "admin" || rowData.descricao !== "Acesso Geral") && this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Modelo de Acesso" onClick={() => this.editarModeloAcesso(rowData)} /> : null}
					{(UserData.usuario.login === "admin" || rowData.descricao !== "Acesso Geral") && this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Modelo de Acesso" onClick={() => this.excluirModeloAcesso(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Modelo de Acesso" onClick={(event) => this.restaurarModeloAcesso(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Modelo de Acesso" onClick={(event) => this.eliminarModeloAcesso(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/modelos-acesso", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Modelos de Acesso">
					<PanelContent>
						<TextField label="Descrição" grid={12} autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarModelosAcesso} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.modelosAcesso} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Modelo de Acesso" icon="fa-plus" onClick={this.editarModeloAcesso} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarModelosAcesso}
					onCtrlN={() => window.location.pathname === "/modelos-acesso" && this.security.create && this.editarModeloAcesso()}
					onCtrlE={() => window.location.pathname === "/modelos-acesso" && this.state.modelosAcesso.length === 1 && this.security.edit && this.editarModeloAcesso(this.state.modelosAcesso[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

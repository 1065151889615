import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "./Action";
import {DialogContent} from "./DialogContent";
import {DialogFooter} from "./DialogFooter";
import {Shortcut} from "./Shortcut";

export class Informacao extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	onClose = () => {
		this.setState({visible: false});
	}

	onClick = () => {
		if (this.props.onOk) {
			this.props.onOk();
		}

		this.onClose();
	}

	render() {
		return (
			<Dialog closable={false} modal header={this.props.header != null ? this.props.header : "Informação"} visible={this.state.visible} style={{minWidth: "300px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						<div className="ui-g-12">{this.props.messages.map((message) => <div key={Math.random()}><i className={`fa fa-${this.props.type === "info" ? "info-circle" : this.props.type === "warn" ? "exclamation-triangle" : "times-circle"}`} /> {message}</div>)}</div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action label="OK" icon="fa-check" onClick={this.onClick}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose} onEnter={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {SelectVeiculo} from "../../select/SelectVeiculo";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import "./Credencial.css";
import {httpGet, httpPost} from "../../utils/Request";
import {ModalRealm} from "../commons/ModalRealm";
import {ManutencaoService} from "../servicos/ManutencaoService";
import {EditarVeiculo} from "../Veiculo/EditarVeiculo";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {UserData} from "../../utils/UserData";
import {Combobox} from "../commons/Combobox";

export class CadastrarControle extends Component {

	state = {
		visible: true,
		credencial: null,
		equipamentos: [],
		origem: null
	};

	security = UserData.security("CRD", true);
	securityVeiculo = UserData.security("VEI");
	securityPessoa = UserData.security("PES");

	manutencaoService = new ManutencaoService();

	componentDidMount() {
		httpGet("/equipamentos?search=familia:LINEAR;status:ATIVADO&page=0&size=100").then(equipamentos => {
			let origem = window.localStorage.getItem("X-CadControle-Origem");
			if (origem?.length) {
				origem = equipamentos.filter(e => e.ip === origem)[0];
			}
			this.setState({origem, equipamentos: equipamentos.map(e => ({label: e.descricao, value: e}))});
		});
	}

	onClose = () => this.setState({visible: false});

	receiveCredencial = credencial => {
		console.log(credencial);
		if (credencial.forma === "CONTROLE" && credencial.automatico && credencial.origem === this.state.origem?.ip) {
			httpGet(`/credenciais/${credencial.id}`).then(credencial => {
				this.setState({credencial, veiculo: credencial.veiculo, titular: credencial.pessoa});
			});
		}
	}

	salvarControle = () => {
		if (!this.state.credencial) {
			return;
		}
		if (this.state.titular || this.state.veiculo?.proprietario) {
			const {credencial} = this.state;
			credencial.veiculo = this.state.veiculo;
			credencial.pessoa = this.state.titular;
			httpPost("/credenciais", credencial).then(() => {
				httpGet("/equipamentos?search=status:ATIVADO;familia:LINEAR&page=0&size=100").then(equipamentos => {
					this.manutencaoService.enviarPessoa({equipamentos, pessoas: [credencial.pessoa]}).then(() => {
						this.setState({credencial: null, veiculo: null, titular: null}, () => {
							if (this.props.onCallback) {
								this.props.onCallback();
							}
							ModalRealm.showInformacao("info", ["Controle cadastrado com sucesso!"]);
						});
					});
				});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Vincule um titular ao controle ou um veículo com proprietário."]);
		}
	}

	handleChange = event => {
		switch (event.name) {
			case "veiculo":
				this.setState({veiculo: event.value, titular: event.value?.proprietario});
				break;
			case "origem":
				window.localStorage.setItem("X-CadControle-Origem", event.value?.ip);
				this.setState({origem: event.value});
				break;
			default:
				this.setState({[event.name]: event.value});
				break;
		}
	}

	editarVeiculo = veiculo => {
		ModalRealm.showDialog(<EditarVeiculo veiculo={veiculo} onModalClose={veiculo => {
			httpGet(`/credenciais/${this.state.credencial.id}`).then(credencial => {
				this.setState({credencial, veiculo, titular: veiculo.proprietario});
			});
		}}/>);
	}

	editarPessoaFisica = pessoa => {
		pessoa = pessoa || this.state.veiculo?.proprietario;
		httpGet(`/pessoas-fisicas/${pessoa.id}`).then(pessoa => {
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={pessoa} onModalClose={titular => this.setState({titular})}/>);
		});
	}

	render() {
		return (
			<Dialog modal header="Cadastrar Controle Remoto" style={{width: "700px"}} visible={this.state.visible} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-12">
						Processo para o cadastro de controle remoto em equipamento Linear/Nice:<br/>
						<ol>
							<li style={this.state.origem?.id ? {} : {color: "#F44336", fontWeight: "bold"}}>Selecione qual equipamento está sendo utilizado para o cadastro na opção "Origem" abaixo.</li>
							<li>
								Acione o menu do equipamento. Isso pode ser feito de diferentes formas, dependendo do modelo:
								<ul>
									<li>Para módulos Linear-HCS Guarita, gire a chave que dá acesso ao modo de cadastro.</li>
									<li>Para módulos Nice, tecle na opção "Menu" na tela e entre com a senha (padrão 2413).</li>
								</ul>
							</li>
							<li>
								Em seguida, aperte dois botões ao mesmo tempo no controle a ser cadastrado.<br/>
								Isso fará com que o serial do controle seja apresentado na tela do Linear/Nice e nesta tela.
							</li>
							<li>Confira se o serial que aparece na tela do Linear/Nice é igual ao que aparece nesta tela.</li>
							<li>Em seguida, preencha o veículo ou o titular ao qual o controle será vinculado.</li>
							<li>Clique em "Salvar". Uma mensagem de sucesso deve ser exibida.</li>
						</ol>
					</div>
					<div className={`ui-g-12 cadastro-controle ${this.state.credencial ? "ok" : ""}`}>
						{this.state.credencial ? `Novo controle identificado: ${this.state.credencial.codigo.toString(16).toUpperCase()}` : this.state.origem?.id ? "Aguardando novo controle" : "Selecione o equipamento de origem"}
					</div>
					<Combobox required grid={12} label="Origem" name="origem" onChange={this.handleChange} value={this.state.origem} options={this.state.equipamentos}/>
					<SelectVeiculo onEdit={this.securityVeiculo.edit ? this.editarVeiculo : null} require={{proprietario: true}} required={!this.state.veiculo?.proprietario && !this.state.titular} disabled={!this.state.credencial} label="Veículo" onChange={this.handleChange} value={this.state.veiculo} name="veiculo" grid={12}/>
					<SelectPessoaFisica editable={this.state.titular} onEdit={this.securityPessoa.edit ? this.editarPessoaFisica : null} required={!this.state.veiculo?.proprietario && !this.state.titular} disabled={!this.state.credencial || this.state.veiculo?.proprietario} label="Titular" onChange={this.handleChange} value={this.state.titular} name="titular" grid={12}/>
				</DialogContent>
				<DialogFooter>
					<Action disabled={!(this.security.edit || (this.security.create && !this.state.credencial?.id)) && !this.state.veiculo?.proprietario && !this.state.titular} mode="post" label="Salvar" icon="fa-save" onClick={this.salvarControle}/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarControle} onEscape={this.onClose}/> : null}
				<SafetyWebSocket connected={this.state.visible} path="/credenciais" onMessage={this.receiveCredencial}/>
			</Dialog>
		);
	}

}

import React from "react";

export class HelpAgenda extends React.Component {

	render() {
		return (
			<div>
				<p>
					A agenda é o mecanismo utilizado pelo Safety para a liberação de acesso para pessoas. Ela faz o vínculo entre as <b>pessoas</b> e os <b>modelos de acesso</b>.
					Uma agenda pode ter uma ou várias pessoas que compartilham dos mesmos perfis de acesso. Além disso, é através das etiquetas vinculadas às agendas que o
					Safety determina se uma pessoa pode ou não ocupar uma vaga de unidade, além de identificar o destino de acesso da pessoa (em condomínios, por exemplo, onde exista a divisão de lotes).
				</p>
				<p>
					Uma pessoa pode ter uma ou várias agendas (ou até mesmo nenhuma), que garantirão a ela diferentes perfis de acesso, em lugares diferentes e períodos diferentes, conforme definido pelos modelos de acesso
					listados nas agendas que ele possui. Uma forma simples de conferir se uma pessoa possui acesso liberado no sistema é procurar por ela utilizando o campo Pessoa na listagem de agendas.
				</p>
				<h1>Como preencher os campos?</h1>
				<h2>Dados Principais</h2>
				<ul>
					<li>
						<em>Status</em>: define o estado da agenda para o sistema, com base nas seguintes opções:
						<ul>
							<li>Ativado: a agenda está ativa e permitindo acesso normalmente.</li>
							<li>Pendente: a agenda foi criada, mas ainda precisa ser confirmada.</li>
							<li>Cancelado: esta agenda foi cancelada manualmente.</li>
							<li>Excluído: a agenda foi excluída (removida da visão de procura convencional).</li>
							<li>Encerrado: esta agenda temporária foi automaticamente encerrada.</li>
						</ul>
					</li>
					<li><em>Validade</em>: determina a data limite (final) até onde esta agenda permitirá acesso.</li>
					<li>
						<em>Opções</em>: habilita opções específicas desta agenda:
						<ul>
							<li>Temporária: agenda que será encerrada automaticamente assim que a última pessoa listada nela deixar o perímetro controlado pelo sistema.</li>
							<li>Horário Livre: ignora as tabelas de horário dos modelos de acesso, liberando as pessoas apenas pelo controle de lugares. Esta função tem o mesmo efeito de colocar um modelo de acesso com 24h nos lugares onde a pessoa tem acesso.</li>
							<li>Crédito Livre: ignora o processo de validação de crédito (tarifação) de lugares. Por exemplo: em um refeitório, é possível validar ou não a cobrança de créditos, dependendo dessa opção.</li>
							<li>Sentido Livre: ignora a validação de lado (posição atual da pesoa) na liberação do equipamento. Em caso de catracas ou torniquetes, libera ambos os sentidos de giro.</li>
							<li>Garagem Livre: ignora a checagem de vaga liberada em lugares de garagem. Mesmo que não haja vaga disponível para a unidade, o sistema libera a entrada quando esta opção está marcada.</li>
							<li>Alertar Carona: cria uma ocorrência na tela de portaria, avisando sobre uma possível tentativa de passagem de carona. Esta opção não bloqueia a tentativa, apenas avisa sobre ela.</li>
							<li>Impedir Carona: impede fisicamente (bloqueando o equipamento) caso o sistema suspeite de uma tentativa de passagem de carona. Ao contrário da opção anterior, esta não libera o equipamento.</li>
							<li>Integração: esta opção deve ser marcada quando uma agenda sofrerá alteração por um sistema externo de integração com o Safety. Noutros casos, pode ficar desmarcada (não tem efeito no processo de validação de acesso).</li>
						</ul>
					</li>
					<li><em>Etiquetas</em>: as etiquetas ajudam a identificar a agenda. Aqui ficam vinculadas as unidades de um condomínio, as categorias de acesso, as identificações especiais (ex.: PNEs), etc.</li>
					<li><em>Observações</em>: texto auxiliar para identificar a agenda e o motivo dela ter sido criada.</li>
				</ul>
				<h2>Pessoas</h2>
				<ul>
					<li>
						<em>Pessoas</em>: listagem de pessoas que terão o acesso controlado por esta agenda. No campo de busca, logo acima da tabela, é possível procurar por pessoas que já estejam cadastradas e,
						caso ainda não exista cadastro, criá-lo instantaneamente. Na tabela abaixo é possível editar o cadastro das pessoas vinculadas e adicionar opções de credenciais (como digitais e senha), além
						de removê-las da agenda. Remover uma pessoa da agenda não exclui seu cadastro, apenas remove as permissões de acesso que aquela agenda atribui.
					</li>
				</ul>
				<h2>Veículos</h2>
				<ul>
					<li>
						<em>Veículos</em>: listagem de veículos que permitiram a entrada das pessoas nessa agenda. No campo de busca, logo acima da tabela, é possível procurar por veículos já cadastrados e,
						caso ainda não exista cadastro, criá-lo instantaneamente. Na tabela abaixo é possível editar o cadastro dos veículos vinculados, além
						de removê-los da agenda. Remover um veículo da agenda não exclui seu cadastro, apenas retira ele das pesquisas por essa agenda.
					</li>
				</ul>
				<h2>Modelos de Acesso</h2>
				<ul>
					<li>
						<em>Modelos de Acesso</em>: lista com os modelos de acesso desta agenda. É possível, com o campo de busca acima da tabela, recuperar modelos de acesso já cadastrados ou criar novos
						(da mesma forma que é feito com o cadastro de pessoas).
						Os modelos de acesso ajudam o Safety a decidir se as pessoas desta agenda podem ou não entrar nos perímetros controlados em determinados horários, conforme as regras definidas por eles.
					</li>
				</ul>
				<h1>Perguntas frequentes</h1>
				<ol>
					<li>
						<em>Eu fiz o cadastro de uma pessoa, de suas digitais e senha. Preciso criar uma agenda para que ela possa operar os equipamentos de acesso?</em>
						<div>
							Sim. O simples cadastro de uma pessoa não indica que ela tem permissão de entrar ou sair dos lugares controlados pelo Safety. Você ainda precisa criar uma agenda para ela
							e definir modelos de acesso que condizam com o perfil que ela possui. Por exemplo: se uma pessoa é propretária de uma unidade, ela geralmente tem acesso ao perímetro que dá acesso
							à sua unidade 24 horas por dia. Isto precisa estar definido no modelo de acesso de sua agenda.
						</div>
					</li>
					<li>
						<em>Eu excluí uma agenda acidentalmente. Consigo recuperá-la ou preciso criar uma nova?</em>
						<div>
							É comum excluir registros por acidente. Caso queira recuperar uma agenda excluída, você pode utilizar o botão de visualização de registros ocultos (um olho riscado, no canto inferior esquerdo do painel de busca).
							Para registros excluídos, existe a opção de recuperá-los (um ícone com uma seta apontando pra fora de uma lixeira). Feito isso, a agenda volta ao status Ativado, pode ser alterada e fará a liberação de acesso normalmente.
						</div>
					</li>
					<li>
						<em>Quero encerrar o acesso de uma pessoa. Como faço?</em>
						<div>
							Você pode procurar por ela na tela de agendas, utilizando o campo Pessoa, procurando pelo nome ou algum documento. Assim, todas as agendas que possuam a pessoa procurada na lista aparecerão. Você deve analisar estas agendas
							e proceder da seguinte forma: caso a agenda tenha apenas uma pessoa (a que você procura), você pode excluí-la. Isto fará com que a agenda deixe de liberar acesso. Caso a agenda possua mais pessoas, você deve
							editá-la (clicando no botão Editar na coluna Ações), navegar para a aba Pessoas e remover apenas a pessoa da lista. Se excluir uma agenda com várias pessoas, <b>todas</b> perderão o acesso daquela agenda.
							Feito isso com todas as agendas, a pessoa fica sem acesso liberado. Contudo, nada impede que uma nova agenda seja aberta para ela (pela portaria, por exemplo). Para impedir a criação de novas agendas
							você pode criar um <b>Bloqueio</b> para a pessoa.
						</div>
					</li>
				</ol>
			</div>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MAnimal} from "../../utils/models/MAnimal";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {Foto} from "../PessoaFisica/Foto";
import {AnimalService} from "../servicos/AnimalService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {toSrc} from "../../utils/StringUtils";

export class EditarAnimal extends React.Component {

	state = {
		animal: Object.assign(MAnimal.Modelo(), this.props.animal),
		visible: true,
		lockSave: false
	};

	animalService = new AnimalService();
	security = UserData.security("ANI", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarAnimal = () => {
		if (this.security.edit) {
			let animal = MAnimal.cleanupBeforeSave(Object.assign({}, this.state.animal));
			let messages = MAnimal.validarParaSalvar(animal);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (animal.foto && animal.foto.conteudo != null) {
					animal.foto.conteudo = animal.foto.conteudo.split(",").pop();
				}
				this.setState({lockSave: true});
				this.animalService.salvar(this.state.animal).then((animal) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(animal);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let animal = this.state.animal;
		animal[event.name] = event.value;
		this.setState({animal});
	}

	render() {
		return (
			<Dialog modal header="Animal de Estimação" visible={this.state.visible} style={{width: "850px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-8 ui-g-nopad">
						<div className="ui-g" style={{marginRight: "10px"}}>
							<Combobox appendTo={document.body} required label="Espécie" grid={5} name="especie" value={this.state.animal.especie} onChange={this.handleChange} options={MAnimal.Especies} />
							<TextField required grid={7} label="Nome" name="nome" onChange={this.handleChange} value={this.state.animal.nome} />
							<TextField grid={6} label="Raça" name="raca" onChange={this.handleChange} value={this.state.animal.raca} />
							<TextField grid={6} label="Cor" name="cor" onChange={this.handleChange} value={this.state.animal.cor} />
							<SelectPessoaFisica required grid={12} label="Responsável" name="responsavel" onChange={this.handleChange} value={this.state.animal.responsavel} />
							<MultiSelectEtiqueta grid={12} label="Etiquetas" name="etiquetas" onSelect={this.handleChange} value={this.state.animal.etiquetas} />
							<TextField multiline grid={12} label="Observações" name="observacao" onChange={this.handleChange} value={this.state.animal.observacao} style={{height: "6em"}} />
						</div>
					</div>
					<div className="ui-g-4 ui-g-nopad">
						<div className="ui-g ui-pet-foto">
							<img onClick={this.tirarFotografia} src={toSrc(this.state.animal.foto)} alt="" />
						</div>
					</div>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.animal.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/animais", this.state.animal)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.animal.id)) || this.state.lockSave} onClick={this.salvarAnimal} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarAnimal} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	tirarFotografia = () => {
		let width = Math.round(document.body.clientWidth * .3);
		let height = Math.round(width * .75);
		ModalRealm.showDialog(<Foto single key={Math.random()} width={width} height={height} onModalClose={(result) => {
			let animal = this.state.animal;
			animal.foto = result.foto1;
			this.setState({animal});
		}} />);
	}

}

import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeCartao from "../../media/icones/cartao.png";
import iconeCredenciais from "../../media/icones/credenciais.png";
import iconeCriarSenha from "../../media/icones/criar-senha.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEmpresa from "../../media/icones/empresas.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconePessoaDentro from "../../media/icones/pessoa-dentro.png";
import iconePessoaFora from "../../media/icones/pessoa-fora.png";
import iconeVeiculo from "../../media/icones/veiculos.png";
import {AppletUtils} from "../../utils/AppletUtils";
import {toDate, toTime} from "../../utils/Format";
import {MAgenda} from "../../utils/models/MAgenda";
import {MUnidade} from "../../utils/models/MUnidade";
import {iconVehicle, MVeiculo} from "../../utils/models/MVeiculo";
import {UserData} from "../../utils/UserData";
import {EditarAgenda} from "../Agenda/EditarAgenda";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CriarSenha} from "../Credencial/CriarSenha";
import {EditarEtiqueta} from "../Etiqueta/EditarEtiqueta";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {VCard} from "../Portaria";
import {AgendaService} from "../servicos/AgendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {UnidadeService} from "../servicos/UnidadeService";
import {byRegistroDesc, includesAnyNormalized, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";
import {SelectPessoa} from "../../select/SelectPessoa";
import {EditarPessoaJuridica} from "../PessoaJuridica/EditarPessoaJuridica";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {PanelContent} from "../commons/PanelContent";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {GUI} from "../../utils/Constants";
import iconeLancarSaida from "../../media/icones/lancar-saida.png";
import {VagaService} from "../servicos/VagaService";
import {LogVagaService} from "../servicos/LogVagaService";
import {EditarVaga} from "../Vaga/EditarVaga";
import {Confirmacao} from "../commons/Confirmacao";
import {isDentro, isDentroOuGaragem} from "../../utils/PessoaFisicaUtils";
import {LocalTime} from "../../utils/DateUtils";
import {simplificarDiasSemana} from "../servicos/PeriodoService";
import {EditarPeriodo} from "../Periodo/EditarPeriodo";
import {MPeriodo} from "../../utils/models/MPeriodo";
import {httpDelete, httpGet} from "../../utils/Request";
import {SelectModeloAcesso} from "../../select/SelectModeloAcesso";
import iconeCloud from "../../media/icones/cloud-ok.png";
import iconeCloudUpload from "../../media/icones/cloud-upload.png";
import {If} from "../../utils/If";

export class EditarUnidade extends React.Component {

	state = {
		unidade: Object.assign(MUnidade.Modelo(), this.props.unidade),
		visible: true,
		abas: [],
		categorias: [],
		activeIndex: 0,
		lockSave: false,
		modelosAgenda: [],
		vagas: []
	};

	unidadeService = new UnidadeService();
	etiquetaService = new EtiquetaService();
	agendaService = new AgendaService();
	setupCondominioService = new SetupCondominioService();
	pessoaFisicaService = new PessoaFisicaService();
	modeloAgendaService = new ModeloAgendaService();
	security = UserData.security("UNI", true);
	securityAgenda = UserData.security("SCH");
	securityPessoa = UserData.security("PES");
	securityEmpresa = UserData.security("EMP");
	securityCredencial = UserData.security("CRD");
	securityVeiculo = UserData.security("VEI");
	vagaService = new VagaService();
	logVagaService = new LogVagaService();

	editarVaga = vaga => ModalRealm.showDialog(<EditarVaga key={Math.random()} vaga={vaga} onModalClose={this.listarVagas}/>);

	excluirVaga = vaga => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta vaga?", () => this.vagaService.excluir(vaga).then(this.listarVagas));
	}

	liberarVaga = vaga => {
		ModalRealm.showDialog(<Confirmacao message="Tem certeza de que deseja liberar esta vaga manualmente?" onYes={() => {
			const logVaga = vaga._logVaga;
			logVaga.status = "DESOCUPADA";
			logVaga.saida = moment().format("YYYY-MM-DDTHH:mm:ss");
			this.logVagaService.salvar(logVaga).then(this.listarVagas);
		}}/>);
	}

	componentDidMount() {
		this.setupCondominioService.buscar().then((setupCondominio) => {
			this.setState({setupCondominio, categorias: setupCondominio.categorias.sort((a, b) => a.descricao.localeCompare(b.descricao))});
		});
		this.modeloAgendaService.listar("", {page: 0, size: 100}).then(modelosAgenda => this.setState({modelosAgenda}));
	}

	editarPessoa = (pessoa, agenda) => {
		const etiquetas = agenda?.etiquetas?.filter(e => !e.flags?.includes("UNIDADE")).map(e => e.descricao);
		const modeloAgenda = this.state.modelosAgenda?.filter(ma => etiquetas?.includes(ma.descricao))[0];
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(<EditarPessoaFisica obrigatorios={modeloAgenda?.obrigatorios} key={Math.random()} pessoa={pessoa} onModalClose={(pessoa) => {
				let agendas = this.state.agendas;
				agendas.filter((a) => a.pessoas.some((p) => p.id === pessoa.id)).forEach((agenda) => {
					let index = agenda.pessoas.map(p => p.id).indexOf(pessoa.id);
					agenda.pessoas[index] = pessoa;
				});
				this.setState({agendas});
			}} />);
		});
	}

	gerenciarCredenciais = (pessoa, agenda) => {
		const etiquetas = agenda?.etiquetas?.filter(e => !e.flags?.includes("UNIDADE")).map(e => e.descricao);
		const modeloAgenda = this.state.modelosAgenda?.filter(ma => etiquetas?.includes(ma.descricao))[0];
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(<EditarPessoaFisica obrigatorios={modeloAgenda?.obrigatorios} activeTab={2} params={{flags: "TEMPORARIA"}} key={Math.random()} pessoa={pessoa} />);
		});
	}

	criarSenha = (rowData) => {
		ModalRealm.showDialog(<CriarSenha key={Math.random()} pessoa={rowData} />);
	}

	cadastrarDigital = (rowData) => {
		AppletUtils.downloadFingerprint(rowData);
	}

	editarEmpresa = empresa => {
		httpGet(`/pessoas-juridicas/${empresa.id}`).then(empresa => {
			ModalRealm.showDialog(<EditarPessoaJuridica pessoa={empresa} onModalClose={empresa => {
				const {agendas} = this.state;
				for (const agenda of agendas) {
					for (const pessoa of agenda.pessoas) {
						if (pessoa.empresa?.id === empresa.id) {
							pessoa.empresa = empresa;
						}
					}
				}
				this.setState({agendas});
			}}/>);
		});
	}

	agendaDetalhes = [
		<Column style={{width: "4em"}} key="status" header="Status" field="status" body={MAgenda.SwitchStatus} />,
		<Column sortable style={{width: "8em"}} key="validade" header="Validade" field="validade" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{rowData.validade ? toDate(rowData.validade) + " " + toTime(rowData.validade) : <div style={{color: "#607D8B"}}>Indeterminada</div>}
				</div>
			);
		}} />,
		<Column style={{width: "15em"}} key="etiquetas" header="Etiquetas" field="etiquetas" body={(rowData) => {
			let etiquetas = rowData.etiquetas.map((etiqueta) => {
				return <EtiquetaChip key={Math.random()} etiqueta={etiqueta} />;
			});
			return <div style={{textAlign: "center"}}>{etiquetas}</div>;
		}} />,
		<Column style={{width: "*"}} key="pessoas" header="Pessoas" field="pessoas" body={(rowData, column) => {
			let pessoas = rowData.pessoas.sort((a, b) => a.nome.localeCompare(b.nome)).map((pessoa) => {
				let id = `${pessoa.id}_${column.rowIndex}`;
				return (
					<div key={pessoa.id} style={{position: "relative"}}>
						<div className="ui-portaria-item-pessoa visitante" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
							<img className="ui-portaria-icone-posicao" src={isDentro(pessoa) ? iconePessoaDentro : iconePessoaFora} alt="" />
							{pessoa.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}} /> </span> : null}
							{pessoa.nascimento && pessoa.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}} /> </span> : null}
							{pessoa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}} /> </span>)}
							<span>{pessoa.nome}</span>
							<div className="ui-portaria-pessoa-acao-container">
								{this.securityPessoa.edit ? <img title="Editar Pessoa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarPessoa(pessoa, rowData)} /> : null}
								{this.securityCredencial.edit ? <img title="Cadastrar Senha" className="ui-portaria-pessoa-acao" src={iconeCriarSenha} alt="" onClick={() => this.criarSenha(pessoa)} /> : null}
								{this.securityCredencial.edit ? <img title="Gerenciar Credenciais" className="ui-portaria-pessoa-acao" src={iconeCartao} alt="" onClick={() => this.gerenciarCredenciais(pessoa, rowData)} /> : null}
								{this.securityCredencial.edit ? <img title="Cadastrar Digital" className="ui-portaria-pessoa-acao" src={iconeCredenciais} alt="" onClick={() => this.cadastrarDigital(pessoa)} /> : null}
							</div>
						</div>
						<VCard pessoa={pessoa} active={this.state.vcard === id} />
					</div>
				);
			});
			if (rowData.veiculos) {
				rowData.veiculos.forEach(veiculo => {
					const id = `${veiculo.placa}_vc${column.rowIndex}`;
					pessoas.push(
						<div key={id} style={{position: "relative"}}>
							<div className="ui-portaria-item-pessoa veiculo" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
								{iconVehicle(veiculo)}
								{veiculo.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								<span>{`${veiculo.placa} (${veiculo.fabricante?.nome || "Fabricante"} - ${veiculo.modelo || "Modelo"}${(" " + (MVeiculo.MapCores[veiculo.cor] || "")).trimEnd()})`}</span>
							</div>
						</div>
					);
				});
			}
			if (rowData.visitados && rowData.visitados.length) {
				pessoas.push(<div key={-3} className="ui-portaria-item-pessoa-end spaced" />);
				pessoas.push(...rowData.visitados.map((pessoa) => {
					let id = `${pessoa.id}_${column.rowIndex}`;
					return (
						<div key={pessoa.id} style={{position: "relative"}}>
							<div onMouseOver={() => this.setState({vcard: id})} onMouseOut={() => this.setState({vcard: 0})} className={`ui-portaria-item-pessoa visitado`}>
								<img className="ui-portaria-icone-posicao" src={isDentro(pessoa) ? iconePessoaDentro : iconePessoaFora} alt="" />
								{pessoa.nome}
							</div>
							<VCard pessoa={pessoa} active={this.state.vcard === id} />
						</div>
					);
				}));
			}
			if (rowData.empresa?.id) {
				const id = `${column.rowIndex}_age${rowData.empresa.id}`;
				pessoas.unshift(
					<div key={id} style={{position: "relative"}}>
						<div key={id} className="ui-portaria-item-pessoa empresa" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
							<img className="ui-portaria-icone-posicao" style={{marginTop: "0"}} src={iconeEmpresa} alt="" />
							{rowData.empresa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
							{rowData.empresa.nome}
							{rowData.empresa.telefone ? <div className="fone-empresa">{rowData.empresa.telefone}</div> : null}
							<div className="ui-portaria-pessoa-acao-container">
								{this.securityEmpresa.edit ? <img title="Editar Empresa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarEmpresa(rowData.empresa)} /> : null}
							</div>
						</div>
					</div>
				);
			} else if (rowData.pessoas.some(p => p.empresa)) {
				const es = rowData.pessoas.filter((p) => p.empresa).map((p) => p.empresa).filter((e, i, a) => a.map((e) => e.id).indexOf(e.id) === i);
				pessoas.unshift(...es.map((e) => {
					const id = `${e.id}_ve${column.rowIndex}`;
					return (
						<div key={id} style={{position: "relative"}}>
							<div key={e.id} className="ui-portaria-item-pessoa empresa" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
								<img className="ui-portaria-icone-posicao" style={{marginTop: "0"}} src={iconeEmpresa} alt="" />
								{e.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								<span>{e.nome}</span>
								{e.telefone ? <div className="fone-empresa">{e.telefone}</div> : null}
								<div className="ui-portaria-pessoa-acao-container">
									{this.securityEmpresa.edit ? <img title="Editar Empresa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarEmpresa(e)} /> : null}
								</div>
							</div>
						</div>
					);
				}));
			}
			pessoas.push(<div key={-1} className="ui-portaria-item-pessoa-end" />);
			return pessoas;
		}} />,
		<Column style={{width: "6em"}} key="Ações" header="Ações" field="acoes" body= {(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.securityAgenda.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Agenda" onClick={(event) => this.editarAgenda(rowData)} /> : null}
					{this.securityAgenda.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Agenda" onClick={(event) => this.excluirAgenda(rowData)} /> : null}
				</div>
			);
		}} />
	];

	onClose = () => {
		this.setState({visible: false});
	}

	salvarUnidade = () => {
		if (this.security.edit) {
			let unidade = MUnidade.cleanupBeforeSave(Object.assign({}, this.state.unidade));
			let messages = MUnidade.validarParaSalvar(unidade);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.unidadeService.salvar(unidade).then((unidade) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(unidade);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let unidade = this.state.unidade;
		unidade[event.name] = event.value;
		this.setState({unidade});
	}

	tabChange = (event) => {
		this.setState({activeIndex: event.index}, () => {
			switch (event.index) {
				case 0:
					break;
				case 1:
					this.listarVagas();
					break;
				default:
					this.listarAgendas(event.index - 2);
					break;
			}
		});
	}

	listarVagas = () => {
		this.vagaService.listar(`search=status:ATIVADO&etiquetas=id:${this.state.unidade.etiqueta.id}`, {page: 0, size: 50}).then(vagas => {
			this.logVagaService.listarOcupadas(vagas).then(logsVaga => {
				for (const vaga of vagas) {
					vaga._logVaga = logsVaga.filter(lv => lv.vaga.id === vaga.id)[0];
				}
				this.setState({vagas});
			});
		});
	}

	listarAgendas = (index) => {
		this.agendaService.listar(`search=status:ATIVADO&etiquetas=id:${this.props.unidade.etiqueta.id};etiquetas=id:${this.state.categorias[index].id};`, {page: 0, size: 1000}).then(agendas => {
			this.setState({agendas, selected: index}, () => this.handleChangeFilter({value: ""}));
		});
	}

	editarAgenda = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.agendaService.buscar(rowData).then((agenda) => {
				ModalRealm.showDialog(<EditarAgenda key={Math.random()} agenda={agenda} onSave={() => this.listarAgendas(this.state.selected)} />);
			});
		} else {
			ModalRealm.showDialog(<EditarAgenda key={Math.random()} agenda={null} onSave={() => this.listarAgendas(this.state.selected)} />);
		}
	}

	excluirAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta agenda?", () => this.agendaService.excluir(rowData).then(() => this.listarAgendas(this.state.selected)));
	}

	criarAgenda = pessoas => {
		let modeloAgenda;
		if (this.state.setupCondominio && this.state.setupCondominio.modelosAgenda) {
			const modelosAgenda = this.state.setupCondominio.modelosAgenda.filter(ma => ma.descricao === this.state.categorias[this.state.selected].descricao);
			if (modelosAgenda && modelosAgenda.length) {
				modeloAgenda = modelosAgenda[0];
			}
		}
		const agenda = MAgenda.Modelo();
		agenda.etiquetas = [this.state.unidade.etiqueta, this.state.categorias[this.state.selected]];
		if (pessoas?.length) {
			agenda.pessoas = pessoas;
		} else if (this.state.filter?.length) {
			agenda._filter = this.state.filter;
		}
		if (modeloAgenda) {
			agenda.modelosAcesso.push(...modeloAgenda.modelosAcesso);
			switch (modeloAgenda.duracao) {
				case -1:
					agenda.validade = moment().format("YYYY-MM-DDT23:59:59");
					break;
				case 0:
					agenda.validade = null;
					break;
				default:
					agenda.validade = moment().add(modeloAgenda.duracao, "minutes").format("YYYY-MM-DDTHH:mm:ss");
					break;
			}
			agenda.flags = [...modeloAgenda.agendaFlags];
		}
		ModalRealm.showDialog(<EditarAgenda agenda={agenda} key={Math.random()} onSave={() => {
			this.listarAgendas(this.state.selected);
		}}/>);
	}

	inserirAgenda = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.criarAgenda()}/>;

	listarPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.query};status:ATIVADO`, {page: 0, size: 10, sort: "nome"}).then((contatos) => this.setState({contatos}));
	}

	editarEtiqueta = () => {
		if (this.security.edit) {
			this.etiquetaService.buscar(this.state.unidade.etiqueta).then((etiqueta) => {
				ModalRealm.showDialog(<EditarEtiqueta etiqueta={etiqueta} key={Math.random()} onModalClose={(etiqueta) => {
					let unidade = this.state.unidade;
					unidade.etiqueta = etiqueta;
					this.setState({unidade});
				}} />);
			});
		}
	}

	excluirUnidade = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta unidade?", () => {
			this.unidadeService.excluir(this.state.unidade).then(() => {
				if (this.props.onModalClose) {
					this.props.onModalClose(this.state.unidade);
				}
				this.onClose();
			});
		});
	}

	portalCondomino = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja subir esta unidade para o cloud?", () => {
			this.unidadeService.portalCondomino(this.state.unidade).then(() => {
				// if (this.props.onModalClose) {
				// 	this.props.onModalClose(this.state.unidade);
				// }
				// this.onClose();
			});
		});
	}

	restaurarUnidade = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta unidade?", () => {
			const unidade = this.state.unidade;
			unidade.status = "ATIVADO";
			this.unidadeService.salvar(unidade).then((unidade) => {
				this.setState({unidade});
			});
		});
	}

	updateContato = (pessoa) => {
		const unidade = this.state.unidade;
		unidade.contato = pessoa;
		this.setState({unidade});
	}

	handleChangeFilter = (event) => {
		this.setState({filter: event.value}, () => {
			if (this.state.filter?.length) {
				const {agendas} = this.state;
				let filtradas = agendas.filter(a => a.pessoas?.some(p => includesAnyNormalized(this.state.filter, p.nome, p.cpf, p.nis, p.rg, p.passaporte, ...p.documentos?.map(d => d.numero))));
				this.setState({filtradas, agendas});
			} else {
				this.setState({filtradas: null});
			}
		});
	}

	criarVaga = () => {
		ModalRealm.showDialog(<EditarVaga key={Math.random()} vaga={{descricao: "", lugar: null, titulares: [], etiquetas: [this.state.unidade.etiqueta]}} onModalClose={this.listarVagas}/>);
	}

	criarPeriodo = () => this.editarPeriodo(MPeriodo.Modelo());

	editarPeriodo = periodo => {
		ModalRealm.showDialog(<EditarPeriodo key={Math.random()} periodo={periodo} onModalClose={periodo => {
			const {unidade} = this.state;
			const currentIndex = unidade.expediente.findIndex(p => p.id === periodo.id);
			if (currentIndex > -1) {
				unidade.expediente[currentIndex] = periodo;
			} else {
				unidade.expediente.push(periodo);
			}
			this.setState({unidade});
		}}/>);
	}

	excluirPeriodo = periodo => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este período?", () => {
			httpDelete(`/periodos/${periodo.id}`).then(() => {
				const {unidade} = this.state;
				unidade.expediente = unidade.expediente.filter(p => p.id !== periodo.id);
				this.setState({unidade});
			});
		});
	}

	newAgenda = () => {
		httpGet(`/pessoas-fisicas?search=nome,rg,cpf,nis,passaporte~${this.state.filter}`).then(pessoas => {
			if (pessoas?.length) {
				this.criarAgenda(pessoas);
			} else {
				this.criarAgenda();
			}
		});
	}

	render() {
		let abas = this.state.categorias.map((categoria) => (
			<TabPanel key={categoria.id} header={categoria.descricao} name={categoria.id}>
				<PanelContent>
					<TextField grid={12} label="Pessoa" onChange={this.handleChangeFilter} placeholder="Pesquise por nome ou qualquer documento" name="filter" value={this.state.filter}/>
					<div className="ui-g-12">
						<DataTable rows={10} paginatorLeft={this.inserirAgenda} paginator emptyMessage={
							this.state.filter?.length ? <div>
								Nenhuma agenda encontrada para {this.state.filter}. {this.securityAgenda.create ? <span onClick={this.newAgenda} style={{textDecoration: "underline", cursor: "pointer"}}>Deseja criá-la?</span> : null}
							</div> : "Nenhuma agenda adicionada"
						} value={this.state.filtradas || this.state.agendas} children={this.agendaDetalhes}/>
					</div>
				</PanelContent>
			</TabPanel>
		));

		let hasUnidadeId = this.state.unidade.id != null;
		if (!hasUnidadeId) abas.length = 0;

		return (
			<Dialog modal header="Unidade" visible={this.state.visible} style={{width: "1100px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView style={{width: "100%"}} onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<div className="ui-g-6 ui-g-nopad">
									<div className="ui-g">
										{
											!hasUnidadeId ? (
												<>
													<div key="1" className="ui-g-2">
														<Label>Bloco</Label>
														<TextField name="bloco" onChange={this.handleChange} value={this.state.unidade.bloco} />
													</div>
													<div key="2" className="ui-g-6">
														<Label>Descrição</Label>
														<TextField name="descricao" onChange={this.handleChange} value={this.state.unidade.descricao} />
													</div>
												</>
											) : (
												<div className="ui-g-8">
													<Label>Etiqueta</Label>
													<div className={`ui-tzm-portaria-chip ui-pattern-${this.state.unidade.etiqueta.padrao} ui-pattern-a${this.state.unidade.etiqueta.efeito}`} onClick={this.editarEtiqueta} style={{fontWeight: "bold", padding: "3px 10px 4px 10px", margin: 0, width: "100%", cursor: "pointer", backgroundColor: this.state.unidade.etiqueta.fundo, color: this.state.unidade.etiqueta.fonte}}>{this.state.unidade.etiqueta.descricao}</div>
												</div>
											)
										}
										<MultiCombobox name="flags" defaultLabel="Nenhuma" options={MUnidade.Flags} value={this.state.unidade.flags} onChange={this.handleChange} grid={4} label="Opções" />
										<SelectPessoa label="Contato" grid={12} appendTo={document.body} onChange={this.handleChange} value={this.state.unidade.contato} name="contato" />
										<div className="ui-g-12">
											<UnidadeVCard key={Math.random()} onEditarPessoa={this.updateContato} pessoa={this.state.unidade.contato} />
										</div>
									</div>
								</div>
								<div className="ui-g-6">
									<div className="ui-g-12">
										<DataTable rows={5} paginator paginatorLeft={
											<div style={{textAlign: "left"}}>
												<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.criarPeriodo()}/>
											</div>
										} emptyMessage="Nenhum expediente definido" header="Expediente" value={this.state.unidade.expediente}>
											<Column style={{width: "*"}} key="diasSemana" field="diasSemana" header="Período" body={periodo => <div style={{whiteSpace: "nowrap"}}>{simplificarDiasSemana(periodo) + ", das " + LocalTime.withoutSeconds(periodo.inicio) + " às " + LocalTime.withoutSeconds(periodo.fim)}</div>}/>
											<Column style={{width: "6em"}} key="feriados" field="feriados" header="Feriados" body={periodo => <div style={{textAlign: "center"}}>{periodo.feriados ? "Sim": "Não"}</div>}/>
											<Column style={{width: "6em"}} key="acoes" field="acoes" header="Ações" body={periodo => {
												return (
													<div style={{textAlign: "center"}}>
														<img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Período" onClick={() => this.editarPeriodo(periodo)}/>
														<img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Período" onClick={() => this.excluirPeriodo(periodo)}/>
													</div>
												);
											}}/>
										</DataTable>
									</div>
									<SelectModeloAcesso grid={12} name="modeloAcesso" value={this.state.unidade.modeloAcesso} onChange={this.handleChange}/>
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Vagas" disabled={!hasUnidadeId}>
							<DataTable paginatorLeft={(
								<div style={{textAlign: "left"}}>
									<Button title="Nova vaga" className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={this.criarVaga}/>
								</div>
							)} emptyMessage="Nenhum registro encontrado" value={this.state.vagas} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage}>
								<Column style={{width: "4em", textAlign: "center"}} header="Status" body={v => <img title={v._logVaga ? "Ocupada" : "Livre"} src={iconeVeiculo} style={v._logVaga ? {} : {filter: "grayscale(1)", opacity: .5}} alt=""/>}/>
								<Column style={{width: "10em"}} header="Descrição" sortable field="descricao"/>
								<Column header="Titulares" body={(v, i) => (
									v.titulares.map(t => {
										const id = `${t.id}_${i.rowIndex}`;
										return (
											<div key={id} style={{position: "relative"}}>
												<div onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})} onMouseLeave={() => this.setState({vcard: 0})} className={`ui-portaria-item-pessoa visitante ${t.status === "ATIVADO" ? "" : "agenda-pessoa-desativada"}`}>
													<img className="ui-portaria-icone-posicao" src={isDentroOuGaragem(t) && v._logVaga?.pessoa?.id === t.id ? iconePessoaDentro : iconePessoaFora} alt=""/>
													{t.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}}/> </span> : null}
													{t.flags.includes("MASTER") ? <span><i title="Acesso Livre" className="fad fa-crown crown-color"/> </span> : null}
													{t.nascimento && t.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}}/> </span> : null}
													{t.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}} /> </span>)}
													<span>{t.nome}</span>
													<br/>
													<div className="ui-portaria-pessoa-acao-container">
														{this.securityPessoa.edit ? <img title="Editar Pessoa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarPessoa(t)}/> : null}
														{this.security.edit && v._logVaga?.pessoa?.id === t.id ? <img alt="" className="ui-portaria-pessoa-acao" src={iconeLancarSaida} title="Liberar Vaga" onClick={() => this.liberarVaga(v)}/> : null}
													</div>
												</div>
												<VCard pessoa={t} active={this.state.vcard === id} />
											</div>
										);
									}).concat([<div key={-3} className="ui-portaria-item-pessoa-end"/>])
								)}/>
								<Column header="Ações" style={{width: "7em"}} body={v => (
									<div style={{textAlign: "center"}}>
										{this.security.edit && v.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Vaga" onClick={() => this.editarVaga(v)}/> : null}
										{this.security.remove && v.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Vaga" onClick={() => this.excluirVaga(v)}/> : null}
									</div>
								)}/>
							</DataTable>
						</TabPanel>
						{abas}
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && hasUnidadeId ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/unidades", this.state.unidade)} /> : null}
					{this.security.remove && hasUnidadeId && this.state.unidade.status === "ATIVADO" ? <Action mode="delete" label="Excluir" style={{float: "left"}} icon="fa-trash" onClick={this.excluirUnidade} /> : null}
					{this.security.remove && hasUnidadeId && this.state.unidade.status === "EXCLUIDO" ? <Action mode="post" label="Restaurar" style={{float: "left"}} icon="fa-recycle" onClick={this.restaurarUnidade} /> : null}
					<If condition={this.state.unidade.status === "ATIVADO"}>
						<img
							className="ui-action-icon"
							style={{margin: ".25em .5em"}}
							src={this.state.unidade.flags?.includes("CLOUD") ? iconeCloud : iconeCloudUpload}
							alt=""
							title={this.state.unidade.flags?.includes("CLOUD") ? "Unidade sincronizada" : "Subir unidade para o cloud"}
							onClick={this.portalCondomino}
						/>
					</If>
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !hasUnidadeId)) || this.state.lockSave} onClick={this.salvarUnidade} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose} onCtrlS={this.salvarUnidade}/> : null}
			</Dialog>
		);
	}

}

export class UnidadeVCard extends React.Component {

	security = UserData.security("PES");
	pessoaFisicaService = new PessoaFisicaService();
	pessoaJuridicaService = new PessoaJuridicaService();

	render() {
		if (this.props.pessoa) {
			const foto = this.props.pessoa.fotos?.filter(isFinalidadeIdentificacao).sort(byRegistroDesc)[0] || this.props.pessoa.logotipo;
			const telefones = [];
			if (this.props.pessoa.telefone && this.props.pessoa.telefone.length) telefones.push(<span key={Math.random()}><i className="fa fa-phone" /> {this.props.pessoa.telefone}</span>);
			if (this.props.pessoa.celular && this.props.pessoa.celular.length) telefones.push(<span key={Math.random()}><i className="fa fa-phone" /> {this.props.pessoa.celular}</span>);
			return (
				<div className="ui-unidade-vcard" onClick={this.editarCadastro}>
					<img src={toSrc(foto, true)} alt="" />
					<div>
						<div>{this.props.pessoa.nome}</div>
						<div>{this.props.pessoa.email}</div>
						<div>{telefones}</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	editarCadastro = () => {
		if (this.security.edit) {
			if (this.props.pessoa.corPrimaria || this.props.pessoa.corSecundaria || this.props.pessoa.fantasia || this.props.pessoa.cnpj) {
				this.pessoaJuridicaService.buscar(this.props.pessoa).then((pessoa) => {
					ModalRealm.showDialog(<EditarPessoaJuridica pessoa={pessoa} onModalClose={this.props.onEditarPessoa} />);
				});
			} else {
				this.pessoaFisicaService.buscar(this.props.pessoa).then((pessoa) => {
					ModalRealm.showDialog(<EditarPessoaFisica pessoa={pessoa} onModalClose={this.props.onEditarPessoa} />);
				});
			}
		}
	}

}

import React from "react";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MVeiculo {

	static Modelo() {
		return {
			modelo: "",
			fabricante: null,
			cor: "INDEFINIDO",
			placa: "",
			categoria: "PARTICULAR",
			proprietario: null,
			documentos: [],
			tipo: "OUTRO"
		};
	}

  	static Cores = [
		{label: "Indefinido", value: ""},
		{label: "Amarelo", value: "AMARELO"},
		{label: "Azul", value: "AZUL"},
		{label: "Bege", value: "BEGE"},
		{label: "Branco", value: "BRANCO"},
		{label: "Cinza", value: "CINZA"},
		{label: "Dourado", value: "DOURADO"},
		{label: "Bordô", value: "GRENA"},
		{label: "Laranja", value: "LARANJA"},
		{label: "Marrom", value: "MARROM"},
		{label: "Prata", value: "PRATA"},
		{label: "Preto", value: "PRETO"},
		{label: "Rosa", value: "ROSA"},
		{label: "Roxo", value: "ROXO"},
		{label: "Verde", value: "VERDE"},
		{label: "Vermelho", value: "VERMELHO"},
		{label: "Fantasia", value: "FANTASIA"}
	].sort((a, b) => a.label.localeCompare(b.label));

	static Categorias = [
		{label: "Particular", value: "PARTICULAR"},
		{label: "Comercial", value: "COMERCIAL"},
		{label: "Oficial", value: "OFICIAL"},
		{label: "Diplomatico", value: "DIPLOMATICO"},
		{label: "Especial", value: "ESPECIAL"},
		{label: "Coleção", value: "COLECAO"}
	].sort((a, b) => a.label.localeCompare(b.label));

	static MapCores = Object.assign({}, ...MVeiculo.Cores.map((item) => ({[item.value]: item.label})));

	static cleanupBeforeSave(veiculo) {
		return MEntidade.cleanupBeforeSave(veiculo);
	}

	static validarParaSalvar(veiculo) {
		let messages = [];
		if (Validator.isEmpty(veiculo.placa)) messages.push("A placa é obrigatória");
		return messages;
	}

	static print(veiculo) {
		if (veiculo.tipo === "CARRETA") {
			return (
				<span>
					{veiculo.placa} [Carreta/Reboque]
				</span>
			);
		}
		if (veiculo.fabricante?.nome) {
			if (veiculo.modelo?.length) {
				if (veiculo.cor?.length) {
					return (
						<span>
							{`${veiculo.placa} (${veiculo.fabricante?.nome} - ${veiculo.modelo}${(" " + (MVeiculo.MapCores[veiculo.cor] || "")).trimEnd()})`}
						</span>
					);
				}
				return (
					<span>
						{`${veiculo.placa} (${veiculo.fabricante?.nome} - ${veiculo.modelo})`}
					</span>
				);
			}
			return (
				<span>
					{`${veiculo.placa} (${veiculo.fabricante?.nome})`}
				</span>
			);
		}
		if (veiculo.modelo?.length) {
			if (veiculo.cor?.length) {
				return (
					<span>
						{`${veiculo.placa} (${veiculo.modelo}${(" " + (MVeiculo.MapCores[veiculo.cor] || "")).trimEnd()})`}
					</span>
				);
			}
			return (
				<span>
					{`${veiculo.placa} (${veiculo.modelo})`}
				</span>
			);
		}
		if (veiculo.cor?.length) {
			return (
				<span>
					{`${veiculo.placa} (${veiculo.cor})`}
				</span>
			);
		}
		return (
			<span>
				{veiculo.placa}
			</span>
		);
	}

}

export function iconVehicle(veiculo) {
	switch (veiculo.tipo) {
		case "MOTOCICLETA":
			return <img title="Motocicleta" className="ui-portaria-icone-posicao" src={require("../../media/icones/veiculo-motocicleta.png")} alt="" />;
		case "ONIBUS":
			return <img title="Ônibus" className="ui-portaria-icone-posicao" src={require("../../media/icones/veiculo-onibus.png")} alt="" />;
		case "CAMINHAO":
			return <img title="Caminhão" className="ui-portaria-icone-posicao" src={require("../../media/icones/veiculo-caminhao.png")} alt="" />;
		case "CARRETA":
			return <img title="Carreta" className="ui-portaria-icone-posicao" src={require("../../media/icones/veiculo-carreta.png")} alt="" />;
		default:
			return <img title="Automóvel" className="ui-portaria-icone-posicao" src={require("../../media/icones/veiculo-automovel.png")} alt="" />;
	}
}

export const optionsVeiculoTipo = [
	{label: "Outro", value: "OUTRO"},
	{label: "Automóvel", value: "AUTOMOVEL"},
	{label: "Motocicleta", value: "MOTOCICLETA"},
	{label: "Van", value: "VAN"},
	{label: "Ônibus", value: "ONIBUS"},
	{label: "Caminhonete", value: "CAMINHONETE"},
	{label: "Caminhão", value: "CAMINHAO"},
	{label: "Carreta/Reboque", value: "CARRETA"}
];

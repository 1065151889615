import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {BloqueioService} from "../servicos/BloqueioService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarBloqueio} from "./EditarBloqueio";

export class Bloqueio extends React.Component {

	state = {
		bloqueios: [],
		pessoa: "",
		firstResult: 0
	};

	bloqueioService = new BloqueioService();
	security = UserData.security("BLK", true);

	editarBloqueio = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.bloqueioService.buscar(rowData).then((bloqueio) => {
				ModalRealm.showDialog(<EditarBloqueio key={Math.random()} bloqueio={bloqueio} onModalClose={this.listarBloqueios} />);
			});
		} else {
			ModalRealm.showDialog(<EditarBloqueio key={Math.random()} bloqueio={null} onModalClose={this.listarBloqueios} />);
		}
	}

	restaurarBloqueio = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este bloqueio?", () => {
			if (rowData != null && rowData.id != null) {
				this.bloqueioService.buscar(rowData).then((bloqueio) => {
					bloqueio.status = "ATIVADO";
					this.bloqueioService.salvar(bloqueio).then(this.listarBloqueios);
				});
			}
		});
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/bloqueios", entidade);
	}

	eliminarBloqueio = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este bloqueio?", () => this.bloqueioService.eliminar(rowData).then((result) => {
			if (result) this.listarBloqueios(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este bloqueio: há outros registros dependentes dele no sistema."]);
		}));
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key="pessoa" header="Pessoa" style={{width: "*"}} body={(rowData) => rowData.pessoa ? rowData.pessoa.nome : null} />,
		<Column key="equipamento" header="Equipamentos" style={{width: "*"}} body={(rowData) => rowData.equipamentos ? rowData.equipamentos.map(e => e.descricao).join(", ") : null} />,
		<Column key="lugar" header="Lugares" style={{width: "*"}} body={(rowData) => rowData.lugares ? rowData.lugares.map(l => l.descricao).join(", ") : null} />,
		<Column key="inicio" header="Início" style={{width: "12em"}} body={(rowData) => rowData.inicio != null ? moment(rowData.inicio).format("DD/MM/YYYY HH:mm:ss") : ""} />,
		<Column key="fim" header="Fim" style={{width: "12em"}} body={(rowData) => rowData.fim != null ? moment(rowData.fim).format("DD/MM/YYYY HH:mm:ss") : ""} />,
		<Column key="acoes" field="acoes" header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Bloqueio" onClick={(event) => this.editarBloqueio(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Bloqueio" onClick={(event) => this.excluirBloqueio(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Bloqueio" onClick={(event) => this.restaurarBloqueio(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Bloqueio" onClick={(event) => this.eliminarBloqueio(rowData)} /> : null}
				</div>
			);
		}} />
	];

	listarBloqueios = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.bloqueioService.listar(`${this.state.excluidos ? "" : "search=status:ATIVADO&"}pessoa=nome,rg,cpf,passaporte~${this.state.pessoa}`, {page: 0, size: GUI.maxResults}).then((bloqueios) => this.setState({bloqueios, firstResult: 0}));
		}, 50);
	}

	excluirBloqueio = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este bloqueio?", () => this.bloqueioService.excluir(rowData).then(() => this.listarBloqueios()));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Bloqueios">
					<PanelContent>
						<TextField grid={12} label="Pessoa" placeholder="Pesquise por nome, RG, CPF ou passaporte" autofocus name="pessoa" value={this.state.pessoa} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarBloqueios} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.bloqueios} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Novo Bloqueio" icon="fa-plus" onClick={this.editarBloqueio} /> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarBloqueios}
						onCtrlN={() => window.location.pathname === "/bloqueios" && this.security.create && this.editarBloqueio()}
						onCtrlE={() => window.location.pathname === "/bloqueios" && this.security.edit && this.state.bloqueios.length === 1 && this.editarBloqueio(this.state.bloqueios[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}

import {Tooltip} from "primereact/components/tooltip/Tooltip";
import React from "react";
import {Link} from "react-router-dom";
import {UserContext} from "../Application";
import {StringUtils} from "../utils/StringUtils";
import {UserData} from "../utils/UserData";
import {ModalRealm} from "./commons/ModalRealm";
import {QuickSearch} from "./commons/QuickSearch";
import {UsuarioService} from "./servicos/UsuarioService";
import {Opcoes} from "./Usuario/Opcoes";

const cloudEnabled = process.env.REACT_APP_CLOUD_ENABLED === "true";

export class NeoMainBar extends React.Component {

	render() {
		return (
			<div className="ui-neomainbar" {...this.props}>{this.props.children}<div className={`ui-neomainbar-bg ui-pattern-${this.props.padrao} ui-pattern-a${this.props.efeito}`} /></div>
		);
	}

}

export class NeoMainBarButton extends React.Component {

	render() {
		return <div className="ui-neomainbar-button" {...this.props}><i className="fa fa-bars" /></div>
	}

}

export class NeoSidebar extends React.Component {

	constructor() {
		super();
		this.state = {
			scrollValue: 0,
			scrollTop: 0
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resizeScroll);
	}

	resizeScroll = () => {
		if (this.sidebarContent) {
			this.setState({scrollValue: Math.round(this.sidebarContent.offsetHeight * 100 / (this.sidebarContent.scrollHeight)), scrollTop: Math.round(this.sidebarContent.scrollTop * this.sidebarContent.offsetHeight / this.sidebarContent.scrollHeight)});
		}
	}

	loadScroll = () => {
		if (!this.state.scroll) {
			this.resizeScroll();
			this.setState({scroll: true});
		}
	}

	refSidebarContent = (el) => this.sidebarContent = el;

	render() {
		return (
			<div onMouseOver={this.loadScroll} className={`ui-neosidebar${this.props.expanded ? " ui-neosidebar-expanded" : ""}`}>
				<div className="ui-neosidebar-scroll"><div className="ui-neosidebar-scroll-bar" style={{backgroundColor: this.props.scrollColor, height: `${this.state.scrollValue === 100 ? 0 : this.state.scrollValue}%`, marginTop: `${this.state.scrollTop}px`}} /></div>
				<div ref={this.refSidebarContent} onScroll={this.resizeScroll} className="ui-neosidebar-content" children={this.props.children} />
			</div>
		);
	}

}

export class NeoSidebarItem extends React.Component {

	state = {
		index: `NSI_${Math.round(Math.random() * 100000)}`
	};

	render() {
		if (!this.props.enabled) return null;
		return (
			<Link key={this.props.location} to={this.props.location}>
				<Tooltip for={`#${this.state.index}`} title={this.props.location !== window.location.pathname ? this.props.tooltip : null} />
				<div id={this.state.index} className={`ui-neosidebar-item ${this.props.location === window.location.pathname ? "ui-neosidebar-item-selected" : ""}`}>
					<div className="ui-neosidebar-item-icon"><img src={this.props.icon} alt="" /></div>{this.props.label}
					{this.props.shortcut != null ? <div className="ui-shortcut-hint-container">{this.props.shortcut.map(s => <div key={s} className="ui-shortcut-hint">{s}</div>)}</div> : null}
				</div>
			</Link>
		);
	}

}

export class NeoSidebarCard extends React.Component {

	state = {
		schemas: false
	};

	usuarioService = new UsuarioService();

	mostrarOpcoes = () => {
		ModalRealm.showDialog(<Opcoes key={Math.random()} usuario={this.props.usuario} operador={this.props.operador} />);
	}

	handleChange = (event) => this.setState({[event.name]: event.value});

	renderSchemaOptions = () => {
		return (
			<div className="tzm-schema">
				<div className="tzm-schema-container">
					{UserData.operador.usuario.schemas.map((s) => <span onClick={() => this.switchSchema(s)}>{s.descricao}</span>)}
				</div>
				<div className="tzm-schema-mask" onClick={this.toggleSchemas} />
			</div>
		);
	}

	switchSchema = (schema) => {
		window.localStorage.setItem("safety-schema-name", schema.nome);
		this.usuarioService.me().then((usuario) => {
			this.usuarioService.salvar({...usuario, schema}).then(() => {
				window.location.reload();
			});
		})
	}

	toggleSchemas = () => cloudEnabled && this.setState({schemas: !this.state.schemas});

	render() {
		return <UserContext.Consumer children={this.renderUser} />;
	}

	renderUser = (operador) => {
		return (
			<div className="ui-neosidebar-card">
				{this.props.sidebar !== -1 ? <QuickSearch /> : <div className="topbar-title">{this.props.title}</div>}
				<div onClick={this.mostrarOpcoes} className="ui-neosidebar-card-text" style={{width: "198px", textAlign: "center"}}>{this.props.operador.pessoa != null ? StringUtils.simplificarNome(this.props.operador.pessoa.nome) : this.props.operador.usuario.login}</div>
				<div className="ui-neosidebar-card-text" style={{textAlign: "center", width: "118px"}}><span className="tzm-schema-select" onClick={this.toggleSchemas}>{operador.usuario._schema.descricao}</span>{this.state.schemas ? this.renderSchemaOptions() : null}</div>
				<div className="ui-neosidebar-card-logout" onClick={this.props.doLogout}><i className="fa fa-sign-out" /></div>
			</div>
		);
	}

}

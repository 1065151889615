import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import {default as iconeRemover} from "../../media/icones/excluir.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarModeloAgenda} from "./EditarModeloAgenda";
import {MModeloAgenda} from "../../utils/models/MModeloAgenda";
import {PeriodoService} from "../servicos/PeriodoService";
import {MAgenda} from "../../utils/models/MAgenda";
import {detalharModeloAcesso} from "../../utils/models/MModeloAcesso";

export class ModeloAgenda extends React.Component {

	state = {
		modelosAgenda: [],
		descricao: "",
		firstResult: 0
	};

	periodoService = new PeriodoService();
	modeloAgendaService = new ModeloAgendaService();
	security = UserData.security("MAG", true);

	editarModeloAgenda = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.modeloAgendaService.buscar(rowData).then((modeloAgenda) => {
				ModalRealm.showDialog(<EditarModeloAgenda key={Math.random()} modeloAgenda={modeloAgenda} onModalClose={this.listarModelosAgenda} />);
			});
		} else {
			ModalRealm.showDialog(<EditarModeloAgenda key={Math.random()} modeloAgenda={null} onModalClose={this.listarModelosAgenda} />);
		}
	}

	restaurarModeloAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este modelo de agenda?", () => {
			if (rowData != null && rowData.id != null) {
				this.modeloAgendaService.buscar(rowData).then((modeloAgenda) => {
					modeloAgenda.status = "ATIVADO";
					this.modeloAgendaService.salvar(modeloAgenda).then(this.listarModelosAgenda);
				});
			}
		});
	}

	eliminarModeloAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este modelo de agenda?", () => this.modeloAgendaService.eliminar(rowData).then((result) => {
			if (result) this.listarModelosAgenda(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este modelo de agenda: há outros registros dependentes dele no sistema."]);
		}));
	}

	columns = [
		<Column header="Descrição" field="descricao" style={{width: "10em"}} />,
		<Column header="Modelos de Acesso" body={(modeloAgenda) => {
			return (
				<div>
					<div className="agenda-modelos-acesso-top" />
					{
						modeloAgenda.modelosAcesso.map(ma => (
							<div className="agenda-modelos-acesso" key={ma.id}>
								<b>{ma.descricao}</b>
								<div>{detalharModeloAcesso(ma)}</div>
							</div>
						))
					}
				</div>
			);
		}} />,
		<Column header="Opções" body={(modeloAgenda) => {
			return modeloAgenda.flags.concat(modeloAgenda.agendaFlags).map(f => MModeloAgenda.MapFlags[f] || MAgenda.MapFlags[f]).join(", ");
		}} />,
		<Column header="Duração" style={{width: "9em"}} body={(modeloAgenda) => {
			switch (Number(modeloAgenda.duracao)) {
				case -1:
					return "Até o final do dia";
				case 0:
					return "Indeterminada";
				default:
					return `${modeloAgenda.duracao} minutos`;
			}
		}} />,
		<Column header="Ações" style={{width: "5em", textAlign: "center"}} body={(modeloAgenda) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Modelo de Agenda" onClick={() => this.editarModeloAgenda(modeloAgenda)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Modelo de Agenda" onClick={() => this.removerModeloAgenda(modeloAgenda)} /> : null}
				</div>
			);
		}} />
	];

	removerModeloAgenda = (modeloAgenda) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este modelo de agenda?", () => {
			this.modeloAgendaService.excluir(modeloAgenda).then(this.listarModelosAgenda);
		});
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/modelos-agenda", entidade);
	}

	listarModelosAgenda = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.modeloAgendaService.listar(`search=descricao~${this.state.descricao}`, {page: 0, size: GUI.maxResults}).then((modelosAgenda) => this.setState({modelosAgenda, firstResult: 0}));
		}, 50);
	}

	excluirModeloAgenda = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este modelo de agenda?", () => this.modeloAgendaService.excluir(rowData).then(() => this.listarModelosAgenda()));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Modelos de Agenda">
					<PanelContent className="ui-g">
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						<Action label="Procurar" icon="fa-search" onClick={this.listarModelosAgenda} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.modelosAgenda} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Novo Modelo de Agenda" icon="fa-plus" onClick={this.editarModeloAgenda} /> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarModelosAgenda}
						onCtrlN={() => window.location.pathname === "/modelos-agenda" && this.security.create && this.editarModeloAgenda()}
						onCtrlE={() => window.location.pathname === "/modelos-agenda" && this.state.modelosAgenda.length === 1 && this.security.edit && this.editarModeloAgenda(this.state.modelosAgenda[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}

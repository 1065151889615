import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {MVeiculo} from "../../utils/models/MVeiculo";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {VeiculoService} from "../servicos/VeiculoService";
import {EditarVeiculo} from "./EditarVeiculo";
import {Placa} from "./PlacaMercosul";
import {SelectPessoa} from "../../select/SelectPessoa";

export class Veiculo extends React.Component {

	state = {
		veiculos: [],
		modelo: "",
		placa: "",
		proprietario: null,
		firstResult: 0
	};

	veiculoService = new VeiculoService();
	security = UserData.security("VEI", true);

	editarVeiculo = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.veiculoService.buscar(rowData).then((veiculo) => {
				ModalRealm.showDialog(<EditarVeiculo key={Math.random()} veiculo={veiculo} onModalClose={() => this.listarVeiculos()} />);
			});
		} else {
			ModalRealm.showDialog(<EditarVeiculo key={Math.random()} veiculo={null} onModalClose={() => this.listarVeiculos()} />);
		}
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key="placa" header="Placa" style={{width: "8em", textAlign: "center"}} body={(veiculo) => <Placa veiculo={veiculo}/>} />,
		<Column sortable key="modelo" header="Modelo" field="modelo" style={{width: "*"}} />,
		<Column key="proprietario" header="Proprietário" style={{width: "*"}} body={(veiculo) => veiculo.proprietario ? veiculo.proprietario.nome : ""} />,
		<Column key="fabricante" header="Fabricante" style={{width: "15em"}} body={(v) => v.fabricante?.nome || ""} />,
		<Column key="cor" header="Cor" style={{width: "12em"}} body={(veiculo) => MVeiculo.MapCores[veiculo.cor]} />,
		<Column key="acoes" field="acoes" header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Veículo" onClick={(event) => this.editarVeiculo(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Veículo" onClick={(event) => this.excluirVeiculo(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Empresa" onClick={(event) => this.restaurarPessoa(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Empresa" onClick={(event) => this.eliminarPessoa(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = entidade => EntidadeNextGenService.listarHistorico("/veiculos", entidade);

	restaurarPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este veículo?", () => {
			if (rowData != null && rowData.id != null) {
				this.veiculoService.buscar(rowData).then((veiculo) => {
					veiculo.status = "ATIVADO";
					this.veiculoService.salvar(veiculo).then(this.listarVeiculos);
				});
			}
		});
	}

	eliminarPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este veículo?", () => this.veiculoService.eliminar(rowData).then((result) => {
			if (result) this.listarVeiculos(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este veículo: há outros registros dependentes dele no sistema."]);
		}));
	}

	listarVeiculos = () => {
		const params = [];
		const aParams = [];
		if (this.state.modelo?.length) params.push(`modelo~${this.state.modelo.replace(/[^a-zA-Z0-9 ]/g, " ")}`);
		if (this.state.placa?.length) params.push(`placa~${this.state.placa.replace(/[^a-zA-Z0-9 ]/g, " ")}`);
		if (this.state.proprietario?.id) aParams.push(`proprietario=id:${this.state.proprietario.id}`);
		if (!this.state.excluidos) params.push(`status:ATIVADO`);
		this.veiculoService.listar(`search=${params.join(";")}&${aParams.join("&")}`, {page: 0, size: 100}).then(veiculos => this.setState({veiculos}));
	}

	excluirVeiculo = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este veículo?", () => this.veiculoService.excluir(rowData).then(this.listarVeiculos));
	}

	handleChange = event => this.setState({[event.name]: event.value});

	changePage = event => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Veículos">
					<div className="ui-g">
						<TextField grid={4} label="Modelo" name="modelo" value={this.state.modelo} onChange={this.handleChange}/>
						<TextField type="alphanumeric" grid={3} label="Placa" autofocus name="placa" value={this.state.placa} onChange={this.handleChange}/>
						<SelectPessoa grid={5} label="Proprietário" name="proprietario" value={this.state.proprietario} onChange={this.handleChange}/>
					</div>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleChange} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarVeiculos} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.veiculos} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Veículo" icon="fa-plus" onClick={this.editarVeiculo} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarVeiculos}
					onCtrlN={() => window.location.pathname === "/veiculos" && this.security.create && this.editarVeiculo()}
					onCtrlE={() => window.location.pathname === "/veiculos" && this.security.edit && this.state.veiculos.length === 1 && this.editarVeiculo(this.state.veiculos[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

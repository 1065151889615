import {httpDelete, httpGet, httpPost} from "../../utils/Request";
import {byRegistroDesc} from "../../utils/StringUtils";

export class ManutencaoService {

	async enviarFirmware(equipamentos) {
		return await httpPost("/manutencoes/enviar-firmware", equipamentos);
	}

	async enviarDatahora(equipamentos) {
		return await httpPost("/manutencoes/enviar-datahora", equipamentos);
	}

	async enviarAgendas(equipamentos) {
		return await httpPost("/manutencoes/enviar-agendas", equipamentos);
	}

	async enviarPessoas(equipamentos) {
		return await httpPost("/manutencoes/enviar-pessoas", equipamentos);
	}

	async enviarPessoa(payload) {
		return await httpPost("/manutencoes/enviar-pessoa", payload);
	}

	async trocarSenha(equipamentos) {
		return await httpPost("/manutencoes/trocar-senha", equipamentos);
	}

	async limparMemoria(equipamentos) {
		return await httpPost("/manutencoes/limpar-memoria", equipamentos);
	}

	async removerPessoa(payload) {
		return await httpPost("/manutencoes/remover-pessoa", payload);
	}

	async removerCredencial(payload) {
		return await httpPost("/manutencoes/remover-credencial", payload);
	}

	async buscarAcessos(equipamentos) {
		return await httpPost("/manutencoes/buscar-acessos", equipamentos);
	}

	async buscarDigitais(equipamentos) {
		return await httpPost("/manutencoes/buscar-digitais", equipamentos);
	}

	async reiniciar(equipamentos) {
		return await httpPost("/manutencoes/reiniciar", equipamentos);
	}

	async coletarFacial(payload) {
		return await httpPost("/manutencoes/coletar-facial", payload);
	}

	async ativarModoEmergencia(payload) {
		return await httpPost("/manutencoes/ativar-modo-emergencia", payload);
	}

	async desativarModoEmergencia(payload) {
		return await httpPost("/manutencoes/desativar-modo-emergencia", payload);
	}

	async listarLogsManutencao() {
		let manutencoes = await httpGet(`/manutencoes/listar-dto`);
		manutencoes.unshift(...(await httpGet("/manutencoes/enfileiradas")));
		manutencoes = manutencoes.sort(byRegistroDesc);
		return manutencoes;
	}

	async listarEquipamentoManutencao(params) {
		return await httpGet(`/manutencoes/listar-equipamento-dto`, params);
	}

	async enviarFacialSync(pessoa) {
		return await httpPost("/faciais/pessoa", pessoa);
	}

	async listarPessoas(query) {
		return await httpGet(`/manutencoes/listar-pessoas?${query}`);
	}

	async interromperManutencoes(id) {
		await httpGet(`/manutencoes/interromper/${id}`);
	}

	async togglePause(id) {
		return await httpGet(`/manutencoes/pause/${id}`, null, {progress: false});
	}

	async isPaused(id) {
		return await httpGet(`/manutencoes/is-paused/${id}`);
	}

	async isAllPaused() {
		return await httpGet("/manutencoes/is-paused");
	}

	async inicializarManutencaoPeriodica() {
		return await httpGet("/manutencoes/periodicas/init");
	}

	async excluirLogsManutencao(e) {
		return await httpDelete(`/manutencoes/logs/${e.id}`);
	}

	async excluirTodosLogsManutencao() {
		return await httpDelete("/manutencoes/logs");
	}

	async listarDTO(params) {
		return await httpGet(`/manutencoes/listar-dto`,params);
	}

}

export const labelLogManutencaoTipo = {
	FIRMWARE: "Envio de Firmware",
	DATA: "Envio de Relógio",
	AGENDAS: "Envio de Agendas",
	PESSOAS: "Envio de Pessoas",
	ACESSOS: "Envio de Acessos",
	DIGITAIS: "Envio de Digitais",
	PESSOA: "Envio de Pessoa",
	REMOVER_PESSOA: "Exclusão",
	LIMPAR_MEMORIA: "Limpeza",
	COLETAR_FACIAL: "Coleta de Facial",
	COLETAR_DIGITAL: "Coleta de Digital",
	COLETAR_CARTAO: "Coleta de Cartão",
	REINICIAR: "Reinicialização",
	TROCAR_SENHA: "Troca de Senha"
};

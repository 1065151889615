import {MEntidade} from "./MEntidade";

export class MContratoTrabalho {

	static Modelo() {
		return {
			status: "ATIVADO",
			periodos: []
		};
	}

	static Status = [
		{label: "Ativo", value: "ATIVADO"},
		{label: "Encerrado", value: "ENCERRADO"}
	];

	static cleanupBeforeSave(feriado) {
		return MEntidade.cleanupBeforeSave(feriado);
	}

	static validarParaSalvar(feriado) {
		let messages = [];
		return messages;
	}

}

import React from "react";

export class HelpReserva extends React.Component {

	render() {
		return (
			<div>
				<p>
                    A reserva é o mecanismo utilizado pelo Safety para reservar um "Espaço" para uma pessoa e seus convidados em um uma data determinada.
				</p>
				<p>
					Esta tela permite a visualização, a reserva e o cancelamento de reservas de Espaços.
				</p>
				<h1>Como reservar um espaço?</h1>
				<ul>
					<li>
						<em>1</em>. Defina uma data específica para a reserva, para isso selecione a data no calendário localizado na tela.
					</li>
                    <li>
						<em>2</em>. Selecione um espaço a ser reservado. Se estiver com o status de disponível, basta seleciná-lo no campo de ações para seguir as próximas etapas, caso contrário escolha outra data.
					</li>
                    <li>
						<em>3</em>. Selecione uma pessoa que irá visitar. Caso não esteja no sistema, será necessário criar o cadastro dela, inserindo o nome completo e pelo menos 1 documento. 
                        É possível adicionar outras pessoas na "Lista de Convidados", seguindo o procedimento dessa etapa.
					</li>
				</ul>
                <p>
					É recomendado que faça a confirmação da presença, pois enquanto reservado, o espaço está indisponível para outras pessoas. Caso contrário é possível editar ou cancelar a reserva, voltando a disponibilizar o espaço na mesma data.
				</p>
				<h1>Perguntas frequentes</h1>
				<ol>
					<li>
						<em>Eu fiz o cadastro de uma pessoa, preciso criar um novo cadastro?</em>
						<div>
							Caso ela esteja cadatrada no sistema, basta selecionar o nome dela com o complemento automático para fazer a reserva.
						</div>
					</li> 
					<li>
						<em>Quero cancelar uma reserva. Como faço?</em>
						<div>
							Basta encontrar a reserva selecionando a data prevista no calendário à esquerda, se a reserva estiver feita, irá aparecer na tabela com os detalhes de: espaço, perído e status. clique no ícone de "x" no campo de ações, onde se encontra o status do usuário a cancelar reserva.
						</div>
					</li>
				</ol>
			</div>
		);
	}

}

export class MEntidade {

	static cleanupBeforeSave(entidade) {
		if (entidade != null) {
			Object.keys(entidade).forEach(property => entidade[property] === "" && delete entidade[property]);
		}
		return entidade;
	}

}

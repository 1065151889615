import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import {SelectEtiqueta} from "../../select/SelectEtiqueta";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MMensagem} from "../../utils/models/MMensagem";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MensagemService} from "../servicos/MensagemService";
import {toSrc} from "../../utils/StringUtils";

export class EditarMensagem extends React.Component {

	state = {
		mensagem: Object.assign(MMensagem.Modelo(), this.props.mensagem),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	mensagemService = new MensagemService();
	security = UserData.security("MSG", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarMensagem = () => {
		if (this.security.edit) {
			let mensagem = MMensagem.cleanupBeforeSave(this.state.mensagem);
			let messages = MMensagem.validarParaSalvar(mensagem);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.mensagemService.salvar(mensagem).then((mensagem) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(mensagem);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let mensagem = this.state.mensagem;
		mensagem[event.name] = event.value;
		this.setState({mensagem});
	}

	loadImage = (event) => {
		let reader = new FileReader();
		if (this.input.files != null && this.input.files.length > 0) {
			reader.readAsDataURL(this.input.files[0]);
			reader.onload = (event) => {
				let mensagem = this.state.mensagem;
				mensagem.foto = {conteudo: event.target.result, finalidade: "MENSAGEM"};
				this.setState({mensagem});
			};
		}
	}

	fileInputRef = (input) => {
		this.input = input;
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	render() {
		return (
			<Dialog modal header="Mensagem" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<DatePicker appendTo={document.body} showTime showSeconds grid={6} label="Período de Vigência" name="inicio" onChange={this.handleChange} value={this.state.mensagem.inicio} />
								<DatePicker appendTo={document.body} showTime showSeconds grid={6} label="&nbsp;" name="fim" onChange={this.handleChange} value={this.state.mensagem.fim} />
								<SelectPessoaFisica grid={12} label="Pessoa" name="pessoa" value={this.state.mensagem.pessoa} onChange={this.handleChange} />
								<SelectEtiqueta grid={12} label="Etiqueta" name="etiqueta" value={this.state.mensagem.etiqueta} onChange={this.handleChange} />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Conteúdo da Mensagem">
							<PanelContent>
								<TextField label="Texto" grid={12} name="texto" onChange={this.handleChange} value={this.state.mensagem.texto} />
								<div className="ui-g-12">
									<Label>Imagem</Label>
									<div className="ui-tzm-fileloader-container">
										<div className="ui-mensagem-imagem ui-tzm-fileloader">
											<img src={toSrc(this.state.mensagem.foto)} alt="" />
											<input ref={this.fileInputRef} type="file" onChange={this.loadImage} />
										</div>
									</div>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.mensagem.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/mensagens", this.state.mensagem)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.mensagem.id)) || this.state.lockSave} onClick={this.salvarMensagem} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarMensagem} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

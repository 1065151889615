import React, {Component} from "react";
import {Combobox} from "../commons/Combobox";
import {optionsFinalidade} from "../../utils/models/MFoto";
import {Button} from "primereact/components/button/Button";
import {httpGet} from "../../utils/Request";
import Hls from "hls.js";
import {ImageUtils} from "../../utils/ImageUtils";

export class FotoServidorMidia extends Component {

	state = {
		flip: false,
		rotation: 0,
		imagem: null,
		camera: null,
		cameras: [],
		className: "",
		finalidade: window.localStorage.getItem("X-Foto-Finalidade") || "IDENTIFICACAO",
		frameWidth: ((Math.max(this.props.width, this.props.height) - Math.min(this.props.width, this.props.height)) / 2) + 10,
		frameHeight: Math.max(this.props.width, this.props.height) + 20
	};

	updateCamera = () => {
		if (Hls.isSupported()) {
			this.hls = new Hls();
			this.hls.loadSource(`${this.state.camera?.controlador?.endereco}/${this.state.camera?.ip}`);
			this.hls.attachMedia(this.video);
		}
	}

	stopCamera = () => {
		if (this.video && this.hls) {
			try {
				this.hls.detachMedia();
				this.hls.stopLoad();
				this.video.stop();
			} catch (ignored) {
			}
		}
	}

	componentDidMount() {
		httpGet("/cameras?search=status:ATIVADO;formato:HTTP_LIVE_STREAM&page=0&size=100").then(cameras => {
			cameras = cameras.map(c => ({label: c.descricao, value: c}));
			let camera = null;
			if (cameras?.length) {
				const index = cameras.findIndex(c => c.value.id === Number(window.localStorage.getItem("X-Foto-RTSPCameraID")));
				if (index > -1) {
					camera = cameras[index].value;
				}
			}
			this.setState({camera, cameras}, this.updateCamera);
		});
	}

	componentWillUnmount() {
		this.stopCamera();
	}

	handleChange = event => {
		this.setState({[event.name]: event.value}, () => {
			switch (event.name) {
				case "camera":
					window.localStorage.setItem("X-Foto-RTSPCameraID", event.value.id);
					this.updateCamera();
					break;
				case "finalidade":
					window.localStorage.setItem("X-Foto-Finalidade", event.value);
					break;
				default:
					break;
			}
		});
	}

	tirarFotografia = () => {
		const canvas = document.createElement("canvas");
		canvas.width = this.props.width;
		canvas.height = this.props.height;
		const context = canvas.getContext("2d");
		context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
		const imagem = canvas.toDataURL("image/jpeg");
		this.stopCamera();
		ImageUtils.rotateBase64Image(imagem, this.props.width, this.props.height, this.state.rotation, this.state.flip, imagem => {
			this.setState({imagem, rotation: 0, className: "", flip: false}, () => {
				if (this.props.onPhotoTaken) {
					this.props.onPhotoTaken({
						finalidade: this.state.finalidade,
						conteudo: imagem.split(",")[1]
					});
				}
			});
		});
	}

	refVideo = el => this.video = el;

	render() {
		const styles = {
			border: this.state.imagem?.length ? "3px solid #4CAF50" : "3px solid #F44336",
			width: `${this.props.width}px`,
			height: `${this.props.height}px`
		};
		const view = this.state.imagem?.length ? (
			<img className="ui-webcam-camtotem" src={this.state.imagem} alt="" style={styles}/>
		) : (
			<video ref={this.refVideo} className={`ui-webcam-camtotem${this.state.className}${this.state.flip ? "-flip" : ""}`} style={styles} autoPlay playsInline width={this.props.width} height={this.props.height}/>
		);
		return (
			<div className="ui-g">
				<div className="ui-g-12 ui-g ui-g-nopad" style={{textAlign: "left"}}>
					<Combobox grid={8} label="Câmera" disabled={this.state.imagem?.length} appendTo={document.body} value={this.state.camera} options={this.state.cameras} onChange={this.handleChange} name="camera"/>
					<Combobox grid={4} label="Finalidade" disabled={this.state.imagem?.length} appendTo={document.body} value={this.state.finalidade} options={optionsFinalidade} onChange={this.handleChange} name="finalidade"/>
				</div>
				<div className="ui-g-12" style={{paddingTop: `${this.state.frameWidth}px`, height: `${this.state.frameHeight}px`}}>
					<div className="ui-tzm-webcam-container-image" onClick={() => !this.state.imagem?.length && this.tirarFotografia()}>
						<div className={`foto-camera-frame ${this.state.finalidade.toLowerCase()}`}>
							{view}
						</div>
						{
							!["BIOMETRIA", "IDENTIFICACAO"].includes(this.state.finalidade) || this.state.rotation ? null : (
								<div className="facial-hints">
									<ul>
										<li>Procure enquadrar o rosto da pessoa na moldura</li>
										<li>Peça para que ela retire os óculos e/ou máscara</li>
										<li>Peça para que a pessoa mantenha uma expressão séria</li>
										<li>Clique sobre a imagem quando estiver pronto(a)</li>
									</ul>
								</div>
							)
						}
					</div>
				</div>
				<div className="ui-g-12" style={{textAlign: "center"}}>
					<Button disabled={!this.state.imagem?.length} title="Tentar novamente" onClick={() => this.setState({imagem: null}, this.updateCamera)} label=" " icon="fa-plus"/>
					<Button disabled={this.state.imagem?.length} title="Girar para a esquerda" onClick={() => this.setState({rotation: (this.state.rotation + 270) % 360, className: ` ui-tzm-webcam-${(this.state.rotation + 270) % 360}`})} label=" " icon="fa-undo"/>
					<Button disabled={this.state.imagem?.length} title="Inverter horizontalmente" onClick={() => this.setState({flip: !this.state.flip})} label=" " icon="fa-arrows-alt-h"/>
					<Button disabled={this.state.imagem?.length} title="Girar para a direita" onClick={() => this.setState({rotation: (this.state.rotation + 90) % 360, className: ` ui-tzm-webcam-${(this.state.rotation + 90) % 360}`})} label=" " icon="fa-redo"/>
				</div>
			</div>
		);
	}

}

import {Tooltip} from "primereact/components/tooltip/Tooltip";
import React from "react";
import iconeAgendaAtivado from "../../media/icones/agenda-ativado.png";
import iconeAgendaCancelado from "../../media/icones/agenda-cancelado.png";
import iconeAgendaEncerrado from "../../media/icones/agenda-encerrado.png";
import iconeAgendaExcluido from "../../media/icones/agenda-excluido.png";
import iconeAgendaExpirado from "../../media/icones/agenda-expirado.png";
import iconeAgendaPendente from "../../media/icones/agenda-pendente.png";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";
import moment from "moment";
import {UserData} from "../UserData";

export class MAgenda {

	static Modelo() {
		return {
			status: "ATIVADO",
			pessoas: [],
			visitados: [],
			modelosAcesso: [],
			etiquetas: [],
			veiculos: [],
			flags: [],
			documentos: [],
			observacao: "",
			operacao: "OUTRA"
		};
	}

	static OptionsOperacao = [
		{label: <span className="options-agenda-operacao"><i className="fad fa-user-hard-hat"/> Serviços/Outros</span>, value: "OUTRA"},
		{label: <span className="options-agenda-operacao"><i className="fad fa-truck-moving"/> Carga</span>, value: "CARGA"},
		{label: <span className="options-agenda-operacao"><i className="fad fa-truck-loading"/> Descarga</span>, value: "DESCARGA"}
	];

	static OptionsOperacaoNoIcon = [
		{label: <span style={{fontSize: ".9em"}}>Serviços/Outros</span>, value: "OUTRA"},
		{label: <span style={{fontSize: ".9em"}}>Carga</span>, value: "CARGA"},
		{label: <span style={{fontSize: ".9em"}}>Descarga</span>, value: "DESCARGA"}
	];

	static IconOperacao = {
		OUTRA: <i className="fad fa-user-hard-hat"/>,
		CARGA: <i className="fad fa-truck-moving"/>,
		DESCARGA: <i className="fad fa-truck-loading"/>
	};

	static LabelOperacao = {
		OUTRA: "Serviços/Outros",
		CARGA: "Carga",
		DESCARGA: "Descarga"
	};

	static validarParaSalvar(agenda) {
		let messages = [];
		if (agenda.empresa?.id && UserData.hasRole("XX1R")) {
			for (const pessoa of agenda.pessoas) {
				if (agenda.empresa.id !== pessoa.empresa?.id) {
					messages.push(`${pessoa.nome} não pertence à empresa selecionada para a agenda`);
				}
			}
		} else {
			if (!agenda.pessoas?.length) {
				messages.push("Selecione ao menos uma pessoa");
			}
		}
		if (!agenda.modelosAcesso?.length) {
			messages.push("Selecione ao menos um modelo de acesso");
		}
		if (agenda.veiculos?.length && !agenda.veiculos.some(av => av.tipo !== "CARRETA")) {
			messages.push("A lista de veículos deve conter ao menos um transporte automotor.")
		}
		return messages;
	}

	static cleanupBeforeSave(agenda) {
		if (agenda.pessoas != null) agenda.pessoas = agenda.pessoas.filter(p => Validator.isEntidade(p));
		if (agenda.etiquetas != null) agenda.etiquetas = agenda.etiquetas.filter(e => Validator.isEntidade(e));
		if (agenda.modelosAcesso != null) agenda.modelosAcesso = agenda.modelosAcesso.filter(ma => Validator.isEntidade(ma));
		return MEntidade.cleanupBeforeSave(agenda);
	}

	static IconesOpcoes = {
		"TEMPORARIA": <i title="Temporária" className="flag-agenda-icon fad fa-alarm-clock"/>,
		"AUTORIZACAO": <i title="Autorização de Acesso" className="flag-agenda-icon fad fa-key"/>,
		"COLETOR_LIVRE": <i title="Coletor Livre" className="flag-agenda-icon fad fa-arrow-alt-up"/>,
		"INTEGRACAO": <i title="Integração de Sistemas" className="flag-agenda-icon fad fa-database"/>
	}

	static Status = [
		{label: "Ativado", value: "ATIVADO"},
		{label: "Pendente", value: "PENDENTE"},
		{label: "Cancelado", value: "CANCELADO"},
		{label: "Excluído", value: "EXCLUIDO"},
		{label: "Encerrado", value: "ENCERRADO"},
		{label: "Suspenso", value: "SUSPENSO"}
	];

	static MapStatus = {
		"ATIVADO": "Ativada",
		"PENDENTE": "Pendente",
		"CANCELADO": "Cancelada",
		"EXCLUIDO": "Excluída",
		"ENCERRADO": "Encerrada",
		"SUSPENSO": "Suspensa"
	};

	static StatusPortaria = [
		{label: "Todas", value: null},
		{label: "Ativas", value: "ATIVADO"},
		{label: "Encerradas", value: "ENCERRADO"},
		{label: "Excluídas", value: "EXCLUIDO"}
	]

	static Flags = [
		{label: "Temporária", value: "TEMPORARIA"},
		{label: "Integração", value: "INTEGRACAO"},
		{label: "Autorização", value: "AUTORIZACAO"},
		{label: "Coletor Livre", value: "COLETOR_LIVRE"}
	];

	static MapFlags = {
		TEMPORARIA: "Temporária",
		INTEGRACAO: "Integração",
		AUTORIZACAO: "Autorização",
		COLETOR_LIVRE: "Coletor Livre"
	};

	static SwitchStatus = (rowData) => {
		let status = rowData.status;
		let title = "Desativada";
		let icone;
		if (status) {
			switch (status) {
				case "PENDENTE":
				case "SUSPENSO":
					icone = iconeAgendaPendente;
					title = "Pendente";
					break;
				case "ENCERRADO":
					icone = iconeAgendaEncerrado;
					title = "Encerrada";
					break;
				case "EXCLUIDO":
					icone = iconeAgendaExcluido;
					title = "Excluída";
					break;
				case "CANCELADO":
					icone = iconeAgendaCancelado;
					title = "Cancelada";
					break;
				case "ATIVADO":
					if (rowData.validade && moment().isAfter(moment(rowData.validade))) {
						icone = iconeAgendaExpirado;
						title = "Expirada";
					} else {
						icone = iconeAgendaAtivado;
						title = "Ativada";
					}
					break;
				default:
			}
		}
		let id = "statusAgenda" + Math.round(Math.random() * 10000);
		return (
			<div id={id} style={{textAlign: "center"}}>
				<img alt="" style={{verticalAlign: "middle"}} src={icone} />
				<Tooltip for={`#${id}`} title={title} tooltipPosition="top" />
			</div>
		);
	}
	
}

export function colorStatusAgenda(agenda) {
	switch (agenda.status) {
		case "ENCERRADO": return "#607D8B";
		case "SUSPENSO": return "#FF9800";
		case "EXCLUIDO": return "#F44336";
		case "PENDENTE": return "#009688";
		case "CANCELADO": return "#673AB7";
		default: return "#4CAF50";
	}
}

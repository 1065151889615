import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {GUI} from "../../utils/Constants";
import {MComunicado} from "../../utils/models/MComunicado";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {RichEditor} from "../commons/RichEditor";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {FileUpload} from "../commons/Upload";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {MailService} from "../servicos/MailService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {UploadService} from "../servicos/UploadService";
import {Fetch} from "../../utils/Fetch";

const basePath = process.env.REACT_APP_API_URL;

export class EditarComunicado extends React.Component {

	state = {
		titulo: "",
		conteudo: "",
		activeIndex: 0,
		destinatarios: [],
		anexos: [],
		visible: true,
		pessoas: [],
		selecionados: [],
		nome: "",
		rg: "",
		cpf: "",
		lockSave: false
	};

	UNSAFE_componentWillMount() {
		if (this.props.comunicado) {
			let {titulo, conteudo, anexos} = this.props.comunicado;
			anexos = anexos.map((a) => ({name: a.substring(a.indexOf("_") + 1), size: 0, _old: a}));
			this.setState({titulo, conteudo, anexos});
		}
	}

	securityPessoas = new UserData.security("PES", false);
	pessoaFisicaService = new PessoaFisicaService();
	mailService = new MailService();
	uploadService = new UploadService();

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleChange = (event) => this.setState({[event.name]: event.value});

	onClose = () => {
		this.setState({lockSave: false, visible: false});
	}
	
	enviarEmail = () => {
		let state = this.state;
		let email = {finalidade: "COMUNICADO", titulo: state.titulo, conteudo: `<html><body>${state.conteudo}</body></html>`, destinatarios: this.state.selecionados.map((p) => p.email)};
		if (this.state.anexos && this.state.anexos.length) {
			email._anexos = this.state.anexos;
			email.anexos = [];
			const messages = MComunicado.validarParaEnviar(email);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.loadAttachmentsAndSend(email);
			}
		} else {
			const messages = MComunicado.validarParaEnviar(email);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.mailService.enviar(email).then(() => this.onClose()).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	loadAttachmentsAndSend = (email, index = 0) => {
		let anexo = email._anexos[index];
		if (anexo) {
			if (anexo._old) {
				email.anexos.push(anexo._old);
				this.loadAttachmentsAndSend(email, ++index);
			} else {
				const body = new FormData();
				body.append("file", anexo, anexo.name);
				Fetch.PostFile(`${basePath}/uploads`, body).then(response => response.text()).then((fileName) => {
					email.anexos.push(fileName);
					this.loadAttachmentsAndSend(email, ++index);
				});
			}
		} else {
			this.setState({lockSave: true});
			this.mailService.enviar(email).then(() => this.onClose()).catch(() => this.setState({lockSave: false}));
		}
	}

	editarPessoa = (pessoa) => {
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={pessoa} onModalClose={(pessoa) => {
				let pessoas = this.state.pessoas;
				pessoas = pessoas.filter((p) => p.id !== pessoa.id);
				pessoas.push(pessoa);
				pessoas = pessoas.sort((a, b) => a.nome.localeCompare(b.nome));
				this.setState({pessoas});
			}} />);
		});
	}

	detailsPessoas = [
		<Column selectionMode="multiple" style={{textAlign: "center", width: "2.5em"}}/>,
		<Column key={1} header="Nome" field="nome" style={{width: "*"}} />,
		<Column key={2} header="CPF" field="cpf" style={{width: "12em"}} />,
		<Column key={3} header="RG" field="rg" style={{width: "10em"}} />,
		<Column key={4} header="Email" style={{width: "20em"}} body={(pessoa) => {
			return Validator.isEmail(pessoa.email) ? pessoa.email : <span style={{color: "#F44336"}}>{pessoa.email}</span>;
		}} />,
		<Column style={{width: "5em", textAlign: "center"}} header="Ações" key={5} body={(pessoa) => {
			return this.securityPessoas.edit ? <img title="Editar Pessoa" className="ui-action-icon" src={iconeEditar} alt="" onClick={() => this.editarPessoa(pessoa)} /> : null;
		}} />
	];

	handleChangeQuery = (event) => {
		this.handleChange(event);
		if (this.queryTimeout) {
			clearTimeout(this.queryTimeout);
		}
		this.queryTimeout = setTimeout(() => {
			let params = [];
			if (this.state.rg && this.state.rg.length) params.push(`rg=${this.state.rg.replace(/[^0-9 a-zA-Z]/g, "")}`);
			if (this.state.cpf && this.state.cpf.length) params.push(`cpf=${this.state.cpf.replace(/[^0-9]/g, "")}`);
			if (this.state.nome && this.state.nome.length) params.push(`nome=${this.state.nome}`);
			if (this.state.etiquetas1 && this.state.etiquetas1.length) params.push(`etiquetas1=id:${this.state.etiquetas1.map(e => e.id).join(",")}`);
			if (this.state.etiquetas2 && this.state.etiquetas2.length) params.push(`etiquetas2=id:${this.state.etiquetas2.map(e => e.id).join(",")}`);
			if (params.length) {
				this.mailService.listarEmails(params.join("&")).then((pessoas) => this.setState({pessoas}));
			}
		}, 200);
	}

	selectPessoas = (event) => {
		this.setState({selecionados: event.data});
	}

	addPessoa = <div style={{textAlign: "left"}}>
		<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.novaPessoa()} />
	</div>;

	novaPessoa = () => {
		ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={null} onModalClose={(pessoa) => {
			let {pessoas, selecionados} = this.state;
			selecionados.push(pessoa);
			pessoas.push(pessoa);
			this.setState({pessoas, selecionados});
		}} />);
	}

	render() {
		return (
			<Dialog modal header="Comunicado" visible={this.state.visible} style={{width: "1200px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12" activeIndex={this.state.activeIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados do E-Mail">
							<PanelContent>
								<TextField autofocus label="Título" grid={12} name="titulo" value={this.state.titulo} onChange={this.handleChange} />
								<RichEditor style={{height: "15em"}} label="Conteúdo" grid={12} name="conteudo" value={this.state.conteudo} onChange={this.handleChange} />
								<FileUpload value={this.state.anexos} label="Anexos" grid={12} onChange={this.handleChange} name="anexos" />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Destinatários">
							<PanelContent>
								<TextField label="Nome" grid={5} name="nome" value={this.state.nome} onChange={this.handleChangeQuery} />
								<TextField label="CPF" grid={4} name="cpf" value={this.state.cpf} onChange={this.handleChangeQuery} />
								<TextField label="RG" grid={3} name="rg" value={this.state.rg} onChange={this.handleChangeQuery} />
								<MultiSelectEtiqueta label="Etiquetas em Pessoas" grid={6} value={this.state.etiquetas1} onSelect={this.handleChangeQuery} name="etiquetas1" />
								<MultiSelectEtiqueta label="Etiquetas em Agendas" grid={6} value={this.state.etiquetas2} onSelect={this.handleChangeQuery} name="etiquetas2" />
								<div className="ui-g">
									<div className="ui-g-12">
										<DataTable paginatorLeft={this.securityPessoas.edit ? this.addPessoa : null} selection={this.state.selecionados} onSelectionChange={this.selectPessoas} rows={10} rowsPerPageOptions={GUI.rowsPerPage} paginator emptyMessage="Nenhuma pessoa encontrada" value={this.state.pessoas} children={this.detailsPessoas} />
										{`${this.state.pessoas.length} resultados. ${this.state.pessoas.filter(p => Validator.isEmail(p.email)).length} com email válido.`}
									</div>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					<Action disabled={this.state.lockSave} mode="post" label="Enviar" onClick={this.enviarEmail} icon="fa-envelope" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.enviarEmail} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

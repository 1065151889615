import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {OperadorService} from "../servicos/OperadorService";
import {UsuarioService} from "../servicos/UsuarioService";
import {EditarUsuario} from "./EditarUsuario";

export class Usuario extends React.Component {

	state = {
		login: "",
		firstResult: 0
	};

	usuarioService = new UsuarioService();
	operadorService = new OperadorService();
	security = UserData.security("USR", true);

	restaurarUsuario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este usuário?", () => {
			if (rowData != null && rowData.id != null) {
				this.usuarioService.buscar(rowData).then((usuario) => {
					usuario.status = "ATIVADO";
					this.usuarioService.salvar(usuario).then(this.listarUsuarios);
				});
			}
		});
	}

	eliminarUsuario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este usuário?", () => this.usuarioService.eliminar(rowData).then((result) => {
			if (result) this.listarUsuarios(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este usuário: há outros registros dependentes dele no sistema."]);
		}));
	}

	listarUsuarios = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let filter = UserData.usuario.login === "admin" ? "" : `&search=login!admin&schemas=id:${UserData.usuario.schema.id}`;
			this.usuarioService.listar(`search=login~${this.state.login}${this.state.excluidos ? "" : ";status:ATIVADO"}${filter}`, {sort: "login", page: 0, size: GUI.maxResults}).then((usuarios) => {
				this.setState({usuarios});
				usuarios.forEach((usuario) => {
					let usuarios = this.state.usuarios;
					this.operadorService.listar(`usuario=id:${usuario.id}`, {page: 0, size: 1}).then((operadores) => {
						usuario.operador = operadores[0];
						if (usuarios != null) {
							let index = usuarios.map(u => u.id).indexOf(usuario.id);
							usuarios[index] = usuario;
							this.setState({usuarios, firstResult: 0});
						}
					});
				});
			});
		}, 50);
	}

	componentWillUnmount() {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarUsuario = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.usuarioService.buscar(rowData).then((usuario) => {
				this.operadorService.listar(`usuario=id:${usuario.id}`, {page: 0, size: 1}).then((operador) => {
					ModalRealm.showDialog(<EditarUsuario key={Math.random()} operador={operador[0]} usuario={usuario} onModalClose={this.listarUsuarios} />);
				});
			});
		} else {
			ModalRealm.showDialog(<EditarUsuario key={Math.random()} operador={null} usuario={{schemas: [UserData.usuario.schema]}} onModalClose={this.listarUsuarios} />);
		}
	}

	excluirUsuario = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este usuário?", () => this.usuarioService.excluir(rowData).then(this.listarUsuarios));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column header="Login" style={{width: "15em"}} sortable field="login" />,
		<Column header="Nome" style={{width: "*"}} body={(rowData) => {
			return rowData.operador != null && rowData.operador.pessoa != null ? rowData.operador.pessoa.nome : null;
		}} />,
		<Column header="Perfis"  style={{width: "*"}} body={(rowData) => {
			return rowData.operador != null && rowData.operador.perfis != null ? rowData.operador.perfis.map(p => p.descricao).join(", ") : null;
		}} />,
		<Column header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Usuário" onClick={(event) => this.editarUsuario(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Usuário" onClick={(event) => this.excluirUsuario(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Usuário" onClick={(event) => this.restaurarUsuario(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Usuário" onClick={(event) => this.eliminarUsuario(rowData)} /> : null}
				</div>
			);
		}} />
	];

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Usuários">
					<div className="ui-g">
						<div className="ui-g-12">
							<Label>Login</Label>
							<TextField autofocus name="login" value={this.state.login} onChange={this.handleUpdate} />
						</div>
					</div>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarUsuarios} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.usuarios} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Usuário" icon="fa-plus" onClick={this.editarUsuario} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarUsuarios}
					onCtrlN={() => window.location.pathname === "/usuarios" && this.security.create && this.editarUsuario()}
					onCtrlE={() => window.location.pathname === "/usuarios" && this.state.usuarios.length === 1 && this.security.edit && this.editarUsuario(this.state.usuarios[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

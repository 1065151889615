import {httpGet} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class CredencialService extends EntidadeNextGenService {

	constructor() {
		super("/credenciais");
	}

	liberarCredencial(credencial) {
		credencial.pessoa = null;
		return this.salvar(credencial);
	}

	listarAlt(parameters) {
		return httpGet(this.basePath + "/alt", parameters);
	}

	temporarias(titulares) {
		return httpGet(`${this.basePath}/temporarias?titulares=${titulares.join(",")}`);
	}

	gerarQr(parameters) {
		return httpGet(this.basePath + "/gerar-qr-code", parameters);
	}

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAdicionar from "../../media/icones/adicionar.png";
import iconeDisponivel from "../../media/icones/disponivel.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeOk from "../../media/icones/ok.png";
import iconePendente from "../../media/icones/pendente.png";
import {GUI} from "../../utils/Constants";
import {LocalDate} from "../../utils/DateUtils";
import {GUIUtils} from "../../utils/GUIUtils";
import {MReserva} from "../../utils/models/MReserva";
import {UserData} from "../../utils/UserData";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {EspacoService} from "../servicos/EspacoService";
import {ReservaService} from "../servicos/ReservaService";
import {EditarReserva} from "./EditarReserva";

export class Reserva extends React.Component {

	state = {
		data: LocalDate.now(),
		o_espacos: [],
		quantidadeMaxima: 0,
		countByPessoa: 0
	};

	security = UserData.security("RES", true);
	securityEspacos = UserData.security("ESP", false);
	espacoService = new EspacoService();
	reservaService = new ReservaService();

	UNSAFE_componentWillMount() {
		if (!UserData.operador.pessoa) {
			ModalRealm.showInformacao("warn", ["Este usuário não tem permissão para criar reservas de espaço."]);
			this.props.history.push("/");
		} else {
			const perfis = UserData.operador.perfis;
			let diasAntecedencia = perfis.map((p) => p.reservaDiasAntecedencia).filter((v) => !isNaN(v)).reduce(Math.max, 0);
			let maxData = new Date();
			if (diasAntecedencia > 0) {
				maxData.setDate(maxData.getDate() + diasAntecedencia);
			}
			const sMaxData = moment(maxData).format("YYYY-MM-DD");
			let quantidadeMaxima = perfis.map((p) => p.reservaQuantidadeMaxima).filter((v) => !isNaN(v)).reduce(Math.max, 0);
			this.espacoService.listar("sort=descricao", {page: 0, size: 100}).then((espacos) => {
				const o_espacos = espacos.map((e) => ({label: e.descricao, value: e}));
				o_espacos.unshift({label: "Todos", value: null});
				this.setState({o_espacos, espacos, quantidadeMaxima: quantidadeMaxima === 0 ? 100 : quantidadeMaxima, maxData: sMaxData !== this.state.data ? maxData : null});
				this.listarReservas();
			});
		}
	}

	switchIconeStatus = (reserva) => {
		switch (reserva.status) {
			case "PENDENTE": return GUIUtils.imageWithTooltip(iconePendente, "Pendente");
			case "ATIVADO": return GUIUtils.imageWithTooltip(iconeOk, "Confirmado");
			default: return GUIUtils.imageWithTooltip(iconeDisponivel, "Disponível");
		}
	}

	switchStatus = (reserva) => {
		switch (reserva.status) {
			case "PENDENTE":
			case "ATIVADO": {
				if (this.securityEspacos.edit) {
					return reserva.pessoa.nome;
				} else {
					if (reserva.pessoa.id === UserData.operador.pessoa.id) {
						return reserva.status === "PENDENTE" ? "Pendente de aprovação" : "Reservado por você";
					} else {
						const posicao = this.posicaoNaFila(reserva);
						return posicao > -1 ? `Você está em ${posicao+1}º na fila de espera` : "Reservado para outra pessoa";
					}
				}
			}
			default: return <span style={{color: "#999999"}}>Disponível</span>;
		}
	}

	confirmarReserva = (reserva) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja confirmar a reserva?", () => {
			reserva.status = "ATIVADO";
			if (reserva.convidados && reserva.convidados.length) {
				reserva.convidados.forEach((aviso) => aviso.status = "ATIVADO");
			}
			this.reservaService.salvar(reserva).then(this.listarReservas);
		});
	}

	entrarNaFilaDeEspera = ({espaco, periodo, data}) => {
		this.editarReserva({...MReserva.Modelo(), espaco, periodo, data});
	}

	sairDaFilaDeEspera = (reserva) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja sair da lista de espera?", () => {
			const index = reserva._fila.findIndex((r) => r.pessoa.id === UserData.operador.pessoa.id);
			this.reservaService.excluir(reserva._fila[index]).then(this.listarReservas);
		});
	}

	columns = [
		<Column key={1} style={{width: "15em"}} header="Espaço" body={(reserva) => reserva.espaco.descricao} />,
		<Column key={2} style={{width: "9em"}} header="Período" body={(reserva) => `${reserva.periodo.inicio.substring(0, 5)} até ${reserva.periodo.fim.substring(0, 5)}`} />,
		<Column key={3} style={{width: "4em", textAlign: "center"}} header="" body={this.switchIconeStatus} />,
		<Column key={3} header="Status" body={this.switchStatus} />,
		<Column key={4} style={{width: "8em", textAlign: "center"}} header="Ações" body={(reserva) => {
			return (
				<div>
					{this.security.edit && reserva.pessoa && reserva.status === "PENDENTE" && this.securityEspacos.edit ? <img alt="" className="ui-action-icon" src={iconeOk} title="Confirmar Reserva" onClick={() => this.confirmarReserva(reserva)} /> : null}
					{this.state.countByPessoa < this.state.quantidadeMaxima && this.security.edit && reserva.pessoa && UserData.operador.pessoa.id !== reserva.pessoa.id && (!reserva._fila || reserva._fila.findIndex((r) => r.pessoa.id === UserData.operador.pessoa.id) === -1) ? <img alt="" className="ui-action-icon" title="Entrar na Fila de Espera" src={iconeAdicionar} onClick={() => this.entrarNaFilaDeEspera(reserva)} /> : null}
					{(this.securityEspacos.edit || this.state.countByPessoa < this.state.quantidadeMaxima || (reserva.pessoa && reserva.pessoa.id === UserData.operador.pessoa.id)) && this.security.edit && (!reserva.pessoa || reserva.pessoa.id === UserData.operador.pessoa.id || this.posicaoNaFila(reserva) !== -1 || this.securityEspacos.edit) ? <img alt="" className="ui-action-icon" src={reserva.pessoa ? iconeEditar : iconeAdicionar} title="Editar Reserva" onClick={() => this.editarReserva(reserva)} /> : null}
					{this.security.edit && reserva.pessoa && UserData.operador.pessoa.id !== reserva.pessoa.id && reserva._fila && reserva._fila.findIndex((r) => r.pessoa.id === UserData.operador.pessoa.id) > -1 ? <img alt="" className="ui-action-icon" title="Sair da Fila de Espera" src={iconeEliminar} onClick={() => this.sairDaFilaDeEspera(reserva)} /> : null}
					{this.security.edit && reserva.pessoa && (!reserva.pessoa || this.securityEspacos.edit || UserData.operador.pessoa.id === reserva.pessoa.id) ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Cancelar Reserva" onClick={() => this.eliminarReserva(reserva)} /> : null}
				</div>
			);
		}} />,
	];

	editarReserva = (reserva) => {
		reserva.data = this.state.data;
		if (!reserva.id) {
			reserva.status = "PENDENTE";
		}
		if (!reserva.pessoa) {
			reserva.pessoa = UserData.operador.pessoa;
		} else if (!this.securityEspacos.edit) {
			if (reserva.pessoa.id !== UserData.operador.pessoa.id) {
				reserva = reserva._fila.filter((r) => r.pessoa.id === UserData.operador.pessoa.id)[0];
			}
		}
		ModalRealm.showDialog(<EditarReserva lockPessoa={!this.securityEspacos.edit} key={Math.random()} reserva={reserva} onModalClose={this.listarReservas} />);
	}

	listarReservas = () => {
		const reservas = [];
		if (this.state.espaco) { 
			const espaco = this.state.espaco;
			espaco.periodos.forEach((periodo) => {
				reservas.push({espaco, periodo});
			});
		} else {
			this.state.espacos.forEach((espaco) => {
				espaco.periodos.forEach((periodo) => {
					reservas.push({espaco, periodo});
				});
			});
		}
		this.reservaService.listar(`search=data:${this.state.data};status:ATIVADO,PENDENTE${this.state.espaco ? `&espaco=id:${this.state.espaco.id}` : ""}&sort=id`, {page: 0, size: 1000}).then((_reservas) => {
			_reservas.forEach((reserva) => {
				const index = reservas.findIndex((r) => r.espaco.id === reserva.espaco.id && r.periodo.id === reserva.periodo.id);
				if (index >= 0 && !reservas[index].pessoa) {
					reservas[index] = reserva;
				} else {
					if (reservas[index]._fila == null) {
						reservas[index]._fila = [];
					}
					reservas[index]._fila.push(reserva);
				}
			});
			this.reservaService.countByPessoa(UserData.operador.pessoa.id).then((countByPessoa) => this.setState({reservas, countByPessoa}));
		});
	}

	eliminarReserva = (reserva) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja cancelar esta reserva?", () => {
			this.reservaService.excluir(reserva).then(this.listarReservas);
		});
	}

	handleWebsocket = (reserva) => {
		if (this.state.data === reserva.data) {
			setTimeout(this.listarReservas, 500);
		}
	}

	posicaoNaFila = (reserva) => {
		if (reserva._fila) {
			return reserva._fila.findIndex((r) => r.pessoa.id === UserData.operador.pessoa.id);
		}
		return -1;
	}

	render() {
		return (
			<div>
				<Panel header="Reservas">
					<PanelContent>
						{/* TODO: criar relatório de reservas e adicionar "minDate={new Date()}" de volta ao campo abaixo */}
						<DatePicker name="data" maxDate={this.state.maxData} grid={3} inline value={this.state.data} onChange={this.handleChange} />
						<div className="ui-g-9 ui-g-nopad">
							<div className="ui-g">
								<Combobox grid={12} label="Espaço" name="espaco" value={this.state.espaco} options={this.state.o_espacos} onChange={this.handleChange} />
								<div className="ui-g-12">
									<DataTable children={this.columns} value={this.state.reservas} emptyMessage="Nenhum registro encontrado" paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} />
								</div>
								{
									this.state.countByPessoa >= this.state.quantidadeMaxima ? (
										<div className="ui-g-12"><i className="fa fa-exclamation-triangle" /> Você atingiu o máximo de reservas possíveis no período</div>
									) : null
								}
							</div>
						</div>
					</PanelContent>
				</Panel>
				<ModalRealm/>
				<SafetyWebSocket path="/reservas" onMessage={this.handleWebsocket} />
			</div>
		);
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
		setTimeout(this.listarReservas, 50);
	}

}

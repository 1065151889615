import React from "react";
import {Label} from "./Label";

export class CheckGroup extends React.Component {

	render() {
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label>{this.props.label}</Label>
				{this.props.children}
			</div>
		);
	}

}

import {MEntidade} from "./MEntidade";
import {Validator} from "../Validator";

export class MControlador {

	static Modelo() {
		return {
			descricao: "",
			endereco: "",
			standalone: "",
			fileName: "",
			fileNameStandalone: ""
		};
	}

	static cleanupBeforeSave(controlador) {
		return MEntidade.cleanupBeforeSave(controlador);
	}

	static validarParaSalvar(controlador) {
		let messages = [];
		if (Validator.isEmpty(controlador.descricao)) messages.push("A descrição é obrigatória");
		if (Validator.isEmpty(controlador.endereco)) messages.push("O endereço é obrigatório");
		return messages;
	}

}

export const MapControladores = {
	"safety.neokoros.x86.controlador.jar": "Neokoros",
	"safety.intelbras.controlador.jar": "Intelbras",
	"safety.hikvision.controlador.jar": "Hikvision",
	"safety.trix.x86.controlador.jar": "Trix",
	"safety.linear-hcs.controlador.jar": "Linear",
};

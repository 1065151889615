import {MEntidade} from "./MEntidade";
import moment from "moment";

export class MLogPonto extends MEntidade {

	static Tipos = [
		{label: "Todos", value: null},
		{label: "Cabeçalho", value: "CABECALHO"},
		{label: "Empresa", value: "EMPRESA"},
		{label: "Marcação", value: "MARCACAO"},
		{label: "Relógio", value: "RELOGIO"},
		{label: "Funcionário", value: "FUNCIONARIO"},
		{label: "Ligado", value: "LIGADO"},
	];

	static MapTipos = {
		"CABECALHO": "Cabeçalho",
		"EMPRESA": "Empresa",
		"MARCACAO": "Marcação",
		"RELOGIO": "Relógio",
		"FUNCIONARIO": "Funcionário",
		"LIGADO": "Ligado"
	};

	static Modelo() {
		return {
			tipo: "MARCACAO",
			data: moment().format("YYYY-MM-DDTHH:mm:ss"),
			nsr: 0,
			nis: "",
			serial: "",
			modo: "MANUAL"
		};
	}

	static Portarias = [
		{label: "por Equipamento", value: ""},
		{label: "MTE 1510/09", value: "MTE-1510/09"},
		{label: "MTE 671/21", value: "MTE-0671/21"}
	];

}

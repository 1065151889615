import {Fetch} from "../../utils/Fetch";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

const url = process.env.REACT_APP_API_URL || "";

export class OcorrenciaService extends EntidadeNextGenService {

	constructor() {
		super("/ocorrencias");
	}

	dispensar() {
		return Fetch.Post(`${url}/ocorrencias/dispensar`);
	}

}

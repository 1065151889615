import React from "react";
import classNames from "classnames";

export class EtiquetaChip extends React.Component {

	render() {
		const etiqueta = resolveEtiqueta(this.props.etiqueta);
		const classes = classNames({
			"ui-tzm-minchip": true,
			[`ui-pattern-${etiqueta.padrao || "0"}`]: true,
			[`ui-pattern-a${etiqueta.efeito || "0"}`]: true
		});
		const style = {
			backgroundColor: etiqueta.fundo || "#CCCCCC",
			color: etiqueta.fonte || "#000000"
		};
		return (
			<div key={etiqueta.descricao} className={classes} style={style} {...this.props}>
				{etiqueta.icone?.length && etiqueta.icone !== "null" ? <><i style={{marginRight: "4px"}} className={`fad fa-etchip-min-size fa-${etiqueta.icone}`}/></> : ""}{etiqueta.descricao}
			</div>
		);
	}

}

function resolveEtiqueta(etiqueta) {
	if (typeof etiqueta === "string") {
		const values = etiqueta.split(",");
		return {
			descricao: values[0],
			fonte: values[1],
			fundo: values[2],
			padrao: values[3],
			efeito: values[4],
			icone: values[5]
		};
	}
	return etiqueta;
}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MModeloAgenda} from "../../utils/models/MModeloAgenda";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {PeriodoService} from "../servicos/PeriodoService";
import {SuggestionBox} from "../commons/SuggestionBox";
import {EditarModeloAcesso} from "../ModeloAcesso/EditarModeloAcesso";
import {detalharModeloAcesso, MModeloAcesso} from "../../utils/models/MModeloAcesso";
import {ModeloAcessoService} from "../servicos/ModeloAcessoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import iconeEditar from "../../media/icones/editar.png";
import iconeRemover from "../../media/icones/remover.png";
import {MAgenda} from "../../utils/models/MAgenda";
import {SelectButton} from "../commons/SelectButton";

const flags = [
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/unidades.png")}/> Unidade Obrigatória</div><span>Obriga o preenchimento de unidade para a agenda</span></div>, value: "FORCAR_UNIDADE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/credenciais.png")}/> Credencial Obrigatória</div><span>Obriga o preenchimento de uma credencial temporária para cada pessoa</span></div>, value: "FORCAR_CREDENCIAL_TEMPORARIA"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/visitados.png")}/> Visitado Obrigatório</div><span>Obriga que a agenda tenha ao menos um responsável (visitado)</span></div>, value: "FORCAR_VISITADO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/etiquetas.png")}/> Categoria Obrigatória</div><span>Obriga o preenchimento de uma categoria para a agenda</span></div>, value: "FORCAR_CATEGORIA"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/documentos.png")}/> Permitir Documento</div><span>Permite a utilização de documentos como credencial de acesso</span></div>, value: "PERMITIR_DOCUMENTO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/veiculos.png")}/> Veículo Obrigatório</div><span>Obriga o preenchimento de um veículo na agenda</span></div>, value: "FORCAR_VEICULO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/integracao.png")}/> Observação Obrigatória</div><span>Obriga o preenchimento do campo Observações da agenda</span></div>, value: "FORCAR_OBSERVACAO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/portaria.png")}/> Adicional de Portaria</div><span>Marca este modelo de agenda como adicional de portaria (roteiro)</span></div>, value: "PORTARIA_ADICIONAL"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/favoritos.png")}/> Principal Obrigatório</div><span>Faz com que a definição de uma pessoa principal na agenda seja obrigatória</span></div>, value: "FORCAR_PRINCIPAL"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/empresas.png")}/> Empresa Obrigatória</div><span>Obriga o preenchimento de uma empresa na agenda</span></div>, value: "FORCAR_EMPRESA"},
	{label: "", value: null},
	{label: "", value: null}
];

export class EditarModeloAgenda extends React.Component {

	state = {
		modeloAgenda: Object.assign(MModeloAgenda.Modelo(), this.props.modeloAgenda),
		visible: true,
		modeloAcesso: "",
		lockSave: false
	};

	modeloAgendaService = new ModeloAgendaService();
	modeloAcessoService = new ModeloAcessoService();
	periodoService = new PeriodoService();
	securityModeloAcesso = UserData.security("MAC");
	security = UserData.security("POR", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarModeloAgenda = () => {
		if (this.security.edit) {
			let modeloAgenda = MModeloAgenda.cleanupBeforeSave(Object.assign({}, this.state.modeloAgenda));
			let messages = MModeloAgenda.validarParaSalvar(modeloAgenda);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.modeloAgendaService.salvar(modeloAgenda).then((modeloAgenda) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(modeloAgenda);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let modeloAgenda = this.state.modeloAgenda;
		modeloAgenda[event.name] = event.value;
		if (event.name === "flags") {
			if (modeloAgenda.flags.includes("PORTARIA_ADICIONAL")) {
				modeloAgenda.flags = ["PORTARIA_ADICIONAL"];
				modeloAgenda.agendaFlags = [];
				modeloAgenda.obrigatorios = [];
				modeloAgenda.duracao = 0;
			}
		}
		this.setState({modeloAgenda});
	}

	handleChangeFlags = (event) => {
		const {modeloAgenda} = this.state;
		modeloAgenda.flags = event.value.filter(e => e);
		this.setState({modeloAgenda});
	}

	render() {
		return (
			<Dialog modal header="Modelo de Agenda" visible={this.state.visible} style={{width: "1150px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={9} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.modeloAgenda.descricao} />
          			<TextField disabled={this.state.modeloAgenda.flags?.includes("PORTARIA_ADICIONAL")} grid={3} label="Duração" required name="duracao" onChange={this.handleChange} value={this.state.modeloAgenda.duracao} />
					<SelectButton multiple grid={12} name="flags" value={this.state.modeloAgenda.flags} onChange={this.handleChangeFlags} label="Opções" options={flags.slice(0, 6)}/>
					<SelectButton style={{marginTop: "-8px"}} multiple grid={12} name="flags" value={this.state.modeloAgenda.flags} onChange={this.handleChangeFlags} options={flags.slice(6, 12)}/>
					<MultiCombobox disabled={this.state.modeloAgenda.flags?.includes("PORTARIA_ADICIONAL")} appendTo={document.body} defaultLabel="Nenhum" grid={12} options={MModeloAgenda.Obrigatorios} label="Campos Obrigatórios (para novos cadastros de Pessoa Física)" name="obrigatorios" onChange={this.handleChange} value={this.state.modeloAgenda.obrigatorios} />
					<MultiCombobox disabled={this.state.modeloAgenda.flags?.includes("PORTARIA_ADICIONAL")} defaultLabel="Nenhuma" options={MAgenda.Flags} appendTo={document.body} label="Opções de Agenda" grid={12} name="agendaFlags" onChange={this.handleChange} value={this.state.modeloAgenda.agendaFlags} />
					<SuggestionBox style={{position: "relative"}} appendTo={document.body} label="Modelos de Acesso" grid={12} field="descricao" completeMethod={this.listarModelosAcesso} suggestions={this.state.modelosAcesso} onChange={(e) => this.setState({modeloAcesso: e.value})} onSelect={this.adicionarModeloAcesso} value={this.state.modeloAcesso} />
					<div className="ui-g-12">
						<DataTable value={this.state.modeloAgenda.modelosAcesso}>
							<Column style={{width: "25em"}} header="Descrição" field="descricao" />
							<Column header="Permissões" body={(ma) => {
								return (
									<div key={ma.id}>{detalharModeloAcesso(ma)}</div>
								);
							}} />
							<Column header="Ações" key="acoes" field="acoes" style={{width: "7em"}} body={(rowData) => {
								return (
									<div style={{textAlign: "center"}}>
										{this.securityModeloAcesso.edit && (UserData.usuario.login === "admin" || rowData.descricao !== "Acesso Geral") ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Modelo de Acesso" onClick={(event) => this.editarModeloAcesso(rowData)} /> : null}
										{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Modelo de Acesso" onClick={(event) => this.removerModeloAcesso(rowData)} /> : null}
									</div>
								);
							}} />
						</DataTable>
					</div>
					{UserData.hasRole("XX0C") ? <TextField grid={12} label="Chave de Integração" name="chaveIntegracao" onChange={this.handleChange} value={this.state.modeloAgenda.chaveIntegracao} /> : null}
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.modeloAgenda.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/modelos-agenda", this.state.modeloAgenda)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.modeloAgenda.id)) || this.state.lockSave} onClick={this.salvarModeloAgenda} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarModeloAgenda} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	editarModeloAcesso = (modeloAcesso) => {
		if (modeloAcesso && modeloAcesso.id) {
			this.modeloAcessoService.buscar(modeloAcesso).then((modeloAcesso) => {
				ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={modeloAcesso} onModalClose={(modeloAcesso) => {
					let {modeloAgenda} = this.state;
					if (modeloAgenda.modelosAcesso == null) modeloAgenda.modelosAcesso = [];
					modeloAgenda.modelosAcesso = modeloAgenda.modelosAcesso.filter(a => a.id !== modeloAcesso.id);
					modeloAgenda.modelosAcesso.push(modeloAcesso);
					this.setState({modeloAgenda});
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarModeloAcesso key={Math.random()} modeloAcesso={null} onModalClose={(modeloAcesso) => {
				let {modeloAgenda} = this.state;
				if (modeloAgenda.modelosAcesso == null) modeloAgenda.modelosAcesso = [];
				modeloAgenda.modelosAcesso.push(modeloAcesso);
				this.setState({modeloAgenda});
			}} />);
		}
	}

	removerModeloAcesso = (event) => {
		const {modeloAgenda} = this.state;
		modeloAgenda.modelosAcesso = modeloAgenda.modelosAcesso.filter((modeloAcesso) => event.id !== modeloAcesso.id);
		this.setState({modeloAgenda});
	}

	adicionarModeloAcesso = (event) => {
		if (event.value.id === -1) {
			ModalRealm.showDialog(<EditarModeloAcesso modeloAcesso={{...MModeloAcesso.Modelo(), descricao: event.value.descricao.replace("Criar Modelo de Acesso ", "")}} onModalClose={(modeloAcesso) => {
				const {modeloAgenda} = this.state;
				modeloAgenda.modelosAcesso.unshift(modeloAcesso);
				this.setState({modeloAgenda});
			}} />);
		} else {
			const {modeloAgenda} = this.state;
			modeloAgenda.modelosAcesso.unshift(event.value);
			this.setState({modeloAgenda});
		}
		this.setState({modeloAcesso: ""});
	}

	listarModelosAcesso = (event) => {
		this.modeloAcessoService.listar(`search=status:ATIVADO;descricao~${event.query};id!${this.state.modeloAgenda.modelosAcesso.map(p => p.id).join(",")}`, {size: 10, page: 0}).then((modelosAcesso) => {
			if (modelosAcesso.length === 0) {
				this.setState({modelosAcesso: [{id: -1, descricao: `Criar Modelo de Acesso ${event.query}`}]});
			} else {
				this.setState({modelosAcesso});
			}
		});
	}

}

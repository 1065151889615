import React from "react";
import ReactDOM from "react-dom";
import {Application} from "./Application";
import "./index.css";
import "./utils/Format";

ReactDOM.render(
    <Application/>,
    document.getElementById("root")
);

import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";

export class SelecionarAviso extends Component {

	state = {
		visible: true
	};

	onClose = () => {
		this.setState({visible: false});
	}

	printUnidade = aviso => {
		return (
			<div className="aviso-select" onClick={() => {
				this.props.onModalClose(aviso);
				this.onClose();
			}} style={{backgroundColor: aviso.etiqueta.fundo, color: aviso.etiqueta.fonte}}>
				{aviso.etiqueta.descricao}
			</div>
		);
	}

	render() {
		return (
			<Dialog modal header="Pré-Agendamentos" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-12">Esta pessoa possui pré-agendamentos para as unidades:</div>
					<div className="ui-g-12">{this.props.avisos.map(this.printUnidade)}</div>
					<div className="ui-g-12" style={{fontSize: ".9em", fontStyle: "italic"}}>
						(Selecione uma unidade da lista acima ou clique em "Fechar" para continuar)
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

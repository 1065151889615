import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MDocumento extends MEntidade {

	static Modelo() {
		return {
			numero: null,
			tipo: null
		};
	}

	static CategoriasCNH = [
		{label: "A", value: "A"},
		{label: "B", value: "B"},
		{label: "C", value: "C"},
		{label: "D", value: "D"},
		{label: "E", value: "E"},
		{label: "AB", value: "AB"},
		{label: "AC", value: "AC"},
		{label: "AD", value: "AD"},
		{label: "AE", value: "AE"},
	];

	static validarParaSalvar(documento) {
		const messages = [];
		if (!Validator.isEntidade(documento.tipo)) {
			messages.push("O tipo é obrigatório");
			return messages;
		}
		if (documento.tipo?.obrigatorios.includes("NUMERO") && (Validator.isEmpty(documento.numero) || Number(documento.numero) === 0)) {
			messages.push("O número é obrigatório");
		}
		if (documento.tipo?.obrigatorios.includes("VALIDADE") && !documento.validade?.length) {
			messages.push("A validade é obrigatória");
		}
		// TODO - conferir algoritmo de validação de CNH
		//if (!messages.length && documento.tipo === "CNH" && !Validator.isCNH(documento.numero)) messages.push("O número da CNH é inválido");
		return messages;
	}

}

export class MDocumentoTipo {

	static Modelo() {
		return {
			descricao: "",
			aplicacao: "FISICA",
			obrigatorios: [],
			ordenacao: 0,
			permissoesAlteracao: [
				"PORTARIA",
				"UNIDADES",
				"AGENDAS",
				"PESSOAS",
				"VEICULOS",
				"EMPRESAS"
			]
		};
	}

}

export const labelTipoDocumentoObrigatorio = {
	NUMERO: "Número",
	VALIDADE: "Validade"
};

export const labelLTipoDocumentoAplicacao = {
	FISICA: "Pessoa Física",
	JURIDICA: "Pessoa Jurídica",
	VEICULO: "Veículo",
	AGENDA: "Agenda"
};

export const optionsTipoDocumentoObrigatorio = [
	{label: "Número", value: "NUMERO"},
	{label: "Validade", value: "VALIDADE"}
];

export const optionsTipoDocumentoAplicacao = [
	{label: "Pessoa Física", value: "FISICA"},
	{label: "Pessoa Jurídica", value: "JURIDICA"},
	{label: "Veículo", value: "VEICULO"},
	{label: "Agenda", value: "AGENDA"}
];

export const optionsTipoDocumentoPermissaoAlteracao = [
	{label: "Portaria", value: "PORTARIA"},
	{label: "Unidades", value: "UNIDADES"},
	{label: "Agendas", value: "AGENDAS"},
	{label: "Pessoas", value: "PESSOAS"},
	{label: "Veículos", value: "VEICULOS"},
	{label: "Empresas", value: "EMPRESAS"}
];

export function isDocumentoEditavel(doc) {
	return (
		doc.tipo?.permissoesAlteracao?.some(dtpa => {
			switch (dtpa) {
				case "PORTARIA":
					return window.location.pathname.endsWith("/portaria");
				case "UNIDADES":
					return window.location.pathname.endsWith("/unidades");
				case "AGENDAS":
					return window.location.pathname.endsWith("/agendas");
				case "PESSOAS":
					return window.location.pathname.endsWith("/pessoas-fisicas");
				case "VEICULOS":
					return window.location.pathname.endsWith("/veiculos");
				case "EMPRESAS":
					return window.location.pathname.endsWith("/pessoas-juridicas");
				default:
					return true;
			}
		})
	);
}

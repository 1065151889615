import {httpGet} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class BloqueioService extends EntidadeNextGenService {

	constructor() {
		super("/bloqueios");
	}

	isBloqueado(id) {
		return httpGet(`/bloqueios/pessoa/${id}`);
	}

}

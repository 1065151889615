import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import preval from "preval.macro";

const BUILD_YEAR = preval`
	const moment = require('moment');
	module.exports = moment().format('YYYY');
`;

export class AboutDialog extends Component {

	state = {
		visible: true,
		key: `ad_${Math.random()}`
	};

	render() {
		return (
			<Dialog key={this.state.key} modal header="Sobre o Safety" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-12">
						<b>TZM Safety</b> (Versão 5.2)<br />
						©2011-{BUILD_YEAR}, TZM Informática e Telecomunicações.<br />
						<a href="skype:+554333670001">+55 (43) 3367-0001</a> - <a href="mailto:suporte@safety.inf.br">desenvolvimento@tzm.com.br</a> - <a rel="noopener noreferrer" target="_blank" href="https://safety.inf.br">https://safety.inf.br</a>
						<br /><br />
						AVISO: este programa de computador é protegido por leis de direitos autorais e tratados internacionais.
						A reprodução ou distribuição não autorizada deste programa, ou de qualquer parte dele, resultará em severas punições civis e criminais
						e os infratores serão punidos sob a máxima extensão possível dentro da lei.<br /><br />
						<span style={{fontSize: ".8em"}}>
							Bancos de dados suportados:<br />
							PostgreSQL 9.6+,
							Microsoft SQL Server 2012+,
							Oracle Database 11g+,
							MySQL 8+
						</span>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	onClose = () => this.setState({visible: false});

}

import React from "react";
import {SuggestionBox} from "../components/commons/SuggestionBox";
import {GUI} from "../utils/Constants";
import {MEtiqueta} from "../utils/models/MEtiqueta";

export class MultiSelect extends React.Component {

	state = {
		entidades: []
	};

	listar = (event) => {
		this.props.service.listar(`search=${this.props.query}~${event.query}`, {size: GUI.maxSuggestion, page: 0}).then((entidades) => {
			if ((this.props.insertable == null || this.props.insertable === true) && entidades.length === 0) {
				entidades.push({...this.props.modelo, id: -1, [this.props.field]: `Criar ${this.props.entidade} ${event.query}`});
			}
			this.setState({entidades});
		});
	}

	onChange = (event) => {
		if (this.props.onChange) {
			event.value = event.value.filter(e => e.id !== -1);
			this.props.onChange(event);
		}
	}

	onRemove = (event) => {
		let entidades = this.props.value || [];
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, value: entidades.filter(e => e.id !== event.id)});
		}
	}

	render() {
		return (
			<SuggestionBox appendTo={document.body} tokenTemplate={(value) => MEtiqueta.Chip(value, this.props.onEdit, this.onRemove, this.props.field, this.props.disabled)} multiple onSelect={this.props.onSelect} name={this.props.name} field={this.props.field} suggestions={this.state.entidades} completeMethod={this.listar} value={this.props.value} {...this.props} onChange={this.onChange} />
		);
	}

}

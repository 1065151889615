import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MReserva {

	static Modelo() {
		return {
			status: "PENDENTE",
			convidados: []
		};
	}

	static cleanupBeforeSave(reserva) {
		if (!Validator.isEntidade(reserva.espaco)) reserva.espaco = null;
		if (!Validator.isEntidade(reserva.pessoa)) reserva.pessoa = null;
		return MEntidade.cleanupBeforeSave(reserva);
	}

	static validarParaSalvar(reserva) {
		let messages = [];
		return messages;
	}

}

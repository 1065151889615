import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeRemover from "../../media/icones/excluir.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {MModeloAgenda} from "../../utils/models/MModeloAgenda";
import {MSetupPortaria} from "../../utils/models/MSetupPortaria";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EditarEtiqueta} from "../Etiqueta/EditarEtiqueta";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {SetupPortariaService} from "../servicos/SetupPortariaService";
import {EditarModeloAgenda} from "../ModeloAgenda/EditarModeloAgenda";
import {MAgenda} from "../../utils/models/MAgenda";
import {SelectEquipamento} from "../../select/SelectEquipamento";
import {SelectEtiqueta} from "../../select/SelectEtiqueta";

function fillNulls(portaria) {
	if (!portaria) {
		portaria = MSetupPortaria.Modelo();
	}
	return portaria;
}

export class EditarPortaria extends React.Component {

	state = {
		portaria: fillNulls(this.props.portaria),
		visible: true,
		tabIndex: 0
	};

	portariaService = new SetupPortariaService();
	etiquetaService = new EtiquetaService();
	modeloAgendaService = new ModeloAgendaService();
	security = UserData.security("POR", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarPortaria = () => {
		let portaria = MSetupPortaria.cleanupBeforeSave(Object.assign({}, this.state.portaria));
		let messages = MSetupPortaria.validarParaSalvar(portaria);
		if (messages.length > 0) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			this.portariaService.salvar(this.state.portaria).then((portaria) => {
				if (this.props.onModalClose) {
					this.props.onModalClose(portaria);
				}
				this.onClose();
			});
		}
	}

	handleChange = (event) => {
		let portaria = this.state.portaria;
		portaria[event.name] = event.value;
		this.setState({portaria});
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page:0, size: 10, sort: "descricao"}).then((etiquetas) => {
			if (etiquetas.length === 0) {
				this.setState({etiquetas: [{...MEtiqueta.Modelo(), id: -1, descricao: `Criar etiqueta ${event.query}`}]});
			} else {
				this.setState({etiquetas});
			}
		});
	}

	editarEtiqueta = (rowData) => {
		this.etiquetaService.buscar(rowData).then((etiqueta) => {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={etiqueta} onModalClose={(etiqueta) => {
				let portaria = this.state.portaria;
				let index = portaria.etiquetas.map(e => e.id).indexOf(etiqueta.id);
				portaria.etiquetas[index] = etiqueta;
				this.setState({portaria});
			}} />);
		});
	}

	selecionarEtiquetas = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={{...MEtiqueta.Modelo(), descricao: event.value.descricao.replace("Criar etiqueta ", "")}} onModalClose={(etiqueta) => {
				let portaria = this.state.portaria;
				portaria.etiquetas.push(etiqueta);
				this.setState({portaria});
			}} />);
		}
	}

	removerEtiqueta = (rowData) => {
		let portaria = this.state.portaria;
		portaria.etiquetas = portaria.etiquetas.filter(e => e.id !== rowData.id);
		this.setState({portaria});
	}

	editarSetor = (rowData) => {
		this.etiquetaService.buscar(rowData).then((etiqueta) => {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={etiqueta} onModalClose={(etiqueta) => {
				let portaria = this.state.portaria;
				let index = portaria.setores.map(e => e.id).indexOf(etiqueta.id);
				portaria.setores[index] = etiqueta;
				this.setState({portaria});
			}} />);
		});
	}

	editarModeloAgenda = (modeloAgenda) => {
		ModalRealm.showDialog(<EditarModeloAgenda modeloAgenda={modeloAgenda} key={Math.random()} onModalClose={(modeloAgenda) => {
			const portaria = this.state.portaria;
			portaria.modelosAgenda = portaria.modelosAgenda.filter((ma) => ma.id !== modeloAgenda.id);
			portaria.modelosAgenda.push(modeloAgenda);
			this.setState({portaria});
		}} />);
	}

	removerSetores = (rowData) => {
		let portaria = this.state.portaria;
		portaria.setores = portaria.setores.filter(e => e.id !== rowData.id);
		this.setState({portaria});
	}

	tabChange = (event) => this.setState({tabIndex: event.index});

	modelosAgendaColumns = [
		<Column header="Descrição" field="descricao" style={{width: "10em"}} />,
		<Column header="Modelos de Acesso" body={(modeloAgenda) => {
			return modeloAgenda.modelosAcesso.map((ma) => <div key={ma.id}>{ma.descricao}</div>);
		}} style={{width: "12em"}} />,
		<Column header="Opções" body={(modeloAgenda) => {
			return modeloAgenda.flags.concat(modeloAgenda.agendaFlags).map((f) => MModeloAgenda.MapFlags[f] || MAgenda.MapFlags[f]).join(", ");
		}} style={{width: "*"}} />,
		<Column header="Duração" style={{width: "9em"}} body={(modeloAgenda) => {
			switch (Number(modeloAgenda.duracao)) {
				case -1:
					return "Até o final do dia";
				case 0:
					return "Indeterminada";
				default:
					return `${modeloAgenda.duracao} minutos`;
			}
		}} />,
		<Column header="Ações" style={{width: "5em", textAlign: "center"}} body={(modeloAgenda) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Modelo de Agenda" onClick={() => this.editarModeloAgenda(modeloAgenda)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Modelo de Agenda" onClick={() => this.removerModeloAgenda(modeloAgenda)} /> : null}
				</div>
			);
		}} />
	];

	removerModeloAgenda = (modeloAgenda) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este modelo de agenda?", () => {
			const portaria = this.state.portaria;
			if (modeloAgenda.id) {
				portaria.modelosAgenda = portaria.modelosAgenda.filter((ma) => ma.id !== modeloAgenda.id);
			} else {
				portaria.modelosAgenda = portaria.modelosAgenda.filter((ma) => ma._key !== modeloAgenda._key);
			}
			this.setState({portaria});
		});
	}
	
	modelosAgendaAddButton = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
		ModalRealm.showDialog(<EditarModeloAgenda modeloAgenda={MModeloAgenda.Modelo()} key={Math.random()} onModalClose={(modeloAgenda) => {
			const portaria = this.state.portaria;
			portaria.modelosAgenda = portaria.modelosAgenda.filter((ma) => ma.id !== modeloAgenda.id);
			portaria.modelosAgenda.push(modeloAgenda);
			this.setState({portaria});
		}} />);
	}} />;

	handleState = (event) => this.setState({[event.name]: event.value});

	listarModelosAgenda = (event) => {
		this.modeloAgendaService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((modelosAgenda) => {
			if (modelosAgenda.length === 0) {
				this.setState({modelosAgenda: [{id: -1, descricao: `Criar modelo de agenda ${event.query}`}]});
			} else {
				this.setState({modelosAgenda});
			}
		});
	}

	addModeloAgenda = (event) => {
		if (event.value.id === -1) {
			ModalRealm.showDialog(<EditarModeloAgenda modeloAgenda={{...MModeloAgenda.Modelo(), descricao: event.value.descricao.replace("Criar modelo de agenda ", "")}} onModalClose={(modeloAgenda) => {
				let portaria = this.state.portaria;
				portaria.modelosAgenda.push(modeloAgenda);
				this.setState({portaria});
			}} />);
		} else {
			let portaria = this.state.portaria;
			portaria.modelosAgenda.push(event.value);
			this.setState({portaria});
		}
		this.setState({modeloAgenda: ""});
	}

	render() {
		return (
			<Dialog modal header="Portaria" visible={this.state.visible} style={{width: "1050px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12" activeIndex={this.state.tabIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField grid={8} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.portaria.descricao}/>
								<SelectEquipamento label="Equipamento (Padrão)" grid={4} onChange={this.handleChange} name="equipamentoPadrao" value={this.state.portaria.equipamentoPadrao}/>
								<MultiSelectEtiqueta label="Setores de Acesso" grid={12} onSelect={this.handleChange} name="setores" value={this.state.portaria.setores}/>
								<MultiSelectEtiqueta label="Etiquetas de Visita" grid={8} onSelect={this.handleChange} name="etiquetas" value={this.state.portaria.etiquetas}/>
								<SelectEtiqueta grid={4} label="Etiqueta de Emergência" name="emergencia" onChange={this.handleChange} value={this.state.portaria.emergencia}/>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Modelos de Agenda">
							<PanelContent>
								<SuggestionBox style={{position: "relative"}} appendTo={document.body} label="Modelos de Agenda" grid={12} field="descricao" completeMethod={this.listarModelosAgenda} suggestions={this.state.modelosAgenda} onChange={(e) => this.setState({modeloAgenda: e.value})} onSelect={this.addModeloAgenda} value={this.state.modeloAgenda} />
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhum modelo de agenda adicionado" paginatorLeft={this.modelosAgendaAddButton} value={this.state.portaria.modelosAgenda} paginator rows={10} children={this.modelosAgendaColumns} />
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.salvarPortaria} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose} onCtrlS={this.salvarPortaria}/> : null}
			</Dialog>
		);
	}

}

import { httpGet } from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class PessoaJuridicaService extends EntidadeNextGenService {

	constructor() {
		super("/pessoas-juridicas");
	}

	async listarSuggestion(params) {
		return await httpGet(`/pessoas-juridicas/suggestion`,params);
	}

}

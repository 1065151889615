import React from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {TextField} from "../commons/TextField";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {Shortcut} from "../commons/Shortcut";
import {UserData} from "../../utils/UserData";
import {FormularioService} from "../servicos/FormularioService";
import {ModalRealm} from "../commons/ModalRealm";
import {criarFormulario} from "../../utils/models/MFormulario";
import {MFeriado} from "../../utils/models/MFeriado";

export class EditarFormulario extends React.Component {

	state = {
		visible: true,
		formulario: this.props.formulario || criarFormulario()
	};
	
	security = UserData.security("FRM", true);
	formularioService = new FormularioService();

	onClose = () => this.setState({visible: false});

	handleChange = e => {
		const {formulario} = this.state;
		formulario[e.name] = e.value;
		this.setState({formulario});
	}

	salvarFormulario = () => {
		let formulario = MFeriado.cleanupBeforeSave(Object.assign({}, this.state.formulario));
		if (!formulario.descricao?.length) {
			ModalRealm.showInformacao("warning", ["A descrição é obrigatória"]);
			return;
		}
		this.formularioService.salvar(formulario).then(f => {
			if (this.props.onModalClose) {
				this.props.onModalClose(f);
			}
			this.onClose();
		});
	}

	render() {
		return (
			<Dialog modal header="Formulário" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={8} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.formulario.descricao}/>
					<TextField unit="dias" grid={4} type="numeric" label="Validade" name="validade" onChange={this.handleChange} value={this.state.formulario.validade}/>
					{UserData.hasRole("XX0C") ? <TextField grid={12} label="Chave de Integração" name="chaveIntegracao" onChange={this.handleChange} value={this.state.formulario.chaveIntegracao} /> : null}
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.formulario.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/formularios", this.state.formulario)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.formulario.id)) || this.state.lockSave} onClick={this.salvarFormulario} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarFormulario} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

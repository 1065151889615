import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpPost} from "../../utils/Request";

export class LogVagaService extends EntidadeNextGenService {

	constructor() {
		super("/logs-vaga");
	}

	listarOcupadas(vagas) {
		return httpPost(this.basePath + "/ocupadas", vagas.map(v => v.id));
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MMessengerBot} from "../../utils/models/MMessengerBot";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MessengerBotService} from "../servicos/MessengerBotService";

export class EditarMessengerBot extends React.Component {

	state = {
		messengerBot: Object.assign(MMessengerBot.Modelo(), this.props.messengerBot),
		visible: true,
		lockSave: false
	};

	messengerBotService = new MessengerBotService();
	security = UserData.security("MBT", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarMessengerBot = () => {
		if (this.security.edit) {
			let messengerBot = MMessengerBot.cleanupBeforeSave(Object.assign({}, this.state.messengerBot));
			let messages = MMessengerBot.validarParaSalvar(messengerBot);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.messengerBotService.salvar(messengerBot).then((messengerBot) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(messengerBot);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let messengerBot = this.state.messengerBot;
		messengerBot[event.name] = event.value;
		this.setState({messengerBot});
	}

	listarLocalidades = (event) => {
		this.localidadeService.listar(`search=nome~${event.query}`, {page: 0, size: 10, sort: "nome"}).then(localidades => this.setState({localidades}));
	}

	render() {
		return (
			<Dialog modal header="Bot Mensageiro" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={8} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.messengerBot.descricao} />
					<Combobox grid={4} label="Tipo" appendTo={document.body} name="tipo" options={MMessengerBot.Tipos} onChange={this.handleChange} value={this.state.messengerBot.tipo} />
					<TextField grid={12} label="Token" name="token" onChange={this.handleChange} value={this.state.messengerBot.token} />
					<TextField grid={12} label="ID do Chat" name="chat" onChange={this.handleChange} value={this.state.messengerBot.chat} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.messengerBot.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/messenger-bots", this.state.messengerBot)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.messengerBot.id)) || this.state.lockSave} onClick={this.salvarMessengerBot} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarMessengerBot} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

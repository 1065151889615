export class MMatrizHorarioPeriodo {
    
    static Modelo() {
        return {
            dias: [],
            inicio: "",
            fim: "",
            folga: false,
            proximo: "NENHUM"
        };
    }

    static Proximos = [
        {label: "Nenhum", value: "NENHUM"},
        {label: "Fim", value: "FIM"},
        {label: "Ambos", value: "AMBOS"}
    ];

}

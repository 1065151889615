import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpPost} from "../../utils/Request";

export class LogPontoService extends EntidadeNextGenService {

	constructor() {
		super("/logs-ponto");
	}

	async listarPessoas(nis) {
		return await httpPost("/logs-ponto/pessoas", nis);
	}

}

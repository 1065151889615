import moment from "moment";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import audioAlert from "../../media/audio/light.ogg";
import {MCredencial} from "../../utils/models/MCredencial";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {UserData} from "../../utils/UserData";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {ActionFloat} from "../commons/ActionFloat";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {PainelOcorrencia} from "../commons/PainelOcorrencia";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {LogAcessoService} from "../servicos/LogAcessoService";
import "./Monitor.css";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";
import {httpPost} from "../../utils/Request";
import {RespostasOrdinal} from "../../utils/models/MLogAcesso";

export class Monitor extends React.Component {

    state = {
        acessos: {},
        requisicoes: {},
        equipamentos: [],
        monitoraveis: [],
        mute: false,
        o_monitoraveis: [],
        quantidade: 10
    };

    security = UserData.security("MON", true);
    equipamentoService = new EquipamentoService();
    logAcessoService = new LogAcessoService();

    componentDidMount() {
        const selecionados = JSON.parse(window.localStorage.getItem("safety-monitor-monitoraveis") || "[]");
        const quantidade = Number(window.localStorage.getItem("safety-monitor-quantidade") || 5);
        this.equipamentoService.listar(`sort=descricao`, {page: 0, size: 1000}).then((equipamentos) => {
            this.setState({
                quantidade, 
                equipamentos, 
                monitoraveis: equipamentos.filter((e) => selecionados.includes(e.id)), 
                o_monitoraveis: equipamentos.map((e) => ({label: e.descricao, value: e}))
            }, this.listAcessos);
        });
    }

    listAcessos = () => {
        for (const equipamento of this.state.equipamentos) {
            httpPost("/painel-acesso", [equipamento.id]).then(logsAcesso => {
                const {acessos} = this.state;
                acessos[equipamento.id] = logsAcesso.filter(la => la.pessoa);
                this.setState({acessos});
            });
        }
    }

    render() {
        return (
            <div>
                <PainelOcorrencia />
                <Panel header="Monitor de Equipamentos">
                    <div className="ui-g">
                        <div className="ui-g-12">
                            <MultiCombobox defaultLabel="Nenhum equipamento selecionado" label="Equipamentos" options={this.state.o_monitoraveis} value={this.state.monitoraveis} onChange={this.handleChange} name="monitoraveis" />
                        </div>
                        {this.state.monitoraveis.map(this.equipamentoTemplate)}
                    </div>
                </Panel>
                <SafetyWebSocket path="/logs-acesso" onMessage={this.queueLogAcesso} />
                <audio ref={this.refAlert}>
					<source src={audioAlert} type="audio/ogg" />
				</audio>
                <ModalRealm/>
                <ActionFloat>
                    <ActionFloat.Button mode="secondary" onClick={this.toggleMute} icon={`fa-volume-${this.state.mute ? "off" : "up"}`} />
                </ActionFloat>
            </div>
        );
    }

    toggleMute = () => this.setState({mute: !this.state.mute});

    refAlert = (el) => this.soundAlert = el;

    queueLogAcesso = (logAcesso) => {
		if (logAcesso.atividade === "PASSAGEM") {
			if (!this.state.mute && this.state.monitoraveis.some((e) => e.id === logAcesso.equipamento.id)) {
				if (this.soundAlert) {
                    this.soundAlert.play();
                }
			}
            const acessos = this.state.acessos;
            acessos[logAcesso.equipamento.id].unshift(logAcesso);
            acessos[logAcesso.equipamento.id].length = this.state.quantidade;
            this.setState({acessos});
		} else {
            if (logAcesso.equipamento) {
                const requisicoes = this.state.requisicoes;
                requisicoes[logAcesso.equipamento.id] = logAcesso;
                this.setState({requisicoes});
            }
        }
	}

    equipamentoTemplate = (equipamento) => {
        const requisicao = this.state.requisicoes[equipamento.id] || {eventos: []};
        return (
            <div className="ui-sm-12 ui-md-6 ui-lg-4 ui-xl-3">
                <Panel header={equipamento.descricao} className="nopad-panel">
                    <div style={{minHeight: "116px", maxHeight: "116px"}} className={requisicao.eventos.length ? "ui-ocorrencia-panel" : ""}>
                        {this.requisicaoTemplate(requisicao)}
                    </div>
                    <div style={{borderTop: "1px solid #C8C8C8", maxHeight: "400px", overflowY: "scroll"}}>
                        {(this.state.acessos[equipamento.id] || []).map(this.acessoTemplate)}
                    </div>
                </Panel>
            </div>
        );
    }

    acessoTemplate = (acesso) => {
        if (acesso && acesso.pessoa) {
            const fotos = acesso.pessoa && acesso.pessoa.fotos ? acesso.pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc) : [];
            return (
                <div key={acesso.id} className={"tzm-monitor-item"}>
                    <div className="tzm-monitor-item-foto"><img src={toSrc(fotos[0], true)} alt="" /></div>
                    <div>{acesso.pessoa.nome}</div>
                    <div>{acesso.etiquetas_?.split(";").map((etiqueta) => <EtiquetaChip etiqueta={etiqueta} />)}</div>
                    <div>{acesso.id ? `Para ${acesso.destino && acesso.destino.tipo !== "EXTERNO" ? acesso.destino.descricao : "fora"}` : null}</div>
                    <div>{acesso.id ? `${MCredencial.MapFormas[acesso.forma]} às ${moment(acesso.data).format("DD/MM/YY HH:mm:ss")}` : null}</div>
                </div>
            );
        }
        return null;
    }

    requisicaoTemplate = (acesso) => {
        if (acesso && acesso.pessoa) {
            const fotos = acesso.pessoa && acesso.pessoa.fotos ? acesso.pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc) : [];
            return (
                <div key={acesso.id} className="tzm-monitor-item">
                    <div className="tzm-monitor-item-foto"><img src={toSrc(fotos[0], true)} alt="" /></div>
                    <div>{acesso.pessoa.nome}</div>
                    <div>{acesso.etiquetas_?.split(";").map((etiqueta) => <EtiquetaChip etiqueta={etiqueta} />)}</div>
                    <div>{acesso.id ? MCredencial.MapFormas[acesso.forma] : ""} - {acesso.respostas_?.split(";").some(r => RespostasOrdinal[r.split(",")[0]] === "LIBERACAO") ? "Acesso permitido" : acesso.atividade === "REQUISICAO" ? "Acesso negado" : "Registro de Evento"}</div>
                    <div>{acesso.eventos?.map(e => MOcorrencia.MapEventos[e])}</div>
                </div>
            );
        }
        return (
            <div key={acesso.id} className="tzm-monitor-item">
                <div className="tzm-monitor-item-foto"><img src={null} alt="" /></div>
                <div>{acesso.id ? `${MCredencial.MapFormas[acesso.forma]} às ${moment(acesso.data).format("DD/MM/YY HH:mm:ss")}` : ""}</div>
                <div>{acesso.respostas_ && acesso.atividade === "REQUISICAO" ? acesso.respostas_?.split(";").some(r => RespostasOrdinal[r.split(",")[0]] === "LIBERACAO") ? "Acesso permitido" : "Acesso negado" : ""}</div>
                <div>{acesso.eventos ? acesso.eventos.map(e => MOcorrencia.MapEventos[e]) : ""}</div>
            </div>
        );
    }

    handleChange = (event) => {
        const reload = event.name === "quantidade";
        this.setState({[event.name]: event.value});
        setTimeout(() => {
            window.localStorage.setItem("safety-monitor-quantidade", this.state.quantidade);
            window.localStorage.setItem("safety-monitor-monitoraveis", JSON.stringify(this.state.monitoraveis.map((e) => e.id)));
            if (reload) {
                this.listAcessos();
            }
        }, 10);
    }

}

import {httpDelete, httpGet, httpPost} from "../../utils/Request";

export class NextGenSingletonService {

	constructor(basePath) {
		this.basePath = basePath;
	}

	buscar() {
		return httpGet(`${this.basePath}/singleton`).then((response) => response);
	}

	buscarTablet() {
		return httpGet(`/public/tablet/setup-condominio`).then((response) => response);
	}

	salvar(entidade) {
		return httpPost(this.basePath, entidade).then((response) => response);
	}

	excluir(entidade) {
		return httpDelete(`${this.basePath}/${entidade.id}`).then((response) => response);
	}

}

import React from "react";
import {Combobox} from "./Combobox";
import {relatoriosCacique, relatoriosGeral} from "../servicos/RelatorioService";
import {UserData} from "../../utils/UserData";

const customFlags = process.env.REACT_APP_CUSTOM_FLAGS || "";

export class SelectRelatorio extends React.Component {

	state = {
		relatorios: []
	};

	componentDidMount() {
		let relatorios = relatoriosGeral.map(r => ({label: r.label, value: r.value.index, role: r.role}));
		if (customFlags.split(",").includes("CACIQUE")) {
			relatorios = relatorios.concat(...relatoriosCacique.map(r => ({label: r.label, value: r.value.index, role: r.role})));
		}
		this.setState({relatorios: relatorios.filter(r => UserData.hasRole(r.role))});
	}

	render() {
		return (
			<Combobox appendTo={document.body} label={this.props.label || "Relatório"} name={this.props.name || "relatorio"} grid={`ui-g-${this.props.grid || 12}`} options={this.state.relatorios} {...this.props}/>
		);
	}

}

import React, {Component} from "react";
import {VeiculoFabricanteService} from "../servicos/VeiculoFabricanteService";
import {Combobox} from "../commons/Combobox";

export class ComboboxFabricante extends Component {

    state = {
        fabricantes: []
    };

    veiculoFabricanteService = new VeiculoFabricanteService();

    componentDidMount() {
        this.veiculoFabricanteService.listar("", {sort: "nome", page: 0, size: 1000}).then(fabricantes => {
            this.setState({fabricantes: fabricantes.map(f => ({label: f.nome, value: f}))});
        });
    }

    render() {
        return (
            <Combobox {...this.props} options={this.state.fabricantes}/>
        );
    }

}

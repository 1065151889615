import moment from "moment";
import React from "react";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {ModalRealm} from "../commons/ModalRealm";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {TabletService} from "../servicos/TabletService";
import "./TabletPassivo.css";

const secondsPerMessage = 4;

export class TabletPassivo extends React.Component {

	state = {
		id: this.props.match.params.id,
		schema: this.props.match.params.schema,
		mensagens: [],
		anonimas: [],
		loop: 0,
		weather: {
			dummy: true
		},
		setupCondominio: {
			textos: []
		}
	};

	totemService = new TabletService();
	setupCondominioService = new SetupCondominioService();

	componentDidMount() {
		document.body.classList.add("ui-login-body");
		window.localStorage.setItem("safety-schema-name", this.state.schema);
		this.setupCondominioService.buscarTablet().then((setupCondominio) => {
			const textos = {};
			if (setupCondominio.textos) {
				setupCondominio.textos.forEach(t => {
					textos[t.finalidade] = t.texto;
				});
			}
			this.setState({setupCondominio, textos});
		});
	}

	UNSAFE_componentWillMount() {
		this.queueInterval = setInterval(this.buscarMensagensAnonimas, 300000);
		setTimeout(this.buscarMensagensAnonimas, 50);
		this.queueTimeout = setTimeout(this.swapMessage, 50);
	}

	buscarMensagensAnonimas = () => {
		this.totemService.anonimas(this.state.schema).then((criterio) => {
			let weather = criterio.weatherReport && criterio.weatherReport.report ? criterio.weatherReport : this.state.weather;
			if (criterio && criterio.mensagens && criterio.mensagens.length > 0) {
				this.setState({weather, anonimas: criterio.mensagens});
			} else {
				this.setState({weather});
			}
		});
	}

	swapMessage = () => {
		let hasMessage = 1000;
		if (this.state.mensagens.length > 0) {
			hasMessage = 1000 * secondsPerMessage;
			let mensagem = this.state.mensagens.shift();
			this.setState({mensagem, loop: 0});
		} else {
			this.callDefaultMessage();
		}
		this.queueTimeout = setTimeout(this.swapMessage, hasMessage);
	}

	componentWillUnmount() {
		clearTimeout(this.queueTimeout);
		clearInterval(this.queueInterval);
	}

	callDefaultMessage() {
		let mensagem;
		if (this.state.anonimas != null && this.state.anonimas.length > 0) {
			mensagem = this.state.anonimas[Math.floor(this.state.loop / (secondsPerMessage * 2)) % (this.state.anonimas.length + 1)];
		}
		if (!mensagem) {
			mensagem = {texto: "<default>"};
		}
		this.setState({mensagem, loop: this.state.loop + 1});
	}

	receberMensagens = (criterio) => {
		if (criterio && criterio.mensagens && criterio.mensagens.length) {
			let mensagens = this.state.mensagens;
			criterio.mensagens.forEach((mensagem) => {
				if (mensagem.texto.includes("@")) {
					const split = mensagem.texto.split("@");
					mensagem.texto = split[0];
					if (mensagem.nome !== split[1]) {
						mensagem.destinatario = `(Para ${split[1]})`;
					}
				}
			});
			mensagens.push(...criterio.mensagens);
			this.setState({mensagens});
		}
	}

	receberLogAcesso = (logAcesso) => {
		if (logAcesso.equipamento.id === Number(this.state.id)) {
			if (logAcesso.eventos) {
				if (logAcesso.eventos.includes("CREDENCIAL")) {
					const mensagens = this.state.mensagens;
					switch (logAcesso.forma) {
						case "SENHA":
							mensagens.push({fonte: "#FFFFFF", fundo: "#F44336", nome: "SENHA CANCELADA", texto: this.state.textos["TABLET_CREDENCIAL_INATIVA"]});
							break;
						default:
							mensagens.push({fonte: "#FFFFFF", fundo: "#F44336", nome: "CREDENCIAL CANCELADA", texto: this.state.textos["TABLET_CREDENCIAL_INATIVA"]});
							break;
					}
					this.setState({mensagens});
				}
			}
		}
	}

	render() {
		let content;
		if (this.state.mensagem != null) {
			if (this.state.mensagem.path != null) {
				content = <img src={this.state.mensagem.path} alt="" style={{width: "100%", height: "100%"}} />;
			} else {
				if (this.state.mensagem.texto === "<default>") {
					if (!this.state.weather.dummy) {
						content = <WeatherReport weather={this.state.weather} />;
					} else {
						content = (
							<div className={`ui-totem-textmessage`}>
								<div className="ui-totem-textmessage-content">
									{moment().format("DD/MM/YYYY HH:mm")}
								</div>
							</div>
						);
					}
				} else {
					content = (
						<div className={`ui-totem-textmessage${this.state.mensagem.nome != null ? " ui-totem-textpersonal" : ""}`}>
							{this.state.mensagem.nome != null ? <div style={
								this.state.mensagem.fundo != null && this.state.mensagem.fonte != null ? {backgroundColor: this.state.mensagem.fundo, color: this.state.mensagem.fonte} : null
							} className="ui-totem-textmessage-nome">{this.state.mensagem.nome}</div> : null}
							<div className="ui-totem-textmessage-content">
								{this.state.mensagem.texto}
								{this.state.mensagem.destinatario ? <div>{this.state.mensagem.destinatario}</div> : null}
							</div>
						</div>
					);
				}
			}
		}
		return (
			<div className="ui-totem-container">
				{content}
				<SafetyWebSocket onMessage={this.receberMensagens} path={`/mensagens`} params={{id: this.state.id}} />
				<SafetyWebSocket onMessage={this.receberLogAcesso} path={`/logs-acesso`} />
				<ModalRealm />
			</div>
		);
	}

}


export class WeatherReport extends React.Component {

	conditionIcon(condition) {
		switch (condition) {
			case "SUNNY": return "-sunny";
			case "PARTIALLY_CLOUDY": return "-cloudy";
			case "CLOUDY": return "-cloudy";
			case "PARTIALLY_RAINY": return "-showers";
			case "RAINY": return "-rain";
			case "STORMY": return "-storm-showers";
			case "WINDY": return "-windy";
			case "SNOWY": return "-snow";
			case "FOGGY": return "-fog";
			case "SLEETY": return "-sleet";
			case "LIGHTNINGY": return "-lightning";
			default: return "-cloudy";
		}
	}

	printDay(index) {
		return (
			<div className="ui-weather-days">
				<div><i className={`wi wi-day${this.conditionIcon(this.props.weather.forecast[index].condition)}`} /></div>
				<div>{moment(this.props.weather.forecast[index].date).format("DD/MM")}</div>
				<div style={{color: "#1976D2"}}>{Math.trunc(this.props.weather.forecast[index].minimum)}°C</div>
				<div style={{color: "#D32F2F"}}>{Math.trunc(this.props.weather.forecast[index].maximum)}°C</div>
			</div>
		);
	}

	render() {
		if (!this.props.weather?.report) {
			return null;
		}
		let hour = new Date().getHours();
		return (
			<div className="ui-weather">
				<div className="ui-weather-day">
					<div><i className={`wi wi-${hour >= 6 && hour < 18 ? "day" : "night"}${this.conditionIcon(this.props.weather.report.condition)}`} /></div>
					<div>{moment().format("DD/MM/YYYY HH:mm")}</div>
					<div>{Math.trunc(this.props.weather.report.temperature)}°C</div>
				</div>
				<div className="ui-weather-days-closure">
					{this.printDay(4)}
					{this.printDay(3)}
					{this.printDay(2)}
					{this.printDay(1)}
					{this.printDay(0)}
				</div>
			</div>
		);
	}

}

import React from "react";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";
import {LocalTime} from "../DateUtils";
import {simplificarDiasSemana} from "../../components/servicos/PeriodoService";

export class MModeloAcesso {

	static Modelo() {
		return {
			descricao: "",
			periodos: [],
			lugares: [],
			destinos: [],
			flags: [
				"DIRECAO_LIVRE"
			],
			equipamentos: [],
			documentosObrigatorios: []
		};
	}

	static Destino() {
		return {
			ausenciaMinima: 0,
			permanenciaMinima: 0,
			permanenciaMaxima: 0
		};
	}

	static Equipamento() {
		return {

		};
	}

	static DocumentoObrigatorio() {
		return {
			_key: Math.random(),
			obrigatoriedade: "TODOS",
			tipo: null
		};
	}

	static cleanupBeforeSave(modeloAcesso) {
		if (modeloAcesso.periodos != null) modeloAcesso.periodos = modeloAcesso.periodos.filter(p => Validator.isEntidade(p));
		if (modeloAcesso.lugares != null) modeloAcesso.lugares = modeloAcesso.lugares.filter(l => Validator.isEntidadeDependente(l));
		return MEntidade.cleanupBeforeSave(modeloAcesso);
	}

	static validarParaSalvar(modeloAcesso) {
		let messages = [];
		if (Validator.isEmpty(modeloAcesso.descricao)) messages.push("A descrição é obrigatória");
		if (!modeloAcesso.periodos?.length && !modeloAcesso.flags?.includes("HORARIO_LIVRE")) messages.push("É obrigatório haver ao menos um período ou o horário livre deve estar habilitado");
		if ((!modeloAcesso.lugares || modeloAcesso.lugares.length === 0) && (!modeloAcesso.equipamentos || modeloAcesso.equipamentos.length === 0)) messages.push("Deve haver ao menos um lugar ou equipamento adicionado");
		return messages;
	}

}

export function detalharModeloAcesso(ma) {
	return (
		<span>
			{ma.periodos?.length ? ma.periodos.map(h => `${simplificarDiasSemana(h)}, das ${LocalTime.withoutSeconds(h.inicio)} às ${LocalTime.withoutSeconds(h.fim)}`).join("; ") : ma.flags?.includes("HORARIO_LIVRE") ? "horário livre" : "sem horário definido"}
		</span>
	);
}

export const labelModeloAcessoFlag = {
	HORARIO_LIVRE: <img key="HORARIO_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/matrizes-horario.png")} alt="" title="Horário Livre"/>,
	CREDITO_LIVRE: <img key="CREDITO_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/tarifas.png")} alt="" title="Tarifação Livre"/>,
	DIRECAO_LIVRE: <img key="DIRECAO_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/direcoes.png")} alt="" title="Sentido Livre"/>,
	SAIDA_LIVRE: <img key="SAIDA_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/lancar-saida.png")} alt="" title="Saída Livre"/>,
	GARAGEM_LIVRE: <img key="GARAGEM_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/veiculos.png")} alt="" title="Garagem Livre"/>,
	SEQUENCIA_LIVRE: <img key="SEQUENCIA_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/sequencia-livre.png")} alt="" title="Sequência de Autenticação Livre"/>,
	ANTIDUPLA_LOGICO: <img key="ANTIDUPLA_LOGICO" className="icon-modelo-acesso" src={require("../../media/icones/avisos.png")} alt="" title="Anticarona Lógico"/>,
	ANTIDUPLA_FISICO: <img key="ANTIDUPLA_FISICO" className="icon-modelo-acesso" src={require("../../media/icones/bloqueios.png")} alt="" title="Anticarona Físico"/>,
	VALIDAR_DOCUMENTO: <img key="VALIDAR_DOCUMENTO" className="icon-modelo-acesso" src={require("../../media/icones/portaria.png")} alt="" title="Validar Documentos"/>,
	INTERVALO_LIVRE: <img key="INTERVALO_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/mantenedor.png")} alt="" title="Intervalo Livre"/>,
	AUTORIZACAO: <img key="AUTORIZACAO" className="icon-modelo-acesso" src={require("../../media/icones/autorizacoes.png")} alt="" title="Autorização de Acesso"/>,
	INTEGRACAO: <img key="INTEGRACAO" className="icon-modelo-acesso" src={require("../../media/icones/integracao.png")} alt="" title="Integração"/>,
	EPI_LIVRE: <img key="EPI_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/capacete.png")} alt="" title="Permitir Acesso sem EPIs"/>,
	TEMPERATURA_LIVRE: <img key="TEMPERATURA_LIVRE" className="icon-modelo-acesso" src={require("../../media/icones/temperatura-anormal.png")} alt="" title="Permitir Acesso com Temperatura Anormal"/>
};

export const labelTipoDocumentoObrigatoriedade = {
	"TODOS": "Todos",
	"PRINCIPAL": "Principal",
	"SECUNDARIOS": "Secundários"
};

import React, {Component} from "react";
import {VagaService} from "../servicos/VagaService";
import {Panel} from "primereact/components/panel/Panel";
import {PanelContent} from "../commons/PanelContent";
import {TextField} from "../commons/TextField";
import {PanelFooter} from "../commons/PanelFooter";
import {CheckButton} from "../commons/CheckButton";
import {Action} from "../commons/Action";
import {DataTable} from "primereact/components/datatable/DataTable";
import {GUI} from "../../utils/Constants";
import {ActionFloat} from "../commons/ActionFloat";
import {Shortcut} from "../commons/Shortcut";
import {ModalRealm} from "../commons/ModalRealm";
import {Column} from "primereact/components/column/Column";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeLancarSaida from "../../media/icones/lancar-saida.png";
import iconeVeiculo from "../../media/icones/veiculos.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {UserData} from "../../utils/UserData";
import {EditarVaga} from "./EditarVaga";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {LogVagaService} from "../servicos/LogVagaService";
import {Confirmacao} from "../commons/Confirmacao";
import moment from "moment";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import iconePessoaDentro from "../../media/icones/pessoa-dentro.png";
import iconePessoaFora from "../../media/icones/pessoa-fora.png";
import {VCard} from "../Portaria";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {isDentroOuGaragem} from "../../utils/PessoaFisicaUtils";

export class Vaga extends Component {

	state = {
		vagas: [],
		excluidos: false,
		descricao: "",
		titular: null,
		etiquetas: []
	};

	security = UserData.security("UNI", true);
	securityPessoa = UserData.security("PES", false);
	vagaService = new VagaService();
	logVagaService = new LogVagaService();

	listarVagas = () => {
		const params = [];
		const search = [];
		if (!this.state.excluidos) search.push("status:ATIVADO");
		if (this.state.descricao?.length) search.push(`descricao~${this.state.descricao}`);
		params.push(`search=${search.join(";")}`);
		if (this.state.titular?.id) params.push(`titulares=id:${this.state.titular.id}`);
		if (this.state.etiquetas?.length) this.state.etiquetas.forEach(e => params.push(`etiquetas=id:${e.id}`));
		params.push("sort=descricao");
		this.vagaService.listar(params.join("&"), {page: 0, size: GUI.maxResults}).then(vagas => {
			this.logVagaService.listarOcupadas(vagas).then(logsVaga => {
				for (const vaga of vagas) {
					vaga._logVaga = logsVaga.filter(lv => lv.vaga.id === vaga.id)[0];
				}
				this.setState({vagas});
			});
		});
	}

	criarVaga = () => this.editarVaga({descricao: "", lugar: null, titulares: [], etiquetas: []});

	editarVaga = vaga => ModalRealm.showDialog(<EditarVaga key={Math.random()} vaga={vaga} onModalClose={this.listarVagas}/>);

	excluirVaga = vaga => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta vaga?", () => this.vagaService.excluir(vaga).then(this.listarVagas));
	}

	restaurarVaga = vaga => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta vaga?", () => {
			if (vaga?.id) {
				this.vagaService.buscar(vaga).then(vaga => {
					vaga.status = "ATIVADO";
					this.vagaService.salvar(vaga).then(this.listarVagas);
				});
			}
		});
	}

	eliminarVaga = vaga => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta vaga?", () => this.vagaService.eliminar(vaga).then(result => {
			if (result) this.listarVagas();
			else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta vaga: há outros registros dependentes dela no sistema."]);
		}));
	}

	visualizarHistorico = vaga => EntidadeNextGenService.listarHistorico("/vagas", vaga);

	handleChange = event => this.setState({[event.name]: event.value});

	liberarVaga = vaga => {
		ModalRealm.showDialog(<Confirmacao message="Tem certeza de que deseja liberar esta vaga manualmente?" onYes={() => {
			const logVaga = vaga._logVaga;
			logVaga.status = "DESOCUPADA";
			logVaga.saida = moment().format("YYYY-MM-DDTHH:mm:ss");
			this.logVagaService.salvar(logVaga).then(this.listarVagas);
		}}/>);
	}

	editarPessoa = pessoa => {
		ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={pessoa} onModalClose={pessoa => {
			const {vagas} = this.state;
			vagas.forEach(v => {
				v.titulares.filter(t => t.id === pessoa.id).forEach(t => Object.assign(t, pessoa));
			});
			this.setState({vagas});
		}}/>);
	}

	render() {
		return (
			<div>
				<Panel header="Vagas">
					<PanelContent className="ui-g">
						<TextField grid={3} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleChange}/>
						<SelectPessoaFisica grid={3} label="Titular" name="titular" value={this.state.titular} onChange={this.handleChange}/>
						<MultiSelectEtiqueta grid={6} label="Etiquetas" name="etiquetas" value={this.state.etiquetas} onSelect={this.handleChange}/>
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleChange}/> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarVagas}/>
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator"/>
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.vagas} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage}>
						<Column style={{width: "4em", textAlign: "center"}} header="Status" body={v => <img title={v._logVaga ? "Ocupada" : "Livre"} src={iconeVeiculo} style={v._logVaga ? {} : {filter: "grayscale(1)", opacity: .5}} alt=""/>}/>
						<Column style={{width: "10em"}} header="Descrição" sortable field="descricao"/>
						<Column header="Titulares" body={(v, i) => (
							v.titulares.map(t => {
								const id = `${t.id}_${i.rowIndex}`;
								return (
									<div key={id} style={{position: "relative"}}>
										<div onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})} onMouseLeave={() => this.setState({vcard: 0})} className={`ui-portaria-item-pessoa visitante ${t.status === "ATIVADO" ? "" : "agenda-pessoa-desativada"}`}>
											<img className="ui-portaria-icone-posicao" src={isDentroOuGaragem(t) && v._logVaga?.pessoa?.id === t.id ? iconePessoaDentro : iconePessoaFora} alt=""/>
											{t.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}}/> </span> : null}
											{t.flags.includes("MASTER") ? <span><i title="Acesso Livre" className="fad fa-crown crown-color"/> </span> : null}
											{t.nascimento && t.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}}/> </span> : null}
											{t.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}} /> </span>)}
											<span>{t.nome}</span>
											<br/>
											<div className="ui-portaria-pessoa-acao-container">
												{this.securityPessoa.edit ? <img title="Editar Pessoa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarPessoa(t)}/> : null}
												{this.security.edit && v._logVaga?.pessoa?.id === t.id ? <img alt="" className="ui-portaria-pessoa-acao" src={iconeLancarSaida} title="Liberar Vaga" onClick={() => this.liberarVaga(v)}/> : null}
											</div>
										</div>
										<VCard pessoa={t} active={this.state.vcard === id} />
									</div>
								);
							}).concat([<div key={-3} className="ui-portaria-item-pessoa-end"/>])
						)}/>
						<Column header="Etiquetas" body={v => v.etiquetas?.map(e => <EtiquetaChip etiqueta={e}/>)}/>
						<Column style={{width: "15em"}} header="Lugar" body={v => v.lugar?.descricao}/>
						<Column header="Ações" style={{width: "7em"}} body={v => (
							<div style={{textAlign: "center"}}>
								{this.security.edit && v.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Vaga" onClick={() => this.editarVaga(v)}/> : null}
								{this.security.remove && v.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Vaga" onClick={() => this.excluirVaga(v)}/> : null}
								{this.security.remove && v.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(v)}/> : null}
								{this.security.remove && v.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Vaga" onClick={() => this.restaurarVaga(v)}/> : null}
								{this.security.remove && v.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Vaga" onClick={() => this.eliminarVaga(v)}/> : null}
							</div>
						)}/>
					</DataTable>
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Nova Vaga" icon="fa-plus" onClick={this.criarVaga}/> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarVagas}
						onCtrlN={() => window.location.pathname === "/vagas" && this.security.create && this.criarVaga()}
						onCtrlE={() => window.location.pathname === "/vagas" && this.state.vagas.length === 1 && this.security.edit && this.editarVaga(this.state.vagas[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CameraService} from "../servicos/CameraService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarCamera} from "./EditarCamera";
import {MCamera} from "../../utils/models/MCamera";

export class Camera extends React.Component {

	state = {
		cameras: [],
		descricao: "",
		firstResult: 0
	};

	cameraService = new CameraService();
	security = UserData.security("CAM", true);

	editarCamera = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.cameraService.buscar(rowData).then((camera) => {
				ModalRealm.showDialog(<EditarCamera key={Math.random()} camera={camera} onModalClose={this.listarCameras} />);
			});
		} else {
			ModalRealm.showDialog(<EditarCamera key={Math.random()} camera={null} onModalClose={this.listarCameras} />);
		}
	}

	restaurarCamera = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta câmera?", () => {
			if (rowData != null && rowData.id != null) {
				this.cameraService.buscar(rowData).then((camera) => {
					camera.status = "ATIVADO";
					this.cameraService.salvar(camera).then(this.listarCameras);
				});
			}
		});
	}

	eliminarCamera = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta câmera?", () => this.cameraService.eliminar(rowData).then((result) => {
			if (result) this.listarCameras(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta câmera: há outros registros dependentes dela no sistema."]);
		}));
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id"/>,
		<Column sortable key="descricao" field="descricao" header="Descrição" style={{width: "12em"}}/>,
		<Column sortable key="ip" field="ip" header="Endereço" style={{width: "*"}} />,
		<Column key="formato" field="formato" body={c => MCamera.MapFormatos[c.formato]} header="Formato" style={{width: "12em"}}/>,
		<Column key="controlador" field="controlador" header="Controlador" style={{width: "20em"}} body={c => c.controlador?.descricao}/>,
		<Column key="acoes" field="acoes" header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Câmera" onClick={(event) => this.editarCamera(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Câmera" onClick={(event) => this.excluirCamera(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Câmera" onClick={(event) => this.restaurarCamera(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Câmera" onClick={(event) => this.eliminarCamera(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/cameras", entidade);
	}

	listarCameras = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.cameraService.listar(`search=descricao,ip~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((cameras) => this.setState({cameras, firstResult: 0}));
		}, 50);
	}

	excluirCamera = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta câmera?", () => this.cameraService.excluir(rowData).then(() => this.listarCameras()));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Câmeras">
					<PanelContent className="ui-g">
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarCameras} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.cameras} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Nova Câmera" icon="fa-plus" onClick={this.editarCamera} /> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarCameras}
						onCtrlN={() => window.location.pathname === "/cameras" && this.security.create && this.editarCamera()}
						onCtrlE={() => window.location.pathname === "/cameras" && this.state.cameras.length === 1 && this.security.edit && this.editarCamera(this.state.cameras[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}

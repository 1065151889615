import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MCredencial} from "../../utils/models/MCredencial";
import {NumberUtils} from "../../utils/NumberUtils";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {MultiCombobox} from "../commons/MultiCombobox";
import {TextField} from "../commons/TextField";
import {CredencialService} from "../servicos/CredencialService";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {ModalRealm} from "../commons/ModalRealm";

export class CadastrarCartao extends React.Component {

    state = {
        visible: true,
        flags: [],
        lockSave: false,
        pessoa: null,
        opcoes: [
            "INVERTER"
        ],
        descricao: "",
        codigo: null
    };

    credencialService = new CredencialService();

    componentDidMount() {
        this.setState({opcoes: JSON.parse(window.localStorage.getItem("Options-CadastrarCartao") || '["INVERTER"]')});
    }

    handleChange = (event) => {
        this.setState({[event.name]: event.value});
        if (event.name === "opcoes") {
            window.localStorage.setItem("Options-CadastrarCartao", JSON.stringify(event.value));
        }
    }

    handleAutoEnroll = (event) => {
        if (event.keyCode === 13 && !this.state.lockSave) {
            const {descricao, codigo, flags, pessoa} = this.state;
            const credencial = {
                ...MCredencial.Modelo(),
                descricao,
                codigo: this.state.opcoes.includes("INVERTER") ? NumberUtils.toLittleEndian(codigo) : codigo,
                flags,
                forma: "PROXIMIDADE",
                pessoa
            };
            this.setState({lockSave: true});
            if (this.state.opcoes.includes("NOMEAR_PELO_TITULAR")) {
                if (!credencial.pessoa) {
                    ModalRealm.showInformacao("warn", ["Nenhum titular selecionado"]);
                    return;
                } else {
                    credencial.descricao = `${credencial.codigo.toString(16).toUpperCase()} - ${credencial.pessoa.nome}`;
                }
            }
            this.credencialService.salvar(credencial).then(() => {
                this.setState({lockSave: false});
                if (this.state.opcoes.includes("CONTINUO")) {
                    const prefixo = this.state.descricao.match(/[^0-9]/g).join("");
                    const olderso =  this.state.descricao.match(/[0-9]/g).join("");
                    let   posfixo = String(Number(olderso) + 1).padStart(olderso.length, "0");
                    this.setState({codigo: "", pessoa: null, descricao: `${prefixo}${posfixo}`});
                } else {
                    this.setState({codigo: "", pessoa: null, descricao: ""});
                    ModalRealm.showInformacao("info", ["Cartão cadastrado com sucesso!"]);
                }
            }).catch(() => this.setState({lockSave: false}));
        }
    }

    opcoes = [
        {label: "Contínuo", value: "CONTINUO"},
        {label: "Nomear pelo titular", value: "NOMEAR_PELO_TITULAR"},
        {label: "Inverter bytes", value: "INVERTER"}
    ];

    render() {
        return (
            <Dialog modal header="Cadastrar Cartões" style={{width: "750px"}} visible={this.state.visible} onHide={this.onClose}>
                <DialogContent>
                    <TextField disabled={this.state.opcoes.includes("NOMEAR_PELO_TITULAR")} name="descricao" label="Descrição" grid={8} value={this.state.opcoes.includes("NOMEAR_PELO_TITULAR") ? "" : this.state.descricao} onChange={this.handleChange}/>
                    <MultiCombobox appendTo={document.body} grid={4} name="flags" label="Opções" defaultLabel="Nenhuma" onChange={this.handleChange} value={this.state.flags} options={MCredencial.Flags}/>
                    <SelectPessoaFisica appendTo={document.body} grid={12} label="Titular" value={this.state.pessoa} name="pessoa" onChange={this.handleChange}/>
                    <TextField label="Código" grid={6} value={this.state.codigo} name="codigo" onKeyUp={this.handleAutoEnroll} onChange={this.handleChange}/>
                    <MultiCombobox defaultLabel="Nenhuma" appendTo={document.body} grid={6} options={this.opcoes} label="Opções de Cadastramento" name="opcoes" value={this.state.opcoes} onChange={this.handleChange}/>
                </DialogContent>
                <DialogFooter>
                    <Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
                </DialogFooter>
            </Dialog>
        );
    }

    onClose = () => this.setState({visible: false});

}

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarEquipamento} from "../components/Equipamento/EditarEquipamento";
import {EquipamentoService} from "../components/servicos/EquipamentoService";
import {MEquipamento} from "../utils/models/MEquipamento";
import {UserData} from "../utils/UserData";
import {MultiSelect} from "./MultiSelect";

export class MultiSelectEquipamento extends React.Component {

	state = {
		equipamentos: [],
		modelo: MEquipamento.Modelo()
	};

	security = UserData.security("ETQ");

	service = new EquipamentoService();

	onEdit = (equipamento) => {
		if (this.security.edit) {
			this.service.buscar(equipamento).then((entidade) => {
				ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={entidade} onModalClose={(equipamento) => {
					let equipamentos = this.props.value;
					let index = equipamentos.map(e => e.id).indexOf(equipamento.id);
					equipamentos[index] = equipamento;
					this.props.onSelect({name: this.props.name, value: equipamentos});
				}} />);
			});
		}
	}

	onSelect = (event) => {
		let equipamentos = this.props.value || [];
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={{...MEquipamento.Modelo(), descricao: event.value.descricao.replace(`Criar equipamento `, "")}} onModalClose={(equipamento) => {
				equipamentos.push(equipamento);
				this.props.onSelect({name: this.props.name, value: equipamentos});
			}} />);
		} else {
			if (!equipamentos.map(e => e.id).includes(event.value.id)) {
				equipamentos.push(event.value);
			}
			this.props.onSelect({name: this.props.name, value: equipamentos});
		}
	}

	onChange = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect(event);
		}
	}

	render() {
		return <MultiSelect onChange={this.onChange} canInsert={this.security.create} entidade="equipamento" service={this.service} modelo={this.state.modelo} onEdit={this.onEdit} field="descricao" query="descricao" {...this.props} onSelect={this.onSelect} />;
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MTarifaConta {

	static Modelo() {
		return {
			status: "ATIVADO",
			pessoa: null,
			modalidade: "PREPAGO",
			limite: 0
		};
	}

	static cleanupBeforeSave(tarifaConta) {
		return MEntidade.cleanupBeforeSave(tarifaConta);
	}

	static validarParaSalvar(tarifaConta) {
		let messages = [];
		if (Validator.isEmpty(tarifaConta.modalidade)) messages.push("A modalidade é obrigatória");
		if (!Validator.isEntidade(tarifaConta.pessoa)) messages.push("A pessoa é obrigatória");
		return messages;
	}

	static MapModalidade = {
		"PREPAGO": "Pré-pago",
		"POSPAGO": "Pós-pago"
	}

	static Modalidades = [
		{label: "Pré-pago", value: "PREPAGO"},
		{label: "Pós-pago", value: "POSPAGO"}
	];

	static Status = [
		{label: "Ativa", value: "ATIVADO"},
		{label: "Suspensa", value: "PENDENTE"},
		{label: "Desativada", value: "EXCLUIDO"}
	];

}

import {httpGet, httpPost} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class PortariaService extends EntidadeNextGenService {

	constructor() {
		super("/setup-portaria");
	}

	listarEntidades(query, offset) {
		return httpGet(`/portaria?${query}&page=${offset.page}&size=${offset.size}${offset.sort != null ? `&sort=${offset.sort}` : ""}`).then((response) => response);
	}

	async lancarAcesso(criterio) {
		if (criterio.pessoa) {
			return await httpPost("/portaria/acesso", criterio).then((comando) => comando);
		}
		return await Promise.resolve();
	}

	async dadosComplementares(criterio) {
		return await httpPost("/portaria/dados-complementares", criterio).then((result) => result);
	}

	existeAcesso(criterio) {
		return httpPost("/portaria/liberacao", criterio).then((result) => result);
	}

	async existeComboPermanente(pessoa) {
		return await httpGet(`/portaria/liberacao-permanente/${pessoa}`).then((result) => result);
	}

}

import React from "react";

export class HelpInicio extends React.Component {

	render() {
		return (
			<div>
				<p>
					Bem-vindo ao Safety 4! Este guia interno tem por objetivo auxiliá-lo com a compreensão dos diversos recursos presentes no sistema.
					Este manual está em constante aprimoramento (assim como o próprio Safety), então sempre que houver novas dúvidas, volte a conferi-lo.
				</p>
				<p>
					Cada tela deve ter um manual correspondente, que explicará detalhadamente cada campo e sua funcionalidade, além de como preenchê-lo.
					Também estarão disponíveis as dúvidas mais frequentes dos usuários (FAQs).
				</p>
				<p>
					Quando este painel de ajuda é aberto (você pode acioná-lo com a tecla F1), ele deve acompanhar a tela atualmente selecionada,
					mas você pode navegar entre os diferentes itens de ajuda no painel guia (logo à esquerda) a qualquer momento.
				</p>
			</div>
		);
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MPessoaFisica {

	static Modelo() {
		return {
			nome: "",
			cpf: "",
			rg: "",
			passaporte: "",
			telefone: "",
			celular: "",
			email: "",
			enderecos: [],
			etiquetas: [],
			gestor: null
		};
	}

	static Flags = [
		{label: "Aceita e-mails", value: "EMAIL"},
		{label: "Administrador", value: "ADMINISTRADOR"},
		{label: "Registra ponto", value: "PONTO"}
	];

	static CNHCategorias = [
		{label: "-", value: null},
		{label: "A", value: "A"},
		{label: "B", value: "B"},
		{label: "C", value: "C"},
		{label: "D", value: "D"},
		{label: "E", value: "E"},
		{label: "AB", value: "AB"},
		{label: "AC", value: "AC"},
		{label: "AD", value: "AD"},
		{label: "AE", value: "AE"}
	];

	static validarParaSalvar(pessoa, obrigatorios) {
		let messages = [];
		if (Validator.isEmpty(pessoa.nome)) messages.push("O nome é obrigatório");
		if (Validator.isEmpty(pessoa.nis) && Validator.isEmpty(pessoa.cnh) && Validator.isEmpty(pessoa.cpf) && Validator.isEmpty(pessoa.rg) && Validator.isEmpty(pessoa.passaporte)) messages.push("É obrigatório preencher ao menos um documento");
		pessoa.enderecos.forEach(endereco => {
			if (Validator.isEmpty(endereco.logradouro)) messages.push("O logradouro do endereço é obrigatório");
			if (Validator.isEmpty(endereco.numero)) messages.push("O número do endereço é obrigatório");
			if (Validator.isEmpty(endereco.localidade.nome)) messages.push("O nome da localidade do endereço é obrigatório");
			if (Validator.isEmpty(endereco.localidade.uf)) messages.push("A UF da localidade do endereço é obrigatório");
		});
		if (!Validator.isEmpty(pessoa.email) && !Validator.isEmail(pessoa.email)) messages.push("O e-mail preenchido é inválido");
		if (!Validator.isEmail(pessoa.email) && pessoa.flags && pessoa.flags.includes("PORTARIA_EMAIL")) messages.push("O e-mail precisa ser preenchido para pessoas que aceitam e-mails");
		if (!Validator.isEmpty(pessoa.cpf) && !Validator.isCPF(pessoa.cpf)) messages.push("O CPF preenchido é inválido");
		if (!Validator.isEmpty(pessoa.nis)) {
			if (!Validator.isNIS(pessoa.nis)) {
				messages.push("O NIS preenchido é inválido");
			}
		} else if (pessoa.etiquetas && pessoa.etiquetas.some(e => e.flags && e.flags.includes("PONTO"))) {
			messages.push("Para cadastros com ponto é necessário preencher o NIS.");
		}
		if (pessoa.id && pessoa.gestor?.id === pessoa.id) {
			messages.push(`${pessoa.nome} não pode ser gestor de si mesmo(a).`)
		}
		if (pessoa.rg && pessoa.rg.length && !Number(pessoa.rg.replace(/[^0-9]/g, ""))) messages.push("O RG preenchido é inválido");
		MPessoaFisica.validarObrigatorios(messages, obrigatorios, pessoa);
		return messages;
	}

	static validarObrigatorios(messages, obrigatorios, pessoa, printName = false) {
		if (obrigatorios) {
			obrigatorios.forEach((obrigatorio) => {
				switch (obrigatorio) {
					case "RG":
						if (Validator.isEmpty(pessoa.rg)) messages.push((printName ? pessoa.nome + " - " : "") + "O RG é obrigatório");
						break;
					case "CPF":
						if (Validator.isEmpty(pessoa.cpf)) messages.push((printName ? pessoa.nome + " - " : "") + "O CPF é obrigatório");
						break;
					case "TELEFONE":
						if (Validator.isEmpty(pessoa.telefone) && Validator.isEmpty(pessoa.celular)) messages.push((printName ? pessoa.nome + " - " : "") + "O telefone é obrigatório");
						break;
					case "EMPRESA":
						if (!Validator.isEntidade(pessoa.empresa)) messages.push((printName ? pessoa.nome + " - " : "") + "A empresa é obrigatória");
						break;
					case "NIS":
						if (Validator.isEmpty(pessoa.nis)) messages.push((printName ? pessoa.nome + " - " : "") + "O NIS é obrigatório");
						break;
					case "EMAIL":
						if (Validator.isEmpty(pessoa.email)) messages.push((printName ? pessoa.nome + " - " : "") + "O email é obrigatório");
						break;
					case "NASCIMENTO":
						if (Validator.isEmpty(pessoa.nascimento)) messages.push((printName ? pessoa.nome + " - " : "") + "A data de nascimento é obrigatória");
						break;
					case "FOTO":
						if (!pessoa.fotos?.length || !pessoa.fotos.some(f => f.finalidade === "IDENTIFICACAO")) messages.push((printName ? pessoa.nome + " - " : "") + "A foto do cadastro é obrigatória");
						break;
					default:
						break;
				}
			});
		}
	}

	static cleanupBeforeEdit(pessoa) {
		if (!Validator.isEmpty(pessoa.cpf)) pessoa.cpf = pessoa.cpf.formatCpf();
		if (pessoa.enderecos != null && pessoa.enderecos.length > 0) {
			pessoa.enderecos.forEach(endereco => {
				if (!endereco.localidade) {
					endereco.localidade = {};
				}
			});
		}
		return pessoa;
	}

	static cleanupBeforeSave(pessoa) {
		if (pessoa.etiquetas != null) pessoa.etiquetas = pessoa.etiquetas.filter(e => Validator.isEntidade(e));
		if (pessoa.empresa != null && !Validator.isEntidade(pessoa.empresa)) pessoa.empresa = null;
		pessoa.enderecos = pessoa.enderecos.filter(e => !Validator.isEmpty(e.logradouro) || !Validator.isEmpty(e.numero) || !Validator.isEmpty(e.localidade.nome));
		return MEntidade.cleanupBeforeSave(pessoa);
	}

}

import {AutoComplete} from "primereact/components/autocomplete/AutoComplete";
import React from "react";
import {Validator} from "../../utils/Validator";
import {Label} from "./Label";

export class SuggestionBox extends React.Component {

	select = (event) => {
		if (this.props.onSelect) this.props.onSelect({...event, name: this.props.name, field: this.props.field, index: this.props.index});
	}

	change = (event) => {
		if (this.props.onChange) this.props.onChange({...event, name: this.props.name, field: this.props.field, index: this.props.index});
	}

	clear = (event) => {
		if (this.props.onClear) this.props.onClear({...event, name: this.props.name, field: this.props.field, index: this.props.index});
	}

	templateTokens = (token) => {
		if (this.props.tokenTemplate) {
			return this.props.tokenTemplate(token);
		} else {
			return <SuggestionChip key={Math.random()} value={token} label={this.props.field} onRemove={(token) => {
				this.change(this.props.value != null ? {value: this.props.value.filter(i => i !== token)} : []);
			}} />;
		}
	}

	inputRef = (input) => {
		if (input != null) this.input = input.inputEl;
	}

	componentDidMount() {
		if (this.props.autofocus && this.input != null) this.input.focus();
	}

	isValidate = () => {
		return (this.props.validate === undefined || this.props.validate === true) && this.props.value && this.props.value.toString().length;
	}

	render() {
		return (
			<div className={`suggestionbox-container ui-g-${this.props.grid}`}>
				<Label help={this.props.help}>
					{this.props.label}
					{this.props.rightLabel ? <i style={{float: "right", fontSize: ".9em"}}>{this.props.rightLabel}</i> : null}
				</Label>
				<div className="ui-tzm-autocomplete-container" onClick={() => this.input && this.input.focus()}>
					<AutoComplete inputClassName={(this.isValidate() && typeof this.props.value === "string") || (this.props.required && !Validator.isEntidade(this.props.value)) ? "ui-field-required" : ""} ref={this.inputRef} {...this.props} onSelect={this.select} onChange={this.change} onClear={this.clear} />
					{this.props.value != null && this.props.multiple ? <div className="ui-tzm-autocomplete-chip-container">{this.props.value.map(this.templateTokens)}</div> : null}
					{this.props.onEdit && this.props.value?.id && (!this.props.disabled || this.props.editable) ? <i className="suggestion-editor fa fa-edit" onClick={() => this.props.onEdit(this.props.value)}/> : null}
				</div>
			</div>
		);
	}

}

export class SuggestionChip extends React.Component {

	onRemove = (event) => {
		event.stopPropagation();
		if (this.props.onRemove) {
			this.props.onRemove(this.props.value);
		}
	}

	onClick = () => {
		if (this.props.onClick) { 
			this.props.onClick(this.props.value);
		}
	}

	render() {
		return (
			<div onClick={this.props.disabled ? null : this.onClick} className={`ui-tzm-autocomplete-chip${this.props.className ? ` ${this.props.className}` : ""}`} style={this.props.style}>
				<div className="ui-tzm-autocomplete-chip-content">
					{this.props.value[this.props.label]}
				</div>
				{this.props.disabled ? null : <i onClick={this.onRemove} className="fa fa-times"/>}
			</div>
		);
	}

}

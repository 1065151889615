import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import audioAlarm from "../../media/audio/alert.ogg";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeJustificar from "../../media/icones/justificar.png";
import iconeLancarSaida from "../../media/icones/lancar-saida.png";
import iconeEmergencia from "../../media/icones/ocorrencia_emergencia.png";
import iconeMaster from "../../media/icones/ocorrencia_master.png";
import iconePresenca from "../../media/icones/ocorrencia_presenca.png";
import {StringUtils} from "../../utils/StringUtils";
import {UserData} from "../../utils/UserData";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EditarOcorrencia} from "../Ocorrencia/EditarOcorrencia";
import {OcorrenciaService} from "../servicos/OcorrenciaService";
import {PortariaService} from "../servicos/PortariaService";
import {ModalRealm} from "./ModalRealm";
import {MOcorrencia} from "../../utils/models/MOcorrencia";

export class PainelOcorrencia extends React.Component {

	state = {
		ocorrencias: []
	};

	security = UserData.security("OCO");
	portariaService = new PortariaService();
	ocorrenciaService = new OcorrenciaService();

	componentDidMount() {
		this.listarOcorrencias();
	}

	listarOcorrencias = () => {
		this.soundAlarm.pause();
		this.ocorrenciaService.listar(`search=justificativa:null;evento!AUTORIZACAO;evento!TREINAMENTO`, {page: 0, size: 10, sort: "id desc"}).then(ocorrencias => {
			if (this.security.edit && ocorrencias.length > 0 && ocorrencias.some(o => o.evento === "EMERGENCIA")) this.soundAlarm.play();
			this.setState({ocorrencias});
		});
	}

	detalhesOcorrencias = [
		<Column style={{width: "3.5em", textAlign: "center"}} key={0} body={(ocorrencia) => {
			switch (ocorrencia.evento) {
				case "ARROMBAMENTO":
				case "EMERGENCIA":
				case "TEMPERATURA":
				case "BLOQUEADO":
					return <img className="ui-ocorrencia-icon ui-tzm-blink" src={iconeEmergencia} alt="" />;
				case "MASTER":
				case "PORTA_ABERTA":
				case "ANTIDUPLA":
				case "CREDENCIAL":
				case "TREINAMENTO":
				case "BLOQUEADO_INTERJORNADA":
				case "BLOQUEADO_INTERVALO":
					return <img className="ui-ocorrencia-icon" src={iconeMaster} alt="" />;
				case "POSICAO":
				case "PRESENCA":
					return <img className="ui-ocorrencia-icon" src={iconePresenca} alt="" />;
				default:
					return null;
			}
		}} />,
		<Column style={{width: "*"}} key={1} header="Mensagem" body={(ocorrencia) => {
			switch (ocorrencia.evento) {
				case "ARROMBAMENTO":
					return `O equipamento ${ocorrencia.equipamento.descricao} foi aberto sem autenticação`;
				case "EMERGENCIA":
					if (ocorrencia.pessoa) {
						return `${StringUtils.simplificarNome(ocorrencia.pessoa.nome)} acionou o modo de emergência em ${ocorrencia.equipamento.descricao}`;
					} else {
						return `Acionaram o modo de emergência em ${ocorrencia.equipamento.descricao}`;
					}
				case "MASTER":
					if (ocorrencia.pessoa) {
						return `${ocorrencia.pessoa.nome} utilizou uma credencial mestre (${ocorrencia.credencial.descricao}) em ${ocorrencia.equipamento.descricao}`;
					} else {
						return `Foi utilizada uma credencial mestre (${ocorrencia.credencial.descricao}) em ${ocorrencia.equipamento.descricao}`;
					}
				case "ANTIDUPLA":
					if (ocorrencia.pessoa) {
						return `Suspeita de passagem de carona por ${ocorrencia.pessoa.nome} em ${ocorrencia.equipamento.descricao}`;
					} else {
						return `Suspeita de passagem de carona em ${ocorrencia.equipamento.descricao}`;
					}
				case "PORTA_ABERTA":
					if (ocorrencia.pessoa) {
						return `O equipamento ${ocorrencia.equipamento.descricao} foi deixado aberto por ${ocorrencia.pessoa.nome}`;
					} else {
						return `O equipamento ${ocorrencia.equipamento.descricao} foi deixado aberto`;
					}
				case "PRESENCA":
					if (ocorrencia.pessoa) {
						return `Identificou-se a presença de ${ocorrencia.pessoa.nome} fora do horário permitido${ocorrencia.lugar ? ` em ${ocorrencia.lugar.descricao}` : ""}`;
					} else {
						return `Identificou-se a presença de pessoas fora do horário permitido${ocorrencia.lugar ? ` em ${ocorrencia.lugar.descricao}` : ""}`;
					}
				case "POSICAO":
					if (ocorrencia.pessoa) {
						return `O posicionamento de ${ocorrencia.pessoa.nome} estava incorreto e foi ajustado automaticamente pelo sistema`;
					} else {
						return `O posicionamento de uma pessoa estava incorreto e foi ajustado automaticamente pelo sistema`;
					}
				case "CREDENCIAL":
					if (ocorrencia.pessoa) {
						return `${ocorrencia.pessoa.nome} tentou utilizar a credencial inativa ${ocorrencia.credencial.descricao} em ${ocorrencia.equipamento.descricao}`;
					} else {
						return `Tentaram utilizar a credencial inativa ${ocorrencia.credencial.descricao} em ${ocorrencia.equipamento.descricao}`;
					}
				case "PESSOA_SEM_EPI":
					if (ocorrencia.pessoa) {
						return `${ocorrencia.pessoa.nome} esteve próxima ao equipamento ${ocorrencia.equipamento.descricao} sem os EPIs necessários`;
					} else {
						return `Presença identificada sem os EPIs necessários próximo ao equipamento ${ocorrencia.equipamento.descricao}`;
					}
				case "BLOQUEADO":
				case "BLOQUEADO_INTERJORNADA":
				case "BLOQUEADO_INTERVALO":
					return `${ocorrencia.pessoa.nome} tentou acesso enquanto estava com ${MOcorrencia.MapEventos[ocorrencia.evento]} ativo.`;
				case "ATENDIMENTO":
				case "TREINAMENTO":
					return `O modo de emergência foi habilitado. Pessoas com credencial terão acesso livre mesmo sem agenda.`;
				case "TEMPERATURA":
					if (ocorrencia.pessoa) {
						return <span>{`Foi identificado no equipamento ${ocorrencia.equipamento.descricao} que ${ocorrencia.pessoa.nome} está com a temperatura anormal`}</span>;
					} else {
						return <span>Foi identificada uma pessoa com temperatura acima do normal no ambiente. {ocorrencia.fotos.map(f => <i style={{float: "right", margin: "4px 0 0 6px"}} title={`Clique para abrir a foto`} className="ui-tzm-logacesso-fotos fa fa-camera" onClick={() => window.open(f.path, "_blank")} />)}</span>;
					}
				default:
				return "";
			}
		}} />,
		<Column header="Etiquetas" style={{textAlign: "center", width: "15em"}} body={(ocorrencia) => {
			return ocorrencia.etiquetas ? ocorrencia.etiquetas.map((e) => <EtiquetaChip etiqueta={e} />) : null;
		}} />,
		<Column header="Data" style={{textAlign: "center", width: "10em"}} body={(ocorrencia) => {
			return moment(ocorrencia.data).format("DD/MM/YYYY HH:mm");
		}} />,
		<Column style={{textAlign: "center", width: "5em"}} key={2} header="Ações" body={(ocorrencia) => {
			return (
				<div>
					{this.security.edit && ocorrencia.evento !== "TREINAMENTO" && UserData.operador.pessoa && this.props.equipamento && ocorrencia.evento === "PRESENCA" ? <img className="ui-action-icon" alt="" src={iconeLancarSaida} title="Lançar Saída Manual" onClick={() => this.lancarSaida(ocorrencia)} /> : null}
					{this.security.edit && ocorrencia.evento !== "TREINAMENTO" && UserData.operador.pessoa ? <img alt="" className="ui-action-icon" src={ocorrencia.evento === "MASTER" ? iconeJustificar : iconeExcluir} title="Dispensar" onClick={() => this.dispensarOcorrencia(ocorrencia)} /> : null}
				</div>
			);
		}} />
	];

	lancarSaida = (ocorrencia) => {
		ModalRealm.showConfirmacao(`Tem certeza de que deseja lançar a saída manual de ${ocorrencia.pessoa.nome}?`, () => {
			let criterio = {};
			criterio.pessoa = ocorrencia.pessoa;
			criterio.destino = null;
			criterio.etiquetas = ocorrencia.etiquetas;
			criterio.equipamento = this.props.equipamento;
			this.portariaService.lancarAcesso(criterio).then(() => {
				if (this.props.onLancarSaida) {
					this.props.onLancarSaida(criterio);
				}
				ocorrencia.justificativa = `Saída manual lançada por ${UserData.operador.pessoa.nome}.`;
				this.ocorrenciaService.salvar(ocorrencia).then(() => this.listarOcorrencias);
			});
		});
	}

	dispensarOcorrencia = (ocorrencia) => {
		ModalRealm.showDialog(<EditarOcorrencia justificar ocorrencia={ocorrencia} onModalClose={(ocorrencia) => {
			let ocorrencias = this.state.ocorrencias;
			ocorrencias = ocorrencias.filter(o => o.id !== ocorrencia.id);
			if (this.soundAlarm) if (this.security.edit && ocorrencias.length > 0 && ocorrencias.some(o => o.evento === "EMERGENCIA")) this.soundAlarm.play(); else this.soundAlarm.pause();
			this.setState({ocorrencias});
		}} />);
	}

	ignorarOcorrencias = [
		"AUTORIZACAO",
		"TREINAMENTO"
	];

	receberOcorrencia = (ocorrencia) => {
		if (ocorrencia.id === 0) {
			const {ocorrencias} = this.state;
			ocorrencias.length = 0;
			this.setState({ocorrencias});
		} else {
			if (!this.ignorarOcorrencias.includes(ocorrencia.evento)) {
				let ocorrencias = this.state.ocorrencias;
				if (!ocorrencia.justificativa) {
					ocorrencias.unshift(ocorrencia);
				} else {
					ocorrencias = ocorrencias.filter(o => o.id !== ocorrencia.id);
				}
				if (this.soundAlarm) if (this.security.edit && ocorrencias.length > 0 && ocorrencias.some(o => o.evento === "EMERGENCIA")) this.soundAlarm.play(); else this.soundAlarm.pause();
				ocorrencias.length = Math.min(ocorrencias.length, 10);
				this.setState({ocorrencias});
				if (this.props.onUpdate) {
					this.props.onUpdate(ocorrencia);
				}
			}
		}
	}

	refAlarm = (el) => this.soundAlarm = el;

	render() {
		return (
			<div style={{display: this.state.ocorrencias.length > 0 && this.security.remove ? "block" : "none"}}>
				<audio ref={this.refAlarm} loop>
					<source src={audioAlarm} type="audio/ogg" />
				</audio>
				<Panel header={
					<div className="ui-ocorrencia-panel-header">
						Ocorrências
						{this.security.edit && UserData.operador.pessoa && this.state.ocorrencias && this.state.ocorrencias.length ? <span className="link-ocorrencias" onClick={() => this.dispensarTudo()}>Dispensar Todas</span> : null}
					</div>
				} className="ui-ocorrencia-panel">
					<DataTable value={this.state.ocorrencias} children={this.detalhesOcorrencias} />
					<SafetyWebSocket path="/logs-acesso" onMessage={this.receberLogAcesso} />
					<SafetyWebSocket path="/ocorrencias" onMessage={this.receberOcorrencia} />
				</Panel>
				<div className="ui-tzm-panel-separator" />
			</div>
		);
	}

	dispensarTudo = (index) => {
		if (index === undefined) {
			ModalRealm.showConfirmacao("Tem certeza de que deseja dispensar todas as ocorrências?", () => this.dispensarTudo(0));
		} else {
			this.ocorrenciaService.dispensar().then(this.listarOcorrencias);
		}
	}

	receberLogAcesso = (logAcesso) => {
        if (logAcesso.pessoa && logAcesso.atividade === "PASSAGEM") {
            let ocorrencias = this.state.ocorrencias.filter(o => o.evento !== "PRESENCA" || o.pessoa.id !== logAcesso.pessoa.id);
            this.setState({ocorrencias});
        }
    }

}

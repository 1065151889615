import {httpGet} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class ReservaService extends EntidadeNextGenService {

	constructor() {
		super("/reservas");
	}

	countByPessoa(pessoa) {
		return httpGet(`/reservas/by-pessoa/${pessoa}`);
	}

}

import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TextField} from "../commons/TextField";
import {DialogFooter} from "../commons/DialogFooter";
import {DialogContent} from "../commons/DialogContent";
import {Action} from "../commons/Action";
import {ModalRealm} from "../commons/ModalRealm";
import {Fetch} from "../../utils/Fetch";

const basePath = process.env.REACT_APP_API_URL;

export class SudoUser extends Component {

	state = {
		visible: true,
		key: Math.random(),
		login: "",
		senha: ""
	};

	render() {
		return (
			<Dialog modal visible={this.state.visible} key={this.state.key} header="Autenticação" style={{width: "450px"}} onHide={this.handleClose}>
				<DialogContent>
					<div>
						A operação que você deseja executar requer permissões elevadas.<br />
						Por gentileza, forneça credenciais de um usuário com habilitação para:
						<ul>{this.props.permissoes.map(p => <li>{p}</li>)}</ul>
					</div>
					<TextField grid={12} label="Login" name="login" value={this.state.login} onChange={this.handleChange} />
					<TextField grid={12} label="Senha" type="password" name="senha" value={this.state.senha} onChange={this.handleChange} />
				</DialogContent>
				<DialogFooter>
					<Action label="Confirmar" onClick={this.handleConfirm} />
				</DialogFooter>
			</Dialog>
		);
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	handleClose = () => {
		this.setState({visible: false});
	}

	handleConfirm = () => {
		const {login, senha} = this.state;
		fetch(`${basePath}/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({login, senha})
		}).then((response) => {
			if (response.status === 200) {
				if (this.props.onSuccess) {
					const Authorization = response.headers.get("Authorization");
					fetch(`${basePath}/me`, {
						method: "GET",
						headers: {Authorization, "Content-Type": "application/json"}
					}).then((response) => {
						response.json().then((usuario) => {
							Fetch.Get(`${basePath}/operadores?usuario=id:${usuario.id}&page=0&size=1`).then((response) => {
								response.json().then((operadores) => {
									if (operadores && operadores.length) {
										const operador = operadores[0];
										if (operador.perfis && operador.perfis.some(p => p.roles.some(r => r.nome === this.props.role.nome && r[this.props.role.chave]))) {
											this.props.onSuccess({...usuario, operador});
											this.handleClose();
										} else {
											console.log({...usuario, operador});
											ModalRealm.showInformacao("warn", ["O usuário não tem as permissões necessárias para executar esta ação."]);
										}
									}
								});
							});
						});
					});
				}
			} else {
				ModalRealm.showInformacao("warn", ["Autenticação falhou. Revise as credenciais e tente novamente."]);
			}
		});
	}

}

import React from "react";
import {UserData} from "../utils/UserData";
import {MultiSelect} from "./MultiSelect";
import {SchemaService} from "../components/servicos/SchemaService";

export class MultiSelectSchema extends React.Component {

	state = {
		etiquetas: [],
		modelo: {
			descricao: "",
			nome: ""
		}
	};

	security = UserData.security("SCH");

	service = new SchemaService();

	onSelect = event => {
		let schemas = this.props.value || [];
		if (!schemas.map(e => e.id).includes(event.value.id)) {
			schemas.push(event.value);
		}
		this.props.onSelect({name: this.props.name, value: schemas});
	}

	onChange = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect(event);
		}
	}

	render() {
		return <MultiSelect insertable={false} label={this.props.label || "Schemas"} onChange={this.onChange} entidade="schema" service={this.service} modelo={this.state.modelo} field="descricao" query="descricao" {...this.props} onSelect={this.onSelect}/>;
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {EditarPessoaJuridica} from "./EditarPessoaJuridica";

export class PessoaJuridica extends React.Component {

	state = {
		nome: "",
		fantasia: "",
		telefone: "",
		cnpj: "",
		endereco: "",
		etiquetas: [],
		firstResult: 0
	};

	etiquetaService = new EtiquetaService();
	pessoaJuridicaService = new PessoaJuridicaService();
	security = UserData.security("EMP", true);

	listarPessoas = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let aParams = [];
			if (!this.state.excluidos) aParams.push("status:ATIVADO");
			if (this.state.nome.length > 0) aParams.push(`nome~${this.state.nome}`);
			if (this.state.fantasia.length > 0) aParams.push(`fantasia~${this.state.fantasia}`);
			if (this.state.cnpj.length > 0) aParams.push(`cnpj~${this.state.cnpj}`);
			if (this.state.telefone.length > 0) aParams.push(`telefone~${this.state.telefone}`);
			let params = aParams.join(";");
			if (this.state.endereco.length > 0) params += `&enderecos=logradouro~${this.state.endereco}`;
			if (this.state.etiquetas.length > 0) params += `&etiquetas=id:${this.state.etiquetas.map((e) => e.id).join(",")}`;
			this.pessoaJuridicaService.listar(`search=${params}`, {page: 0, size: GUI.maxResults, sort: "nome"}).then((pessoas) => this.setState({pessoas, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarPessoa = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.pessoaJuridicaService.buscar(rowData).then((pessoa) => {
				ModalRealm.showDialog(<EditarPessoaJuridica pessoa={pessoa} onModalClose={(pessoa) => {
					this.listarPessoas();
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarPessoaJuridica pessoa={null} onModalClose={(pessoa) => {
				this.listarPessoas();
			}} />);
		}
	}

	excluirPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta empresa?", () => this.pessoaJuridicaService.excluir(rowData).then(this.listarPessoas));
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page: 0, size: 10, sort: "descricao"}).then((etiquetas) => this.setState({etiquetas}));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column header="Razão Social"  style={{width: "*"}} sortable field="nome" />,
		<Column header="Nome Fantasia" style={{width: "*"}} sortable field="fantasia" />,
		<Column header="CNPJ" style={{width: "13em"}} body={(pessoa) => pessoa.cnpj ? pessoa.cnpj.formatCnpj() : ""} />,
		<Column header="Telefone" style={{width: "9em"}} sortable field="telefone" />,
		<Column header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Empresa" onClick={(event) => this.editarPessoa(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Empresa" onClick={(event) => this.excluirPessoa(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Empresa" onClick={(event) => this.restaurarPessoa(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Empresa" onClick={(event) => this.eliminarPessoa(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/pessoas-juridicas", entidade);
	}

	restaurarPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta empresa?", () => {
			if (rowData != null && rowData.id != null) {
				this.pessoaJuridicaService.buscar(rowData).then((pessoa) => {
					pessoa.status = "ATIVADO";
					this.pessoaJuridicaService.salvar(pessoa).then(this.listarPessoas);
				});
			}
		});
	}

	eliminarPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta empresa?", () => this.pessoaJuridicaService.eliminar(rowData).then((result) => {
			if (result) this.listarPessoas(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta empresa: há outros registros dependentes dela no sistema."]);
		}));
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Empresas">
					<PanelContent>
						<TextField grid={5} label="Razão Social" autofocus name="nome" value={this.state.nome} onChange={this.handleUpdate} />
						<TextField grid={4} label="Nome Fantasia" name="fantasia" value={this.state.fantasia} onChange={this.handleUpdate} />
						<TextField grid={3} label="CNPJ" name="cnpj" value={this.state.cnpj} onChange={this.handleUpdate} />
						<TextField grid={3} label="Telefone" name="telefone" value={this.state.telefone} onChange={this.handleUpdate} />
						<MultiSelectEtiqueta appendTo={null} insertable={false} grid={9} label="Etiquetas" name="etiquetas" value={this.state.etiquetas} onSelect={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarPessoas} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.pessoas} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Pessoa" icon="fa-plus" onClick={this.editarPessoa} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarPessoas}
					onCtrlN={() => window.location.pathname === "/pessoas-juridicas" && this.security.create && this.editarPessoa()}
					onCtrlE={() => window.location.pathname === "/pessoas-juridicas" && this.state.pessoas.length === 1 && this.security.edit && this.editarPessoa(this.state.pessoas[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

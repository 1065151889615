import moment from "moment";
import preval from "preval.macro";
import {Growl} from "primereact/components/growl/Growl";
import React from "react";
import {withRouter} from "react-router-dom";
import "./App.css";
import {handleLogout, UserContext} from "./Application";
import {SafetyHelp} from "./components/commons/SafetyHelp";
import {Shortcut} from "./components/commons/Shortcut";
import {NeoMainBar, NeoMainBarButton, NeoSidebar, NeoSidebarCard, NeoSidebarItem} from "./components/NeoMainBar";
import {Progressbar} from "./components/Progressbar";
import Root from "./components/Root";
import {OperadorService} from "./components/servicos/OperadorService";
import {UsuarioService} from "./components/servicos/UsuarioService";
import soundAlert from "./media/audio/monitoramento.ogg";
import iconeAgendas from "./media/icones/agendas.png";
import iconeAnimais from "./media/icones/animais.png";
import iconeAvisos from "./media/icones/avisos.png";
import iconeBloqueios from "./media/icones/bloqueios.png";
import iconeCameras from "./media/icones/cameras.png";
import iconeVagas from "./media/icones/vagas.png";
import iconeComunicados from "./media/icones/comunicados.png";
import iconeTarifaContas from "./media/icones/contas-credito.png";
import iconeConfiguracoes from "./media/icones/dispositivo.png";
import iconeGruposTrabalho from "./media/icones/grupos-trabalho.png";
import iconePainelControle from "./media/icones/painel-controle.png";
import iconeContratosTrabalho from "./media/icones/contratos-trabalho.png";
import iconeControladores from "./media/icones/controladores.png";
import iconeModelosAgenda from "./media/icones/modelos-agenda.png";
import iconeCredenciais from "./media/icones/credenciais.png";
import iconeEmpresas from "./media/icones/empresas.png";
import iconeEncomendas from "./media/icones/encomendas.png";
import iconeEquipamentos from "./media/icones/equipamentos.png";
import iconeEspacos from "./media/icones/espacos.png";
import iconeEtiquetas from "./media/icones/etiquetas.png";
import iconeFeriados from "./media/icones/feriados.png";
import iconeAutorizacoes from "./media/icones/autorizacoes.png";
import iconeFuncionarios from "./media/icones/funcionarios.png";
import iconeGuarita from "./media/icones/guarita.png";
import iconeHome from "./media/icones/home.png";
import iconeLogsAcesso from "./media/icones/logs-acesso.png";
import iconeLugares from "./media/icones/lugares.png";
import iconeManutencoes from "./media/icones/manutencoes.png";
import iconeMatrizesHorario from "./media/icones/matrizes-horario.png";
import iconeMensagens from "./media/icones/mensagens.png";
import iconeMessengerBots from "./media/icones/messenger-bots.png";
import iconeFormularios from "./media/icones/formularios.png";
import iconeModeloAcesso from "./media/icones/modelos-acesso.png";
import iconeMonitorPresenca from "./media/icones/monitor-presenca.png";
import iconeMonitor from "./media/icones/monitor.png";
import iconeMonitoramentos from "./media/icones/monitoramentos.png";
import iconeOcorrencias from "./media/icones/ocorrencias.png";
import iconePerfis from "./media/icones/perfis.png";
import iconePessoas from "./media/icones/pessoas.png";
import iconePortaria from "./media/icones/portaria.png";
import iconePreAgendamentos from "./media/icones/pre-agendamentos.png";
import iconeRelatorios from "./media/icones/relatorios.png";
import iconeReservas from "./media/icones/reservas.png";
import iconeUnidades from "./media/icones/unidades.png";
import iconeUsuarios from "./media/icones/usuarios.png";
import iconeVeiculos from "./media/icones/veiculos.png";
import logoTzm from "./media/tzm.svg";
import "./Patterns.css";
import {MLogAcesso} from "./utils/models/MLogAcesso";
import {MOcorrencia} from "./utils/models/MOcorrencia";
import {UserData} from "./utils/UserData";
import {SafetyWebSocket} from "./utils/WebSocket";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "./utils/StringUtils";
import {UrgenteTrocaSenha} from "./components/Usuario/UrgenteTrocaSenha";
import {ModalRealm} from "./components/commons/ModalRealm";

const BUILD_VERSION = preval`
	const moment = require('moment');
	module.exports = moment().format('YYMMDD-HHmm');
`;

const BUILD_YEAR = preval`
	const moment = require('moment');
	module.exports = moment().format('YYYY');
`;

export const PageContext = React.createContext({
	sidebar: 0,
	title: "Sistema de Segurança",
	onPage: (event = {name: "", value: ""}) => {
		console.log("Você precisa implementar o onPage");
	}
});

export const PageProvider = PageContext.Provider;
export const PageConsumer = PageContext.Consumer;

export const withPage = (Tag) => (props) => {
	return (
		<PageConsumer>
			{(page) => <Tag {...props} {...page} />}
		</PageConsumer>
	);
}

export const Page = withRouter(class extends React.Component {

	loadBackground() {
		let mainbarStyle = {};
		let bgColor = window.localStorage.getItem("safety-neomainbar-bgcolor");
		if (bgColor != null) {
			mainbarStyle.bgColor = bgColor;
		} else {
			window.localStorage.setItem("safety-neomainbar-bgcolor", "#B71C1C");
			mainbarStyle.bgcolor = "#B71C1C";
		}
		let bgPattern = window.localStorage.getItem("safety-neomainbar-bgpattern");
		if (bgPattern != null) {
			mainbarStyle.bgPattern = Number(bgPattern);
		} else {
			window.localStorage.setItem("safety-neomainbar-bgpattern", "0");
			mainbarStyle.bgPattern = 0;
		}
		let bgEffect = window.localStorage.getItem("safety-neomainbar-bgeffect");
		if (bgEffect != null) {
			mainbarStyle.bgEffect = Number(bgEffect);
		} else {
			window.localStorage.setItem("safety-neomainbar-bgeffect", "0");
			mainbarStyle.bgEffect = 0;
		}
		return mainbarStyle;
	}

	state = {
		sidebar: 0,
		usuario: {},
		authorities: [],
		operador: {},
		title: "Sistema de Segurança",
		mainbarStyle: this.loadBackground()
	};

	usuarioService = new UsuarioService();
	operadorService = new OperadorService();

	UNSAFE_componentWillMount() {
		let sidebar = window.localStorage.getItem("safety-neomainbar-sidebar") === "true";
		this.setState({sidebar});
	}

	toggleSidebar = () => {
		window.localStorage.setItem("safety-neomainbar-sidebar", String(this.state.sidebar === 1 ? 0 : 1));
		this.setState({sidebar: this.state.sidebar === 1 ? 0 : 1, helpActive: false});
	}

	selectDestination = (event) => {
		this.props.history.push(event.location);
	}

	refGrowl = (el) => this.growl = el;
	refGrowlMonitoramento = (el) => this.growlMonitoramento = el;

	receberFrontend = (message) => {
		switch (message.comando) {
			case "REFRESH":
				window.location.reload(true);
				break;
			case "LOGOUT":
				handleLogout();
				break;
			case "MESSAGE":
				this.growl.show({life: 30000, severity: message.tipo.toLowerCase(), summary: "Atenção", detail: unescape(message.mensagem)});
				break;
			default:
		}
	}

	toggleHelp = () => {
		this.setState({helpActive: !this.state.helpActive});
	}

	componentDidMount() {
		var usuario = UserData.usuario;
		console.log(usuario);
		if (usuario?.ultimaTrocaSenha) {
			const expiracao = moment(usuario.ultimaTrocaSenha).add(usuario.diasTrocaSenha, "days");
			console.log(expiracao);
			if (expiracao.add(Math.round(usuario.diasTrocaSenha / 10) * -1, "days").isBefore(moment())) {
				ModalRealm.showDialog(<UrgenteTrocaSenha usuario={usuario}/>);
			}
		}
	}

	render() {
		return <UserContext.Consumer children={this.renderUser}/>;
	}

	receberMonitoramento = (monitoramento) => {
		if (UserData.operador.pessoa && monitoramento.monitores && monitoramento.monitores.map(p => p.id).includes(UserData.operador.pessoa.id)) {
			let detail = (
				<div className="ui-growl-monitoramento">
					<div className="ui-g">
						<div className="ui-g-2">
							<img src={monitoramento.logAcesso && monitoramento.logAcesso.pessoa ? toSrc(monitoramento.logAcesso.pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc)[0], true) : null} alt="" />
						</div>
						<div className="ui-g-10">
							<div>{monitoramento.logAcesso.pessoa.nome}</div>
							<div>{`${MLogAcesso.MapAtividades[monitoramento.logAcesso.atividade]} em ${monitoramento.logAcesso.equipamento.descricao} às ${moment(monitoramento.logAcesso.data).format("DD/MM/YYYY HH:mm:ss")}`}</div>
							<div>{monitoramento.logAcesso.eventos && monitoramento.logAcesso.eventos.length ? monitoramento.logAcesso.eventos.map(e => MOcorrencia.MapEventos[e]).join(", ") : null}</div>
						</div>
					</div>
				</div>
			);
			this.growlMonitoramento.show({severity: "info", sticky: true, summary: "Atenção", detail});
			this.soundAlert.play();
		}
	}

	refAlert = (el) => this.soundAlert = el;

	onPage = (event) => this.setState({[event.name]: event.value});

	renderUser = (operador) => {
		return (
			<PageProvider value={{sidebar: this.state.sidebar, onPage: this.onPage}}>
				<div className="page-container">
					<Root sidebar={this.state.sidebar}>{this.props.children}</Root>
					<NeoMainBar padrao={this.state.mainbarStyle.bgPattern} efeito={this.state.mainbarStyle.bgEffect} style={{backgroundColor: this.state.mainbarStyle.bgColor}}>
						<NeoMainBarButton onClick={this.toggleSidebar}/>
						<img src={logoTzm} alt="" className="ui-tzm-logo"/>
						<div className="ui-neomainbar-label">SAFETY</div>
						{
							this.state.sidebar !== -1 ? (
								<NeoSidebar scrollColor={this.state.mainbarStyle.bgColor} expanded={this.state.sidebar === 1}>
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_USER")} location="/" icon={iconeHome} label="Início" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_PORV")} shortcut={["F4"]} location="/portaria" icon={iconePortaria} label="Portaria" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_GUAV")} shortcut={["F9"]} location="/guarita" icon={iconeGuarita} label="Guarita" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MONV")} location="/monitor" icon={iconeMonitor} label="Monitor de Equipamentos" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_UNIV")} shortcut={["F10"]} location="/unidades" icon={iconeUnidades} label="Unidades" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_UNIV")} location="/vagas" icon={iconeVagas} label="Vagas" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_RELV")} shortcut={["Ctrl", "F10"]} location="/relatorios" icon={iconeRelatorios} label="Relatórios" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_SCHV")} shortcut={["F3"]} location="/agendas" icon={iconeAgendas} label="Agendas" />
									<NeoSidebarItem enabled={operador.pessoa && operador.authorities.includes("ROLE_AACV")} location="/autorizacoes-acesso" icon={iconeAutorizacoes} label="Autorizações de Acesso" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_ENCV")} shortcut={["Ctrl", "F12"]} location="/encomendas" icon={iconeEncomendas} label="Encomendas" />
									<NeoSidebarItem enabled={operador.etiquetas.length && operador.authorities.includes("ROLE_AGNV")} shortcut={["Ctrl", "F11"]} location="/pre-agendamentos" icon={iconePreAgendamentos} label="Pré-Agendamentos" />
									<NeoSidebarItem enabled={operador.etiquetas.length && operador.authorities.includes("ROLE_AGNV")} location="/pre-agendamentos-fixos" icon={iconeFuncionarios} label="Prestação de Serviços" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_AVIV")} location="/avisos" icon={iconeAvisos} label="Avisos" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_BLKV")} shortcut={["Ctrl", "F3"]} location="/bloqueios" icon={iconeBloqueios} label="Bloqueios" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_PESV")} shortcut={["F2"]} location="/pessoas-fisicas" icon={iconePessoas} label="Pessoas" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_CRDV")} shortcut={["F6"]} location="/credenciais" icon={iconeCredenciais} label="Credenciais" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_EMPV")} shortcut={["Ctrl", "F2"]} location="/pessoas-juridicas" icon={iconeEmpresas} label="Empresas" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_VEIV")} location="/veiculos" icon={iconeVeiculos} label="Veículos" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MACV")} shortcut={["Ctrl", "F6"]} location="/modelos-acesso" icon={iconeModeloAcesso} label="Modelos de Acesso" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_EQPV")} shortcut={["Ctrl", "Shift", "F11"]} location="/equipamentos" icon={iconeEquipamentos} label="Equipamentos" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_XX1V")} location="/painel-controle-acesso" icon={iconePainelControle} label="Painel de Controle de Acesso" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_ETQV")} shortcut={["Ctrl", "F9"]} location="/etiquetas" icon={iconeEtiquetas} label="Etiquetas" />
									<NeoSidebarItem enabled={operador.pessoa && operador.authorities.includes("ROLE_RESV")} location="/reservas" icon={iconeReservas} label="Reservas" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_ESPV")} location="/espacos" icon={iconeEspacos} label="Espaços" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MSGV")} location="/mensagens" icon={iconeMensagens} label="Mensagens" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_ANIV")} location="/animais" icon={iconeAnimais} label="Animais de Estimação" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_AVSV")} location="/avaliacao-servicos" icon={iconeAnimais} label="Avaliação de Serviço" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_CTBV")} location="/contratos-trabalho" icon={iconeContratosTrabalho} label="Contratos de Trabalho" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_GRTV")} location="/grupos-trabalho" icon={iconeGruposTrabalho} label="Grupos de Trabalho" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_JTBV")} location="/jornadas-trabalho" icon={iconeFuncionarios} label="Jornadas de Trabalho" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MTHV")} location="/matrizes-horario" icon={iconeMatrizesHorario} label="Matrizes de Horário" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_FERV")} location="/feriados" icon={iconeFeriados} label="Feriados" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_FRMV")} location="/formularios" icon={iconeFormularios} label="Formulários" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MBTV")} location="/bots-mensageiros" icon={iconeMessengerBots} label="Bots Mensageiros" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_CAMV")} location="/cameras" icon={iconeCameras} label="Câmeras" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_COMV")} location="/comunicados" icon={iconeComunicados} label="Comunicados" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_LUGV")} location="/lugares" icon={iconeLugares} label="Lugares" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MAGV")} location="/modelos-agenda" icon={iconeModelosAgenda} label="Modelos de Agenda" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_LGAV")} shortcut={["F7"]} location="/logs-acesso" icon={iconeLogsAcesso} label="Registros de Acesso" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_OCOV")} shortcut={["Ctrl", "F7"]} location="/ocorrencias" icon={iconeOcorrencias} label="Ocorrências" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_LPTV")} location="/logs-ponto" icon={iconeLogsAcesso} label="Registros de Ponto" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MONV")} location="/monitoramentos" icon={iconeMonitoramentos} label="Monitoramentos" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MNAV")} location="/monitor-presenca" icon={iconeMonitorPresenca} label="Monitor de Presença" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_MANV")} shortcut={["Ctrl", "Shift", "F2"]} location="/manutencao" icon={iconeManutencoes} label="Manutenção" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_TCTV")} location="/tarifa-contas" icon={iconeTarifaContas} label="Contas de Crédito" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_CTRV")} shortcut={["Ctrl", "Shift", "F3"]} location="/controladores" icon={iconeControladores} label="Controladores" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_USRV")} shortcut={["F8"]} location="/usuarios" icon={iconeUsuarios} label="Usuários" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_PRFV")} shortcut={["Ctrl", "F8"]} location="/perfis" icon={iconePerfis} label="Perfis de Usuário" />
									<NeoSidebarItem enabled={operador.authorities.includes("ROLE_CFGV")} shortcut={["Ctrl", "Shift", "F12"]} location="/configuracoes" icon={iconeConfiguracoes} label="Configurações" />
								</NeoSidebar>
							) : null
						}
						<NeoSidebarCard title={this.state.title} sidebar={this.state.sidebar} usuario={operador.usuario} operador={operador} doLogout={handleLogout} />
						<Progressbar />
					</NeoMainBar>
					<Shortcut
						master
						onF1={() => this.toggleHelp()}
						onCtrlF1={() => this.toggleSidebar()}
						onF2={() => this.selectDestination({location: "/pessoas-fisicas"})}
						onCtrlF2={() => this.selectDestination({location: "/pessoas-juridicas"})}
						onF3={() => this.selectDestination({location: "/agendas"})}
						onCtrlF3={() => this.selectDestination({location: "/bloqueios"})}
						onF4={() => this.selectDestination({location: "/portaria"})}
						onF6={() => this.selectDestination({location: "/credenciais"})}
						onCtrlF6={() => this.selectDestination({location: "/modelos-acesso"})}
						onF7={() => this.selectDestination({location: "/logs-acesso"})}
						onCtrlF7={() => this.selectDestination({location: "/ocorrencias"})}
						onF8={() => this.selectDestination({location: "/usuarios"})}
						onCtrlF8={() => this.selectDestination({location: "/perfis"})}
						onF9={() => this.selectDestination({location: "/guarita"})}
						onCtrlF9={() => this.selectDestination({location: "/etiquetas"})}
						onF10={() => this.selectDestination({location: "/unidades"})}
						onCtrlF10={() => this.selectDestination({location: "/relatorios"})}
						onCtrlF11={() => this.selectDestination({location: "/pre-agendamentos"})}
						onCtrlF12={() => this.selectDestination({location: "/encomendas"})}
						onShiftCtrlF2={() => this.selectDestination({location: "/manutencao"})}
						onShiftCtrlF3={() => this.selectDestination({location: "/controladores"})}
						onShiftCtrlF11={() => this.selectDestination({location: "/equipamentos"})}
						onShiftCtrlF12={() => this.selectDestination({location: "/configuracoes"})}
						onCtrlQ={handleLogout}
					/>
					<div className="ui-footer-copyright">TZM Safety 5.2.{BUILD_VERSION} <i className="fa fa-copyright" /> {BUILD_YEAR}, TZM Informática e Telecomunicações</div>
					<SafetyWebSocket path="/frontend" onMessage={this.receberFrontend} />
					<SafetyWebSocket path="/monitoramento" onMessage={this.receberMonitoramento} />
					{this.state.helpActive ? <SafetyHelp onHide={this.toggleHelp} /> : null}
					<Growl position="bottomright" ref={this.refGrowl} />
					<Growl position="bottomright" className="ui-growl-monitoramento-container" ref={this.refGrowlMonitoramento} />
					<audio ref={this.refAlert}>
						<source src={soundAlert} type="audio/ogg" />
					</audio>
				</div>
			</PageProvider>
		);
	}

});

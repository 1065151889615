import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MUsuario {

	static Modelo() {
		return {
			login: "",
			senha: "",
			timeout: -1,
			diasTrocaSenha: 90,
			schemas: []
		};
	}

	static cleanupBeforeSave(usuario) {
		if (usuario.schemas != null) usuario.schemas = usuario.schemas.filter(s => Validator.isEntidade(s));
		return MEntidade.cleanupBeforeSave(usuario);
	}

	static validarParaSalvar(usuario) {
		let messages = [];
		if (Validator.isEmpty(usuario.login)) messages.push("O login é obrigatório");
		if (!usuario.id && (Validator.isEmpty(usuario.senha1) || Validator.isEmpty(usuario.senha2))) messages.push("A senha é obrigatória");
		if (!usuario.id && usuario.senha1 !== usuario.senha2) messages.push("As senhas não conferem");
		if (usuario.id && (!Validator.isEmpty(usuario.senha1) || !Validator.isEmpty(usuario.senha2)) && usuario.senha1 !== usuario.senha2) {
			messages.push("As senhas não conferem");
		}
		if (!usuario.schemas?.length) {
			messages.push("Você precisa selecionar pelo menos um schema");
		}
		return messages;
	}

}

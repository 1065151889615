import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "./Action";
import {DialogContent} from "./DialogContent";
import {DialogFooter} from "./DialogFooter";
import {Shortcut} from "./Shortcut";

export class Confirmacao extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes();
		}
		this.onClose();
	}

	onNo = () => {
		if (this.props.onNo) {
			this.props.onNo();
		}
		this.onClose();
	}

	onClose = () => {
		this.setState({visible: false});
	}

	render() {
		return (
			<Dialog closable={false} modal header={this.props.header != null ? this.props.header : "Confirmação"} visible={this.state.visible} style={{minWidth: "300px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						<div className="ui-g-12">
							<i className="fa fa-question-circle" style={{color: "#2196F3"}} />&nbsp;
							{this.props.message}
						</div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action label={this.props.yes || "Sim"} icon="fa-check" onClick={this.onYes} />
					<Action mode="secondary" label={this.props.no || "Não"} icon="fa-times" onClick={this.onNo} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onS={this.onYes} onN={this.onNo} onEscape={this.onNo}/> : null}
			</Dialog>
		);
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {SelectEtiqueta} from "../../select/SelectEtiqueta";
import {GUI} from "../../utils/Constants";
import {LocalDateTime} from "../../utils/DateUtils";
import {toDate, toTime} from "../../utils/Format";
import {MAviso} from "../../utils/models/MAviso";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Combobox} from "../commons/Combobox";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {AvisoService} from "../servicos/AvisoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarAviso} from "./EditarAviso";

export class Aviso extends React.Component {

	state = {
		pessoa: "",
		status: "ATIVADO",
		etiquetas: [],
		inicio: "",
		fim: "",
		validade: 0,
		iniciov: "",
		fimv: "",
		firstResult: 0
	};

	avisoService = new AvisoService();
	security = UserData.security("AVI", true);

	listarAvisos = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let aParams = [];
			if (this.state.status != null) {
				aParams.push(`status:${this.state.status}`);
			} else {
				if (!this.state.excluidos) {
					aParams.push("status!EXCLUIDO");
				}
			}
			switch (this.state.validade) {
				case 1:
					aParams.push(`fim<${LocalDateTime.now()}`);
					break;
				case 2:
					aParams.push(`fim:null`);
					break;
				default:
			}
			let params = aParams.join(";");
			if (Validator.isEntidade(this.state.etiqueta)) params += `&etiqueta=id:${this.state.etiqueta.id}`;
			if (this.state.pessoa.length > 0) params += `&pessoa=nome,rg,cpf,passaporte~${this.state.pessoa}`;
			this.avisoService.listar(`search=${params}`, {sort: "id desc", page: 0, size: GUI.maxResults}).then((avisos) => this.setState({avisos, firstResult: 0}));
		}, 50);
	}

	editarAviso = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.avisoService.buscar(rowData).then((aviso) => {
				ModalRealm.showDialog(<EditarAviso key={Math.random()} aviso={aviso} onSave={this.listarAvisos} />);
			});
		} else {
			ModalRealm.showDialog(<EditarAviso key={Math.random()} aviso={null} onSave={this.listarAvisos} />);
		}
	}

	excluirAviso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este aviso?", () => this.avisoService.excluir(rowData).then(() => this.listarAvisos()));
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column style={{width: "4em"}} key="status" header="Status" field="status" body={MAviso.SwitchStatus} />,
		<Column style={{width: "8em"}} key="tipo" header="Tipo" field="tipo" body={(aviso) => MAviso.MapTipos[aviso.tipo]} />,
		<Column sortable style={{width: "12em"}} key="inicio" header="Início" field="inicio" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{rowData.inicio ? toDate(rowData.inicio) + " " + toTime(rowData.inicio) : "Indeterminado"}
				</div>
			);
		}} />,
		<Column sortable style={{width: "12em"}} key="fim" header="Fim" field="fim" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{rowData.fim ? toDate(rowData.fim) + " " + toTime(rowData.fim) : "Indeterminado"}
				</div>
			);
		}} />,
		<Column style={{width: "12em", textAlign: "center"}} key="etiqueta" header="Unidade" field="etiqueta" body={(rowData) => {
			if (rowData.etiqueta != null) {
				return <EtiquetaChip etiqueta={rowData.etiqueta} />;
			} else {
				return null;
			}
		}} />,
		<Column style={{width: "*"}} key="pessoa" header="Pessoa" field="pessoa" body={(rowData, column) => {
			return rowData.pessoa != null ? rowData.pessoa.nome : null;
		}} />,
		<Column style={{width: "7em"}} key="Ações" header="Ações" field="acoes" body= {(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Aviso" onClick={(event) => this.editarAviso(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Aviso" onClick={(event) => this.excluirAviso(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Aviso" onClick={(event) => this.restaurarAviso(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Aviso" onClick={(event) => this.eliminarAviso(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/avisos", entidade);
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	validades = [
		{label: "Todos", value: 0},
		{label: "Expirado", value: 1},
		{label: "Indeterminado", value: 2},
	];

	restaurarAviso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este aviso?", () => {
			if (rowData != null && rowData.id != null) {
				this.avisoService.buscar(rowData).then((aviso) => {
					aviso.status = "ATIVADO";
					this.avisoService.salvar(aviso).then(this.listarAvisos);
				});
			}
		});
	}

	eliminarAviso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este aviso?", () => this.avisoService.eliminar(rowData).then((result) => {
			if (result) this.listarAvisos(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este aviso: há outros registros dependentes dele no sistema."]);
		}));
	}

	status = [
		{label: "Todos", value: null},
		{label: "Ativado", value: "ATIVADO"},
		{label: "Cancelado", value: "CANCELADO"},
		{label: "Pendente", value: "PENDENTE"}
	];

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Avisos">
					<PanelContent>
						<Combobox grid={2} label="Status" name="status" value={this.state.status} options={this.status} onChange={this.handleChange} />
						<Combobox grid={2} label="Validade" name="validade" value={this.state.validade} options={this.validades} onChange={this.handleChange} />
						<TextField grid={4} label="Pessoa" autofocus placeholder="Nome, RG, CPF ou passaporte" name="pessoa" value={this.state.pessoa} onChange={this.handleChange} />
						<SelectEtiqueta appendTo={null} grid={4} label="Etiqueta" value={this.state.etiqueta} name="etiqueta" onChange={this.handleChange} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleChange} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarAvisos} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.avisos} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Aviso" icon="fa-plus" onClick={this.editarAviso} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarAvisos}
					onCtrlN={() => window.location.pathname === "/avisos" && this.security.create && this.editarAviso()}
					onCtrlE={() => window.location.pathname === "/avisos" && this.security.edit && this.state.avisos.length === 1 && this.editarAviso(this.state.avisos[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

import moment from "moment";
import React from "react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {GraficoService} from "../servicos/GraficoService";

export class GraficoAcessosHora extends React.Component {

	constructor() {
		super();
		this.state = {
			acessos: [],
			etiquetas: []
		};
		this.graficoService = new GraficoService();
	}

	UNSAFE_componentWillMount() {
		this.toggleDiario();
		this.queryTimeout = setTimeout(() => {
			this.updateData();
			this.queryInterval = setInterval(this.updateData, 60000);
		}, (60 - new Date().getSeconds()) * 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.queryTimeout);
		clearInterval(this.queryInterval);
	}

	updateData = () => {
		this.graficoService.acessosPorHora(this.state.dias).then((acessos) => {
			let itens = [];
			let etiquetas = [];
			for (let i = 0; i < 24; ++i) itens.push({xaxis: String(i)});
			acessos.forEach((acesso) => {
				if (!etiquetas.some(e => e.descricao === acesso.etiqueta)) {
					etiquetas.push({descricao: acesso.etiqueta, fundo: acesso.fundo});
					itens.forEach(item => {
						item[acesso.etiqueta] = 0;
					});
				}
				let item = itens.filter(i => i.xaxis === acesso.xaxis)[0];
				if (item) {
					item[acesso.etiqueta] = acesso.quantidade;
				}
			});
			let hour = new Date().getHours();
			itens = itens.sort((a, b) => a.xaxis - b.xaxis);
			if (hour < 23) {
				itens = itens.slice(hour, 24).concat(itens.slice(0, hour));
			}
			this.setState({acessos: itens, etiquetas: etiquetas.sort((a, b) => a.descricao.localeCompare(b.descricao))});
		});
	}

	render() {
		return (
			<div style={{height: "100%"}}>
				<div className="ui-start-chart-title"><span>Acessos por Hora do Dia</span></div>
				<ResponsiveContainer width="100%" height="100%">
					<LineChart data={this.state.acessos}>
						<XAxis dataKey="xaxis" />
						<YAxis />
						<Tooltip />
						<Legend />
						<CartesianGrid strokeDasharray="3 3" />
						{this.state.etiquetas.map(etiqueta => <Line key={etiqueta.descricao} isAnimationActive={false} type="linear" dataKey={etiqueta.descricao} stroke={etiqueta.fundo} />)}
					</LineChart>
				</ResponsiveContainer>
				<div className="grafico-toggle-period">
					<button onClick={this.toggleDiario}>Diário</button>
					<button onClick={this.toggleMensal}>Mensal</button>
					<button onClick={this.toggleAnual}>Anual</button>
				</div>
			</div>
		);
	}

	toggleDiario = () => {
		const dias = Math.abs(moment().add(-1, "days").diff(moment(), "days"));
		this.setState({dias});
		setTimeout(() => {
			this.updateData();
		}, 50);
	}

	toggleMensal = () => {
		const dias = Math.abs(moment().add(-1, "months").diff(moment(), "days"));
		this.setState({dias});
		setTimeout(() => {
			this.updateData();
		}, 50);
	}

	toggleAnual = () => {
		const dias = Math.abs(moment().add(-1, "years").diff(moment(), "days"));
		this.setState({dias});
		setTimeout(() => {
			this.updateData();
		}, 50);
	}

}

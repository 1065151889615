import {Dialog} from "primereact/components/dialog/Dialog";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {Slider} from "primereact/components/slider/Slider";
import React from "react";
import {MPeriodo} from "../../utils/models/MPeriodo";
import {Action} from "../commons/Action";
import {CheckSquare} from "../commons/CheckSquare";
import {Clock} from "../commons/Clock";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {PeriodoService} from "../servicos/PeriodoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {UserData} from "../../utils/UserData";
import {intAsTime, timeAsInt} from "../../utils/StringUtils";

export class EditarPeriodo extends React.Component {

	state = {
		periodo: this.fillNulls(this.props.periodo),
		visible: true,
		inicio: intAsTime(this.props.periodo.inicio),
		fim: intAsTime(this.props.periodo.fim)
	};

	periodoService = new PeriodoService();
	security = UserData.security("PER", false);

	fillNulls(periodo) {
		if (periodo == null) {
			periodo = MPeriodo.Modelo();
		}
		if (periodo.inicio && periodo.fim) {
			if (periodo.inicio) {
				periodo.inicio = timeAsInt(periodo.inicio);
			}
			if (periodo.fim) {
				periodo.fim = timeAsInt(periodo.fim);
			}
			periodo.range = [Math.floor((periodo.inicio === 1439 ? 1440 : periodo.inicio) / 60), Math.floor((periodo.fim === 1439 ? 1440 : periodo.fim) / 60)];
		} else {
			periodo.range = [0, 24];
			periodo.inicio = 0;
			periodo.fim = 1439;
		}
		return periodo;
	}

	savePeriodo = () => {
		let periodo = this.state.periodo;
		periodo.inicio = intAsTime(periodo.inicio);
		periodo.fim = intAsTime(periodo.fim);
		this.periodoService.salvar(periodo).then((periodo) => {
			if (this.props.onSave) {
				this.props.onSave(periodo);
			}
			if (this.props.onModalClose) {
				this.props.onModalClose(periodo);
			}
			this.onClose();
		});
	}

	onClose = () => {
		this.setState({visible: false});
	}

	diasSemana = [
		{label: "Domingo", value: "SUNDAY"},
		{label: "Segunda", value: "MONDAY"},
		{label: "Terça", value: "TUESDAY"},
		{label: "Quarta", value: "WEDNESDAY"},
		{label: "Quinta", value: "THURSDAY"},
		{label: "Sexta", value: "FRIDAY"},
		{label: "Sábado", value: "SATURDAY"}
	];

	updateTimer = (event) => {
		let periodo = this.state.periodo;
		periodo.range = event.value;
		periodo.inicio = event.value[0] * 60;
		periodo.fim = event.value[1] === 24 ? 1439 : event.value[1] * 60;
		this.setState({periodo});
		this.setState({inicio: intAsTime(periodo.inicio)});
		this.setState({fim: intAsTime(periodo.fim)});
	}

	alterTimeManually = event => {
		const {periodo} = this.state;
		if (event.value?.length === 5 && event.value?.indexOf(":") === 2) {
			periodo[event.name] = timeAsInt(event.value);
			periodo.range[event.name === "inicio" ? 0 : 1] = Math.round(periodo[event.name] / 60);
		}
		this.setState({[event.name]: event.value, periodo});
	}

	handleFeriados = () => {
		let periodo = this.state.periodo;
		periodo.feriados = !periodo.feriados;
		this.setState({periodo});
	}

	render() {
		return (
			<Dialog modal header="Período" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						<div style={{textAlign: "center"}} className="ui-g-6"><Clock time={this.state.inicio} /></div>
						<div style={{textAlign: "center"}} className="ui-g-6"><Clock time={this.state.fim} /></div>
						<div className="ui-g-12">
							<Label style={{marginBottom: "12px"}}>Intervalo</Label>
							<Slider range step={1} min={0} max={24} value={this.state.periodo.range} onChange={this.updateTimer} />
						</div>
						<div className="ui-g-12"/>
						<div className="ui-g-3"/>
						<TextField grid={3} name="inicio" onChange={this.alterTimeManually} value={this.state.inicio.formatTime()} />
						<TextField grid={3} name="fim" onChange={this.alterTimeManually} value={this.state.fim.formatTime()} />
						<div className="ui-g-3"/>
						<div className="ui-g-12"/>
						<div className="ui-g-10" style={{textAlign: "center"}}>
							<SelectButton multiple value={this.state.periodo.diasSemana} options={this.diasSemana} onChange={(event) => this.setState({periodo: {...this.state.periodo, diasSemana: event.value}})} />
						</div>
						<div className="ui-g-2">
							<CheckSquare name="feriados" onChange={this.handleFeriados} checked={this.state.periodo.feriados} label="Feriados" />
						</div>
						<div className="ui-g-12" style={{textAlign: "center"}}>
							{this.periodoService.simplificarDiasSemana({diasSemana: this.state.periodo.diasSemana})}
						</div>
					</div>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.periodo.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/periodos", this.state.periodo)} /> : null}
					<Action mode="post" label="Salvar" onClick={this.savePeriodo} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.savePeriodo} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import saveAs from "file-saver";
import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeBaixar from "../../media/icones/download.png";
import {SelectEquipamentoPonto} from "../../select/SelectEquipamentoPonto";
import {GUI} from "../../utils/Constants";
import {Fetch} from "../../utils/Fetch";
import {MLogPonto} from "../../utils/models/MLogPonto";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {GoogleMap} from "../commons/GoogleMap";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {TabPanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {LogPontoService} from "../servicos/LogPontoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {ActionFloat} from "../commons/ActionFloat";
import {EditarLogPonto} from "./EditarLogPonto";

const baseURL = process.env.REACT_APP_API_URL || "";

function statusOffline(offline) {
	let color = "#4CAF50";
	switch (offline) {
		case 0:
			break;
		case 1:
			color = "#FF9800";
			break;
		default:
			color = "#F44336";
			break;
	}
	return <span title={`Falhas consecutivas de conexão: ${offline}`} style={{cursor: "default", fontSize: "2em", lineHeight: ".8em", transform: "translateY(-1px)", color: color}}>&#9679;</span>;
}

export class LogPonto extends React.Component {

	state = {
		tipo: "MARCACAO",
		nis: "",
		serial: "",
		firstResult: 0,
		activeIndex: 0,
		equipamentos: [],
		lockAFD: [],
		marcacoes: true,
		portaria: ""
	};

	logPontoService = new LogPontoService();
	equipamentoService = new EquipamentoService();
	pessoaFisicaService = new PessoaFisicaService();
	security = UserData.security("LPT", true);

	listarLogsPonto = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let params = [];
			if (this.state.tipo) params.push(`tipo:${this.state.tipo}`);
			if (this.state.nis) params.push(`nis:${this.state.nis}`);
			if (this.state.inicio) params.push(`data>${moment(this.state.inicio).format("YYYY-MM-DDT00:00:00")}`);
			if (this.state.fim) params.push(`data<${moment(this.state.fim).format("YYYY-MM-DDT23:59:59")}`);
			if (Validator.isEntidade(this.state.equipamento)) params.push(`serial:${this.state.equipamento.serial}`);
			this.logPontoService.listar(`search=${params.join(";")}${this.state.excluidos ? "" : ";status:ATIVADO"}&sort=serial,nsr`, {page: 0, size: GUI.maxResults}).then((logsPonto) => {
				let nises = logsPonto.map(lp => lp.nis);
				nises = nises.filter((nis, i) => nises.indexOf(nis) === i);
				let serials = logsPonto.map(lp => lp.serial);
				serials = serials.filter((serial, i) => serials.indexOf(serial) === i);
				this.equipamentoService.listar(`fields=descricao,serial&search=serial:${serials.join(",")}`, {page: 0, size: GUI.maxResults}).then((equipamentos) => {
					this.logPontoService.listarPessoas(nises).then(pessoas => {
						for (const equipamento of equipamentos) {
							logsPonto.filter(lp => lp.serial === equipamento.serial).forEach(lp => lp._equipamento = equipamento.descricao);
						}
						for (const pessoa of pessoas) {
							logsPonto.filter(lp => lp.nis === pessoa.nis || lp.nis === pessoa.cpf).forEach(lp => lp._nome = pessoa.nome);
						}
						this.setState({logsPonto, firstResult: 0});
					});
				});
			});
		}, 50);
	}

	componentWillUnmount() {
		if (this.nsrInterval) {
			clearInterval(this.nsrInterval);
		}
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} header="Equipamento" style={{width: "*"}} sortable field="_equipamento" />,
		<Column key={1} header="NSR" style={{width: "6em"}} sortable field="nsr" />,
		<Column key={2} header="Tipo" style={{width: "8em"}} body={(e) => MLogPonto.MapTipos[e.tipo]} />,
		<Column key={3} header="Data" sortable field="data" style={{width: "10em"}} body={(e) => e.data ? moment(e.data).format("DD/MM/YYYY HH:mm") : ""} />,
		<Column key={4} header="NIS/CPF" style={{width: "10em"}} sortable field="nis" />,
		<Column key={5} header="Nome" style={{width: "*"}} sortable field="_nome" />,
		<Column key={6} header="Localização" style={{width: "6em", textAlign: "center"}} body={(e) => e.posicao ? <i style={{cursor: "pointer"}} className="fa fa-map" onClick={() => this.abrirGoogleMap(e.posicao)} /> : null} />
	];

	abrirGoogleMap = (location) => {
		ModalRealm.showDialog(<GoogleMap location={location} />);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	tabChange = (event) => {
		switch (event.index) {
			case 1:
				if (!this.state.equipamentos.length) {
					this.equipamentoService.listar(`search=status:ATIVADO;categoria:PONTO&sort=descricao`, {page: 0, size: 100}).then((equipamentos) => {
						this.setState({equipamentos}, () => {
							this.buscarUltimoNSR(equipamentos);
							this.nsrInterval = setInterval(() => this.buscarUltimoNSR(this.state.equipamentos), 60000);
						});
					});
				}
				break;
			default:
				break;
		}
		this.setState({activeIndex: event.index});
	}

	buscarUltimoNSR = (equipamentos, index = 0) => {
		if (index < equipamentos?.length) {
			this.logPontoService.listar(`fields=nsr,data&search=tipo:MARCACAO;serial:${equipamentos[index].serial}`, {sort: "nsr desc", page: 0, size: 1}).then(logsPonto => {
				if (logsPonto?.length) {
					equipamentos[index]._nsr = logsPonto[0].nsr;
					equipamentos[index]._data = logsPonto[0].data;
				} else {
					equipamentos[index]._nsr = 0;
					equipamentos[index]._data = 0;
				}
				this.buscarUltimoNSR(equipamentos, ++index);
			});
		} else {
			this.setState({equipamentos});
		}
	}

	render() {
		return (
			<div>
				<Panel header="Registros de Ponto">
					<TabView activeIndex={this.state.activeIndex} onTabChange={this.tabChange}>
						<TabPanel header="Registros Individuais">
							<PanelContent>
								<Combobox grid={2} options={MLogPonto.Tipos} label="Tipo" value={this.state.tipo} name="tipo" onChange={this.handleUpdate} />
								<DatePicker grid={2} label="Período" name="inicio" value={this.state.inicio} onChange={this.handleUpdate} />
								<DatePicker grid={2} label="&nbsp;" name="fim" value={this.state.fim} onChange={this.handleUpdate} />
								<TextField name="nis" value={this.state.nis} onChange={this.handleUpdate} grid={3} label="NIS/CPF" />
								<SelectEquipamentoPonto name="equipamento" value={this.state.equipamento} onChange={this.handleUpdate} label="Equipamento" grid={3} />
							</PanelContent>
							<TabPanelFooter>
								<Action label="Procurar" icon="fa-search" onClick={this.listarLogsPonto} />
							</TabPanelFooter>
						</TabPanel>
						<TabPanel header="AFDs">
							<PanelContent>
								<DatePicker grid={2} label="Período" name="inicio" value={this.state.inicio} onChange={this.handleUpdate} />
								<DatePicker grid={2} label="&nbsp;" name="fim" value={this.state.fim} onChange={this.handleUpdate} />
								<Combobox grid={2} name="portaria" options={MLogPonto.Portarias} label="Portaria" value={this.state.portaria} onChange={this.handleUpdate}/>
							</PanelContent>
						</TabPanel>
					</TabView>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				{this.selectedTable()}
				<ModalRealm />
				<Shortcut onEnter={this.listarLogsPonto}/>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Registro de Ponto (Manual)" icon="fa-plus" onClick={this.editarLogPonto} /> : null}
				</ActionFloat>
			</div>
		);
	}

	editarLogPonto = () => {
		ModalRealm.showDialog(<EditarLogPonto key={Math.random()} logPonto={MLogPonto.Modelo()} onModalClose={this.listarLogsPonto}/>);
	}

	detalhesAFD_Headers = (
		<ColumnGroup>
			<Row>
				<Column rowSpan={2} field="offline" style={{width: "3em", textAlign: "center"}}/>
				<Column rowSpan={2} field="descricao" sortable style={{width: "*"}} header="Equipamento"/>
				<Column rowSpan={2} field="ip" sortable style={{width: "10em"}} header="IP"/>
				<Column rowSpan={2} field="serial" sortable style={{width: "12em"}} header="Número de Série"/>
				<Column rowSpan={2} style={{width: "*"}} header="Empresa"/>
				<Column colSpan={2} style={{width: "20em"}} header="Última Marcação"/>
				<Column rowSpan={2} style={{width: "5em"}} header="Ações"/>
			</Row>
			<Row>
				<Column style={{width: "*"}} header="NSR"/>
				<Column style={{width: "12em"}} header="Data"/>
			</Row>
		</ColumnGroup>
	);

	detalhesAFD = [
		<Column key={1} style={{textAlign: "center"}} field="offline" body={e => statusOffline(e.offline)}/>,
		<Column key={0} field="descricao"/>,
		<Column key={2} body={e => <a style={{textDecoration: "none"}} href={`https://${e.ip}`} rel="noopener noreferrer" target="_blank">{e.ip}</a>}/>,
		<Column key={3} field="serial"/>,
		<Column key={4} body={e => e.empresa?.nome}/>,
		<Column key={5} body={e => <div style={{textAlign: "right"}}>{e._nsr?.toLocale()}</div>}/>,
		<Column key={6} style={{textAlign: "center"}} body={e => e._data ? moment(e._data).format("DD/MM/YYYY HH:mm") : ""}/>,
		<Column key={7} style={{width: "5em", textAlign: "center"}} header="Ações" body={(e) => {
			return (
				<div>
					{this.state.lockAFD[e.id] ? <i className="fal fa-spin fa-spinner"/> : <img alt="" className="ui-action-icon" src={iconeBaixar} title="Baixar AFD" onClick={() => this.baixarAFD(e)} />}
				</div>
			);
		}}/>
	];

	baixarAFD = (equipamento) => {
		let {lockAFD} = this.state;
		lockAFD[equipamento.id] = true;
		this.setState({lockAFD});
		const params = [
			`serial:${equipamento.serial}`
		];
		if (this.state.inicio) params.push(`data>${this.state.inicio}`);
		if (this.state.fim) params.push(`data<${moment(this.state.fim).add( 1, "days").format("YYYY-MM-DD")}`);
		if (this.state.marcacoes) params.push("tipo:MARCACAO");
		Fetch.Get(`${baseURL}/afds?serial=${equipamento.serial}&search=${params.join(";")}&portaria=${this.state.portaria}&sort=nsr`, Fetch.BuildHeadersText()).then(response => response.blob().then((blob) => {
			lockAFD[equipamento.id] = false;
			this.setState({lockAFD});
			const file = new Blob([blob], {type: "text/plain"});
			if (!file.size) {
				ModalRealm.showInformacao("warn", ["Nenhum registro encontrado"]);
			} else {
				const data = window.URL.createObjectURL(file);
				if (equipamento.firmware === "1") {
					saveAs(data, `AFD${equipamento.serial}.txt`);
				} else {
					saveAs(data, `AFD${equipamento.serial}${equipamento.empresa?.cnpj || equipamento.empresa?.cpf}REP_C.txt`);
				}
			}
		}));
	}

	selectedTable = () => {
		switch (this.state.activeIndex) {
			case 0:
				return <DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.logsPonto} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />;
			default:
				return <DataTable rowClassName={this.detalhesAFD_ClassName} headerColumnGroup={this.detalhesAFD_Headers} emptyMessage="Nenhum registro encontrado" value={this.state.equipamentos} children={this.detalhesAFD} />;
		}
	}

	detalhesAFD_ClassName = (equipamento) => ({
		"logponto-danger": equipamento._nsr && moment(equipamento._data).isBefore(moment().add(-2, "days"))
	});

}

import React from "react";

export class HelpPortaria extends React.Component {

	render() {
		return (
			<div>
				<p>A portaria permite o cadastramento e liberação de acesso de maneira rápida. Nela, você pode cadastrar pessoas, veículos e fazer liberações de acesso com poucos cliques.</p>
				<p>
					Esta tela confere uma visão completa de toda a atividade necessária para o controle de acesso de visitantes e prestadores de serviço, além de permitir ao porteiro a visualização
					em tempo real de toda a atividade de acesso do Safety, pelos painéis de acesso e de ocorrências.
				</p>
				<h1>Como utilizar a Portaria?</h1>
				<p>
					Para iniciar o lançamento de uma visita, utilize o primeiro campo do painel Portaria. Este é um campo de busca, que mostrará os resultados da consulta à medida em que você for digitando.
					Por se tratar de um campo de propósito geral, permitindo encontrar pessoas, veículos, unidades e credenciais, alguns detalhes devem ser considerados:
				</p>
				<h2>Mecanismo de consulta</h2>
				<ul>
					<li><em>Padrão</em>:
						quando você digita o número de um documento, como CPF, RG, passaporte; ou nome de uma pessoa, não é necessário usar sinal nenhum. O Safety entende a pesquisa padrão como sendo por pessoa.
					</li>
					<li><em>Mais (+)</em>:
						quando digitado ao lado da pesquisa de pessoa, o sinal de mais permite pesquisar por uma credencial temporária de acesso (geralmente utilizada por visitantes). Por exemplo, uma pessoa
						com documento 12345678 que utilizará um cartão de visitante 123, a pesquisa pode ser feita assim: 12345678+123. O sistema buscará o cadastro da pessoa e da credencial e, caso encontre-os,
						fará o vínculo automaticamente.
					</li>
					<li><em>Cifrão ($)</em>:
						permite a busca de veículos para lançamento de acesso com a agenda. Para cada agenda é possível adicionar um veículo apenas. Você pode procurar por veículos já cadastrados utilizando a
						placa. Caso ele ainda não esteja cadastrado, um painel para cadastramento será mostrado automaticamente (da mesma forma que acontece com pessoas).
					</li>
					<li><em>Arroba (@)</em>:
						busca por etiquetas de unidade, a fim de identificar qual unidade será responsável pelo acesso da(s) pessoa(s) desta agenda. Essa identificação é importante para condomínios onde o acesso
						ao perímetro depende de autorização do responsável pela unidade.
					</li>
					<li><em>Cerquilha (#)</em>:
						busca por pessoas, utilizando nome ou um documento, para serem vinculadas como visitados (responsáveis pela agenda). Os visitados receberão email de aviso de agenda de visita, caso esta opção
						esteja habilitada no cadastro deles.
					</li>
				</ul>
				<p>
					É importante notar que estas buscas podem ser feitas de maneira individual (com exceção das credenciais, que precisam ser vinculadas a uma pessoa) ou em conjunto, acelerando ainda mais
					o processo. Por exemplo: você pode pesquisar o documento de uma pessoa (ex.: 12345678) e selecioná-la. Depois procurar pelo veículo (ex.: <b>$</b>ABC1234) e selecioná-lo. Por fim, procurar a unidade
					responsável (ex.: <b>@</b>A01) e selecioná-la; ou ainda, você pode buscar tudo de uma vez (ex.: 12345678<b>$</b>ABC1234<b>@</b>A01) e o Safety entenderá cada parte da consulta e trará tudo que você buscou em uma
					única linha. Feito isso, basta determinar à qual Categoria a agenda pertence (estas categorias podem ser cadastradas na tela de Configurações).
				</p>
				<p>
					Para cada pessoa adicionada é possível também selecionar algumas opções individuais:
				</p>
				<ul>
					<li><em>Credencial de Visita</em>:
						credencial temporária que será utilizada pela pessoa para acesso aos equipamentos. Esta credencial é adicionada quando você utiliza o sinal de mais na busca da portaria e pode ser trocada
						utilizando este campo. Somente credenciais temporárias aparecerão na busca, facilitando o processo.
					</li>
					<li><em>Documentos como Senha</em>:
						quando não há disponibilidade de credenciais temporárias (como cartões), pode-se utilizar o número de um documento (RG ou CPF) para autenticar acesso. Marcar uma das opções desse
						campo habilita o documento para ser digitado (como se fosse uma senha) nos equipamentos.
					</li>
				</ul>
				<h1>Como preencher os campos?</h1>
				<ul>
					<li><em>Categoria</em>:
						define quais etiquetas adicionais serão colocadas na agenda. Este mecanismo ajuda a identificar a finalidade da visita (se é para 
						prestação de serviços ou entrega, por exemplo). Conforme a categoria selecionada, também, se houver um Modelo de Agenda com a mesma descrição, ele será selecionado automaticamente.
					</li>
					<li><em>Modelo de Agenda</em>:
						O Modelo de Agenda (não confundir com Modelo de Acesso), é um gabarito para a geração de agendas de portaria que pode ser configurado no menu Configurações.
						Por padrão, o Safety escolhe o modelo de agenda com a mesma descrição da categoria selecionada. Caso não haja modelo de agenda com descrição igual à da categoria, o Safety escolhe
						o padrão (que possui a mesma descrição da Portaria selecionada). O modelo de agenda determina, além de outras coisas, se a agenda deve ser temporária e se os visitantes devem ter
						documentos habilitados como credencial de acesso.
					</li>
					<li><em>Portaria</em>:
						Em um cenário onde há várias portarias (ex.: uma empresa que possui filiais em outras cidades) que não visualizam as mesmas agendas, é ideal que hajam várias portarias configuradas
						(acesse o menu Configurações para cadastrá-las). <u>A portaria deve ser selecionada antes de começar a criação da agenda, ou ela será reiniciada.</u> Para cada portaria é possível definir
						uma série de categorias e modelos de agenda diferentes, e a(s) etiqueta(s) da portaria será(ão) colocadas na agenda criada, para identificar a origem dela.
					</li>
					<li><em>Acesso Manual</em>:
						ao criar a agenda na portaria, pode-se escolher a opção de fazer o lançamento do acesso da(s) pessoa(s). Caso o computador em que o sistema esteja sendo operado esteja cadastrado
						corretamente, aparecerão as opções de lançamento de acesso disponíveis.
					</li>
					<li><em>Observações</em>:
						texto complementar para auxiliar na identificação da agenda e especificar o motivo de sua geração. Opcional.
					</li>
				</ul>
				<h1>Como utilizar o painel de Visitas?</h1>
				<p>
					À direita do painel de Portaria fica o painel de Visitas. Nele serão mostradas as agendas do último dia. Existe uma série de opções de manutenção, permitindo ao porteiro fazer ajustes
					ou correções nas agendas que foram criadas.
				</p>
				<h2>Como utilizar os campos de filtragem?</h2>
				<ul>
					<li><em>Data</em>: a data base da consulta. O painel de visitas mostra o último dia de lançamento de agendas de portaria com base na data selecionada neste campo.</li>
					<li><em>Pesquisa</em>:
						neste campo você pode procurar por agendas de visita utilizando o nome ou algum documento de uma pessoa que esteja na agenda, ou pela placa do veículo utilizado no acesso,
						ou ainda pela descrição da unidade cuja etiqueta foi vinculada à agenda.
					</li>
				</ul>
				<p>
					Na tabela de visitas, o Safety mostra as informações mais relevantes das agendas. Com esse painel, você pode editar os cadastros das pessoas e veículos vinculados à agenda,
					bem como alterar as etiquetas, validade ou modelos de acesso. Para cada pessoa há, ainda, a opção de cadastrar digitais, senha, ou fazer o lançamento manual de acesso
					(da mesma forma que pode ser feito no momento da confirmação da visita, com a opção Acesso Manual).
				</p>
				<h1>Perguntas frequentes</h1>
				<ol>
					<li>
						<em>Qual a diferença entre Confirmar Visita e Lançar Acesso?</em>
						<div>
							O processo de confirmação de visita consiste na criação da agenda (que é a liberação do acesso para as pessoas envolvidas). Criar uma agenda permite que a(s) pessoa(s) utilizem
							quaisquer credenciais de acesso que lhe estejam disponíveis em todos os equipamentos que deem acesso aos lugares em que ela tem permissão de entrada dentro dos horários determinados
							(configurados nos modelos de acesso dessa agenda). Lançar acesso é o ato de efetivamente registrar a passagem da pessoa pelo equipamento. Para dispositivos de controle de acesso,
							como catracas, torniquetes, etc. o próprio equipamento registra a passagem da pessoa, dispensando o registro manual pelo porteiro. Em casos onde o porteiro é responsável por liberar
							o acesso da pessoa (ex.: em guaritas onde o acionamento da cancela de passagem é manual, ou o visitante não possui acesso permanente), a opção de lançar acesso diz para o Safety
							para onde essa(s) pessoa(s) está(ão) indo. Para que o registro de acesso de uma pessoa conste no relatório de acessos, não basta a criação da agenda, o registro do acesso também
							deve ser feito (pelo porteiro, através do acesso manual, ou pela própria pessoa, utilizando uma credencial em um equipamento de acesso).
						</div>
					</li>
				</ol>
			</div>
		);
	}

}

import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeExcluir from "../../media/icones/excluir.png";
import {DayOfWeek} from "../../utils/DateUtils";
import {MMatrizHorario} from "../../utils/models/MMatrizHorario";
import {MMatrizHorarioPeriodo} from "../../utils/models/MMatrizHorarioPeriodo";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {CheckSquare} from "../commons/CheckSquare";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MatrizHorarioService} from "../servicos/MatrizHorarioService";

export class EditarMatrizHorario extends React.Component {

	state = {
		matrizHorario: Object.assign(MMatrizHorario.Modelo(), this.props.matrizHorario),
		visible: true,
		index: 0,
		lockSave: false
	};

	matrizHorarioService = new MatrizHorarioService();
	security = UserData.security("MTH", true);
	
	componentDidMount() {
		const maxValues = this.state.matrizHorario.periodos.map(p => Math.max(...p.dias));
		const matrizHorario = this.state.matrizHorario;
		matrizHorario.periodos.forEach((periodo) => {
			periodo.inicio = periodo.inicio.formatTime();
			periodo.fim = periodo.fim.formatTime();
		});
		matrizHorario._tamanho = Math.max(0, ...maxValues) + 1;
		matrizHorario.periodos = matrizHorario.periodos.sort((a, b) => {
			const aDias = Math.min(99, ...a.dias)
			const bDias = Math.min(99, ...b.dias);
			if (aDias === bDias) {
				const aProximo = this.proximo[a.proximo];
				const bProximo = this.proximo[b.proximo];
				if (aProximo === bProximo) {
					return a.inicio && b.inicio ? a.inicio.localeCompare(b.inicio) : 0;
				} else {
					return aProximo - bProximo;
				}
			} else {
				return aDias - bDias;
			}
		});
		this.setState({matrizHorario});
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarMatrizHorario = () => {
		if (this.security.edit) {
			let matrizHorario = MMatrizHorario.cleanupBeforeSave(Object.assign({}, this.state.matrizHorario));
			let messages = MMatrizHorario.validarParaSalvar(matrizHorario);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.matrizHorarioService.salvar(matrizHorario).then((matrizHorario) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(matrizHorario);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		const matrizHorario = this.state.matrizHorario;
		matrizHorario[event.name] = event.value;
		this.setState({matrizHorario});
	}

	handleChangeItem = (event, index) => {
		const matrizHorario = this.state.matrizHorario;
		if (["inicio", "fim"].includes(event.name)) {
			matrizHorario.periodos[index][event.name] = event.value.formatTime();
		} else {
			matrizHorario.periodos[index][event.name] = event.value;
		}
		this.setState({matrizHorario});
	}
	
	toggleDay = (index, dia) => {
		const matrizHorario = this.state.matrizHorario;
		if (matrizHorario.periodos[index].dias.includes(dia)) {
			matrizHorario.periodos[index].dias = matrizHorario.periodos[index].dias.filter(d => d !== dia);
		} else {
			matrizHorario.periodos[index].dias.push(dia);
		}
		this.setState({matrizHorario});
	}

	detalhesPeriodos = [
		<Column key={0} style={{width: "*"}} header="Dias" body={(p, r) => {
			const boxes = [];
			const w = moment(this.state.matrizHorario.inicio).day();
			for (let i = 0; i < 7; ++i) {
				boxes.push(<span key={-i} className="mh-periodo-check header">{DayOfWeek.Abbr[i]}</span>);
			}
			for (let i = 0; i < w; ++i) {
				boxes.push(<span key={-i - 7} className="mh-periodo-check space" />);
			}
			for (let i = 0; i < this.state.matrizHorario._tamanho; ++i) {
				boxes.push(<i key={i} className={`mh-periodo-check fal fa-${p.dias.includes(i) ? "check" : "times"}`} onClick={() => this.toggleDay(r.rowIndex, i)} />);
			}
			while (boxes.length % 7) {
				boxes.push(<span key={boxes.length + 7} className="mh-periodo-check space" />);
			}
			return <div className="mh-periodo-check-container">{boxes}</div>;
		}} />,
		<Column key={1} style={{width: "7em"}} header="Início" body={(p, r) => <TextField name="inicio" required={p.fim != null && p.fim.length} value={p.inicio} onChange={(e) => this.handleChangeItem(e, r.rowIndex)} />} />,
		<Column key={2} style={{width: "7em"}} header="Fim" body={(p, r) => <TextField name="fim" required={p.inicio != null && p.inicio.length} value={p.fim} onChange={(e) => this.handleChangeItem(e, r.rowIndex)} />} />,
		<Column key={3} style={{width: "10em"}} header="Próximo Dia" body={(p, r) => <Combobox name="proximo" options={MMatrizHorarioPeriodo.Proximos} appendTo={document.body} value={p.proximo} onChange={(e) => this.handleChangeItem(e, r.rowIndex)} />} />,
		<Column key={4} style={{width: "4em", textAlign: "center"}} header="Folga" body={(p, r) => <CheckSquare name="folga" checked={p.folga} onChange={(e) => this.handleChangeItem(e, r.rowIndex)} />} />,
		<Column key={5} style={{textAlign: "center", width: "5em"}} header="Ações" body={(p) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Período" onClick={() => this.excluirPeriodo(p)} /> : null}
				</div>
			);
		}} />
	];

	excluirPeriodo = (periodo) => {
		const matrizHorario = this.state.matrizHorario;
		matrizHorario.periodos = matrizHorario.periodos.filter(p => p.id ? p.id !== periodo.id : p._key !== periodo._key);
		this.setState({matrizHorario});
	}

	periodoFooter = (
		<div style={{textAlign: "left"}}>
			<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.adicionarPeriodo()} />
		</div>
	);

	adicionarPeriodo = () => {
		const matrizHorario = this.state.matrizHorario;
		matrizHorario.periodos.push(MMatrizHorarioPeriodo.Modelo());
		this.setState({matrizHorario});
	}

	tabelaVisualizacao = () => {
		const diarios = [];
		this.state.matrizHorario.periodos.forEach((periodo) => {
			periodo.dias.forEach((i) => {
				const dia = diarios.filter((p) => p.dias.includes(i));
				if (dia && dia.length) {
					dia[0].horarios.push(periodo);
				} else {
					diarios.push({folga: periodo.folga, data: moment(this.state.matrizHorario.inicio).add("days", i), dias: [i], horarios: [periodo]});
				}
			});
		});
		diarios.forEach(p => p.horarios.sort((a, b) => this.proximo[a.proximo] - this.proximo[b.proximo]));
		return diarios.sort((a, b) => a.data - b.data);
	}

	proximo = {
		"NENHUM": 0,
		"FIM": 1,
		"AMBOS": 2
	};

	tabChange = (e) => this.setState({index: e.index});

	render() {
		return (
			<Dialog modal header="Matriz de Horário" visible={this.state.visible} style={{width: "800px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView activeIndex={this.state.index} onTabChange={this.tabChange}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField required grid={6} label="Descrição" onChange={this.handleChange} name="descricao" value={this.state.matrizHorario.descricao} />
								<DatePicker required grid={2} appendTo={document.body} label="Início" onChange={this.handleChange} name="inicio" value={this.state.matrizHorario.inicio} />
								<TextField minValue={1} maxValue={32} required grid={2} type="number" label="Tamanho" onChange={this.handleChange} name="_tamanho" value={this.state.matrizHorario._tamanho} />
								<TextField required grid={2} type="number" label="Tolerância" onChange={this.handleChange} name="tolerancia" value={this.state.matrizHorario.tolerancia} />
								<div className="ui-g-12">
									<div className="ui-g">
										<div className="p-col-12">
											<DataTable paginator paginatorLeft={this.periodoFooter} emptyMessage="Nenhum período adicionado" header="Períodos" rows={5} children={this.detalhesPeriodos} value={this.state.matrizHorario.periodos} />
										</div>
									</div>
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Visualização">
							<PanelContent>
								<DataTable value={this.tabelaVisualizacao()}>
									<Column header="Dia" body={(p) => `${moment(p.data).format("DD/MM/YYYY")}, ${DayOfWeek.from(moment(p.data).day())}`} />
									<Column header="Horários" body={(p) => p.folga ? "Folga" : p.horarios.map(h => `${h.inicio} - ${h.fim}`).join(", ")} />
								</DataTable>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.matrizHorario.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/matrizes-horario", this.state.matrizHorario)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.matrizHorario.id)) || this.state.lockSave} onClick={this.salvarMatrizHorario} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarMatrizHorario} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

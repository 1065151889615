export function whatBrowser() {
	const isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
	const isFirefox = typeof InstallTrigger !== 'undefined';   // Firefox 1.0+
	const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
	const isChrome = !!window.chrome && !isOpera;              // Chrome 1+
	const isIE = /*@cc_on!@*/false || !!document.documentMode;   // At least IE6
	return {
		isOpera, isSafari, isFirefox, isChrome, isIE
	};
}

import React, {Component} from "react";
import {GrupoTrabalhoService} from "../servicos/GrupoTrabalhoService";
import {Panel} from "primereact/components/panel/Panel";
import {PanelContent} from "../commons/PanelContent";
import {TextField} from "../commons/TextField";
import {PanelFooter} from "../commons/PanelFooter";
import {Action} from "../commons/Action";
import {DataTable} from "primereact/components/datatable/DataTable";
import {GUI} from "../../utils/Constants";
import {ActionFloat} from "../commons/ActionFloat";
import {Shortcut} from "../commons/Shortcut";
import {ModalRealm} from "../commons/ModalRealm";
import {Column} from "primereact/components/column/Column";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeAuditoria from "../../media/icones/auditoria.png";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {UserData} from "../../utils/UserData";
import {EditarGrupoTrabalho} from "./EditarGrupoTrabalho";

export class GrupoTrabalho extends Component {

	state = {
		gruposTrabalho: [],
		excluidos: false,
		descricao: "",
	};

	security = UserData.security("GRT", true);
	grupoTrabalhoService = new GrupoTrabalhoService();

	listarGruposTrabalho = () => {
		const params = [];
		const search = [];
		if (this.state.descricao?.length) search.push(`descricao~${this.state.descricao}`);
		params.push(`search=${search.join(";")}`);
		params.push("sort=descricao");
		this.grupoTrabalhoService.listar(params.join("&"), {page: 0, size: GUI.maxResults}).then(gruposTrabalho => this.setState({gruposTrabalho}));
	}

	criarGrupoTrabalho = () => this.editarGrupoTrabalho({descricao: "", equipamentos: []});

	editarGrupoTrabalho = grupoTrabalho => ModalRealm.showDialog(<EditarGrupoTrabalho key={Math.random()} grupoTrabalho={grupoTrabalho} onModalClose={this.listarGruposTrabalho}/>);

	excluirGrupoTrabalho = grupoTrabalho => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta grupo de trabalho?", () => this.grupoTrabalhoService.excluir(grupoTrabalho).then(this.listarGruposTrabalho));
	}

	restaurarGrupoTrabalho = grupoTrabalho => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta grupo de trabalho?", () => {
			if (grupoTrabalho?.id) {
				this.grupoTrabalhoService.buscar(grupoTrabalho).then(grupoTrabalho => {
					grupoTrabalho.status = "ATIVADO";
					this.grupoTrabalhoService.salvar(grupoTrabalho).then(this.listarGruposTrabalho);
				});
			}
		});
	}

	eliminarGrupoTrabalho = grupoTrabalho => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este grupo de trabalho?", () => this.grupoTrabalhoService.eliminar(grupoTrabalho).then(result => {
			if (result) this.listarGruposTrabalho();
			else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta grupo de trabalho: há outros registros dependentes dele no sistema."]);
		}));
	}

	visualizarHistorico = grupoTrabalho => EntidadeNextGenService.listarHistorico("/grupos-trabalho", grupoTrabalho);

	handleChange = event => this.setState({[event.name]: event.value});

	render() {
		return (
			<div>
				<Panel header="Grupos de Trabalho">
					<PanelContent className="ui-g">
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleChange}/>
					</PanelContent>
					<PanelFooter>
						<Action label="Procurar" icon="fa-search" onClick={this.listarGruposTrabalho}/>
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator"/>
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.gruposTrabalho} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage}>
						<Column header="ID" sortable style={{width: "5em", textAlign: "right"}} field="id"/>
						<Column header="Descrição" sortable field="descricao"/>
						<Column header="Equipamentos" body={gt => gt.equipamentos?.map(e => <div id={e.id}>{e.descricao}</div>)}/>
						<Column header="Ações" style={{width: "6em"}} body={v => (
							<div style={{textAlign: "center"}}>
								{this.security.edit && v.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Grupo de Trabalho" onClick={() => this.editarGrupoTrabalho(v)}/> : null}
								{this.security.remove && v.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Grupo de Trabalho" onClick={() => this.excluirGrupoTrabalho(v)}/> : null}
								{this.security.remove && v.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(v)}/> : null}
							</div>
						)}/>
					</DataTable>
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Novo Grupo de Trabalho" icon="fa-plus" onClick={this.criarGrupoTrabalho}/> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarGruposTrabalho}
						onCtrlN={() => window.location.pathname === "/grupos-trabalho" && this.security.create && this.criarGrupoTrabalho()}
						onCtrlE={() => window.location.pathname === "/grupos-trabalho" && this.state.gruposTrabalho.length === 1 && this.security.edit && this.editarGrupoTrabalho(this.state.gruposTrabalho[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}


const basePath = process.env.REACT_APP_API_URL;

export class TabletService {

	mensagens(id) {
		return fetch(`${basePath}/public/tablet/mensagens/${id}`, {method: "get"}).then((response) => response.json());
	}

	anonimas(schema) {
		return fetch(`${basePath}/public/tablet/mensagens-anonimas/${schema}`, {method: "get"}).then((response) => response.json());
	}

}

import {MEntidade} from "./MEntidade";

export class MLugarTarifa {

	static Modelo() {
		return {
			valor: 1,
			etiqueta: null
		};
	}

	static cleanupBeforeSave(tarifa) {
		return MEntidade.cleanupBeforeSave(tarifa);
	}

	static validarParaSalvar(tarifa) {
		let messages = [];
		return messages;
	}

}

import {httpGet, httpPost} from "../../utils/Request";

export class RelatorioService {

	relatorioAcesso(parameters) {
		return httpPost("/relatorios/acesso", parameters);
	}

	relatorioMovimentacao(parameters) {
		return httpPost("/relatorios/movimentacao", parameters);
	}

	relatorioPresenca(parameters) {
		return httpPost("/relatorios/presenca", parameters);
	}

	registrosEmissao() {
		return httpGet("/logs-relatorio", {sort: "inicio desc", page: 0, size: 100});
	}

}

export const relatoriosGeral = [
	{role: "XXAV", label: "101 - Relatório de Credenciais Não Devolvidas", value: {index: 101, path: "/credenciais/expiradas", nome: "Credenciais Expiradas"}},
	{role: "XXAC", label: "102 - Relatório de Presença por Lugar", value: {index: 102, path: "/logs-acesso/presencas", nome: "Presenca por Lugar"}},
	{role: "XXAE", label: "103 - Relatório de Acessos Ativos por Unidade", value: {index: 103, path: "/agendas/ativos", nome: "Relação de Ativos por Unidade"}},
	{role: "XXAR", label: "104 - Relatório de Detalhamento de Acesso", value: {index: 104, path: "/logs-acesso/acessos", nome: "Detalhamento de Acesso"}},
	{role: "XXBV", label: "105 - Relatório de Atividade de Acesso por Pessoa", value: {index: 105, path: "/logs-acesso/movimentacoes", nome: "Movimentacao por Pessoa"}},
	{role: "XXBC", label: "106 - Relatório de Ocorrências de Segurança", value: {index: 106, path: "/ocorrencias", nome: "Ocorrências"}},
	{role: "XXBE", label: "107 - Relatório de Reservas de Espaço", value: {index: 107, path: "/reservas", nome: "Reservas"}},
	{role: "XXBR", label: "108 - Extrato de Movimentação de Conta de Crédito", value: {index: 108, path: "/tarifa-saldos", nome: "Extrato de Conta de Crédito"}},
	{role: "XXCV", label: "109 - Relatório de Permissões de Acesso por Equipamento", value: {index: 109, path: "/equipamentos/permissoes", nome: "Permissões por Equipamento"}},
	{role: "XXCC", label: "110 - Relatório de Documentos a Expirar", value: {index: 110, path: "/pessoas/documentos-vencimento", nome: "Documentos a Vencer"}}
];

export const relatoriosCacique = [
	{role: "XXVV", label: "151 - Relatório de Refeições de Visitantes por Centro de Custo", value: {index: 1151, path: "/custom/cacique/refeicoes-visitantes", nome: "Refeicoes Visitantes"}},
	{role: "XXVC", label: "152 - Relatório de Refeições de Terceiros por Empresa", value: {index: 1152, path: "/custom/cacique/refeicoes-terceiros", nome: "Refeicoes Terceiros"}},
	{role: "XXVE", label: "153 - Relatório de Refeições por Período", value: {index: 1153, path: "/custom/cacique/refeicoes-periodo", nome: "Refeicoes Periodo"}},
	{role: "XXVR", label: "154 - Relatório de Refeições de Terceiros", value: {index: 1154, path: "/views/cacique/refeicoes/detalhado", nome: "Refeicoes Terceiros Detalhado"}},
	{role: "XXWV", label: "155 - Relatório de Refeições de Terceiros por Período", value: {index: 1155, path: "/views/cacique/refeicoes/agrupado-periodo", nome: "Refeicoes Terceiros Agrupado Periodo"}},
	{role: "XXWC", label: "156 - Relatório de Detalhamento de Refeições de Visitantes por Centro de Custo", value: {index: 1156, path: "/custom/cacique/refeicoes-visitantes-detalhado", nome: "Refeicoes Visitantes Detalhado"}},
	{role: "XXWE", label: "157 - Relatório de Plano de Evacuação", value: {index: 1157, path: "/custom/cacique/plano-evacuacao", nome: "Plano de Evacuação"}}
];

export const labelRelatorio = Object.assign({}, ...relatoriosGeral.concat(relatoriosCacique).map(r => ({
	[r.value.index]: r.label
})));

import {httpGet} from "../../utils/Request";

export class HistoricoService {

	versoes(path, entidade) {
		return httpGet(`${path}/versoes/${entidade.id}`);
	}

	revisoes(path, entidade) {
		return httpGet(`${path}/revisoes/${entidade.id}`);
	}

}

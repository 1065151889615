import React, {Component} from "react";
import {Label} from "./Label";
import {Chips as PChips} from "primereact/components/chips/Chips";

export class Chips extends Component {

	handleAdd = event => {
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, index: this.props.index, value: [...this.props.value, event.value]});
		}
	}

	handleRemove = event => {
		if (this.props.onChange) {
			const value = this.props.value?.filter(v => v !== event.value[0]);
			this.props.onChange({name: this.props.name, index: this.props.index, value});
		}
	}

	render() {
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label help={this.props.help}>{this.props.label}</Label>
				<div className="textfield-container">
					<PChips {...this.props} onAdd={this.handleAdd} onRemove={this.handleRemove}/>
				</div>
			</div>
		);
	}

}

export const optionsFinalidade = [
	{label: "Identificação", value: "IDENTIFICACAO"},
	{label: "Biometria", value: "BIOMETRIA"},
	{label: "Documento", value: "DOCUMENTO"}
];

export const finalidades = [
	"IDENTIFICACAO",
	"DOCUMENTO",
	"BIOMETRIA"
];

export const mapFinalidade = {
	IDENTIFICACAO: "Identificação",
	DOCUMENTO: "Documento",
	BIOMETRIA: "Biometria"
}

import moment from "moment";
import React from "react";
import audioAlert from "../../media/audio/light.ogg";
import {MCredencial} from "../../utils/models/MCredencial";
import {StringUtils, toSrc} from "../../utils/StringUtils";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {LogAcessoService} from "../servicos/LogAcessoService";
import {ActionFloat} from "./ActionFloat";
import {Combobox} from "./Combobox";
import {httpPost} from "../../utils/Request";

const MAX_COUNT = 12;

export class PainelAcesso extends React.Component {

	state = {
		logsAcesso: [],
		lastLogAcesso: 0,
		monitoraveis: []
	};

	equipamentoService = new EquipamentoService();
	logAcessoService = new LogAcessoService();

	componentDidMount() {
		let setor = null;
		if (this.props.portaria && this.props.portaria.setores && this.props.portaria.setores.length) {
			let selected = window.localStorage.getItem("safety-painelacesso-setor");
			if (selected) {
				setor = this.props.portaria.setores.filter(s => s.id === Number(selected))[0] || setor;
			} else {
				setor = this.props.portaria.setores[0];
			}
		}
		this.changeSetor({value: setor});
	}

	changeSetor = (event) => {
		if (event.value) {
			let setor = event.value ? `etiquetas=id:${event.value.id}` : "";
			this.equipamentoService.listar(setor, {page: 0, size: 100, sort: "descricao"}).then(equipamentos => {
				this.setState({lastLogAcesso: 0, logsAcesso: [], setor: event.value, monitoraveis: equipamentos.map(e => e.id)}, () => {
					httpPost("/painel-acesso", this.state.monitoraveis).then(logsAcesso => {
						this.setState({logsAcesso: logsAcesso.map(this.templateLogAcesso)});
					});
				});
			});
			if (event.value) {
				window.localStorage.setItem("safety-painelacesso-setor", event.value.id);
			}
		} else {
			httpPost("/painel-acesso", []).then(logsAcesso => {
				this.setState({logsAcesso: logsAcesso.map(this.templateLogAcesso)});
			});
			window.localStorage.removeItem("safety-painelacesso-setor");
		}
	}

	togglePainelAcesso = () => {
		window.localStorage.setItem("safety-painelacesso", !this.props.expanded);
		if (this.props.onToggle) this.props.onToggle({value: !this.props.expanded});
	}

	togglePainelAcessoMute = () => {
		window.localStorage.setItem("safety-painelacesso-mute", !this.props.mute);
		if (this.props.onToggleMute) this.props.onToggleMute({value: !this.props.mute});
	}

	templateLogAcesso(logAcesso) {
		let foto;
		if (logAcesso.pessoa.ultimaFoto_) {
			foto = toSrc({path: `/images/photos/${logAcesso.pessoa.ultimaFoto_}-t.jpg`});
		}
		return (
			<div key={logAcesso.id} className={`ui-portaria-logacesso ${logAcesso.flags?.includes("OFFLINE") ? "offline" : ""}`}>
				{foto ? <img className="logs-acesso-v2-foto painel-acesso" src={foto} alt="" /> : <span className="logs-acesso-v2-foto painel-acesso sem-foto"/>}
				<div className="ui-portaria-logacesso-dados">
					<div>{logAcesso.pessoa ? StringUtils.simplificarNome(logAcesso.pessoa.nome) : "Pessoa Não Identificada"}</div>
					<div>{(logAcesso.destino && logAcesso.destino.tipo !== "EXTERNO" ? "Para " + logAcesso.destino.descricao : "Saiu") + ` às ${moment(logAcesso.data).format("HH:mm")}`}</div>
					<div>{`${MCredencial.MapFormas[logAcesso.forma]} em ${logAcesso.equipamento != null ? logAcesso.equipamento.descricao : ""}`}</div>
					{logAcesso.etiquetas_?.split(";").map(etiqueta_ => <EtiquetaChip key={etiqueta_} etiqueta={etiqueta_}/>)}
				</div>
			</div>
		);
	}

	queueLogAcesso = (logAcesso) => {
		if (logAcesso.atividade === "PASSAGEM" && (!this.state.monitoraveis.length || this.state.monitoraveis.includes(logAcesso.equipamento.id))) {
			if (this.soundAlert && this.props.expanded && !this.props.mute) {
				this.soundAlert.play();
			}
			let {logsAcesso} = this.state;
			logsAcesso.unshift(this.templateLogAcesso(logAcesso));
			if (logsAcesso.length > MAX_COUNT) {
				logsAcesso = logsAcesso.slice(0, MAX_COUNT);
			}
			this.setState({logsAcesso});
			if (this.props.onUpdate) {
				this.props.onUpdate(logAcesso);
			}
		}
	}

	refAlert = (el) => this.soundAlert = el;

	render() {
		let setores = this.props.portaria.setores;
		if (setores != null) {
			setores = setores.map(s => {return {label: s.descricao, value: s}});
		}
		return (
			<div>
				<div className={`ui-painel-acessos${this.props.expanded ? " ui-painel-acessos-expanded" : ""}`} {...this.props}>
					<div className="ui-painel-acessos-container">
						{this.state.logsAcesso.length === 0 ? <div className="fa fa-spinner fa-spin ui-painel-acessos-spin" /> : null}
						{
							this.props.portaria.setores?.length ? (
								<div className="ui-g-12" style={{backgroundColor: "#FFFFFF", borderLeft: "1px solid #CCCCCC"}}>
									<Combobox grid={12} label="Setor" name="setor" value={this.state.setor} options={setores} onChange={this.changeSetor}/>
								</div>
							) : null
						}
						<div>
							<div className="ui-portaria-logacesso-container">{this.state.logsAcesso}</div>
						</div>
					</div>
				</div>
				<div className="ui-painel-acessos-toggler" onClick={this.togglePainelAcesso} />
				<SafetyWebSocket path="/logs-acesso" onMessage={this.queueLogAcesso} />
				<audio ref={this.refAlert}>
					<source src={audioAlert} type="audio/ogg" />
				</audio>
				<ActionFloat>
					{this.props.expanded ? <ActionFloat.Button mode="secondary small" onClick={this.togglePainelAcessoMute} icon={`fa-volume-${this.props.mute ? "off" : "up"}`} /> : null}
					<ActionFloat.Button mode="secondary" onClick={this.togglePainelAcesso} icon={`fa-arrow-${this.props.expanded ? "right" : "left"}`} />
				</ActionFloat>
			</div>
		);
	}

}

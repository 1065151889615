import {httpGet} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class PessoaFisicaService extends EntidadeNextGenService {

	constructor() {
		super("/pessoas-fisicas");
	}

	detalharAcesso(id) {
		return httpGet(`/pessoas-fisicas/acesso/${id}`);
	}

	buscarTodasPessoas(){
		return httpGet('/pessoas-fisicas')
	}

	buscarPorDocumento(nomeDoc , documento){
		return httpGet(`/pessoas-fisicas?search=status:ATIVADO;${nomeDoc}~${documento}&page=0&size=100&`)
	}
	
	async buscarPorDocumentoExcluido(nomeDoc,documento) {
		if (nomeDoc === 'cpf') {
			return await httpGet(`/pessoas-fisicas/buscar-pessoa-excluida`, {'cpf' : documento});
		} else {
			return await httpGet(`/pessoas-fisicas/buscar-pessoa-excluida`, {'rg' : documento})
		}
		
	}

	async listarSuggestion(params) {
		return await httpGet(`/pessoas-fisicas/suggestion`,params);
	}
}
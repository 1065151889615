import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MTarifaSaldo} from "../../utils/models/MTarifaSaldo";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {TarifaSaldoService} from "../servicos/TarifaSaldoService";
import {TextField} from "../commons/TextField";

export class EditarTarifaSaldo extends React.Component {

	state = {
		tarifaSaldo: Object.assign(MTarifaSaldo.Modelo(), this.props.tarifaSaldo),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	tarifaSaldoService = new TarifaSaldoService();
	security = UserData.security("TSD", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarTarifaSaldo = () => {
		let tarifaSaldo = MTarifaSaldo.cleanupBeforeSave(Object.assign({}, this.state.tarifaSaldo));
		let messages = MTarifaSaldo.validarParaSalvar(tarifaSaldo);
		if (messages.length > 0) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			tarifaSaldo.responsavel = UserData.usuario;
			this.setState({lockSave: true});
			this.tarifaSaldoService.salvar(tarifaSaldo).then((tarifaSaldo) => {
				this.setState({lockSave: false});
				if (this.props.onModalClose) {
					this.props.onModalClose(tarifaSaldo);
				}
				this.onClose();
			}).catch(() => this.setState({lockSave: false}));
		}
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleChange = (event) => {
		let tarifaSaldo = this.state.tarifaSaldo;
		tarifaSaldo[event.name] = event.value;
		this.setState({tarifaSaldo});
	}

	numberFormat = Intl.NumberFormat("pt-BR");

	render() {
		return (
			<Dialog modal header="Lançamento de Saldo" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={8} disabled name="conta" value={`Nº ${this.numberFormat.format(this.state.tarifaSaldo.conta.id)} - ${this.state.tarifaSaldo.conta.pessoa.nome}`} label="Conta" />
					<TextField unit="Cr" grid={4} name="valor" value={this.state.tarifaSaldo.valor} label="Valor" onChange={this.handleChange} />
					<TextField maxLength={200} grid={12} label="Identificação no Extrato" name="observacao" value={this.state.tarifaSaldo.observacao} onChange={this.handleChange} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.tarifaSaldo.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/tarifa-contas", this.state.tarifaSaldo)} /> : null}
					<Action disabled={!(this.security.edit || (this.security.create && !this.state.tarifaSaldo.id)) || this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarTarifaSaldo} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarTarifaSaldo} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import iconeRemover from "../../media/icones/remover.png";
import {isDocumentoEditavel, MDocumento} from "../../utils/models/MDocumento";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {toSrc, truncateString} from "../../utils/StringUtils";
import {Button} from "primereact/components/button/Button";
import {DocumentoTipoService} from "../servicos/DocumentoTipoService";
import {Foto as NewFoto} from "../Foto";
import {httpPost} from "../../utils/Request";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {PanelContent} from "../commons/PanelContent";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import iconeDownload from "../../media/icones/download.png";
import {Fetch} from "../../utils/Fetch";
import saveAs from "file-saver";
import {uuidv4} from "../../utils/UUIDUtils";
import {SelectLocalidade} from "../../select/SelectLocalidade";

const basePath = process.env.REACT_APP_API_URL;

function filterTiposDocumentos(modo, dt) {
	switch (modo) {
		case "fisica":
			return dt.aplicacao === "FISICA";
		case "juridica":
			return dt.aplicacao === "JURIDICA";
		case "veiculo":
			return dt.aplicacao === "VEICULO";
		case "agenda":
			return dt.aplicacao === "AGENDA";
		default:
			return false;
	}
}

export class EditarDocumento extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			documento: this.fillNulls(Object.assign({}, props.documento)),
			visible: true,
			documentoTipos: [],
			activeIndex: 0
		};
		this.documentoTipoService = new DocumentoTipoService();
	}

	componentDidMount() {
		this.documentoTipoService.listar("", {page: 0, size: 100, sort: "id"}).then(documentoTipos => {
			documentoTipos = documentoTipos.filter(dt => filterTiposDocumentos(this.props.modo, dt) && isDocumentoEditavel({tipo: dt})).map(dt => ({label: dt.descricao, value: dt}));
			this.setState({documentoTipos});
		});
	}

	fillNulls = (documento) => {
		if (!documento) {
			documento = MDocumento.Modelo();
		}
		if (documento.fotos) {
			documento.fotos.forEach((foto) => {
				if (foto && foto.conteudo && !foto.conteudo.includes(",")) {
					foto.conteudo = `data:image/jpeg;base64,${foto.conteudo}`;
				}
			});
		}
		if (documento.numero && documento.tipo?.descricao === "CNH") {
			const parts = documento.numero.split("/");
			documento.numero = parts[0];
			documento._categoria = parts[1] || "";
		}
		return documento;
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarDocumento = () => {
		let documento = MDocumento.cleanupBeforeSave(Object.assign({}, this.state.documento));
		let messages = MDocumento.validarParaSalvar(documento);
		if (messages.length > 0) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			if (documento.fotos?.length) {
				documento.fotos = documento.fotos.filter(f => f);
			}
			if (documento.tipo?.descricao === "CNH") {
				if (documento._categoria) {
					documento.numero = `${documento.numero}/${documento._categoria}`;
				}
			}
			if (this.props.onModalClose) {
				this.props.onModalClose(documento);
			}
			this.onClose();
		}
	}

	handleChange = (event) => {
		let {documento} = this.state;
		documento[event.name] = event.value;
		this.setState({documento});
	}
	
	removerFoto = (event, index) => {
		event.stopPropagation();
		const documento = this.state.documento;
		documento.fotos[index] = null;
		documento.fotos = documento.fotos.filter(f => f);
		this.setState({documento});
	}

	uploadPhoto1 = (event) => {
		const file = event.target.files[0];
		if (file) {
			let reader = new FileReader();
			reader.onloadend = async () => {
				let foto = {
					status: "ATIVADO",
					_timestamp: new Date(),
					finalidade: "DOCUMENTO",
					conteudo: reader.result.split(",")[1]
				};
				foto = await httpPost("/fotos", foto);
				const {documento} = this.state;
				if (!documento.fotos) {
					documento.fotos = [];
				}
				documento.fotos[0] = foto;
				this.setState({documento});
			};
			reader.readAsDataURL(file);
		}
	}

	handlePhotoUpload1 = (event) => {
		event.stopPropagation();
		this.inputFile1.click();
	}

	uploadPhoto2 = (event) => {
		const file = event.target.files[0];
		if (file) {
			let reader = new FileReader();
			reader.onloadend = async () => {
				let foto = {
					status: "ATIVADO",
					_timestamp: new Date(),
					finalidade: "DOCUMENTO",
					conteudo: reader.result.split(",")[1]
				};
				foto = await httpPost("/fotos", foto);
				const {documento} = this.state;
				if (!documento.fotos) {
					documento.fotos = [];
				}
				documento.fotos[1] = foto;
				this.setState({documento});
			};
			reader.readAsDataURL(file);
		}
	}

	handlePhotoUpload2 = (event) => {
		event.stopPropagation();
		this.inputFile2.click();
	}

	handleTabChange = (event) => {
		this.setState({activeIndex: event.index});
	}

	refInput1 = (el) => this.inputFile1 = el;

	refInput2 = (el) => this.inputFile2 = el;

	refInput3 = (el) => this.inputFile3 = el;

	subirArquivo = event => {
		const file = event.target.files[0];
		if (file) {
			let reader = new FileReader();
			reader.onloadend = async () => {
				let arquivo = {
					fileName: truncateString(`${uuidv4()}_${file.name.replace(/[^a-zA-Z0-9\-._ ]/g, "_")}`, {maxLength: 255, cutStart: true}),
					conteudo: reader.result.split(",")[1]
				};
				arquivo = await httpPost("/arquivos", arquivo);
				const {documento} = this.state;
				if (!documento.arquivos) {
					documento.arquivos = [];
				}
				documento.arquivos.push(arquivo);
				this.setState({documento});
			};
			reader.readAsDataURL(file);
		}
	}

	handleSubirArquivo = event => {
		event.stopPropagation();
		this.inputFile3.click();
	}

	baixarArquivo = arquivo => {
		Fetch.Get(`${basePath}/arquivos/download/${arquivo.fileName}`).then(response => response.blob().then(blob => {
			const file = new Blob([blob]);
			const data = window.URL.createObjectURL(file);
			saveAs(data, arquivo.fileName);
		}));
	}

	removerArquivo = arquivo => {
		const {documento} = this.state;
		documento.arquivos = documento.arquivos.filter(a => a.id !== arquivo.id);
		this.setState({documento});
	}

	render() {
		const cnh = this.state.documento.tipo?.descricao === "CNH";
		return (
			<Dialog modal header="Documento" visible={this.state.visible} style={{width: "716px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<Combobox appendTo={document.body} label="Tipo" grid={12} options={this.state.documentoTipos} name="tipo" value={this.state.documento.tipo} onChange={this.handleChange} />
								<DatePicker appendTo={document.body} label="Validade" grid={3} name="validade" value={this.state.documento.validade} onChange={this.handleChange} />
								<TextField type="document" label="Número" grid={cnh ? 3 : 5} name="numero" value={this.state.documento.numero} onChange={this.handleChange} />
								{cnh ? <Combobox label="Categoria" options={MDocumento.CategoriasCNH} name="_categoria" grid={2} value={this.state.documento._categoria} onChange={this.handleChange} /> : null}
								<SelectLocalidade name="localidade" value={this.state.documento.localidade} grid={4} onChange={this.handleChange}/>
								<div className="ui-g-12">
									<Label>Fotos</Label>
									<div className="ui-g ui-g-nopad">
										<div style={{position: "relative"}} className="ui-g-6 ui-g-nopad">
											<div onClick={() => this.tirarFotografia(0)} className="ui-foto-documento-thumb">
												<img className="ui-foto-documento-thumb-foto" src={this.state.documento.fotos && this.state.documento.fotos.length > 0 ? toSrc(this.state.documento.fotos[0]) : null} alt="" />
												{this.state.documento.fotos && this.state.documento.fotos[0] ? <img src={iconeRemover} title="Remover Foto" className="ui-action-icon ui-foto-documento-thumb-remove" alt="" onClick={(event) => this.removerFoto(event, 0)} /> : null}
											</div>
											<input accept="image/jpeg" style={{display: "none"}} ref={this.refInput1} type="file" value="" onChange={this.uploadPhoto1}/>
											<Button icon="fa-upload" title="Upload de Imagem" className="ui-button-secondary upload-button" onClick={this.handlePhotoUpload1}/>
										</div>
										<div style={{position: "relative"}} className="ui-g-6 ui-g-nopad">
											<div onClick={() => this.tirarFotografia(1)} className="ui-foto-documento-thumb">
												<img className="ui-foto-documento-thumb-foto" src={this.state.documento.fotos && this.state.documento.fotos.length > 1 ? toSrc(this.state.documento.fotos[1]) : null} alt="" />
												{this.state.documento.fotos && this.state.documento.fotos[1] ? <img src={iconeRemover} title="Remover Foto" className="ui-action-icon ui-foto-documento-thumb-remove" alt="" onClick={(event) => this.removerFoto(event, 1)} /> : null}
											</div>
											<input accept="image/jpeg" style={{display: "none"}} ref={this.refInput2} type="file" value="" onChange={this.uploadPhoto2}/>
											<Button icon="fa-upload" title="Upload de Imagem" className="ui-button-secondary upload-button" onClick={this.handlePhotoUpload2}/>
										</div>
									</div>
								</div>
								{
									this.props.modo !== "fisica" ? null : (
										<>
											<TextField name="_cpf" type="cpf" value={this.state.documento._cpf} onChange={this.handleChange} grid={4} label="CPF" />
											<TextField name="_rg" value={this.state.documento._rg} onChange={this.handleChange} grid={4} label="RG" />
											<TextField name="_passaporte" value={this.state.documento._passaporte} onChange={this.handleChange} grid={4} label="Outro Documento" />
										</>
									)
								}
							</PanelContent>
						</TabPanel>
						<TabPanel header="Anexos">
							<PanelContent>
								<DataTable rows={10} paginator paginatorLeft={
									<Button icon="fa fa-plus" className="ui-tzm-pagleft-icon" onClick={this.handleSubirArquivo}/>
								} emptyMessage="Nenhum arquivo adicionado" value={this.state.documento.arquivos}>
									<Column style={{overflow: "hidden"}} header="Nome do Arquivo" field="fileName" body={a => a.fileName.substring(a.fileName.indexOf("_") + 1)}/>
									<Column style={{width: "5em", textAlign: "center"}} header="Ações" body={a => (
										<div>
											<img alt="" className="ui-action-icon" src={iconeDownload} title="Baixar Arquivo" onClick={() => this.baixarArquivo(a)} />
											<img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Arquivo" onClick={() => this.removerArquivo(a)} />
										</div>
									)}/>
								</DataTable>
							</PanelContent>
							<input accept="application/pdf, image/jpeg, image/png, text/plain" style={{display: "none"}} ref={this.refInput3} type="file" value="" onChange={this.subirArquivo}/>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.salvarDocumento} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarDocumento} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}
	
	tirarFotografia = () => {
		const {documento} = this.state;
		let width = Math.round(document.body.clientWidth * .3);
		let height = Math.round(width * .75);
		const fotos = documento.fotos ? [...documento.fotos] : [];
		ModalRealm.showDialog(<NewFoto fotos={fotos} width={width} height={height} onModalClose={(fotos, _modFotos) => {
			documento.fotos = fotos;
			this.setState({documento, _modFotos});
		}}/>);
	}

}

import React from "react";
import {UserData} from "../../utils/UserData";
import {ModalRealm} from "./ModalRealm";
import moment from "moment";
import {AvisoService} from "../servicos/AvisoService";

export class ChipAviso extends React.Component {

	avisoService = new AvisoService();
	security = UserData.security("AVI");

	excluirAviso = e => {
		e.stopPropagation();
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este aviso?", () => {
			this.avisoService.excluir(this.props.aviso).then(() => {
				if (this.props.onDelete) {
					this.props.onDelete(this.props.aviso);
				}
			});
		});
	}

	render() {
		if (this.props.aviso.tipo === "LIBERACAO") {
			return (
				<div style={{color: this.props.aviso.fonte, backgroundColor: this.props.aviso.fundo}} className="portaria-aviso" key={this.props.aviso.id}>
					<div>
						{
							!this.props.aviso.texto?.trim().length
								? `Autorizado para a unidade ${this.props.aviso.etiqueta.descricao}`
								: this.props.aviso.texto
						}
						{
							this.props.aviso.inicio && moment().isBefore(moment(this.props.aviso.inicio))
								? <span className="aviso-scadia">{`Adiantado em ${moment(this.props.aviso.inicio).diff(moment(), "minutes")} minutos`}</span>
								: null
						}
						{
							this.security.remove
								? <i className="fa fa-times ui-chip-remove" onClick={() => this.excluirAviso()} />
								: null
						}
					</div>
					<div className={`aviso-scline ${this.props.aviso.flags?.includes("IMEDIATO")}`}>
						{
							this.props.aviso.flags?.includes("IMEDIATO")
								? <span><i className="fa fa-phone-slash"/> Dispensa confirmação de chegada</span>
								: <span><i className="fa fa-phone"/> Ligar para confirmar chegada</span>
						}
					</div>
				</div>
			);
		}
		return (
			<div style={{color: this.props.aviso.fonte, backgroundColor: this.props.aviso.fundo}} className="portaria-aviso" key={this.props.aviso.id}>
				{this.props.aviso.icone?.length ? <i style={{marginRight: "4px"}} className={`fad fa-${this.props.aviso.icone}`}/> : null}
				{this.props.aviso.texto}
				{this.security.remove ? <i className="fa fa-times ui-chip-remove" onClick={this.excluirAviso}/> : null}
			</div>
		);
	}

}

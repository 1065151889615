import React from "react";
import {Label} from "./Label";

export class TextField extends React.Component {

	state = {
		hide: true
	};

	inputRef = (input) => {
		if (input != null) this.input = input;
	}

	componentDidMount() {
		if (this.props.autofocus && this.input) {
			setTimeout(() => this.input.focus(), 50);
		}
	}

	onChange = (event) => {
		this.props.onChange({...event, name: this.props.name, value: event.target.value, index: this.props.index});
	}

	handleTimestamp = (event) => {
		event.target.value = event.target.value.toDateTime();
		this.onChange(event);
	}

	handleCPF = (event) => {
		event.target.value = event.target.value.formatCpf();
		this.onChange(event);
	}

	handleCEP = (event) => {
		event.target.value = event.target.value.formatCep();
		this.onChange(event);
	}

	handleCNPJ = (event) => {
		event.target.value = event.target.value.formatCnpj();
		this.onChange(event);
	}

	handleTelefone = (event) => {
		event.target.value = event.target.value.formatPhone();
		this.onChange(event);
	}

	handleNumeric = (event) => {
		event.target.value = event.target.value.replace(/[^0-9]/g, "");
		if (!event.target.value.length) event.target.value = "0";
		event.target.value = String(Number(event.target.value));
		if (this.props.minValue && Number(event.target.value) < this.props.minValue) event.target.value = this.props.minValue;
		if (this.props.maxValue && Number(event.target.value) > this.props.maxValue) event.target.value = this.props.maxValue;
		this.onChange(event);
	}

	handleDecimal = (event) => {
		event.target.value = event.target.value.replace(/[^0-9.]/g, "");
		if (!event.target.value.length) event.target.value = "0";
		if (!event.target.value.endsWith(".")) {
			event.target.value = String(Number(event.target.value));
		}
		if (this.props.minValue && Number(event.target.value) < this.props.minValue) event.target.value = this.props.minValue;
		if (this.props.maxValue && Number(event.target.value) > this.props.maxValue) event.target.value = this.props.maxValue;
		this.onChange(event);
	}

	handleAlphabetic = (event) => {
		event.target.value = event.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ ]/g, "");
		this.onChange(event);
	}

	handleAlphanumeric = (event) => {
		event.target.value = event.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]/g, "");
		this.onChange(event);
	}

	handleDocument = (event) => {
		event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
		this.onChange(event);
	}

	handleQuerySymbols = (event) => {
		event.target.value = event.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9 +$@#]/g, "");
		this.onChange(event);
	}

	handleLogin = (event) => {
		event.target.value = event.target.value.replace(/[^a-zA-Z0-9\\.\\_@]/g, "");
		this.onChange(event);
	}

	handleUppercase = event => {
		event.target.value = event.target.value.toUpperCase();
		this.onChange(event);
	}

	render() {
		let handleMethod;
		switch (this.props.type) {
			case "uppercase":
				handleMethod = this.handleUppercase;
				break;
			case "timestamp":
				handleMethod = this.handleTimestamp;
				break;
			case "cpf":
				handleMethod = this.handleCPF;
				break;
			case "cnpj":
				handleMethod = this.handleCNPJ;
				break;
			case "telefone":
				handleMethod = this.handleTelefone;
				break;
			case "cep":
				handleMethod = this.handleCEP;
				break;
			case "alphabetic":
				handleMethod = this.handleAlphabetic;
				break;
			case "number":
			case "numeric":
				handleMethod = this.handleNumeric;
				break;
			case "decimal":
				handleMethod = this.handleDecimal;
				break;
			case "alphanumeric":
				handleMethod = this.handleAlphanumeric;
				break;
			case "document":
				handleMethod = this.handleDocument;
				break;
			case "query":
				handleMethod = this.handleQuerySymbols;
				break;
			case "login":
				handleMethod = this.handleLogin;
				break;
			default:
				handleMethod = this.onChange;
				break;
		}
		let component;
		if (this.props.multiline) {
			component = <textarea ref={this.inputRef} className={`ui-tzm-textfield ui-tzm-textarea${this.props.invalid || (this.props.required && (this.props.value == null || this.props.value.length <= (this.props.length ? this.props.length - 1 : 0))) ? " ui-field-required" : ""}`} {...this.props} onChange={handleMethod} />;
		} else {
			component = <input autoComplete={this.props.type === "password" ? "new-password" : "off"} ref={this.inputRef} className={`ui-tzm-textfield${this.props.invalid || (this.props.required && (this.props.value == null || this.props.value.length <= (this.props.length ? this.props.length - 1 : 0))) ? " ui-field-required" : ""}`} {...this.props} type={this.props.type === "password" && !this.state.hide ? "text" : this.props.type } onChange={handleMethod} />
		}
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label help={this.props.help}>{this.props.label}</Label>
				<div className="textfield-container">
					{component}
					{this.props.unit ? <span className="textfield-unit">{this.props.unit}</span> : null}
					{this.props.type === "password" ? <i onClick={this.togglePassword} className={`tzm-password-toggler fa fa-${this.state.hide ? "eye-slash" : "eye"}`} /> : null}
				</div>
			</div>
		);
	}

	togglePassword = () => this.setState({hide: !this.state.hide});

}

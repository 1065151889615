import {Fetch} from "./Fetch";

const basePath = process.env.REACT_APP_API_URL;

export class AppletUtils {

	static downloadFingerprint(pessoa) {
		return Fetch.Get(`${basePath}/applets/fingerprint-${pessoa.id}.jnlp`).then((response) => response.blob().then((blob) => {
			const file = new Blob([blob], {type: "application/x-java-jnlp-file"});
			const data = window.URL.createObjectURL(file);
			window.open(data, "_blank");
		}));
	}

}

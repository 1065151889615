import {LocalDate} from "../DateUtils";
import {MEntidade} from "./MEntidade";

export class MJornadaTrabalho {

	static Modelo() {
		return {
			descricao: "",
			data: LocalDate.now()
		};
	}

	static ModosEtiqueta = [
		{label: "Todas", value: "TODAS"},
		{label: "Qualquer uma", value: "QUALQUER"}
	];

	static cleanupBeforeSave(feriado) {
		return MEntidade.cleanupBeforeSave(feriado);
	}

	static validarParaSalvar(feriado) {
		let messages = [];
		return messages;
	}

}

import React, {Component} from "react";
import {ModalRealm} from "../commons/ModalRealm";
import {DialogContent} from "../commons/DialogContent";
import {TextField} from "../commons/TextField";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {VeiculoFabricanteService} from "../servicos/VeiculoFabricanteService";

export class EditarVeiculoFabricante extends Component {

    state = {
        visible: true,
        fabricante: this.props.fabricante
    };

    veiculoFabricanteService = new VeiculoFabricanteService();

    onClose = () => this.setState({visible: false});

    onSalvar = () => {
        const {fabricante} = this.state;
        const messages = [];
        if (!fabricante.nome?.length) messages.push("O nome do fabricante é obrigatório");
        if (messages.length) {
            ModalRealm.showInformacao("warn", messages);
        } else {
            this.veiculoFabricanteService.salvar(fabricante).then(fabricante => {
                if (this.props.onModalClose) {
                    this.props.onModalClose(fabricante);
                }
                this.onClose();
            });
        }
    }

    handleChange = (event) => {
        const {fabricante} = this.state;
        fabricante[event.name] = event.value;
        this.setState({fabricante});
    }

    render() {
        return (
            <Dialog modal header="Fabricante de Veículo" visible={this.state.visible} style={{width: "450px"}} onHide={this.onClose}>
                <DialogContent>
                    <TextField grid={12} label="Nome" value={this.state.fabricante.nome} onChange={this.handleChange} name="nome"/>
                </DialogContent>
                <DialogFooter>
                    <Action mode="post" label="Salvar" onClick={this.onSalvar} icon="fa-save"/>
                    <Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
                </DialogFooter>
                {this.state.visible ? <Shortcut onCtrlS={this.onSalvar} onEscape={this.onClose}/> : null}
            </Dialog>
        );
    }

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MContaEmail} from "../../utils/models/MContaEmail";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {ContaEmailService} from "../servicos/ContaEmailService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MultiCombobox} from "../commons/MultiCombobox";

function fillNulls(contaEmail) {
	if (!contaEmail) {
		contaEmail = MContaEmail.Modelo();
	}
	return contaEmail;
}

export class EditarContaEmail extends React.Component {

	state = {
		contaEmail: fillNulls(this.props.contaEmail),
		visible: true,
		activeIndex: 0
	};

	contaEmailService = new ContaEmailService();

	security = UserData.security("CFG", true);

	onClose = (contaEmail) => {
		if (contaEmail && this.props.onModalClose) {
			this.props.onModalClose(contaEmail);
		}
		this.setState({visible: false});
	}

	saveContaEmail = () => {
		if (this.security.edit) {
			let contaEmail = MContaEmail.cleanupBeforeSave(Object.assign({}, this.state.contaEmail));
			let messages = MContaEmail.validarParaSalvar(contaEmail);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.contaEmailService.salvar(contaEmail).then((contaEmail) => {
					if (contaEmail) this.onClose(contaEmail);
				});
			}
		}
	}

	handleChange = (event) => {
		let contaEmail = this.state.contaEmail;
		contaEmail[event.name] = event.value;
		this.setState({contaEmail});
	}

	opcoesContas = [
		{label: "Habilitar SSL", value: "ENABLE_SSL"}
	];

	render() {
		return (
			<Dialog modal header="Conta de Email" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox appendTo={document.body} options={MContaEmail.Finalidades} grid={4} name="finalidade" label="Finalidade" value={this.state.contaEmail.finalidade} onChange={this.handleChange}/>
					<TextField grid={6} name="servidor" label="Servidor" value={this.state.contaEmail.servidor} onChange={this.handleChange}/>
					<TextField grid={2} type="numeric" name="porta" label="Porta" value={this.state.contaEmail.porta} onChange={this.handleChange}/>
					<TextField grid={12} name="nome" label="Nome" value={this.state.contaEmail.nome} onChange={this.handleChange}/>
					<TextField grid={6} name="usuario" label="Email" value={this.state.contaEmail.usuario} onChange={this.handleChange}/>
					<TextField grid={6} type="password" name="senha" label="Senha" value={this.state.contaEmail.senha} onChange={this.handleChange}/>
					<MultiCombobox defaultLabel="Nenhuma" grid={12} options={this.opcoesContas} label="Opções" value={this.state.contaEmail.flags} name="flags" onChange={this.handleChange}/>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.contaEmail.id ?
						<Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock"
						        onClick={() => EntidadeNextGenService.listarHistorico("/contas-email", this.state.contaEmail)}/> : null}
					<Action mode="post" label="Salvar" disabled={!this.security.edit} onClick={this.saveContaEmail}
					        icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={() => this.onClose()}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.saveContaEmail} onEscape={() => this.onClose()}/> : null}
			</Dialog>
		);
	}

}

import React from "react";
import {DialogContent} from "./DialogContent";
import {DialogFooter} from "./DialogFooter";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {httpGet, httpPost} from "../../utils/Request";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Action} from "./Action";
import "./FacialDialog.css";
import { toSrcUltimaFoto} from "../../utils/StringUtils";
import iconeReload from "../../media/icones/reutilizar.png";
import iconeCamera from "../../media/icones/cameras.png";
import {ModalRealm} from "./ModalRealm";
import {Foto} from "../Foto";
import moment from "moment";

function printPessoa(pessoa) {
    return (
        <div className="panel-pessoa">
            <div>
                <img alt="" className="mini-photo" src={toSrcUltimaFoto(pessoa.pessoa.ultimaFoto_)}/>
            </div>
            <div>
                <b>{pessoa.pessoa.nome}</b><br/>
                {pessoa.pessoa.cpf || pessoa.pessoa.rg || pessoa.pessoa.passaporte || pessoa.pessoa.nis}
            </div>
        </div>
    );
}

function printResultado(resultado) {
    let src, className;
    switch (resultado.status) {
        case "SUCESSO":
            src = "fal fa-check";
            className = "success";
            break;
        case "FALHA":
            src = "fal fa-times";
            className = "failure";
            break;
        default:
            className = "";
            src = "fal fa-spinner fa-spin";
            break;
    }
    return (
        <div className={`result-status ${className}`}>
            <div>
                <span><i className={src}/></span>
            </div>
            <div>
                <div style={{fontWeight: "bold"}}>{resultado.equipamento.descricao}</div>
                <div>{resultado.mensagem}</div>
            </div>
        </div>
    );
}

function printMessages(response) {
    return response.mensagem?.split(";").map(m => m.trim()).filter(m => m.length).map(m => <div className="response">{m}</div>);
}

export class FacialDialog extends React.Component {

    state = {
        visible: true,
        pessoas: []
    };

    pessoaService = new PessoaFisicaService();

    componentDidMount() {
        const pessoas = this.props.pessoas.map(pessoa => ({
            pessoa,
            resultados: this.props.equipamentos.map(equipamento => ({equipamento, status: "PENDENTE", mensagem: "Aguarde. Processando..."}))
        }));
        this.setState({pessoas}, () => {
            this.state.pessoas.forEach(pessoa => this.cadastrarFacial(pessoa.pessoa));
        });
    }

    cadastrarFacial = pessoa => {
        const {pessoas} = this.state;
        pessoas.filter(p => p.pessoa.id === pessoa.id).forEach(p => p.resultados.forEach(r => {
            r.status = "PENDENTE";
            r.mensagem = "Por favor, aguarde. Enviando...";
        }));
        this.setState({pessoas},  () => {
            httpGet(`/credenciais/facial/${pessoa.id}`).then(() => {
                for (const equipamento of this.props.equipamentos) {
                    httpPost("/faciais", {pessoa, equipamento}).then(response => {
                        const {pessoas} = this.state;
                        const pessoaID = pessoas.findIndex(p => p.pessoa.id === pessoa.id);
                        const resultadoID = pessoas[pessoaID].resultados.findIndex(r => r.equipamento.id === response.equipamentoId);
                        pessoas[pessoaID].resultados[resultadoID].status = response.status;
                        if (response.status === "SUCESSO") {
                            pessoas[pessoaID].resultados[resultadoID].mensagem = "Sincronização concluída com sucesso";
                        } else {
                            pessoas[pessoaID].resultados[resultadoID].mensagem = printMessages(response);
                        }
                        this.setState({pessoas}, () => {
                            if (this.state.pessoas.every(p => p.resultados.every(r => r.status === "SUCESSO"))) {
                                setTimeout(this.handleModalClose, 1000);
                            }
                        });
                    });
                }
            });
        });
    }

    recadastrarFacial = pessoa => {
        this.pessoaService.buscar(pessoa).then((pessoa) => {
            let width = Math.round(document.body.clientWidth * .3);
            let height = Math.round(width * .75);
            ModalRealm.showDialog(
                <Foto
                    key={Math.random()}
                    fotos={pessoa.fotos}
                    width={width}
                    height={height}
                    onModalClose={fotos => {
                        pessoa.fotos = fotos;
                        pessoa.ultimaAtualizacao = moment().format(
                            "YYYY-MM-DDTHH:mm:ss"
                        )
                        this.pessoaService.salvar(pessoa).then(this.cadastrarFacial)
                    }}
                />
            );
        });
    }

    handleClose = () => {
        this.setState({visible: false});
    }

    handleModalClose = () => {
        if (this.props.onModalClose) {
            this.props.onModalClose();
        }
        this.handleClose();
    }

    printActions = pessoa => {
        const notReady = !pessoa.resultados.some(r => r.status === "PENDENTE");
        return (
            <div>
                <img style={notReady ? {} : {filter: "grayscale(1)", opacity: .5}} title="Tentar Enviar Novamente" alt="" className="ui-action-icon" src={iconeReload} onClick={() => notReady && this.cadastrarFacial(pessoa.pessoa)}/>
                <img style={notReady ? {} : {filter: "grayscale(1)", opacity: .5}} title="Tirar Fotografia" alt="" className="ui-action-icon" src={iconeCamera} onClick={() => notReady && this.recadastrarFacial(pessoa.pessoa)}/>
            </div>
        );
    }

    render() {
        return (
            <Dialog modal className="facial-dialog" style={{width: "1100px"}} visible={this.state.visible} header="Sincronização de Biometria Facial" onHide={this.handleClose}>
                <DialogContent>
                    <div style={{paddingBottom: "1em"}}>Por favor, acompanhe o envio e verifique as mensagens abaixo:</div>
                    <div className="p-col-12">
                        <DataTable value={this.state.pessoas}>
                            <Column style={{width: "25em"}} header="Pessoa" body={printPessoa}/>
                            <Column header="Equipamentos" body={p => p.resultados.map(printResultado)}/>
                            <Column style={{width: "6em", textAlign: "center"}} header="Ações" body={this.printActions}/>
                        </DataTable>
                    </div>
                </DialogContent>
                <DialogFooter>
                    <Action disabled={this.state.pessoas.some(p => p.resultados.some(r => r.status === "PENDENTE"))} label="Fechar" icon="fas fa-times" onClick={this.handleClose}/>
                </DialogFooter>
            </Dialog>
        );
    }

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MultiSelectEquipamento} from "../../select/MultiSelectEquipamento";
import {MultiSelectLugar} from "../../select/MultiSelectLugar";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MBloqueio} from "../../utils/models/MBloqueio";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {BloqueioService} from "../servicos/BloqueioService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {ConfirmarBloqueio} from "./ConfirmarBloqueio";

export class EditarBloqueio extends React.Component {

	state = {
		bloqueio: Object.assign(MBloqueio.Modelo(), this.props.bloqueio),
		visible: true,
		lockSave: false
	};

	bloqueioService = new BloqueioService();
	security = UserData.security("BLK", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarBloqueio = () => {
		if (this.security.edit) {
			let bloqueio = MBloqueio.cleanupBeforeSave(Object.assign({}, this.state.bloqueio));
			let messages = MBloqueio.validarParaSalvar(bloqueio);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (!bloqueio.pessoa && (!bloqueio.lugares || !bloqueio.lugares.length) && (!bloqueio.equipamentos || !bloqueio.equipamentos.length)) {
					ModalRealm.showInformacao("warn", ["Não é possível criar um bloqueio sem definir ao menos uma pessoa, equipamento ou lugar."]);
				} else {
					if (!bloqueio.pessoa) {
						ModalRealm.showDialog(<ConfirmarBloqueio onYes={() => {
							this.salvar(bloqueio);
						}} />);
					} else {
						this.salvar(bloqueio);
					}
				}
			}
		}
	}

	salvar = (bloqueio) => {
		this.setState({lockSave: true});
		this.bloqueioService.salvar(bloqueio).then((bloqueio) => {
			this.setState({lockSave: false});
			if (this.props.onModalClose) {
				this.props.onModalClose(bloqueio);
			}
			this.onClose();
		}).catch(() => this.setState({lockSave: false}));
	}

	handleChange = (event) => {
		let bloqueio = this.state.bloqueio;
		bloqueio[event.name] = event.value;
		this.setState({bloqueio});
	}

	render() {
		return (
			<Dialog modal header="Bloqueio" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<SelectPessoaFisica required={this.state.bloqueio.tipo !== "SEGURANCA"} grid={12} label="Pessoa" name="pessoa" onChange={this.handleChange} value={this.state.bloqueio.pessoa} />
					<DatePicker showTime showSeconds appendTo={document.body} grid={4} label="Início" onChange={this.handleChange} name="inicio" value={this.state.bloqueio.inicio} />
					<DatePicker showTime showSeconds appendTo={document.body} grid={4} label="Fim" onChange={this.handleChange} name="fim" value={this.state.bloqueio.fim} />
					<Combobox grid={4} label="Tipo" appendTo={document.body} name="tipo" options={MBloqueio.Tipos} onChange={this.handleChange} value={this.state.bloqueio.tipo} />
					<MultiSelectLugar grid={12} label="Lugares" appendTo={document.body} name="lugares" onSelect={this.handleChange} value={this.state.bloqueio.lugares} />
					<MultiSelectEquipamento grid={12} label="Equipamentos" appendTo={document.body} name="equipamentos" onSelect={this.handleChange} value={this.state.bloqueio.equipamentos} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.bloqueio.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/bloqueios", this.state.bloqueio)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.bloqueio.id)) || this.state.lockSave} onClick={this.salvarBloqueio} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarBloqueio} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React from "react";
import {ComboboxEquipamentoFormulario} from "../../select/ComboboxEquipamentoFormulario";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {ModalRealm} from "../commons/ModalRealm";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {DeclaracaoPessoalSaude} from "./forms/DeclaracaoPessoalSaude";
import iconeEquipamento from "../../media/icones/equipamentos.png";
import {httpDelete, httpPost} from "../../utils/Request";

export class TabletFormulario extends React.Component {

    state = {
        equipamento: null,
        requisicoes: []
    };

    equipamentoService = new EquipamentoService();

    componentDidMount() {
        const id = window.localStorage.getItem("X-Last-Selected-Form-Device");
        if (id) {
            this.equipamentoService.buscar({id}).then(equipamento => this.setState({equipamento}));
        }
    }

    handleChange = e => this.setState({[e.name]: e.value});

    handleWebsocket = requisicao => {
        if (requisicao.equipamento?.id === this.state.equipamento?.id) {
            const {requisicoes} = this.state;
            requisicao.key = Math.random();
            requisicoes.push(requisicao);
            this.setState({requisicoes});
        }
    }

    selecionarEquipamento = () => {
        ModalRealm.showDialog(<SelecionarEquipamento onModalClose={equipamento => this.setState({equipamento})}/>);
    }

    handlePost = async payload => {
        await httpPost(`/formularios/tablet/answer`, payload);
        ModalRealm.showInformacao("info", [`Formulário aceito!${this.state.requisicoes.length > 1 ? " Por favor, entregue para a próxima pessoa responder." : ""}`]);
        this.handleDiscard(false);
    }

    handleDiscard = (discarded = true) => {
        const {requisicoes} = this.state;
        const requisicao = requisicoes.shift();
        this.setState({requisicoes}, async () => {
            if (discarded) {
                await httpDelete(`/formularios/tablet/${requisicao.id}`);
            }
        });
    }

    printFormulario = () => {
        if (this.state.requisicoes.length) {
            switch (this.state.requisicoes[0].tipo.formulario.chaveIntegracao) {
                case "CACIQUE-DPS":
                    return <DeclaracaoPessoalSaude key={this.state.requisicoes[0].key} requisicao={this.state.requisicoes[0]} onPost={this.handlePost} onDiscard={this.handleDiscard}/>;
                default:
                    return (
                        <div className="waiting-form">
                            <div style={{textAlign: "center"}}>
                                <i className="fal fa-exclamation-triangle"/> Formulário {this.state.requisicoes[0].tipo.formulario.descricao} incompatível<br/>
                                <u style={{cursor: "pointer"}} onClick={this.handleDiscard}>Clique para Descartá-lo</u>
                            </div>
                        </div>
                    );
            }
        } else {
            return (
                <div className="waiting-form">
                    <div><i className="fal fa-exclamation-triangle"/> Nenhum formulário pendente</div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="ui-g">
                {this.printFormulario()}
                <div className="tablet-control-container">
                    <div className="select-device-button" onClick={this.selecionarEquipamento}><img className="ui-action-icon" src={iconeEquipamento} alt=""/> {this.state.equipamento?.descricao || "Escolha o Equipamento"}</div>
                    {this.state.requisicoes?.length > 1 ? <div className="form-counter">{this.state.requisicoes?.length} formulários enfileirados</div> : null}
                </div>
                <SafetyWebSocket key={this.state.equipamento?.id} identifier={this.state.equipamento?.id} path="/formularios" onMessage={this.handleWebsocket}/>
                <ModalRealm/>
            </div>
        );
    }

}

class SelecionarEquipamento extends React.Component {

    state = {
        visible: true,
        equipamento: null
    };

    onClose = () => this.setState({visible: false});

    handleChange = e => {
        if (e.automatic) {
            this.setState({[e.name]: e.value});
            return;
        }
        if (this.props.onModalClose) {
            this.props.onModalClose(e.value);
        }
        this.onClose();
    }

    render() {
        return (
            <Dialog modal visible={this.state.visible} header="Selecione o Equipamento" style={{width: "450px"}} onHide={this.onClose}>
                <DialogContent>
                    <ComboboxEquipamentoFormulario grid={12} label="Equipamento" value={this.state.equipamento} onChange={this.handleChange}/>
                </DialogContent>
                <DialogFooter>
                    <Action secondary label="Fechar" onClick={this.onClose}/>
                </DialogFooter>
            </Dialog>
        );
    }

}

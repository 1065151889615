import {EntidadeNextGenService} from "./EntidadeNextGenService";

const diasSemana = [
	"SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"
];

const mapDiasSemana = {
	"SUNDAY": "domingo",
	"MONDAY": "segunda",
	"TUESDAY": "terça",
	"WEDNESDAY": "quarta",
	"THURSDAY": "quinta",
	"FRIDAY": "sexta",
	"SATURDAY": "sábado"
};

function sortDiasSemana(array) {
	if (array && array.length > 0) {
		return array.sort((a, b) => diasSemana.indexOf(a) - diasSemana.indexOf(b));
	}
	return array;
}

export function simplificarDiasSemana(periodo) {
	if (periodo.diasSemana && periodo.diasSemana.length === diasSemana.length && sortDiasSemana(periodo.diasSemana).every((value, index) => diasSemana[index] === value)) {
		return "todos os dias";
	}
	if (periodo.diasSemana && periodo.diasSemana.length > 0) {
		let index = 0;
		let sorted = sortDiasSemana(periodo.diasSemana);
		let groups = [[sorted[0]]];
		for (let i = 1; i < sorted.length; ++i) {
			if (diasSemana.indexOf(groups[index][groups[index].length - 1]) + 1 === diasSemana.indexOf(sorted[i])) {
				groups[index].push(sorted[i]);
			} else {
				groups[++index] = [sorted[i]];
			}
		}
		return groups.map((group) => group.length > 1 ? `de ${mapDiasSemana[group[0]]} à ${mapDiasSemana[group[group.length - 1]]}` : mapDiasSemana[group[0]]).join(", ");
	}
	return periodo.diasSemana ? periodo.diasSemana.map((diaSemana) => mapDiasSemana[diaSemana]).join(", ") : null;
}


export class PeriodoService extends EntidadeNextGenService {

	diasSemana = diasSemana;

	mapDiasSemana = mapDiasSemana;

	constructor() {
		super("/periodos");
	}

	sortDiasSemana = sortDiasSemana;

	simplificarDiasSemana = simplificarDiasSemana;

}

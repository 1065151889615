import React from "react";

export class DialogContent extends React.Component {

	render() {
		return (
			<div className="ui-tzm-dialogcontent" {...this.props}>
				<div className="ui-g">{this.props.children}</div>
			</div>
		);
	}

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MensagemService} from "../servicos/MensagemService";
import {EditarMensagem} from "./EditarMensagem";

export class Mensagem extends React.Component {

	state = {
		texto: "",
		pessoa: "",
		firstResult: 0
	};

	mensagemService = new MensagemService();
	security = UserData.security("MSG", true);

	listarMensagens = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let add = Validator.isEmpty(this.state.pessoa) ? "" : `&pessoa=nome,rg,cpf,passaporte~${this.state.pessoa}`;
			this.mensagemService.listar(`search=texto~${this.state.texto}${this.state.excluidos ? "" : ";status:ATIVADO"}${add}`, {page: 0, size: GUI.maxResults}).then((mensagens) => this.setState({mensagens, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarMensagem = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.mensagemService.buscar(rowData).then((mensagem) => {
				ModalRealm.showDialog(<EditarMensagem key={Math.random()} mensagem={mensagem} onModalClose={this.listarMensagens} />);
			});
		} else {
			ModalRealm.showDialog(<EditarMensagem key={Math.random()} mensagem={null} onModalClose={this.listarMensagens} />);
		}
	}

	excluirMensagem = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta mensagem?", () => this.mensagemService.excluir(rowData).then(this.listarMensagens));
	}

	restaurarMensagem = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este mensagem?", () => {
			if (rowData != null && rowData.id != null) {
				this.mensagemService.buscar(rowData).then((mensagem) => {
					mensagem.status = "ATIVADO";
					this.mensagemService.salvar(mensagem).then(this.listarMensagens);
				});
			}
		});
	}

	eliminarMensagem = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este mensagem?", () => this.mensagemService.eliminar(rowData).then((result) => {
			if (result) this.listarMensagens(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este mensagem: há outros registros dependentes dele no sistema."]);
		}));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column header="Texto"  style={{width: "*"}} body={(mensagem) => {
			if (Validator.isEntidade(mensagem.foto)) {
				return `<imagem>${!Validator.isEmpty(mensagem.texto) ? ` ${mensagem.texto}` : ""}`;
			} else return mensagem.texto;
		}} />,
		<Column header="Início" style={{width: "12em"}} body={(rowData) => rowData.inicio != null ? moment(rowData.inicio).format("DD/MM/YYYY HH:mm") : null} />,
		<Column header="Fim"  style={{width: "12em"}} body={(rowData) => rowData.fim != null ? moment(rowData.fim).format("DD/MM/YYYY HH:mm") : null} />,
		<Column header="Pessoa" style={{width: "20em"}} body={(rowData) => rowData.pessoa != null ? rowData.pessoa.nome : null} />,
		<Column header="Etiqueta" style={{width: "10em"}} body={(rowData) => rowData.etiqueta != null ? rowData.etiqueta.descricao : null} />,
		<Column header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Mensagem" onClick={(event) => this.editarMensagem(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Mensagem" onClick={(event) => this.excluirMensagem(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Mensagem" onClick={(event) => this.restaurarMensagem(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Mensagem" onClick={(event) => this.eliminarMensagem(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/mensagens", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Mensagens">
					<PanelContent>
						<TextField grid={6} label="Texto" autofocus name="texto" value={this.state.texto} onChange={this.handleUpdate} />
						<TextField grid={6} label="Pessoa" placeholder="Nome, RG, CPF ou passaporte" name="pessoa" value={this.state.pessoa} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarMensagens} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.mensagens} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Mensagem" icon="fa-plus" onClick={this.editarMensagem} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarMensagens}
					onCtrlN={() => window.location.pathname === "/mensagens" && this.security.create && this.editarMensagem()}
					onCtrlE={() => window.location.pathname === "/mensagens" && this.state.mensagens.length === 1 && this.security.edit && this.editarMensagem(this.state.mensagens[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

import React from "react";
import {httpGet} from "../utils/Request";

const styles = {
	contentContainer: {
		marginTop: "46px",
		position: "relative"
	},
	contentContainerBlock: {
		position: "relative",
		padding: "8px 8px 16px 8px"
	}
};

const sidebarAdjust = [
	{width: "calc(100% - 11px)", marginLeft: "6px", position: "fixed", maxHeight: "calc(100% - 76px)", overflowY: "hidden"},
	{width: "calc(100% - 47px)", marginLeft: "42px"},
	{width: "calc(100% - 261px)", marginLeft: "256px"}
];

class Root extends React.Component {

	state = {
		locked: false
	};

	checkDatabaseTimeout = null;

	componentDidMount() {
		this.checkDatabaseTimeout = setInterval(this.checkDatabase, 30000);
	}

	componentWillUnmount() {
		clearInterval(this.checkDatabaseTimeout);
	}

	checkDatabase = () => {
		httpGet("/watchdog/is-locked", null, {progress: false}).then(locked => this.setState({locked}));
	}

	render() {
		let contentContainerStyle = {
			...styles.contentContainer,
			...sidebarAdjust[this.props.sidebar + 1]
		};
		return (
			<div className="content-container" style={contentContainerStyle}>
				{this.state.locked ? <DatabaseIsOffline/> : null}
				<div className="content-container-block" style={styles.contentContainerBlock}>
					{this.props.children}
				</div>
			</div>
		);
  	}

}

class DatabaseIsOffline extends React.Component {

	render() {
		return (
			<div className="db-offline-panel">O banco de dados da aplicação está offline. Todos os recursos do sistema estarão indisponíveis até que isso seja resolvido.</div>
		);
	}

}

export default Root;

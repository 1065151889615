import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarLugar} from "../components/Lugar/EditarLugar";
import {LugarService} from "../components/servicos/LugarService";
import {MLugar} from "../utils/models/MLugar";
import {UserData} from "../utils/UserData";
import {MultiSelect} from "./MultiSelect";

export class MultiSelectLugar extends React.Component {

	state = {
		lugares: [],
		modelo: MLugar.Modelo()
	};

	security = UserData.security("ETQ");

	service = new LugarService();

	onEdit = (lugar) => {
		if (this.security.edit) {
			this.service.buscar(lugar).then((entidade) => {
				ModalRealm.showDialog(<EditarLugar key={Math.random()} lugar={entidade} onModalClose={(lugar) => {
					let lugares = this.props.value;
					let index = lugares.map(e => e.id).indexOf(lugar.id);
					lugares[index] = lugar;
					this.props.onSelect({name: this.props.name, value: lugares});
				}} />);
			});
		}
	}

	onSelect = (event) => {
		let lugares = this.props.value || [];
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarLugar key={Math.random()} lugar={{...MLugar.Modelo(), descricao: event.value.descricao.replace(`Criar lugar `, "")}} onModalClose={(lugar) => {
				lugares.push(lugar);
				this.props.onSelect({name: this.props.name, value: lugares});
			}} />);
		} else {
			if (!lugares.map(e => e.id).includes(event.value.id)) {
				lugares.push(event.value);
			}
			this.props.onSelect({name: this.props.name, value: lugares});
		}
	}

	onChange = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect(event);
		}
	}

	render() {
		return <MultiSelect onChange={this.onChange} canInsert={this.security.create} entidade="lugar" service={this.service} modelo={this.state.modelo} onEdit={this.onEdit} field="descricao" query="descricao" {...this.props} onSelect={this.onSelect} />;
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MBloqueio {

	static Modelo() {
		return {
			tipo: "SEGURANCA"
		};
	}

	static cleanupBeforeSave(bloqueio) {
		if (bloqueio.pessoa != null && !Validator.isEntidade(bloqueio.pessoa)) bloqueio.pessoa = null; 
		return MEntidade.cleanupBeforeSave(bloqueio);
	}

	static validarParaSalvar(bloqueio) {
		let messages = [];
		if (bloqueio.inicio && bloqueio.fim && bloqueio.inicio.localeCompare(bloqueio.fim) > 0) {
			messages.push("A data de fim não pode ser menor que a data de início do bloqueio");
		}
		if (bloqueio.tipo !== "SEGURANCA" && !Validator.isEntidade(bloqueio.pessoa)) {
			messages.push("A pessoa é obrigatória para bloqueios que não sejam de segurança");
		}
		return messages;
	}

	static Tipos = [
		{label: "Segurança", value: "SEGURANCA"},
		{label: "Intervalo", value: "INTERVALO"},
		{label: "Interjornada", value: "INTERJORNADA"}
	];

}

import React from "react";

export class DialogFooter extends React.Component {

	render() {
		return (
			<div {...this.props} className={`ui-tzm-dialog-footer ${this.props.className}`} />
		);
	}

}

import {MPerfil} from "./models/MPerfil";

export class UserData {

	static user = {};

	static usuario = {};

	static operador = {};

	static securities = {};

	static setUser(user) {
		UserData.user = user;
		if (user && user.authorities) {
			let authorities = user.authorities.map(a => a.authority);
			Object.keys(MPerfil.Nomes).forEach(prefix => {
				UserData.securities[prefix] = {
					create: authorities.includes("ROLE_" + prefix + "C"),
					edit: authorities.includes("ROLE_" + prefix + "E"),
					view: authorities.includes("ROLE_" + prefix + "V"),
					remove: authorities.includes("ROLE_" + prefix + "R")
				};
			});
		}
	}

	static hasRole(role) {
		return UserData.user && UserData.user.authorities.includes(`ROLE_${role}`);
	}

	static security(prefix, kick) {
		if (UserData.securities != null) {
			let permissions = UserData.securities[prefix];
			if ((!permissions || !permissions.view) && kick) window.location.replace("/");
			return permissions;
		} else window.location.replace("/");
	}

	static edit(type) {
		return UserData.securities[type] && UserData.securities[type].edit;
	}

}

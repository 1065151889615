import React from "react";
import {SuggestionChip} from "../../components/commons/SuggestionBox";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MEtiqueta {

	static Modelo() {
		return {
			descricao: "",
			fundo: "#DDDDDD",
			fonte: "#000000",
			padrao: 0,
			flags: []
		};
	}

	static cleanupBeforeSave(etiqueta) {
		return MEntidade.cleanupBeforeSave(etiqueta);
	}

	static validarParaSalvar(etiqueta) {
		let messages = [];
		if (Validator.isEmpty(etiqueta.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

	static Flags = [
		{label: "Acesso", value: "ACESSO"},
		{label: "Exclusiva", value: "EXCLUSIVA"},
		{label: "Unidade", value: "UNIDADE"},
		{label: "Serviço", value: "SERVICO"},
		{label: "Ponto", value: "PONTO"},
		{label: "Portaria", value: "PORTARIA"}
	];

	static Efeitos = [
		{label: "Nenhum", value: 0},
		{label: "Saturação", value: 1},
		{label: "Sépia", value: 2},
		{label: "Multicores", value: 3},
		{label: "Negativo", value: 4},
		{label: "Tons de Cinza", value: 5}
	];

	static Chip = (value, clickCallback, removeCallback, field = "descricao", disabled = false) => {
		return <SuggestionChip disabled={disabled} key={Math.random()} label={field} className={`ui-pattern-${value.padrao} ui-pattern-a${value.efeito}`} onClick={clickCallback} onRemove={removeCallback} value={value} style={{backgroundColor: value.fundo, color: value.fonte}} />;
	};

}

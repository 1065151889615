export class MTexto {

    static Modelo() {
        return {
            finalidade: null,
            texto: ""
        };
    }

    static MFinalidade = [
        {label: "Tablet: Credencial Inativa", value: "TABLET_CREDENCIAL_INATIVA"},
    ];

    static MapFinalidade = {
        "TABLET_CREDENCIAL_INATIVA": "Tablet: Credencial Inativa"
    };

}

export class MMonitoramento {

	static Modelo() {
		return {
			atividades: [],
			respostas: [],
			lugares: [],
			pessoas: [],
			equipamentos: [],
			monitores: [],
			periodos: [],
			disparos: [],
			eventos: [],
			flags: []
		};
	}

	static Disparos = [
		{label: "Email", value: "EMAIL"},
		{label: "SMS", value: "SMS"},
		{label: "Foto", value: "FOTO"},
		{label: "Popup de Mensagem", value: "WEBFRONTEND"},
		{label: "Mensagem de Telegram™", value: "TELEGRAM"},
		{label: "Mensagem de WhatsApp™", value: "WHATSAPP"},
	];

	static MapDisparos = {
		"EMAIL": "Email",
		"SMS": "SMS",
		"FOTO": "Foto",
		"WEBFRONTEND": "Popup de Mensagem",
		"TELEGRAM": "Mensagem de Telegram™",
		"WHATSAPP": "Mensagem de WhatsApp™"
	};

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {DataView} from "primereact/components/dataview/DataView";
import {Panel} from "primereact/components/panel/Panel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import ResizeObserver from "react-resize-observer";
import iconeLancarEntrada from "../../media/icones/lancar-entrada.png";
import iconeLancarSaida from "../../media/icones/lancar-saida.png";
import iconeRele from "../../media/icones/rele.png";
import {SelectLugar} from "../../select/SelectLugar";
import {SelectVeiculo} from "../../select/SelectVeiculo";
import {MDispositivo} from "../../utils/models/MEquipamento";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {
	byDescricao,
	byRegistroDesc,
	isFinalidadeIdentificacao,
	logAcessoRespostaSimples,
	simplificarNome,
	StringUtils,
	toSrc
} from "../../utils/StringUtils";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PainelAcesso} from "../commons/PainelAcesso";
import {PainelOcorrencia} from "../commons/PainelOcorrencia";
import {PanelContent} from "../commons/PanelContent";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {AvisoService} from "../servicos/AvisoService";
import {BloqueioService} from "../servicos/BloqueioService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {GuaritaService} from "../servicos/GuaritaService";
import {PortariaService} from "../servicos/PortariaService";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {UnidadeService} from "../servicos/UnidadeService";
import {EditarUnidade} from "../Unidade/EditarUnidade";
import {Fetch} from "../../utils/Fetch";
import "./index.css";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {LogAcessoService} from "../servicos/LogAcessoService";
import {MLogAcesso} from "../../utils/models/MLogAcesso";
import {isLogAcessoNotBefore5Minutes} from "../../utils/DateUtils";
import {isDentro, isDentroOuGaragem} from "../../utils/PessoaFisicaUtils";
import {ChipAviso} from "../commons/ChipAviso";

const DANGER_STYLE = [
	"BLOQUEIO",
	"EQUIPAMENTO",
	"LEITOR",
	"ETIQUETA",
	"TEMPERATURA",
	"DISPOSITIVO",
	"DESTINO",
	"PESSOA"
];

const familiasAcionamento = [
	"NEOKOROS",
	"NEOKOROS_GAM",
	"CONTROLID_FIT",
	"CONTROLID_FLEX",
	"CONTROLID_BLOCK",
	"CONTROLID_BLOCK_NEXT",
	"CONTROLID_ACCESS",
	"CONTROLID_FACE",
	"CONTROLID_UHF",
	"INTELBRAS_FRT"
];

export class Guarita extends React.Component {

	state = {
		etiquetas: [],
		pessoa: "",
		posicao: "TODOS",
		pessoas: [],
		categorias: [],
		selectedTab: 0,
		leftPanelWidth: 0,
		painelExpanded: false,
		tabIndex: 0,
		acionamentos1: [],
		acionamentos2: [],
		acessosGerenciaveis: [],
		outrosAcessos: []
	};

	guaritaService = new GuaritaService();
	etiquetaService = new EtiquetaService();
	setupCondominioService = new SetupCondominioService();
	logAcessoService = new LogAcessoService();
	portariaService = new PortariaService();
	avisoService = new AvisoService();
	bloqueioService = new BloqueioService();
	unidadeService = new UnidadeService();
	equipamentoService = new EquipamentoService();

	securityUnidade = UserData.security("UNI");
	securityAviso = UserData.security("AVI");

	componentDidMount() {
		this.equipamentoService.me().then((equipamento) => {
			this.setupCondominioService.buscar().then(setupCondominio => {
				if (setupCondominio.categoriasGuarita != null) {
					let categorias = setupCondominio.categoriasGuarita.sort((a, b) => a.descricao.localeCompare(b.descricao));
					let categoria = categorias.length > 0 ? setupCondominio.categoriasGuarita[0] : null;
					this.setState({setupCondominio, categorias: setupCondominio.categoriasGuarita, categoria});
				}
			});
			this.portariaService.listar(``, {page: 0, size: 10}).then(portarias => {
				const etiquetasOperador = UserData.operador.etiquetas;
				if (etiquetasOperador?.length && etiquetasOperador.some(e => e.flags.includes("PORTARIA"))) {
					const ids = etiquetasOperador.filter(e => e.flags.includes("PORTARIA")).map(e => e.id);
					portarias = portarias.filter(p => p.etiquetas?.some(e => ids.includes(e.id)));
				}
				if (portarias.length) {
					let ultimaPortaria = window.localStorage.getItem("safety-guarita-portaria-last");
					let ultimoSetor = window.localStorage.getItem("safety-guarita-setor-last");
					let portaria, setor;
					if (ultimaPortaria) {
						portarias.filter(p => p.id === Number(ultimaPortaria)).forEach(p => portaria = p);
					}
					if (!portaria) {
						portaria = portarias[0];
					}
					if (ultimoSetor && portaria) {
						portaria.setores.filter(s => s.id === Number(ultimoSetor)).forEach(s => setor = s);
					}
					if (!setor && portaria) {
						setor = portaria.setores[0];
					}
					equipamento = equipamento?.id ? equipamento : portaria.equipamentoPadrao;
					let setores = portaria?.setores?.sort(byDescricao).map(s => ({label: s.descricao, value: s})) || [];
					portarias = portarias.sort(byDescricao).map(p => ({label: p.descricao, value: p}));
					this.setState({portaria, portarias, setor, setores, equipamento});
					setTimeout(() => {
						this.switchPortaria({value: portaria});
						this.switchSetor({value: setor});
					}, 50);
				}
			});			
			this.equipamentoService.listar(`search=familia:${familiasAcionamento};status:ATIVADO&controlador=id!null&sort:descricao`, {page: 0, size: 100}).then((acionamentos) => {
				const acionamentos1 = acionamentos.filter((a) => acionamentos.indexOf(a) % 2 === 0);
				const acionamentos2 = acionamentos.filter((a) => acionamentos.indexOf(a) % 2 === 1);
				this.setState({acionamentos1, acionamentos2});
			});
			this.setState({painelExpanded: window.localStorage.getItem("safety-painelacesso") === "true"});
			this.logAcessoInterval = setInterval(() => {
				let {outrosAcessos} = this.state;
				outrosAcessos = outrosAcessos.filter(isLogAcessoNotBefore5Minutes);
				this.setState({outrosAcessos});
			}, 60000);
		});
	}

	switchPortaria = (event) => {
		if (event.value) {
			window.localStorage.setItem("safety-guarita-portaria-last", event.value.id);
		} else {
			window.localStorage.removeItem("safety-guarita-portaria-last");
		}
	}

	switchSetor = (event) => {
		if (event.value) {
			window.localStorage.setItem("safety-guarita-setor-last", event.value.id);
		} else {
			window.localStorage.removeItem("safety-guarita-setor-last");
		}
	}

	componentWillUnmount() {
		clearInterval(this.logAcessoInterval);
	}

	listarUnidades = (event) => {
		this.unidadeService.listar(`etiqueta=descricao~${event.query}`, {page: 0, size: 10, sort: "descricao"}).then((unidades) => this.setState({setiquetas: unidades.map(u => u.etiqueta)}));
	}

	confirmarAcesso = (criterio) => {
		return this.portariaService.lancarAcesso(criterio).then((comando) => comando);
	}

	confirmarAcessos = (destino, selecionados, index) => {
		index = index != null ? index : 0;
		return this.confirmarAcesso({lugar: this.state.lugar, equipamento: this.state.equipamento, etiquetas: selecionados[index].etiquetas, destino, pessoa: selecionados[index]}).then(() => {
			if (selecionados.length > ++index) {
				return this.confirmarAcessos(destino, selecionados, index);
			} else {
				this.listarAcessos();
			}
		});
	}

	togglePainelAcessoMute = () => {
		this.setState({painelMute: !this.state.painelMute});
	}

	handleChange = (event) => {
		switch (event.name) {
			case "portaria":
				const setores = event.value?.setores.sort(byDescricao).map(s => ({label: s.descricao, value: s})) || [];
				if (event.value) {
					window.localStorage.setItem("safety-guarita-portaria-last", event.value.id);
				}
				if (setores[0]) {
					window.localStorage.setItem("safety-guarita-setor-last", setores[0].value.id);
				}
				this.setState({acessosGerenciaveis: [], portaria: event.value, setores, setor: setores[0].value}, () => {
					this.guaritaService.listarLogsAcessoGerenciaveis().then(this.handleMultipleLogsAcesso);
				});
				break;
			case "setor":
				if (event.value) {
					window.localStorage.setItem("safety-guarita-setor-last", event.value.id);
				}
				this.setState({acessosGerenciaveis: [], setor: event.value}, () => {
					this.guaritaService.listarOutrosAcessos(this.state.setor?.id || 0).then(outrosAcessos => this.setState({outrosAcessos: outrosAcessos.filter(isLogAcessoNotBefore5Minutes)}));
					this.guaritaService.listarLogsAcessoGerenciaveis().then(this.handleMultipleLogsAcesso);
				});
				break;
			default:
				this.setState({[event.name]: event.value});
				if (event.name !== "lugar" || Validator.isEntidade(event.value) || event.value == null) this.listarAcessos();
				break;
		}
	}

	handleChangeUpperCase = (event) => {
		if (!Validator.isEntidade(event.value)) {
			this.setState({[event.name]: event.value.toUpperCase()});
		}
		if (event.name !== "lugar" || Validator.isEntidade(event.value) || event.value == null) this.listarAcessos();
	}

	listarAcessos = () => {
		if (this.queryPainelTimeout != null) clearTimeout(this.queryPainelTimeout);
		this.queryPainelTimeout = setTimeout(() => {
			let lugar = Validator.isEntidade(this.state.lugar) ? this.state.lugar : null;
			let veiculo = Validator.isEntidade(this.state.veiculo) ? this.state.veiculo : null;
			this.guaritaService.listarAcessos({veiculo, lugar, etiquetas: [this.state.categoria, ...this.state.etiquetas], pessoa: this.state.pessoa, posicao: this.state.posicao}).then((pessoas) => {
				this.setState({pessoas, loading: false});
				this.windowResize();
			})
		}, 500);
	}

	buscarAvisos = (pessoas) => {
		if (pessoas.length > 0) {
			pessoas.forEach(pessoa => {
				this.bloqueioService.isBloqueado(pessoa.id).then(bloqueado => pessoa.bloqueio = bloqueado);
			});
			this.avisoService.listarAlt(pessoas.map(p => p.id), this.state.etiquetas.filter(u => u.flags.includes("UNIDADE")).map(u => u.id)).then((avisos) => {
				let pessoas = this.state.pessoas;
				avisos.forEach(aviso => {
					pessoas.filter(p => p.id === aviso.pessoa.id).forEach(p => {
						if (p.avisos == null) p.avisos = [];
						p.avisos.push(aviso);
					});
				});
				this.setState({pessoas});
			});
		}
	}

	opcoesPosicao = [
		{label: "Todos", value: "TODOS"},
		{label: "Dentro", value: "DENTRO"},
		{label: "Fora", value: "FORA"}
	];

	removerEtiqueta = (rowData) => {
		let etiquetas = this.state.etiquetas;
		etiquetas = etiquetas.filter(e => e.id !== rowData.id);
		this.setState({etiquetas});
		this.listarAcessos();
	}

	tabChange = (event) => {
		this.setState({selectedTab: event.index, categoria: this.state.categorias[event.index], loading: true});
		this.listarAcessos();
	}

	expiracaoPessoa = (pessoa) => {
		const expiracoes = this.state.setupCondominio.expiracoes;
		let expired = false;
		if (expiracoes && expiracoes.length) {
			expiracoes.filter(e => !e.etiquetas || !e.etiquetas.length).forEach((expiracao) => {
				if (pessoa.ultimaAtualizacao && moment(pessoa.ultimaAtualizacao).add(expiracao.dias, "days").isBefore(moment())) {
					expired = true;
				}
			});
			if (!expired && pessoa.etiquetas && pessoa.etiquetas.length) {
				expiracoes.filter(ex => ex.etiquetas.some(et => pessoa.etiquetas.some(ep => ep.id === et.id))).forEach((expiracao) => {
					if (pessoa.ultimaAtualizacao && moment(pessoa.ultimaAtualizacao).add(expiracao.dias, "days").isBefore(moment())) {
						expired = true;
					}
				});
			}
		}
		return expired;
	}

	painelAcessoTemplate = (pessoa) => {
		let fotos = pessoa.fotos != null ? pessoa.fotos.sort((a, b) => b.id - a.id) : [];
		return (
			<div className="ui-sm-12 ui-md-4 ui-lg-3 ui-xl-2 ui-g-nopad">
				<div className="ui-portaria-painel-item-container">
					<div onClick={() => {
						let pessoas = this.state.pessoas;
						pessoas.filter(p => p.id === pessoa.id && p.etiquetas === pessoa.etiquetas).forEach(p => p.selecionado = !p.selecionado);
						this.setState({pessoas});
					}} className={`ui-portaria-painel-item${pessoa.selecionado ? " ui-portaria-painel-item-selected" : ""}`}>
						{fotos[0] ? <img style={{borderStyle: "solid", borderWidth: "2px", borderColor: isDentro(pessoa) ? "#4CAF50" : "#F44336"}} className="logs-acesso-v2-foto guarita" src={toSrc(fotos[0], true)} alt=""/> : <span className="logs-acesso-v2-foto guarita sem-foto" style={{borderStyle: "solid", borderWidth: "2px", borderColor: isDentro(pessoa) ? "#4CAF50" : "#F44336"}}/>}
						<div>
							<div>{StringUtils.simplificarNome(pessoa.nome)}</div>
							{pessoa.bloqueio ? <div className="portaria-aviso red">O acesso desta pessoa está bloqueado</div> : null}
							{this.securityAviso.view && pessoa.avisos != null ? pessoa.avisos.map(a => <ChipAviso aviso={a} onDelete={this.excluirAviso}/>) : null}
							{this.expiracaoPessoa(pessoa) ? <div className="portaria-aviso" key={-11}>Cadastro desatualizado</div> : null}
							{pessoa.documentos ? pessoa.documentos.filter((d) => d.validade && moment(d.validade).add(1, "days").isBefore(moment())).map((d) => <div className="portaria-aviso" key={d.id}>{`${d.tipo?.descricao} com validade expirada`}</div>) : null}
							<div>{isDentroOuGaragem(pessoa) ? `Em ${pessoa.lugar.descricao}` : "Fora"}</div>
							<div>{pessoa.ultimaPassagem != null ? `Desde ${moment(pessoa.ultimaPassagem).format("DD/MM/YY HH:mm")}` : null}</div>
							<div className="ui-footer">{pessoa.etiquetas.map(etiqueta => <EtiquetaChip etiqueta={etiqueta} />)}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	refLeftPanel = (el) => {
		if (el != null) {
			this.leftPanel = el;
		}
	}

	windowResize = () => {
		if (this.leftPanel != null) {
			this.setState({leftPanelWidth: this.leftPanel.clientWidth});
		}
	}

	editarUnidade = (etiqueta) => {
		if (this.securityUnidade.edit) {
			this.unidadeService.listar(`etiqueta=id:${etiqueta.id}`, {page: 0, size: 1}).then((unidades) => {
				ModalRealm.showDialog(<EditarUnidade key={Math.random()} unidade={unidades[0]} onModalClose={(unidade) => {
					let etiquetas = this.state.etiquetas;
					etiquetas = etiquetas.filter(e => e.id !== unidade.etiqueta.id);
					etiquetas.push(unidade.etiqueta);
					this.setState({etiquetas});
				}} />);
			});
		}
	}

	togglePainelAcesso = () => {
		this.setState({painelExpanded: !this.state.painelExpanded});
		setTimeout(this.windowResize, 50);
	}

	excluirAviso = aviso => {
		let pessoas = this.state.pessoas;
		pessoas.filter(p => p.id === aviso.pessoa.id).forEach((pessoa) => {
			pessoa.avisos = pessoa.avisos != null ? pessoa.avisos.filter(a => a.id !== aviso.id) : [];
		});
		this.setState({pessoas});
	}

	outerTabChange = (event) => {
		this.setState({tabIndex: event.index});
		switch (event.index) {
			case 1:
				if (!this.state.acessosGerenciaveis?.length) {
					this.setState({acessosGerenciaveis: []}, () => {
						this.guaritaService.listarOutrosAcessos(this.state.setor?.id || 0).then(outrosAcessos => this.setState({outrosAcessos: outrosAcessos.filter(isLogAcessoNotBefore5Minutes)}));
						this.guaritaService.listarLogsAcessoGerenciaveis().then(this.handleMultipleLogsAcesso);
					});
				}
				break;
			default:
				break;
		}
	}

	handleMultipleLogsAcesso = (logsAcesso, index = 0) => {
		if (logsAcesso.length > index) {
			this.handleLogAcesso(logsAcesso[index++], () => this.handleMultipleLogsAcesso(logsAcesso, index));
		}
	}

	detailsAcionamento = [
		<Column header="Descrição" style={{width: "*"}} field="descricao" />,
		<Column header="Ações" style={{width: "7em", textAlign: "center"}} body={(equipamento) => {
			return equipamento.dispositivos.sort((a, b) => a.destino ? b.destino ? 0 : -1 : 1).filter(d => d.atuador !== "ORIGEM").map((d) => {
				const icon = d.atuador === "RELAY1" || d.atuador === "RELAY2" ? iconeRele : (d.destino && d.destino.tipo !== "EXTERNO") ? iconeLancarEntrada : iconeLancarSaida;
				return (
					<img title={MDispositivo.MapAtuadores[d.atuador]} className="ui-action-icon" src={icon} alt="" onClick={() => this.acionarDispositivo(equipamento, d)} />
				);
			});
		}} />
	];

	acionarDispositivo = (equipamento, dispositivo) => {
		if (equipamento.controlador) {
			const body = {
				equipamento,
				dispositivos: [
					dispositivo
				]
			};
			Fetch.Post(`${equipamento.controlador.alias || equipamento.controlador.endereco}/acionamentos`, body).then((response) => {
				if (response.status === 200) {
					ModalRealm.showInformacao("info", ["Acionamento feito com sucesso!"]);
				} else {
					ModalRealm.showInformacao("warn", ["Falha no acionamento do equipamento!"]);
				}
			}).catch(() => {
				ModalRealm.showInformacao("warn", ["Falha no acionamento do equipamento!"]);
			});
		} else {
			ModalRealm.showInformacao("warn", ["Este equipamento não possui controlador. Vincule-o a um controlador antes de usar o acionamento manual."]);
		}
	}

	templateAcessoGerenciavel = (acesso) => {
		return (
			<div className="guarita-acesso">
				<div className="guarita-acesso-ativo">
					{this.templateAcessoGerenciavelAtivo(acesso)}
				</div>
				<div className="guarita-acesso-passivo">
					{acesso.adicionais.map((pessoa) => this.templateAcessoGerenciavelPassivo(acesso, pessoa))}
				</div>
			</div>
		);
	}

	templateAcessoGerenciavelAtivo = (acessoGerenciavel) => {
		const logAcesso = acessoGerenciavel.logAcesso;
		const pessoa = logAcesso.pessoa;
		const selectDisable = !acessoGerenciavel.logAcesso.pessoa?._selecionado && !acessoGerenciavel.adicionais?.some(a => a._selecionado);
		const destinos = logAcesso.equipamento?.dispositivos?.map(d => (
			<Action key={d.id}
					mode={d.destino && d.destino.tipo !== "EXTERNO" ? "post" : "delete"}
					className={`icon-only`}
					disabled={selectDisable}
					icon={`fa-arrow-up ${d.destino && d.destino.tipo !== "EXTERNO" ? "i-entrada" : "i-saida"}`}
					onClick={(e) => this.lancarAcesso(e, acessoGerenciavel, d.destino)}
			/>
		));
		if (destinos) {
			destinos.push(<Action key={0} mode="secondary" className="icon-only" icon="fa-times" onClick={(e) => this.dispensarAcesso(e, acessoGerenciavel)}/>);
		}
		const foto = pessoa?.fotos?.filter(isFinalidadeIdentificacao).sort(byRegistroDesc)[0];
		const imgPosicao = isDentro(pessoa) ? "dentro" : "fora";
		return (
			<div className="guarita-acesso-pessoa">
				<div className={`guarita-acesso-pessoa-content${logAcesso.pessoa?._selecionado ? " selecionado" : ""}`} onClick={() => this.selecionarPessoa(acessoGerenciavel, pessoa)}>
					{foto ? <img className={imgPosicao} src={toSrc(foto, true)} alt=""/> : <span className={`logs-acesso-v2-foto sem-foto guarita-gerenciavel-ativo ${imgPosicao}`}/>}
					<div>{simplificarNome(pessoa.nome)}</div>
					<div>{isDentroOuGaragem(pessoa) ? `Em ${pessoa.lugar.descricao}` : "Fora"}</div>
					<div>{logAcessoRespostaSimples(logAcesso)}</div> 
					<div>{logAcesso.equipamento?.descricao}</div>
					<div>{moment(logAcesso.data).format("DD/MM/YYYY HH:mm:ss")}</div>
					<div className="guarita-acesso-etiquetas">{logAcesso.etiquetas.map(e => <EtiquetaChip key={e.id} etiqueta={e}/>)}</div>
					<div className="guarita-acesso-actions">{destinos}</div>
				</div>
			</div>
		);
	}

	templateAcessoGerenciavelPassivo = (acessoGerenciavel, pessoa) => {
		const foto = pessoa?.fotos?.filter(isFinalidadeIdentificacao).sort(byRegistroDesc)[0];
		const imgPosicao = isDentro(pessoa) ? "dentro" : "fora";
		return (
			<div className="guarita-acesso-pessoa passivo">
				<div className={`guarita-acesso-pessoa-content${pessoa._selecionado ? " selecionado" : ""}`} onClick={() => this.selecionarPessoa(acessoGerenciavel, pessoa)}>
					{foto ? <img className={imgPosicao} src={toSrc(foto, true)} alt=""/> : <span className={`logs-acesso-v2-foto sem-foto guarita-gerenciavel-passivo ${imgPosicao}`}/>}
					<div>{simplificarNome(pessoa.nome)}</div>
					<div>{isDentroOuGaragem(pessoa) ? `Em ${pessoa.lugar.descricao}` : "Fora"}</div>
				</div>
			</div>
		);
	}

	lancarAcesso = async (event, acessoGerenciavel, destino) => {
		event.stopPropagation();
		ModalRealm.showConfirmacao("Confirma o acesso das pessoas selecionadas?", () => {
			const criterio = {
				equipamento: acessoGerenciavel.logAcesso.equipamento,
				destino,
				etiquetas: acessoGerenciavel.logAcesso.etiquetas
			};
			const pessoas = [];
			if (acessoGerenciavel.logAcesso.pessoa._selecionado) {
				pessoas.push(acessoGerenciavel.logAcesso.pessoa);
			}
			pessoas.push(...acessoGerenciavel.adicionais.filter(a => a._selecionado));
			this.lancarMultiplosAcessos(criterio, pessoas, () => this.removerAcessoGerenciavel(acessoGerenciavel));
		});
	}

	lancarMultiplosAcessos = (criterio, pessoas, callbackFn, index = 0) => {
		this.portariaService.lancarAcesso({...criterio, pessoa: pessoas[index++]}).then(() => {
			if (pessoas.length > index) {
				this.lancarMultiplosAcessos(criterio, pessoas, callbackFn, index);
			} else {
				return callbackFn?.();
			}
		});
	}

	dispensarAcesso = (event, acessoGerenciavel) => {
		event.stopPropagation();
		ModalRealm.showConfirmacao("Tem certeza de que deseja dispensar este acesso?", () => this.removerAcessoGerenciavel(acessoGerenciavel));
	}

	removerAcessoGerenciavel = async acessoGerenciavel => {
		const logAcesso = acessoGerenciavel.logAcesso;
		await this.logAcessoService.removeFlag(logAcesso.id, "PENDENTE");
		let {acessosGerenciaveis} = this.state;
		acessosGerenciaveis = acessosGerenciaveis.filter(ag => ag.logAcesso.id !== acessoGerenciavel.logAcesso.id);
		this.setState({acessosGerenciaveis});
	}

	selecionarPessoa = (acessoGerenciavel, pessoa) => {
		const {acessosGerenciaveis} = this.state;
		acessosGerenciaveis.filter(ag => ag.logAcesso.id === acessoGerenciavel.logAcesso.id).forEach(ag => {
			if (ag.logAcesso.pessoa?.id === pessoa.id) {
				ag.logAcesso.pessoa._selecionado = !ag.logAcesso.pessoa._selecionado;
			}
			ag.adicionais.filter(a => a.id === pessoa.id).forEach(p => p._selecionado = !p._selecionado);
		});
		this.setState({acessosGerenciaveis});
	}

	templateOutrosAcessos = (logAcesso) => {
		let mensagem;
		if (logAcesso?.respostas[0]?.resposta === "CREDENCIAL") {
			switch (logAcesso.forma) {
				case "SENHA":
					mensagem = "Senha incorreta";
					break;
				case "DIGITAL":
					mensagem = "Digital não reconhecida";
					break;
				case "FACIAL":
					mensagem = "Facial não reconhecida";
					break;
				default:
					mensagem = "Credencial inválida";
					break;
			}
		} else {
			mensagem = MLogAcesso.MapRespostas[logAcesso.respostas[0]?.resposta] || "Tentativa de Acesso";
		}
		return (
			<div key={logAcesso.id} className="guarita-acesso-outro">
				<div className={DANGER_STYLE.includes(logAcesso.respostas[0]?.resposta) ? "danger" : ""}>
					<div style={{whiteSpace: "nowrap"}}>{mensagem}</div>
					<div style={{whiteSpace: "nowrap", fontSize: ".9em"}}>{moment(logAcesso.data).format("DD/MM/YYYY HH:mm:ss")}</div>
					<div style={{whiteSpace: "nowrap", fontSize: ".9em"}}>{logAcesso.equipamento?.descricao}</div>
				</div>
			</div>
		);
	}

	render() {
		let destinos = [];
		if (this.state.equipamento != null && this.state.equipamento.dispositivos != null) {
			destinos.push({label: "Nenhum", value: null});
			destinos.push(...this.state.equipamento.dispositivos.map(d => {return {label: d.destino != null && d.destino.tipo !== "EXTERNO" ? "Lançar entrada para " + d.destino.descricao : "Lançar saída", value: d.destino == null ? {id: -1} : d.destino}}));
		}
		let abas = this.state.categorias.map((etiqueta) => {
			return (
				<TabPanel header={etiqueta.descricao}>
					<div className="ui-g" style={{position: "relative"}}>
						<div className="ui-g-12">
							<DataView layout="grid" emptyMessage="Nenhum resultado encontrado" value={this.state.pessoas} itemTemplate={this.painelAcessoTemplate} paginator rows={48} />
						</div>
						{this.state.loading ? <div className="ui-g-loading"><div className="fa fa-spinner ui-g-loading-spin fa-spin" /></div> : null}
					</div>
				</TabPanel>
			);
		});
		return (
			<div className="ui-g">
				<div ref={this.refLeftPanel} className={`ui-g-${this.state.painelExpanded ? "9" : "12"} ui-g-nopad`}>
					<ResizeObserver onResize={this.windowResize} />
					<PainelOcorrencia equipamento={this.state.equipamento}/>
					<Panel header="Guarita">
						<TabView activeIndex={this.state.tabIndex} onTabChange={this.outerTabChange}>
							<TabPanel header="Acessos">
								<PanelContent>
									<SuggestionBox grid={5} label="Unidades" tokenTemplate={(value) => MEtiqueta.Chip(value, this.editarUnidade, this.removerEtiqueta)} multiple field="descricao" completeMethod={this.listarUnidades} value={this.state.etiquetas} onChange={this.handleChange} suggestions={this.state.setiquetas} name="etiquetas" />
									<SelectVeiculo grid={3} label="Veículo" name="veiculo" onChange={this.handleChange} onSelect={this.handleChange} value={this.state.veiculo} />
									<SelectLugar grid={4} onChange={this.handleChange} name="lugar" value={this.state.lugar} label="Lugar" />
									<TextField grid={9} label="Pessoa" placeholder="Pesquise por nome ou qualquer documento" value={this.state.pessoa} name="pessoa" onChange={this.handleChange} />
									<Combobox grid={3} label="Posição" options={this.opcoesPosicao} value={this.state.posicao} name="posicao" onChange={this.handleChange} />
									<div className="ui-g-12">
										<Label style={{marginBottom: "6px"}}>Opções de Lançamento</Label>
										<div style={{display: "flex"}}>
											{destinos.filter(d => d.value != null).map(destino => <Action style={{flex: 1, width: "100%"}} key={destino.value.id} icon={`fa-arrow-up ${destino.value.id > 0 && destino.value.tipo !== "EXTERNO" ? "i-entrada" : "i-saida"}`} mode={destino.value.id > 0 && destino.value.tipo !== "EXTERNO" ? "post" : "delete"} onClick={() => {
												if (!this.state.pessoas.some(p => p.selecionado)) {
													ModalRealm.showInformacao("warn", ["Nenhuma pessoa selecionada"]);
												} else {
													ModalRealm.showConfirmacao(`Confirma ${destino.value.id > 0 && destino.value.tipo !== "EXTERNO" ? `a entrada das pessoas selecionadas em ${destino.value.descricao}?` : `a saída das pessoas selecionadas?`}`, () => {
														if (this.state.pessoas.filter(p => p.selecionado).some(p => p.bloqueio)) {
															ModalRealm.showInformacao("warn", ["Há pessoas com bloqueio de acesso ativo."]);
														} else {
															this.confirmarAcessos(destino.value.id > 0 ? destino.value : null, this.state.pessoas.filter(p => p.selecionado));
														}
													});
												}
											}} label={destino.label} />)}
										</div>
									</div>
									<div className="ui-g-12">
										<TabView onTabChange={this.tabChange} activeIndex={this.state.selectedTab} children={abas} />
									</div>
								</PanelContent>
							</TabPanel>
							<TabPanel header="Monitor de Equipamentos">
								<PanelContent>
									<Combobox name="portaria" grid={3} label="Portaria" value={this.state.portaria} options={this.state.portarias} onChange={this.handleChange}/>
									<div className="ui-g-6 guarita-acesso-spacer"/>
									<Combobox name="setor" grid={3} label="Setor" disabled={!this.state.setores?.length} value={this.state.setor} options={this.state.setores} onChange={this.handleChange}/>
									<div className="ui-g-12 guarita-acesso-block">
										{this.state.outrosAcessos.map(this.templateOutrosAcessos)}
									</div>
									<div className="ui-g-12">
										{this.state.acessosGerenciaveis?.length ? this.state.acessosGerenciaveis.map(this.templateAcessoGerenciavel) : (
											<div className="guarita-acesso-no-access">
												<span>Aguardando novos acessos</span>
											</div>
										)}
									</div>
								</PanelContent>
							</TabPanel>
							<TabPanel header="Acionamentos">
								<PanelContent>
									<div className="ui-g-6 ui-g-nopad"><DataTable children={this.detailsAcionamento} value={this.state.acionamentos1} emptyMessage="&nbsp;" /></div>
									<div className="ui-g-6 ui-g-nopad"><DataTable children={this.detailsAcionamento} value={this.state.acionamentos2} emptyMessage="&nbsp;" /></div>
								</PanelContent>
							</TabPanel>
						</TabView>
					</Panel>
					<div className="ui-g-12 ui-g-nopad"><div className="ui-tzm-floatbutton-margin" /></div>
				</div>
				{this.state.portaria != null ? <PainelAcesso onToggleMute={this.togglePainelAcessoMute} onToggle={this.togglePainelAcesso} mute={this.state.painelMute} expanded={this.state.painelExpanded} style={{width: `${this.state.leftPanelWidth / 3 + 21}px`}} portaria={this.state.portaria} /> : null}
				<SafetyWebSocket path="/logs-acesso" onMessage={this.handleLogAcesso}/>
				<ModalRealm />
			</div>
		);
	}

	handleLogAcesso = (logAcesso, callbackFn) => {
		if (!this.state.setores?.length || logAcesso.equipamento?.etiquetas?.some(e => e.id === this.state.setor?.id)) {
			if (logAcesso.pessoa) {
				let {acessosGerenciaveis} = this.state;
				if (logAcesso.atividade === "PASSAGEM") {
					acessosGerenciaveis.forEach(ag => {
						if (ag.logAcesso?.pessoa.id === logAcesso.pessoa.id) {
							ag.logAcesso.pessoa.lugar = logAcesso.destino;
						}
						ag.adicionais.filter(a => a.id === logAcesso.pessoa.id).forEach(a => a.lugar = logAcesso.destino);
					});
					this.setState({acessosGerenciaveis}, callbackFn);
				} else if (logAcesso.flags?.includes("PENDENTE")) {
					this.guaritaService.listarAcessosGerenciaveis(logAcesso).then(adicionais => {
						logAcesso.pessoa._selecionado = true;
						acessosGerenciaveis = acessosGerenciaveis.filter(ag => ag.logAcesso.pessoa.id !== logAcesso.pessoa.id);
						acessosGerenciaveis.push({logAcesso, adicionais});
						this.setState({acessosGerenciaveis}, callbackFn);
					});
				} else if (acessosGerenciaveis?.some(ag => ag.logAcesso.id === logAcesso.id)) {
					acessosGerenciaveis = acessosGerenciaveis.filter(ag => ag.logAcesso.id !== logAcesso.id);
					this.setState({acessosGerenciaveis}, callbackFn);
				}
			} else if (isLogAcessoNotBefore5Minutes(logAcesso)) {
				const {outrosAcessos} = this.state;
				outrosAcessos.unshift(logAcesso);
				outrosAcessos.length = Math.min(outrosAcessos.length, 8);
				this.setState({outrosAcessos});
			}
		}
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";

export class ConfirmarModeloAcesso extends React.Component {

	state = {
		visible: true
	};

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes();
		}
		this.onClose();
	}

	onClose = () => {
		this.setState({visible: false});
	}

	render() {
		return (
			<Dialog className="tzm-danger-dialog" closable={false} modal header={this.props.header != null ? this.props.header : "ATENÇÃO"} visible={this.state.visible} style={{with: "450px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						<div className="ui-g-12">
                            O Safety identificou {this.props.count} agenda(s) que utiliza(m) este modelo de acesso.<br />
                            Alterá-lo afetará o acesso de TODAS as pessoas que utilizam esta(s) agenda(s) e pode causar <u>bloqueios indesejados</u>.<br /><br />
                            Tem certeza de que deseja fazer isto?
                        </div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="delete" label="Sim, Tenho Certeza" icon="fa-check" onClick={this.onYes}/>
					<Action mode="secondary" label="Cancelar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onS={this.onYes} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {LugarService} from "../servicos/LugarService";
import {EditarLugar} from "./EditarLugar";

export class Lugar extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	lugarService = new LugarService();
	security = UserData.security("LUG", true);

	listarLugares = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.lugarService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((lugares) => this.setState({lugares, firstResult: 0}));
		}, 50);
	}

	restaurarLugar = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este lugar?", () => {
			if (rowData != null && rowData.id != null) {
				this.lugarService.buscar(rowData).then((lugar) => {
					lugar.status = "ATIVADO";
					this.lugarService.salvar(lugar).then(this.listarLugares);
				});
			}
		});
	}

	eliminarLugar = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este lugar?", () => this.lugarService.eliminar(rowData).then((result) => {
			if (result) this.listarLugares(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este lugar: há outros registros dependentes dele no sistema."]);
		}));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarLugar = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.lugarService.buscar(rowData).then((lugar) => {
				ModalRealm.showDialog(<EditarLugar key={Math.random()} lugar={lugar} onModalClose={this.listarLugares} />);
			});
		} else {
			ModalRealm.showDialog(<EditarLugar key={Math.random()} lugar={null} onModalClose={this.listarLugares} />);
		}
	}

	excluirLugar = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este lugar?", () => this.lugarService.excluir(rowData).then(this.listarLugares));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} header="Descrição"  style={{width: "*"}} sortable field="descricao" />,
		<Column key={1} header="Localidade" style={{width: "15em"}} body={(lugar) => lugar.localidade ? lugar.localidade.nome : null} />,
		<Column key={2} header="Tipo" style={{width: "15em"}} sortable field="tipo" />,
		<Column key={3} header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Lugar" onClick={(event) => this.editarLugar(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Lugar" onClick={(event) => this.excluirLugar(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Lugar" onClick={(event) => this.restaurarLugar(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Lugar" onClick={(event) => this.eliminarLugar(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/lugares", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Lugares">
					<PanelContent>
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarLugares} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.lugares} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Lugar" icon="fa-plus" onClick={this.editarLugar} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarLugares}
					onCtrlN={() => window.location.pathname === "/lugares" && this.security.create && this.editarLugar()}
					onCtrlE={() => window.location.pathname === "/lugares" && this.state.lugares.length === 1 && this.security.edit && this.editarLugar(this.state.lugares[0])}
				/>
				<ModalRealm/>
			</div>
		);
	}

}

import React from "react";

export class HelpEtiqueta extends React.Component {

	render() {
		return (
			<div>
				<p>Esta tela permite realizar a criação, a edição e a consulta de etiquetas utilizadas no sistema.</p>
				<p>A etiqueta é o mecanismo utilizado pelo Safety para rotular um processo ou objeto qualquer do sistema, permitindo um tratamento simplificado do alvo rotulado ao atribuir uma classificação que o destaque ou generalize em uma funcionalidade do sistema, podendo assim ser filtrado facilmente.
				</p>
				<h1>Como utilizar as etiquetas?</h1>
				<p>
					Se a descrição tiver diversos objetos com propriedades em comum, poderá ser criado uma etiqueta com a descrição que essas propriedades . Caso haja necessidade, a etiqueta possui o campo de "Opções", para atribuir funcionalidades ao objeto etiquetado. 
				</p>
				<h2>Filtros de Busca</h2>
				<ul>
					<li><em>Descrição</em>:
                    texto único que será usado para identificar a etiqueta noutras telas do sistema.
					</li>
					<li><em>Opções</em>:
					permite adicionar atributos que influenciam na funcionalidade do objeto etiquetado.	
					</li>
				</ul>
			</div>
		);
	}
}

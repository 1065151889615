import {EntidadeNextGenService} from "./EntidadeNextGenService";
import cronstrue from "cronstrue/i18n";
import {Validator} from "../../utils/Validator";

export class RelatorioProgramadoService extends EntidadeNextGenService {

	constructor() {
		super("/relatorios/agendamentos");
	}

	validar(agendamento) {
		const messages = [];
		if (!agendamento.relatorio) {
			messages.push("O relatório a ser emitido é obrigatório");
		}
		if (!agendamento.destinatarios?.length) {
			messages.push("O relatório deve ter ao menos um destinatário");
		} else if (agendamento.destinatarios.split(",").map(d => d.trim()).some(d => !Validator.isEmail(d))) {
			messages.push("Um ou mais destinatários possuem e-mail inválido");
		}
		if (!agendamento.titulo?.length) {
			messages.push("O título do e-mail a ser enviado é obrigatório");
		}
		if (!agendamento.cron?.length) {
			messages.push("A frequência de emissão do relatório é obrigatória")
		} else {
			try {
				cronstrue.toString(agendamento.cron, {throwExceptionOnParseError: true});
				if (/^\* /g.test(agendamento.cron) || /^\d([,\-/]\d+)+ /g.test(agendamento.cron)) {
					messages.push("Não é possível emitir o relatório mais de uma vez em menos de um minuto");
				}
			} catch (e) {
				messages.push("A frequência preenchida é inválida");
			}
		}
		return messages;
	}

}

export function criarAgendamento() {
	return {
		cron: "0 0 8 1 * ?",
		destinatarios: []
	};
}

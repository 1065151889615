import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import iconeExcluir from "../../media/icones/excluir.png";
import {MCredencial} from "../../utils/models/MCredencial";
import {MAuxiliar, MEquipamento, MLeitor} from "../../utils/models/MEquipamento";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {MultiCombobox} from "../commons/MultiCombobox";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";

export class EditarLeitor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			leitor: this.fillNulls(props.leitor),
			visible: true
		};
	}

	fillNulls = (leitor) => {
		if (!leitor) {
			leitor = MLeitor.Modelo();
		}
		return leitor;
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarLeitor = () => {
		let leitor = this.state.leitor;
		Object.keys(leitor).forEach((property) => (leitor[property] === "") && delete leitor[property]);
		if (this.props.onModalClose) {
			this.props.onModalClose(this.state.leitor);
		}
		this.onClose();
	}

	handleChange = (event) => {
		let leitor = this.state.leitor;
		leitor[event.name] = event.value;
		this.setState({leitor});
	}

	detalhesAuxiliares = [
		<Column key="1" style={{width: "5em"}} header="Auxiliar" body={(rowData) => {
			return <TextField value={rowData.auxiliar} type="number" onChange={(event) => {
				if (rowData.id != null) {
					let leitor = this.state.leitor;
					leitor.auxiliares.filter(a => a.id === rowData.id).forEach((auxiliar) => auxiliar.auxiliar = Number(event.target.value));
					this.setState({leitor});
				} else {
					let leitor = this.state.leitor;
					leitor.auxiliares.filter(a => a.chave === rowData.chave).forEach((auxiliar) => auxiliar.auxiliar = Number(event.target.value));
					this.setState({leitor});
				}
			}} />
		}} />,
		<Column key="2" header="Atuadores" body={(rowData) => {
			return <MultiCombobox appendTo={document.body} onChange={(event) => {
				if (rowData.id != null) {
					let leitor = this.state.leitor;
					leitor.auxiliares.filter(a => a.id === rowData.id).forEach((auxiliar) => auxiliar.atuadores = event.value);
					this.setState({leitor});
				} else {
					let leitor = this.state.leitor;
					leitor.auxiliares.filter(a => a.chave === rowData.chave).forEach((auxiliar) => auxiliar.atuadores = event.value);
					this.setState({leitor});
				}
			}} defaultLabel="Nenhum atuador selecionado" options={MEquipamento.Atuadores} value={rowData.atuadores} />
		}} />,
		<Column key="3" style={{width: "5em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					<img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Auxiliar" onClick={(event) => this.excluirAuxiliar(rowData)} />
				</div>
			);
		}} />
	];

	excluirAuxiliar = (rowData) => {
		let leitor = this.state.leitor;
		if (rowData.id != null) {
			leitor.auxiliares = leitor.auxiliares.filter(a => a.id !== rowData.id);
		} else {
			leitor.auxiliares = leitor.auxiliares.filter(a => a.chave !== rowData.chave);
		}
		this.setState({leitor});
	}

	botaoAddAuxiliar = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
		let leitor = this.state.leitor;
		leitor.auxiliares.push(MAuxiliar.Modelo());
		this.setState({leitor});
	}} />;

	render() {
		return (
			<Dialog modal header="Leitor" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox label="Forma" grid={4} appendTo={document.body} options={MCredencial.Formas} name="forma" onChange={this.handleChange} value={this.state.leitor.forma} />
					<TextField grid={2} label="Porta" name="porta" value={this.state.leitor.porta} type="number" onChange={this.handleChange} />
					<MultiCombobox defaultLabel="Nenhuma" appendTo={document.body} label="Opções" grid={6} options={MLeitor.Flags} name="flags" value={this.state.leitor.flags} onChange={this.handleChange} />
					<div className="ui-g-12">
						<DataTable paginator rows={10} paginatorLeft={this.botaoAddAuxiliar} emptyMessage="Nenhum auxiliar adicionado" value={this.state.leitor.auxiliares} children={this.detalhesAuxiliares} />
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.salvarLeitor} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarLeitor} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpPost} from "../../utils/Request";

export class TarifaSaldoService extends EntidadeNextGenService {

	constructor() {
		super("/tarifa-saldos");
	}

	salvarBatch(tarifaBatch) {
		return httpPost(`/tarifa-saldos/batch`, tarifaBatch);
	}

}

import React, {Component} from "react";
import {SelectButton as PSelectButton} from "primereact/components/selectbutton/SelectButton";
import {Label} from "./Label";

export class SelectButton extends Component {

	render() {
		return (
			<div className={`ui-g-${this.props.grid || 12}`}>
				<Label>{this.props.label}</Label>
				<PSelectButton {...this.props} onChange={this.onChange}/>
			</div>
		);
	}

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, value: event.value});
		}
	}

}

import {MLogAcesso} from "./models/MLogAcesso";
import moment from "moment";

const basePath = process.env.REACT_APP_PHOTO_PATH || "";

export class StringUtils {

	static ajuntores = ["DE", "DI", "DAS", "DOS", "DA", "DO"];

	static simplificarNome = simplificarNome;

	static sortByLength(a, b) {
		const lengA = a.indexOf(" ");
		const descA = a.substring(0, lengA === -1 ? a.length : lengA);
		const lengB = b.indexOf(" ");
		const descB = b.substring(0, lengB === -1 ? a.length : lengB);
		if (descA.length - descB.length !== 0) return descA.length - descB.length;
		return descA.localeCompare(descB);
	}

	static hasPlacaMercosul(text) {
		return /\$[A-Za-z0-9]{7}/g.test(text);
	}

	static getPlacaMercosul(text) {
		let placa = /\$[A-Za-z0-9]{7}/g.exec(text);
		return placa[0].substring(1, placa[0].length).toUpperCase();
	}

	static isPlacaMercosul(text) {
		return /[A-Za-z0-9]{7}/g.test(text) && /[A-Za-z]{2,}/g.test(text) && /[0-9]{2,}/g.test(text);
	}

	static sortByTimestampDesc(a, b) {
		if (a._timestamp && b._timestamp) {
			return b._timestamp - a._timestamp;
		} else if (a._timestamp) {
			return -1;
		} else if (b._timestamp) {
			return 1;
		}
		return b.id - a.id;
	}

}

export function simplificarNome(nome) {
	if (nome && nome.length > 25) {
		nome = nome.trim().split(" ");
		if (nome.length === 1) return nome;
		if (nome.length > 2) {
			return `${nome[0]} ${nome.slice(1, nome.length - 1).map(n => StringUtils.ajuntores.includes(n.toUpperCase()) ? n : `${n.substring(0, 1)}.`).join(" ")} ${nome[nome.length - 1]}`;
		}
		return nome[0] + " " + nome[nome.length - 1];
	}
	return nome;
}

export function secondsToString(value) {
	let days = Math.floor(value / 86400).toString();
	let hours = (Math.floor(value / 3600) % 24).toString();
	if (hours.length === 1) hours = `0${hours}`;
	let minutes = Math.floor((value / 60) % 60).toString();
	if (minutes.length === 1) minutes = `0${minutes}`;
	let seconds = Math.floor(value % 60).toString();
	if (seconds.length === 1) seconds = `0${seconds}`;
	return `${days !== "0" ? days + "d " : ""}${hours !== "00" ? `${hours}:` : ""}${minutes}:${seconds}`;
}

export function toSrc(foto, thumb = false) {
	return foto ? foto.conteudo ? foto.conteudo.startsWith("data:image") ? foto.conteudo : `data:image/jpeg;base64,${foto.conteudo}` : thumb ? basePath + foto.thumb : basePath + foto.path : null;
}

export function toSrcUltimaFoto(foto, thumb = false) {
	return foto ?   `${basePath}/images/photos/${foto}${thumb ? "-t" : ""}.jpg`  : null;
}

export function toSrcDTO(foto, thumb = false) {
	return foto ? foto.conteudo ? foto.conteudo.startsWith("data:image") ? foto.conteudo : `data:image/jpeg;base64,${foto.conteudo}` : `${basePath}/images/photos/${foto.nome}${thumb ? "-t" : ""}.jpg` : null;
}

export function isFinalidadeIdentificacao(foto) {
	return foto.finalidade === "IDENTIFICACAO" && foto.status === "ATIVADO";
}

const pt_BR = new Intl.NumberFormat("pt-BR");

export function toNumberFormat(number) {
	return pt_BR.format(number);
}

export function byRegistroDesc(a, b) {
	if (a._timestamp && b._timestamp) {
		return b._timestamp - a._timestamp;
	} else if (a._timestamp) {
		return -1;
	} else if (b._timestamp) {
		return 1;
	}
	if (a.registro && b.registro) {
		return b.registro.localeCompare(a.registro);
	}
	if (a.id && b.id) {
		return b.id - a.id;
	}
}

export function mapByID(entidades) {
	const result = {};
	if (entidades && entidades.length) {
		entidades.forEach(e => result[e.id] = e);
	}
	return result;
}

export function logAcessoRespostaSimples(logAcesso) {
	if (logAcesso.respostas?.some(r => r.resposta === "LIBERACAO")) {
		return "Acesso Permitido";
	}
	return MLogAcesso.MapRespostas[logAcesso.respostas?.[0].resposta];
}

export function byDescricao(entidade1, entidade2) {
	return entidade1?.descricao?.localeCompare(entidade2?.descricao);
}

export function includesAnyNormalized(query, ...value) {
	value = value.filter(str1 => str1).map(str1 => str1.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase());
	query = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().split( " ");
	return query.every(s => value.some(str1 => str1.includes(s)));
}

export function timeAsInt(value) {
	switch (value.length) {
		case 8:
			value = value.substring(0, 5);
		// eslint-disable-next-line
		case 5:
			if (value.indexOf(":") === 2) {
				let time = value.split(":");
				return parseInt(time[0] * 60, 10) + parseInt(time[1], 10);
			}
			break;
		default:
			return null;
	}
}

export function intAsTime(value) {
	return Math.floor(value / 60).toString().padStart(2, "0") + ":" + (value % 60).toString().padStart(2, "0");
}

export function truncateString(str, {maxLength, cutStart = false}) {
	if (str.length < maxLength) {
		return str;
	}
	if (cutStart) {
		return str.substring(str.length - maxLength);
	} else {
		return str.substring(0, maxLength);
	}
}

export function toDateTimeWithSeconds(timestamp) {
	return moment(timestamp).format("DD/MM/YYYY HH:mm:ss");
}

export function toTimeWithSeconds(timestamp) {
	return moment(timestamp).format("HH:mm:ss");
}
import React, {Component} from "react";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {httpPost} from "../../utils/Request";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {UserData} from "../../utils/UserData";
import {TextField} from "../commons/TextField";
import {Validator} from "../../utils/Validator";
import {ModalRealm} from "../commons/ModalRealm";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {nomesDocumentos} from "./EditarPessoaFisica";

export class EditarPessoaFisicaSimples extends Component {

	state = {
		visible: true,
		_key: Math.random(),
		pessoa: this.props.pessoa || MPessoaFisica.Modelo()
	};

	security = UserData.security("PES", true);
	pessoaFisicaService = new PessoaFisicaService();

	salvarPessoa = () => {
		if (this.security.edit) {
			const pessoa = MPessoaFisica.cleanupBeforeSave(Object.assign({}, this.state.pessoa));
			const messages = MPessoaFisica.validarParaSalvar(pessoa, this.props.obrigatorios);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				httpPost("/pessoas-fisicas", pessoa).then(pessoa => {
					if (this.props.onModalClose) {
						this.props.onModalClose(pessoa);
					}
					this.onClose();
				});
			}
		}
	}

	todosDocumentosVazios = () => {
		return !(this.state.pessoa.cpf?.length || this.state.pessoa.rg?.length || this.state.pessoa.passaporte?.length);
	}

	buscarPessoaDocumento = docName => {
		const {pessoa} = this.state;
		const docNum = pessoa[docName]?.replace(/\D/g, "");
		if (docNum?.length && !pessoa.id && !this.state.isSaving) {
			this.pessoaFisicaService.buscarPorDocumento(docName, docNum).then(pessoas => {
				if (pessoas.length && pessoas[0][docName] === docNum) {
					ModalRealm.showConfirmacao(`Encontramos uma pessoa já cadastrada com esse ${nomesDocumentos[docName]}. Gostaria de utilizar esse cadastro?`, () => {
						const pessoa = pessoas[0];
						pessoa.cpf = pessoa.cpf?.formatCpf();
						this.setState({pessoa});
					});
				}
			});
		}
	}

	onClose = () => this.setState({visible: false});

	handleChange = event => {
		const {pessoa} = this.state;
		pessoa[event.name] = event.value;
		this.setState({pessoa});
	}

	render() {
		return (
			<Dialog modal header="Pessoa" visible={this.state.visible} style={{width: "850px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField onBlur={() => this.buscarPessoaDocumento("cpf")} autofocus help="Cadastro de Pessoa Física" grid={4} label="CPF" type="cpf" required={this.todosDocumentosVazios()} invalid={!Validator.isCPF(this.state.pessoa.cpf)} name="cpf" onChange={this.handleChange} value={this.state.pessoa.cpf?.formatCpf()}/>
					<TextField onBlur={() => this.buscarPessoaDocumento("rg")} help="Registro Geral" grid={4} label="RG" required={this.todosDocumentosVazios()} name="rg" onChange={this.handleChange} value={this.state.pessoa.rg}/>
					<TextField onBlur={() => this.buscarPessoaDocumento("passaporte")} help="Documento Alternativo (Passaporte, CNH, Termo de Responsabilidade, etc.)" grid={4} label="Outro Documento" required={this.todosDocumentosVazios()} name="passaporte" onChange={this.handleChange} value={this.state.pessoa.passaporte}/>
					<TextField grid={12} label="Nome" type="alphabetic" required name="nome" onChange={this.handleChange} value={this.state.pessoa.nome}/>
				</DialogContent>
				<DialogFooter>
					<Action disabled={!this.security.edit} mode="post" label="Salvar" icon="fa-save" onClick={this.salvarPessoa}/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarPessoa} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

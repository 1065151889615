import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarLugar} from "../components/Lugar/EditarLugar";
import {LugarService} from "../components/servicos/LugarService";
import {MLugar} from "../utils/models/MLugar";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectLugar extends React.Component {

	service = new LugarService();

	security = UserData.security("LUG");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarLugar key={Math.random()} lugar={{...MLugar.Modelo(), descricao: event.value.descricao.replace(`Criar lugar `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select entidade="lugar" insertable={this.security.create && this.props.insertable} service={this.service} field="descricao" query="descricao" onSelect={this.onSelect} {...this.props} />;
	}

}

import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeRemover from "../../media/icones/remover.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectPessoa} from "../../select/SelectPessoa";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {GUI} from "../../utils/Constants";
import {LocalDateTime} from "../../utils/DateUtils";
import {MEncomenda} from "../../utils/models/MEncomenda";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EditarOcorrencia} from "../Ocorrencia/EditarOcorrencia";
import {Foto} from "../PessoaFisica/Foto";
import {EncomendaService} from "../servicos/EncomendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {toSrc} from "../../utils/StringUtils";
import {CheckSquare} from "../commons/CheckSquare";

export class EditarEncomenda extends React.Component {

	state = {
		encomenda: Object.assign(MEncomenda.Modelo(), this.props.encomenda),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	encomendaService = new EncomendaService();
	security = UserData.security("ENC", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarEncomenda = () => {
		if (this.security.edit) {
			let encomenda = MEncomenda.cleanupBeforeSave(Object.assign({}, this.state.encomenda));
			let messages = MEncomenda.validarParaSalvar(encomenda);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (encomenda.receptor && !encomenda.dataEntrega) {
					encomenda.dataEntrega = LocalDateTime.fullDateTimeISO();
					encomenda.status = "EXCLUIDO";
					encomenda.notificarEntrega = true;
				}
				if (encomenda.foto && encomenda.foto.conteudo != null) {
					encomenda.foto.conteudo = encomenda.foto.conteudo.split(",").pop();
				}
				this.salvarFinal(encomenda);
			}
		}
	}

	salvarFinal = (encomenda) => {
		this.setState({lockSave: true});
		this.encomendaService.salvar(encomenda).then((encomenda) => {
			this.setState({lockSave: false});
			if (this.props.onModalClose) {
				this.props.onModalClose(encomenda);
			}
			this.onClose();
		}).catch(() => this.setState({lockSave: false}));
	}

	handleChange = (event) => {
		let encomenda = this.state.encomenda;
		encomenda[event.name] = event.value;
		this.setState({encomenda});
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	detailsOcorrencias = [
		<Column style={{width: "10em", textAlign: "center"}} key={0} header="Data"
		        body={(ocorrencia) => moment(ocorrencia.data).format("DD/MM/YYYY HH:mm")}/>,
		<Column style={{width: "*"}} key={2} header="Justificativa" field="justificativa"/>,
		<Column style={{width: "4em", textAlign: "center"}} key={3} header="Ações" body={(ocorrencia) => {
			return (
				<div>
					<img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Ocorrência"
					     onClick={() => this.removerOcorrencia(ocorrencia)}/>
				</div>
			);
		}}/>
	];

	removerOcorrencia = (ocorrencia) => {
		const encomenda = this.state.encomenda;
		encomenda.ocorrencias = encomenda.ocorrencias.filter((o) => o.id !== ocorrencia.id && (!ocorrencia._key || ocorrencia._key !== o._key));
		this.setState({encomenda});
	}

	editarOcorrencia = (ocorrencia = MOcorrencia.Modelo()) => {
		ocorrencia = {
			...ocorrencia,
			pessoa: UserData.operador.pessoa,
			evento: "ATENDIMENTO",
			data: moment().format("YYYY-MM-DDTHH:mm:ss")
		};
		if (!ocorrencia.id) {
			ocorrencia._key = Math.random();
		}
		ModalRealm.showDialog(<EditarOcorrencia justificar ocorrencia={ocorrencia} onModalClose={(ocorrencia) => {
			const encomenda = this.state.encomenda;
			if (!encomenda.ocorrencias) {
				encomenda.ocorrencias = [];
			}
			encomenda.ocorrencias.push(ocorrencia);
			this.setState({encomenda});
		}}/>);
	}

	footerOcorrencias = <Button icon="fa-plus" className="ui-tzm-pagleft-icon" onClick={() => this.editarOcorrencia()}/>;

	render() {
		return (
			<Dialog modal header="Encomenda" visible={this.state.visible} style={{width: "650px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView activeIndex={this.state.activeIndex} onTabChange={this.tabChange} className="ui-g-12">
						<TabPanel header="Dados Principais">
							<PanelContent>
								<DatePicker appendTo={document.body} value={this.state.encomenda.data} name="data" onChange={this.handleChange} label="Data" grid={3}/>
								<SelectPessoa value={this.state.encomenda.remetente} name="remetente" onChange={this.handleChange} label="Remetente" grid={9}/>
								<SelectPessoa value={this.state.encomenda.destinatario} name="destinatario" onChange={this.handleChange} required label="Destinatário" grid={12}/>
								<MultiSelectEtiqueta value={this.state.encomenda.etiquetas} name="etiquetas" onSelect={this.handleChange} label="Etiquetas" grid={12}/>
								<TextField style={{height: "6em"}} multiline={true} grid={12} label="Discriminação" required name="discriminacao" onChange={this.handleChange} value={this.state.encomenda.discriminacao}/>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Ocorrências">
							<PanelContent>
								<div className="ui-g-12">
									<DataTable paginator paginatorLeft={this.footerOcorrencias} emptyMessage="Nenhuma ocorrência registrada" rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} value={this.state.encomenda.ocorrencias} children={this.detailsOcorrencias} />
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Entrega">
							<PanelContent>
								<SelectPessoaFisica value={this.state.encomenda.receptor} name="receptor" onChange={this.handleChange} label="Receptor" grid={12}/>
								<div style={{width: "100%", padding: "0 .5em"}}>
									<CheckSquare style={{float: "right"}} checked={this.state.encomenda.notificarEntrega} name="notificarEntrega" onChange={this.handleChange} label="Notificar entrega"/>
								</div>
								<div className="ui-g-12">
									<Label>Imagem</Label>
									<div onClick={this.tirarFotografia} className="ui-tzm-fotoencomenda-container">
										<img alt="" className="ui-tzm-fotoencomenda" src={toSrc(this.state.encomenda.foto)} />
									</div>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.encomenda.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/encomendas", this.state.encomenda)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.encomenda.id)) || this.state.lockSave} onClick={this.salvarEncomenda} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarEncomenda} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	tirarFotografia = () => {
		let width = Math.round(document.body.clientWidth * .3);
		let height = Math.round(width * .75);
		ModalRealm.showDialog(<Foto key={Math.random()} width={width} height={height} onModalClose={(result) => {
			let encomenda = this.state.encomenda;
			encomenda.foto = result.foto1;
			this.setState({encomenda});
		}}/>);
	}

}

import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {GUI} from "../../utils/Constants";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {Validator} from "../../utils/Validator";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";

export class SelecionarPessoas extends React.Component {

	state = {
		nome: "",
		rg: "",
		cpf: "",
		endereco: "",
		numero: "",
		passaporte: "",
		etiquetas: [],
		visible: true,
		pessoas: [],
		excluidos: false,
		empresa: null
	};

	pessoaFisicaService = new PessoaFisicaService();

	onClose = () => {
		this.setState({visible: false});
	}
	
	onConfirmar = () => {
		if (this.props.onModalClose) {
			if (this.state.pessoas.some(p => p._selecionada)) {
				const pessoas = this.state.pessoas.filter(p => p._selecionada);
				this.props.onModalClose(pessoas);
				this.onClose();
			} else {
				ModalRealm.showInformacao("warn", ["Nenhuma pessoa selecionada"]);
			}
		} else {
			this.onClose();
		}
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
		this.listarPessoas();
	}

	toggleAllPessoas = () => {
		const pessoas = this.state.pessoas;
		pessoas.forEach((p) => p._selecionada = !p._selecionada);
		this.setState({pessoas});
	}

	togglePessoa = (e, r) => {
		return (
			<Checkbox checked={this.state.pessoas[r.rowIndex]._selecionada} onChange={() => {
				const pessoas = this.state.pessoas;
				pessoas[r.rowIndex]._selecionada = !pessoas[r.rowIndex]._selecionada;
				this.setState({pessoas});
			}} />
		);
	}

	listarPessoas = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let search = [];
			if (!this.state.excluidos) search.push("status:ATIVADO");
			if (this.props.except && this.props.except.length) search.push(`id!${this.props.except.map(p => p.id).join(",")}`);
			if (this.state.nome.length) search.push(`nome~${this.state.nome}`);
			if (this.state.cpf.length) search.push(`cpf~${this.state.cpf}`);
			if (this.state.rg.length) search.push(`rg~${this.state.rg}`);
			if (this.state.passaporte.length) search.push(`passaporte~${this.state.passaporte}`);
			let params = [search.join(";")];
			if (this.state.endereco.length) params.push(`enderecos=logradouro~${this.state.endereco}`);
			if (this.state.numero.length) params.push(`enderecos=numero~${this.state.numero}`);
			if (Validator.isEntidade(this.state.empresa)) params.push(`empresa=id:${this.state.empresa.id}`);
			if (this.state.etiquetas.length) this.state.etiquetas.forEach(e => params.push(`etiquetas=id:${e.id}`));
			if (params.length) {
				this.pessoaFisicaService.listar(`search=${params.join("&")}`, {page: 0, size: GUI.maxResults, sort: "nome"}).then((pessoas) => {
					const selecionadas = this.state.pessoas.filter(p => p._selecionada);
					this.setState({pessoas: [...selecionadas, ...pessoas], firstResult: 0});
				});
			}
		}, 500);
	}

	render() {
		return (
			<Dialog closable={false} modal header="Pessoas" visible={this.state.visible} style={{width: "850px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={12} label="Nome" autofocus name="nome" value={this.state.nome} onChange={this.handleUpdate} />
					<TextField grid={4} label="CPF" name="cpf" value={this.state.cpf} onChange={this.handleUpdate} />
					<TextField grid={4} label="RG" name="rg" value={this.state.rg} onChange={this.handleUpdate} />
					<TextField grid={4} label="Documento" name="passaporte" value={this.state.passaporte} onChange={this.handleUpdate} />
					<TextField grid={4} label="Endereço" name="endereco" value={this.state.endereco} onChange={this.handleUpdate} />
					<TextField grid={2} label="Número" name="numero" value={this.state.numero} onChange={this.handleUpdate} />
					<SelectPessoaJuridica grid={6} label="Empresa" name="empresa" value={this.state.empresa} onChange={this.handleUpdate} />
					<MultiSelectEtiqueta grid={12} label="Etiquetas" value={this.state.etiquetas} name="etiquetas" onSelect={this.handleUpdate} />
					<div className="ui-g-12">
						<DataTable rows={10} emptyMessage="Nenhuma pessoa encontrada" value={this.state.pessoas}>
							<Column style={{textAlign: "center", width: "3em"}} header={<Checkbox checked={this.state.pessoas.every(p => p._selecionada)} onChange={this.toggleAllPessoas} />} body={this.togglePessoa} />
							<Column style={{width: "*"}} header="Nome" field="nome" />
							<Column style={{width: "10em"}} header="CPF" field="cpf" />
							<Column style={{width: "10em"}} header="RG" field="rg" />
							<Column style={{width: "10em"}} header="Documento" field="passaporte" />
						</DataTable>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Confirmar" icon="fa-check" onClick={this.onConfirmar} />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

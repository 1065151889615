import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectControlador} from "../../select/SelectControlador";
import {SelectEquipamento} from "../../select/SelectEquipamento";
import {SelectPessoa} from "../../select/SelectPessoa";
import {MCredencial} from "../../utils/models/MCredencial";
import {MBotoeira, MDispositivo, MEquipamento, MLeitor, optionsEquipamentoFlags} from "../../utils/models/MEquipamento";
import {MLogAcesso} from "../../utils/models/MLogAcesso";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {EditarDispositivo} from "./EditarDispositivo";
import {EditarLeitor} from "./EditarLeitor";
import {Label} from "../commons/Label";
import {EditarBotoeira} from "./EditarBotoeira";
import {SelectButton} from "../commons/SelectButton";

export class EditarEquipamento extends React.Component {

	state = {
		equipamento: Object.assign(MEquipamento.Modelo(), this.props.equipamento),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	equipamentoService = new EquipamentoService();
	security = UserData.security("EQP", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarEquipamento = () => {
		if (this.security.edit) {
			let equipamento = MEquipamento.cleanupBeforeSave(Object.assign({}, this.state.equipamento));
			let messages = MEquipamento.validarParaSalvar(equipamento);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.equipamentoService.salvar(equipamento).then((equipamento) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(equipamento);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let equipamento = this.state.equipamento;
		equipamento[event.name] = event.value;
		this.setState({equipamento});
	}

	detalhesLeitores = [
		<Column style={{width: "12em"}} header="Forma" body={(rowData) => MCredencial.Formas.filter(f => f.value === rowData.forma).map(f => f.label).join(", ")} />,
		<Column field="porta" style={{width: "10em"}} header="Porta" />,
		<Column style={{width: "*"}} header="Opções" body={(rowData) => rowData.flags.map(o => MLeitor.MapFlags[o]).join(", ")} />,
		<Column style={{width: "*"}} header="Auxiliares" body={(rowData) => {
			return rowData.auxiliares.map((auxiliar) => <div>{`${auxiliar.auxiliar === -1 ? "Todos liberam" : `${auxiliar.auxiliar} libera apenas`} ${MEquipamento.Atuadores.filter(a => auxiliar.atuadores.includes(a.value)).map(a => a.label).join(", ")}`}</div>);
		}} />,
		<Column style={{width: "7em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Leitor" onClick={() => this.editarLeitor(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Leitor" onClick={() => this.excluirLeitor(rowData)} /> : null}
				</div>
			);
		}} />,
	];

	detalhesBotoeiras = [
		<Column field="porta" style={{width: "10em"}} header="Porta" />,
		<Column style={{width: "*"}} header="Finalidade" body={b => MBotoeira.MapFinalidades[b.finalidade]} />,
		<Column style={{width: "7em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Leitor" onClick={() => this.editarBotoeira(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Leitor" onClick={() => this.excluirBotoeira(rowData)} /> : null}
				</div>
			);
		}} />,
	];

	detalhesDispositivos = [
		<Column style={{width: "9em"}} header="Tipo de Atuador" body={(rowData) => MEquipamento.Atuadores.filter(a => a.value === rowData.atuador).map(a => a.label).join(", ")} />,
		<Column style={{width: "7em"}} header="Acionamento" field="acionamento" />,
		<Column style={{width: "12em"}} header="Sensorização" body={(rowData) => MDispositivo.Sensorizacoes.filter(s => s.value === rowData.sensorizacao).map(s => s.label).join(", ")} />,
		<Column style={{width: "*"}} header="Gatilhos" body={(rowData) => {
			return MLogAcesso.Gatilhos.filter(r => rowData.gatilhos.includes(r.value)).map(r => r.label).join(", ");
		}} />,
		<Column style={{width: "*"}} header="Destino" body={(rowData) => rowData.destino ? rowData.destino.descricao : "Fora"} />,
		<Column style={{width: "7em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Dispositivo" onClick={() => this.editarDispositivo(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Dispositivo" onClick={() => this.excluirDispositivo(rowData)} /> : null}
				</div>
			);
		}} />,
	];

	editarBotoeira = (botoeira) => {
		ModalRealm.showDialog(<EditarBotoeira key={Math.random()} botoeira={botoeira} onModalClose={botoeira => {
			if (botoeira.id != null) {
				let equipamento = this.state.equipamento;
				let index = this.state.equipamento.botoeiras.map(l => l.id).indexOf(botoeira.id);
				equipamento.botoeiras[index] = botoeira;
				this.setState({equipamento});
			} else {
				let equipamento = this.state.equipamento;
				let index = this.state.equipamento.botoeiras.map(l => l.chave).indexOf(botoeira.chave);
				if (index === -1) {
					equipamento.botoeiras.push(botoeira);
				} else {
					equipamento.botoeiras[index] = botoeira;
				}
				this.setState({equipamento});
			}
		}} />);
	}

	excluirBotoeira = (botoeira) => {
		let equipamento = this.state.equipamento;
		if (botoeira.id != null) {
			equipamento.botoeiras = equipamento.botoeiras.filter(l => l.id !== botoeira.id);
		} else {
			equipamento.botoeiras = equipamento.botoeiras.filter(l => l.chave !== botoeira.chave);
		}
		this.setState({equipamento});
	}

	editarLeitor = (leitor) => {
		ModalRealm.showDialog(<EditarLeitor key={Math.random()} leitor={leitor} onModalClose={(leitor) => {
			if (leitor.id != null) {
				let equipamento = this.state.equipamento;
				let index = this.state.equipamento.leitores.map(l => l.id).indexOf(leitor.id);
				equipamento.leitores[index] = leitor;
				this.setState({equipamento});
			} else {
				let equipamento = this.state.equipamento;
				let index = this.state.equipamento.leitores.map(l => l.chave).indexOf(leitor.chave);
				if (index === -1) {
					equipamento.leitores.push(leitor);
				} else {
					equipamento.leitores[index] = leitor;
				}
				this.setState({equipamento});
			}
		}} />);
	}

	excluirLeitor = (leitor) => {
		let equipamento = this.state.equipamento;
		if (leitor.id != null) {
			equipamento.leitores = equipamento.leitores.filter(l => l.id !== leitor.id);
		} else {
			equipamento.leitores = equipamento.leitores.filter(l => l.chave !== leitor.chave);
		}
		this.setState({equipamento});
	}

	editarDispositivo = (dispositivo) => {
		ModalRealm.showDialog(<EditarDispositivo key={Math.random()} dispositivo={dispositivo} onModalClose={(dispositivo) => {
			if (dispositivo.id != null) {
				let equipamento = this.state.equipamento;
				let index = this.state.equipamento.dispositivos.map(d => d.id).indexOf(dispositivo.id);
				equipamento.dispositivos[index] = dispositivo;
				this.setState({equipamento});
			} else {
				let equipamento = this.state.equipamento;
				let index = this.state.equipamento.dispositivos.map(d => d.chave).indexOf(dispositivo.chave);
				if (index === -1) {
					equipamento.dispositivos.push(dispositivo);
				} else {
					equipamento.dispositivos[index] = dispositivo;
				}
				this.setState({equipamento});
			}
		}} />);
	}

	excluirDispositivo = (dispositivo) => {
		let equipamento = this.state.equipamento;
		if (dispositivo.id != null) {
			equipamento.dispositivos = equipamento.dispositivos.filter(d => d.id !== dispositivo.id);
		} else {
			equipamento.dispositivos = equipamento.dispositivos.filter(d => d.chave !== dispositivo.chave);
		}
		this.setState({equipamento});
	}

	botaoAddLeitor = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
		this.editarLeitor(null);
	}} />;

	botaoAddBotoeira = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
		this.editarBotoeira(null);
	}} />;

	botaoAddDispositivo = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
		this.editarDispositivo(null);
	}} />;

	tabChange = (event) => this.setState({activeIndex: event.index});

	render() {
		return (
			<Dialog modal header="Equipamento" visible={this.state.visible} style={{width: "1000px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<Combobox grid={2} label="Status" appendTo={document.body} options={MEquipamento.Status} name="status" onChange={this.handleChange} value={this.state.equipamento.status} />
								<TextField grid={5} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.equipamento.descricao} />
								<Combobox grid={2} label="Categoria" appendTo={document.body} options={MEquipamento.Categorias} name="categoria" onChange={this.handleChange} value={this.state.equipamento.categoria} />
								<Combobox grid={3} label="Família" appendTo={document.body} options={[{label: "Nenhuma", value: "NENHUMA"}, ...MEquipamento.Familias]} name="familia" onChange={this.handleChange} value={this.state.equipamento.familia} />
								<SelectButton multiple grid={12} name="flags" value={this.state.equipamento.flags} onChange={this.handleChange} label="Opções" options={optionsEquipamentoFlags}/>
								<TextField grid={3} label="IP" required name="ip" onChange={this.handleChange} value={this.state.equipamento.ip} />
								<TextField disabled={this.state.equipamento.id && this.state.equipamento.categoria === "PONTO"} required={this.state.equipamento.categoria === "PONTO"} grid={3} label="Número de Série" name="serial" onChange={this.handleChange} value={this.state.equipamento.serial} />
								<TextField grid={2} name="firmware" value={this.state.equipamento.firmware} label="Firmware" onChange={this.handleChange}/>
								<TextField grid={2} unit="min" label="Fuso Horário" name="fusoHorario" onChange={this.handleChange} value={this.state.equipamento.fusoHorario} />
								<TextField disabled={this.state.equipamento.categoria === "PONTO"} grid={2} unit="ms" label="Limite de Obstrução" name="limiteObstrucao" type="number" onChange={this.handleChange} value={this.state.equipamento.limiteObstrucao} />
								<SelectControlador grid={5} label="Controlador" name="controlador" onChange={this.handleChange} value={this.state.equipamento.controlador} />
								<TextField grid={2} label="Login" name="login" value={this.state.equipamento.login} onChange={this.handleChange} />
								<TextField type="password" grid={3} label="Senha" name="senha" value={this.state.equipamento.senha} onChange={this.handleChange} />
								<TextField type="password" grid={2} label="Senha Mestre" name="senhaMestre" value={this.state.equipamento.senhaMestre} onChange={this.handleChange} />
								<SelectPessoa required={this.state.equipamento.categoria === "PONTO"} value={this.state.equipamento.empresa} name="empresa" onChange={this.handleChange} label="Empresa" grid={6} />
								<SelectEquipamento disabled={this.state.equipamento.categoria === "PONTO"} name="mestre" value={this.state.equipamento.mestre} onChange={this.handleChange} grid={6} label="Mestre" />
								<MultiSelectEtiqueta label="Etiquetas" grid={12} onSelect={this.handleChange} name="etiquetas" value={this.state.equipamento.etiquetas} />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Leitores & GPIO">
							<Label>Leitores</Label>
							<DataTable rows={10} paginator paginatorLeft={this.botaoAddLeitor} value={this.state.equipamento.leitores} emptyMessage="Nenhum leitor adicionado" children={this.detalhesLeitores} />
							<div style={{height: "10px"}}/>
							<Label>GPIO</Label>
							<DataTable rows={10} paginator paginatorLeft={this.botaoAddBotoeira} value={this.state.equipamento.botoeiras} emptyMessage="Nenhuma botoeira adicionada" children={this.detalhesBotoeiras} />
						</TabPanel>
						<TabPanel header="Dispositivos">
							<DataTable rows={10} paginator paginatorLeft={this.botaoAddDispositivo} value={this.state.equipamento.dispositivos} emptyMessage="Nenhum dispositivo adicionado" children={this.detalhesDispositivos} />
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.equipamento.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/equipamentos", this.state.equipamento)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.equipamento.id)) || this.state.lockSave} onClick={this.salvarEquipamento} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarEquipamento} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

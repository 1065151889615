import React from "react";

export class HelpLugar extends React.Component {

	render() {
		return (
			<div>
				<p>
					Um lugar é um perímetro limitado (cercado), controlado por um ou mais equipamentos de acesso. Quando há espaços abertos ou conjuntos, sem a presença de um equipamento
					para dividí-los, consideramos o perímetro inteiro como sendo um único lugar. Um exemplo onde isso fica claro é em um condomínio: depois de passar pela guarita,
					geralmente não há equipamentos de acesso controlando o acesso individual às unidades/lotes. Logo, considera-se que o condomínio inteiro é um lugar só.
				</p>
				<p>
					Quando o controle individual de acesso às unidades torna-se necessário, devemos utilizar o cadastramento de unidades e vincular agendas a elas (através do uso de etiquetas).
				</p>
				<h1>Como preencher os campos?</h1>
				<ul>
					<li><em>Descrição</em>: texto único que será usado para identificar o lugar noutras telas do sistema.</li>
					<li>
						<em>Tipo</em>: um lugar pode ser tipado para que o comportamento do controle de acesso mude, conforme as seguintes regras:
						<ul>
							<li>Normal: representa um lugar onde o controle de acesso será feito normalmente.</li>
							<li>Garagem: representa um lugar onde o acesso é limitado pela quantidade de vagas disponíveis para a unidade à qual a agenda da pessoa buscando acesso está vinculada (através do uso de etiquetas).</li>
						</ul>
					</li>
				</ul>
				<h1>Perguntas frequentes</h1>
				<ol>
					<li>
						<em>Como faço a liberação de acesso para um lugar?</em>
						<div>
							O processo de liberação de acesso aos lugares é feito pelos modelos de acesso. Você precisa criar uma agenda para as pessoas que deverão ter acesso a este lugar e vincular a esta agenda um modelo de acesso
							que contenha este lugar na lista de liberação.
						</div>
					</li>
				</ol>
			</div>
		);
	}

}

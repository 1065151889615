import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {SuggestionBox} from "../components/commons/SuggestionBox";
import {EditarPessoaFisica} from "../components/PessoaFisica/EditarPessoaFisica";
import {EditarPessoaJuridica} from "../components/PessoaJuridica/EditarPessoaJuridica";
import {PessoaFisicaService} from "../components/servicos/PessoaFisicaService";
import {PessoaJuridicaService} from "../components/servicos/PessoaJuridicaService";
import {MPessoaFisica} from "../utils/models/MPessoaFisica";
import {MPessoaJuridica} from "../utils/models/MPessoaJuridica";
import {UserData} from "../utils/UserData";
import {Validator} from "../utils/Validator";

export class SelectPessoaDTO extends React.Component {

	state = {
		entidades: []
	};

	pfService = new PessoaFisicaService();

	pjService = new PessoaJuridicaService();

	security = UserData.security("PES") || UserData.security("EMP");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			if (event.value.passaporte === -1) {
				let value = event.value.nome.replace("Criar pessoa ", "");
				let property = Validator.isCPF(value) ? "cpf" : !isNaN(Number(value)) ? "rg" : "nome";
				if (property === "cpf") value = value.formatCpf();
				ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={{...MPessoaFisica.Modelo(), [property]: value}} onModalClose={(value) => this.props.onChange({name: this.props.name, value})} />);
			} else {
				let value = event.value.nome.replace("Criar empresa ", "");
				let property = Validator.isCNPJ(value) ? "cnpj" : "nome";
				if (property === "cnpj") value = value.formatCnpj();
				ModalRealm.showDialog(<EditarPessoaJuridica key={Math.random()} pessoa={{...MPessoaJuridica.Modelo(), [property]: value}} onModalClose={(value) => this.props.onChange({name: this.props.name, value})} />);
			}
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	onChange = (event) => {
		if (this.props.onChange) this.props.onChange({name: this.props.name, value: event.value});
	}

	listar = (event) => {
		let params = Object.assign({}, this.state.params);
		params.search = event.query;
		params.status = 'ATIVADO';		
		params.size = 100;
		this.pfService.listarSuggestion(params).then((pessoas) => {
			this.pjService.listarSuggestion(params).then((empresas) => {
				if (!pessoas.length && !empresas.length) {
					pessoas.push({nome: `Criar pessoa ${event.query}`, id: -1, passaporte: -1});
					empresas.push({nome: `Criar empresa ${event.query}`, id: -1});
				}
				this.setState({entidades: [...pessoas, ...empresas].sort((a, b) => a.nome.localeCompare(b.nome))});
			});
		});
	}

	itemTemplate = (entidade) => {
		return <div>{entidade.rg || entidade.cpf || entidade.passaporte ? <div className="ui-tzm-peschip">Pessoa</div> : <div className="ui-tzm-peschip">Empresa</div>}{entidade.nome}</div>;
	}

	render() {
		return <SuggestionBox appendTo={document.body} itemTemplate={this.itemTemplate} field="nome" value={this.props.value} suggestions={this.state.entidades} onSelect={this.onSelect} onChange={this.onChange} completeMethod={this.listar} {...this.props} />;
	}

}

import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {Dialog} from "primereact/components/dialog/Dialog";
import {httpGet, httpPost} from "../../utils/Request";
import {UserData} from "../../utils/UserData";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {CheckSquare} from "../commons/CheckSquare";
import {DatePicker} from "../commons/DatePicker";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import moment from "moment";
import {v4} from "uuid";
import {ComboboxModeloAcessoAutorizacao} from "../../select/ComboboxModeloAcessoAutorizacao";
import {LocalTime} from "../../utils/DateUtils";
import {simplificarDiasSemana} from "../servicos/PeriodoService";

export class EditarAutorizacaoAcesso extends Component {

	state = {
		visible: true,
		pessoas: [],
		lockSave: false,
		dias: 0,
		repetir: null,
		inicio: "00:00",
		fim: "23:59",
		agenda: Object.assign({}, this.props.agenda)
	};

	security = UserData.security("AAC", true);

	componentDidMount() {
		this.listarPessoas();
	}

	listarPessoas = () => {
		httpGet(`/agendas/integracao/gestor/${UserData.operador?.pessoa?.id}`).then(horarios => {
			const pessoas = horarios.map(h => ({...h.pessoa, _horarios: h.modeloAcesso}));
			this.setState({pessoas});
		});
	}

	onClose = () => this.setState({visible: false});

	toggleSelecionar = event => {
		const {pessoas} = this.state;
		pessoas[event.index]._selecionado = !pessoas[event.index]._selecionado;
		this.setState({pessoas});
	}

	toggleSelecionarTodos = () => {
		const {pessoas} = this.state;
		const checked = !pessoas.every(p => p._selecionado);
		pessoas.forEach(p => p._selecionado = checked);
		this.setState({pessoas});
	}

	salvarAgenda = async () => {
		const {agenda} = this.state;
		agenda.flags = ["AUTORIZACAO"];
		agenda.pessoas = this.state.pessoas.filter(p => p._selecionado);
		agenda.modelosAcesso = [this.state.modeloAcesso];
		agenda.inicio = `${agenda.validade}T${this.state.inicio}:00`;
		agenda.validade = `${agenda.validade}T${this.state.fim}:00`;
		if (moment(agenda.validade).isBefore(moment(agenda.inicio))) {
			agenda.validade = moment(agenda.validade).add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
		}
		const prefixoChaveIntegracao = `AAC;${v4()};`;
		agenda.chaveIntegracao = prefixoChaveIntegracao + moment(agenda.inicio).format("YYYY-MM-DD");
		await httpPost("/agendas", agenda);
		if (this.state.repetir?.length) {
			let referencia = moment(agenda.inicio).format("YYYY-MM-DD");
			while (moment(referencia).isBefore(this.state.repetir)) {
				agenda.inicio = moment(agenda.inicio).add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
				agenda.validade = moment(agenda.validade).add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
				agenda.chaveIntegracao = prefixoChaveIntegracao + moment(agenda.inicio).format("YYYY-MM-DD");
				await httpPost("/agendas", agenda);
				referencia = moment(referencia).add(1, "days").format("YYYY-MM-DD");
			}
		}
		if (this.props.onModalClose) {
			this.props.onModalClose();
		}
		this.onClose();
	}

	disableSalvar = () => {
		return (
			!(this.security.edit || (this.security.create && !this.state.agenda.id))
			|| this.state.lockSave
			|| !this.state.pessoas?.some(p => p._selecionado)
			|| this.state.inicio?.length !== 5
			|| this.state.fim?.length !== 5
			|| !this.state.modeloAcesso?.id
		);
	}

	handleChange = event => {
		const {agenda} = this.state;
		agenda[event.name] = event.value;
		this.setState({agenda});
	}

	handleBaseChange = event => this.setState({[event.name]: event.value});

	handleBaseTimeChange = event => this.setState({[event.name]: event.value.formatTime()});

	loadedModelosAcesso = modelosAcesso => {
		if (modelosAcesso?.length === 1) {
			this.setState({modeloAcesso: modelosAcesso[0]});
		}
	}

	render() {
		return (
			<Dialog key={this.props.key} modal header="Autorização de Acesso" visible={this.state.visible} style={{width: "1200px"}} onHide={this.onClose}>
				<DialogContent>
					<DatePicker disabled value={this.state.agenda.validade} label="Data" grid={2} appendTo={document.body}/>
					<TextField name="inicio" value={this.state.inicio} onChange={this.handleBaseTimeChange} label="Início" grid={2}/>
					<TextField name="fim" value={this.state.fim} onChange={this.handleBaseTimeChange} label="Fim" grid={2}/>
					<ComboboxModeloAcessoAutorizacao onLoaded={this.loadedModelosAcesso} grid={3} name="modeloAcesso" value={this.state.modeloAcesso} onChange={this.handleBaseChange} label="Filial"/>
					<DatePicker maxDate={moment(this.state.agenda.validade).add(1, "months").toDate()} minDate={moment(this.state.agenda.validade).add(1, "days").toDate()} appendTo={document.body} value={this.state.repetir} name="repetir" onChange={this.handleBaseChange} label="Repetir agenda até" grid={3}/>
					<div className="ui-g-12">
						<DataTable emptyMessage="Nenhum registro encontrado" paginator rows={20} rowsPerPageOptions={[10, 20, 50]} value={this.state.pessoas}>
							<Column style={{width: "3em", textAlign: "center"}} header={(
								<CheckSquare onChange={this.toggleSelecionarTodos} checked={this.state.pessoas.every(p => p._selecionado)}/>
							)} body={(p, i) => (
								<CheckSquare index={i.rowIndex} onChange={this.toggleSelecionar} checked={p._selecionado}/>
							)}/>
							<Column sortable style={{width: "25em", whiteSpace: "nowrap", overflow: "hidden"}} header="Nome" field="nome"/>
							<Column sortable style={{width: "*"}} header="Horários de Acesso Normal" body={pessoa => (
								pessoa._horarios.periodos.map(p => simplificarDiasSemana(p) + ", das " + LocalTime.withoutSeconds(p.inicio) + " às " + LocalTime.withoutSeconds(p.fim)).join("; ")
							)}/>
						</DataTable>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action disabled={this.disableSalvar()} mode="post" label="Salvar" onClick={this.salvarAgenda} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarAgenda} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "./Action";
import {DialogContent} from "./DialogContent";
import {DialogFooter} from "./DialogFooter";

export class GoogleMap extends React.Component {

	state = {
		visible: true
	};

	onHide = () => this.setState({visible: false});

	render() {
		return (
			<Dialog visible={this.state.visible} header="Localização" onHide={this.onHide}>
				<DialogContent>
					<iframe title="Google Map" width={600} height={450} frameBorder={0} style={{border: 0}} src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAbXaF2I1H6X4F8hmCVc_Iri6oxwB1pSLs&q=${this.props.location}`} allowFullScreen />
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onHide} />
				</DialogFooter>
			</Dialog>
		);
	}

}

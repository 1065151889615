import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MTarifaConta} from "../../utils/models/MTarifaConta";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {TarifaContaService} from "../servicos/TarifaContaService";
import {SelectPessoa} from "../../select/SelectPessoa";
import {Combobox} from "../commons/Combobox";
import {TextField} from "../commons/TextField";

export class EditarTarifaConta extends React.Component {

	state = {
		tarifaConta: Object.assign(MTarifaConta.Modelo(), this.props.tarifaConta),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	tarifaContaService = new TarifaContaService();
	security = UserData.security("TCT", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarTarifaConta = () => {
		let tarifaConta = MTarifaConta.cleanupBeforeSave(Object.assign({}, this.state.tarifaConta));
		let messages = MTarifaConta.validarParaSalvar(tarifaConta);
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			this.setState({lockSave: true});
			this.tarifaContaService.salvar(tarifaConta).then((tarifaConta) => {
				this.setState({lockSave: false});
				if (this.props.onModalClose) {
					this.props.onModalClose(tarifaConta);
				}
				this.onClose();
			}).catch(() => this.setState({lockSave: false}));
		}
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleChange = (event) => {
		let tarifaConta = this.state.tarifaConta;
		tarifaConta[event.name] = event.value;
		this.setState({tarifaConta});
	}

	render() {
		return (
			<Dialog modal header="Conta de Crédito" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox grid={3} options={MTarifaConta.Status} name="status" label="Status" value={this.state.tarifaConta.status} onChange={this.handleChange} />
					<Combobox label="Modalidade" grid={3} options={MTarifaConta.Modalidades} name="modalidade" value={this.state.tarifaConta.modalidade} onChange={this.handleChange} />
					<TextField grid={3} disabled={this.state.tarifaConta.modalidade === "PREPAGO"} onChange={this.handleChange} label="Limite Negativo" type="decimal" unit="Cr" minValue={0} name="creditoMaximo" value={this.state.tarifaConta.creditoMaximo} />
					<TextField grid={3} onChange={this.handleChange} label="Limite Diário" type="decimal" unit="Cr" minValue={0} name="limite" value={this.state.tarifaConta.limite} />
					<SelectPessoa name="pessoa" label="Pessoa" onChange={this.handleChange} grid={12} value={this.state.tarifaConta.pessoa} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.tarifaConta.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/tarifa-contas", this.state.tarifaConta)} /> : null}
					<Action disabled={!(this.security.edit || (this.security.create && !this.state.tarifaConta.id)) || this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarTarifaConta} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarTarifaConta} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React from "react";
import {UserData} from "../utils/UserData";
import {TarifaContaService} from "../components/servicos/TarifaContaService";
import {SuggestionBox} from "../components/commons/SuggestionBox";

export class SelectTarifaConta extends React.Component {

	state = {
		tarifaContas: []
	};

	service = new TarifaContaService();

	security = UserData.security("TCT");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <SuggestionBox field="id" entidade="tarifaConta" insertable={this.security.create && this.props.insertable} service={this.service} itemTemplate={this.itemTemplate} completeMethod={this.listarContas} {...this.props} onSelect={this.onSelect} suggestions={this.state.tarifaContas} />;
	}

	itemTemplate = (tarifaConta) => {
		return (
			<div>
				<div><b>Nº {tarifaConta.id}</b></div>
				<div>{tarifaConta.pessoa.nome}</div>
			</div>
		);
	}

	listarContas = (event) => {
		const id = event.query.replace(/[^0-9]/g, "");
		if (id && id.length) {
			this.service.listar(`search=status:ATIVADO;id:${id}`, {page: 0, size: 10, sort: "id"}).then((tarifaContas) => this.setState({tarifaContas}));
		}
	}

}

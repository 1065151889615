import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeExcluir from "../../media/icones/excluir.png";
import {GUI} from "../../utils/Constants";
import {LocalDate, LocalDateTime} from "../../utils/DateUtils";
import {MAviso} from "../../utils/models/MAviso";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {SuggestionBox} from "../commons/SuggestionBox";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {AvisoService} from "../servicos/AvisoService";
import {OperadorService} from "../servicos/OperadorService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";

export class PreAgendamento extends React.Component {

	state = {
		pessoa: "",
		data: LocalDate.now(),
		avisos: [],
		etiquetas: []
	};

	security = UserData.security("AGN");

	securityPessoa = UserData.security("PES");

	UNSAFE_componentWillMount() {
		this.operadorService.listar(`usuario=id:${UserData.usuario.id}`, {page: 0, size: 1}).then(operadores => {
			if (operadores.length > 0 && operadores[0].etiquetas.length > 0) {
				let etiquetas = operadores[0].etiquetas.filter(e => e.flags.includes("UNIDADE")).sort((a, b) => a.descricao.localeCompare(b.descricao));
				this.setState({etiqueta: etiquetas[0], etiquetas: etiquetas.map(etiqueta => {return {label: etiqueta.descricao, value: etiqueta}})});
				setTimeout(this.listarAvisos, 50);
			} else window.location.replace("/");
		});
	}

	avisoService = new AvisoService();

	operadorService = new OperadorService();

	pessoaFisicaService = new PessoaFisicaService();

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
		if (event.name !== "pessoa") setTimeout(this.listarAvisos, 50);
	}

	listarAvisos = () => {
		this.avisoService.listarLiberacoes(this.state.etiqueta.id, this.state.data).then(avisos => this.setState({avisos}));
	}

	selectPessoa = (event) => {
		let data = LocalDateTime.toLocalDate(this.state.data);
		if (event.value.id === -1) {
			let value = event.value.nome.replace("Criar pessoa ", "");
			let property = Validator.isCPF(value) ? "cpf" : !isNaN(Number(value)) ? "rg" : "nome";
			if (property === "cpf") value = value.formatCpf();
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={{...MPessoaFisica.Modelo(), [property]: value}} onModalClose={(pessoa) => {
				let aviso = MAviso.Modelo();
				aviso.pessoa = pessoa;
				aviso.etiqueta = this.state.etiqueta;
				aviso.inicio = LocalDateTime.atStartOfDayISO(data);
				aviso.fim = LocalDateTime.atEndOfDayISO(data);
				aviso.tipo = "LIBERACAO";
				this.avisoService.salvar(aviso).then(this.listarAvisos);
			}} />);
		} else {
			let aviso = MAviso.Modelo();
			aviso.pessoa = event.value;
			aviso.etiqueta = this.state.etiqueta;
			aviso.inicio = LocalDateTime.atStartOfDayISO(data);
			aviso.fim = LocalDateTime.atEndOfDayISO(data);
			aviso.tipo = "LIBERACAO";
			this.avisoService.salvar(aviso).then(this.listarAvisos);
		}
		this.setState({pessoa: ""});
	}

	excluirLiberacao = (aviso) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta liberação?", () => {
			this.avisoService.excluir(aviso).then(this.listarAvisos);
		});
	}

	columns = [
		<Column key={0} style={{width: "*"}} header="Pessoa" body={(aviso) => aviso.pessoa.nome} />,
		<Column key={1} style={{width: "10em"}} header="CPF" body={(aviso) => aviso.pessoa.cpf ? aviso.pessoa.cpf.formatCpf() : null} />,
		<Column key={2} style={{width: "9em"}} header="RG" body={(aviso) => aviso.pessoa.rg} />,
		<Column key={3} style={{width: "9em"}} header="Telefone" body={(aviso) => aviso.pessoa.telefone || aviso.pessoa.celular} />,
		<Column key={4} style={{width: "5em", textAlign: "center"}} header="Ações" body={(aviso) => {
			return this.security.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Liberação" onClick={(event) => this.excluirLiberacao(aviso)} /> : null;
		}} />
	];

	listarPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.query}`, {size: 10, page: 0}).then((pessoas) => {
			if (pessoas.length === 0 && this.securityPessoa.edit) {
				this.setState({pessoas: [{id: -1, nome: `Criar pessoa ${event.query}`}]});
			} else {
				this.setState({pessoas});
			}
		});
	}

	refSuggestionPessoa = (el) => this.suggestionPessoa = el;

	render() {
		return (
			<Panel header="Pré-Agendamentos">
				<PanelContent>
					<DatePicker minDate={new Date()} name="data" grid={3} inline value={this.state.data} onChange={this.handleChange}/>
					<div className="ui-g-9 ui-g-nopad">
						<div className="ui-g">
							<Combobox grid={2} options={this.state.etiquetas} name="etiqueta" label="Unidade" value={this.state.etiqueta} onChange={this.handleChange} />
							<SuggestionBox ref={this.refSuggestionPessoa} label="Pessoas" name="pessoa" grid={10} field="nome" completeMethod={this.listarPessoas} suggestions={this.state.pessoas} onChange={this.handleChange} onSelect={this.selectPessoa} value={this.state.pessoa} />
							<div className="ui-g-12">
								<DataTable children={this.columns} value={this.state.avisos} header={`Liberações para o dia ${moment(this.state.data).format("DD/MM/YYYY")}`} emptyMessage="Nenhum registro encontrado" paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} />
							</div>
						</div>
					</div>
				</PanelContent>
				<ModalRealm/>
			</Panel>
		);
	}

}

export class MContratoTrabalhoPeriodo {

    static Modelo() {
        return {
            _key: Math.random(),
            inicio: null,
            fim: null,
            matrizHorario: null
        };
    }

}

import {AutoComplete} from "primereact/components/autocomplete/AutoComplete";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeRemover from "../../media/icones/remover.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectMessengerBot} from "../../select/SelectMessengerBot";
import {MCamera} from "../../utils/models/MCamera";
import {MCredencial} from "../../utils/models/MCredencial";
import {MLogAcesso} from "../../utils/models/MLogAcesso";
import {MMonitoramento} from "../../utils/models/MMonitoramento";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {UserData} from "../../utils/UserData";
import {EditarCamera} from "../Camera/EditarCamera";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EditarPeriodo} from "../Periodo/EditarPeriodo";
import {CameraService} from "../servicos/CameraService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {LugarService} from "../servicos/LugarService";
import {MonitoramentoService} from "../servicos/MonitoramentoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";

export class EditarMonitoramento extends React.Component {

	state = {
		monitoramento: Object.assign(MMonitoramento.Modelo(), this.props.monitoramento),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	service = new MonitoramentoService();
	pessoaFisicaService = new PessoaFisicaService();
	lugarService = new LugarService();
	equipamentoService = new EquipamentoService();
	cameraService = new CameraService();
	security = UserData.security("MON", true);

	onClose = () => {
		this.setState({visible: false});
	}

	handleChange = (value, key) => {
		this.setState({monitoramento: {...this.state.monitoramento, [key]: value}});
	}

	listEquipamentos = (event) => {
		this.equipamentoService.listar(`search=descricao~${event.query}`, {size: 10, page: 0, sort: "descricao"}).then((list) => this.setState({listEquipamentos: list}));
	}

	listCameras = (event) => {
		this.cameraService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((listCameras) => {
			if (listCameras.length === 0) {
				this.setState({listCameras: [{...MCamera.Modelo(), id: -1, descricao: `Criar câmera ${event.query}`}]});
			} else {
				this.setState({listCameras});
			}
		});
	}

	selecionarCameras = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarCamera key={Math.random()} camera={{...MCamera.Modelo(), descricao: event.value.descricao.replace("Criar câmera ", "")}} onModalClose={(camera) => {
				let monitoramento = this.state.monitoramento;
				monitoramento.cameras.push(camera);
				this.setState({monitoramento});
			}} />);
		}
	}

	handleEntityListSuggestion = (event) => {
		let monitoramento = this.state.monitoramento;
		event.value = event.value.filter(e => e.id !== -1);
		monitoramento[event.name] = event.value;
		this.setState({monitoramento});
	}

	listPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.query}`, {size: 10, page: 0}).then((list) => this.setState({listPessoas: list}));
	}

	listLugares = (event) => {
		this.lugarService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((list) => this.setState({listLugares: list}));
	}

	saveMonitoramento = () => {
		if (this.security.edit) {
			let monitoramento = this.state.monitoramento;
			Object.keys(monitoramento).forEach((property) => monitoramento.property === "" && delete monitoramento[property]);
			this.setState({lockSave: true});
			this.service.salvar(monitoramento).then((response) => {
				if (this.props.onSave) {
					this.props.onSave(response);
				}
				this.setState({visible: false, lockSave: false});
			}).catch(() => this.setState({lockSave: false}));
		}
	}

	removePessoa = (event, rowData) => {
		this.setState({monitoramento: {...this.state.monitoramento, pessoas: this.state.monitoramento.pessoas.filter((p) => p.id !== rowData.id)}});
	}

	removeMonitor = (event, rowData) => {
		this.setState({monitoramento: {...this.state.monitoramento, monitores: this.state.monitoramento.monitores.filter((p) => p.id !== rowData.id)}});
	}

	pessoaColumns = [
		<Column header="Nome" key="nome" field="nome" style={{width: "*"}} />,
		<Column header="CPF" key="cpf" field="cpf" style={{width: "10em"}} />,
		<Column header="RG" key="rg" field="rg" style={{width: "7em"}} />,
		<Column header="Ações" key="acoes" field="acoes" style={{width: "4em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Pessoa" onClick={(event) => this.removePessoa(event, rowData)} /> : null}
				</div>
			);
		}} />
	];

	monitorColumns = [
		<Column header="Nome" key="nome" field="nome" style={{width: "*"}} />,
		<Column header="Email" key="email" field="email" style={{width: "15em"}} />,
		<Column header="Ações" key="acoes" field="acoes" style={{width: "4em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Monitor" onClick={(event) => this.removeMonitor(event, rowData)} /> : null}
				</div>
			);
		}} />
	];

	periodoColumns = [
		<Column style={{width: "8em"}} key="inicio" field="inicio" header="Início" className="ui-tzm-column-center" />,
		<Column style={{width: "8em"}} key="fim" field="fim" header="Fim" className="ui-tzm-column-center" />,
		<Column style={{width: "*"}} key="diasSemana" field="diasSemana" header="Dias da Semana" body={(rowData) => this.printDaysOfWeek(rowData.diasSemana)} />,
		<Column style={{width: "6em"}} key="feriados" field="feriados" header="Feriados" body={(rowData) => <div style={{textAlign: "center"}}>{rowData.feriados ? "Sim": "Não"}</div>} />,
		<Column style={{width: "6em"}} key="acoes" field="acoes" header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Período" onClick={(event) => this.editPeriodo(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Período" onClick={(event) => this.removePeriodo(rowData)} /> : null}
				</div>
			);
		}} />
	];

	addPessoa = (event) => {
		let pessoas = this.state.monitoramento.pessoas;
		pessoas.push(event.value);
		this.setState({monitoramento: {...this.state.monitoramento, pessoas: pessoas}});
		this.setState({pessoaQuery: ""});
	}

	addMonitor = (event) => {
		let monitores = this.state.monitoramento.monitores;
		monitores.push(event.value);
		this.setState({monitoramento: {...this.state.monitoramento, monitores: monitores}});
		this.setState({monitorQuery: ""});
	}

	editPeriodo = (periodo) => {
		ModalRealm.showDialog(<EditarPeriodo key={Math.random()} periodo={periodo} onSave={(periodo) => {
			let periodos = this.state.monitoramento.periodos;
			periodos = periodos.filter((p) => p.id !== periodo.id);
			periodos.push(periodo);
			this.setState({monitoramento: {...this.state.monitoramento, periodos: periodos}});
		}} />);
	}

	removePeriodo = (periodo) => {
		let periodos = this.state.monitoramento.periodos;
		periodos = periodos.filter((p) => p !== periodo);
		this.setState({monitoramento: {...this.state.monitoramento, periodos: periodos}});
	}

	periodoFooter = <div style={{textAlign: "left"}}>
		<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editPeriodo(null)} />
	</div>;

	printDaysOfWeek(list) {
		return list != null ? <div style={{textAlign: "center"}}>{list.map((dow) => {
			switch (dow) {
			case "SUNDAY": return "Dom";
			case "MONDAY": return "Seg";
			case "TUESDAY": return "Ter";
			case "WEDNESDAY": return "Qua";
			case "THURSDAY": return "Qui";
			case "FRIDAY": return "Sex";
			case "SATURDAY": return "Sáb";
			default: return null;
			}
		}).join(" - ")}</div> : null;
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	onChange = (event) => {
		const monitoramento = this.state.monitoramento;
		monitoramento[event.name] = event.value;
		this.setState({monitoramento});
	}

	render() {
		return (
			<Dialog modal header="Monitoramento" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<div className="ui-g">
								<div className="ui-g-12">
									<Label>Atividades</Label>
									<MultiCombobox appendTo={document.body} defaultLabel="Todas" onChange={(event) => this.handleChange(event.value, "atividades")} value={this.state.monitoramento.atividades} options={MLogAcesso.Atividades} />
								</div>
								<div className="ui-g-12">
									<Label>Respostas</Label>
									<MultiCombobox appendTo={document.body} defaultLabel="Todas" onChange={(event) => this.handleChange(event.value, "respostas")} value={this.state.monitoramento.respostas} options={MLogAcesso.Respostas} />
								</div>
								<div className="ui-g-12">
									<Label>Eventos</Label>
									<MultiCombobox appendTo={document.body} defaultLabel="Nenhuma" onChange={(event) => this.handleChange(event.value, "eventos")} value={this.state.monitoramento.eventos} options={MOcorrencia.Eventos} />
								</div>
								<div className="ui-g-12">
									<Label>Formas</Label>
									<MultiCombobox appendTo={document.body} defaultLabel="Todas" onChange={(event) => this.handleChange(event.value, "formas")} value={this.state.monitoramento.formas} options={MCredencial.Formas} />
								</div>
								<div className="ui-g-12">
									<Label>Equipamentos</Label>
									<SuggestionBox appendTo={document.body} multiple field="descricao" suggestions={this.state.listEquipamentos} completeMethod={this.listEquipamentos} value={this.state.monitoramento.equipamentos} onChange={(event) => this.setState({monitoramento: {...this.state.monitoramento, equipamentos: event.value}})} />
								</div>
								<div className="ui-g-12">
									<Label>Lugares</Label>
									<SuggestionBox appendTo={document.body} multiple field="descricao" suggestions={this.state.listLugares} completeMethod={this.listLugares} value={this.state.monitoramento.lugares} onChange={(event) => this.setState({monitoramento: {...this.state.monitoramento, lugares: event.value}})} />
								</div>
								<MultiSelectEtiqueta grid={12} label="Etiquetas" onSelect={this.onChange} name="etiquetas" value={this.state.monitoramento.etiquetas} />
							</div>
						</TabPanel>
						<TabPanel header="Horários">
							<div className="ui-g">
								<div className="ui-g-12">
									<DataTable emptyMessage="Monitorar tempo integral" value={this.state.monitoramento.periodos} children={this.periodoColumns} rows={10} paginator paginatorLeft={this.periodoFooter} />
								</div>
							</div>
						</TabPanel>
						<TabPanel header="Ações">
							<div className="ui-g">
								<div className="ui-g-12">
									<Label>Ações</Label>
									<MultiCombobox appendTo={document.body} defaultLabel="Nenhuma" onChange={(event) => this.handleChange(event.value, "disparos")} value={this.state.monitoramento.disparos} options={MMonitoramento.Disparos} />
								</div>
								<div className="ui-g-12">
									<Label>Título do email</Label>
									<TextField onChange={(event) => this.handleChange(event.target.value, "titulo")} value={this.state.monitoramento.titulo} />
								</div>
								<div className="ui-g-12">
									<Label>Conteúdo do email/SMS</Label>
									<TextField multiline style={{height: "6em"}} onChange={(event) => this.handleChange(event.target.value, "texto")} value={this.state.monitoramento.texto} />
								</div>
								<div className="ui-g-12">
									<Label>Câmeras</Label>
									<SuggestionBox appendTo={document.body} name="cameras" multiple onSelect={this.selecionarCameras} field="descricao" suggestions={this.state.listCameras} completeMethod={this.listCameras} value={this.state.monitoramento.cameras} onChange={this.handleEntityListSuggestion} />
								</div>
								<SelectMessengerBot grid={12} label="Bot Mensageiro" name="messengerBot" value={this.state.monitoramento.messengerBot} onChange={this.onChange} />
							</div>
						</TabPanel>
						<TabPanel header="Pessoas">
							<div className="ui-g">
								<div className="ui-g-12">
									<Label>Pessoas</Label>
									<AutoComplete appendTo={document.body} placeholder="Adicione pessoas específicas a serem monitoradas ou deixe em branco para monitorar todos" field="nome" suggestions={this.state.listPessoas} completeMethod={this.listPessoas} onSelect={this.addPessoa} value={this.state.pessoaQuery} onChange={(event) => this.setState({pessoaQuery: event.value})} />
								</div>
								<div className="ui-g-12">
									<DataTable emptyMessage="Monitorar todas as pessoas" rows={10} paginator value={this.state.monitoramento.pessoas} children={this.pessoaColumns} />
								</div>
							</div>
						</TabPanel>
						<TabPanel header="Monitores">
							<div className="ui-g">
								<div className="ui-g-12">
									<Label>Monitores</Label>
									<AutoComplete appendTo={document.body} placeholder="Adicione pessoas que receberão os emails e mensagens de monitoria" field="nome" suggestions={this.state.listPessoas} completeMethod={this.listPessoas} onSelect={this.addMonitor} value={this.state.monitorQuery} onChange={(event) => this.setState({monitorQuery: event.value})} />
								</div>
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhum monitor adicionado" rows={10} paginator value={this.state.monitoramento.monitores} children={this.monitorColumns} />
								</div>
							</div>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.monitoramento.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/monitoramentos", this.state.monitoramento)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.monitoramento.id)) || this.state.lockSave} onClick={this.saveMonitoramento} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.saveMonitoramento} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

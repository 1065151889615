import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MPerfil {

	static Modelo() {
		return {
			descricao: "",
			tipo: "NORMAL",
			roles: [
				{nome: "CAM", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "OPR", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "TBL", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MON", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "OCO", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "POR", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "GUA", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "ENC", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "AVI", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MAN", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "ETQ", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "PRF", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "USR", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "SCH", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "CRD", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "EQP", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MTH", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "LOC", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "LUG", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "PER", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "PES", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "EMP", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "AGN", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "ESP", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MSG", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MAC", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "RES", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "UNI", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "FER", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "LGA", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "JTB", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "VEI", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "CTR", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "CFG", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "CTB", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "BLK", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "REL", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "COM", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "LPT", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MBT", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "ANI", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MNA", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "TAR", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "TCT", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "TSD", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "MAG", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "AAC", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "GRT", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "FRM", visualizar: true, criar: true, editar: true, remover: true},
				{nome: "RPR", visualizar: true, criar: true, editar: true, remover: true}
			],
			reservaQuantidadeMaxima: 0,
			reservaDiasAntecedencia: 0,
		};
	}

	static Nomes = {
		CAM: "Câmeras",
		OPR: "Operadores",
		TBL: "Tablet",
		MON: "Monitoramentos",
		OCO: "Ocorrências",
		POR: "Portarias",
		GUA: "Guaritas",
		AVI: "Avisos",
		MAN: "Manutenção",
		ETQ: "Etiquetas",
		ENC: "Encomendas",
		PRF: "Perfis",
		USR: "Usuários",
		MTH: "Matrizes de Horário",
		SCH: "Agendas",
		CRD: "Credenciais",
		EQP: "Equipamentos",
		LOC: "Localidades",
		LUG: "Lugares",
		PER: "Períodos",
		MBT: "Bots Mensageiros",
		PES: "Pessoas",
		EMP: "Empresas",
		LPT: "Registros de Ponto",
		CTB: "Contratos de Trabalho",
		AGN: "Pré-Agendamentos",
		ESP: "Espaços",
		MAG: "Modelos de Agenda",
		MSG: "Mensagens",
		MAC: "Modelos de Acesso",
		RES: "Reservas",
		UNI: "Unidades",
		FER: "Feriados",
		JTB: "Jornadas de Trabalho",
		LGA: "Registros de Acesso",
		VEI: "Veículos",
		CTR: "Controladores",
		TAR: "Tarifas",
		CFG: "Configurações",
		BLK: "Bloqueios",
		REL: "Relatórios",
		COM: "Comunicados",
		ANI: "Animais",
		AVS: "Relatorio de Servicos",
		MNA: "Monitor de Presença",
		TCT: "Contas de Crédito",
		TSD: "Entrada de Crédito",
		AAC: "Autorização de Acesso",
		DOC: "Documentos",
		GRT: "Grupos de Trabalho",
		FRM: "Formulários",
		RPR: "Relatórios Programados"
	};

	static NameFor(name) {
		let value = MPerfil.Nomes[name];
		return value != null ? value : "";
	}

	static Tipos = [
		{label: "Normal", value: "NORMAL"},
		{label: "Administrador", value: "ADMINISTRADOR"}
	];

	static cleanupBeforeSave(perfil) {
		return MEntidade.cleanupBeforeSave(perfil);
	}

	static validarParaSalvar(perfil) {
		let messages = [];
		if (Validator.isEmpty(perfil.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MModeloAgenda {

    static Modelo() {
		return {
			descricao: "",
			modelosAcesso: [],
			flags: [],
			agendaFlags: []
		};
	}

	static Flags = [
		{label: "Unidade Obrigatória", value: "FORCAR_UNIDADE"},
		{label: "Visitado Obrigatório", value: "FORCAR_VISITADO"},
		{label: "Credencial Obrigatória", value: "FORCAR_CREDENCIAL_TEMPORARIA"},
		{label: "Veículo Obrigatório", value: "FORCAR_VEICULO"},
		{label: "Observação Obrigatória", value: "FORCAR_OBSERVACAO"},
		{label: "RG/CPF como Credenciais", value: "PERMITIR_DOCUMENTO"},
		{label: "Adicional de Portaria", value: "PORTARIA_ADICIONAL"}
	];

    static Obrigatorios = [
		{label: "RG", value: "RG"},
		{label: "CPF", value: "CPF"},
		{label: "NIS", value: "NIS"},
		{label: "Email", value: "EMAIL"},
		{label: "Empresa", value: "EMPRESA"},
		{label: "Telefone", value: "TELEFONE"},
		{label: "Nascimento", value: "NASCIMENTO"},
	    {label: "Foto", value: "FOTO"}
	];

	static FlagsNoDocs = [
		{label: "Unidade Obrigatória", value: "FORCAR_UNIDADE"},
		{label: "Visitado Obrigatório", value: "FORCAR_VISITADO"},
		{label: "Agenda Temporária", value: "TEMPORARIA"}
	];

	static MapFlags = {
		"FORCAR_UNIDADE": "Unidade Obrigatória",
		"FORCAR_VISITADO": "Visitado Obrigatório",
		"FORCAR_CREDENCIAL_TEMPORARIA": "Credencial Obrigatória",
		"PERMITIR_DOCUMENTO": "RG/CPF como Credenciais",
		"FORCAR_VEICULO": "Veículo Obrigatório",
		"FORCAR_OBSERVACAO": "Observação Obrigatória",
		"PORTARIA_ADICIONAL": "Adicional de Portaria",
		"FORCAR_PRINCIPAL": "Principal Obrigatório",
		"FORCAR_EMPRESA": "Empresa Obrigatória"
	};

	static cleanupBeforeSave(modeloAgenda) {
		return MEntidade.cleanupBeforeSave(modeloAgenda);
	}

	static validarParaSalvar(modeloAgenda) {
		let messages = [];
        if (Validator.isEmpty(modeloAgenda.descricao)) messages.push("A descrição é obrigatória");
        if (!modeloAgenda.modelosAcesso || !modeloAgenda.modelosAcesso.length) messages.push("Selecione pelo menos um modelo de acesso");
		return messages;
	}

}

import React from "react";
import "./Loading.css";

export class Loading extends React.Component {

  render() {
    return (
      <div>
        <div className="cssload-tetrominos">
          <div className="cssload-tetromino cssload-box1"></div>
          <div className="cssload-tetromino cssload-box2"></div>
          <div className="cssload-tetromino cssload-box3"></div>
          <div className="cssload-tetromino cssload-box4"></div>
        </div>
        <div className="cssload-tetrominos-text">Carregando. Por favor, aguarde...</div>
      </div>
    );
  }

}

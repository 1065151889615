import React from "react";
import ReactDOM from "react-dom";
import {Confirmacao} from "./Confirmacao";
import {Informacao} from "./Informacao";

export class ModalRealm extends React.Component {

	constructor() {
		super();
		ModalRealm.modalDialogs = [];
	}

	componentWillUnmount() {
		ModalRealm.modalDialogs = [];
		ReactDOM.unmountComponentAtNode(this.modalRealm);
	}

	refModalRealm = (el) => this.modalRealm = el;

	render() {
		return (
			<div>
				<div ref={this.refModalRealm} id="modal-realm" {...this.props} />
			</div>
		);
	}

	static modalDialogs = [];

	static clearDialogs() {
		ModalRealm.modalDialogs = [];
		ReactDOM.render(<div>{ModalRealm.modalDialogs}</div>, document.getElementById("modal-realm"));
	}

	static showDialog(dialog) {
		ModalRealm.modalDialogs.push(dialog);
		ReactDOM.render(<div>{ModalRealm.modalDialogs}</div>, document.getElementById("modal-realm"));
	}

	static showInformacao(type, messages, onOk) {
		ModalRealm.showDialog(<Informacao key={Math.random()} messages={messages} type={type} onOk={onOk}/>);
	}

	static showConfirmacao(message, onYes, onNo, options) {
		ModalRealm.showDialog(<Confirmacao key={Math.random()} {...options} message={message} onYes={onYes} onNo={onNo} />);
	}

}

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarControlador} from "../components/Controlador/EditarControlador";
import {ControladorService} from "../components/servicos/ControladorService";
import {MControlador} from "../utils/models/MControlador";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectControlador extends React.Component {

	service = new ControladorService();

	security = UserData.security("CTR");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarControlador key={Math.random()} controlador={{...MControlador.Modelo(), descricao: event.value.descricao.replace(`Criar controlador `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return (
			<Select
				label="Controlador"
				name="controlador"
				insertable={this.security.create && this.props.insertable}
				entidade="controlador"
				service={this.service}
				field="descricao"
				query="descricao"
				onSelect={this.onSelect}
				{...this.props}
			/>
		);
	}

}

import React from "react";
import ResizeObserver from "react-resize-observer";
import {ModalRealm} from "../commons/ModalRealm";
import {GraficoAcessosDia} from "./GraficoAcessosDia";
import {GraficoAcessosHora} from "./GraficoAcessosHora";
import {GraficoSumario} from "./GraficoSumario";

const chartLines = 3;

export class Home extends React.Component {

	state = {
		height: (window.innerHeight - 71 - 14 * (chartLines + 2)) / chartLines,
	};

	windowResize = () => {
		this.setState({height: (window.innerHeight - 71 - 14 * (chartLines + 1)) / chartLines});
	}

	render() {
		return (
			<div className="ui-g">
				<div style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: -1}}>
					<ResizeObserver onResize={this.windowResize}/>
				</div>
				<div className="ui-g-12 ui-start-chart" style={{height: `${this.state.height}px`}}>
					<GraficoAcessosDia height={this.state.height}/>
				</div>
				<div className="ui-g-6 ui-start-chart"
				     style={{height: `${this.state.height}px`, marginRight: "7px", width: "calc(50% - 7px)"}}>
					<GraficoAcessosHora height={this.state.height}/>
				</div>
				<div className="ui-g-6 ui-start-chart" style={{height: `${this.state.height}px`, marginLeft: "7px", width: "calc(50% - 7px)"}}>
					<GraficoSumario height={this.state.height}/>
				</div>
				<ModalRealm/>
			</div>
		);
	}

}

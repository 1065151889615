import React, {Component} from "react";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {FotoWebcam} from "./FotoWebcam";
import {FotoCamera} from "./FotoCamera";
import {FotoServidorMidia} from "./FotoServidorMidia";
import {httpPost} from "../../utils/Request";
import {Label} from "../commons/Label";
import {toSrc} from "../../utils/StringUtils";
import {finalidades, mapFinalidade} from "../../utils/models/MFoto";
import {byKeyOrId} from "../../utils/FilterUtils";
import {FotoControliD} from "./FotoControliD";

export class Foto extends Component {

	state = {
		activeIndex: Number(window.localStorage.getItem("X-Foto-Aba") || "0"),
		visible: true,
		lockSave: false,
		fotos: [],
		_mod: false
	};

	componentDidMount() {
		if (this.tabView && this.imgView) {
			this.imgView.style.maxHeight = this.tabView.clientHeight + "px";
			this.setState({fotos: this.props.fotos});
		}
	}

	onClose = () => this.setState({visible: false});

	salvarFotografias = async () => {
		const {fotos} = this.state;
		for (let i = 0; i < fotos.length; ++i) {
			fotos[i] = await httpPost("/fotos", fotos[i]);
		}
		if (this.props.onModalClose) {
			this.props.onModalClose(fotos, this.state._mod);
		}
		this.onClose();
	}

	onPhotoTaken = foto => {
		const {fotos} = this.state;
		foto._key = Math.random();
        fotos.unshift(foto);
        this.setState({fotos, _mod: true});
	}

	tabChange = event => {
		this.setState({activeIndex: event.index}, () => {
			window.localStorage.setItem("X-Foto-Aba", event.index);
		});
	}

	printFoto = foto => {
		return (
			<div key={foto._key || foto.id} className="foto-galeria-imagem">
				<img alt="" src={toSrc(foto)}/>
				<span onClick={() => {
					const {fotos} = this.state;
					fotos.filter(f => byKeyOrId(f, foto)).forEach(f => {
						f.finalidade = finalidades[(finalidades.indexOf(f.finalidade) + 1) % finalidades.length];
					});
					this.setState({fotos, _mod: true});
				}}>{mapFinalidade[foto.finalidade]}</span>
				<i onClick={() => {
					let {fotos} = this.state;
					fotos = fotos.filter(f => !byKeyOrId(f, foto));
					this.setState({fotos, _mod: true});
				}} className="fa fa-trash-alt"/>
			</div>
		);
	}

	refTabView = el => this.tabView = el;
	refImgView = el => this.imgView = el;

	render() {
		if (!this.state.visible) {
			return null;
		}
		return (
			<Dialog modal header="Fotos" onHide={this.onClose} visible={this.state.visible} style={{width: `${this.props.width + 310}px`, textAlign: "center", whiteSpace: 'nowrap'}}>
				<div className="ui-tzm-dialogcontent">
					<div className="ui-g" ref={this.refTabView}>
						<TabView style={{width: `${this.props.width + 30}px`}} activeIndex={this.state.activeIndex} onTabChange={this.tabChange}>
							<TabPanel header="Webcam">
								<FotoWebcam {...this.props} onPhotoTaken={this.onPhotoTaken}/>
							</TabPanel>
							<TabPanel header="Totem">
								<FotoCamera {...this.props} onPhotoTaken={this.onPhotoTaken}/>
							</TabPanel>
							<TabPanel header="Equipamento">
								<FotoServidorMidia {...this.props} onPhotoTaken={this.onPhotoTaken}/>
							</TabPanel>
							<TabPanel  header="ControliD">
								<FotoControliD {...this.props} onPhotoTaken={this.onPhotoTaken}/>
							</TabPanel>
						</TabView>
						<div ref={this.refImgView} className="foto-galeria ui-g">
							<Label style={{marginBottom: "6px"}}>Galeria</Label>
							{this.state.fotos.map(this.printFoto)}
						</div>
					</div>
				</div>
				<DialogFooter>
					<Action disabled={this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarFotografias} icon="fa-camera"/>
					<Action disabled={this.state.lockSave} mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarFotografias} onEscape={this.onClose}/> : null}
			</Dialog>
		)
	}

}

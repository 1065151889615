import {Dropdown} from "primereact/components/dropdown/Dropdown";
import React from "react";
import {Label} from "./Label";
import classNames from "classnames";

export class Combobox extends React.Component {

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({...event, name: this.props.name, field: this.props.field, index: this.props.index});
		}
	}

	render() {
		const classes = classNames({
			[`ui-g-${this.props.grid}`]: true
		});
		const inputClasses = classNames({
			"ui-field-required": this.props.required && !this.props.value
		});
		return (
			<div className={classes}>
				<Label>{this.props.label}</Label>
				<Dropdown className={inputClasses} {...this.props} style={{width: "100%"}} onChange={this.onChange} />
			</div>
		);
	}

}

import {httpGet, httpPost} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class LogAcessoService extends EntidadeNextGenService {

	constructor() {
		super("/logs-acesso");
	}

	consolidar() {
		return httpGet("/graficos/acessos-por-hora");
	}

	count(lugares) {
		lugares = lugares && lugares.length ? lugares.map(l => l.id).join(",") : null;
		return httpGet(`/logs-acesso/count/in${lugares ? `/${lugares}` : ""}`);
	}

	ultimosAcessos() {
		return httpGet("/home/ultimos-acessos");
	}

	async listarDTO(query, offset) {
		return await httpGet(`${this.basePath}/dto?${query}&page=${offset.page}&size=${offset.size}${offset.sort != null ? `&sort=${offset.sort}` : ""}`);
	}

    async removeFlag(id, flag) {
		return await httpPost(`${this.basePath}/flags/disable/${flag}/${id}`);
    }

}

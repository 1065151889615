import React from "react";
import {Combobox} from "../commons/Combobox";

export class ComboboxModeloAcessoObrigatoriedade extends React.Component {

	render() {
		return (
			<Combobox
				name="obrigatoriedade"
				options={optionsDocumentoObrigatorioObrigatoriedade}
				label={null}
				grid={12}
				{...this.props}
			/>
		);
	};

}

export const optionsDocumentoObrigatorioObrigatoriedade = [
	{label: "Todos", value: "TODOS"},
	{label: "Principal", value: "PRINCIPAL"},
	{label: "Secundários", value: "SECUNDARIOS"}
];

import {Tooltip} from "primereact/components/tooltip/Tooltip";
import React from "react";

export class GUIUtils {

  static imageWithTooltip(icon, title) {
    let id = "TooltipImage-" + Math.round(Math.random() * 10000);
		return (
			<div id={id} style={{textAlign: "center"}}>
				<img alt="" style={{verticalAlign: "middle"}} src={icon} />
				<Tooltip for={`#${id}`} title={title} tooltipPosition="top" />
			</div>
		);
  }

}

import {LocalDate} from "../DateUtils";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MFeriado {

	static Modelo() {
		return {
			descricao: "",
			data: LocalDate.now()
		};
	}

	static cleanupBeforeSave(feriado) {
		if (feriado.localidade != null && !Validator.isEntidade(feriado.localidade)) feriado.localidade = null;
		return MEntidade.cleanupBeforeSave(feriado);
	}

	static validarParaSalvar(feriado) {
		let messages = [];
		if (Validator.isEmpty(feriado.descricao)) messages.push("A descrição é obrigatória");
		if (Validator.isEmpty(feriado.data)) messages.push("A data é obrigatória");
		return messages;
	}

}

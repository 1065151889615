import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {CredencialService} from "../servicos/CredencialService";
import "./Credencial.css";
import {ModalRealm} from "../commons/ModalRealm";

const PASSWORD_LENGTH = 4;
const PASSWORD_PREFIX = 3;

export class CriarSenha extends React.Component {

	state = {
		visible: true,
		pessoa: this.props.pessoa,
		senha: "",
		senha1: "",
		senha2: "",
		lockSave: false,
		credencial: null
	};

	credencialService = new CredencialService();

	componentDidMount() {
		document.addEventListener("keypress", this.handleKeyPress);
	}

	onClose = () => {
		document.removeEventListener("keypress", this.handleKeyPress);
		this.setState({visible: false});
	}

	render() {
		const elements = [];
		if (this.state.credencial) {
			const codigo = String(this.state.credencial.codigo);
			for (let i = 0; i < PASSWORD_PREFIX; ++i) {
				elements.push(<div key={i} className="input-senha"><span>{codigo.charAt(i)}</span></div>);
			}
		}
		for (let i = 0; i < PASSWORD_LENGTH; ++i) {
			elements.push(<div key={PASSWORD_PREFIX + i} className={`input-senha ${this.state.senha.length > i ? "complete" : ""}`}>{this.state.senha.length > i ? <i className="fal fa-asterisk"/> : null}</div>);
		}
		return (
			<Dialog modal header="Criar Senha" visible={this.state.visible} style={{width: "450px"}} onHide={this.onClose}>
				<DialogContent>
					<div style={{width: "100%", padding: "0 1em 1em 1em"}}>
						{
							this.state.credencial
								? (
									<>
										<b><i className="fal fa-key"/> Senha criada com sucesso!</b><br/><br/>
										<div style={{display: "flex"}}>
											<b style={{flex: 1}}>ATENÇÃO </b>
											<span style={{flex: 5}}>
												Anote o prefixo gerado automaticamente pelo sistema.<br/>
												Ele deve ser utilizado junto à senha.
											</span>
										</div>
									</>
								) : this.state.senha1.length === PASSWORD_LENGTH
									? "Repita a senha digitada"
									: `Digite uma senha numérica de ${PASSWORD_LENGTH} dígitos`
						}
					</div>
					<div style={{width: "100%", textAlign: "center"}}>
						{elements}
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" disabled={this.state.lockSave} label={this.state.credencial ? "OK" : "Cancelar"} onClick={this.onClose}/>
				</DialogFooter>
			</Dialog>
		);
	}

	handleKeyPress = (event) => {
		if (!this.state.lockSave && event.keyCode >= 48 /*0*/ && event.keyCode <= 57 /*9*/) {
			let {senha} = this.state;
			senha += event.key;
			if (senha.length === PASSWORD_LENGTH) {
				if (this.state.senha1.length === PASSWORD_LENGTH) {
					if (senha === this.state.senha1) {
						this.setState({senha2: senha, senha, lockSave: true});
						setTimeout(() => {
							this.credencialService.salvar({naoPrefixar: false, pessoa: this.state.pessoa, forma: "SENHA", codigo: this.state.senha1, auxiliar: 0, descricao: `Senha ${this.state.pessoa.id}`}).then((credencial) => {
								this.setState({credencial, lockSave: false});
							}).catch(() => this.setState({lockSave: false}));
						}, 300);
					} else {
						ModalRealm.showInformacao("warn", ["As senhas digitadas não coincidem. Por favor, tente novamente."]);
						this.setState({senha: "", senha1: "", senha2: ""});
					}
				} else {
					this.setState({senha, lockSave: true});
					setTimeout(() => {
						this.setState({senha1: senha, senha: "", lockSave: false});
					}, 300);
				}
			} else if (senha.length < PASSWORD_LENGTH) {
				this.setState({senha});
			}
		}
	}

}

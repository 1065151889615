import React from "react";
import {Label} from "./Label";

export class FileUpload extends React.Component {

	handleChange = (event) => {
		const value = this.props.value;
		const file = event.target.files[0];
		if (file) {
			value.push(file);
			this.props.onChange({name: this.props.name, value});
		}
	}

	removeItem = (event, index) => {
		event.stopPropagation();
		const value = this.props.value;
		value.splice(index, 1);
		this.props.onChange({name: this.props.name, value});
	}

	handleLoad = () => {
		this.inputFile.click();
	}

	refInput = (el) => this.inputFile = el;

	render() {
		let component = this.props.value.map((file, index) => <div className="file-item" key={index}>{file.name}<i className="fa fa-times" onClick={(event) => this.removeItem(event, index)} /></div>);
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label help={this.props.help}>{this.props.label}<span style={{float: "right", fontSize: "11px"}}>Tamanho máximo por arquivo: 10MB</span></Label>
				<div onClick={this.handleLoad} className="ui-tzm-textfield file-item-container">{component}</div>
				<input style={{display: "none"}} ref={this.refInput} type="file" files={this.props.value} value="" multiple onChange={this.handleChange} />
			</div>
		);
	}

}

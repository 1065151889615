import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MOcorrencia {

	static Modelo() {
		return {
			evento: "EMERGENCIA",
			justificativa: ""
		};
	}

	static Eventos = [
		{label: "Modo de Emergência", value: "EMERGENCIA"},
		{label: "Abertura Não-Autorizada", value: "ARROMBAMENTO"},
		{label: "Tentativa de Carona", value: "ANTIDUPLA"},
		{label: "Porta Aberta", value: "PORTA_ABERTA"},
		{label: "Uso de Credencial Master", value: "MASTER"},
		{label: "Atendimento", value: "ATENDIMENTO"},
		{label: "Presença Não-Autorizada", value: "PRESENCA"},
		{label: "Uso de Credencial Inativa", value: "CREDENCIAL"},
		{label: "Posicionamento Incorreto", value: "POSICAO"},
		{label: "Modo de Treinamento", value: "TREINAMENTO"},
		{label: "Temperatura irregular", value: "TEMPERATURA"},
		{label: "Bloqueio de Segurança", value: "BLOQUEADO"},
		{label: "Bloqueio de Intervalo", value: "BLOQUEADO_INTERVALO"},
		{label: "Bloqueio de Interjornada", value: "BLOQUEADO_INTERJORNADA"},
		{label: "Autorização de Acesso", value: "AUTORIZACAO"},
		{label: "Acesso Fora de Horário", value: "FORA_DE_HORARIO"}
	];

	static MapEventos = {
		"EMERGENCIA": "Modo de Emergência",
		"ARROMBAMENTO": "Abertura Não-Autorizada",
		"ANTIDUPLA": "Tentativa de Carona",
		"PORTA_ABERTA": "Porta Aberta",
		"MASTER": "Uso de Credencial Master",		
		"ATENDIMENTO": "Atendimento",
		"PRESENCA": "Presença Não-Autorizada",
		"CREDENCIAL": "Uso de Credencial Inativa",
		"POSICAO": "Posicionamento Incorreto",
		"TREINAMENTO": "Modo de Emergência",
		"TEMPERATURA": "Temperatura irregular",
		"BLOQUEADO": "Bloqueio de Segurança",
		"BLOQUEADO_INTERVALO": "Bloqueio de Intervalo",
		"BLOQUEADO_INTERJORNADA": "Bloqueio de Interjornada",
		"AUTORIZACAO": "Autorização de Acesso",
		"FORA_DE_HORARIO": "Acesso Fora de Horário"
	};

	static cleanupBeforeSave(ocorrencia) {
		return MEntidade.cleanupBeforeSave(ocorrencia);
	}

	static validarParaSalvar(ocorrencia) {
		let messages = [];
		if (ocorrencia.justificar) {
			if (Validator.isEmpty(ocorrencia.justificativa)) messages.push("A justificativa da ocorrência é obrigatória");
		}
		return messages;
	}

}

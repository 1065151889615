import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MTarifaSaldo {

	static Modelo() {
		return {
			conta: null,
			valor: 0,
			tipo: "MOVIMENTACAO"
		};
	}

	static cleanupBeforeSave(tarifaSaldo) {
		return MEntidade.cleanupBeforeSave(tarifaSaldo);
	}

	static validarParaSalvar(tarifaSaldo) {
		let messages = [];
		if (Validator.isEmpty(tarifaSaldo.valor)) messages.push("O valor deve ser diferente de zero");
		if (!Validator.isEntidade(tarifaSaldo.conta)) messages.push("A conta é obrigatória");
		return messages;
	}

}

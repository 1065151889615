import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {TextField} from "../commons/TextField";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {MultiCombobox} from "../commons/MultiCombobox";
import {
	optionsTipoDocumentoAplicacao,
	optionsTipoDocumentoObrigatorio,
	optionsTipoDocumentoPermissaoAlteracao
} from "../../utils/models/MDocumento";
import {DocumentoTipoService} from "../servicos/DocumentoTipoService";
import {ModalRealm} from "../commons/ModalRealm";
import {Combobox} from "../commons/Combobox";
import {SelectFormulario} from "../../select/SelectFormulario";

export class EditarDocumentoTipo extends Component {

	state = {
		_key: Math.random(),
		visible: true,
		documentoTipo: this.props.documentoTipo
	};

	documentoTipoService = new DocumentoTipoService();

	onClose = () => this.setState({visible: false});

	onSalvar = () => {
		const {documentoTipo} = this.state;
		const messages = [];
		if (!documentoTipo.descricao?.trim().length)
			messages.push("A descrição do tipo de documento é obrigatória");
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			this.documentoTipoService.salvar(documentoTipo).then(documentoTipo => {
				if (this.props.onModalClose) {
					this.props.onModalClose(documentoTipo);
				}
				this.onClose();
			});
		}
	}

	handleChange = event => {
		const {documentoTipo} = this.state;
		documentoTipo[event.name] = event.value;
		this.setState({documentoTipo});
	}

	render() {
		return (
			<Dialog key={this.state._key} modal header="Tipo de Documento" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={12} label="Descrição" value={this.state.documentoTipo.descricao} onChange={this.handleChange} name="descricao"/>
					<Combobox appendTo={document.body} grid={4} label="Aplicação" options={optionsTipoDocumentoAplicacao} value={this.state.documentoTipo.aplicacao} onChange={this.handleChange} name="aplicacao"/>
					<TextField grid={2} label="Ordenação" name="ordenacao" value={this.state.documentoTipo.ordenacao} onChange={this.handleChange}/>
					<SelectFormulario appendTo={document.body} grid={6} name="formulario" value={this.state.documentoTipo.formulario} onChange={this.handleChange}/>
					<MultiCombobox appendTo={document.body} grid={12} label="Campos Obrigatórios" options={optionsTipoDocumentoObrigatorio} value={this.state.documentoTipo.obrigatorios} onChange={this.handleChange} name="obrigatorios"/>
					<MultiCombobox appendTo={document.body} grid={12} label="Permissão de Edição por Tela" options={optionsTipoDocumentoPermissaoAlteracao} value={this.state.documentoTipo.permissoesAlteracao} onChange={this.handleChange} name="permissoesAlteracao"/>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.onSalvar} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.onSalvar} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

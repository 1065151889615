export const Constraints = {

	// NÃO É NECESSÁRIO DECLARAR CONSTRAINTS QUE TERMINAM COM "_DESCRICAO" OU "_CHAVE_INTEGRACAO"
	// O MECANISMO DE ERRO DEDUZ AUTOMATICAMENTE, NESTES CASOS

	// Feriado
	"UC_FERIADO_LOCALIDADE_DATA": "localidade e data",

	// Jornada de Trabalho
	"UC_JORNADA_TRABALHO_CONTRATO_TRABALHO_REFERENCIA": "contrato de trabalho e data de referência",

	// Leitor Auxiliar
	"UC_LEITOR_AUXILIAR_LEITOR_AUXILIAR": "auxiliar em leitor",

	// Pessoa Física
	"UC_PESSOA_CPF": "CPF",
	"UC_PESSOA_RG": "RG",
	"UC_PESSOA_PASSAPORTE": "outro documento",
	"UC_PESSOA_NIS": "NIS",

	// Pessoa Jurídica
	"UC_PESSOA_CNPJ": "CNPJ",

	// Localidade
	"UC_LOCALIDADE_UF_NOME": "nome e UF",

	// Equipamento
	"UC_EQUIPAMENTO_FAMILIA_SERIAL": "serial e família",

	// Dispositivo
	"UC_DISPOSITIVO_EQUIPAMENTO_ATUADOR": "atuador e equipamento",

	// Leitor
	"UC_LEITOR_EQUIPAMENTO_PORTA": "porta em equipamento",

	// Usuário
	"UC_USUARIO_LOGIN": "login",

	// Credencial
	"UC_CREDENCIAL_FORMA_CODIGO": "código e forma",
	"UC_CREDENCIAL_CODIGO_PONTO": "código de ponto",
	"UC_CREDENCIAL_ITEM_CREDENCIAL_FORMATO_NUMERO": "número com formato e credencial",
	// Veículo
	"UC_VEICULO_PLACA": "placa",

	// Setup Portaria Modelo Agenda
	"UC_SETUP_PORTARIA_MODELO_AGENDA_SETUP_PORTARIA_DESCRICAO": "descrição e portaria",

	// Unidade
	"UC_UNIDADE_BLOCO_AGRUPAMENTO_DESCRICAO": "descrição em bloco e agrupamento",

	// Veículo Fabricante
	"UC_VEICULO_FABRICANTE_NOME": "nome"

};

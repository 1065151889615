import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MEndereco} from "../../utils/models/MEndereco";
import {MLocalidade} from "../../utils/models/MLocalidade";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {LocalidadeService} from "../servicos/LocalidadeService";

export class EditarLocalidade extends React.Component {

	state = {
		localidade: Object.assign(MLocalidade.Modelo(), this.props.localidade),
		visible: true,
		lockSave: false
	};

	localidadeService = new LocalidadeService();
	security = UserData.security("LOC", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarLocalidade = () => {
		if (this.security.edit) {
			let localidade = MLocalidade.cleanupBeforeSave(Object.assign({}, this.state.localidade));
			let messages = MLocalidade.validarParaSalvar(localidade);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.localidadeService.salvar(this.state.localidade).then((localidade) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(localidade);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let localidade = this.state.localidade;
		localidade[event.name] = event.value;
		this.setState({localidade});
	}

	render() {
		return (
			<Dialog modal header="Localidade" visible={this.state.visible} style={{width: "550px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={7} label="Nome" required name="nome" onChange={this.handleChange} value={this.state.localidade.nome} />
					<Combobox appendTo={document.body} options={MEndereco.UFs} grid={5} label="UF" required name="uf" onChange={this.handleChange} value={this.state.localidade.uf} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.localidade.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/localidades", this.state.localidade)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.localidade.id)) || this.state.lockSave} onClick={this.salvarLocalidade} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarLocalidade} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import moment from "moment";
import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MMatrizHorario {

	static Modelo() {
		return {
			_key: Math.random(),
			descricao: "",
			periodos: [],
			inicio: moment().format("YYYY-MM-DD"),
			tolerancia: 0
		};
	}

	static cleanupBeforeSave(matrizHorario) {
		if (matrizHorario.periodos) {
			matrizHorario.periodos.forEach(periodo => {
				if (periodo.folga && Validator.isEmpty(periodo.inicio)) periodo.inicio = matrizHorario.periodos.map(p => p.inicio).sort((a, b) => a.localeCompare(b))[0] || "00:00";
				if (periodo.folga && Validator.isEmpty(periodo.fim)) periodo.fim = matrizHorario.periodos.map(p => p.fim).sort((a, b) => b.localeCompare(a))[0] || "23:59";
			});
		}
		return MEntidade.cleanupBeforeSave(matrizHorario);
	}

	static validarParaSalvar(matrizHorario) {
		let messages = [];
		if (Validator.isEmpty(matrizHorario.descricao)) messages.push("A descrição é obrigatória");
		if (isNaN(Number(matrizHorario.tolerancia))) messages.push("A tolerância é obrigatória");
		if (matrizHorario.periodos) {
			matrizHorario.periodos.forEach(periodo => {
				if (!periodo.inicio) messages.push("O início é obrigatório para todos os períodos");
				if (!periodo.fim) messages.push("O fim é obrigatório para todos os períodos");
			});
		}
		return messages;
	}

}

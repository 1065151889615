import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {ImageUtils} from "../../utils/ImageUtils";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {WebCamera} from "../commons/Webcam";
import {CameraService} from "../servicos/CameraService";
import {ManutencaoService} from "../servicos/ManutencaoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import moment from "moment";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {CredencialService} from "../servicos/CredencialService";
import {UserData} from "../../utils/UserData";
import {Fetch} from "../../utils/Fetch";
import {EquipamentoService} from "../servicos/EquipamentoService";

const basePath = process.env.REACT_APP_API_URL;

export class Foto extends React.Component {

	state = {
		pessoa: this.props.pessoa,
		visible: true,
		cameras1: [],
		cameras2: [],
		camera1: {id: -1, descricao: ""},
		camera2: {id: -1, descricao: ""},
		rotation1: 0,
		rotation2: 0,
		className1: " ui-tzm-webcam-0",
		className2: " ui-tzm-webcam-0",
		flip1: false,
		flip2: false,
		cpf: "",
		rg: "",
		passaporte: "",
		cache1: "",
		cache2: "",
		image1: "",
		image2: "",
		single: this.props.single,
		lockSave: false,
		ignoreImage1: false,
		webcamKey: Math.random()
	};

	cameraService = new CameraService();
	manutencaoService = new ManutencaoService();
	localidadeService = new LocalidadeService();
	pessoaFisicaService = new PessoaFisicaService();
	credencialService = new CredencialService();
	securityCredencial = UserData.security("CRD", false);
	equipamentoService = new EquipamentoService();

	componentDidMount() {
		navigator.mediaDevices.enumerateDevices().then((webcams) => {
			webcams = webcams.filter(w => w.kind === "videoinput").map(w => ({id: -1, descricao: w.label, deviceId: w.deviceId}));
			this.cameraService.listar(`search=status:ATIVADO`, {page: 0, size: 100, sort: "descricao"}).then((moreCameras) => {
				let cameras1 = this.state.cameras1;
				let cameras2 = this.state.cameras2;
				cameras1.push(...webcams, ...moreCameras);
				cameras2.push(...moreCameras);
				let camera1 = webcams.length ? webcams[0] : moreCameras && moreCameras.length ? moreCameras[0] : {id: -1, descricao: ""};
				let camera2 = moreCameras && moreCameras.length > 0 ? moreCameras[0] : {id: -1, descricao: ""};
				this.setState({cameras1, cameras2, camera1, camera2, single: this.props.single || camera2.id === -1});
				if (this.state.camera1) {
					this.refreshCamera(1);
				}
				if (this.state.camera2) {
					this.refreshCamera(2);
				}
			});
		});
	}

	tirarFotografia = (index) => {
		if (this.state[`image${index}`] === "") {
			this.stopCamera(index);
			let foto;
			if (this.state[`camera${index}`].id === -1) {
				try {
					foto = this.video.takeScreenshot();
					ImageUtils.rotateBase64Image(foto, this.props.width, this.props.height, this.state[`rotation${index}`], this.state[`flip${index}`], (data) => {
						this.setState({[`image${index}`]: data});
					});
				} catch (exception) {
					console.log(exception);
				}
			} else {
				Fetch.Post(basePath + "/cameras/snapshot", this.state[`camera${index}`]).then((conteudo) => {
					conteudo.text().then((result) => {
						let imagem = `data:image/jpeg;base64,${result}`;
						ImageUtils.rotateBase64Image(imagem, this.props.width, this.props.height, this.state[`rotation${index}`], this.state[`flip${index}`], (data) => {
							this.setState({[`image${index}`]: data});
						});
					});
				});
			}
		}
	}

	salvarFotografias = () => {
		if (this.state.image1 === "" && this.state.image2 === "") {
			ModalRealm.showInformacao("warn", ["Você precisa clicar sobre a imagem para tirar a fotografia"]);
		} else {
			if (this.props.onModalClose) {
				let result = {};
				if (this.state.image1 !== "" && !this.state.ignoreImage1) {
					result.foto1 = {status: "ATIVADO", _timestamp: new Date(), conteudo: this.state.image1, finalidade: "IDENTIFICACAO"};
				}
				if (this.state.image2 !== "") {
					result.foto2 = {status: "ATIVADO", _timestamp: new Date(), conteudo: this.state.image2, finalidade: "DOCUMENTO"};
				}
				result.rg = this.state.rg;
				result.cpf = this.state.cpf;
				result.passaporte = this.state.passaporte;
				this.props.onModalClose(result);
				this.onClose();
			}
		}
	}

	onClose = () => {
		this.cameraTimeout.forEach(clearTimeout);
		if (this.stream) {
			this.stream.getTracks().forEach(track => track.stop());
		}
		this.setState({visible: false});
	}

	refVideo = (video) => this.video = video;

	cameraTimeout = [];

	handlePRChange = (event) => {
		this.setState({[event.name]: event.value});
		this.refreshCamera(1);
		this.refreshCamera(2);
	}

	refreshCamera = (index) => {
		if (this.cameraTimeout[index]) clearTimeout(this.cameraTimeout[index]);
		this.cameraTimeout[index] = setTimeout(() => {
			if (this.state[`camera${index}`].id > -1) {
				Fetch.Post(basePath + "/cameras/snapshot", this.state[`camera${index}`]).then((response) => {
					response.text().then((data) => {
						this.setState({[`cache${index}`]: `data:image/jpeg;base64,${data}`});
					});
					if (this.state.visible) {
						this.refreshCamera(index);
					}
				}).catch(() => {
					if (this.state.visible) {
						this.refreshCamera(index);
					}
				});
			} else {
				if (index === 1) {
					this.updateWebcam();
				}
			}
		}, 1000);
	}

	updateWebcam = () => {
		if (this.stream) {
			this.stream.getTracks().forEach(track => track.stop());
		}
		if (this.state.camera1.id === -1) {
			navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: this.state.camera1.deviceId}}).then((stream) => {
				this.video.setStream(stream);
				this.stream = stream;
			});
		}
	}

	stopCamera = (index) => {
		if (this.cameraTimeout[index]) clearTimeout(this.cameraTimeout[index]);
	}

	rotateCamera = (index, rotation) => {
		this.setState({[`rotation${index}`]: rotation, [`className${index}`]: ` ui-tzm-webcam-${rotation}`});
	}

	flipCamera = (index) => {
		this.setState({[`flip${index}`]: !this.state[`flip${index}`]});
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	render() {
		let view1 = null, view2 = null;
		if (this.state.visible) {
			if (this.state.image1 === "") {
				if (this.state.camera1.id === -1) {
					view1 = <div className="camera-identificacao"><WebCamera key={this.state.webcamKey} ref={this.refVideo} className={`ui-webcam-camtotem${this.state.className1}${this.state.flip1 ? "-flip" : ""}`} style={{cursor: "pointer", border: "3px solid #F44336", width: `${this.props.width}px`, height: `${this.props.height}px`}} /></div>;
				} else {
					view1 = <div className="camera-identificacao"><img key={this.state.webcamKey} className={`ui-webcam-camtotem${this.state.className1}${this.state.flip1 ? "-flip" : ""}`} src={this.state.cache1} alt="" style={{cursor: "pointer", border: "3px solid #F44336", width: `${this.props.width}px`, height: `${this.props.height}px`}} /></div>;
				}
			} else {
				view1 = <div className="camera-identificacao"><img className="ui-webcam-camtotem" src={this.state.image1} alt="" style={{border: "3px solid #4CAF50", width: `${this.props.width}px`, height: `${this.props.height}px`}} /></div>;
			}
			if (this.state.image2 === "") {
				view2 = <img className={`ui-webcam-camtotem${this.state.className2}${this.state.flip2 ? "-flip" : ""}`} src={this.state.cache2} alt="" style={{cursor: "pointer", border: "3px solid #F44336", width: `${this.props.width}px`, height: `${this.props.height}px`}} />;
			} else {
				view2 = <img className="ui-webcam-camtotem" src={this.state.image2} alt="" style={{border: "3px solid #4CAF50", width: `${this.props.width}px`, height: `${this.props.height}px`}} />;
			}
		}
		return (
			<Dialog modal header="Tirar Fotografia" onHide={this.onClose} visible={this.state.visible} style={{width: `${this.props.width * (this.state.single ? 1 : 2) + (this.state.single ? 1 : 2) * 60}px`, textAlign: "center"}}>
				<DialogContent>
					<div className="ui-g">
						<div className={`ui-g-${this.state.single ? 12 : 6}`}>
							<div className="ui-g-12">
								<Label style={{textAlign: "left"}}>Câmera</Label>
								<Combobox appendTo={document.body} value={this.state.camera1} options={this.state.cameras1} optionLabel="descricao" onChange={this.handlePRChange} name="camera1" />
							</div>
							<div className="ui-g-12" style={{paddingTop: `${(Math.max(this.props.width, this.props.height) - Math.min(this.props.width, this.props.height)) / 2}px`, height: `${Math.max(this.props.width, this.props.height)}px`}}>
								<div className="ui-tzm-webcam-container-image" onClick={() => this.tirarFotografia(1)}>
									<div className="ui-tzm-webcam-loading" />
									{view1}
									<div className="facial-hints">
										<ul>
											<li>Procure enquadrar o rosto da pessoa na moldura</li>
											<li>Peça para que ela retire os óculos e/ou máscara</li>
											<li>Peça para que a pessoa mantenha uma expressão séria</li>
											<li>Clique sobre a imagem quando estiver pronto(a)</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="ui-g-12 ui-webcam-buttons">
								<Button onClick={() => this.rotateCamera(1, this.state.rotation1 > 0 ? this.state.rotation1 - 90 : 270)} label=" " icon="fa-undo" />
								<Button onClick={() => this.flipCamera(1)} label=" " icon="fa-arrows-alt-h" />
								<Button onClick={() => this.rotateCamera(1, (this.state.rotation1 + 90) % 360)} label=" " icon="fa-redo" />
							</div>
						</div>
						{!this.state.single ? (
							<div className="ui-g-6">
								<div className="ui-g-12">
									<Label style={{textAlign: "left"}}>Câmera</Label>
									<Combobox appendTo={document.body} value={this.state.camera2} options={this.state.cameras2} optionLabel="descricao" onChange={this.handlePRChange} name="camera2" />
								</div>
								<div className="ui-g-12" style={{paddingTop: `${(Math.max(this.props.width, this.props.height) - Math.min(this.props.width, this.props.height)) / 2}px`, height: `${Math.max(this.props.width, this.props.height)}px`}}>
									<div className="ui-tzm-webcam-container-image" onClick={() => this.tirarFotografia(2)}>
										<div className="ui-tzm-webcam-loading" />{view2}
									</div>
								</div>
								<div className="ui-g-12 ui-webcam-buttons">
									<Button onClick={() => this.rotateCamera(2, this.state.rotation2 > 0 ? this.state.rotation2 - 90 : 270)} label=" " icon="fa-undo" />
									<Button onClick={() => this.flipCamera(2)} label=" " icon="fa-arrows-alt-h" />
									<Button onClick={() => this.rotateCamera(2, (this.state.rotation2 + 90) % 360)} label=" " icon="fa-redo" />
								</div>
								<div className="ui-g-4">
									<Label style={{textAlign: "left"}}>CPF</Label>
									<TextField value={this.state.cpf} name="cpf" onChange={this.handleChange} />
								</div>
								<div className="ui-g-4">
									<Label style={{textAlign: "left"}}>RG</Label>
									<TextField value={this.state.rg} name="rg" onChange={this.handleChange} />
								</div>
								<div className="ui-g-4">
									<Label style={{textAlign: "left"}}>Outro Documento</Label>
									<TextField value={this.state.passaporte} name="passaporte" onChange={this.handleChange} />
								</div>
							</div>
						) : null}
					</div>
				</DialogContent>
				<DialogFooter>
					{this.securityCredencial.create && this.state.pessoa && !this.props.simple ? <Action style={{float : "left"}} disabled={!this.state.image1 || !this.state.image1.length || this.state.lockSave || this.state.ignoreImage1} mode="post" label="Biometria Facial" onClick={this.processarFacial} icon="fa-portrait" /> : null}
					<Action disabled={this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarFotografias} icon="fa-camera" />
					<Action disabled={this.state.lockSave} mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarFotografias} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	processarFacial = () => {
		if (this.state.image1 && this.state.image1.length) {
			ModalRealm.showConfirmacao("Para cadastrar a biometria facial é preciso salvar o cadastro da pessoa. Deseja salvá-lo agora?", () => {
				const {pessoa} = this.state;
				if (!pessoa.fotos) {
					pessoa.fotos = [];
				}
				pessoa.fotos.push({finalidade: "IDENTIFICACAO", status: "ATIVADO", conteudo: this.state.image1});
				this.salvarPessoa(pessoa);
			});
		}
	}

	enviarProcessamentoFacial = (pessoa) => {
		this.setState({lockSave: true});
		this.manutencaoService.enviarFacialSync(MPessoaFisica.cleanupBeforeSave(Object.assign({}, pessoa))).then((logManutencao) => {
			this.setState({lockSave: false});
			if (logManutencao.results.every(r => r.status === "SUCESSO")) {
				ModalRealm.showInformacao("info", ["Biometria facial cadastrada com sucesso!"]);
				this.setState({ignoreImage1: true, visible: false});
			} else {
				ModalRealm.showInformacao("warn", logManutencao.results.filter(r => r.status !== "SUCESSO").map(r => r.mensagem));
				this.setState({image1: "", webcamKey: Math.random()});
				this.updateWebcam();
			}
		}).catch(() => this.setState({lockSave: false}));
	}

	salvarPessoa = (pessoa) => {
		pessoa = MPessoaFisica.cleanupBeforeSave(Object.assign({}, pessoa));
		let messages = MPessoaFisica.validarParaSalvar(pessoa, this.props.obrigatorios);
		if (messages.length > 0) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			this.processarSalvarPessoa(pessoa);
		}
	}

	processarSalvarPessoa = (pessoa) => {
		if (pessoa.fotos) {
			pessoa.fotos.forEach((foto) => {
				if (foto.conteudo) {
					foto.conteudo = foto.conteudo.split(",").pop();
				}
			});
		}
		pessoa.ultimaAtualizacao = moment().format("YYYY-MM-DDTHH:mm:ss");
		if (pessoa.enderecos && pessoa.enderecos.length) {
			this.completarEnderecosESalvar(pessoa);
		} else {
			this.postPessoa(pessoa);
		}
	}

	completarEnderecosESalvar = async (pessoa, index = 0) => {
		if (pessoa.enderecos.length > index) {
			await this.completarEndereco(pessoa.enderecos[index]).then((localidade) => {
				pessoa.enderecos[index].localidade = localidade;
				this.completarEnderecosESalvar(pessoa, ++index);
			});
		} else {
			this.postPessoa(pessoa);
		}
	}

	completarEndereco = async (endereco) => {
		return await this.localidadeService.listar(`search=nome:${endereco.localidade.nome};uf:${endereco.localidade.uf}`, {page: 0, size: 1, sort: "nome"}).then(async (localidades) => {
			if (localidades && localidades.length) {
				return localidades[0];
			} else {
				return await this.salvarLocalidade(endereco.localidade);
			}
		});
	}

	salvarLocalidade = async (localidade) => {
		return await this.localidadeService.salvar(localidade);
	}

	postPessoa = (pessoa) => {
		this.setState({lockSave: true});
		this.pessoaFisicaService.salvar(pessoa).then((pessoa) => {
			if (this.props.onForcedSave) {
				this.props.onForcedSave(pessoa);
			}
			this.setState({pessoa, lockSave: false}, () => this.enviarProcessamentoFacial(pessoa));
		}).catch(() => this.setState({lockSave: false}));
	}

}

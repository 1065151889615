import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarPessoaFisica} from "../components/PessoaFisica/EditarPessoaFisica";
import {PessoaFisicaService} from "../components/servicos/PessoaFisicaService";
import {MPessoaFisica} from "../utils/models/MPessoaFisica";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectPessoaFisica extends React.Component {

	service = new PessoaFisicaService();

	security = UserData.security("PES");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={{...MPessoaFisica.Modelo(), nome: event.value.nome.replace(`Criar pessoa `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select entidade="pessoa" insertable={this.security.create && this.props.insertable} service={this.service} field="nome" query="status:ATIVADO;nome,rg,cpf,passaporte" onSelect={this.onSelect} {...this.props} />;
	}

}

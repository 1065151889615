import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MPessoaJuridica {

	static Modelo() {
		return {
			nome: "",
			fantasia: "",
			cnpj: "",
			telefone: "",
			enderecos: []
		};
	}

	static Flags = [
		{label: "Aceita e-mails", value: "EMAIL"},
		{label: "Empresa fixa", value: "FIXA"}
	];

	static cleanupBeforeSave(empresa) {
		empresa.enderecos = empresa.enderecos.filter(e => !Validator.isEmpty(e.localidade.nome));
		return MEntidade.cleanupBeforeSave(empresa);
	}

	static cleanupBeforeEdit(pessoa) {
		if (!Validator.isEmpty(pessoa.cnpj)) pessoa.cnpj = pessoa.cnpj.formatCnpj();
		return pessoa;
	}

	static validarParaSalvar(empresa) {
		let messages = [];
		if (Validator.isEmpty(empresa.nome)) messages.push("A razão social é obrigatória");
		if (!Validator.isEmpty(empresa.cnpj) && !Validator.isCNPJ(empresa.cnpj)) messages.push("O CNPJ é inválido");
		return messages;
	}

}

const lugaresExternos = [
	"EXTERNO",
	"GARAGEM"
];

export function isDentro(pessoa) {
	return pessoa.lugar && !lugaresExternos.includes(pessoa.lugar.tipo);
}

export function isDentroOuGaragem(pessoa) {
	return pessoa.lugar && pessoa.lugar.tipo !== "EXTERNO";
}

import React, {Component} from "react";
import {LogPontoService} from "../servicos/LogPontoService";
import {DialogContent} from "../commons/DialogContent";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {UserData} from "../../utils/UserData";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {DatePicker} from "../commons/DatePicker";

export class EditarLogPonto extends Component {

	state = {
		visible: true,
		logPonto: this.props.logPonto
	};

	security = UserData.security("LPT", true);
	logPontoService = new LogPontoService();

	onClose = () => this.setState({visible: false});

	onSalvar = () => {
		this.logPontoService.salvar(this.state.logPonto).then(logPonto => {
			if (this.props.onModalClose) {
				this.props.onModalClose(logPonto);
			}
			this.onClose();
		})
	}

	handleChange = (event) => {
		const {logPonto} = this.state;
		logPonto[event.name] = event.value;
		this.setState({logPonto});
	}

	render() {
		return (
			<Dialog modal header="Editar Log de Ponto" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={4} value={this.state.logPonto.modo} label="Modo" name="modo" onChange={this.handleChange}/>
					<TextField grid={4} value={this.state.logPonto.tipo} label="Tipo" name="tipo" onChange={this.handleChange}/>
					<TextField grid={4} value={this.state.logPonto.nis} label="NIS" name="nis" onChange={this.handleChange}/>
					<TextField grid={4} value={this.state.logPonto.serial} label="Serial" name="serial" onChange={this.handleChange}/>
					<TextField type="number" grid={4} value={this.state.logPonto.nsr} label="NSR" name="nsr" onChange={this.handleChange}/>
					<DatePicker appendTo={document.body} showTime showSeconds grid={4} value={this.state.logPonto.data} label="Data" name="data" onChange={this.handleChange}/>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.logPonto.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/logs-ponto", this.state.logPonto)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.logPonto.id)) || this.state.lockSave} onClick={this.onSalvar} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.onSalvar} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import React from "react";

export class Clock extends React.Component {

	render() {
		let timer = this.props.time.split(":");
		return (
			<div className="ui-clock-container">
				<div className="ui-clock-number ui-clock-number-3">3</div>
				<div className="ui-clock-number ui-clock-number-6">6</div>
				<div className="ui-clock-number ui-clock-number-9">9</div>
				<div className="ui-clock-number ui-clock-number-12">12</div>
				<div className="ui-clock-ampm">{timer[0] < 12 ? "AM" : "PM"}</div>
				<div className="ui-clock-arm-minute" style={{transform: `rotate(${Math.round(6 * Number(timer[1])) - 90}deg)`}} />
				<div className="ui-clock-arm-hour" style={{transform: `rotate(${Math.round(30 * Number(timer[0])) + Math.floor(Number(timer[1]) / 2) - 90}deg)`}} />
				<div className="ui-clock-panel" />
				<div className="ui-clock-bright" />
			</div>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MVeiculo, optionsVeiculoTipo} from "../../utils/models/MVeiculo";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {VeiculoService} from "../servicos/VeiculoService";
import {PlacaMercosul} from "./PlacaMercosul";
import {ComboboxFabricante} from "./ComboboxFabricante";
import {httpGet, httpPost} from "../../utils/Request";
import {MCredencial} from "../../utils/models/MCredencial";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {PanelContent} from "../commons/PanelContent";
import {DataTable} from "primereact/components/datatable/DataTable";
import {GUI} from "../../utils/Constants";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import moment from "moment";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import {MDocumento} from "../../utils/models/MDocumento";
import {EditarDocumento} from "../Documento/EditarDocumento";

export class EditarVeiculo extends React.Component {

	state = {
		veiculo: Object.assign(MVeiculo.Modelo(), this.props.veiculo),
		visible: true,
		lockSave: false,
		activeIndex: 0
	};

	veiculoService = new VeiculoService();
	security = UserData.security("VEI", true);
	securityDocumento = UserData.security("DOC");

	onClose = () => {
		this.setState({visible: false});
	}

	printCredencial(credencial) {
		return (
			<div className="credencial-veiculo">
				{MCredencial.MapFormas[credencial.forma]} - {credencial.descricao}
			</div>
		);
	}

	salvarVeiculo = () => {
		if (this.security.edit) {
			let veiculo = MVeiculo.cleanupBeforeSave(Object.assign({}, this.state.veiculo));
			let messages = MVeiculo.validarParaSalvar(veiculo);
			if (this.props.require?.proprietario && !veiculo.proprietario?.id) {
				messages.push("O proprietário do veículo é obrigatório");
			}
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (veiculo.proprietario && veiculo.id) {
					httpGet(`/credenciais?veiculo=id:${veiculo.id}`).then(credenciais => {
						if (credenciais.length && credenciais.some(c => c.pessoa && c.pessoa.id !== veiculo.proprietario.id)) {
							const message = (
								<>
									Alterar o proprietário do veículo afetará também as seguintes credenciais vinculadas a ele:<br/>
									{credenciais.filter(c => c.pessoa && c.pessoa.id !== veiculo.proprietario.id).map(this.printCredencial)}
									<br/>Tem certeza de que deseja continuar?
								</>
							);
							ModalRealm.showConfirmacao(message, async () => {
								for (const credencial of credenciais) {
									if (credencial.pessoa && credencial.pessoa.id !== veiculo.proprietario.id) {
										credencial.pessoa = veiculo.proprietario;
										await httpPost("/credenciais", credencial);
									}
								}
								this.finalizarSalvarVeiculo(veiculo);
							});
						} else {
							this.finalizarSalvarVeiculo(veiculo);
						}
					});
				} else {
					this.finalizarSalvarVeiculo(veiculo);
				}
			}
		}
	}

	editarDocumento = (documento) => {
		if (!documento) {
			documento = MDocumento.Modelo();
		}
		if (!documento.id && !documento._key) {
			documento._key = Math.random();
		}
		ModalRealm.showDialog(<EditarDocumento modo="veiculo" key={Math.random()} documento={documento} onModalClose={(documento) => {
			const {veiculo} = this.state;
			if (!veiculo.documentos) {
				veiculo.documentos = [];
			} else {
				veiculo.documentos = veiculo.documentos.filter((d) => d.id !== documento.id || d._key !== documento._key);
			}
			veiculo.documentos.push(documento);
			this.setState({veiculo, _mod: true});
		}} />);
	}

	excluirDocumento = (documento) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja remover este documento?", () => {
			const {veiculo} = this.state;
			if (veiculo.documentos) {
				veiculo.documentos = veiculo.documentos.filter(d => d.id !== documento.id || d._key !== documento._key);
			}
			this.setState({veiculo});
		});
	}

	finalizarSalvarVeiculo = veiculo => {
		this.setState({lockSave: true}, () => {
			this.veiculoService.salvar(veiculo).then((veiculo) => {
				this.setState({lockSave: false});
				if (this.props.onModalClose) {
					this.props.onModalClose(veiculo);
				}
				this.onClose();
			}).catch(() => this.setState({lockSave: false}));
		});
	}

	handleChange = (event) => {
		let veiculo = this.state.veiculo;
		veiculo[event.name] = event.value;
		this.setState({veiculo});
	}

	handleChangeToUpper = (event) => {
		let veiculo = this.state.veiculo;
		veiculo[event.name] = event.value.toUpperCase();
		this.setState({veiculo});
	}

	detailsDocumentos = [
		<Column style={{width: "*"}} key={0} header="Tipo" body={(documento) => documento.tipo?.descricao} />,
		<Column style={{width: "*"}} key={1} header="Número" field="numero" />,
		<Column style={{width: "10em"}} key={2} header="Validade" body={(documento) => {
			const expired = documento.validade && moment(documento.validade).add(1, "days").isBefore(moment());
			return documento.validade ? <div className={expired ? "ui-expired-doc" : ""}>{moment(documento.validade).format("DD/MM/YYYY")}</div> : "Indeterminada";
		}} />,
		<Column style={{width: "6em", textAlign: "center"}} key={3} header="Ações" body={(documento) => {
			return (
				<div>
					{this.securityDocumento?.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Documento" onClick={() => this.editarDocumento(documento)} /> : null}
					{this.securityDocumento?.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Documento" onClick={() => this.excluirDocumento(documento)} /> : null}
				</div>
			);
		}} />
	];

	tabChange = (event) => this.setState({activeIndex: event.index});

	render() {
		return (
			<Dialog modal header="Veículo" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<div className="ui-g-12">
									<Label>Visualização</Label>
									<PlacaMercosul veiculo={this.state.veiculo}/>
								</div>
								<Combobox appendTo={document.body} grid={4} label="Tipo" name="tipo" value={this.state.veiculo.tipo} onChange={this.handleChange} options={optionsVeiculoTipo}/>
								<TextField autofocus required length={7} maxLength={7} type="alphanumeric" grid={3} label="Placa" name="placa" onChange={this.handleChangeToUpper} value={this.state.veiculo.placa}/>
								<TextField grid={5} label="Modelo" name="modelo" onChange={this.handleChange} value={this.state.veiculo.modelo}/>
								<ComboboxFabricante filter filterBy="nome" grid={6} label="Fabricante" appendTo={document.body} name="fabricante" options={this.state.fabricantes} onChange={this.handleChange} value={this.state.veiculo.fabricante}/>
								<Combobox filter grid={6} label="Cor" appendTo={document.body} name="cor" options={MVeiculo.Cores} onChange={this.handleChange} value={this.state.veiculo.cor}/>
								<Combobox grid={4} label="Categoria" appendTo={document.body} name="categoria" options={MVeiculo.Categorias} onChange={this.handleChange} value={this.state.veiculo.categoria}/>
								<SelectPessoaFisica required={this.props.require?.proprietario && !this.state.veiculo.proprietario} grid={8} label="Proprietário" onChange={this.handleChange} name="proprietario" value={this.state.veiculo.proprietario}/>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Documentos">
							<PanelContent>
								<DataTable emptyMessage="Nenhum documento cadastrado"
										   value={this.state.veiculo.documentos}
										   rows={GUI.defaultRows}
										   rowsPerPageOptions={GUI.rowsPerPage}
										   paginator
										   paginatorLeft={this.securityDocumento?.create ? <Button onClick={() => this.editarDocumento()} icon="fa-plus" className="ui-tzm-pagleft-icon" /> : null}
										   children={this.detailsDocumentos}/>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.veiculo.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/veiculos", this.state.veiculo)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.veiculo.id)) || this.state.lockSave} onClick={this.salvarVeiculo} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarVeiculo} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

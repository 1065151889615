import React from "react";

export class PanelFooter extends React.Component {

	render() {
		return (
			<div {...this.props} className={`ui-tzm-panel-footer ${this.props.className || ""}`} />
		);
	}

}

export class TabPanelFooter extends React.Component {

	render() {
		return (
			<div {...this.props} className={`ui-tzm-tabpanel-footer ${this.props.className || ""}`} />
		);
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MLocalidade extends MEntidade {

	static Modelo() {
		return {
			nome: "",
			uf: ""
    };
	}

	static cleanupBeforeSave(localidade) {
		return MEntidade.cleanupBeforeSave(localidade);
	}

	static validarParaSalvar(localidade) {
		let messages = [];
		if (Validator.isEmpty(localidade.nome)) messages.push("O nome é obrigatório");
		if (Validator.isEmpty(localidade.uf)) messages.push("A UF é obrigatória");
		return messages;
	}

}

import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpPost} from "../../utils/Request";

export class ModeloAcessoService extends EntidadeNextGenService {

	constructor() {
		super("/modelos-acesso");
	}

	findModeloWithLugares(lugares) {
		return httpPost("/modelos-acesso/lugares", lugares);
	}

}

import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {UserData} from "../../utils/UserData";
import {MAgenda} from "../../utils/models/MAgenda";
import {AgendaService} from "../servicos/AgendaService";
import {EditarAgenda} from "./EditarAgenda";
import {ModalRealm} from "../commons/ModalRealm";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {CheckSquare} from "../commons/CheckSquare";
import {GUI} from "../../utils/Constants";
import {TextField} from "../commons/TextField";
import {MultiSelectLugar} from "../../select/MultiSelectLugar";
import moment from "moment";
import {ModeloAcessoService} from "../servicos/ModeloAcessoService";
import {Button} from "primereact/components/button/Button";
import {SelecionarPessoas} from "../PessoaFisica/SelecionarPessoas";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";

export class EditarAutorizacao extends Component {

	state = {
		periodo: 5,
		agenda: this.props.agenda,
		visible: true,
		key: Math.random(),
		lugares: []
	};

	security = UserData.security("SCH", true);
	agendaService = new AgendaService();
	modeloAcessoService = new ModeloAcessoService();
	pessoaFisicaService = new PessoaFisicaService();

	componentDidMount() {
		const {lugares} = this.state;
		this.state.agenda.modelosAcesso.forEach(ma => {
			ma.lugares.forEach(l => {
				if (!lugares.includes(l.lugar)) {
					lugares.push(l.lugar);
				}
			});
		});
		this.setState({lugares}, () => {
			if (!this.state.agenda.pessoas?.length) {
				this.selecionarPessoas();
			}
		});
	}

	togglePessoa = (event) => {
		const {agenda} = this.state;
		agenda.pessoas[event.index]._selecionado = event.value;
		this.setState({agenda});
	}

	togglePessoas = (event) => {
		const {agenda} = this.state;
		agenda.pessoas.forEach(p => p._selecionado = event.value);
		this.setState({agenda});
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	selecionarPessoas = () => {
		ModalRealm.showDialog(<SelecionarPessoas except={this.state.agenda.pessoas} onModalClose={(pessoas) => {
			pessoas.forEach(p => p._selecionado = true);
			const agenda = this.state.agenda;
			agenda.pessoas = agenda.pessoas.concat(pessoas).sort((a, b) => a.nome.localeCompare(b.nome));
			this.setState({agenda});
		}} />);
	}

	removerPessoas = () => {
		const agenda = this.state.agenda;
		agenda.pessoas = agenda.pessoas.filter(p => !p._selecionado);
		this.setState({agenda});
	}

	editarPessoa = (pessoa) => {
		if (pessoa?.id) {
			this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
				ModalRealm.showDialog(<EditarPessoaFisica pessoa={pessoa} onModalClose={(pessoa) => {
					let agenda = this.state.agenda;
					agenda.pessoas = agenda.pessoas.filter(p => p.id !== pessoa.id);
					agenda.pessoas.unshift(pessoa);
					this.setState({agenda});
				}}/>);
			});
		} else {
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={null} onModalClose={(pessoa) => {
				let agenda = this.state.agenda;
				agenda.pessoas.unshift(pessoa);
				this.setState({agenda});
			}}/>);
		}
	}

	render() {
		return (
			<Dialog modal visible={this.state.visible} key={this.state.key} header="Autorização de Acesso" style={{width: "1050px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField label="Período" grid={2} type="numeric" unit="min" onChange={this.handleChange} value={this.state.periodo} name="periodo"/>
					<MultiSelectLugar label="Lugares" grid={10} name="lugares" value={this.state.lugares} onSelect={this.handleChange}/>
					<div className="ui-g-12">
						<DataTable emptyMessage="Nenhuma pessoa adicionada" paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} value={this.state.agenda.pessoas} paginatorLeft={
							<div>
								<Button title="Busca Avançada" className="ui-tzm-pagleft-icon" icon="fa-search" onClick={() => this.selecionarPessoas()}/>
								<Button title="Nova Pessoa" className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarPessoa()}/>
								<Button title="Remover Pessoas Selecionadas" className="ui-tzm-pagleft-icon danger" icon="fa-minus" onClick={() => this.removerPessoas()}/>
							</div>
						}>
							<Column style={{width: "3em", textAlign: "center"}} header={
								<CheckSquare style={{margin: "-4px -3px 0 0"}} checked={this.state.agenda.pessoas.every(p => p._selecionado)} onChange={this.togglePessoas} />
							} body={(p, i) => (
								<CheckSquare style={{margin: "-4px -3px 0 0"}} index={i.rowIndex} checked={p._selecionado || false} onChange={this.togglePessoa}/>
							)}/>
							<Column field="nome" header="Nome"/>
							<Column style={{width: "9em"}} header="CPF" body={p => p.cpf ? p.cpf.formatCpf() : ""}/>
							<Column style={{width: "7em"}} field="rg" header="RG"/>
							<Column style={{width: "9em"}} field="nis" header="NIS"/>
						</DataTable>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action disabled={!this.security.edit || this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarAutorizacao} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarAutorizacao} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	onClose = () => this.setState({visible: false});

	salvarAutorizacao = () => {
		const messages = [];
		if (Number(this.state.periodo) < 1) {
			messages.push("Você precisa selecionar um período maior que zero");
		}
		if (!this.state.agenda.pessoas.some(p => p._selecionado)) {
			messages.push("Você precisa selecionar ao menos uma pessoa");
		}
		if (!this.state.lugares.length) {
			messages.push("Você precisa selecionar ao menos um lugar");
		}
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			const agenda = MAgenda.Modelo();
			agenda.flags = ["AUTORIZACAO"];
			agenda.etiquetas = this.state.agenda.etiquetas;
			agenda.pessoas = this.state.agenda.pessoas.filter(p => p._selecionado);
			agenda.validade = moment().add(this.state.periodo, "minutes").format("YYYY-MM-DDTHH:mm:59");
			this.modeloAcessoService.findModeloWithLugares(this.state.lugares.map(l => l.id)).then(modeloAcesso => {
				agenda.modelosAcesso = [modeloAcesso];
				ModalRealm.showDialog(<EditarAgenda key={Math.random()} agenda={agenda} onSave={this.props.onSave}/>);
				this.onClose();
			});
		}
	}

}

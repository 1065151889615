import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Agenda} from "./components/Agenda";
import {Animal} from "./components/Animal";
import {Aviso} from "./components/Aviso";
import {Bloqueio} from "./components/Bloqueio";
import {Camera} from "./components/Camera";
import {Loading} from "./components/commons/loading/Loading";
import {Comunicado} from "./components/Comunicado";
import {Configuracao} from "./components/Configuracao";
import {ContratoTrabalho} from "./components/ContratoTrabalho";
import {Controlador} from "./components/Controlador";
import {Credencial} from "./components/Credencial";
import {Encomenda} from "./components/Encomenda";
import {Equipamento} from "./components/Equipamento";
import {Espaco} from "./components/Espaco";
import {Etiqueta} from "./components/Etiqueta";
import {Feriado} from "./components/Feriado";
import {Guarita} from "./components/Guarita";
import {Home} from "./components/Home";
import {JornadaTrabalho} from "./components/JornadaTrabalho";
import {Lugar} from "./components/Lugar";
import {Manutencao} from "./components/Manutencao";
import {MatrizHorario} from "./components/MatrizHorario";
import {Mensagem} from "./components/Mensagem";
import {MessengerBot} from "./components/MessengerBot";
import {ModeloAcesso} from "./components/ModeloAcesso";
import {Monitor} from "./components/Monitor/Monitor";
import {Monitoramento} from "./components/Monitoramento";
import {Login} from "./components/NeoLogin";
import {Perfil} from "./components/Perfil";
import {PessoaFisica} from "./components/PessoaFisica";
import {PessoaJuridica} from "./components/PessoaJuridica";
import {Portaria} from "./components/Portaria";
import {PreAgendamento} from "./components/PreAgendamento";
import {Relatorio} from "./components/Relatorio";
import {Reserva} from "./components/Reserva";
import {OperadorService} from "./components/servicos/OperadorService";
import {PerfilService} from "./components/servicos/PerfilService";
import {UsuarioService} from "./components/servicos/UsuarioService";
import {TabletAtivo} from "./components/TabletAtivo";
import {TabletPassivo} from "./components/TabletPassivo";
import {Unidade} from "./components/Unidade";
import {Usuario} from "./components/Usuario";
import {Veiculo} from "./components/Veiculo";
import {Page} from "./Page";
import {buildHeaders} from "./utils/Request";
import {UserData} from "./utils/UserData";
import {LogPonto} from "./components/LogPonto";
import {TarifaConta} from "./components/TarifaConta";
import {ModeloAgenda} from "./components/ModeloAgenda";
import {MonitorPresenca} from "./components/MonitorPresenca";
import {TimeoutObserver} from "./components/commons/TimeoutObserver";
import {LogAcesso} from "./components/LogAcesso";
import {Ocorrencia} from "./components/Ocorrencia";
import {Vaga} from "./components/Vaga";
import {AutorizacaoAcesso} from "./components/AutorizacaoAcesso";
import {PreAgendamentoFixo} from "./components/PreAgendamentoFixo";
import {GrupoTrabalho} from "./components/GrupoTrabalho";
import {Formulario} from "./components/Formulario";
import {TabletFormulario} from "./components/Formulario/TabletFormulario";
import * as faceapi from 'face-api.js';
import {PainelControleAcesso} from "./components/PainelControleAcesso";

export const UserContext = React.createContext({});
const basePath = process.env.REACT_APP_API_URL;

export const handleLogout = () => {
    window.localStorage.removeItem("safety-authorization-token");
    window.location.reload();
}

export class Application extends React.Component {

    usuarioService = new UsuarioService();
    operadorService = new OperadorService();
    perfilService = new PerfilService();

    state = {
        operador: {},
        loading: true
    };

    UNSAFE_componentWillMount() {
        this.loadUser();
        this.loadFaceapi();
    }

    loadFaceapi() {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/external/faceapi/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/external/faceapi/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/external/faceapi/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/external/faceapi/models'),
        ]).catch((e) => {
            console.error(`faceapi: couldn't load because: ${e}`);
        });
    }

    loadUser() {
        fetch(`${basePath}/me`, {method: "get", headers: buildHeaders()}).then((response) => {
            if (response.status === 200) {
                response.json().then((usuario) => {
                    if (usuario) {
                        let schema = window.localStorage.getItem("safety-schema-name");
                        if (!schema || !usuario.schemas.some(s => s.nome === schema)) {
                            window.localStorage.setItem("safety-schema-name", usuario.schema.nome);
                        }
                        this.operadorService.listar(`usuario=id:${usuario.id}`, {
                            page: 0,
                            size: 1
                        }).then((operadores) => {
                            if (operadores.length) {
                                this.setUsuario(usuario, operadores[0]);
                                const perfis = operadores[0].perfis.filter(p => p.paginaInicial).sort((a, b) => b.roles.length - a.roles.length);
                                if (perfis.length) {
                                    const loaded = window.sessionStorage.getItem("safety-login-default-page");
                                    if (!loaded || loaded === "false") {
                                        window.sessionStorage.setItem("safety-login-default-page", "true");
                                        window.location.replace(perfis[0].paginaInicial);
                                    }
                                }
                            } else {
                                this.perfilService.listar(`search=descricao~Administrador`, {
                                    page: 0,
                                    size: 1
                                }).then((perfis) => {
                                    this.operadorService.salvar({usuario, perfis}).then((operador) => {
                                        this.setUsuario(usuario, operador);
                                    });
                                });
                            }
                        });
                    } else {
                        this.setState({loading: false});
                    }
                });
            } else {
                this.setState({loading: false});
            }
        }).catch(() => {
            setTimeout(() => this.loadUser(), 3000);
        });
    }

    setUsuario = (usuario, operador) => {
        UserData.setUser(operador);
        UserData.usuario = usuario;
        UserData.operador = operador;
        operador.authorities = operador.authorities.map(a => a.authority);
        let currentSchema = window.localStorage.getItem("safety-schema-name");
        let selectedSchema = operador.usuario.schemas.filter((s) => s.nome === currentSchema);
        if (!currentSchema || !selectedSchema.length) {
            selectedSchema = [operador.usuario.schema];
            window.localStorage.setItem("safety-schema-name", operador.usuario.schema.nome);
        }
        operador.usuario._schema = selectedSchema[0];
        this.setState({loading: false, operador});
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        } else {
            if (this.state.operador.usuario && this.state.operador.usuario.id) {
                return (
                    <UserContext.Provider value={this.state.operador}>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/login" component={Login}/>
                                <Route path="/totem/:schema/:id" exact component={TabletPassivo}/>
                                <Route path="/tablet" component={TabletAtivo}/>
                                <Route path="/forms" component={TabletFormulario}/>
                                <Page>
                                    <Route exact path="/" component={Home}/>
                                    <Route path="/unidades" component={Unidade}/>
                                    <Route path="/vagas" component={Vaga}/>
                                    <Route path="/credenciais" component={Credencial}/>
                                    <Route path="/equipamentos" component={Equipamento}/>
                                    <Route path="/painel-controle-acesso" component={PainelControleAcesso}/>
                                    <Route path="/espacos" component={Espaco}/>
                                    <Route path="/relatorios" component={Relatorio}/>
                                    <Route path="/pessoas-juridicas" component={PessoaJuridica}/>
                                    <Route path="/lugares" component={Lugar}/>
                                    <Route path="/modelos-acesso" component={ModeloAcesso}/>
                                    <Route path="/pessoas-fisicas" component={PessoaFisica}/>
                                    <Route path="/portaria" component={Portaria}/>
                                    <Route path="/guarita" component={Guarita}/>
                                    <Route path="/agendas" component={Agenda}/>
                                    {this.state.operador?.pessoa ? <Route path="/autorizacoes-acesso" component={AutorizacaoAcesso}/> : null}
                                    <Route path="/encomendas" component={Encomenda}/>
                                    <Route path="/monitor" component={Monitor}/>
                                    <Route path="/pre-agendamentos" component={PreAgendamento}/>
                                    <Route path="/pre-agendamentos-fixos" component={PreAgendamentoFixo}/>
                                    <Route path="/avisos" component={Aviso}/>
                                    <Route path="/bloqueios" component={Bloqueio}/>
                                    <Route path="/feriados" component={Feriado}/>
                                    <Route path="/formularios" component={Formulario}/>
                                    <Route path="/cameras" component={Camera}/>
                                    <Route path="/etiquetas" component={Etiqueta}/>
                                    <Route path="/tarifa-contas" component={TarifaConta}/>
                                    <Route path="/mensagens" component={Mensagem}/>
                                    <Route path="/animais" component={Animal}/>
                                    <Route path="/modelos-agenda" component={ModeloAgenda}/>
                                    <Route path="/contratos-trabalho" component={ContratoTrabalho}/>
                                    <Route path="/grupos-trabalho" component={GrupoTrabalho}/>
                                    <Route path="/jornadas-trabalho" component={JornadaTrabalho}/>
                                    <Route path="/matrizes-horario" component={MatrizHorario}/>
                                    <Route path="/veiculos" component={Veiculo}/>
                                    <Route path="/comunicados" component={Comunicado}/>
                                    <Route path="/logs-acesso" component={LogAcesso}/>
                                    <Route path="/logs-ponto" component={LogPonto}/>
                                    <Route path="/ocorrencias" component={Ocorrencia}/>
                                    <Route path="/monitoramentos" component={Monitoramento}/>
                                    <Route path="/manutencao" component={Manutencao}/>
                                    <Route path="/configuracoes" component={Configuracao}/>
                                    <Route path="/controladores" component={Controlador}/>
                                    <Route path="/usuarios" component={Usuario}/>
                                    <Route path="/perfis" component={Perfil}/>
                                    <Route path="/monitor-presenca" component={MonitorPresenca}/>
                                    <Route path="/reservas" component={Reserva}/>
                                    <Route path="/bots-mensageiros" component={MessengerBot}/>
                                </Page>
                            </Switch>
                        </BrowserRouter>
                        <TimeoutObserver/>
                    </UserContext.Provider>
                );
            } else {
                return (
                    <BrowserRouter>
                        <Switch>
                            <Route path="/totem/:schema/:id" exact component={TabletPassivo}/>
                            <Login/>
                        </Switch>
                    </BrowserRouter>
                );
            }
        }
    }

}

import React, {Component} from "react";
import {PanelContent} from "../commons/PanelContent";
import {Panel} from "primereact/components/panel/Panel";
import {PanelFooter} from "../commons/PanelFooter";
import {DataTable} from "primereact/components/datatable/DataTable";
import {GUI} from "../../utils/Constants";
import {Shortcut} from "../commons/Shortcut";
import {ModalRealm} from "../commons/ModalRealm";
import {Action} from "../commons/Action";
import {Column} from "primereact/components/column/Column";
import {DatePicker} from "../commons/DatePicker";
import {SelectCredencial} from "../../select/SelectCredencial";
import {TextField} from "../commons/TextField";
import {Combobox} from "../commons/Combobox";
import {MultiCombobox} from "../commons/MultiCombobox";
import {
    AtuadoresOrdinal,
    diaSemana,
    MapFlags,
    MLogAcesso,
    optionsModoEtiqueta,
    RespostasOrdinal
} from "../../utils/models/MLogAcesso";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {SelectLugar} from "../../select/SelectLugar";
import {SelectEquipamento} from "../../select/SelectEquipamento";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {MCredencial} from "../../utils/models/MCredencial";
import moment from "moment";
import {LogAcessoService} from "../servicos/LogAcessoService";
import {Validator} from "../../utils/Validator";
import {mapByID, toSrc} from "../../utils/StringUtils";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {UserData} from "../../utils/UserData";
import {EditarCredencial} from "../Credencial/EditarCredencial";
import {CredencialService} from "../servicos/CredencialService";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {Label} from "../commons/Label";
import {SafetyWebSocket} from "../../utils/WebSocket";
import "../../auxiliar.css";
import {httpGet} from "../../utils/Request";

export class LogAcesso extends Component {

    state = {
        firstResult: 0,
        inicio: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
        fim: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
        pessoa: null,
        lugar: null,
        equipamento: null,
        etiquetas: [],
        credencial: null,
        codigo: "",
        forma: null,
        atividades: [],
        respostas: [],
        logsAcesso: [],
        eventos: [],
        size: 100,
        equipamentos: [],
        lockSearch: false,
        autoUpdate: false,
        gestor: null,
        lockGestor: false,
        minDate: moment().add(-1, "year").toDate(),
        modoEtiqueta: "log_acesso"
    };

    logAcessoService = new LogAcessoService();
    credencialService = new CredencialService();
    equipamentoService = new EquipamentoService();

    componentDidMount() {
        const gestor = UserData.operador?.pessoa;
        if (gestor?.id) {
            httpGet(`/pessoas-fisicas?gestor=id:${gestor.id}&page=0&size=1`).then(subordinados => {
                if (subordinados?.length) {
                    this.setState({gestor, lockGestor: true});
                }
            });
        }
    }

    printLogAcesso = (la) => {
        let messages = [];
        if (la.credencial) {
            if (la.pessoa) {
                switch (la.atividade) {
                    case "REQUISICAO":
                    case "SEQUENCIA":
                        switch (la.forma) {
                            case "MANUAL":
                                messages.push(<div>{`O usuário ${la.responsavel?.login || "<anônimo>"} fez uma liberação manual para ${la.pessoa?.nome || "uma pessoa sem identificação"}`}</div>);
                                messages.push(<div style={{color: "#388E3C"}}>Permissão de acesso concedida</div>);
                                break;
                            default:
                                messages.push(<div>{`${la.pessoa.nome} usou a credencial ${la.credencial.descricao}`}</div>);
                                if (la.respostas_?.split(";").some(r => RespostasOrdinal[r.split(",")[0]] === "LIBERACAO")) {
                                    const equipamento = this.state.equipamentos[la.equipamento.id];
                                    if (equipamento) {
                                        const atuadoresLiberados = la.respostas_.split(";").filter(r => RespostasOrdinal[r.split(",")[0]] === "LIBERACAO").map(r => AtuadoresOrdinal[r.split(",")[1]]);
                                        messages.push(<div style={{color: "#388E3C"}}>{`Acesso${la.eventos.includes("FORA_DE_HORARIO") ? " fora de horário" : ""} permitido ${equipamento.dispositivos.filter(d => atuadoresLiberados.includes(d.atuador)).map(d => d.destino ? `para ${d.destino.descricao}` : "para fora").join(", ")}`}</div>);
                                    }
                                } else {
                                    if (la.respostas_?.split(";").some(r => RespostasOrdinal[r.split(",")[0]] === "BLOQUEIO")) {
                                        if (la.eventos.includes("BLOQUEADO_INTERJORNADA")) {
                                            messages.push(<div style={{color: "#D32F2F"}}>Acesso bloqueado porque a pessoa tem um bloqueio por interjornada</div>);
                                        } else if (la.eventos.includes("BLOQUEADO_INTERVALO")) {
                                            messages.push(<div style={{color: "#D32F2F"}}>Acesso bloqueado porque a pessoa tem um bloqueio por intervalo</div>);
                                        } else {
                                            messages.push(<div style={{color: "#D32F2F"}}>{la.respostas_.split(";").map(r => MLogAcesso.RespostasOrdenadas[RespostasOrdinal[r.split(",")[0]]])[0]}</div>);
                                        }
                                    } else {
                                        if (la.respostas_?.split(";").some(r => RespostasOrdinal[r.split(",")[0]] === "SEQUENCIA")) {
                                            messages.push(<div style={{color: "#388E3C"}}>Sequência de autenticação {`${(la.sequencia || 0) + 1}ª etapa`}</div>);
                                        } else {
                                            messages.push(<div style={{color: "#D32F2F"}}>{la.respostas_?.split(";").map(r => MLogAcesso.RespostasOrdenadas[RespostasOrdinal[r.split(",")[0]]])[0]}</div>);
                                        }
                                    }
                                }
                                break;
                        }
                        break;
                    case "PASSAGEM":
                        messages.push(<div>{`${la.pessoa.nome} passou para ${la.destino?.descricao || "fora"}`}</div>);
                        messages.push(<div style={{color: "#388E3C"}}>Passagem registrada</div>);
                        break;
                    default:
                        break;
                }
            } else {
                let auxLink
                if (la.credencial && !la.credencial.pessoa && (la.forma === "CONTROLE" || la.forma === "PROXIMIDADE") && UserData.hasRole("CRDC") && !this.state.credenciais?.includes(la.codigo)) {
                    auxLink = <span>. (<span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.editarCredencial(la.credencial)}>Deseja vinculá-la a alguém?</span>)</span>;
                }
                messages.push(<div>{`A credencial ${la.credencial.descricao} foi utilizada, mas não está vinculada a ninguém`} {auxLink}</div>);
                messages.push(<div style={{color: "#D32F2F"}}>Acesso bloqueado porque a pessoa não foi identificada</div>);
            }
        } else {
            switch (la.atividade) {
                case "AJUSTE":
                    messages.push(<div>{`A posição de ${la.pessoa.nome} estava incorreta e foi ajustada automaticamente`}</div>);
                    break;
                case "ARROMBAMENTO":
                    messages.push(<div>A porta foi aberta sem autenticação (possível violação de acesso)</div>);
                    break;
                default:
                    let auxLink = null;
                    if (la.forma === "PROXIMIDADE" && la.codigo && UserData.hasRole("CRDC") && !this.state.credenciais?.includes(la.codigo)) {
                        auxLink = <span>. (<span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.criarCredencial(la.codigo)}>Deseja cadastrá-la?</span>)</span>;
                    }
                    switch (la.forma) {
                        case "DIGITAL":
                        case "FACIAL":
                            messages.push(<div>{`A biometria ${la.forma === "FACIAL" ? "facial" : "digital"} não foi reconhecida`}</div>);
                            messages.push(<div style={{color: "#F57F17"}}>Acesso bloqueado porque não foi possível identificar a pessoa</div>);
                            break;
                        default:
                            messages.push(<div>{`Não foi encontrada nenhuma credencial de ${MCredencial.MapFormas[la.forma]} com código ${la.codigo || la.placa}`} {auxLink}</div>);
                            messages.push(<div style={{color: "#F57F17"}}>Acesso bloqueado porque a credencial não está cadastrada</div>);
                            break;
                    }
            }
        }
        const foto = la.pessoa?.ultimaFoto_ ? toSrc({path: `/images/photos/${la.pessoa.ultimaFoto_}-t.jpg`}) : null;
        messages.unshift(
            <div style={{width: "72px", height: "72px"}} className={`logs-acesso-v2-foto${la.eventos && la.eventos.length ? " events": ""}`}>
                {foto ? <img alt="" style={{width: "72px", height: "72px"}} src={foto}/> : <span className="logs-acesso-v2-foto sem-foto logs-acesso"/>}
            </div>
        );
        if (la.etiquetas_) {
            messages.push(...la.etiquetas_.split(";").map(e => <EtiquetaChip id={e} etiqueta={e}/>));
        }
        messages.unshift(
            <div className="logacesso-flags-container">
                {la.veiculo ? <div className="ui-tzm-minchip placa">{la.veiculo.placa}</div> : null}
                {la.flags?.map(flag => MapFlags[flag])}
                {la.eventos?.map(evento => <div key={evento} style={{background: "rgba(180, 0, 0, .5)"}} className="ui-tzm-minchip">{MLogAcesso.EventosOrdenados[evento]}</div>)}
            </div>
        );
        return <div style={{position: "relative"}}>{messages}</div>;
    }

    detalhesRegistros = [
        <Column key={0} sortable field="data" header="Data" style={{width: "8em", textAlign: "center"}} body={la => (
            <div>
                {moment(la.data).format("DD/MM/YYYY HH:mm:ss")}<br/>
                <span style={{fontSize: ".7em", fontStyle: "italic"}}>{diaSemana[la.diaSemana]}</span>
            </div>
        )}/>,
        <Column key={1} header="Descrição" body={this.printLogAcesso}/>,
        <Column key={2} header="Equipamento" style={{width: "20em"}} body={la => (
            <div>
                {la.equipamento?.descricao}
                <div style={{fontSize: "11px"}}>Leitor: {la.leitor}</div>
                <div style={{fontSize: "11px"}}>Código: {la.forma === "SENHA" ? "*******" : la.codigo}</div>
            </div>
        )}/>,
    ];

    criarCredencial = codigo => {
        const credencial = {
            descricao: "",
            forma: "PROXIMIDADE",
            codigo,
            _wiegand: 0,
            itens: [],
            flags: []
        };
        ModalRealm.showDialog(<EditarCredencial credencial={credencial} onModalClose={() => {
            ModalRealm.showInformacao("info", ["Credencial cadastrada com sucesso!"]);
            this.listarRegistros();
        }}/>);
    }

    editarCredencial = credencial => {
        httpGet(`/credenciais/${credencial.id}`).then(credencial => {
            ModalRealm.showDialog(<EditarCredencial credencial={credencial} onModalClose={this.listarRegistros}/>);
        });
    }

    lockSearch = () => {
        return (
            !this.state.inicio?.length
            || !this.state.fim?.length
            || Math.abs(moment(this.state.inicio).diff(moment(this.state.fim), "days")) > 7
        );
    }

    listarRegistros = () => {
        if (this.state.lockSearch || this.lockSearch()) {
            return;
        }
        let properties = "search=";
        let parameters = [
            "atividade!PRESENCA",
        ];
        if (this.state.inicio) parameters.push(`data>${this.state.inicio}`);
        if (this.state.fim) parameters.push(`data<${this.state.fim}`);
        if (this.state.atividades.length) parameters.push(`atividade:${this.state.atividades.join(",")}`);
        if (this.state.eventos.length) parameters.push(`eventos:${this.state.eventos.join(",")}`);
        if (this.state.codigo) parameters.push(`codigo:${this.state.codigo}`);
        if (this.state.forma) parameters.push(`forma:${this.state.forma}`);
        properties += parameters.join(";");
        if (this.state.etiquetas.length) properties += `&${this.state.etiquetas.map(e => `etiquetas=id:${e.id}`).join("&")}`;
        if (this.state.modoEtiqueta?.length) properties += `&modoEtiqueta=${this.state.modoEtiqueta}`;
        if (this.state.respostas.length) properties += `&respostas=resposta:${this.state.respostas.join(",")}`;
        if (Validator.isEntidade(this.state.equipamento)) properties += `&equipamento=id:${this.state.equipamento.id}`;
        if (Validator.isEntidade(this.state.credencial)) properties += `&credencial=id:${this.state.credencial.id}`;
        if (Validator.isEntidade(this.state.destino)) properties += `&destino=id:${this.state.destino.id}`;
        if (Validator.isEntidade(this.state.pessoa)) properties += `&pessoa=id:${this.state.pessoa.id}`;
        if (Validator.isEntidade(this.state.gestor)) properties += `&gestor=id:${this.state.gestor.id}`;
        if (Validator.isEntidade(this.state.empresa)) properties += `&empresa=id:${this.state.empresa.id}`;
        this.setState({lockSearch: true});
        this.logAcessoService.listarDTO(properties, {sort: "id desc", page: 0, size: this.state.size}).then(logsAcesso => {
            const e = logsAcesso.filter(la => la.equipamento).map(la => la.equipamento.id).filter((e, i, a) => a.indexOf(e) === i);
            this.equipamentoService.listar(`search=id:${e.join(",")}`, {page: 0, size: 100}).then(equipamentos => this.setState({equipamentos: mapByID(equipamentos)}, () => {
                const codigos = logsAcesso.filter(la => !la.credencial && la.forma === "PROXIMIDADE" && la.codigo).map(c => c.codigo);
                if (codigos.length) {
                    this.credencialService.listar(`search=codigo:${codigos.join(",")};forma=PROXIMIDADE&fields=codigo`, {page: 0, size: this.state.size}).then(credenciais => {
                        this.setState({logsAcesso, firstResult: 0, lockSearch: false, credenciais: credenciais.map(c => c.codigo)});
                    });
                } else {
                    this.setState({logsAcesso, firstResult: 0, lockSearch: false});
                }
            }));
        });
    }

    handlePageChange = (event) => this.setState({firstResult: event.first});

    handleUpdate = (event) => this.setState({[event.name]: event.value});

    colorirLinha = (la => {
        const classes = {"logs-acesso-v2": true};
        switch (la.atividade) {
            case "REQUISICAO":
            case "SEQUENCIA":
                if (la.respostas_?.split(";").some(r => ["LIBERACAO", "SEQUENCIA"].includes(RespostasOrdinal[r.split(",")[0]]))) {
                    return {...classes, "green": true};
                } else {
                    if (!la.credencial && !la.pessoa) {
                        return {...classes, "yellow": true};
                    } else {
                        return {...classes, "red": true};
                    }
                }
            case "PASSAGEM":
                return {...classes, "blue": true};
            default:
                return {...classes, "": true};
        }
    });

    queueLogAcesso = logAcesso => {
        if (this.state.autoUpdate) {
            if (this.state.inicio && moment(this.state.inicio).isAfter(logAcesso.data)) return;
            if (this.state.fim && moment(this.state.fim).isBefore(logAcesso.data)) return;
            if (this.state.atividades.length && !this.state.atividades.includes(logAcesso.atividade)) return;
            if (this.state.eventos.length && !logAcesso.eventos.some(e => this.state.eventos.includes(e))) return;
            if (this.state.codigo && logAcesso.codigo !== this.state.codigo) return;
            if (this.state.forma && logAcesso.forma !== this.state.forma) return;
            if (this.state.etiquetas.length && !logAcesso.etiquetas.some(e => this.state.etiquetas.some(e1 => e1.id === e.id))) return;
            if (this.state.respostas.length && !logAcesso.respostas.some(r => this.state.respostas.includes(r.resposta))) return;
            if (Validator.isEntidade(this.state.equipamento) && logAcesso.equipamento.id !== this.state.equipamento.id) return;
            if (Validator.isEntidade(this.state.credencial) && logAcesso.credencial?.id !== this.state.credencial.id) return;
            if (Validator.isEntidade(this.state.destino) && logAcesso.destino?.id !== this.state.destino.id) return;
            if (Validator.isEntidade(this.state.pessoa) && logAcesso.pessoa?.id !== this.state.pessoa.id) return;
            if (Validator.isEntidade(this.state.empresa) && logAcesso.empresa?.id !== this.state.empresa.id) return;
            const {logsAcesso} = this.state;
            logsAcesso.unshift(logAcesso);
            this.setState({logsAcesso});
        }
    }

    toggleAutoUpdate = () => this.setState({autoUpdate: !this.state.autoUpdate});

    sizeOptions = [
        {label: "100", value: 100},
        {label: "500", value: 500},
        {label: "1.000", value: 1000},
        {label: "10.000", value: 10000},
        {label: "100.000", value: 100000}
    ];

    render() {
        return (
            <div>
                <Panel header="Registros de Acesso">
                    <PanelContent>
                        <div className="ui-g-4 ui-g-nopad" style={{position: "relative"}}>
                            <div className="ui-g">
                                <DatePicker minDate={this.state.minDate} grid={6} showTime showSeconds label="Período" name="inicio" value={this.state.inicio} onChange={this.handleUpdate}/>
                                <DatePicker grid={6} showTime showSeconds label={
                                    <Label>
                                        &nbsp;
                                        {this.lockSearch() ? <div style={{color: "#F44336", position: "absolute", top: "4px", right: "8px", fontSize: "1em"}}>O intervalo de datas não pode ser maior que sete dias</div> : ""}
                                    </Label>
                                } name="fim" value={this.state.fim} onChange={this.handleUpdate}/>
                            </div>
                        </div>
                        <SelectPessoaFisica grid={4} label="Pessoa" name="pessoa" value={this.state.pessoa} onChange={this.handleUpdate} placeholder="Pesquise por nome ou qualquer documento"/>
                        <SelectLugar grid={4} label="Lugar" name="destino" value={this.state.destino} onChange={this.handleUpdate} placeholder="Pesquise pela descrição"/>
                        <SelectEquipamento grid={4} label="Equipamento" name="equipamento" value={this.state.equipamento} onChange={this.handleUpdate} placeholder="Pesquise pela descrição"/>
                        <MultiSelectEtiqueta onSelect={this.handleUpdate} grid={6} label="Etiquetas" name="etiquetas" value={this.state.etiquetas} onChange={this.handleUpdate} placeholder="Pesquise etiquetas"/>
                        <Combobox grid={2} options={optionsModoEtiqueta} label="&nbsp;" name="modoEtiqueta" value={this.state.modoEtiqueta} onChange={this.handleUpdate}/>
                        <SelectCredencial placeholder="Pesquise pela descrição" grid={4} label="Credencial" value={this.state.credencial} name="credencial" onChange={this.handleUpdate}/>
                        <TextField grid={4} label="Código" value={this.state.codigo} onChange={this.handleUpdate} name="codigo"/>
                        <Combobox grid={4} label="Forma" options={[{label: "Todas", value: null}, ...MCredencial.Formas]} value={this.state.forma} onChange={this.handleUpdate} name="forma"/>
                        <SelectPessoaFisica disabled={this.state.lockGestor} grid={4} label="Gestor" name="gestor" value={this.state.gestor} onChange={this.handleUpdate} placeholder="Pesquise por nome ou qualquer documento"/>
                        <MultiCombobox grid={4} label="Respostas" name="respostas" value={this.state.respostas} onChange={this.handleUpdate} options={MLogAcesso.Respostas} defaultLabel="Todas"/>
                        <SelectPessoaJuridica grid={4} label="Empresa" name="empresa" value={this.state.empresa} onChange={this.handleUpdate} placeholder="Pesquise por nome, CNPJ ou CEI"/>
                        <MultiCombobox grid={6} label="Atividades" name="atividades" value={this.state.atividades} onChange={this.handleUpdate} options={MLogAcesso.Atividades} defaultLabel="Todas"/>
                        <MultiCombobox grid={5} label="Eventos" name="eventos" value={this.state.eventos} onChange={this.handleUpdate} options={MLogAcesso.Eventos} defaultLabel="Todos"/>
                        <Combobox grid={1} options={this.sizeOptions} value={this.state.size} onChange={this.handleUpdate} label="Quantidade" name="size"/>
                    </PanelContent>
                    <PanelFooter>
                        <Action label="Tempo Real" style={{float: "left"}} icon={`fa fa-${this.state.autoUpdate ? "check-" : ""}square`} onClick={this.toggleAutoUpdate}/>
                        <Action disabled={this.state.lockSearch || this.lockSearch()} label="Procurar" icon="fa-search" onClick={this.listarRegistros} />
                    </PanelFooter>
                </Panel>
                <div className="ui-tzm-panel-separator"/>
                <DataTable rowClassName={this.colorirLinha}
                           children={this.detalhesRegistros}
                           first={this.state.firstResult}
                           onPage={this.handlePageChange}
                           emptyMessage="Nenhum registro encontrado"
                           rows={GUI.defaultRows}
                           rowsPerPageOptions={GUI.rowsPerPage}
                           paginator
                           value={this.state.logsAcesso}/>
                <Shortcut onEnter={this.listarRegistros}/>
                <ModalRealm/>
                <div className="ui-tzm-pagebottom"/>
                <SafetyWebSocket path="/logs-acesso" onMessage={this.queueLogAcesso}/>
            </div>
        );
    }

}

import {observable} from "mobx";
import {observer} from "mobx-react";
import React from "react";

export const Progressbar = observer(class extends React.Component {
	
	static staticProps = observable({active: false});

	static setActive(active) {
		Progressbar.staticProps.active = active;
	}

	render() {
		return (
			<div className={`ui-neoprogressbar ${Progressbar.staticProps.active ? " ui-neoprogressbar-active" : ""}`}></div>
		);
	}

});

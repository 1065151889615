import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarMessengerBot} from "../components/MessengerBot/EditarMessengerBot";
import {MessengerBotService} from "../components/servicos/MessengerBotService";
import {MMessengerBot} from "../utils/models/MMessengerBot";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectMessengerBot extends React.Component {

	service = new MessengerBotService();

	security = UserData.security("MBT");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarMessengerBot key={Math.random()} messengerBot={{...MMessengerBot.Modelo(), descricao: event.value.descricao.replace(`Criar bot mensageiro `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select entidade="messengerBot" insertable={this.security.create && this.props.insertable} service={this.service} field="descricao" query="descricao" onSelect={this.onSelect} {...this.props} />;
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "../commons/Action";
import {CheckSquare} from "../commons/CheckSquare";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";

export class OpcoesPortaria extends React.Component {

	constructor() {
		super();
		let habilitarBlocosConsulta = window.localStorage.getItem("safety-portaria-habilitarBlocosConsulta");
		habilitarBlocosConsulta = habilitarBlocosConsulta != null ? habilitarBlocosConsulta === "true" : true;
		let mostrarAcoesPessoaVisita = window.localStorage.getItem("safety-portaria-mostrarAcoesPessoaVisita");
		mostrarAcoesPessoaVisita = mostrarAcoesPessoaVisita != null ? mostrarAcoesPessoaVisita === "true" : false;
		let nomesEmMaiuscula = window.localStorage.getItem("safety-portaria-nomesEmMaiuscula");
		nomesEmMaiuscula = nomesEmMaiuscula != null ? nomesEmMaiuscula === "true" : true;
		this.state = {
			visible: true,
			habilitarBlocosConsulta,
			mostrarAcoesPessoaVisita,
			nomesEmMaiuscula
		};
	}

	handleChange = (event) => {
		window.localStorage.setItem(`safety-portaria-${event.name}`, event.value);
		this.setState({[event.name]: event.value});
	}

	onClose = () => {
		this.props.onClose();
		this.setState({visible: false});
	}

	render() {
		return (
			<Dialog modal header="Opções" visible={this.state.visible} style={{width: "350px"}} onHide={this.onClose}>
				<DialogContent>
					<CheckSquare label="Habilitar blocos de consulta na Portaria" name="habilitarBlocosConsulta" onChange={this.handleChange} checked={this.state.habilitarBlocosConsulta} />
					<CheckSquare label="Sempre mostrar Ações em pessoas da visita" name="mostrarAcoesPessoaVisita" onChange={this.handleChange} checked={this.state.mostrarAcoesPessoaVisita} />
					<CheckSquare label="Forçar o preenchimento dos nomes em maiúscula" name="nomesEmMaiuscula" onChange={this.handleChange} checked={this.state.nomesEmMaiuscula} />
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="OK" icon="fa-check" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

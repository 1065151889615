import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MJornadaTrabalho} from "../../utils/models/MJornadaTrabalho";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {JornadaTrabalhoService} from "../servicos/JornadaTrabalhoService";

export class EditarJornadaTrabalho extends React.Component {

	state = {
		jornadaTrabalho: Object.assign(MJornadaTrabalho.Modelo(), this.props.jornadaTrabalho),
		visible: true,
		lockSave: false
	};

	jornadaTrabalhoService = new JornadaTrabalhoService();
	security = UserData.security("JTB", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarJornadaTrabalho = () => {
		if (this.security.edit) {
			let jornadaTrabalho = MJornadaTrabalho.cleanupBeforeSave(Object.assign({}, this.state.jornadaTrabalho));
			let messages = MJornadaTrabalho.validarParaSalvar(jornadaTrabalho);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.jornadaTrabalhoService.salvar(jornadaTrabalho).then((jornadaTrabalho) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(jornadaTrabalho);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let jornadaTrabalho = this.state.jornadaTrabalho;
		jornadaTrabalho[event.name] = event.value;
		this.setState({jornadaTrabalho});
	}

	render() {
		return (
			<Dialog modal header="Jornada de Trabalho" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.jornadaTrabalho.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/jornadas-trabalho", this.state.jornadaTrabalho)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.jornadaTrabalho.id)) || this.state.lockSave} onClick={this.salvarJornadaTrabalho} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarJornadaTrabalho} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

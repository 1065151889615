import {MEntidade} from "./MEntidade";
import React from "react";

export class MSetupSistema extends MEntidade {

    static Flags = [
        {label: "Mostrar Visitados", value: "MOSTRAR_VISITADO"},
        {label: "Modo de Emergência", value: "MODO_TREINAMENTO"},
        {label: "Criar Credenciais de Facial Automaticamente", value: "GERAR_FACIAL_AUTOMATICAMENTE"},
        {label: "Liberar Credencial Temporária Somente na Passagem", value: "LIBERAR_CREDENCIAL_TEMPORARIA_SOMENTE_NA_PASSAGEM"},
        {label: "Validar Lado na Saída com Credencial Temporária", value: "VALIDAR_LADO_NA_SAIDA_COM_CREDENCIAL_TEMPORARIA"},
        {label: "Fechar Agendas Temporárias Automaticamente", value: "FECHAR_AGENDAS_TEMPORARIAS_AUTOMATICAMENTE"},
        {label: "Permitir Acesso Mesmo com Temperatura Anormal", value: "PERMITIR_ACESSO_MESMO_COM_TEMPERATURA_ANORMAL"},
        {label: "Listar Somente Credenciais Temporárias Liberadas", value: "LISTAR_SOMENTE_CREDENCIAIS_TEMPORARIAS_LIBERADAS"},
        {label: "Visitante com Facial Lança Acesso Manual", value: "VISITANTE_ENTRA_MANUALMENTE"},
        {label: "Buscar Unidades Somente Pela Descrição", value: "BUSCAR_UNIDADES_SOMENTE_PELA_DESCRICAO"},
        {label: "Liberar Acesso de Visitante com Facial", value: "LIBERAR_VISITANTE_COM_FACIAL"},
        {label: "Validar Credencial Temporária com Agenda Temporária", value: "VALIDAR_CREDENCIAL_TEMPORARIA_COM_AGENDA_TEMPORARIA"},
        {label: "Baixar Acessos Sem Movimentação Recente", value: "BAIXAR_ACESSOS_SEM_MOVIMENTO_RECENTE"},
        {label: "Manter Agendas Encerradas Permanentemente", value: "MANTER_AGENDAS_ENCERRADAS_POR_TEMPO_INDETERMINADO"}
    ];

}

export const setupSistemaOptionsFlags = [
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/visitados.png")}/> Mostrar Visitados</div><span>Mostrar a guia de "Visitados" nas agendas de acesso</span></div>, value: "MOSTRAR_VISITADO"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/treinamento.png")}/> Modo de Treinamento</div><span>Desliga a validação de acesso por agendas ou autorizações de acesso</span></div>, value: "MODO_TREINAMENTO"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/cameras.png")}/> Facial Automática</div><span>Gera as credenciais de biometria facial automaticamente ao salvar o cadastro</span></div>, value: "GERAR_FACIAL_AUTOMATICAMENTE"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/direcoes.png")}/> Temporária até Passagem</div><span>Libera as credenciais temporárias somente na passagem</span></div>, value: "LIBERAR_CREDENCIAL_TEMPORARIA_SOMENTE_NA_PASSAGEM"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/lancar-saida.png")}/> Validar Saída Temporária</div><span>Validar lado na saída com credencial temporária</span></div>, value: "VALIDAR_LADO_NA_SAIDA_COM_CREDENCIAL_TEMPORARIA"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/agenda-encerrado.png")}/> Fechamento de Temporárias</div><span>Encerra as agendas temporárias automaticamente</span></div>, value: "FECHAR_AGENDAS_TEMPORARIAS_AUTOMATICAMENTE"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/todos.png")}/> Listar Temporárias Livres</div><span>Lista (na portaria) somente credenciais temporárias livres</span></div>, value: "LISTAR_SOMENTE_CREDENCIAIS_TEMPORARIAS_LIBERADAS"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/logs-acesso.png")}/> Visitante Acesso Manual</div><span>Permite o lançamento de acesso manual na portaria para visitantes</span></div>, value: "VISITANTE_ENTRA_MANUALMENTE"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/unidades.png")}/> Buscar Unidades Descrição</div><span>Buscar unidades na portaria somente pela descrição</span></div>, value: "BUSCAR_UNIDADES_SOMENTE_PELA_DESCRICAO"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/pessoas.png")}/> Liberar Visitante Facial</div><span>Liberar visitantes com utilização de biometria facial</span></div>, value: "LIBERAR_VISITANTE_COM_FACIAL"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/matrizes-horario.png")}/> Combo Temporário</div><span>Validar credenciais temporárias com agendas temporárias</span></div>, value: "VALIDAR_CREDENCIAL_TEMPORARIA_COM_AGENDA_TEMPORARIA"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/logs-historicos.png")}/> Arquivar Acessos</div><span>Habilita processo de arquivação de registros de acesso anteriores a um ano</span></div>, value: "ARQUIVAR_REGISTROS_DE_ACESSO_HISTORICOS"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/limpar-presencas.png")}/> Limpar Presenças Expiradas</div><span>Dá baixa (lança saída) de pessoas sem acesso nos últimos 3 dias</span></div>, value: "BAIXAR_ACESSOS_SEM_MOVIMENTO_RECENTE"},
    {label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/agenda-encerrado.png")}/> Manter Agendas Encerradas</div><span>Mantém (não exclui) agendas encerradas antigas por tempo indeterminado</span></div>, value: "MANTER_AGENDAS_ENCERRADAS_POR_TEMPO_INDETERMINADO"},
];

export const passwordLevels = [
    {label: <span className="password-score-button score-1">1</span>, value: 1},
    {label: <span className="password-score-button score-2">2</span>, value: 2},
    {label: <span className="password-score-button score-3">3</span>, value: 3},
    {label: <span className="password-score-button score-4">4</span>, value: 4},
    {label: <span className="password-score-button score-5">5</span>, value: 5}
];

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarEquipamento} from "../components/Equipamento/EditarEquipamento";
import {EquipamentoService} from "../components/servicos/EquipamentoService";
import {MEquipamento} from "../utils/models/MEquipamento";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectEquipamento extends React.Component {

	service = new EquipamentoService();

	security = UserData.security("EQP");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={{...MEquipamento.Modelo(), descricao: event.value.descricao.replace(`Criar equipamento `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select insertable={this.security.create && this.props.insertable} entidade="equipamento" service={this.service} field="descricao" query="status:ATIVADO;categoria:ACESSO;descricao" {...this.props} onSelect={this.onSelect} />;
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MExpiracao extends MEntidade {

  static Modelo() {
    return {
      etiquetas: [],
      dias: 365
    };
  }

  static cleanupBeforeSave(expiracao) {
    expiracao.etiquetas = expiracao.etiquetas.filter(e => Validator.isEntidade(e));
		return MEntidade.cleanupBeforeSave(expiracao);
	}

	static validarParaSalvar(expiracao) {
		let messages = [];
		if (expiracao.dias === 0) messages.push("O número de dias para expirar deve ser maior que zero");
		return messages;
	}

}

import moment from "moment";

export class DayOfWeek {

	static Abbr = [
		"D", "S", "T", "Q", "Q", "S", "S"
	];

	static daysOfWeek = [
		"domingo",
		"segunda-feira",
		"terça-feira",
		"quarta-feira",
		"quinta-feira",
		"sexta-feira",
		"sábado"
	];

	static from(index) {
		return DayOfWeek.daysOfWeek[index];
	}

}

export class LocalDate {

	static now() {
		let data = new Date();
		let real = new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0);
		return real.toISOString().split("T")[0];
	}

	static lastDayOfPastMonth() {
		let now = new Date();
		return new Date(now.getFullYear(), now.getMonth(), 0).getDate();
	}

}

export class LocalDateTime {

	static fullDateTimeISO() {
		let data = new Date();
		let timezoneOffset = data.getTimezoneOffset() * 60000;
		return new Date(data.getTime() - timezoneOffset).toISOString();
	}

	static toLocalDate(isoDate) {
		let data = new Date(isoDate);
		return new Date(data.getTime() + data.getTimezoneOffset() * 60000);
	}

	static now() {
		let data = new Date();
		let real = new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0);
		return real.toISOString().split(".")[0];
	}

	static atStartOfDay() {
		let data = new Date();
		let real = new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0);
		return moment(real).format("DD/MM/YYYY HH:mm:ss");
	}

	static atStartOfDayISO(data) {
		data = data || new Date();
		let real = new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0);
		return moment(real).format("YYYY-MM-DDTHH:mm:ss");
	}

	static atEndOfDay() {
		return moment().format("DD/MM/YYYY 23:59:59");
	}

	static atEndOfDayISO(data = null) {
		return (data ? moment(data) : moment()).format("YYYY-MM-DDT23:59:59");
	}

}

export class LocalTime {

	static withoutSeconds(time) {
		if (time != null) {
			time = time.split(":");
			if (time.length >= 2) {
				return `${time[0]}:${time[1]}`;
			}
		}
		return time;
	}

}

export function isLogAcessoNotBefore5Minutes(logAcesso) {
	return moment().add(-5, "minutes").isBefore(moment(logAcesso.data));
}

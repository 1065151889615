import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MUnidade} from "../../utils/models/MUnidade";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {TextField} from "../commons/TextField";
import {UnidadeService} from "../servicos/UnidadeService";


export class CriarCondominio extends React.Component {

	state = {
		visible: true,
		params: {
			blocos: 1,
			unidades: 1,
			prefixoUnidades: "",
			prefixoBlocos: "",
			radix: 10,
			nomenclaturaBlocos: "ALFABETICO",
			nomenclaturaUnidades: "NUMERICO"
		},
		lockSave: false
	};

	unidadeService = new UnidadeService();

	onClose = () => {
		this.setState({visible: false});
	}

	criarCondominio = () => {
		this.setState({lockSave: true});
		this.unidadeService.inicializar(this.state.params).then(() => {
			this.setState({lockSave: false});
			if (this.props.onModalClose) {
				this.props.onModalClose();
			}
			this.onClose();
		}).catch(() => this.setState({lockSave: false}));
	}

	handleChange = (event) => {
		let params = this.state.params;
		params[event.target.name] = event.target.value;
		this.setState({params});
	}

	handlePRChange = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}

	render() {
		return (
			<Dialog modal header="Criar Unidades" visible={this.state.visible} style={{width: "500px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						<div className="ui-g-4">
							<Label>Número de Blocos</Label>
							<TextField type="number" min={1} name="blocos" onChange={this.handleChange} value={this.state.params.blocos} />
						</div>
						<div className="ui-g-4">
							<Label>Unidades por Bloco</Label>
							<TextField type="number" min={1} name="unidades" onChange={this.handleChange} value={this.state.params.unidades} />
						</div>
						<div className="ui-g-4">
							<Label>Unidades por Pavimento</Label>
							<TextField name="radix" min={1} type="number" onChange={this.handlePRChange} value={this.state.params.radix} />
						</div>
						<div className="ui-g-6">
							<Label>Prefixo dos Blocos</Label>
							<TextField name="prefixoBlocos" onChange={this.handleChange} value={this.state.params.prefixoBlocos} />
						</div>
						<div className="ui-g-6">
							<Label>Prefixo das Unidades</Label>
							<TextField name="prefixoUnidades" onChange={this.handleChange} value={this.state.params.prefixoUnidades} />
						</div>
						<div className="ui-g-6">
							<Label>Nomenclatura dos Blocos</Label>
							<Combobox appendTo={document.body} name="nomenclaturaBlocos" options={MUnidade.Nomenclaturas} onChange={this.handlePRChange} value={this.state.params.nomenclaturaBlocos} />
						</div>
						<div className="ui-g-6">
							<Label>Nomenclatura das Unidades</Label>
							<Combobox appendTo={document.body} name="nomenclaturaUnidades" options={MUnidade.Nomenclaturas} onChange={this.handlePRChange} value={this.state.params.nomenclaturaUnidades} />
						</div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Criar" onClick={this.criarCondominio} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
			</Dialog>
		);
	}

}

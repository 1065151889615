import {ToggleButton} from "primereact/components/togglebutton/ToggleButton";
import React from "react";

export class CheckButton extends React.Component {

	onChange = (event) => {
		if (this.props.onChange) this.props.onChange({name: this.props.name, value: !this.props.checked});
	}

	render() {
		return <ToggleButton onLabel="" offLabel="" {...this.props} onChange={this.onChange} />;
	}

}

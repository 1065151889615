import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {Fetch} from "../../utils/Fetch";

const basePath = process.env.REACT_APP_API_URL;

export class EquipamentoService extends EntidadeNextGenService {

	constructor() {
		super("/equipamentos");
	}

	async me() {
		return await Fetch.Get(`${basePath}/equipamentos/me`).then(async (response) => {
			switch (response.status) {
				case 200:
				case 400:
					return await response.json();
				default:
					return null;
			}
		}).catch(console.log);
	}

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {GraficoService} from "../servicos/GraficoService";
import "./Grafico.css";

export class GraficoSumario extends React.Component {

	constructor() {
		super();
		this.state = {
			acessos1: [],
			acessos2: [],
			etiquetas: [],
			sumario: [],
			_loading3: false
		};
		this.graficoService = new GraficoService();
	}

	UNSAFE_componentWillMount() {
		this.toggleMensal();
		this.queryTimeout = setTimeout(() => {
			this.updateData1();
			this.updateData2();
			this.queryInterval1 = setInterval(this.updateData1, 60000);
			this.queryInterval2 = setInterval(this.updateData2, 60000);
		}, (60 - new Date().getSeconds()) * 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.queryTimeout);
		clearInterval(this.queryInterval1);
		clearInterval(this.queryInterval2);
	}

	updateData1 = () => {
		this.graficoService.atividadeMensal(this.state.dias).then((acessos) => {
			this.setState({total1: acessos.map(a => a.quantidade).reduce((a, b) => a + b, 0), acessos1: acessos.map(acesso => {return {name: acesso.etiqueta, value: acesso.quantidade, fundo: acesso.fundo}}).sort((a, b) => a.name.localeCompare(b.name))});
		});
	}

	updateData2 = () => {
		this.graficoService.acessosNegados(this.state.dias).then((acessos) => {
			this.setState({total2: acessos.map(a => a.quantidade).reduce((a, b) => a + b, 0), acessos2: acessos.map(acesso => {return {name: acesso.resposta, value: acesso.quantidade}}).sort((a, b) => a.name.localeCompare(b.name))});
		});
	}

	updateData3 = () => {
		this.setState({_loading3: true});
		this.graficoService.pessoas(this.state.dias).then((acessos) => {
			this.setState({_loading3: false, sumario: acessos.map(acesso => {return {name: acesso.etiqueta, value: acesso.quantidade}}).sort((a, b) => a.name.localeCompare(b.name))});
		});
	}
	
	colors = ["#FFCDD2", "#EF9A9A", "#E57373", "#EF5350", "#F44336", "#F44336", "#F44336", "#C62828", "#C62828", "#A11818"];

	intlFormat = Intl.NumberFormat("pt-BR");

	columnsSumario = [
		<Column key={0} style={{width: "*"}} header="Categoria" body={(e) => {
			return e.name;
		}} />,
		<Column key={1} style={{width: "8em"}} header="Quantidade" body={(e) => {
			return this.intlFormat.format(e.value);
		}} />
	]

	render() {
		return (
			<div style={{height: "100%"}}>
				<div className="ui-start-chart-title"><span>Sumário de Atividades</span></div>
				<div style={{position: "relative", marginTop: "-10px", display: "inline-block", width: "6.5%", height: "100%"}} />
				<div style={{position: "relative", marginTop: "-6px", display: "inline-block", width: "31%", height: "100%"}}>
					<div style={{position: "absolute", lineHeight: `${this.props.height - 20}px`, width: "100%", textAlign: "center", fontSize: "16px", fontWeight: "600"}}>{this.intlFormat.format(this.state.total1)}</div>
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Tooltip />
							<Pie dataKey="value" isAnimationActive={false} data={this.state.acessos1} innerRadius={this.props.height / 5} outerRadius={this.props.height / 2.5}>
								{this.state.acessos1.map(acesso => <Cell key={acesso.fundo} fill={acesso.fundo} />)}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div style={{position: "relative", marginTop: "-6px", display: "inline-block", width: "31%", height: "100%"}}>
					<div style={{position: "absolute", lineHeight: `${this.props.height - 20}px`, width: "100%", textAlign: "center", fontSize: "16px", fontWeight: "600"}}>{this.intlFormat.format(this.state.total2)}</div>
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Tooltip />
							<Pie dataKey="value" isAnimationActive={false} data={this.state.acessos2} innerRadius={this.props.height / 5} outerRadius={this.props.height / 2.5}>
								{this.state.acessos2.map((acesso, index) => <Cell key={index} fill={acesso.name === "LIBERACAO" ? "#4CAF50" : this.colors[index]} />)}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div className="table-sumario">
					<DataTable emptyMessage="Nenhum registro encontrado" header="Total de Pessoas" value={this.state.sumario} children={this.columnsSumario} />
					{this.state._loading3 ? <div className="table-sumario-loading" /> : null}
				</div>
				<div className="grafico-toggle-period">
					<button onClick={this.toggleMensal}>Mensal</button>
					<button onClick={this.toggleAnual}>Anual</button>
				</div>
			</div>
		);
	}

	toggleMensal = () => {
		const dias = Math.abs(moment().add(-1, "months").diff(moment(), "days"));
		this.setState({dias});
		setTimeout(() => {
			this.updateData1();
			this.updateData2();
			this.updateData3();
		}, 50);
	}

	toggleAnual = () => {
		const dias = Math.abs(moment().add(-1, "years").diff(moment(), "days"));
		this.setState({dias});
		setTimeout(() => {
			this.updateData1();
			this.updateData2();
			this.updateData3();
		}, 50);
	}

}

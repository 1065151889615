import React from "react";
import "./commons.css";

export class ActionFloat extends React.Component {

    static Button = class extends React.Component {

        render() {
            return (
                <button className={`action-float-button ${this.props.mode || ""} ${this.props.className || ""}`} {...this.props}>
                    <i className={`fal ${this.props.icon}`} />
                </button>
            );
        }

    }

    render() {
        return (
            <div className={`action-float ${this.props.className || ""}`} {...this.props}>
                {this.props.children}
            </div>
        );
    }

}

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {VeiculoService} from "../components/servicos/VeiculoService";
import {EditarVeiculo} from "../components/Veiculo/EditarVeiculo";
import {MVeiculo} from "../utils/models/MVeiculo";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";
import {httpGet} from "../utils/Request";

export class SelectVeiculo extends React.Component {

	state = {
		fabricantes: {}
	};

	service = new VeiculoService();

	security = UserData.security("VEI");

	componentDidMount() {
		httpGet("/veiculos/fabricantes").then(fbs => {
			const fabricantes = {};
			for (const fb of fbs) {
				fabricantes[fb.id] = fb;
			}
			this.setState({fabricantes});
		});
	}

	onSelect = (event) => {
		if (event.value?.id === -1) {
			if (this.props.onChange) {
				this.props.onChange({name: this.props.name, value: ""});
			}
			const placa = event.value.placa.replace("Criar veículo ", "").toUpperCase();
			ModalRealm.showDialog(<EditarVeiculo {...this.props} key={Math.random()} veiculo={{...MVeiculo.Modelo(), placa}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}}/>);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) {
				this.props.onSelect({name: this.props.name, value: event.value});
			}
		}
	}

	render() {
		return <Select uppercase insertable={this.security.create && this.props.insertable} entidade="veículo" service={this.service} field="placa" itemTemplate={veiculoTemplate} query="placa" {...this.props} label={this.props.label || "Veículo"} onSelect={this.onSelect} />;
	}

}

export function veiculoTemplate(veiculo) {
	if (veiculo.id > 0) {
		const descricao = [
			<span className="ui-tzm-minchip placa">{veiculo.placa?.replace("Criar veículo ", "")}</span>
		];
		if (veiculo.proprietario?.id) {
			descricao.push(
				<span> {veiculo.proprietario.nome}</span>
			);
		} else {
			descricao.push(
				<span style={{color: "#BBBBBB"}}> Sem proprietário cadastrado</span>
			);
		}
		return <div>{descricao}</div>;
	} else {
		return (
			<div>
				<span className="ui-tzm-minchip placa">{veiculo.placa.replace("Criar veículo ", "").toUpperCase()}</span>
				<span> Criar novo veículo</span>
			</div>
		);
	}
}

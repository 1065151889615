import saveAs from "file-saver";
import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeCopy from "../../media/icones/copiar.png";
import iconeErro from "../../media/icones/eliminar.png";
import iconeOk from "../../media/icones/ok.png";
import iconeRetry from "../../media/icones/reutilizar.png";
import {GUI} from "../../utils/Constants";
import {LocalDateTime} from "../../utils/DateUtils";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {LogEmailService} from "../servicos/LogEmailService";
import {MailService} from "../servicos/MailService";
import {EditarComunicado} from "./EditarComunicado";
import {Fetch} from "../../utils/Fetch";

const basePath = process.env.REACT_APP_API_URL;

export class Comunicado extends React.Component {

	state = {
		activeIndex: 0,
		anexos: [],
		comunicados: [],
		inicio: LocalDateTime.atStartOfDayISO(),
		fim: LocalDateTime.atEndOfDayISO()
	};

	mailService = new MailService();
	logEmailService = new LogEmailService();
	security = UserData.security("COM", true);

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleChange = (event) => this.setState({[event.name]: event.value});

	editarComunicado = () => {
		ModalRealm.showDialog(<EditarComunicado key={Math.random()} />);
	}

	detailsEmails = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} style={{width: "2.5em"}} expander />,
		<Column key={1} header="Data" style={{width: "10em", textAlign: "center"}} body={(email) => moment(email.registro).format("DD/MM/YYYY HH:mm")} />,
		<Column key={2} header="Título" style={{width: "20em"}} body={(email) => <div style={{whiteSpace: "nowrap", overflow: "hidden"}}>{email.titulo}</div>} />,
		<Column key={3} header="Conteúdo" style={{width: "*"}} body={(email) => {
			const conteudo = email.conteudo ? email.conteudo.replace(/<[^>]*>/g, " ").replace(/\\s+/g, " ").trim() : "";
			return <div style={{whiteSpace: "nowrap", overflow: "hidden"}}>{conteudo.length > 50 ? `${conteudo.substring(0, 50)}...` : conteudo}</div>;
		}} />,
		<Column key={4} header="Anexos" style={{width: "*"}} body={(email) => {
			return (
				<div style={{whiteSpace: "nowrap", overflow: "hidden"}}>
					{email.anexos.map((a, index) => <a style={{textDecoration: "none"}} key={a} href={`#${a}`} onClick={() => this.downloadFile(a)}>{`${a.substring(a.indexOf("_") + 1)}${index + 1 < email.anexos.length ? "; " : ""}`}</a>)}
				</div>
			);
		}} />,
		<Column key={5} header="Ações" style={{textAlign: "center", width: "5em"}} body={(email) => {
			return (
				<div>
					<img title="Copiar Conteúdo" src={iconeCopy} alt="" className="ui-action-icon" onClick={() => this.copyEmail(email)} />
				</div>
			);
		}} />
	];

	downloadFile = (arquivo) => {
		Fetch.Get(`${basePath}/uploads/${arquivo}`).then((response) => response.blob()).then((blob) => {
			const file = new Blob([blob]);
			const data = window.URL.createObjectURL(file);
			saveAs(data, arquivo.substring(arquivo.indexOf("_") + 1));
		});
	}

	listarComunicados = () => {
		let params = [];
		let aParams = [];
		aParams.push("finalidade:COMUNICADO");
		if (this.state.titulo && this.state.titulo.length) aParams.push(`titulo~${this.state.titulo}`);
		if (this.state.conteudo && this.state.conteudo.length) aParams.push(`conteudo~${this.state.conteudo}`);
		if (Validator.isDate(this.state.inicio)) aParams.push(`registro>${this.state.inicio}`);
		if (Validator.isDate(this.state.fim)) aParams.push(`registro<${this.state.fim}`);
		params.push(`search=${aParams.join(";")}`);
		this.mailService.listar(params.join("&"), {size: 100, page: 0, sort: "registro desc"}).then((comunicados) => this.setState({comunicados}));
	}

	copyEmail = (email) => {
		const comunicado = Object.assign({}, email);
		ModalRealm.showDialog(<EditarComunicado comunicado={comunicado} key={Math.random()} />);
	}

	detailsLogs = [
		<Column style={{width: "4em", textAlign: "center"}} header="Status" body={(logEmail) => {
			switch (logEmail.status) {
				case "SUCESSO":
					return <img style={{verticalAlign: "middle"}} src={iconeOk} alt="" />;
				case "CANCELADO":
					return <img style={{verticalAlign: "middle"}} src={iconeRetry} alt="" />;
				default:
					return <img style={{verticalAlign: "middle"}} src={iconeErro} alt="" />;
			}
		}} />,
		<Column style={{width: "10em", textAlign: "center"}} header="Data" body={(logEmail) => moment(logEmail.data).format("DD/MM/YYYY HH:mm")} />,
		<Column style={{width: "15em"}} header="Remetente" body={(logEmail) => logEmail.contaEmail ? logEmail.contaEmail.usuario : null} />,
		<Column style={{width: "*"}} header="Destinatários" body={(logEmail) => logEmail.destinatarios ? logEmail.destinatarios.join(", ") : null} />,
		<Column style={{width: "*"}} header="Mensagem" field="erro" />,
		<Column key={5} header="Ações" style={{textAlign: "center", width: "5em"}} body={(logEmail) => {
			return (
				<div>
					{logEmail.status === "FALHA" ? <img title="Tentar Novamente" src={iconeRetry} alt="" className="ui-action-icon" onClick={() => this.retryLog(logEmail)} /> : null}
				</div>
			);
		}} />
	];

	retryLog = (logEmail) => {
		this.mailService.retry(logEmail);
	}

	expandedEmail = (email) => {
		return <DataTable emptyMessage={moment(email.registro).add(30, "seconds").isAfter() ? <span><i className="fa fa-spin fa-spinner" /> Envio em endamento</span> : "Nenhum registro de envio completado"} value={email.logs} children={this.detailsLogs} />;
	}

	rowToggle = (event) => {
		let expandidos = event.data;
		expandidos.forEach((email, index) => {
			if (!email.logs) {
				this.reloadLogs(email, index);
			}
		});
		this.setState({expandidos});
	}

	reloadLogs = (email, index, retry = 0) => {
		this.logEmailService.listar(`email=id:${email.id}`, {sort: "id desc", page: 0, size: 100}).then((logs) => {
			if (logs && logs.length) {
				let expandidos = this.state.expandidos;
				email.logs = logs;
				expandidos[index] = email;
				this.setState({expandidos});
			} else {
				if (++retry < 3) {
					setTimeout(() => this.reloadLogs(email, index, retry), 10000);
				}
			}
		});
	}

	render() {
		return (
			<div>
				<Panel header="Comunicados">
					<PanelContent>
						<DatePicker grid={2} label="Período" value={this.state.inicio} name="inicio" onChange={this.handleChange} />
						<DatePicker grid={2} label="&nbsp;" value={this.state.fim} name="fim" onChange={this.handleChange} />
						<TextField grid={4} label="Título" value={this.state.titulo} name="titulo" onChange={this.handleChange} />
						<TextField grid={4} label="Conteúdo" value={this.state.conteudo} name="conteudo" onChange={this.handleChange} />
					</PanelContent>
					<PanelFooter>
						<Action label="Procurar" icon="fa-search" onClick={this.listarComunicados} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable onRowToggle={this.rowToggle} rowExpansionTemplate={this.expandedEmail} expandedRows={this.state.expandidos} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator emptyMessage="Nenhum comunicado encontrado" value={this.state.comunicados} children={this.detailsEmails} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Comunicado" icon="fa-plus" onClick={this.editarComunicado} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarComunicados}
					onCtrlN={() => window.location.pathname === "/comunicados" && this.security.edit && this.editarComunicado()}
				/>
				<ModalRealm />
			</div>
		);
	}

}

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarPessoaJuridica} from "../components/PessoaJuridica/EditarPessoaJuridica";
import {PessoaJuridicaService} from "../components/servicos/PessoaJuridicaService";
import {MPessoaJuridica} from "../utils/models/MPessoaJuridica";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectPessoaJuridica extends React.Component {

	service = new PessoaJuridicaService();

	security = UserData.security("EMP");

	onSelect = (event) => {
		if (event.value?.id === -1) {
			if (this.props.onChange) {
				this.props.onChange({name: this.props.name, value: null});
			}
			ModalRealm.showDialog(<EditarPessoaJuridica key={Math.random()} pessoa={{...MPessoaJuridica.Modelo(), nome: event.value.nome.replace(`Criar empresa `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}}/>);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select entidade="empresa" insertable={this.security.create && this.props.insertable} service={this.service} field="nome" query="status:ATIVADO;nome,fantasia,cnpj" onSelect={this.onSelect} {...this.props} />;
	}

}

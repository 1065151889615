import React from "react";
import {Combobox} from "../components/commons/Combobox";
import {EquipamentoService} from "../components/servicos/EquipamentoService";

export class ComboboxEquipamentoFormulario extends React.Component {

	state = {
		equipamentos: []
	};

	equipamentoService = new EquipamentoService();

	componentDidMount() {
		this.equipamentoService.listarAlt("familia=FORMULARIO").then(equipamentos => {
			this.setState({equipamentos: equipamentos.map(e => ({label: e.descricao, value: e}))}, () => {
				const prevEquipamento = window.localStorage.getItem("X-Last-Selected-Form-Device");
				if (prevEquipamento) {
					const equipamento = equipamentos.filter(e => e.id === Number(prevEquipamento))[0];
					if (equipamento) {
						this.props.onChange({name: this.props.name || "equipamento", index: this.props.index, value: equipamento, automatic: true});
					}
				}
			});
		});
	}

	handleChange = e => {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
		if (e.value?.id) {
			window.localStorage.setItem("X-Last-Selected-Form-Device", e.value.id);
		}
	}

	render() {
		return <Combobox name="equipamento" label="Equipamento" options={this.state.equipamentos} {...this.props} onChange={this.handleChange}/>;
	}

}

import React from "react";
import {Label} from "./Label";

export class PatternPanel extends React.Component{

	constructor() {
		super();
		this.state = {
			height: 16,
			patterns: []
		};
	}

	componentDidMount() {
		this.setState({height: this.first.clientWidth});
	}

	changePattern = (value) => {
		if (this.props.onChange) this.props.onChange({name: this.props.name, value});
	}
	
	refFirst = (el) => this.first = el;

	render() {
		let patterns = [];
		for (let i = 1; i < 20; ++i) {
			patterns.push(<div key={i} onClick={() => this.changePattern(i)} className={`ui-colorpanel-item ui-patternpanel-item ui-pattern-${i} ${this.props.value === i ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#000000"}} />);
		}
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label>{this.props.label}</Label>
				<div className="ui-g">
					<div ref={this.refFirst} onClick={() => this.changePattern(0)} className={`ui-colorpanel-item ui-patternpanel-item ui-pattern-0 ${this.props.value === 0 ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#000000"}} />
					{patterns}
				</div>
			</div>
		);
	}

}

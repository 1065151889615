import React from "react";
import {Panel} from "primereact/components/panel/Panel";
import {PanelContent} from "../commons/PanelContent";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {GUI} from "../../utils/Constants";
import {MEquipamento} from "../../utils/models/MEquipamento";
import {ModalRealm} from "../commons/ModalRealm";

const labelOperacoes = {
    "BLOQUEADO": "de bloqueio",
    "EMERGENCIA": "de emergência",
    "NORMAL": "normal de operação"
}

export class PainelControleAcesso extends React.Component {

    state = {
        equipamentos: []
    };

    equipamentoService = new EquipamentoService();

    componentDidMount() {
        this.equipamentoService.listar("search=categoria:ACESSO", {page: 0, size: 1000, sort: "descricao"}).then(equipamentos => {
            equipamentos.forEach(e => e._operacao = "NORMAL");
            this.setState({equipamentos});
        });
    }

    alternarModoOperacao = (e, modo) => {
        if (!e && modo !== "NORMAL") {
            const message = `Tem certeza de que deseja colocar todos os equipamentos em modo ${labelOperacoes[modo]}?`;
            ModalRealm.showConfirmacao(message, () => {
                const {equipamentos} = this.state;
                equipamentos.forEach(e => e._operacao = modo);
                this.setState({equipamentos});
            });
        } else {
            const {equipamentos} = this.state;
            equipamentos.filter(eq => !e || eq.id === e.id).forEach(e => e._operacao = modo);
            this.setState({equipamentos});
        }
    }

    printAcoes = e => {
        const disableBloquear = !e || e._operacao === "BLOQUEADO" || this.state.equipamentos?.every(e => e._operacao === "BLOQUEADO");
        const disableEmergencia = e?._operacao === "EMERGENCIA" || this.state.equipamentos?.every(e => e._operacao === "EMERGENCIA");
        const disableNormal = e?._operacao === "NORMAL" || this.state.equipamentos?.every(e => e._operacao === "NORMAL");
        return (
            <div className="manutencoes-interromper-container">
                <div
                    onClick={() => !disableBloquear && this.alternarModoOperacao(e, "BLOQUEADO")}
                    title={`Bloquear ${e ? e.descricao : "todos os equipamentos"}`}
                    className={`manutencoes-interromper red ${disableBloquear ? "disabled" : ""}`}
                >
                    <img alt="" src={require("../../media/icones/pendente.png")}/>
                </div>
                <div
                    onClick={() => !disableEmergencia && this.alternarModoOperacao(e, "EMERGENCIA")}
                    title={`Ligar modo de emergência em ${e ? e.descricao : "todos os equipamentos"}`}
                    className={`manutencoes-interromper blue ${disableEmergencia ? "disabled" : ""}`}
                >
                    <img alt="" src={require("../../media/icones/treinamento.png")}/>
                </div>
                <div
                    onClick={() => !disableNormal && this.alternarModoOperacao(e, "NORMAL")}
                    title={`Restaurar modo de operação normal em ${e ? e.descricao : "todos os equipamentos"}`}
                    className={`manutencoes-interromper green ${disableNormal ? "disabled" : ""}`}
                >
                    <img alt="" src={require("../../media/icones/autorizacao.png")}/>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Panel header="Painel de Controle de Equipamentos de Acesso">
                <PanelContent>
                    <DataTable value={this.state.equipamentos} paginator rows={50} rowsPerPageOptions={GUI.rowsPerPage}>
                        <Column sortable header="Equipamento" field="descricao"/>
                        <Column sortable header="IP" style={{width: "10em"}} field="ip"/>
                        <Column sortable header="Nº de Série" style={{width: "12em"}} field="serial"/>
                        <Column sortable header="Família" style={{width: "12em"}} field="familia" body={e => MEquipamento.MapFamilias[e.familia]}/>
                        <Column sortable header="Controlador" field="controlador" body={e => e.controlador?.descricao}/>
                        <Column header={
                            <div>
                                <span style={{fontSize: "12px"}}>Ações</span>
                                {this.printAcoes()}
                            </div>
                        } style={{width: "10em"}} body={this.printAcoes}/>
                    </DataTable>
                </PanelContent>
                <ModalRealm/>
            </Panel>
        );
    }

}

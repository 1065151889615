import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeRemover from "../../media/icones/remover.png";
import {LocalTime} from "../../utils/DateUtils";
import {MModeloAcesso} from "../../utils/models/MModeloAcesso";
import {MPeriodo} from "../../utils/models/MPeriodo";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EditarPeriodo} from "../Periodo/EditarPeriodo";
import {AgendaService} from "../servicos/AgendaService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {LugarService} from "../servicos/LugarService";
import {ModeloAcessoService} from "../servicos/ModeloAcessoService";
import {PeriodoService} from "../servicos/PeriodoService";
import {ConfirmarModeloAcesso} from "./ConfirmarModeloAcesso";
import {SelectButton} from "../commons/SelectButton";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {
	labelLTipoDocumentoAplicacao,
	labelTipoDocumentoObrigatorio,
	MDocumentoTipo
} from "../../utils/models/MDocumento";
import {DocumentoTipoService} from "../servicos/DocumentoTipoService";
import {EditarDocumentoTipo} from "../Configuracao/EditarDocumentoTipo";
import {ComboboxModeloAcessoObrigatoriedade} from "./ComboboxModeloAcessoObrigatoriedade";
import {byKeyOrId} from "../../utils/FilterUtils";

const flags = [
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/modelos-acesso.png")}/> Horário Livre</div><span>Libera a passagem sem validar horários do modelo de acesso</span></div>, value: "HORARIO_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/tarifas.png")}/> Crédito Livre</div><span>Permite entrada em lugares tarifados sem cobrança de crédito</span></div>, value: "CREDITO_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/direcoes.png")}/> Sentido Livre</div><span>Libera os equipamentos (catracas) em ambos os sentidos</span></div>, value: "DIRECAO_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/lancar-saida.png")}/> Saída Livre</div><span>Ignora a validação de horário para a saída (útil para visitantes)</span></div>, value: "SAIDA_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/veiculos.png")}/> Garagem Livre</div><span>Permite entrada em garagem mesmo sem vaga liberada</span></div>, value: "GARAGEM_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/sequencia-livre.png")}/> Sequência Livre</div><span>Reduz a sequência de autenticação para identificação simples (1:N)</span></div>, value: "SEQUENCIA_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/avisos.png")}/> Alertar Carona</div><span>Emite um alerta quando alguém tenta passar carona</span></div>, value: "ANTIDUPLA_LOGICO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/bloqueios.png")}/> Impedir Carona</div><span>Impede a tentativa de carona fisicamente (Equipamento)</span></div>, value: "ANTIDUPLA_FISICO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/portaria.png")}/> Documentos</div><span>Bloqueia o acesso se algum documento da pessoa estiver expirado ou ausente</span></div>, value: "VALIDAR_DOCUMENTO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/mantenedor.png")}/> Intervalo Livre</div><span>Ignora bloqueios por intervalo de ausência mínima</span></div>, value: "INTERVALO_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/autorizacoes.png")}/> Autorização</div><span>Será utilizada na criação de agendas de autorização de acesso</span></div>, value: "AUTORIZACAO"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/capacete.png")}/> Liberar sem EPI</div><span>Permitir o acesso da pessoa mesmo sem os EPIs obrigatórios</span></div>, value: "EPI_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/temperatura-anormal.png")}/> Temperatura Alta</div><span>Permitir acesso da pessoa mesmo constatada temperatura alta</span></div>, value: "TEMPERATURA_LIVRE"},
	{label: <div className="agn-exp-cont"><div><img alt="" src={require("../../media/icones/integracao.png")}/> Integração</div><span>Modelo de acesso usado nas agendas de integração</span></div>, value: "INTEGRACAO"}
];

export class EditarModeloAcesso extends React.Component {

	state = {
		modeloAcesso: Object.assign(MModeloAcesso.Modelo(), this.props.modeloAcesso),
		visible: true,
		activeIndex: 0,
		lockSave: false,
		equipamentos: [],
		documentosObrigatorios: []
	};

	modeloAcessoService = new ModeloAcessoService();
	equipamentoService = new EquipamentoService();
	documentosObrigatoriosService = new DocumentoTipoService();
	agendaService = new AgendaService();
	lugarService = new LugarService();
	periodoService = new PeriodoService();
	security = UserData.security("MAC", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarModeloAcesso = () => {
		if (this.security.edit) {
			let modeloAcesso = MModeloAcesso.cleanupBeforeSave(Object.assign({}, this.state.modeloAcesso));
			let messages = MModeloAcesso.validarParaSalvar(modeloAcesso);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.agendaService.countByModeloAcesso(modeloAcesso.id || 0).then((contagem) => {
					if (contagem > 1) {
						ModalRealm.showDialog(<ConfirmarModeloAcesso count={contagem} onYes={() => {
							this.modeloAcessoService.salvar(modeloAcesso).then((modeloAcesso) => {
								if (this.props.onModalClose) {
									this.props.onModalClose(modeloAcesso);
								}
								this.onClose();
							});
						}}/>);
					} else {
						this.salvar(modeloAcesso);
					}
				});
			}
		}
	}

	salvar = (modeloAcesso, i = 0) => {
		if (modeloAcesso.periodos && modeloAcesso.periodos.length > i && !modeloAcesso.periodos[i].id) {
			const periodo = modeloAcesso.periodos[i];
			const key = Math.random();
			periodo._key = key;
			console.log(this.state.modeloAcesso)
			this.periodoService.salvar(periodo).then((periodo) => {
				modeloAcesso.periodos = modeloAcesso.periodos.filter(p => p._key !== key);
				modeloAcesso.periodos.push(periodo);
				this.salvar(modeloAcesso, ++i);
			});
		} else {
			this.setState({lockSave: true});
			console.log(this.state.modeloAcesso)
			this.modeloAcessoService.salvar(modeloAcesso).then((modeloAcesso) => {
				this.setState({lockSave: false});
				if (this.props.onModalClose) {
					this.props.onModalClose(modeloAcesso);
				}
				this.onClose();
			}).catch(() => this.setState({lockSave: false}));
		}
	}

	handleChange = (event) => {
		let modeloAcesso = this.state.modeloAcesso;
		modeloAcesso[event.name] = event.value;
		this.setState({modeloAcesso});

	}

	listarLugares = (event) => {
		this.lugarService.listar(`search=status:ATIVADO;descricao~${event.query}`, {page: 0, size: 10}).then((lugares) => this.setState({lugares}));
	}

	listarEquipamentos = (event) => {
		this.equipamentoService.listar(`search=status:ATIVADO;descricao,ip~${event.query};id!${this.state.modeloAcesso.equipamentos.map(e => e.equipamento.id).join(",")}`, {page: 0, size: 10}).then(equipamentos => this.setState({equipamentos}));
	}

	listarDocumentosObrigatorios = (event) => {
		const ids = this.state.modeloAcesso.documentosObrigatorios.map(e => e.id).join(",");
		this.documentosObrigatoriosService.listar(`search=descricao~${event.query};id!${ids}`, {page: 0, size: 10}).then(documentosObrigatorios => this.setState({documentosObrigatorios}));
	}

	printDaysOfWeek(list) {
		return list != null ? <div style={{textAlign: "center"}}>{list.map((dow) => {
			switch (dow) {
			case "SUNDAY": return "Dom";
			case "MONDAY": return "Seg";
			case "TUESDAY": return "Ter";
			case "WEDNESDAY": return "Qua";
			case "THURSDAY": return "Qui";
			case "FRIDAY": return "Sex";
			case "SATURDAY": return "Sáb";
			default: return null;
			}
		}).join(" - ")}</div> : null;
	}

	editarPeriodo = (periodo) => {
		if (periodo == null) periodo = MPeriodo.Modelo();
		periodo = Object.assign({}, periodo);
		ModalRealm.showDialog(<EditarPeriodo key={Math.random()} periodo={periodo} onSave={(periodo) => {
			let modeloAcesso = this.state.modeloAcesso;
			modeloAcesso.periodos = modeloAcesso.periodos.filter((p) => p.id !== periodo.id);
			modeloAcesso.periodos.push(periodo);
			this.setState({modeloAcesso});
		}} />);
	}

	excluirPeriodo = (rowData) => {
		let modeloAcesso = this.state.modeloAcesso;
		modeloAcesso.periodos = modeloAcesso.periodos.filter(p => p.id !== rowData.id);
		this.setState({modeloAcesso});
	}

	periodoColumns = [
		<Column style={{width: "*"}} key="diasSemana" field="diasSemana" header="Período" body={(rowData) => <div style={{whiteSpace: "nowrap"}}>{this.periodoService.simplificarDiasSemana(rowData) + ", das " + LocalTime.withoutSeconds(rowData.inicio) + " às " + LocalTime.withoutSeconds(rowData.fim)}</div>} />,
		<Column style={{width: "6em"}} key="feriados" field="feriados" header="Feriados" body={(rowData) => <div style={{textAlign: "center"}}>{rowData.feriados ? "Sim": "Não"}</div>} />,
		<Column style={{width: "6em"}} key="acoes" field="acoes" header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					<img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Período" onClick={(event) => this.editarPeriodo(rowData)} />
					<img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Período" onClick={(event) => this.excluirPeriodo(rowData)} />
				</div>
			);
		}}/>
	];

	periodoFooter = (
		<div style={{textAlign: "left"}}>
			<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarPeriodo(null)} />
		</div>
	);

	tabChange = (event) => this.setState({activeIndex: event.index});

	detailsDestinosHeader = (
		<ColumnGroup>
			<Row>
				<Column style={{width: "*"}} header="Lugar" rowSpan={2} />
				<Column style={{width: "14em"}} header="Permanência" colSpan={2} />
				<Column style={{width: "7em"}} header="Ausência" />
				<Column style={{width: "5em"}} header="Ações" rowSpan={2} />
			</Row>
			<Row>
				<Column style={{width: "7em"}} header="Mínima" />
				<Column style={{width: "7em"}} header="Máxima" />
				<Column style={{width: "7em"}} header="Mínima" />
			</Row>
		</ColumnGroup>
	);

	detailsDestinos = [
		<Column key={0} field="lugar.descricao" />,
		<Column key={1} body={(d) => <TextField disabled unit="min" name="permanenciaMinima" value={d.permanenciaMinima} onChange={(e) => this.handleChangeDestino(d, e)} />} />,
		<Column key={2} body={(d) => <TextField unit="min" name="permanenciaMaxima" value={d.permanenciaMaxima} onChange={(e) => this.handleChangeDestino(d, e)} />} />,
		<Column key={3} body={(d) => <TextField unit="min" name="ausenciaMinima" value={d.ausenciaMinima} onChange={(e) => this.handleChangeDestino(d, e)} />} />,
		<Column key={4} style={{textAlign: "center"}} body={(d) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Lugar" onClick={() => this.removerDestino(d)} /> : null}
				</div>
			);
		}} />
	];

	removerDestino = (destino) => {
		const modeloAcesso = this.state.modeloAcesso;
		modeloAcesso.lugares = modeloAcesso.lugares.filter(d => d.lugar.id !== destino.lugar.id);
		this.setState({modeloAcesso});
	}

	handleDestino = (event) => {
		if (Validator.isEntidade(event.value)) {
			const modeloAcesso = this.state.modeloAcesso;
			modeloAcesso.lugares = modeloAcesso.lugares.filter(d => d.lugar.id !== event.value.id);
			modeloAcesso.lugares.push({...MModeloAcesso.Destino(), lugar: event.value});
			modeloAcesso._lugar = null;
			this.setState({modeloAcesso});
		}
	}

	handleEquipamento = (event) => {
		if (Validator.isEntidade(event.value)) {
			const {modeloAcesso} = this.state;
			modeloAcesso.equipamentos = modeloAcesso.equipamentos.filter(e => e.equipamento.id !== event.value.id);
			modeloAcesso.equipamentos.push({...MModeloAcesso.Equipamento(), equipamento: event.value});
			modeloAcesso._equipamento = null;
			this.setState({modeloAcesso});
		}
	}

	handleDocumentosObrigatorios  = (event) => {
		if (Validator.isEntidade(event.value)) {
			const {modeloAcesso} = this.state;
			modeloAcesso.documentosObrigatorios.push({
				...MModeloAcesso.DocumentoObrigatorio(),
				tipo: event.value
			});
			modeloAcesso._tipoDocumento = null;
			this.setState({modeloAcesso});
		}
	}

	handleChangeDestino = (destino, event) => {
		destino[event.name] = event.value;
		this.forceUpdate();
	}

	equipamentosColumns = [
		<Column style={{width: "20em"}} key={0} field="equipamento.descricao" header="Descrição"/>,
		<Column style={{width: "10em"}} key={1} field="equipamento.ip" header="IP"/>,
		<Column key={2} header="Destinos" body={e => e.equipamento.dispositivos.map(d => d.destino ? d.destino.descricao : "Fora").join(", ")}/>,
		<Column style={{width: "4em", textAlign: "center"}} key={3} header="Ações" body={e => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Equipamento" onClick={() => this.removerEquipamento(e.equipamento)} /> : null}
				</div>
			);
		}}/>,
	];

	removerEquipamento = (equipamento) => {
		const {modeloAcesso} = this.state;
		modeloAcesso.equipamentos = modeloAcesso.equipamentos.filter(e => e.equipamento.id !== equipamento.id);
		this.setState({modeloAcesso});
	}

	handleChangeFlags = (event) => {
		const {modeloAcesso} = this.state;
		modeloAcesso.flags = event.value.filter(e => e);
		this.setState({modeloAcesso});
	}

	inserirDocumentoTipo = () => this.editarDocumentoTipo(MDocumentoTipo.Modelo());

	editarDocumentoTipo = documentoObrigatorio => {
		ModalRealm.showDialog(<EditarDocumentoTipo documentoTipo={documentoObrigatorio.tipo} onModalClose={tipo => {
			const {modeloAcesso} = this.state;
			const index = modeloAcesso.documentosObrigatorios.findIndex(mado => mado.tipo.id === tipo.id);
			if (index > -1) {
				modeloAcesso.documentosObrigatorios[index].tipo = tipo;
			} else {
				modeloAcesso.documentosObrigatorios.push({
					...MModeloAcesso.DocumentoObrigatorio(),
					tipo
				});
			}
			this.setState({modeloAcesso});
		}}/>);
	}

	desvincularDocumentoTipo = documentoObrigatorio => {
		const {modeloAcesso} = this.state;
		modeloAcesso.documentosObrigatorios = modeloAcesso.documentosObrigatorios.filter(mado => byKeyOrId(mado, documentoObrigatorio));
		this.setState({modeloAcesso});
	}

	handleChangeDocumentosObrigatorios = event => {
		const {modeloAcesso} = this.state;
		modeloAcesso.documentosObrigatorios[event.index][event.name] = event.value;
		this.setState({modeloAcesso});
	}

	render() {
		const flagsLength = flags.length;
		const flagsHalf   = Math.ceil(flagsLength / 2);
		return (
			<Dialog modal header="Modelo de Acesso" visible={this.state.visible} style={{width: "1100px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField grid={12} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.modeloAcesso.descricao} />
								<SelectButton multiple grid={12} name="flags" value={this.state.modeloAcesso.flags} onChange={this.handleChangeFlags} label="Opções" options={flags.slice(0, flagsHalf)}/>
								<SelectButton style={{marginTop: "-8px"}} multiple grid={12} name="flags" value={this.state.modeloAcesso.flags} onChange={this.handleChangeFlags} options={flags.slice(flagsHalf, flagsLength)}/>
								<SuggestionBox appendTo={document.body} field="descricao" completeMethod={this.listarLugares} suggestions={this.state.lugares} onChange={this.handleChange} label="Lugares" grid={12} onSelect={this.handleDestino} name="_lugar" value={this.state.modeloAcesso._lugar} />
								<div className="ui-g-12">
									<DataTable headerColumnGroup={this.detailsDestinosHeader} value={this.state.modeloAcesso.lugares} children={this.detailsDestinos} emptyMessage="Nenhum lugar adicionado" />
								</div>
								{UserData.hasRole("XX0C") ? <TextField grid={12} label="Chave de Integração" name="chaveIntegracao" onChange={this.handleChange} value={this.state.modeloAcesso.chaveIntegracao} /> : null}
							</PanelContent>
						</TabPanel>
						<TabPanel header="Horários">
							<div className="ui-g">
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhum período adicionado" value={this.state.modeloAcesso.periodos} children={this.periodoColumns} rows={10} paginator paginatorLeft={this.periodoFooter} />
								</div>
							</div>
						</TabPanel>
						<TabPanel header="Equipamentos Adicionais">
							<div className="ui-g">
								<SuggestionBox label="Equipamento" grid={12} appendTo={document.body} field="descricao" completeMethod={this.listarEquipamentos} suggestions={this.state.equipamentos} onChange={this.handleChange} onSelect={this.handleEquipamento} name="_equipamento" value={this.state.modeloAcesso._equipamento}/>
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhum equipamento adicionado" value={this.state.modeloAcesso.equipamentos} children={this.equipamentosColumns} rows={10} paginator />
								</div>
							</div>
						</TabPanel>
						<TabPanel header="Documentos Obrigatórios" disabled={!this.state.modeloAcesso.flags.includes("VALIDAR_DOCUMENTO")}>
							<div className="ui-g">
								<SuggestionBox label="Documento Obrigatório" grid={12} appendTo={document.body} field="descricao" completeMethod={this.listarDocumentosObrigatorios} suggestions={this.state.documentosObrigatorios} onChange={this.handleChange} onSelect={this.handleDocumentosObrigatorios} name="_tipoDocumento" value={this.state.modeloAcesso._tipoDocumento}/>
								<div className="ui-g-12">
									<DataTable paginator rows={100} paginatorLeft={<Button className="ui-tzm-pagleft-icon" icon="fal fa-plus" onClick={() => this.inserirDocumentoTipo()}/>} emptyMessage="Nenhum registro encontrado" value={this.state.modeloAcesso.documentosObrigatorios}>
										<Column style={{width: "12em"}} header="Descrição" field="tipo.descricao"/>
										<Column style={{width: "12em"}} header="Aplicação" field="aplicacao" body={dt => labelLTipoDocumentoAplicacao[dt.tipo.aplicacao]}/>
										<Column style={{width: "12em"}} header="Obrigatoriedade" body={(dt, c) => <ComboboxModeloAcessoObrigatoriedade index={c.rowIndex} value={dt.obrigatoriedade} onChange={this.handleChangeDocumentosObrigatorios}/>}/>
										<Column header="Campos Obrigatórios" field="obrigatorios" body={dt => dt.tipo.obrigatorios.map(o => labelTipoDocumentoObrigatorio[o]).join(", ")}/>
										<Column header="Ações" style={{width: "5em"}} body={dt => (
											<div style={{textAlign: "center"}}>
												{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Tipo de Documento" onClick={() => this.editarDocumentoTipo(dt)} /> : null}
												{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Tipo de Documento" onClick={() => this.desvincularDocumentoTipo(dt)} /> : null}
											</div>
										)}/>
									</DataTable>
								</div>
							</div>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.modeloAcesso.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/modelos-acesso", this.state.modeloAcesso)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.modeloAcesso.id)) || this.state.lockSave || (UserData.usuario.login !== "admin" && this.state.modeloAcesso.descricao === "Acesso Geral")} onClick={this.salvarModeloAcesso} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarModeloAcesso} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

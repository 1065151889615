import {httpGet} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class UsuarioService extends EntidadeNextGenService {

	constructor() {
		super("/usuarios");
	}

	me() {
		return httpGet("/me");
	}

	logout() {
		httpGet("/logout").then(() => {
			window.location.replace("/login");
		});
	}

}

import React, {Component} from "react";
import {Combobox} from "../commons/Combobox";
import {Button} from "primereact/components/button/Button";
import {WebCamera} from "../commons/Webcam";
import {ImageUtils} from "../../utils/ImageUtils";
import {optionsFinalidade} from "../../utils/models/MFoto";

export class FotoWebcam extends Component {

	state = {
		flip: false,
		rotation: 0,
		cameras: [],
		camera: null,
		webcamKey: null,
		className: "",
		imagem: null,
		finalidade: window.localStorage.getItem("X-Foto-Finalidade") || "IDENTIFICACAO",
		frameWidth: ((Math.max(this.props.width, this.props.height) - Math.min(this.props.width, this.props.height)) / 2) + 10,
		frameHeight: Math.max(this.props.width, this.props.height) + 20
	};

	componentDidMount() {
		const mediaDevices = navigator.mediaDevices;
		if (mediaDevices) {
			mediaDevices.enumerateDevices().then(webcams => {
				const cameras = webcams.filter(w => w.kind === "videoinput").map(w => ({label: w.label, value: {deviceId: w.deviceId}}));
				let camera = null;
				if (cameras?.length) {
					const lastCamera = window.localStorage.getItem("X-Foto-WebcamID");
					if (lastCamera?.length) {
						const index = cameras.findIndex(c => c.value.deviceId === lastCamera);
						if (index > -1) {
							camera = cameras[index].value;
						}
					} else {
						camera = cameras[0].value;
					}
				}
				this.setState({cameras, camera}, this.updateWebcam);
			});
		} else {
			window.alert("Não foi possível obter a lista de webcams. O seu navegador pode estar bloqueando este recurso.");
		}
	}

	componentWillUnmount() {
		this.stopWebcam();
	}

	stopWebcam = () => {
		if (this.stream) {
			this.stream.getTracks().forEach(track => track.stop());
		}
	}

	refVideo = el => this.video = el;

	handleChange = event => {
		this.setState({[event.name]: event.value}, () => {
			switch (event.name) {
				case "camera":
					window.localStorage.setItem("X-Foto-WebcamID", event.value.deviceId);
					this.updateWebcam();
					break;
				case "finalidade":
					window.localStorage.setItem("X-Foto-Finalidade", event.value);
					break;
				default:
					break;
			}
		});
	}

	updateWebcam = () => {
		this.stopWebcam();
		if (this.state.camera && this.video) {
			const {deviceId} = this.state.camera;
			navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId}}).then(stream => {
				this.video.setStream(stream);
				this.stream = stream;
			});
		}
	}

	tirarFotografia = () => {
		const imagem = this.video.takeScreenshot();
		this.stopWebcam();
		ImageUtils.rotateBase64Image(imagem, this.props.width, this.props.height, this.state.rotation, this.state.flip, imagem => {
			this.setState({imagem}, () => {
				if (this.props.onPhotoTaken) {
					this.props.onPhotoTaken({
						finalidade: this.state.finalidade,
						conteudo: imagem.split(",")[1]
					});
				}
			});
		});
	}

	render() {
		const styles = {
			border: this.state.imagem?.length ? "3px solid #4CAF50" : "3px solid #F44336",
			width: `${this.props.width}px`,
			height: `${this.props.height}px`
		};
		const view = this.state.imagem?.length ? (
			<img className="ui-webcam-camtotem" src={this.state.imagem} alt="" style={styles}/>
		) : (
			<WebCamera key={this.state.webcamKey} ref={this.refVideo} className={`ui-webcam-camtotem${this.state.className}${this.state.flip ? "-flip" : ""}`} style={styles}/>
		);
		return (
			<div className="ui-g">
				<div className="ui-g-12 ui-g ui-g-nopad" style={{textAlign: "left"}}>
					<Combobox grid={8} label="Câmera" disabled={this.state.imagem?.length} appendTo={document.body} value={this.state.camera} options={this.state.cameras} onChange={this.handleChange} name="camera"/>
					<Combobox grid={4} label="Finalidade" disabled={this.state.imagem?.length} appendTo={document.body} value={this.state.finalidade} options={optionsFinalidade} onChange={this.handleChange} name="finalidade"/>
				</div>
				<div className="ui-g-12" style={{paddingTop: `${this.state.frameWidth}px`, height: `${this.state.frameHeight}px`}}>
					<div className="ui-tzm-webcam-container-image" onClick={() => !this.state.imagem?.length && this.tirarFotografia()}>
						<div className={`foto-camera-frame ${this.state.finalidade.toLowerCase()}`}>
							{view}
						</div>
						{
							!["BIOMETRIA", "IDENTIFICACAO"].includes(this.state.finalidade) || this.state.rotation ? null : (
								<div className="facial-hints">
									<ul>
										<li>Procure enquadrar o rosto da pessoa na moldura</li>
										<li>Peça para que ela retire os óculos e/ou máscara</li>
										<li>Peça para que a pessoa mantenha uma expressão séria</li>
										<li>Clique sobre a imagem quando estiver pronto(a)</li>
									</ul>
								</div>
							)
						}
					</div>
				</div>
				<div className="ui-g-12" style={{textAlign: "center"}}>
					<Button disabled={!this.state.imagem?.length} title="Tentar novamente" onClick={() => this.setState({imagem: null}, this.updateWebcam)} label=" " icon="fa-plus"/>
					<Button disabled={this.state.imagem?.length} title="Girar para a esquerda" onClick={() => this.setState({rotation: (this.state.rotation + 270) % 360, className: ` ui-tzm-webcam-${(this.state.rotation + 270) % 360}`})} label=" " icon="fa-undo"/>
					<Button disabled={this.state.imagem?.length} title="Inverter horizontalmente" onClick={() => this.setState({flip: !this.state.flip})} label=" " icon="fa-arrows-alt-h"/>
					<Button disabled={this.state.imagem?.length} title="Girar para a direita" onClick={() => this.setState({rotation: (this.state.rotation + 90) % 360, className: ` ui-tzm-webcam-${(this.state.rotation + 90) % 360}`})} label=" " icon="fa-redo"/>
				</div>
			</div>
		);
	}

}

import React from "react";
import "./Utils.css";

export class FailedToFetch extends React.Component {

	state = {
		visible: true
	};

	UNSAFE_componentWillMount() {
		setTimeout(() => {
			this.setState({visible: false});
		}, 5000);
	}

	render() {
		return (
			<div className={`tzm-failed-fetch${this.state.visible ? "" : " hide"}`} {...this.props}>
				<i className="fa fa-times-circle" style={{fontSize: "20px", marginRight: ".5em"}}/>
				<span>Falha na comunicação com o servidor. Por favor, tente novamente.</span>
			</div>
		);
	}

}

import React from "react";
import "./commons.css";
import {Label} from "./Label";

const icons = [
    "",
    "home",
    "coffee",
    "stethoscope",
    "car",
    "paw",
    "utensils",
    "dolly",
    "laptop",
    "seedling",
    "dollar-sign",
    "book",
    "wrench",
    "exclamation-triangle",
    "wheelchair",
    "handshake",
    "balance-scale",
    "recycle",
    "thumbs-up",
    "star",
    "key",
    "trophy",
    "shopping-cart",
    "bullhorn",
    "tint",
    "briefcase",
    "bolt",
    "phone-rotary",
    "heart",
    "dumbbell",
    "camera-retro",
    "motorcycle",
    "envelope",
    "truck",
    "construction",
    "paint-roller",
    "tshirt",
    "trumpet",
    "tooth",
    "tire",
    "traffic-cone",
    "user-hard-hat",
    "fire-extinguisher",
    "plane",
    "glass-cheers",
    "car-garage",
    "eye",
    "concierge-bell",
    "car-mechanic",
    "bomb",
    "user-tie",
    "badge-sheriff",
    "vacuum",
    "user-secret",
    "hammer",
    "hat-chef",
    "fingerprint",
    "car-crash",
    "baby-carriage",
    "walker",
    "university",
    "theater-masks",
    "swimming-pool",
    "hat-santa",
    "sack-dollar",
    "trees",
    "joystick",
    "hands-heart"
];

export class IconPanel extends React.Component {

    render() {
        return (
            <div className={`ui-g-${this.props.grid}`}>
                <Label>{this.props.label}</Label>
                <div className="ui-iconpanel">
                    {icons.map(this.printIconButton)}
                </div>
            </div>
        );
    }

    onClick = (value) => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value});
        }
    }

    printIconButton = icn => {
        return <i onClick={() => this.onClick(icn)} className={`ui-iconpanel-item fad fa-${icn} ${this.props.value === icn ? "selected" : ""}`}/>
    }

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeParar from "../../media/icones/ctrl_parar.png";
import iconeIniciar from "../../media/icones/ctrl_iniciar.png";
import iconeReiniciar from "../../media/icones/ctrl_reiniciar.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {ControladorService} from "../servicos/ControladorService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarControlador} from "./EditarControlador";
import {httpGet} from "../../utils/Request";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import moment from "moment";
import {SafetyWebSocket} from "../../utils/WebSocket";

export class Controlador extends React.Component {

	state = {
		activeIndex: 0,
		descricao: "",
		firstResult: 0,
		controladores: [],
		threads: {},
		logsControlador: []
	};

	controladorService = new ControladorService();
	security = UserData.security("CTR", true);

	componentDidMount() {
		this.listarControllers();
	}

	listarControllers = () => {
		httpGet("/watchdog").then(t => {
			const threads = {};
			for (const thread of t) {
				threads[thread.fileName] = thread;
			}
			this.setState({threads});
		});
	}

	listarControladores = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.controladorService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then(controladores => this.setState({controladores, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarControlador = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.controladorService.buscar(rowData).then((controlador) => {
				ModalRealm.showDialog(<EditarControlador key={Math.random()} controlador={controlador} onModalClose={this.listarControladores}/>);
			});
		} else {
			ModalRealm.showDialog(<EditarControlador key={Math.random()} controlador={null} onModalClose={this.listarControladores}/>);
		}
	}

	excluirControlador = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este controlador?", () => this.controladorService.excluir(rowData).then(this.listarControladores));
	}

	restaurarControlador = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este controlador?", () => {
			if (rowData != null && rowData.id != null) {
				this.controladorService.buscar(rowData).then((controlador) => {
					controlador.status = "ATIVADO";
					this.controladorService.salvar(controlador).then(this.listarControladores);
				});
			}
		});
	}

	eliminarControlador = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este controlador?", () => this.controladorService.eliminar(rowData).then((result) => {
			if (result) this.listarControladores(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este controlador: há outros registros dependentes dele no sistema."]);
		}));
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/controladores", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	acaoControlador = async (fileName, acao) => {
		if (fileName == null) {
			if (acao === "iniciar") {
				const threads = Object.keys(this.state.threads);
				for (const thread of threads) {
					await httpGet(`/watchdog/${thread}/${acao}`);
				}
				this.listarControllers();
			} else {
				ModalRealm.showConfirmacao(`Tem certeza de que deseja ${acao} todos os controladores?`, async () => {
					const threads = Object.keys(this.state.threads);
					for (const thread of threads) {
						await httpGet(`/watchdog/${thread}/${acao}`);
					}
					this.listarControllers();
				});
			}
		} else {
			if (acao === "iniciar") {
				httpGet(`/watchdog/${fileName}/${acao}`).then(this.listarControllers);
			} else {
				ModalRealm.showConfirmacao(`Tem certeza de que deseja ${acao} este controlador?`, () => {
					httpGet(`/watchdog/${fileName}/${acao}`).then(this.listarControllers);
				});
			}
		}
	}

	recarregarControladores = () => {
		ModalRealm.showConfirmacao("Este processo reinicia todos os controladores ativos atualmente. Tem certeza de que deseja recarregar a lista de controladores?", () => {
			httpGet("/watchdog/recarregar").then(this.listarControllers);
		});
	}

	tabChange = event => {
		this.setState({activeIndex: event.index}, () => {
			switch (event.index) {
				case 1:
					httpGet("/logs-controlador?page=0&size=100&sort=registro+desc").then(logsControlador => this.setState({logsControlador}));
					break;
				default:
					break;
			}
		});
	}

	atualizarControlador = controllerStatus => {
		const {threads} = this.state;
		threads[controllerStatus.fileName] = controllerStatus;
		this.setState({threads});
	}

	render() {
		const anyPaused = Object.keys(this.state.threads).some(t => this.state.threads[t].paused);
		return (
			<div>
				<Panel header="Controladores &amp; Aplicações">
					<PanelContent>
						<TabView activeIndex={this.state.activeIndex} onTabChange={this.tabChange}>
							<TabPanel header="Dados Principais">
								<Panel header="Filtros de Pesquisa">
									<PanelContent>
										<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate}/>
									</PanelContent>
									<PanelFooter>
										{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate}/> : null}
										{UserData.usuario.login === "admin" ? <Action mode="delete" label="Reiniciar Tudo" icon="fa-exclamation-triangle" onClick={this.recarregarControladores}/> : null}
										<Action label="Procurar" icon="fa-search" onClick={this.listarControladores}/>
									</PanelFooter>
								</Panel>
								<div className="ui-tzm-panel-separator"/>
								<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.controladores} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator>
									<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id"/>
									<Column header="Descrição" style={{width: "*"}} sortable field="descricao"/>
									<Column header="Nome do Arquivo" style={{width: "*"}} body={c => (
										<div>
											<div>{c.fileName}</div>
											<div>{c.fileNameStandalone}</div>
										</div>
									)}/>
									<Column header="Endereço" style={{width: "20em"}} body={c => (
										<div>
											<div>{c.endereco}</div>
											<div>{c.standalone}</div>
										</div>
									)}/>
									<Column header={(
										!this.state.controladores?.length ? null : (
											<div>
												<img alt="" className="ui-action-icon" src={anyPaused ? iconeIniciar : iconeParar} title={anyPaused ? "Iniciar" : "Parar"} onClick={() => this.acaoControlador(null,  anyPaused ? "iniciar" : "parar")}/>
												<img alt="" style={anyPaused ? {filter: "grayscale(1)"} : {}} className="ui-action-icon" src={iconeReiniciar} title="Reiniciar" onClick={() => !anyPaused && this.acaoControlador(null, "reiniciar")}/>
											</div>
										)
									)} style={{width: "5em", textAlign: "center"}} body={c => (
										!this.state.threads[c.fileName] ? null : (
											<>
												{this.state.threads[c.fileName].paused ? <img alt="" className="ui-action-icon" src={iconeIniciar} title="Iniciar" onClick={() => this.acaoControlador(c.fileName, "iniciar")}/> : null}
												{this.state.threads[c.fileName].paused ? null : <img alt="" className="ui-action-icon" src={iconeParar} title="Parar" onClick={() => this.acaoControlador(c.fileName, "parar")}/>}
												<img alt="" style={this.state.threads[c.fileName].paused ? {filter: "grayscale(1)"} : {}} className="ui-action-icon" src={iconeReiniciar} title="Reiniciar" onClick={() => !this.state.threads[c.fileName].paused && this.acaoControlador(c.fileName, "reiniciar")}/>
												{
													!(c.fileNameStandalone?.length && this.state.threads[c.fileNameStandalone]) ? null : (
														<>
															<div style={{margin: "3px 0", borderBottom: "1px solid rgba(0, 0, 0, .1)"}}/>
															{this.state.threads[c.fileNameStandalone].paused ? <img alt="" className="ui-action-icon" src={iconeIniciar} title="Iniciar Standalone" onClick={() => this.acaoControlador(c.fileNameStandalone, "iniciar")}/> : null}
															{this.state.threads[c.fileNameStandalone].paused ? null : <img alt="" className="ui-action-icon" src={iconeParar} title="Parar Standalone" onClick={() => this.acaoControlador(c.fileNameStandalone, "parar")}/>}
															<img alt="" style={this.state.threads[c.fileNameStandalone].paused ? {filter: "grayscale(1)"} : {}} className="ui-action-icon" src={iconeReiniciar} title="Reiniciar Standalone" onClick={() => !this.state.threads[c.fileNameStandalone].paused && this.acaoControlador(c.fileNameStandalone, "reiniciar")}/>
														</>
													)
												}
											</>
										)
									)}/>
									<Column header="Ações" style={{width: "5em"}} body={c => (
										<div style={{textAlign: "center"}}>
											{this.security.edit && c.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Controlador" onClick={() => this.editarControlador(c)}/> : null}
											{this.security.remove && c.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Controlador" onClick={() => this.excluirControlador(c)}/> : null}
											{this.security.remove && c.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(c)}/> : null}
											{this.security.remove && c.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Controlador" onClick={() => this.restaurarControlador(c)}/> : null}
											{this.security.remove && c.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Controlador" onClick={() => this.eliminarControlador(c)}/> : null}
										</div>
									)}/>
								</DataTable>
							</TabPanel>
							<TabPanel header="Registros de Manutenção">
								<DataTable paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} emptyMessage="Nenhum registro encontrado" value={this.state.logsControlador}>
									<Column style={{width: "10em"}} header="Ação" body={lc => MapSinais[lc.sinal]}/>
									<Column style={{textAlign: "center", width: "14em"}} header="Data" body={lc => moment(lc.registro).format("DD/MM/YYYY HH:mm:ss")}/>
									<Column header="Controlador/Aplicação" field="fileName"/>
									<Column header="Responsável" field="login"/>
								</DataTable>
							</TabPanel>
						</TabView>
					</PanelContent>
				</Panel>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Controlador" icon="fa-plus" onClick={this.editarControlador}/> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarControladores}
					onCtrlN={() => window.location.pathname === "/controladores" && this.security.create && this.editarControlador()}
					onCtrlE={() => window.location.pathname === "/controladores" && this.state.controladores.length === 1 && this.security.edit && this.editarControlador(this.state.controladores[0])}
				/>
				<SafetyWebSocket path="/controladores/status" onMessage={this.atualizarControlador}/>
				<ModalRealm/>
			</div>
		);
	}

}

export const MapSinais = {
	START: <div><img className="ctrl-acao" alt="" src={iconeIniciar}/>Iniciar</div>,
	STOP: <div><img className="ctrl-acao" alt="" src={iconeParar}/>Parar</div>,
	RESTART: <div><img className="ctrl-acao" alt="" src={iconeReiniciar}/>Reiniciar</div>
};

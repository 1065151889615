import {Checkbox} from "primereact/components/checkbox/Checkbox";
import React from "react";

export class CheckSquare extends React.Component {

	onClick = () => {
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, value: !this.props.checked, index: this.props.index});
		}
	}

	render() {
		return (
			<div className={`ui-g-${this.props.grid}`} style={this.props.style}>
				<Checkbox disabled={this.props.disabled} checked={this.props.checked} onChange={this.onClick} />{this.props.label != null ? <span onClick={this.onClick} className="ui-tzm-checksquare-text">{this.props.label}</span> : null}
			</div>
		);
	}

}

import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeRemover from "../../media/icones/remover.png";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MAviso} from "../../utils/models/MAviso";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {MReserva} from "../../utils/models/MReserva";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {AvisoService} from "../servicos/AvisoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {ReservaService} from "../servicos/ReservaService";

export class EditarReserva extends React.Component {

	state = {
		visible: true,
		reserva: Object.assign(MReserva.Modelo(), this.props.reserva),
		lockPessoa: false,
		tabIndex: 0,
		lockSave: false
	};

	security = UserData.security("RES");
	securityEspaco = UserData.security("ESP", false);
	reservaService = new ReservaService();
	avisoService = new AvisoService();
	pessoaFisicaService = new PessoaFisicaService();

	UNSAFE_componentWillMount() {
		if (!this.securityEspaco.edit && UserData.operador.pessoa) {
			const reserva = this.state.reserva;
			reserva.pessoa = UserData.operador.pessoa;
			this.setState({reserva, lockPessoa: true});
		}
	}

	convidadosColumns = [
		<Column key={0} style={{width: "*"}} header="Nome" field="pessoa.nome" />,
		<Column header="CPF" key="cpf" style={{width: "10em"}} body={(aviso) => aviso.pessoa.cpf != null ? aviso.pessoa.cpf.formatCpf() : null} />,
		<Column header="RG" key="rg" field="pessoa.rg" style={{width: "8em"}} />,
		<Column header="Passaporte" key="passaporte" field="pessoa.passaporte" style={{width: "9em"}} />,
		<Column key={1} style={{width: "5em"}} header="Ações" body={(aviso) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Pessoa" onClick={() => this.removerPessoa(aviso)} /> : null}
				</div>
			);
		}} />
	];

	adicionarConvidado = () => {
		ModalRealm.showDialog(<EditarPessoaFisica pessoa={null} onModalClose={(pessoa) => this.adicionarPessoa({value: pessoa})} />);
	}

	listarPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.query}`, {size: 10, page: 0}).then((pessoas) => {
			if (pessoas.length === 0) {
				this.setState({pessoas: [{id: -1, nome: `Criar pessoa ${event.query}`}]});
			} else {
				this.setState({pessoas});
			}
		});
	}

	removerPessoa = (event) => {
		let convidados = this.state.reserva.convidados.filter((aviso) => event.pessoa.id !== aviso.pessoa.id);
		if (event.id) {
			this.avisoService.excluir(event).then(() => {
				this.setState({reserva: {...this.state.reserva, convidados}});
			});
		} else {
			this.setState({reserva: {...this.state.reserva, convidados}});
		}
	}

	adicionarPessoa = (event) => {
		if (event.value.id === -1) {
			let value = event.value.nome.replace("Criar pessoa ", "");
			let property = Validator.isCPF(value) ? "cpf" : !isNaN(Number(value)) ? "rg" : "nome";
			if (property === "cpf") value = value.formatCpf();
			ModalRealm.showDialog(<EditarPessoaFisica pessoa={{...MPessoaFisica.Modelo(), [property]: event.value.nome.replace("Criar pessoa ", "")}} onModalClose={(pessoa) => {
				let reserva = this.state.reserva;
				let convidados = this.state.reserva.convidados.filter((aviso) => pessoa.id !== aviso.pessoa.id);
				convidados.push({...MAviso.Modelo(), tipo: "LIBERACAO", pessoa});
				reserva.convidados = convidados;
				this.setState({reserva});
			}} />);
		} else {
			let reserva = this.state.reserva;
			let convidados = this.state.reserva.convidados.filter((aviso) => event.value.id !== aviso.pessoa.id);
			convidados.push({...MAviso.Modelo(), tipo: "LIBERACAO", pessoa: event.value});
			reserva.convidados = convidados;
			this.setState({reserva});
		}
		this.setState({pessoa: ""});
	}

	addConvidado = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.adicionarConvidado(null)} />;

	tabChange = (event) => this.setState({tabIndex: event.index});

	render() {
		const periodo = this.state.reserva.periodo;
		return (
			<Dialog modal header="Reserva" visible={this.state.visible} style={{width: "900px"}} onHide={this.onHide}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" activeIndex={this.state.tabIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField grid={6} label="Espaço" value={this.state.reserva.espaco.descricao} disabled />
								<TextField grid={3} label="Período" value={`${periodo.inicio.substring(0, 5)} até ${periodo.fim.substring(0, 5)}`} disabled />
								<TextField grid={3} label="Data" value={moment(this.state.reserva.data).format("DD/MM/YYYY")} disabled />
								<SelectPessoaFisica disabled={this.state.lockPessoa} grid={12} label="Pessoa" value={this.state.reserva.pessoa} name="pessoa" onChange={this.handleChange} />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Lista de Convidados">
							<PanelContent>
								<SuggestionBox style={{position: "relative"}} appendTo={document.body} label="Pessoas" grid={12} field="nome" completeMethod={this.listarPessoas} suggestions={this.state.pessoas} onChange={(e) => this.setState({pessoa: e.value})} onSelect={this.adicionarPessoa} value={this.state.pessoa} />
								<div className="ui-g-12">
									<DataTable paginator paginatorLeft={this.addConvidado} rows={10} emptyMessage="Nenhuma pessoa adicionada" value={this.state.reserva.convidados} children={this.convidadosColumns} />
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" disabled={!(this.security.edit || (this.security.create && !this.state.reserva.id)) || this.state.lockSave} icon="fa-save" label="Salvar" onClick={this.handleSalvar} />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onHide} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.handleSalvar} onEscape={this.onHide}/> : null}
			</Dialog>
		);
	}

	handleSalvar = () => {
		let reserva = this.state.reserva;
		if (reserva.convidados && reserva.convidados.length) {
			reserva.convidados.forEach((aviso) => {
				aviso.status = reserva.status;
				aviso.inicio = `${reserva.data}T${reserva.periodo.inicio}`;
				aviso.fim = `${reserva.data}T${reserva.periodo.fim}`;
			});
		}
		this.reservaService.salvar(reserva).then((reserva) => {
			if (this.props.onModalClose) {
				this.props.onModalClose(reserva);
			}
			this.onHide();
		});
	}

	handleChange = (event) => {
		const reserva = this.state.reserva;
		reserva[event.name] = event.value;
		this.setState({reserva});
	}

	onHide = () => {
		this.setState({visible: false});
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";
import moment from "moment";

export class MEncomenda {

	static Modelo() {
		return {
			data: moment().format("YYYY-MM-DD"),
			discriminacao: "",
			etiquetas: [],
			remetente: null,
			destinatario: null,
			receptor: null,
			foto: null,
			notificarEntrega: true
		};
	}

	static Status = [
		{label: "Todos", value: null},
		{label: "Novo", value: "ATIVADO"},
		{label: "Entregue", value: "EXCLUIDO"},
		{label: "Cancelado", value: "CANCELADO"},
	];

	static cleanupBeforeSave(encomenda) {
		return MEntidade.cleanupBeforeSave(encomenda);
	}

	static validarParaSalvar(encomenda) {
		let messages = [];
		if (!Validator.isEntidade(encomenda.destinatario)) messages.push("O destinatário é obrigatório");
		if (Validator.isEmpty(encomenda.discriminacao)) messages.push("A discriminação é obrigatória");
		return messages;
	}

}

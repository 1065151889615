import React from "react";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Action} from "../commons/Action";
import {ComboboxEquipamentoFormulario} from "../../select/ComboboxEquipamentoFormulario";
import {httpPost} from "../../utils/Request";
import {UserData} from "../../utils/UserData";
import {ModalRealm} from "../commons/ModalRealm";

export class PreencherFormulario extends React.Component {

	state = {
		visible: true
	};

	onClose = () => this.setState({visible: false});

	confirmarSolicitacao = async () => {
		const {equipamento} = this.state;
		const success = await httpPost("/formularios/tablet", {...this.props.requisicao, equipamento, responsavel: UserData.operador});
		if (success) {
			if (this.props.onModalClose) {
				this.props.onModalClose();
			}
			this.onClose();
		} else {
			ModalRealm.showInformacao("warn", ["O equipamento solicitado não está conectado."]);
		}
	}

	handleChange = e => {
		this.setState({[e.name]: e.value});
	}

	render() {
		const {descricao, nome, placa} = this.props.requisicao.entidade;
		return (
			<Dialog modal header={`${this.props.requisicao.tipo.formulario.descricao} para ${descricao || nome || placa || "Agenda"}`} visible={this.state.visible} style={{width: "650px"}} onHide={this.onClose}>
				<DialogContent>
					<ComboboxEquipamentoFormulario grid={12} label="Selecione o equipamento para o qual deseja enviar a solicitação de preenchimento do formulário" value={this.state.equipamento} onChange={this.handleChange}/>
				</DialogContent>
				<DialogFooter>
					<Action onClick={this.confirmarSolicitacao} mode="post" label="Confirmar" icon="fal fa-check"/>
					<Action onClick={this.onClose} mode="secondary" label="Cancelar" icon="fal fa-times"/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEnter={this.confirmarSolicitacao} onS={this.confirmarSolicitacao} onCtrlS={this.confirmarSolicitacao} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

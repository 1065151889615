import React, {Component} from "react";
import {UserData} from "../../utils/UserData";
import {httpGet, httpPost} from "../../utils/Request";
import {Panel} from "primereact/components/panel/Panel";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import moment from "moment";
import {Combobox} from "../commons/Combobox";
import iconeExcluir from "../../media/icones/excluir.png";
import {ActionFloat} from "../commons/ActionFloat";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {MAgenda} from "../../utils/models/MAgenda";
import {EditarPessoaFisicaSimples} from "../PessoaFisica/EditarPessoaFisicaSimples";
import {byPrimeiraPessoaNome} from "../../utils/SortUtils";
import {DatePicker} from "../commons/DatePicker";
import {Action} from "../commons/Action";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";
import {byDescricao} from "../../utils/StringUtils";

export class PreAgendamentoFixo extends Component {

	state = {
		status: "ATIVADO",
		agendas: [],
		unidade: null,
		unidades: [],
		modelosAgenda: []
	};

	security = UserData.security("AGN", true);
	securityPessoa = UserData.security("PES", true);
	setupCondominioService = new SetupCondominioService();

	componentDidMount() {
		const unidades = UserData.operador?.etiquetas.map(u => ({label: u.descricao, value: u}));
		let unidade = null;
		if (unidades?.length === 1) {
			unidade = unidades[0].value;
		}
		this.setupCondominioService.buscar().then(setupCondominio => {
			const categorias = [{label: "Todos"}, ...setupCondominio.categoriasPrestacaoServicos.map(c => ({label: c.descricao, value: c})).sort(byDescricao)];
			httpGet("/modelos-agenda/prestacao-servicos").then(modelosAgenda => {
				this.setState({unidade, unidades, categorias, modelosAgenda}, this.listarAgendas);
			});
		});
	}

	listarAgendas = () => {
		const params = [];
		params.push(`chaveIntegracao~AGNFIX_${this.state.unidade?.id}`);
		params.push(this.state.status ? `status:${this.state.status}` : "status!EXCLUIDO");
		const extParams = [];
		if (this.state.categoria?.id) extParams.push(`etiquetas=id:${this.state.categoria.id}`);
		httpGet(`/agendas?search=${params.join(";")}&${extParams.join("&")}`).then(agendas => this.setState({agendas}));
	}

	handleChange = event => {
		this.setState({[event.name]: event.value}, () => {
			if (["unidade", "status", "categoria"].includes(event.name)) {
				this.listarAgendas();
			}
		});
	}

	excluirAgenda = agenda => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta agenda?", () => {
			agenda.status = "EXCLUIDO";
			httpPost("/agendas", agenda).then(this.listarAgendas);
		});
	}

	criarPessoa = () => {
		ModalRealm.showDialog(<EditarPessoaFisicaSimples pessoa={MPessoaFisica.Modelo()} onModalClose={pessoa => {
			const agenda = MAgenda.Modelo();
			const modeloAgenda = this.state.modelosAgenda.filter(ma => ma.descricao === this.state.categoria.descricao)[0];
			if (modeloAgenda?.id) {
				agenda.pessoas.push(pessoa);
				switch (modeloAgenda.duracao) {
					case -1:
						agenda.validade = moment().endOf("day");
						break;
					case 0:
						agenda.validade = null;
						break;
					default:
						agenda.validade = moment().add(modeloAgenda.duracao, "minutes").endOf("day").format("YYYY-MM-DDTHH:mm:ss");
						break;
				}
				agenda.modelosAcesso = modeloAgenda.modelosAcesso;
				agenda.flags = modeloAgenda.agendaFlags;
				agenda.etiquetas.push(this.state.categoria);
				agenda.etiquetas.push(this.state.unidade);
				agenda.chaveIntegracao = `AGNFIX_${this.state.unidade.id}-${pessoa.id}-${moment().format("YYYYMMDDHHmm")}`;
				httpPost("/agendas", agenda).then(this.listarAgendas);
			} else {
				ModalRealm.showInformacao("warn", ["Não foi possível criar a agenda pois não há modelos de agenda para essa categoria."]);
			}
		}}/>);
	}

	handleChangeAgenda = event => {
		const {agendas} = this.state;
		agendas[event.index][event.name] = event.value;
		agendas[event.index]._alterado = true;
		this.setState({agendas});
	}

	salvarAlteracoes = async () => {
		const agendas = this.state.agendas.filter(a => a._alterado);
		for (const agenda of agendas) {
			if (agenda.validade?.length) {
				agenda.validade += "T23:59:59";
			}
			await httpPost("/agendas", agenda);
		}
		this.listarAgendas();
	}

	desfazerAlteracoes = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja desfazer as alterações pendentes?", this.listarAgendas);
	}

	criarAgenda = () => {
		if (this.state.agendas.some(a => a._alterado)) {
			ModalRealm.showInformacao("warn", ["Há agendas com alterações não salvas. Por favor, salve essas alterações antes de criar uma nova agenda."]);
		} else {
			ModalRealm.showDialog(<SelecionarCategoria categoria={this.state.categoria} options={this.state.categorias} onModalClose={categoria => {
				this.setState({categoria}, this.criarPessoa);
			}}/>);
		}
	}

	render() {
		return (
			<div>
				<Panel header="Prestação de Serviços">
					<PanelContent>
						<Combobox name="status" label="Status" grid={2} value={this.state.status} options={MAgenda.Status} onChange={this.handleChange}/>
						<Combobox name="unidade" label="Unidade" grid={6} value={this.state.unidade} options={this.state.unidades} onChange={this.handleChange}/>
						<Combobox name="categoria" label="Categoria" grid={4} value={this.state.categoria} options={this.state.categorias} onChange={this.handleChange}/>
						<div className="ui-g-12">
							<DataTable value={this.state.agendas.sort(byPrimeiraPessoaNome)} emptyMessage="Nenhuma prestação de serviço programada">
								<Column header="Nome" body={a => a.pessoas[0].nome}/>
								<Column header="RG" style={{width: "10em"}} body={a => a.pessoas[0].rg}/>
								<Column header="CPF" style={{width: "12em"}} body={a => a.pessoas[0].cpf?.formatCpf()}/>
								<Column header="Telefone" style={{width: "12em"}} field="telefone" body={a => a.pessoas[0].telefone || a.pessoas[0].celular}/>
								<Column header="Liberado(a) até" style={{width: "10em", textAlign: "center"}} field="validade" body={(a, i) => (
									<div style={{position: "relative"}}>
										<DatePicker minDate={new Date()} maxDate={moment().add(30, "days").toDate()} index={i.rowIndex} name="validade" value={a.validade} onChange={this.handleChangeAgenda}/>
										{moment().startOf("day").isAfter(a.validade) ? <i title="Agenda Expirada" className="fa fa-exclamation-triangle prest-serv-alert"/> : null}
									</div>
								)}/>
								<Column header="Ações" style={{width: "6em", textAlign: "center"}} body={a => (
									<div>
										{this.security.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Agenda" onClick={() => this.excluirAgenda(a)} /> : null}
									</div>
								)}/>
							</DataTable>
						</div>
					</PanelContent>
					<PanelFooter>
						<Action disabled={!this.state.agendas?.some(a => a._alterado)} icon="fa fa-save" label="Salvar" onClick={this.salvarAlteracoes}/>
						<Action mode="secondary" disabled={!this.state.agendas?.some(a => a._alterado)} icon="fa fa-undo" label="Desfazer" onClick={this.desfazerAlteracoes}/>
					</PanelFooter>
				</Panel>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Agenda" icon="fa-plus" onClick={this.criarAgenda}/> : null}
				</ActionFloat>
				<ModalRealm/>
			</div>
		);
	}

}

class SelecionarCategoria extends Component {

	state = {
		visible: true,
		categoria: this.props.categoria
	};

	onClose = () => this.setState({visible: false});

	selecionarCategoria = () => {
		this.props.onModalClose(this.state.categoria);
		this.onClose();
	}

	handleChange = event => this.setState({[event.name]: event.value});

	render() {
		return (
			<Dialog modal header="Categoria" visible={this.state.visible} style={{width: "350px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox grid={12} label="Selecione a categoria" autofocus options={this.props.options.filter(o => o.value)} value={this.state.categoria} name="categoria" onChange={this.handleChange}/>
				</DialogContent>
				<DialogFooter>
					<Action disabled={!this.state.categoria?.id} mode="post" label="Selecionar" icon="fa-save" onClick={this.selecionarCategoria}/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

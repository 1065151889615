import React from "react";
import "./commons.css";

export class Action extends React.Component {

    render() {
        return (
            <div style={this.props.style} className={`action-container ${this.props.className || ""}`}>
                <button {...this.props} className={`action ${this.props.mode} ${this.props.className || ""}`}>
                    {this.props.icon ? <i className={`fal ${this.props.icon}`} /> : null}
                    {this.props.label}
                </button>
            </div>
        );
    }

}

import React from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {If} from "../../utils/If";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Label} from "../commons/Label";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Shortcut} from "../commons/Shortcut";
import moment from "moment";
import {ModalRealm} from "../commons/ModalRealm";
import {impressaoEtiquetaService} from "../servicos/ImpressaoEtiquetaService";

const optionsTipoEtiqueta = [
    {label: "Paciente", value: "PACIENTE"},
    {label: "Acompanhante", value: "ACOMPANHANTE"},
    {label: "Visitante/Terceiro", value: "TERCEIRO"}
];

const tagFileNames = {
    "PACIENTE": "./etiquetas/EtiquetaPaciente.zpl",
    "TERCEIRO": "./etiquetas/EtiquetaVisitante.zpl",
    "ACOMPANHANTE": "./etiquetas/EtiquetaAcompanhante.zpl"
};

export class ImpressaoEtiqueta extends React.Component {

    state = {
        visible: true,
        tipoEtiqueta: "ACOMPANHANTE",
        acompanhantes: [],
        pacientes: [],
        paciente: null,
        setores: [],
        impressoras: [],
        disablePrint: false
    };

    componentDidMount() {
        const setores = this.props.agenda?.etiquetas?.filter(e => e.flags?.includes("UNIDADE")).map(e => ({label: e.descricao, value: e}));
        const setor = setores.length === 1 ? setores[0].value : null;
        const paciente = !this.props.agenda?.veiculos?.length && this.props.agenda?.pessoas?.filter(p => this.props.agenda.principalPessoa === p.id)[0];
        this.setState({
            setor,
            setores,
            pacientes: this.props.agenda?.pessoas?.map(v => ({label: v.nome, value: v})),
            paciente,
            acompanhantes: this.props.agenda?.pessoas?.filter(v => this.state.tipoEtiqueta === "TERCEIRO" || !paciente?.id || v.id !== paciente?.id)
        });
        impressaoEtiquetaService.buscarImpressoras().then(is => {
            const nome = window.localStorage.getItem("X-SelectedZebraPrinter") || "";
            const impressora = is.filter(i => i.nome === nome)[0];
            this.setState({impressoras: is.map(i => ({label: i.nome, value: i})), impressora});
        });
    }

    handleClose = () => this.setState({visible: false});

    handleChange = e => {
        if (e.name === "impressora") {
            if (e.value) {
                window.localStorage.setItem("X-SelectedZebraPrinter", e.value.nome);
            } else {
                window.localStorage.removeItem("X-SelectedZebraPrinter");
            }
        }
        this.setState({[e.name]: e.value}, () => {
            this.setState({acompanhantes: this.props.agenda?.pessoas?.filter(v => this.state.tipoEtiqueta === "TERCEIRO" || !this.state.paciente?.id || v.id !== this.state.paciente?.id)});
        });
    }

    handlePrint = () => {
        fetch(tagFileNames[this.state.tipoEtiqueta]).then(res => {
            res.text().then(tagFile => {
                switch (this.state.tipoEtiqueta) {
                    case "TERCEIRO":
                    case "ACOMPANHANTE":
                        for (const s of this.state.acompanhantes?.filter(a => a._selecionado)) {
                            this.printSingleTag(tagFile, s);
                        }
                        break;
                    case "PACIENTE":
                        this.printSingleTag(tagFile, this.state.paciente);
                        break;
                    default:
                        break;
                }
            });
        });
    }

    printSingleTag = async (tagFile, pessoa) => {
        const {impressora} = this.state;
        let categoria = this.props.agenda?.etiquetas?.filter(e => !e.flags.includes("UNIDADE") && !["PORTARIA", "RECEPCAO"].some(d => e.descricao.toUpperCase().includes(d))).map(e => e.descricao).join("/");
        if (!categoria?.length) {
            categoria = "Visitante";
        }
        tagFile = tagFile
            .replace("__F_DATANASCIMENTO", pessoa.nascimento ? moment(pessoa.nascimento).format("DD/MM/YYYY") : "")
            .replace("__F_NOME", pessoa.nome)
            .replace("__F_PACIENTE", this.state.paciente?.nome)
            .replace("__F_SETOR", this.state.setor.descricao)
            .replace("__F_CATEGORIA", categoria)
            .replace("__F_EMPRESA", pessoa.empresa?.nome || "")
            .replace("__F_DATAATENDIMENTO", moment(this.props.agenda.registro).format("DD/MM/YYYY"));
        const impressao = {
            impressora,
            conteudo: tagFile
        };
        this.setState({disablePrint: true}, async () => {
            if (this.state.impressoras?.length) {
                impressaoEtiquetaService.imprimirEtiqueta(impressao).catch(() => {
                    ModalRealm.showInformacao("warn", ["O servidor de impressões não está instalado ou iniciado. Por favor, instale-o ou inicie-o."]);
                });
            } else {
                const newWindow = window.open();
                newWindow.document.open("text/plain");
                newWindow.document.write(tagFile);
                newWindow.document.close();
                newWindow.focus();
                newWindow.print();
                newWindow.close();
            }
            ModalRealm.showInformacao("info", ["Etiqueta enviada para impressão!"]);
            this.setState({disablePrint: false});
        });
    }

    toggleAcompanhante = v => {
        const {acompanhantes} = this.state;
        acompanhantes.filter(a => v.id === a.id).forEach(a => a._selecionado = !a._selecionado);
        this.setState({acompanhantes});
    }

    toggleAllAcompanhantes = () => {
        const {acompanhantes} = this.state;
        acompanhantes.forEach(v => v._selecionado = !v._selecionado);
        this.setState({acompanhantes});
    }

    render() {
        const disablePrint = (
                this.state.disablePrint
                || (this.state.impressoras?.length && this.state.impressora == null)
                || ((["PACIENTE", "ACOMPANHANTE"].includes(this.state.tipoEtiqueta) && !this.state.paciente?.id)
                || ((["ACOMPANHANTE", "TERCEIRO"].includes(this.state.tipoEtiqueta)) && !this.state.acompanhantes.some(a => a._selecionado)))
        );
        return (
            <Dialog visible={this.state.visible} modal header="Impressão de Etiqueta" style={{width: "650px"}} onHide={this.handleClose}>
                <DialogContent>
                    <Combobox appendTo={document.body} options={optionsTipoEtiqueta} grid={6} label="Qual o tipo de etiqueta?" name="tipoEtiqueta" value={this.state.tipoEtiqueta} onChange={this.handleChange}/>
                    <Combobox appendTo={document.body} options={this.state.setores} grid={6} label="Qual setor?" name="setor" value={this.state.setor} onChange={this.handleChange}/>
                    <If condition={this.state.tipoEtiqueta !== "TERCEIRO"}>
                        <Combobox appendTo={document.body} options={this.state.pacientes} grid={12} label="Quem é o paciente?" name="paciente" value={this.state.paciente} onChange={this.handleChange}/>
                    </If>
                    <If condition={this.state.tipoEtiqueta === "TERCEIRO" || (this.state.tipoEtiqueta === "ACOMPANHANTE" && this.state.paciente?.id)}>
                        <div className="ui-g-12">
                            <Label>Para quem será impressa a etiqueta?</Label>
                            <DataTable value={this.state.acompanhantes} emptyMessage="Nenhum acompanhante disponível">
                                <Column style={{width: "2.25em", textAlign: "center"}} header={
                                    <Checkbox checked={this.state.acompanhantes.every(v => v._selecionado)} onChange={this.toggleAllAcompanhantes}/>
                                } body={v => <Checkbox checked={v._selecionado} onChange={() => this.toggleAcompanhante(v)}/>}/>
                                <Column header="Nome" field="nome"/>
                            </DataTable>
                        </div>
                    </If>
                </DialogContent>
                <DialogFooter>
                    <If condition={this.state.impressoras?.length}>
                        <div className="ui-g ui-g-nopad" style={{textAlign: "left", float: "left", width: "300px"}}>
                            <Combobox placeholder="Selecione a impressora" appendTo={document.body} emptyMessage="Selecione a impressora" grid={12} name="impressora" value={this.state.impressora} options={this.state.impressoras} onChange={this.handleChange}/>
                        </div>
                    </If>
                    <Action disabled={disablePrint} label="Imprimir" mode="post" icon="fa fa-print" onClick={this.handlePrint}/>
                    <Action label="Fechar" mode="secondary" icon="fa fa-times" onClick={this.handleClose}/>
                </DialogFooter>
                <Shortcut onEscape={this.handleClose}/>
            </Dialog>
        );
    }

}

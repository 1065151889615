import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {SelectCredencial} from "../../select/SelectCredencial";
import {SelectEquipamento} from "../../select/SelectEquipamento";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {OcorrenciaService} from "../servicos/OcorrenciaService";

export class EditarOcorrencia extends React.Component {

	state = {
		ocorrencia: Object.assign(MOcorrencia.Modelo(), this.props.ocorrencia),
		visible: true,
		lockSave: false
	};

	ocorrenciaService = new OcorrenciaService();
	localidadeService = new LocalidadeService();
	security = UserData.security("OCO", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarOcorrencia = () => {
		if (this.security.edit) {
			let ocorrencia = MOcorrencia.cleanupBeforeSave(Object.assign({}, this.state.ocorrencia));
			let messages = MOcorrencia.validarParaSalvar(ocorrencia);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.ocorrenciaService.salvar(ocorrencia).then((ocorrencia) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(ocorrencia);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let ocorrencia = this.state.ocorrencia;
		ocorrencia[event.name] = event.value;
		this.setState({ocorrencia});
	}

	listarLocalidades = (event) => {
		this.localidadeService.listar(`search=nome~${event.query}`, {page: 0, size: 10, sort: "nome"}).then(localidades => this.setState({localidades}));
	}

	render() {
		return (
			<Dialog modal header="Ocorrência" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox disabled={this.props.justificar} grid={4} label="Tipo" appendTo={document.body} name="evento" options={MOcorrencia.Eventos} onChange={this.handleChange} value={this.state.ocorrencia.evento} />
					<SelectPessoaFisica disabled={this.props.justificar} value={this.state.ocorrencia.pessoa} onSelect={this.handleChange} name="pessoa" label="Pessoa" grid={8} />
					<SelectCredencial disabled={this.props.justificar} name="credencial" label="Credencial" grid={6} value={this.state.ocorrencia.credencial} onSelect={this.handleChange} />
					<SelectEquipamento disabled={this.props.justificar} name="equipamento" label="Equipamento" grid={6} value={this.state.ocorrencia.equipamento} onSelect={this.handleChange} />
					<TextField required={this.props.justificar} grid={12} label="Justificativa" multiline value={this.state.ocorrencia.justificativa} onChange={this.handleChange} name="justificativa" style={{height: "6em"}} rows={4} />
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.ocorrencia.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/ocorrencias", this.state.ocorrencia)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.ocorrencia.id)) || this.state.lockSave} onClick={this.salvarOcorrencia} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarOcorrencia} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

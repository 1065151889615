import React from "react";
import {SuggestionBox} from "../components/commons/SuggestionBox";
import {GUI} from "../utils/Constants";

export class Select extends React.Component {

	state = {
		entidades: []
	};

	listar = (event) => {
		this.props.service.listar(`search=${this.props.query}${this.props.exact ? ":" : "~"}${event.query}`, {page: 0, size: GUI.maxSuggestion}).then(entidades => {
			if ((this.props.insertable == null || this.props.insertable === true) && entidades.length === 0) {
				entidades.push({...this.props.modelo, id: -1, [this.props.field]: `Criar ${this.props.entidade} ${event.query}`});
			}
			this.setState({entidades});
		});
	}

	onChange = event => {
		if (this.props.onChange) {
			let value = event.value;
			if (this.props.uppercase) {
				value = value?.toUpperCase();
			}
			this.props.onChange({name: this.props.name, value, index: this.props.index});
		}
	}

	render() {
		return <SuggestionBox appendTo={document.body} value={this.props.value} field={this.props.field} suggestions={this.state.entidades} onChange={this.onChange} completeMethod={this.listar} {...this.props}/>;
	}

}

import moment from "moment";
import {Calendar} from "primereact/components/calendar/Calendar";
import React from "react";
import {Label} from "./Label";

export class DatePicker extends React.Component {

	onChange = (event) => {
		if (event.originalEvent.target.value != null) {
			if (this.props.showTime) {
				event.originalEvent.target.value = event.originalEvent.target.value.toDateTime();
			} else {
				event.originalEvent.target.value = event.originalEvent.target.value.toDate();
			}
		}
		if (this.props.onChange) {
			if (event.value) {
				const pattern = this.props.showTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD";
				this.props.onChange({name: this.props.name, index: this.props.index, value: moment(event.value).format(pattern)});
			} else {
				this.props.onChange({name: this.props.name, index: this.props.index, value: null});
			}
		}
	}

	pt_br = {
		firstDayOfWeek: 0,
    	dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
    	dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    	dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    	monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    	monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
	}

	removeKeys = [8, 46];

	onKeyPress = (event) => {
		if (!this.fullSelected()) {
			if (!this.removeKeys.includes(event.keyCode)) {
				if (this.calendar.value && this.calendar.value.length === (this.props.showTime ? 19 : 10)) {
					event.preventDefault();
				}
			}
		}
	}

	fullSelected = () => {
		let calendar = this.calendar || {};
		return this.props.value ? calendar.selectionStart === 0 && calendar.selectionEnd === (this.props.showTime ? 19 : 10) : true;
	}

	refCalendar = (el) => this.calendar = el ? el.inputfield : null;
	
	render() {
		let value = /T[0-9]{2}:[0-9]{2}:[0-9]{2}/g.test(this.props.value) ? this.props.value : this.props.value + "T00:00:00";
		value = typeof this.props.value === "string" ? new Date(Date.parse(value)) : this.props.value == null || isNaN(this.props.value) ? null : new Date(this.props.value);
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label>{this.props.label}</Label>
				<Calendar ref={this.refCalendar}
				          className={`${this.props.required && (this.props.value == null || this.props.value.length <= (this.props.length ? this.props.length - 1 : 0)) ? " ui-field-required" : ""}`}
				          locale={this.pt_br}
				          dateFormat="dd/mm/yy"
				          style={{width: "100%", display: "inline-block"}}
				          {...this.props}
				          value={value}
				          onChange={this.onChange}
				          onKeyPress={this.onKeyPress}/>
			</div>
		);
	}

}

import {httpPost} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class JornadaTrabalhoService extends EntidadeNextGenService {

	constructor() {
		super("/jornadas-trabalho");
	}

	listarCriterio(criterio) {
		return httpPost("/jornadas-trabalho/criterio", criterio);
	}

}

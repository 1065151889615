import React from "react";
import {criarAgendamento, RelatorioProgramadoService} from "../servicos/RelatorioProgramadoService";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {SelectRelatorio} from "../commons/SelectRelatorio";
import {TextField} from "../commons/TextField";
import ReactJson from "react-json-view";
import {Label} from "../commons/Label";
import {Shortcut} from "../commons/Shortcut";
import {ModalRealm} from "../commons/ModalRealm";
import {HelpCronExpression} from "./HelpCronExpression";
import cronstrue from "cronstrue/i18n";

function clearCriterio(c) {
	const keys = Object.keys(c);
	delete c.relatorio;
	delete c.registros;
	keys.filter(k => c[k] == null).forEach(k => delete c[k]);
	return c;
}

function prepareForEdit(agendamento) {
	if (Array.isArray(agendamento.destinatarios)) {
		agendamento.destinatarios = agendamento.destinatarios.join(", ");
	}
	return agendamento;
}

export class EditarRelatorioProgramado extends React.Component {

	state = {
		visible: true,
		agendamento: prepareForEdit(this.props.agendamento || {relatorio: this.props.criterio?.relatorio, ...criarAgendamento(), criterio: clearCriterio(this.props.criterio || {})})
	};

	relatorioProgramadoService = new RelatorioProgramadoService();

	handleClose = () => this.setState({visible: false});

	salvarAgendamento = () => {
		const {agendamento} = this.state;
		const messages = this.relatorioProgramadoService.validar(agendamento);
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
			return;
		}
		agendamento.destinatarios = agendamento.destinatarios.split(",").map(d => d.trim()).sort((a, b) => a.localeCompare(b));
		this.relatorioProgramadoService.salvar(agendamento).then(agendamento => {
			if (this.props.onModalClose) {
				this.props.onModalClose(agendamento);
			}
			this.handleClose();
		});
	}

	handleChange = e => {
		const {agendamento} = this.state;
		agendamento[e.name] = e.value;
		this.setState({agendamento});
	}

	openHelpCronExpression = () => {
		ModalRealm.showDialog(<HelpCronExpression onModalClose={value => this.handleChange({name: "cron", value})}/>);
	}

	render() {
		return (
			<Dialog modal visible={this.state.visible} style={{width: "850px"}} header="Editar Agendamento de Relatório" onHide={this.handleClose}>
				<DialogContent>
					<SelectRelatorio grid={12} value={this.state.agendamento.relatorio} onChange={this.handleChange}/>
					<TextField grid={3} label={<Label>Frequência <span style={{fontSize: "1em", color: "#2196F3", float: "right", cursor: "pointer"}} onClick={this.openHelpCronExpression}><i style={{fontSize: "10px"}} className="fa fa-question-circle"/> Ajuda</span></Label>} name="cron" value={this.state.agendamento.cron} onChange={this.handleChange}/>
					<TextField grid={9} label={<Label>Destinatários <i style={{fontSize: "1em", float: "right"}}>(Separado por vírgula)</i></Label>} name="destinatarios" value={this.state.agendamento.destinatarios} onChange={this.handleChange}/>
					<div className="ui-g-12 ui-g-nopad">
						<div style={{margin: "0 .5em", fontSize: "11px", fontStyle: "italic"}}>{cronstrue.toString(this.state.agendamento.cron, {verbose: true, throwExceptionOnParseError: false, use24HourTimeFormat: true, locale: "pt_BR"})}</div>
					</div>
					<TextField grid={12} label="Título" name="titulo" value={this.state.agendamento.titulo} onChange={this.handleChange}/>
					<TextField multiline style={{height: "10em"}} grid={12} label="Conteúdo" name="conteudo" value={this.state.agendamento.conteudo} onChange={this.handleChange}/>
					<div className="ui-g-12">
						<Label>Parâmetros<i style={{float: "right", fontSize: "12px"}}>(Os parâmetros são copiados automaticamente da tela de filtros do relatório)</i></Label>
						<div style={{height: "12em", overflowY: "scroll", marginTop: ".25em", border: "1px solid rgba(0, 0, 0, .2)", borderRadius: "3px"}}>
							<ReactJson src={this.state.agendamento.criterio}/>
						</div>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="success" label="Salvar" icon="fa fa-save" onClick={this.salvarAgendamento}/>
					<Action mode="secondary" label="Fechar" icon="fa fa-times" onClick={this.handleClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarAgendamento} onEscape={this.handleClose}/> : null}
			</Dialog>
		);
	}

}

export function byValidadeDesc(a, b) {
    if (!a.validade) {
        if (!b.validade) return 0;
        return -1;
    }
    if (!b.validade) return 1;
    return b.validade?.localeCompare(a.validade);
}

export function byNome(a, b) {
    return a?.nome?.localeCompare(b?.nome);
}

export function byPrimeiraPessoaNome(a, b) {
    if (a.pessoas?.length && b.pessoas?.length) {
        if (a.pessoas.length === b.pessoas.length) {
            return a.pessoas[0].nome.localeCompare(b.pessoas[0].nome);
        }
        return a.pessoas.length - b.pessoas.length;
    }
    return (a.pessoas?.length ? -1 : b.pessoas?.length ? 1 : 0);
}

export function byIsInThenDescricao(list, a, b) {
    if (list?.length) {
        if (list.some(e => e.id === a.id)) {
            if (!list.some(e => e.id === b.id)) {
                return -1;
            }
        } else if (list.some(e => e.id === b.id)) {
            return 1;
        }
    }
    return a.descricao.localeCompare(b.descricao);
}

export function hasFlagUnidade(e) {
    return e.flags.includes("UNIDADE");
}

export function hasNotFlagUnidade(e) {
    return !hasFlagUnidade(e);
}

export function byTipoOrdenacaoThenDescricao(a, b) {
    if (a.tipo?.ordenacao !== b.tipo?.ordenacao) {
        return a.tipo?.ordenacao - b.tipo?.ordenacao;
    }
    return a.tipo?.descricao - b.tipo?.descricao;
}

export function byPrincipalThenNome(agenda, a, b) {
    if (agenda.principalPessoa === a.id) return -1;
    if (agenda.principalPessoa === b.id) return 1;
    return a.nome.localeCompare(b.nome);
}

export function byVeiculoTipo(a, b) {
    if (a.tipo === "CARRETA") {
        return a.tipo !== b.tipo;
    }
    return (b.tipo !== "CARRETA") - 1;
}

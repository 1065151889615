import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeReutilizar from "../../media/icones/reutilizar.png";
import {GUI} from "../../utils/Constants";
import {labelTecnologias, MCredencial} from "../../utils/models/MCredencial";
import {NumberUtils} from "../../utils/NumberUtils";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Combobox} from "../commons/Combobox";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Radio} from "../commons/Radio";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CredencialService} from "../servicos/CredencialService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {CadastrarCartao} from "./CadastrarCartao";
import {EditarCredencial} from "./EditarCredencial";
import {CadastrarControle} from "./CadastrarControle";
import {httpGet} from "../../utils/Request";
import {ManutencaoService} from "../servicos/ManutencaoService";
import {SetupSistemaService} from "../servicos/SetupSistemaService";

export class Credencial extends React.Component {

	state = {
		forma: null,
		flags: null,
		status: null,
		firstResult: 0,
		sort: "id desc",
		tecnologia: null
	};

	credencialService = new CredencialService();
	manutencaoService = new ManutencaoService();
	setupSistemaService = new SetupSistemaService();
	security = UserData.security("CRD", true);

	componentDidMount() {
		this.setupSistemaService.buscar().then(setupSistema => this.setState({tecnologia: setupSistema.tecnologiaPadrao}));
	}

	editarCredencial = credencial => {
		if (credencial?.id) {
			this.credencialService.buscar(credencial).then(credencial => {
				ModalRealm.showDialog(<EditarCredencial key={Math.random()} credencial={credencial} onModalClose={this.listarCredenciais}/>);
			});
		} else {
			ModalRealm.showDialog(<EditarCredencial key={Math.random()} credencial={{...MCredencial.Modelo(), tecnologia: this.state.tecnologia}} onModalClose={this.listarCredenciais}/>);
		}
	}

	excluirCredencial = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta credencial?", () => {
			this.credencialService.excluir(rowData).then(() => {
				this.listarCredenciais();
				if (rowData.forma === "CONTROLE") {
					httpGet("/equipamentos?search=status:ATIVADO;familia:LINEAR&page=0&size=100").then(equipamentos => {
						if (equipamentos.length) {
							const manutencao = {
								credenciais: [rowData],
								equipamentos
							};
							this.manutencaoService.removerCredencial(manutencao);
						}
					});
				}
			});
		});
	}

	liberarCredencial = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja liberar esta credencial?", () => this.credencialService.liberarCredencial(rowData).then(() => this.listarCredenciais()));
	}

	listarCredenciais = () => {
		if (this.queryTimeout) {
			clearInterval(this.queryTimeout);
		}
		let codigo = null;
		if (this.state.codigo && this.state.codigo.length) {
			codigo = this.state.codigo;
		} else if (this.state._wiegand && this.state._wiegand.length) {
			codigo = ((Math.floor(Number(this.state._wiegand) / 100000) * (Math.pow(2, 32)))) + (Number(this.state._wiegand) % 100000);
		}
		this.queryTimeout = setTimeout(() => {
			const {titular, forma, flags, status, sort, excluidos} = this.state;
			this.credencialService.listarAlt({
				query: this.state.descricao,
				titular,
				forma,
				flags,
				status,
				codigo,
				page: 0,
				size: GUI.maxResults,
				sort: sort || "descricao",
				excluidos: excluidos
			}).then((credenciais) => this.setState({credenciais, firstResult: 0}));
		}, 50);
	}

	restaurarCredencial = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta credencial?", () => {
			if (rowData != null && rowData.id != null) {
				this.credencialService.buscar(rowData).then((credencial) => {
					credencial.status = "ATIVADO";
					this.credencialService.salvar(credencial).then(() => {
						this.listarCredenciais();
						if (credencial.forma === "CONTROLE" && credencial.pessoa) {
							httpGet("/equipamentos?search=status:ATIVADO;familia:LINEAR&page=0&size=100").then(equipamentos => {
								if (equipamentos.length) {
									this.manutencaoService.enviarPessoa({
										pessoas: [credencial.pessoa],
										equipamentos
									});
								}
							});
						}
					});
				});
			}
		});
	}

	eliminarCredencial = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta credencial?", () => this.credencialService.eliminar(rowData).then((result) => {
			if (result) this.listarCredenciais(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta credencial: há outros registros dependentes dela no sistema."]);
		}));
	}

	queryTimeout = null;

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	sorts = [
		{label: "Descrição", value: "descricao"},
		{label: "Mais Recente", value: "id desc"},
		{label: "Mais Antigo", value: "id"},
	];

	cadastrarCartoes = () => {
		ModalRealm.showDialog(<CadastrarCartao />);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	cadastrarControleRemoto = () => {
		ModalRealm.showDialog(<CadastrarControle key={Math.random()}/>);
	}

	render() {
		return (
			<div>
				<Panel header="Credenciais">
					<PanelContent>
						<TextField grid={4} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleChange} />
						<TextField grid={4} label="Titular" name="titular" value={this.state.titular} onChange={this.handleChange} />
						<TextField grid={2} label="Código" name="codigo" value={this.state.codigo} onChange={this.handleChange} />
						<TextField grid={2} label="Wiegand" name="_wiegand" value={this.state._wiegand} onChange={this.handleChange} />
						<Combobox grid={3} label="Forma" onChange={this.handleChange} value={this.state.forma} options={MCredencial.Formas} name="forma" />
						<Combobox grid={3} label="Flags" onChange={this.handleChange} value={this.state.flags} options={MCredencial.Flags} name="flags" />
						<Combobox grid={2} label="Ordenação" onChange={this.handleChange} name="sort" value={this.state.sort} options={this.sorts} />
						<div className="ui-g-4">
							<Label>Status</Label>
							<Radio id="filterStatusTodos" label="Todas" onChange={() => this.setState({status: null}, this.listarCredenciais)} checked={this.state.status === null} />
							<Radio id="filterStatusLivres" label="Livres" onChange={() => this.setState({status: "LIVRE"}, this.listarCredenciais)} checked={this.state.status === "LIVRE"} />
							<Radio id="filterStatusOcupadas" label="Ocupadas" onChange={() => this.setState({status: "OCUPADO"}, this.listarCredenciais)} checked={this.state.status === "OCUPADO"} />
							<Radio id="filterStatusExpiradas" label="Expiradas" onChange={() => this.setState({status: "EXPIRADO"}, this.listarCredenciais)} checked={this.state.status === "EXPIRADO"} />
						</div>
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleChange} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarCredenciais} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.credenciais} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator>
					<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />
					<Column sortable header="Descrição" style={{width: "*"}} field="descricao" />
					<Column header="Código" style={{width: "*"}} field="codigo" body={(rowData) => {
						switch (rowData.forma) {
							case "SENHA":
								return rowData.codigo.toString().substring(0, 3) + "****";
							case "PROXIMIDADE":
								const facility = NumberUtils.intToByteArray(rowData.codigo);
								return `${rowData.codigo}; W: ` + `${(facility[2] << 8) | facility[3]}`.padStart(3, '0') + " " + `${(rowData.codigo & 0xFFFFFFFF)}`.padStart(5, '0');
							default:
								return rowData.codigo;
						}
					}} />
					<Column header="Forma" style={{width: "14em"}} body={c => {
						if (c.forma === "PROXIMIDADE") {
							return <>Proximidade ({labelTecnologias[c.tecnologia]})</>;
						} else {
							return MCredencial.MapFormas[c.forma];
						}
					}} />
					<Column header="Opções" style={{width: "10em"}} field="flags" body={(rowData) => {
						return <div style={{textAlign: "center"}}>{
							MCredencial.Flags.filter(f => rowData.flags.includes(f.value)).map(f => {
								return <span className="ui-tzm-minchip" style={{backgroundColor: (f.value === "TEMPORARIA" ? "#607D8B" : "#F44336")}}>{f.label}</span>;
							})
						}</div>;
					}} />
					<Column header="Titular" style={{width: "*"}} body={(rowData) => rowData.pessoa ? rowData.pessoa.nome : null} />
					<Column style={{textAlign: "center", width: "8em"}} header="Ações" body={(rowData) => {
						return (
							<div>
								{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Credencial" onClick={(event) => this.editarCredencial(rowData)} /> : null}
								{this.security.edit && rowData.status !== "EXCLUIDO" && rowData.flags.includes("TEMPORARIA") && this.state.status === "EXPIRADO" ? <img className="ui-action-icon" src={iconeReutilizar} alt="" title="Liberar Credencial" onClick={(event) => this.liberarCredencial(rowData)} /> : null}
								{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Credencial" onClick={(event) => this.excluirCredencial(rowData)} /> : null}
								{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
								{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Credencial" onClick={(event) => this.restaurarCredencial(rowData)} /> : null}
								{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Credencial" onClick={(event) => this.eliminarCredencial(rowData)} /> : null}
							</div>
						);
					}} />
				</DataTable>
				<div className="ui-tzm-pagebottom" />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post small" title="Cadastrar Controles Remotos" icon="fa-box" onClick={this.cadastrarControleRemoto} /> : null}
					{this.security.create ? <ActionFloat.Button mode="post small" title="Cadastrar Cartões" icon="fa-credit-card" onClick={this.cadastrarCartoes} /> : null}
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Credencial" icon="fa-plus" onClick={this.editarCredencial} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarCredenciais}
					onCtrlN={() => window.location.pathname === "/credenciais" && this.security.create && this.editarCredencial()}
					onCtrlE={() => window.location.pathname === "/credenciais" && this.security.edit && this.state.credenciais.length === 1 && this.editarCredencial(this.state.credenciais[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/credenciais", entidade);
	}

}

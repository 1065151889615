const undesiredWords = [
    "123",
    "qwerty",
    "senha"
];

export function scorePassword(password, username = "?????????????????????") {
    if (!password?.length) return {score: 0};
    let score = 0;
    if (password.split("").some(c => "1234567890".includes(c))) ++score;
    if (password.split("").some(c => "abcdefghijklmnopqrstuvwxyz".includes(c))) ++score;
    if (password.split("").some(c => "ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(c))) ++score;
    if (password.split("").some(c => "()-{}[]<>?!:\\|/+-@#$%&*~^".includes(c))) ++score;
    if (password.length < 4) {
        score = 1;
    } else if (password.length > 7) {
        ++score;
    }
    if (password.toLowerCase().includes(username.toLowerCase())) score = 1;
    if (undesiredWords.some(uw => password.toLowerCase().includes(uw))) score = 1;
    return {score};
}

import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {UserData} from "../utils/UserData";
import {MultiSelect} from "./MultiSelect";
import {PerfilService} from "../components/servicos/PerfilService";
import {MPerfil} from "../utils/models/MPerfil";
import {EditarPerfil} from "../components/Perfil/EditarPerfil";

export class MultiSelectPerfil extends React.Component {

	state = {
		etiquetas: [],
		modelo: MPerfil.Modelo()
	};

	security = UserData.security("PRF");

	service = new PerfilService();

	onSelect = event => {
		let perfis = this.props.value || [];
		if (event.value?.id === -1) {
			ModalRealm.showDialog(<EditarPerfil key={Math.random()} perfil={{...MPerfil.Modelo(), descricao: event.value.descricao.replace(`Criar perfil `, "")}} onModalClose={perfil => {
				perfis.push(perfil);
				this.props.onSelect({name: this.props.name, value: perfis});
			}} />);
		} else {
			if (!perfis.map(e => e.id).includes(event.value.id)) {
				perfis.push(event.value);
			}
			this.props.onSelect({name: this.props.name, value: perfis});
		}
	}

	onChange = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect(event);
		}
	}

	render() {
		return <MultiSelect label={this.props.label || "Perfis"} onChange={this.onChange} insertable={this.security.create && this.props.insertable} entidade="perfil" service={this.service} modelo={this.state.modelo} field="descricao" query="status:ATIVADO;descricao" {...this.props} onSelect={this.onSelect}/>;
	}

}

import React from "react";
import SignatureCanvas from "react-signature-canvas";
import "./Signature.css";
import {toSrc} from "../../utils/StringUtils";
import {Action} from "./Action";

const margin = 18;

export class Signature extends React.Component {

	state = {
		width: 0
	};

	containerRef = el => this.containerEl = el;
	signatureRef = el => this.signatureEl = el;

	componentDidMount() {
		if (this.containerEl) {
			this.setState({witdh: this.containerEl.getBoundingClientRect().width - margin});
		}
		if (this.containerEl && this.signatureEl) {
			const observerFunction = () => {
				if (this.containerEl) {
					this.setState({width: this.containerEl.getBoundingClientRect().width - margin});
				}
				this.updateWidth();
			};
			const resizeObserver = new ResizeObserver(observerFunction);
			resizeObserver.observe(this.containerEl);
		}
	}

	updateWidth = () => {
		if (this.signatureEl) {
			this.signatureEl.fromDataURL(!this.props.value || this.props.value.startsWith("data") ? this.props.value : `data:image/png;base64,${this.props.value}`);
		}
	}

	handleStroke = () => {
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, index: this.props.index, value: this.signatureEl.getCanvas().toDataURL().split(",")[1]});
		}
	}

	limparCanvas = () => {
		if (this.signatureEl) {
			this.signatureEl.clear();
		}
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, index: this.props.index, value: null});
		}
	}

	render() {
		return (
			<div style={{position: "relative"}} ref={this.containerRef} className={`ui-g-${this.props.grid || 12}`}>
				{this.props.label ? <label className="label">{this.props.label}</label> : null}
				{this.props.image ? <img style={{width: `${this.state.width}px`}} src={toSrc({id: this.props.image})} alt=""/> : <SignatureCanvas canvasProps={{width: this.state.width, height: this.props.height, className: "signature-canvas"}} {...this.props} onEnd={this.handleStroke} ref={this.signatureRef}/>}
				{this.props.value || this.props.image ? <Action mode="icon-only delete" style={{position: "absolute", bottom: "10px", right: "8px"}} icon="fal fa-eraser" onClick={this.limparCanvas}/> : null}
			</div>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MControlador} from "../../utils/models/MControlador";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {ControladorService} from "../servicos/ControladorService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";

export class EditarControlador extends React.Component {

	state = {
		controlador: Object.assign(MControlador.Modelo(), this.props.controlador),
		visible: true,
		lockSave: false
	};

	controladorService = new ControladorService();
	security = UserData.security("CTR", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarControlador = () => {
		if (this.security.edit) {
			let controlador = MControlador.cleanupBeforeSave(Object.assign({}, this.state.controlador));
			let messages = MControlador.validarParaSalvar(controlador);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.controladorService.salvar(this.state.controlador).then((controlador) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(controlador);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let controlador = this.state.controlador;
		controlador[event.name] = event.value;
		this.setState({controlador});
	}

	render() {
		return (
			<Dialog modal header="Controlador" visible={this.state.visible} style={{width: "650px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={8} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.controlador.descricao}/>
					<TextField grid={4} help="Endereço utilizado pelo frontend para enviar comandos de acionamento" label="Alias" name="alias" onChange={this.handleChange} value={this.state.controlador.alias}/>
					<TextField grid={6} label="Endereço" required name="endereco" onChange={this.handleChange} value={this.state.controlador.endereco}/>
					<TextField grid={6} label="Nome do Arquivo" required name="fileName" onChange={this.handleChange} value={this.state.controlador.fileName}/>
					<TextField grid={6} label="Endereço (Standalone)" name="standalone" onChange={this.handleChange} value={this.state.controlador.standalone}/>
					<TextField grid={6} label="Nome do Arquivo (Standalone)" name="fileNameStandalone" onChange={this.handleChange} value={this.state.controlador.fileNameStandalone}/>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.controlador.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/controladores", this.state.controlador)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.controlador.id)) || this.state.lockSave} onClick={this.salvarControlador} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarControlador} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

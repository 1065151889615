import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MGrupoTrabalho {

	static Modelo() {
		return {
			descricao: "",
			equipamentos: []
		};
	}

	static cleanupBeforeSave(lugar) {
		return MEntidade.cleanupBeforeSave(lugar);
	}

	static validarParaSalvar(lugar) {
		let messages = [];
		if (Validator.isEmpty(lugar.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MContaEmail {

	static Modelo() {
		return {
			finalidade: "GERAL",
			servidor: "",
			porta: 0,
			usuario: "",
			senha: "",
			flags: []
		};
	}

	static Finalidades = [
		{label: "Geral", value: "GERAL"},
		{label: "Encomenda", value: "ENCOMENDA"},
		{label: "Visita", value: "VISITA"},
		{label: "Monitoramento", value: "MONITORAMENTO"},
		{label: "Comunicado", value: "COMUNICADO"},
		{label: "Reserva", value: "RESERVA"},
		{label: "Relatório", value: "RELATORIO"}
	];

	static MapFinalidades = {
		"GERAL": "Geral",
		"ENCOMENDA": "Encomenda",
		"VISITA": "Visita",
		"MONITORAMENTO": "Monitoramento",
		"COMUNICADO": "Comunicado",
		"RESERVA": "Reserva",
		"RELATORIO": "Relatório"
	};

	static cleanupBeforeSave(contaEmail) {
		return MEntidade.cleanupBeforeSave(contaEmail);
	}

	static validarParaSalvar(contaEmail) {
		let messages = [];
		if (Validator.isEmpty(contaEmail.servidor)) messages.push("O servidor de email é obrigatório");
		if (!contaEmail.porta) messages.push("A porta de email é obrigatória");
		if (Validator.isEmpty(contaEmail.usuario)) messages.push("O usuario de email é obrigatório");
		if (Validator.isEmpty(contaEmail.senha)) messages.push("A senha de email é obrigatória");
		return messages;
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {SelectCredencial} from "../../select/SelectCredencial";
import {NumberUtils} from "../../utils/NumberUtils";
import {httpPost} from "../../utils/Request";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {CredencialService} from "../servicos/CredencialService";

export class EditarCredencialTemporaria extends React.Component {

    credencialService = new CredencialService();

    state = {
        visible: true
    };

    render() {
        return (
            <Dialog modal header="Trocar Credencial Temporária" visible={this.state.visible} style={{width: "350px"}} onHide={this.onClose}>
				<DialogContent>
                    <SelectCredencial grid={12} disabled label="Anterior" value={this.props.credencial} />
                    <SuggestionBox autofocus grid={12} onKeyPress={this.convertCartao} name="credencial" label="Nova" value={this.state.credencial} suggestions={this.state.credenciais} completeMethod={this.listarCredenciaisTemporarias} onChange={this.handleChange} field="descricao" />
                </DialogContent>
                <DialogFooter>
                    <Action mode="post" label="Salvar" onClick={this.onSave} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
                    {this.state.visible ? <Shortcut onCtrlS={this.onSave} onEscape={this.onClose}/> : null}
                </DialogFooter>
            </Dialog>
        );
    }

    convertCartao = (event) => {
		if (event.charCode === 13) {
			this.credencialService.listar(`search=codigo:${NumberUtils.toLittleEndian(this.state.credencial)}`, {page: 0, size: 1}).then((credenciais) => {
				if (credenciais.length > 0) {
                    this.setState({credencial: credenciais[0]});
                    this.onSave();
				}
			});
		}
    }
    
    listarCredenciaisTemporarias = (event) => {
		this.credencialService.listarAlt({query: event.query, flags: "TEMPORARIA", page: 0, size: 10, sort: "descricao"}).then((credenciais) => this.setState({credenciais}));
	}

    handleChange = (event) => this.setState({[event.name]: event.value});

    onSave = () => {
        if (this.state.credencial && this.state.credencial.id) {
            const credencial = this.state.credencial;
            credencial.pessoa = this.props.credencial.pessoa;
            if (this.props.credencial.id !== credencial.id) {
                const antiga = this.props.credencial;
                antiga.pessoa = null;
                httpPost("/credenciais", antiga);
            }
            httpPost("/credenciais", credencial).then((credencial) => {
                if (this.props.onModalClose) {
                    this.props.onModalClose(credencial);
                }
                this.onClose();
            });
        } else {
            const antiga = this.props.credencial;
            antiga.pessoa = null;
            httpPost("/credenciais", antiga).then(() => {
                if (this.props.onModalClose) this.props.onModalClose();
                this.onClose();
            });
        }
    }

    onClose = () => {
        this.setState({visible: false});
    }

}

import React from "react";

export class HelpEspaco extends React.Component {

	render() {
		return (
			<div>
				<p>O espaço é o mecanismo utilizado pelo Safety para especificar um período de acesso à um espaço com uma determinada descrição.</p>
				<p>Esta tela permite realizar a criação, a edição e a consulta do espaço.</p>
				<h1>Como utilizar o espaço?</h1>
				<p>
					Um espaço registrado só pode ser utilizado através de uma reserva, para isso é necessário o acesso à tela de reserva.
				</p>
				<h2>Filtros de Busca</h2>
				<ul>
					<li><em>Descrição</em>:
                    texto único usado para identificar o espaço noutras telas do sistema.
					</li>
				</ul>
				<h2>Como preencher os campos?</h2>
                <li>Descrição: epecifíque o espaço de acordo com a sua funcionalidade, atribuindo uma nomeação única .</li>
                <li>Período: defina um período que o acesso é permitido.</li>
			</div>
		);
	}
}

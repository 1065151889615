import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {SelectLugar} from "../../select/SelectLugar";
import {MDispositivo, MEquipamento} from "../../utils/models/MEquipamento";
import {MLogAcesso} from "../../utils/models/MLogAcesso";
import {Validator} from "../../utils/Validator";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {MultiCombobox} from "../commons/MultiCombobox";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {Chips} from "../commons/Chips";

export class EditarDispositivo extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dispositivo: this.fillNulls(props.dispositivo),
			visible: true
		};
	}

	fillNulls = (dispositivo) => {
		if (!dispositivo) {
			dispositivo = MDispositivo.Modelo();
		}
		return dispositivo;
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarDispositivo = () => {
		let dispositivo = this.state.dispositivo;
		if (!Validator.isEntidade(dispositivo.destino)) dispositivo.destino = null;
		Object.keys(dispositivo).forEach((property) => (dispositivo[property] === "") && delete dispositivo[property]);
		if (this.props.onModalClose) {
			this.props.onModalClose(dispositivo);
		}
		this.onClose();
	}

	handleChange = (event) => {
		let dispositivo = this.state.dispositivo;
		dispositivo[event.name] = event.value;
		this.setState({dispositivo});
	}

	addSequencia = event => {
		const {dispositivo} = this.state;
		dispositivo.sequencia.push(event.value);
		this.setState({dispositivo});
	}

	delSequencia = event => {
		const {dispositivo} = this.state;
		dispositivo.sequencia = dispositivo.sequencia.filter(s => s !== event.value);
		this.setState({dispositivo});
	}

	render() {
		return (
			<Dialog modal header="Dispositivo" visible={this.state.visible} style={{width: "650px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox label="Atuador" grid={4} appendTo={document.body} options={MEquipamento.Atuadores} name="atuador" onChange={this.handleChange} value={this.state.dispositivo.atuador} />
					<SelectLugar label="Destino" grid={8} name="destino" onChange={this.handleChange} value={this.state.dispositivo.destino} />
					<TextField label="Tempo de Acionamento" unit="ms" grid={4} name="acionamento" type="number" onChange={this.handleChange} value={this.state.dispositivo.acionamento} />
					<TextField label="Tempo de Anti-Carona" unit="s" grid={4} name="antidupla" type="number" onChange={this.handleChange} value={this.state.dispositivo.antidupla} />
					<Combobox label="Sensorização" grid={4} appendTo={document.body} options={MDispositivo.Sensorizacoes} name="sensorizacao" onChange={this.handleChange} value={this.state.dispositivo.sensorizacao} />
					<MultiCombobox grid={12} label="Gatilhos" appendTo={document.body} options={MLogAcesso.Gatilhos} name="gatilhos" onChange={this.handleChange} value={this.state.dispositivo.gatilhos} />
					<Chips grid={12} name="sequencia" value={this.state.dispositivo.sequencia} label="Sequência de Autenticação" onChange={this.handleChange}/>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.salvarDispositivo} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarDispositivo} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

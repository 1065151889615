import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {Progressbar} from "../components/Progressbar";
import {FailedToFetch} from "./FailedToFetch";
import {ErrorDialog} from "./Request";

export class Fetch {

	static async HandleError(error) {
		Progressbar.setActive(false);
		if (error !== "Application") {
			ModalRealm.showDialog(<FailedToFetch />);
		}
		return Promise.reject();
	}

	static async HandleResponse(response) {
		switch (response.status) {
			case 401:
			case 403:
			case 410:
				window.location.reload();
				return response;
			case 500:
				return this.CallReloader(response);
			default:
				return response;
		}
	}

	static async Get(path, headers) {
		return await fetch(path, {method: "get", headers: headers || this.BuildHeaders()}).then(await this.HandleResponse).catch(await this.HandleError);
	}

	static async Delete(path) {
		return await fetch(path, {method: "delete", headers: this.BuildHeaders()}).then(await this.HandleResponse).catch(await this.HandleError);
	}

	static async Post(path, body = null) {
		return await fetch(path, {method: "post", headers: this.BuildHeaders(), body: JSON.stringify(body)}).then(await this.HandleResponse).catch(await this.HandleError);
	}

	static async PostFile(path, body = null) {
		return await fetch(path, {method: "post", headers: this.BuildHeadersFile(), body: body}).then(await this.HandleResponse).catch(await this.HandleError);
	}

	static BuildHeaders() {
		return {
			"Accept": "application/json",
			"Content-Type": "application/json",
			"Authorization": window.localStorage.getItem("safety-authorization-token") || "",
			"Schema-Name": window.localStorage.getItem("safety-schema-name") || ""
		};
	}

	static BuildHeadersText() {
		return {
			"Accept": "text/plain",
			"Content-Type": "application/json",
			"Authorization": window.localStorage.getItem("safety-authorization-token") || "",
			"Schema-Name": window.localStorage.getItem("safety-schema-name") || ""
		};
	}

	static BuildHeadersFile() {
		return {
			"Authorization": window.localStorage.getItem("safety-authorization-token") || "",
			"Schema-Name": window.localStorage.getItem("safety-schema-name") || ""
		};
	}

	static CallReloader(response) {
		ModalRealm.showDialog(<ErrorDialog key={Math.random()} response={response} />);
		return Promise.reject("Application");
	}

}

import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import {LocalTime} from "../../utils/DateUtils";
import {MEspaco} from "../../utils/models/MEspaco";
import {MPeriodo} from "../../utils/models/MPeriodo";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EditarPeriodo} from "../Periodo/EditarPeriodo";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EspacoService} from "../servicos/EspacoService";
import {PeriodoService} from "../servicos/PeriodoService";

export class EditarEspaco extends React.Component {

	state = {
		espaco: Object.assign(MEspaco.Modelo(), this.props.espaco),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	espacoService = new EspacoService();
	periodoService = new PeriodoService();
	security = UserData.security("ESP", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarEspaco = () => {
		let espaco = MEspaco.cleanupBeforeSave(Object.assign({}, this.state.espaco));
		let messages = MEspaco.validarParaSalvar(espaco);
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
		} else {
			this.setState({lockSave: true});
			this.espacoService.salvar(espaco).then((espaco) => {
				this.setState({lockSave: false});
				if (this.props.onModalClose) {
					this.props.onModalClose(espaco);
				}
				this.onClose();
			}).catch(() => this.setState({lockSave: false}));
		}
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleChange = (event) => {
		let espaco = this.state.espaco;
		espaco[event.name] = event.value;
		this.setState({espaco});
	}

	periodoColumns = [
		<Column style={{width: "*"}} key="diasSemana" field="diasSemana" header="Período" body={(rowData) => <div style={{whiteSpace: "nowrap"}}>{this.periodoService.simplificarDiasSemana(rowData) + ", das " + LocalTime.withoutSeconds(rowData.inicio) + " às " + LocalTime.withoutSeconds(rowData.fim)}</div>} />,
		<Column style={{width: "6em"}} key="feriados" field="feriados" header="Feriados" body={(rowData) => <div style={{textAlign: "center"}}>{rowData.feriados ? "Sim": "Não"}</div>} />,
		<Column style={{width: "6em"}} key="acoes" field="acoes" header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					<img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Período" onClick={(event) => this.editarPeriodo(rowData)} />
					<img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Período" onClick={(event) => this.excluirPeriodo(rowData)} />
				</div>
			);
		}} />
	];

	editarPeriodo = (periodo) => {
		if (periodo == null) periodo = MPeriodo.Modelo();
		periodo = Object.assign({}, periodo);
		ModalRealm.showDialog(<EditarPeriodo key={Math.random()} periodo={periodo} onSave={(periodo) => {
			let espaco = this.state.espaco;
			espaco.periodos = espaco.periodos.filter((p) => p.id !== periodo.id);
			espaco.periodos.push(periodo);
			this.setState({espaco});
		}} />);
	}

	excluirPeriodo = (rowData) => {
		let espaco = this.state.espaco;
		espaco.periodos = espaco.periodos.filter(p => p.id !== rowData.id);
		this.setState({espaco});
	}

	periodoFooter = <div style={{textAlign: "left"}}>
		<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarPeriodo(null)} />
	</div>;

	render() {
		return (
			<Dialog modal header="Espaço" visible={this.state.visible} style={{width: "600px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField label="Descrição" grid={12} required name="descricao" onChange={this.handleChange} value={this.state.espaco.descricao} />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Períodos">
							<PanelContent>
								<div className="ui-g-12">
									<DataTable emptyMessage="Nenhum período adicionado" value={this.state.espaco.periodos} children={this.periodoColumns} rows={10} paginator paginatorLeft={this.periodoFooter} />
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.espaco.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/espacos", this.state.espaco)} /> : null}
					<Action disabled={!(this.security.edit || (this.security.create && !this.state.espaco.id)) || this.state.lockSave} mode="post" label="Salvar" onClick={this.salvarEspaco} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarEspaco} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import {buildHeaders} from "../utils/Request";
import {Action} from "./commons/Action";
import {DialogContent} from "./commons/DialogContent";
import {DialogFooter} from "./commons/DialogFooter";
import {ModalRealm} from "./commons/ModalRealm";
import {PanelContent} from "./commons/PanelContent";
import {Shortcut} from "./commons/Shortcut";
import {TextField} from "./commons/TextField";
import {LoginService} from "./servicos/LoginService";
import {UsuarioService} from "./servicos/UsuarioService";

const basePath = process.env.REACT_APP_API_URL;
const safetyCloud = process.env.REACT_APP_CLOUD_ENABLED === "true";

export class Login extends React.Component {

	state = {
		login: "",
		senha: "",
		activeIndex: 0,
		capsLock: false,
		error: 0
	};

	loginService = new LoginService();
	usuarioService = new UsuarioService();

	UNSAFE_componentWillMount() {
		document.addEventListener("keydown", this.checkCapsLock);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.checkCapsLock);
	}

	componentDidMount() {
		document.body.classList.add("ui-login-body");
	}

	checkCapsLock = (event) => this.setState({capsLock: event.getModifierState && event.getModifierState("CapsLock")});

	handleUpdate = (event) => {
		this.setState({[event.target.name]: event.target.value});
	}

	handleUpdateLower = (event) => {
		this.setState({[event.target.name]: event.target.value.toLowerCase()});
	}

	doLogin = () => {
		this.setState({error: -1});
		const {login, senha, schema} = this.state;
		if (this.state.activeIndex === 0) {
			fetch(basePath + "/login", {method: "post", body: JSON.stringify({login, senha})}).then((response) => {
				switch (response.status / 100) {
					case 2:
						window.localStorage.setItem("safety-authorization-token", response.headers.get("Authorization") || "");
						window.localStorage.setItem("safety-schema-name", response.headers.get("Schema-Name") || "");
						window.location.reload();
						break;
					default:
						this.authError(1);
						break;
				}
				return response;
			}).catch(() => this.authError(2));
		} else {
			fetch(`${basePath}/public/registrar`, {method: "post", headers: {"Content-Type": "application/json"}, body: JSON.stringify({login, senha, schema})}).then(() => {
				this.loginService.login({login, senha}).then((response) => {
					switch (response.status) {
						case 200:
							fetch(`${basePath}/init`, {method: "post", headers: buildHeaders()}).then(() => {
								window.location.replace("/");
							});
							break;
						case 400:
							console.log("Parâmetros não preenchidos");
							break;
						case 409:
							console.log("Schema já registrado");
							break;
						default:
							break;
					}
				});
			});
		}
	}

	authError = (error) => {
		this.setState({error});
		this.loginTimeout = setTimeout(() => {
			this.setState({error: 0});
		}, 500);
	}

	render() {
		let panelLogin = [
			<TextField maxLength={64} type="login" key={0} label="Login" grid={12} autofocus value={this.state.login} onChange={this.handleUpdateLower} name="login" />,
			<TextField autocomplete="new-password" key={1} label="Senha" grid={12} value={this.state.senha} onChange={this.handleUpdate} type="password" name="senha" />,
			(this.state.capsLock ? <div style={{color: "#F44336"}} className="ui-g-12">A tecla Caps Lock está ligada</div> : null)
		];
		let panelCadastro = [
			<TextField maxLength={64} type="login" key={0} label="Login" grid={12} value={this.state.login} onChange={this.handleUpdate} name="login" />,
			<TextField key={1} label="Senha" grid={12} value={this.state.senha} onChange={this.handleUpdate} type="password" name="senha" />,
			<TextField key={2} label="Condomínio" grid={12} value={this.state.schema} onChange={this.handleUpdate} name="schema" />
		];
		let content;
		if (safetyCloud) {
			content = (
				<TabView className="ui-g-12 ui-g-nopad" onTabChange={(e) => this.setState({activeIndex: e.index})} activeIndex={this.state.activeIndex}>
					<TabPanel header="Já Tenho Cadastro"><PanelContent>{panelLogin}</PanelContent></TabPanel>
					<TabPanel header="Quero me Cadastrar"><PanelContent>{panelCadastro}</PanelContent></TabPanel>
				</TabView>
			);
		} else {
			content = panelLogin;
		}
		return (
			<div>
				<Dialog className={this.state.error > 0 ? "login-shake" : ""} header="Safety" draggable={false} resizable={false} closable={false} visible style={{width: "400px"}}>
					<DialogContent>{content}</DialogContent>
					<DialogFooter>
						{this.state.error === 1 ? <div className="login-error">Usuário ou senha incorretos</div> : null}
						{this.state.error === 2 ? <div className="login-error">Falha ao comunicar com o servidor</div> : null}
						<Action mode="post" disabled={this.state.error} icon="fa-sign-in-alt" onClick={this.doLogin} label={this.state.activeIndex === 0 ? "Entrar": "Registrar"} />
					</DialogFooter>
				</Dialog>
				<Shortcut active onEnter={this.doLogin} />
				<ModalRealm />
			</div>
		);
	}

}

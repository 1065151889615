import React from "react";
import {Label} from "./Label";

export class ColorPanel extends React.Component {

	constructor() {
		super();
		this.state = {
			height: 16
		};
	}

	componentDidMount() {
		this.setState({height: this.first.clientWidth});
	}

	changeColor = (value) => {
		if (this.props.onChange) this.props.onChange({name: this.props.name, value});
	}
	
	refFirst = (el) => this.first = el;

	render() {
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label>{this.props.label}</Label>
				<div className="ui-g">
					<div ref={this.refFirst} onClick={() => this.changeColor("#EF9A9A")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#EF9A9A" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#EF9A9A"}} />
					<div onClick={() => this.changeColor("#F48FB1")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#F48FB1" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#F48FB1"}} />
					<div onClick={() => this.changeColor("#CE93D8")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#CE93D8" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#CE93D8"}} />
					<div onClick={() => this.changeColor("#B39DDB")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#B39DDB" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#B39DDB"}} />
					<div onClick={() => this.changeColor("#9FA8DA")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#9FA8DA" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#9FA8DA"}} />
					<div onClick={() => this.changeColor("#90CAF9")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#90CAF9" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#90CAF9"}} />
					<div onClick={() => this.changeColor("#81D4FA")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#81D4FA" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#81D4FA"}} />
					<div onClick={() => this.changeColor("#80DEEA")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#80DEEA" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#80DEEA"}} />
					<div onClick={() => this.changeColor("#80CBC4")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#80CBC4" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#80CBC4"}} />
					<div onClick={() => this.changeColor("#A5D6A7")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#A5D6A7" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#A5D6A7"}} />
					<div onClick={() => this.changeColor("#C5E1A5")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#C5E1A5" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#C5E1A5"}} />
					<div onClick={() => this.changeColor("#E6EE9C")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#E6EE9C" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#E6EE9C"}} />
					<div onClick={() => this.changeColor("#FFF59D")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFF59D" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFF59D"}} />
					<div onClick={() => this.changeColor("#FFE082")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFE082" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFE082"}} />
					<div onClick={() => this.changeColor("#FFCC80")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFCC80" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFCC80"}} />
					<div onClick={() => this.changeColor("#FFAB91")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFAB91" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFAB91"}} />
					<div onClick={() => this.changeColor("#BCAAA4")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#BCAAA4" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#BCAAA4"}} />
					<div onClick={() => this.changeColor("#EEEEEE")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#EEEEEE" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#EEEEEE"}} />
					<div onClick={() => this.changeColor("#B0BEC5")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#B0BEC5" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#B0BEC5"}} />
					<div onClick={() => this.changeColor("#FFFFFF")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFFFFF" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFFFFF"}} />
					<div onClick={() => this.changeColor("#F44336")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#F44336" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#F44336"}} />
					<div onClick={() => this.changeColor("#E91E63")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#E91E63" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#E91E63"}} />
					<div onClick={() => this.changeColor("#9C27B0")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#9C27B0" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#9C27B0"}} />
					<div onClick={() => this.changeColor("#673AB7")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#673AB7" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#673AB7"}} />
					<div onClick={() => this.changeColor("#3F51B5")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#3F51B5" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#3F51B5"}} />
					<div onClick={() => this.changeColor("#2196F3")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#2196F3" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#2196F3"}} />
					<div onClick={() => this.changeColor("#03A9F4")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#03A9F4" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#03A9F4"}} />
					<div onClick={() => this.changeColor("#00BCD4")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#00BCD4" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#00BCD4"}} />
					<div onClick={() => this.changeColor("#009688")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#009688" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#009688"}} />
					<div onClick={() => this.changeColor("#4CAF50")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#4CAF50" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#4CAF50"}} />
					<div onClick={() => this.changeColor("#8BC34A")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#8BC34A" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#8BC34A"}} />
					<div onClick={() => this.changeColor("#CDDC39")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#CDDC39" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#CDDC39"}} />
					<div onClick={() => this.changeColor("#FFEB3B")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFEB3B" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFEB3B"}} />
					<div onClick={() => this.changeColor("#FFC107")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FFC107" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FFC107"}} />
					<div onClick={() => this.changeColor("#FF9800")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FF9800" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FF9800"}} />
					<div onClick={() => this.changeColor("#FF5722")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FF5722" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FF5722"}} />
					<div onClick={() => this.changeColor("#795548")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#795548" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#795548"}} />
					<div onClick={() => this.changeColor("#9E9E9E")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#9E9E9E" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#9E9E9E"}} />
					<div onClick={() => this.changeColor("#607D8B")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#607D8B" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#607D8B"}} />
					<div onClick={() => this.changeColor("#DDDDDD")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#DDDDDD" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#DDDDDD"}} />
					<div onClick={() => this.changeColor("#B71C1C")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#B71C1C" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#B71C1C"}} />
					<div onClick={() => this.changeColor("#880E4F")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#880E4F" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#880E4F"}} />
					<div onClick={() => this.changeColor("#4A148C")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#4A148C" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#4A148C"}} />
					<div onClick={() => this.changeColor("#311B92")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#311B92" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#311B92"}} />
					<div onClick={() => this.changeColor("#1A237E")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#1A237E" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#1A237E"}} />
					<div onClick={() => this.changeColor("#0D47A1")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#0D47A1" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#0D47A1"}} />
					<div onClick={() => this.changeColor("#01579B")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#01579B" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#01579B"}} />
					<div onClick={() => this.changeColor("#006064")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#006064" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#006064"}} />
					<div onClick={() => this.changeColor("#004D40")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#004D40" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#004D40"}} />
					<div onClick={() => this.changeColor("#1B5E20")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#1B5E20" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#1B5E20"}} />
					<div onClick={() => this.changeColor("#33691E")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#33691E" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#33691E"}} />
					<div onClick={() => this.changeColor("#827717")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#827717" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#827717"}} />
					<div onClick={() => this.changeColor("#F57F17")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#F57F17" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#F57F17"}} />
					<div onClick={() => this.changeColor("#FF6F00")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#FF6F00" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#FF6F00"}} />
					<div onClick={() => this.changeColor("#E65100")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#E65100" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#E65100"}} />
					<div onClick={() => this.changeColor("#BF360C")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#BF360C" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#BF360C"}} />
					<div onClick={() => this.changeColor("#3E2723")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#3E2723" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#3E2723"}} />
					<div onClick={() => this.changeColor("#212121")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#212121" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#212121"}} />
					<div onClick={() => this.changeColor("#263238")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#263238" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#263238"}} />
					<div onClick={() => this.changeColor("#000000")} className={`${this.props.effect || ""} ui-colorpanel-item${this.props.value === "#000000" ? " ui-colorpanel-item-selected" : ""}`} style={{height: `${this.state.height}px`, backgroundColor: "#000000"}} />
				</div>
			</div>
		);
	}

}

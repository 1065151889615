import {httpGet, httpPost} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class MailService extends EntidadeNextGenService {

  constructor() {
		super("/mailer");
  }
  
  listarEmails(params) {
    return httpGet(`/mailer/emails?${params}`);
  }

  enviar(email) {
    return httpPost(`/mailer/enviar`, email);
  }

  retry(logEmail) {
    return httpPost(`/mailer/retry`, logEmail);
  }

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {MOperador} from "../../utils/models/MOperador";
import {MUsuario} from "../../utils/models/MUsuario";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {OperadorService} from "../servicos/OperadorService";
import {UsuarioService} from "../servicos/UsuarioService";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MultiSelectPerfil} from "../../select/MultiSelectPerfil";
import {MultiSelectSchema} from "../../select/MultiSelectSchema";
import {PasswordScorer} from "../commons/PasswordScorer";
import {scorePassword} from "../../utils/passwordUtils";
import {SetupSistemaService} from "../servicos/SetupSistemaService";

export class EditarUsuario extends React.Component {

	state = {
		usuario: Object.assign(MUsuario.Modelo(), this.props.usuario),
		operador: Object.assign(MOperador.Modelo(), this.props.operador),
		visible: true,
		lockSchemas: UserData.usuario.login !== "admin",
		lockSave: false
	};

	security = UserData.security("USR", true);
	usuarioService = new UsuarioService();
	operadorService = new OperadorService();
	setupSistemaService = new SetupSistemaService();

	async componentDidMount() {
		this.setState({setupSistema: await this.setupSistemaService.buscar()});
	}

	onClose = () => this.setState({visible: false});

	salvarUsuario = () => {
		if (this.security.edit) {
			const usuario = MUsuario.cleanupBeforeSave(Object.assign({}, this.state.usuario));
			const operador = MOperador.cleanupBeforeSave(Object.assign({}, this.state.operador));
			const messages = [
				...MUsuario.validarParaSalvar(usuario),
				...MOperador.validarParaSalvar(operador)
			];
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (usuario.senha1) usuario.senha = usuario.senha1;
				if (!usuario.schema) usuario.schema = usuario.schemas[0];
				const score = scorePassword(usuario.senha).score;
				switch (score) {
					case 0:
					case 5:
						this.handleSalvar(usuario, operador);
						break;
					case 4:
						ModalRealm.showConfirmacao("Esta senha não é segura o suficiente. Deseja continuar mesmo assim?", () => {
							this.handleSalvar(usuario, operador);
						});
						break;
					default:
						if (this.state.setupSistema.scoreMinimoSenha > score) {
							ModalRealm.showInformacao("warn", ["Senha não atinge os requisitos mínimos de segurança."]);
						} else {
							ModalRealm.showConfirmacao("Esta senha não é segura o suficiente. Deseja continuar mesmo assim?", () => {
								this.handleSalvar(usuario, operador);
							});
						}
						break;
				}
			}
		}
	}

	handleSalvar = (usuario, operador) => {
		this.setState({lockSave: true}, () => {
			this.usuarioService.salvar(usuario).then(usuario => {
				operador.usuario = usuario;
				this.operadorService.salvar(operador).then(() => {
					this.setState({lockSave: false}, () => {
						if (this.props.onModalClose) {
							this.props.onModalClose(usuario);
						}
						this.onClose();
					});
				}).catch(() => this.setState({lockSave: false}));
			}).catch(() => this.setState({lockSave: false}));
		});
	}


	handleChange = (event) => {
		const {usuario} = this.state;
		usuario[event.name] = event.value;
		this.setState({usuario});
	}

	handleChangeOperador = (event) => {
		const {operador} = this.state;
		operador[event.name] = event.value;
		this.setState({operador});
	}

	render() {
		return (
			<Dialog modal header="Usuário" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField disabled={this.state.usuario.id} label="Login (nome de usuário)" grid={7} maxLength={64} type="login" required={!this.state.usuario.id} name="login" onChange={this.handleChange} value={this.state.usuario.login?.toLowerCase()}/>
					<TextField grid={2} unit="min" label="Timeout" help="Tempo (em minutos) até o usuário ser desconectado automaticamente por inatividade" type="numeric" name="timeout" onChange={this.handleChange} value={this.state.usuario.timeout}/>
					<TextField grid={3} unit="dias" label="Validade da Senha" help="Intervalo máximo para troca de senha" type="numeric" name="diasTrocaSenha" onChange={this.handleChange} value={this.state.usuario.diasTrocaSenha}/>
					<TextField autocomplete="off" label="Senha" grid={6} required={!this.state.usuario.id} placeholder={this.state.usuario.id ? "********" : ""} type="password" name="senha1" onChange={this.handleChange} value={this.state.usuario.senha1}/>
					<TextField autocomplete="off" label="Confirmação de senha" grid={6} required={!this.state.usuario.id} placeholder={this.state.usuario.id ? "********" : ""} type="password" name="senha2" onChange={this.handleChange} value={this.state.usuario.senha2}/>
					<PasswordScorer username={this.state.usuario.login} password={this.state.usuario.senha1} grid={6}/>
					<PasswordScorer username={this.state.usuario.login} password={this.state.usuario.senha2} grid={6}/>
					<SelectPessoaFisica label="Pessoa" grid={12} name="pessoa" onChange={this.handleChangeOperador} value={this.state.operador.pessoa}/>
					<MultiSelectEtiqueta name="etiquetas" grid={12} value={this.state.operador.etiquetas} onSelect={this.handleChangeOperador}/>
					<MultiSelectPerfil name="perfis" grid={12} onSelect={this.handleChangeOperador} value={this.state.operador.perfis}/>
					{this.state.lockSchemas ? null : <MultiSelectSchema grid={12} name="schemas" value={this.state.usuario.schemas} onSelect={this.handleChange}/>}
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.usuario.id)) || this.state.lockSave} onClick={this.salvarUsuario} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarUsuario} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "../components/commons/Action";
import {DialogContent} from "../components/commons/DialogContent";
import {DialogFooter} from "../components/commons/DialogFooter";
import {ModalRealm} from "../components/commons/ModalRealm";
import {Shortcut} from "../components/commons/Shortcut";
import {Progressbar} from "../components/Progressbar";
import {Constraints} from "./Constraints";
import {FailedToFetch} from "./FailedToFetch";

const basePath = process.env.REACT_APP_API_URL;

function handleError(error) {
	Progressbar.setActive(false);
	if (error !== "Application") {
		ModalRealm.showDialog(<FailedToFetch/>);
	}
	return Promise.reject();
}

export function buildHeaders() {
	return {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"Authorization": window.localStorage.getItem("safety-authorization-token") || "",
		"Schema-Name": window.localStorage.getItem("safety-schema-name") || ""
	};
}


export async function httpGet(path, params = null, options = {progress: true}) {
	let pathVars = params != null ? "?" + Object.keys(params).filter((key) => params[key]?.toString().length > 0).map((key, index) => `${key}=${params[key]}`).join("&") : "";
	Progressbar.setActive(options.progress);
	return await fetch(basePath + path + pathVars, {method: "get", headers: buildHeaders()}).then(async (response) => {
		Progressbar.setActive(false);
		switch (response.status) {
			case 401:
			case 403:
			case 410:
				window.location.reload();
				return response;
			case 500:
				return callReloader(response);
			default:
				return await response.json();
		}
	}).catch(handleError);
}

export async function httpPost(path, body = null) {
	Progressbar.setActive(true);
	return await fetch(basePath + path, {method: "post", body: JSON.stringify(body), headers: buildHeaders()}).then(async (response) => {
		Progressbar.setActive(false);
		switch (response.status) {
			case 401:
			case 403:
			case 410:
				window.location.reload();
				return response;
			case 500:
				return await callReloader(response);
			default:
				return await response.json();
		}
	}).catch(handleError);
}

export function httpDelete(path, params = null) {
	Progressbar.setActive(true);
	let pathVars = params != null ? "?" + Object.keys(params).map((key, index) => `${key}=${params[key]}`).join("&") : "";
	return fetch(basePath + path + pathVars, {method: "delete", headers: buildHeaders()}).then((response) => {
		Progressbar.setActive(false);
		switch (response.status) {
			case 401:
			case 403:
			case 410:
				window.location.reload();
				return response;
			case 500:
				return callReloader(response);
			default:
				return true;
		}
	}).catch(handleError);
}

export function httpWipe(path, params = null) {
	Progressbar.setActive(true);
	let pathVars = params != null ? "?" + Object.keys(params).map((key, index) => `${key}=${params[key]}`).join("&") : "";
	return fetch(basePath + path + pathVars, {method: "delete", headers: buildHeaders()}).then((response) => {
		Progressbar.setActive(false);
		switch (response.status) {
			case 401:
			case 403:
			case 410:
				window.location.reload();
				return response;
			case 500:
				return callReloader(response);
			default:
				return true;
		}
	}).catch(handleError);
}

async function callReloader(response) {
	ModalRealm.showDialog(<ErrorDialog key={Math.random()} response={response} />);
	return Promise.reject("Application");
}

export class ErrorDialog extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true,
			key: Math.random(),
			error: {},
			mode: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.props.response.text().then((data) => {
			const error = JSON.parse(data);
			let mode = 0;
			if (error.message.includes("ConstraintViolationException")) {
				let constraint = /constraint (\[[a-zA-Z_]+]);/g.exec(error.message)?.[1].toUpperCase().replace(/[^a-zA-Z_]/g, "");
				if (constraint) {
					if (constraint.endsWith("_DESCRICAO")) {
						constraint = "descrição";
					} else if (constraint.endsWith("_CHAVE_INTEGRACAO")) {
						constraint = "chave de integração";
					} else {
						constraint = Constraints[constraint] || "documento";
					}
					error.message = `Erro de duplicidade: existe outro registro com ${constraint} igual ao deste.`;
				}
				mode = 1;
			}
			if (error.message.includes("JPA EntityManager")) {
				error.message = (
					<div>
						O serviço de banco de dados da aplicação está indisponível.<br/>
						Por favor, contate a equipe responsável ou tente novamente mais tarde.
					</div>
				);
				mode = 1;
			}
			this.setState({error, mode});
		});
	}

	onClose = () => {
		this.setState({visible: false});
	}

	errorTitle(status) {
		switch (status) {
			case 400:
				return "Erro de Aplicação da Interface de Usuário";
			case 401:
			case 403:
			case 410:
				return "Usuário não autenticado";
			case 404:
				return "Recurso Indisponível";
			case 500:
				return "Erro Interno da Aplicação";
			case 503:
				return "Aplicação Indisponível ou em Manutenção";
			default:
				return "Tipo de Erro Desconhecido";
		}
	}

	render() {
		return (
			<Dialog header="Erro" key={this.state.key} modal style={{width: "650px"}} visible={this.state.visible} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-error-header">
						<div className="ui-error-header-status">{this.state.error.status}</div>
						<div className="ui-error-header-title">{this.state.error.error}</div>
					</div>
					{
						this.state.mode === 0 ? (
							<div>
								<div className="ui-error-message">
									Houve um erro ao processar sua última requisição. Isto pode significar que:<br />
									<ul>
										<li>O servidor está indisponível ou em manutenção.</li>
										<li>A aplicação de interface está com problemas para processar as informações.</li>
										<li>Você encontrou um erro na aplicação do sistema.</li>
									</ul>
									A mensagem de erro abaixo pode nos ajudar a encontrar o problema:
								</div>
								<div className="ui-error-console">
									{this.state.error.message}
								</div>
							</div>
						) : (
							<div className="ui-handled-message">
								{this.state.error.message}
							</div>
						)
					}
				</DialogContent>
				<DialogFooter>
					<Action icon="fa-check" label="OK" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

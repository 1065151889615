import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import "./Credencial.css";

export class WiegandHelp extends Component {

    state = {
        visible: true,
        key: Math.random()
    };

    onClose = () => {
        this.setState({visible: false});
    }

    render() {
        return (
            <Dialog style={{width: "740px"}} modal visible={this.state.visible} header="Ajuda com cadastro Wiegand" onHide={this.onClose}>
                <DialogContent>
                    <div className="ui-g-4 cartao-wiegand">
                        <div className="legenda">Decimal&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wiegand</div>
                        <div className="decimal">0012345678&nbsp;&nbsp;&nbsp;123,45678</div>
                    </div>
                    <div className="ui-g-8">
                        <div style={{padding: "0 0 0 1em"}}>
                            Alguns cartões possuem seu código impresso no canto inferior direito, como no exemplo ao lado.<br/>
                            No exemplo, há dois campos: um mais à esquerda que representa o código Decimal do cartão; outro à direita, representando o conjunto Wiegand (Facility Code e Card ID), separados por vírgula.<br/>
                            Para cadastrar esses valores no Safety, basta copiar esses números nos campos respectivos na tela de cadastro, sem vírgulas ou zeros à esquerda.
                        </div>
                    </div>
                </DialogContent>
                <DialogFooter>
                    <Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
                </DialogFooter>
                {this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
            </Dialog>
        );
    }

}

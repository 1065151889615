import React from "react";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Placa} from "../Veiculo/PlacaMercosul";

export class SelecionarVeiculo extends React.Component {

    state = {
        visible: true
    };

    onClose = () => {
        this.setState({visible: false});
    }

    handleSelect = event => {
        if (this.props.onModalClose) {
            this.props.onModalClose(event.data);
        }
        this.onClose();
    }

    render() {
        return (
            <Dialog modal header="Selecionar Veículo" visible={this.state.visible} style={{width: "450px"}} onHide={this.onClose}>
                <DialogContent>
                    <DataTable style={{cursor: "pointer"}} onRowClick={this.handleSelect} value={this.props.veiculos}>
                        <Column style={{width: "7.5em", textAlign: "center"}} header="Placa" field="placa" body={v => <Placa veiculo={v}/>}/>
                        <Column header="Modelo" field="modelo"/>
                        <Column header="Fabricante" body={v => v.fabricante?.nome}/>
                    </DataTable>
                </DialogContent>
                <DialogFooter>
                    <Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
                </DialogFooter>
                {this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
            </Dialog>
        );
    }

}

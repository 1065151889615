import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MCamera} from "../../utils/models/MCamera";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CameraService} from "../servicos/CameraService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {SelectControlador} from "../../select/SelectControlador";

export class EditarCamera extends React.Component {

	state = {
		camera: Object.assign(MCamera.Modelo(), this.props.camera),
		visible: true
	};

	cameraService = new CameraService();
	security = UserData.security("CAM", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarCamera = () => {
		if (this.security.edit) {
			let camera = MCamera.cleanupBeforeSave(Object.assign({}, this.state.camera));
			let messages = MCamera.validarParaSalvar(camera);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.cameraService.salvar(camera).then((camera) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(camera);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let camera = this.state.camera;
		camera[event.name] = event.value;
		this.setState({camera});
	}

	render() {
		return (
			<Dialog modal header="Câmera" visible={this.state.visible} style={{width: "700px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={12} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.camera.descricao}/>
					<TextField grid={6} label="Endereço" required name="ip" onChange={this.handleChange} value={this.state.camera.ip}/>
					<SelectControlador grid={6} name="controlador" value={this.state.camera.controlador} onChange={this.handleChange}/>
					<Combobox grid={4} label="Formato" appendTo={document.body} name="formato" options={MCamera.Formatos} onChange={this.handleChange} value={this.state.camera.formato}/>
					<TextField grid={4} label="Login" name="login" onChange={this.handleChange} value={this.state.camera.login}/>
					<TextField type="password" grid={4} label="Senha" name="senha" onChange={this.handleChange} value={this.state.camera.senha}/>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.camera.id ?
						<Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/cameras", this.state.camera)}/> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.camera.id)) || this.state.lockSave} onClick={this.salvarCamera} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarCamera} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

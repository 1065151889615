import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeJustificar from "../../media/icones/justificar.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectCredencial} from "../../select/SelectCredencial";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {GUI} from "../../utils/Constants";
import {LocalDate} from "../../utils/DateUtils";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {OcorrenciaService} from "../servicos/OcorrenciaService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {httpGet} from "../../utils/Request";

export class Ocorrencia extends React.Component {

	securityMonitor = UserData.security("MNA", false);

	state = {
		inicio: LocalDate.now(),
		fim: null,
		firstResult: 0,
		gestor: null,
		lockGestor: false
	};

	security = UserData.security("OCO");
	ocorrenciaService = new OcorrenciaService();
	pessoaFisicaService = new PessoaFisicaService();

	componentDidMount() {
		const gestor = UserData.operador?.pessoa;
		if (gestor?.id) {
			httpGet(`/pessoas-fisicas?gestor=id:${gestor.id}&page=0&size=1`).then(subordinados => {
				if (subordinados?.length) {
					this.setState({gestor, lockGestor: true});
				}
			});
		}
	}

	listarOcorrencias = () => {
		if (this.queryTimeout) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let params = [];
			if (this.state.evento) params.push(`evento=${this.state.evento}`);
			if (this.state.inicio) params.push(`inicio=${this.state.inicio}`);
			if (this.state.fim) params.push(`fim=${this.state.fim}`);
			if (this.state.pessoa && this.state.pessoa.id) params.push(`pessoa=${this.state.pessoa.id}`);
			if (this.state.gestor) params.push(`gestor=${this.state.gestor.id}`);
			if (this.state.credencial) params.push(`credencial=${this.state.credencial.id}`);
			if (this.state.etiquetas?.length) params.push(`etiquetas=${this.state.etiquetas.map(e => e.id).join(",")}`);
			httpGet(`/ocorrencias/alt?${params.join("&")}&page=0&size=100`).then((ocorrencias) => this.setState({ocorrencias, firstResult: 0}));
		}, 50);
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column header="Data" style={{width: "8em", textAlign: "center"}} body={(rowData) => rowData.data ? moment(rowData.data).format("DD/MM/YYYY HH:mm:ss") : null} />,
		<Column header="Pessoa"  style={{width: "20em"}} body={(rowData) => <div>{rowData.pessoa ? rowData.pessoa.nome : ""}</div>} />,
		<Column header="Etiquetas"  style={{width: "*", textAlign: "center"}} body={(rowData) => rowData.etiquetas ? rowData.etiquetas.map((e) => <EtiquetaChip etiqueta={e} />) : null} />,
		<Column header="Evento" style={{width: "20em"}} body={(rowData) => (
			<div>
				<div>{MOcorrencia.MapEventos[rowData.evento]}</div>
				<div>{rowData.responsavel ? `Responsável: ${rowData.responsavel.login}` : null}</div>
			</div>
		)} />,
		<Column header="Justificativa para Finalização" style={{width: "*"}} field="justificativa" />,
		<Column header="Ações" style={{width: "5em", textAlign: "center"}} body={(ocorrencia) => {
			if (this.security.edit) {
				return <img alt="" className="ui-action-icon" src={iconeJustificar} title="Histórico" onClick={() => {
					this.ocorrenciaService.buscar(ocorrencia).then((ocorrencia) => EntidadeNextGenService.listarHistorico("/ocorrencias", ocorrencia));
				}} />
			} else return null;
		}} />
	];

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	eventos = [{label: "Todos", value: null}, ...MOcorrencia.Eventos];

	render() {
		return (
			<div>
				<Panel header="Ocorrências">
					<PanelContent>
						<DatePicker grid={2} label="Período" type="date" value={this.state.inicio} onChange={this.handleChange} name="inicio" />
						<DatePicker grid={2} label="&nbsp;" type="date" value={this.state.fim} onChange={this.handleChange} name="fim" />
						<SelectPessoaFisica placeholder="Pesquise por nome, RG, CPF ou outro documento" grid={5} label="Pessoa" name="pessoa" value={this.state.pessoa} onChange={this.handleChange} />
						<Combobox grid={3} label="Evento" value={this.state.evento} onChange={this.handleChange} options={this.eventos} name="evento" />
						<SelectCredencial placeholder="Pesquise pela descrição" label="Credencial" grid={3} name="credencial" value={this.state.credencial} onChange={this.handleChange} />
						<MultiSelectEtiqueta grid={5} label="Etiquetas" name="etiquetas" value={this.state.etiquetas} onChange={this.handleChange} />
						<SelectPessoaFisica placeholder="Pesquise por nome, RG, CPF ou outro documento" disabled={this.state.lockGestor} grid={4} label="Gestor" name="gestor" value={this.state.gestor} onChange={this.handleChange} />
					</PanelContent>
					<PanelFooter>
						<Action label="Procurar" icon="fa-search" onClick={this.listarOcorrencias} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.ocorrencias} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<div className="ui-tzm-floatbutton-margin" />
				<Shortcut onEnter={this.listarOcorrencias}/>
				<ModalRealm/>
			</div>
		);
	}

}

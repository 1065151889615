export class MPeriodo {

	static Modelo() {
		return {
			inicio: "00:00",
			fim: "23:59",
			diasSemana: ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"],
			feriados: true
		};
	}

}

import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {Action} from "../commons/Action";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";

export class ConfirmarEnvioGeral extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes();
		}
		this.onClose();
	}

	onNo = () => {
		if (this.props.onNo) {
			this.props.onNo();
		}
		this.onClose();
	}

	onClose = () => {
		this.setState({visible: false});
	}

	enviar = (
		<div className="ui-g-12">
			Tem certeza de que deseja enviar <b>TODAS</b> as pessoas com acesso para os equipamentos selecionados?<br />
			Este procedimento pode levar horas e só é necessário para a primeira configuação do equipamento.
		</div>
	);

	reiniciar = (
		<div className="ui-g-12">
			Tem certeza de que deseja reiniciar os equipamentos selecionados?<br />
		</div>
	);

	digitais = (
		<div className="ui-g-12">
			Tem certeza de que deseja coletar as digitais dos equipamentos selecionados?<br />
		</div>
	);

	excluir = (
		<div className="ui-g-12">
			Tem certeza de que deseja excluir <b>TODAS</b> as pessoas dos equipamentos selecionados?<br />
			Este procedimento pode levar algum tempo e deixará o equipamento sem as informações necessárias para liberação de acesso.
		</div>
	);

	trocarSenha = (
		<div className="ui-g-12">
			Tem certeza de que deseja trocar a senha de administrador dos equipamentos selecionados?
		</div>
	);

	ativarModoEmergencia = (
		<div className="ui-g-12">
			Tem certeza de que deseja ativar o modo emergência dos equipamentos selecionados?
		</div>
	);

	desativarModoEmergencia = (
		<div className="ui-g-12">
			Tem certeza de que deseja desativar o modo emergência dos equipamentos selecionados?
		</div>
	);

	agenda = (
		<div className="ui-g-12">
			Esse processo exclui <b>TODAS</b> as pessoas dos equipamentos selecionados e envia novamente<br />
			Este procedimento pode levar algum tempo e deixará o equipamento sem as informações necessárias para liberação de acesso.
		</div>
	);

	onMode = () => {
		console.log("this.props.mode",this.props.mode);
		switch (this.props.mode) {
			case "enviar":
				return this.enviar;				
			case "excluir":
				return this.excluir;
			case "agenda":
				return this.agenda;
			case "reiniciar":
				return this.reiniciar;
			case "digitais":
				return this.digitais;
			case "trocarSenha":
				return this.trocarSenha;
			case "ativarModoEmergencia":
				return this.ativarModoEmergencia;
			case "desativarModoEmergencia":
				return this.desativarModoEmergencia;
			default:
				break;
		}
	}

	render() {
		return (
			<Dialog className="tzm-danger-dialog" closable={false} modal header={this.props.header != null ? this.props.header : "ATENÇÃO"} visible={this.state.visible} style={{with: "450px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g">
						{this.onMode()}
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="delete" label="Sim" icon="fa-check" onClick={this.onYes} />
					<Action mode="post" label="Não" icon="fa-times" onClick={this.onNo} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onS={this.onYes} onN={this.onNo} onEscape={this.onNo}/> : null}
			</Dialog>
		);
	}

}

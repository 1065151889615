import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarLocalidade} from "../components/Localidade/EditarLocalidade";
import {LocalidadeService} from "../components/servicos/LocalidadeService";
import {MLocalidade} from "../utils/models/MLocalidade";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectLocalidade extends React.Component {

	service = new LocalidadeService();

	security = UserData.security("LOC");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarLocalidade key={Math.random()} localidade={{...MLocalidade.Modelo(), nome: event.value.nome.replace(`Criar localidade `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select label={this.props.label || "Localidade"} entidade="localidade" insertable={this.security.create && this.props.insertable} service={this.service} field="nome" query="nome" onSelect={this.onSelect} {...this.props} />;
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {MMessengerBot} from "../../utils/models/MMessengerBot";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MessengerBotService} from "../servicos/MessengerBotService";
import {EditarMessengerBot} from "./EditarMessengerBot";

export class MessengerBot extends React.Component {

	state = {
		descricao: "",
		firstResult: 0
	};

	messengerBotService = new MessengerBotService();
	security = UserData.security("MBT", true);

	listarMessengerBots = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			this.messengerBotService.listar(`search=descricao~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((messengerBots) => this.setState({messengerBots, firstResult: 0}));
		}, 50);
	}

	restaurarMessengerBot = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este bot mensageiro?", () => {
			if (rowData != null && rowData.id != null) {
				this.messengerBotService.buscar(rowData).then((messengerBot) => {
					messengerBot.status = "ATIVADO";
					this.messengerBotService.salvar(messengerBot).then(this.listarMessengerBots);
				});
			}
		});
	}

	eliminarMessengerBot = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este bot mensageiro?", () => this.messengerBotService.eliminar(rowData).then((result) => {
			if (result) this.listarMessengerBots(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este bot mensageiro: há outros registros dependentes dele no sistema."]);
		}));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarMessengerBot = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.messengerBotService.buscar(rowData).then((messengerBot) => {
				ModalRealm.showDialog(<EditarMessengerBot key={Math.random()} messengerBot={messengerBot} onModalClose={this.listarMessengerBots} />);
			});
		} else {
			ModalRealm.showDialog(<EditarMessengerBot key={Math.random()} messengerBot={null} onModalClose={this.listarMessengerBots} />);
		}
	}

	excluirMessengerBot = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este bot mensageiro?", () => this.messengerBotService.excluir(rowData).then(this.listarMessengerBots));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} header="Descrição"  style={{width: "*"}} sortable field="descricao" />,
		<Column key={2} header="Tipo" style={{width: "15em"}} field="tipo" sortable body={(b) => MMessengerBot.MapTipos[b.tipo]} />,
		<Column key={3} header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Bot Mensageiro" onClick={() => this.editarMessengerBot(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Bot Mensageiro" onClick={() => this.excluirMessengerBot(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Bot Mensageiro" onClick={() => this.restaurarMessengerBot(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Bot Mensageiro" onClick={() => this.eliminarMessengerBot(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/messenger-bots", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Bots Mensageiros">
					<PanelContent>
						<TextField grid={12} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarMessengerBots} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.messengerBots} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Bot Mensageiro" icon="fa-plus" onClick={this.editarMessengerBot} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarMessengerBots}
					onCtrlN={() => window.location.pathname === "/messenger-bots" && this.security.create && this.editarMessengerBot()}
					onCtrlE={() => window.location.pathname === "/messenger-bots" && this.state.messengerBots.length === 1 && this.security.edit && this.editarMessengerBot(this.state.messengerBots[0])}
				/>
				<ModalRealm/>
			</div>
		);
	}

}

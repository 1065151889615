import {httpGet} from "../../utils/Request";
import {EntidadeNextGenService} from "./EntidadeNextGenService";

export class AvisoService extends EntidadeNextGenService {

	constructor() {
		super("/avisos");
	}

	async listarAlt(pessoas, etiquetas) {
		return await httpGet(this.basePath + "/alt", {pessoas, etiquetas});
	}

	listarLiberacoes(etiqueta, data) {
		return httpGet(this.basePath + "/liberacoes", {etiqueta, data});
	}

	async listarAvisosPortaria(pessoa) {
		return await httpGet(this.basePath + `/portaria/${pessoa}`);
	}

}

export class NumberUtils {

	static longToByteArray(long) {
		var byteArray = [0, 0, 0, 0];
		for (let index = 0; index < byteArray.length; ++index) {
			let byte = long & 0xFF;
			byteArray[index] = byte;
			long = (long - byte) / 256;
		}
		return byteArray;
	};

	static invertArray(byteArray) {
		let result = [];
		for (let i = byteArray.length - 1; i >= 0; --i) result.push(byteArray[i]);
		return result;
	}

	static toLittleEndian(cartao) {
		return this.byteArrayToLong(this.invertArray(this.longToByteArray(parseInt(cartao, 16))));
	}
	
	static byteArrayToLong(byteArray) {
		let value = 0;
		for (let i = byteArray.length - 1; i >= 0; i--) {
			value = (value * 256) + byteArray[i];
		}
		return value;
	}

	static intToByteArray(x) {
		let y= Math.floor(x/2**32);
		return [y,(y<<8),(y<<16),(y<<24), x,(x<<8),(x<<16),(x<<24)].map(z=> z>>>24)
	}

}

import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import React from "react";
import {Label} from "./Label";

export class MultiCombobox extends React.Component {

	change = (event) => {
		if (this.props.onChange) this.props.onChange({name: this.props.name, field: this.props.field, value: event.value});
	}

	render() {
		return (
			<div className={`ui-g-${this.props.grid}`}>
				<Label help={this.props.help}>{this.props.label}</Label>
				<MultiSelect selectedItemTemplate={this.itemTemplate} defaultLabel="Nenhuma opção selecionada" {...this.props} onChange={this.change}/>
			</div>
		);
	}

	itemTemplate = (data) => data.map(i => i[this.props.field]).join(", ");

}

import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {GUI} from "../../utils/Constants";
import {MJornadaTrabalho} from "../../utils/models/MJornadaTrabalho";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {JornadaTrabalhoService} from "../servicos/JornadaTrabalhoService";
import {EditarJornadaTrabalho} from "./EditarJornadaTrabalho";

export class JornadaTrabalho extends React.Component {

	state = {
		firstResult: 0,
		jornadasTrabalho: [],
		inicio: moment().format("YYYY-MM-DD"),
		fim: moment().format("YYYY-MM-DD"),
		modoEtiqueta: "TODAS"
	};
	
	jornadaTrabalhoService = new JornadaTrabalhoService();
	security = UserData.security("JTB", true);

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarJornadaTrabalho = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.jornadaTrabalhoService.buscar(rowData).then((jornadaTrabalho) => {
				ModalRealm.showDialog(<EditarJornadaTrabalho key={Math.random()} jornadaTrabalho={jornadaTrabalho} onModalClose={this.listarJornadasTrabalho} />);
			});
		} else {
			ModalRealm.showDialog(<EditarJornadaTrabalho key={Math.random()} jornadaTrabalho={null} onModalClose={this.listarJornadasTrabalho} />);
		}
	}

	excluirJornadaTrabalho = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta jornada de trabalho?", () => this.jornadaTrabalhoService.excluir(rowData).then(this.listarJornadasTrabalho));
	}

	restaurarJornadaTrabalho = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta jornada de trabalho?", () => {
			if (rowData != null && rowData.id != null) {
				this.jornadaTrabalhoService.buscar(rowData).then((jornadaTrabalho) => {
					jornadaTrabalho.status = "ATIVADO";
					this.jornadaTrabalhoService.salvar(jornadaTrabalho).then(this.listarJornadasTrabalho);
				});
			}
		});
	}

	eliminarJornadaTrabalho = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta jornada de trabalho?", () => this.jornadaTrabalhoService.eliminar(rowData).then((result) => {
			if (result) this.listarJornadasTrabalho(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta jornada de trabalho: há outros registros dependentes dele no sistema."]);
		}));
	}

	switchPontualidade = (lp) => {
		switch (lp.pontualidade) {
			case "PONTUAL": return "ok";
			case "ADIANTADO": return "positive";
			case "ATRASADO": return "negative";
			default: return null;
		}
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id ? e.id.toLocale() : "?"}</div>} sortable field="id" />,
		<Column key={0} header="Funcionário"  style={{width: "*"}} sortable field="funcionario.nome" />,
		<Column key={1} header="Data"  style={{width: "10em"}} body={(jt) => moment(jt.referencia).format("DD/MM/YYYY")} />,
		<Column key={2} header="Marcações" style={{width: "*"}} body={(jornada) => (
			jornada.logsPonto.map((lp, i) => (
				<div className={`jornada-marcacao ${this.switchPontualidade(lp)}${i > 1 && i % 2 === 0 ? " dot" : ""}`} key={lp.id}>
					{moment(lp.data).format("HH:mm")}
				</div>
			))
		)} />,
		<Column key={3} header="Saldo" style={{width: "10em"}} body={(jt) => {
			return (
				<div>
					<div className={`jornada-saldo s-${Math.sign(jt.saldo)}`}>
						<i className={`fal fa-${jt.saldo > 0 ? "plus" : jt.saldo < 0 ? "minus" : "tilde"}`} />
					</div>
					{moment("2000-01-01").add("minutes", Math.abs(jt.saldo)).format("HH:mm")}
				</div>
			);
		}} />,
		<Column key={4} header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Jornada de Trabalho" onClick={(event) => this.editarJornadaTrabalho(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Jornada de Trabalho" onClick={(event) => this.excluirJornadaTrabalho(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Jornada de Trabalho" onClick={(event) => this.restaurarJornadaTrabalho(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Jornada de Trabalho" onClick={(event) => this.eliminarJornadaTrabalho(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/jornadas-trabalho", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	listarJornadasTrabalho = () => {
		if (this.queryTimeout) clearTimeout(this.queryTimeout);
		const params = Object.assign({}, this.state);
		Object.keys(params).forEach(k => params[k] === "" && delete params[k]);
		this.queryTimeout = setTimeout(() => this.jornadaTrabalhoService.listarCriterio(params).then((jornadasTrabalho) => this.setState({jornadasTrabalho, firstResult: 0})), 50);
	}

	render() {
		return (
			<div>
				<Panel header="Jornadas de Trabalho">
					<PanelContent>
						<DatePicker grid={2} label="Início" name="inicio" onChange={this.handleUpdate} value={this.state.inicio} />
						<DatePicker grid={2} label="Fim" name="fim" onChange={this.handleUpdate} value={this.state.fim} />
						<SelectPessoaFisica label="Funcionário" grid={8} value={this.state.funcionario} name="funcionario" onChange={this.handleUpdate} />
						<MultiSelectEtiqueta grid={9} label="Etiquetas" onSelect={this.handleUpdate} name="etiquetas" value={this.state.etiquetas} />
						<Combobox label="&nbsp;" grid={3} value={this.state.modoEtiqueta} name="modoEtiqueta" onChange={this.handleUpdate} options={MJornadaTrabalho.ModosEtiqueta} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarJornadasTrabalho} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.jornadasTrabalho} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Jornada de Trabalho" icon="fa-plus" onClick={this.editarJornadaTrabalho} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarJornadasTrabalho}
					onCtrlN={() => window.location.pathname === "/jornadas-trabalho" && this.security.create && this.editarJornadaTrabalho()}
					onCtrlE={() => window.location.pathname === "/jornadas-trabalho" && this.state.jornadasTrabalho.length === 1 && this.security.edit && this.editarJornadaTrabalho(this.state.jornadasTrabalho[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeDepositar from "../../media/icones/tarifa-saldo.png";
import iconeTransferir from "../../media/icones/tarifa-transferencia.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {TarifaContaService} from "../servicos/TarifaContaService";
import {EditarTarifaConta} from "./EditarTarifaConta";
import {MTarifaConta} from "../../utils/models/MTarifaConta";
import {EditarTarifaSaldo} from "../TarifaSaldo/EditarTarifaSaldo";
import moment from "moment";
import {httpGet} from "../../utils/Request";
import {TarifaSaldoService} from "../servicos/TarifaSaldoService";
import {TransferirSaldo} from "../TarifaSaldo/TransferirSaldo";

export class TarifaConta extends React.Component {

	state = {
		descricao: "",
		firstResult: 0,
		tarifaContas: [],
		tarifaContasExpanded: []
	};

	tarifaContaService = new TarifaContaService();
	tarifaSaldoService = new TarifaSaldoService();
	security = UserData.security("TCT", true);
	securitySaldo = UserData.security("TSD", false);

	listarTarifaContas = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			if (!isNaN(this.state.descricao)) {
				this.tarifaContaService.listar(`search=id:${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {sort: "id", page: 0, size: GUI.maxResults}).then((tarifaContas) => this.setState({tarifaContas, firstResult: 0}));
			} else {
				this.tarifaContaService.listar(`pessoa=nome~${this.state.descricao}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {sort: "id", page: 0, size: GUI.maxResults}).then((tarifaContas) => this.setState({tarifaContas, firstResult: 0}));
			}
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarTarifaConta = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.tarifaContaService.buscar(rowData).then((tarifaConta) => {
				ModalRealm.showDialog(<EditarTarifaConta key={Math.random()} tarifaConta={tarifaConta} onModalClose={this.listarTarifaContas} />);
			});
		} else {
			ModalRealm.showDialog(<EditarTarifaConta key={Math.random()} tarifaConta={null} onModalClose={this.listarTarifaContas} />);
		}
	}

	excluirTarifaConta = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta conta de crédito?", () => this.tarifaContaService.excluir(rowData).then(this.listarTarifaContas));
	}

	detalhes = [
		<Column key={0} style={{width: "2.5em", textAlign: "center"}} expander />,
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key="pessoa" header="Pessoa" style={{width: "*"}} sortable field="pessoa.nome" body={(tc) => {
			return (
				<div>
					<div><b>{tc.pessoa.nome}</b></div>
					<div>{tc.pessoa.cpf ? `CPF: ${tc.pessoa.cpf.formatCpf()}` : tc.pessoa.cnpj ? `CNPJ: ${tc.pessoa.cnpj.formatCnpj()}` : ""}</div>
				</div>
			);
		}} />,
		<Column header="Modalidade" style={{width: "12em"}} sortable field="modalidade" body={(tc) => MTarifaConta.MapModalidade[tc.modalidade]} />,
		<Column header="Saldo" style={{width: "12em"}} sortable field="saldo" body={(tc) => <div style={{textAlign: "right"}}>{`Cr ${this.numberFormatDecimals.format(tc.saldo)}`}</div>} />,
		<Column header="Ações" style={{width: "9em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.securitySaldo.create && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeDepositar} title="Lançar Saldo em Conta de Crédito" onClick={() => this.criarTarifaSaldo(rowData)} /> : null}
					{this.securitySaldo.create && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeTransferir} title="Transferir Saldo para outra Conta de Crédito" onClick={() => this.transferirTarifaSaldo(rowData)} /> : null}
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Conta de Crédito" onClick={() => this.editarTarifaConta(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Conta de Crédito" onClick={() => this.excluirTarifaConta(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Conta de Crédito" onClick={() => this.restaurarTarifaConta(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Conta de Crédito" onClick={() => this.eliminarTarifaConta(rowData)} /> : null}
				</div>
			);
		}} />
	];

	transferirTarifaSaldo = (tarifaConta) => {
		ModalRealm.showDialog(<TransferirSaldo origem={tarifaConta} onModalClose={this.listarTarifaContas} />);
	}

	criarTarifaSaldo = (tarifaConta) => {
		ModalRealm.showDialog(<EditarTarifaSaldo key={Math.random()} tarifaSaldo={{conta: tarifaConta}} onModalClose={this.listarTarifaContas} />);
	}

	numberFormat = Intl.NumberFormat("pt-BR");

	numberFormatDecimals = Intl.NumberFormat("pt-BR", {minimumFractionDigits: 2});

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/tarifa-contas", entidade);
	}

	restaurarTarifaConta = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta conta de crédito?", () => {
			if (rowData != null && rowData.id != null) {
				this.tarifaContaService.buscar(rowData).then((tarifaContas) => {
					tarifaContas.status = "ATIVADO";
					this.tarifaContaService.salvar(tarifaContas).then(this.listarTarifaContas);
				});
			}
		});
	}

	eliminarTarifaConta = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta conta de crédito?", () => this.tarifaContaService.eliminar(rowData).then((result) => {
			if (result) this.listarTarifaContas(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta conta de crédito: há outros registros dependentes dela no sistema."]);
		}));
	}

	render() {
		return (
			<div>
				<Panel header="Contas de Crédito">
					<PanelContent>
						<TextField label="Pessoa" placeholder="Pesquise por nome do titular ou número da conta" grid={12} autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarTarifaContas} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable onRowExpand={this.expandSaldos} onRowToggle={this.expandTarifaConta} expandedRows={this.state.tarifaContasExpanded} rowExpansionTemplate={this.templateSaldos} first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.tarifaContas} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Conta de Crédito" icon="fa-plus" onClick={this.editarTarifaConta} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarTarifaContas}
					onCtrlN={() => window.location.pathname === "/tarifa-contas" && this.editarTarifaConta()}
					onCtrlE={() => window.location.pathname === "/tarifa-contas" && this.state.tarifaContas.length === 1 && this.editarTarifaConta(this.state.tarifaContas[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

	expandTarifaConta = (event) => this.setState({tarifaContasExpanded: event.data});

	expandSaldos = (event) => {
		httpGet(`/tarifa-saldos?search=status!PENDENTE&conta=id:${event.data.id}&page=0&size=100&sort=registro+desc`).then((_saldos) => {
			const {tarifaContas} = this.state;
			tarifaContas.filter(tc => tc.id === event.data.id).forEach(tc => tc._saldos = _saldos);
			this.setState({tarifaContas});
		});
	}

	templateSaldos = (tarifaConta) => {
		return (
			<DataTable paginator rowsPerPageOptions={GUI.rowsPerPage} rows={GUI.defaultRows} value={tarifaConta._saldos} emptyMessage="Nenhum registro encontrado">
				<Column style={{width: "8em"}} header="Nº" body={ts => <div style={{textAlign: "right"}}>{ts.id.toLocale()}</div>} />
				<Column style={{width: "12em", textAlign: "center"}} header="Data" body={(ts) => moment(ts.registro).format("DD/MM/YYYY HH:mm:ss")} />
				<Column style={{width: "18em"}} header="Responsável" body={(ts) => ts.responsavel ? ts.responsavel.login : ""} />
				<Column style={{width: "*"}} header="Observações" field="observacao" body={(ts) => {
					if (ts.observacao) {
						return ts.status === "ATIVADO" ? ts.observacao : <s>{ts.observacao}</s>;
					}
					if (ts.logAcesso) {
						const observacao = `Cobrança de crédito por passagem de ${ts.logAcesso.pessoa.nome} em ${ts.logAcesso.equipamento.descricao}`;
						return ts.status === "ATIVADO" ? observacao : <s>{observacao}</s>;
					}
				}} />
				<Column style={{width: "8em", textAlign: "right"}} header="Valor (Cr)" body={(ts) => <span style={{color: ts.valor > 0 ? "#4CAF50" : "#F44336"}}>{this.numberFormatDecimals.format(ts.valor)}</span>} />
				<Column style={{width: "5em", textAlign: "center"}} header="Ações" body={(ts) => {
					return (
						<div>
							{this.security.remove && ts.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistoricoSaldo(ts)} /> : null}
							{this.security.remove && ts.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Tarifa" onClick={() => this.restaurarTarifaSaldo(tarifaConta, ts)} /> : null}
							{this.securitySaldo.remove && ts.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Lançamento" onClick={() => this.excluirTarifaSaldo(tarifaConta, ts)} /> : null}
						</div>
					);
				}} />
			</DataTable>
		);
	}

	restaurarTarifaSaldo = (tarifaConta, tarifaSaldo) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este movimento?", () => {
			if (tarifaSaldo && tarifaSaldo.id) {
				this.tarifaSaldoService.buscar(tarifaSaldo).then((tarifaSaldo) => {
					tarifaSaldo.status = "ATIVADO";
					this.tarifaSaldoService.salvar(tarifaSaldo).then(() => {
						const {tarifaContas} = this.state;
						tarifaContas.filter(tc => tc.id === tarifaConta.id).forEach(tc => {
							tc.saldo += tarifaSaldo.valor;
							tc._saldos.filter(ts => ts.id === tarifaSaldo.id).forEach(ts => ts.status = "ATIVADO");
						});
						this.setState({tarifaContas});
					});
				});
			}
		});
	}

	visualizarHistoricoSaldo = (entidade) => {
		EntidadeNextGenService.listarHistorico("/tarifa-saldos", entidade);
	}

	excluirTarifaSaldo = (tarifaConta, tarifaSaldo) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta movimentação?", () => {
			this.tarifaSaldoService.excluir(tarifaSaldo).then(() => {
				const {tarifaContas} = this.state;
				tarifaContas.filter(tc => tc.id === tarifaConta.id).forEach(tc => {
					tc.saldo -= tarifaSaldo.valor;
					tc._saldos.filter(ts => ts.id === tarifaSaldo.id).forEach(ts => ts.status = "EXCLUIDO");
				});
				this.setState({tarifaContas});
			});
		});
	}

}

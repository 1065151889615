import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MCamera {

	static Modelo() {
		return {
			descricao: "",
			formato: "HTTP_SNAPSHOT"
		};
	}

	static Formatos = [
		{label: "Snapshot", value: "HTTP_SNAPSHOT"},
		{label: "Live Stream", value: "HTTP_LIVE_STREAM"},
	];

	static MapFormatos = {
		HTTP_SNAPSHOT: "Snapshot",
		HTTP_LIVE_STREAM: "Live Stream"
	}

	static cleanupBeforeSave(camera) {
		return MEntidade.cleanupBeforeSave(camera);
	}

	static validarParaSalvar(camera) {
		let messages = [];
		if (Validator.isEmpty(camera.descricao)) messages.push("A descrição é obrigatória");
		if (Validator.isEmpty(camera.ip)) messages.push("O endereço IP é obrigatório");
		return messages;
	}

}

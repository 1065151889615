import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import "../../utils/Format";
import {MEndereco} from "../../utils/models/MEndereco";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {MPessoaJuridica} from "../../utils/models/MPessoaJuridica";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EditarEtiqueta} from "../Etiqueta/EditarEtiqueta";
import {EnderecoService} from "../servicos/EnderecoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {ColorPanel} from "../commons/ColorPanel";
import {toSrc} from "../../utils/StringUtils";
import {Validator} from "../../utils/Validator";
import {MultiCombobox} from "../commons/MultiCombobox";
import { DataTable } from "primereact/components/datatable/DataTable";
import { PanelContent } from "../commons/PanelContent";
import { GUI } from "../../utils/Constants";
import { Button } from "primereact/components/button/Button";
import { MDocumento } from "../../utils/models/MDocumento";
import { Column } from "primereact/components/column/Column";
import moment from "moment";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import {EditarDocumento} from "../Documento/EditarDocumento";

export class EditarPessoaJuridica extends React.Component {

	constructor(props) {
		super(props);
		let pessoa = this.prepararPessoa(props.pessoa);
		let endereco = this.prepararEndereco(pessoa);
		this.state = {
			pessoa,
			endereco,
			visible: true,
			activeIndex: 0,
			...props.params,
			lockSave: false
		};
		this.pessoaJuridicaService = new PessoaJuridicaService();
		this.enderecoService = new EnderecoService();
		this.securityDocumento = UserData.security("DOC");
		this.localidadeService = new LocalidadeService();
		this.etiquetaService = new EtiquetaService();
		this.security = UserData.security("EMP", true);
	}

	prepararPessoa(pessoa) {
		if (pessoa == null) {
			pessoa = MPessoaJuridica.Modelo();
		}
		return MPessoaJuridica.cleanupBeforeEdit(pessoa);
	}

	prepararEndereco(pessoa) {
		pessoa = this.prepararPessoa(pessoa);
		let endereco;
		if (pessoa.enderecos != null) {
			endereco = pessoa.enderecos.filter((endereco) => endereco.tipo === "PRINCIPAL")[0];
		}
		if (endereco == null) {
			endereco = MEndereco.Modelo();
			if (pessoa.enderecos == null) pessoa.enderecos = [endereco]; else pessoa.enderecos.push(endereco);
		}
		return endereco;
	}

	salvarPessoa = () => {
		if (this.security.edit) {
			let pessoa = MPessoaJuridica.cleanupBeforeSave(this.state.pessoa);
			let messages = MPessoaJuridica.validarParaSalvar(pessoa);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (pessoa.logotipo && pessoa.logotipo.conteudo) {
					pessoa.logotipo.conteudo = pessoa.logotipo.conteudo.split(",").pop();
				}
				if (pessoa.enderecos != null && pessoa.enderecos.length > 0 && pessoa.enderecos.some(e => e.id == null)) {
					this.completarEnderecosESalvar(pessoa);
				} else {
					this.postPessoa(pessoa);
				}
			}
		}
	}

	completarEnderecosESalvar = (pessoa, index = 0) => {
		if (pessoa.enderecos.length > index) {
			this.completarEndereco(pessoa.enderecos[index]).then((localidade) => {
				pessoa.enderecos[index].localidade = localidade;
				this.completarEnderecosESalvar(pessoa, ++index);
			});
		} else {
			this.postPessoa(pessoa);
		}
	}

	completarEndereco = (endereco) => {
		return this.localidadeService.listar(`search=nome:${endereco.localidade.nome};uf:${endereco.localidade.uf}`, {page: 0, size: 1, sort: "nome"}).then((localidades) => {
			if (localidades && localidades.length) {
				return localidades[0];
			} else {
				return this.salvarLocalidade(endereco.localidade);
			}
		});
	}

	salvarLocalidade = async (localidade) => {
		return await this.localidadeService.salvar(localidade);
	}

	onClose = () => {
		this.setState({visible: false});
	}

	handleChange = (event) => {
		let pessoa = this.state.pessoa;
		pessoa[event.name] = event.value;
		this.setState({pessoa});
	}

	handleChangeEndereco = (event) => {
		let endereco = this.state.endereco;
		endereco[event.target.name] = event.target.value;
		this.setState({endereco});
	}

	handleChangeEnderecoLocalidade = (event) => {
		let endereco = this.state.endereco;
		endereco.localidade[event.target.name] = event.target.value;
		this.setState({endereco});
	}

	handleChangeEnderecoLocalidadeSuggestion = (event) => {
		let endereco = this.state.endereco;
		endereco.localidade[event.name] = event.value;
		this.setState({endereco});
	}

	buscarEndereco = () => {
		this.enderecoService.buscarCEP(this.state.endereco.cep).then((result) => {
			if (result.status === 200) {
				result.json().then((json) => {
					let endereco = this.state.endereco;
					endereco.cep = json.cep;
					endereco.logradouro = json.logradouro;
					endereco.bairro = json.bairro;
					this.localidadeService.listar(`search=nome:${json.localidade};uf:${json.uf}`, {size: 1, page: 0}).then((localidades) => {
						if (localidades.length > 0) {
							endereco.localidade = localidades[0];
						} else {
							endereco.localidade = {nome: json.localidade, uf: json.uf};
						}
						this.setState({endereco});
					});
				});
			}
		});
	}

	changeTipoEndereco = (event) => {
		let endereco = this.state.pessoa.enderecos.filter((endereco) => endereco.tipo === event.value)[0];
		if (endereco == null) {
			endereco = MEndereco.Modelo();
			endereco.tipo = event.value;
			let pessoa = this.state.pessoa;
			pessoa.enderecos.push(endereco);
			this.setState({pessoa, endereco});
		} else {
			this.setState({endereco});
		}
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {sort: "descricao", page: 0, size: 10}).then((etiquetas) => {
			if (etiquetas == null || etiquetas.length === 0) {
				this.setState({etiquetas: [{...MEtiqueta.Modelo(), id: -1, descricao: `Criar etiqueta ${event.query}`}]});
			} else {
				this.setState({etiquetas});
			}
		});
	}

	selecionarEtiquetas = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={{...MEtiqueta.Modelo(), descricao: event.value.descricao.replace("Criar etiqueta ", "")}} onModalClose={(etiqueta) => {
				let pessoa = this.state.pessoa;
				pessoa.etiquetas.push(etiqueta);
				this.setState({pessoa});
			}} />);
		}
	}

	postPessoa = (pessoa) => {
		this.setState({lockSave: true});
		this.pessoaJuridicaService.salvar(pessoa).then((pessoa) => {
			this.setState({lockSave: false});
			if (this.props.onModalClose) {
				this.props.onModalClose(pessoa);
			}
			this.onClose();
		}).catch(() => this.setState({lockSave: false}));
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	editarDocumento = (documento) => {
		if (!documento) {
			documento = MDocumento.Modelo();
		}
		if (!documento.id && !documento._key) {
			documento._key = Math.random();
		}

		ModalRealm.showDialog(<EditarDocumento modo="juridica" key={Math.random()} documento={documento} onModalClose={(documento) => {
			const pessoa = this.state.pessoa;
			if (!pessoa.documentos) {
				pessoa.documentos = [];
			} else {
				pessoa.documentos = pessoa.documentos.filter((d) => d.id !== documento.id || d._key !== documento._key);
			}
			pessoa.documentos.push(documento);
			this.setState({pessoa, _mod: true});
		}} />);
	}
	
	detailsDocumentos = [
		<Column style={{width: "*"}} key={0} header="Tipo" body={(documento) => documento.tipo?.descricao} />,
		<Column style={{width: "*"}} key={1} header="Número" field="numero" />,
		<Column style={{width: "10em"}} key={2} header="Validade" body={(documento) => {
			const expired = documento.validade && moment(documento.validade).add(1, "days").isBefore(moment());
			return documento.validade ? <div className={expired ? "ui-expired-doc" : ""}>{moment(documento.validade).format("DD/MM/YYYY")}</div> : "Indeterminada";
		}} />,
		<Column style={{width: "6em", textAlign: "center"}} key={3} header="Ações" body={(documento) => {
			return (
				<div>
					{this.securityDocumento?.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Documento" onClick={() => this.editarDocumento(documento)} /> : null}
					{this.securityDocumento?.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Documento" onClick={() => this.excluirDocumento(documento)} /> : null}
				</div>
			);
		}} />
	];

	excluirDocumento = (documento) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja remover este documento?", () => {
			const { pessoa } = this.state;
			if (pessoa.documentos) {
				pessoa.documentos = pessoa.documentos.filter(d => d.id !== documento.id || d._key !== documento._key);
			}
			this.setState({pessoa});
		});
	}

	render() {
		return (
			<Dialog modal header="Empresa" visible={this.state.visible} style={{width: "1024px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<div className="ui-g">
								<div className="ui-g-4 ui-g-nopad">
									<div className="pesjur-logotipo-container">
										<img onClick={this.handleLoadLogotipo} alt="" className="pesjur-logotipo" src={this.state.pessoa.logotipo?.conteudo || toSrc(this.state.pessoa.logotipo)} />
										<input accept="image/jpeg" style={{display: "none"}} ref={this.refInput} type="file" files={this.props.value} value="" onChange={this.loadLogotipo} />
									</div>
								</div>
								<div className="ui-g-8 ui-g-nopad">
									<div className="ui-g">
										<TextField label="Razão Social" grid={7} alphanumeric required name="nome" onChange={this.handleChange} value={this.state.pessoa.nome} />
										<TextField label="Nome Fantasia" grid={5} name="fantasia" onChange={this.handleChange} value={this.state.pessoa.fantasia} />
										<TextField grid={6} help="Cadastro Nacional de Pessoa Jurídica" label="CNPJ" type="cnpj" name="cnpj" onChange={this.handleChange} value={this.state.pessoa.cnpj} />
										<TextField label="Telefone" grid={6} type="telefone" name="telefone" onChange={this.handleChange} value={this.state.pessoa.telefone} />
										<TextField invalid={!Validator.isEmpty(this.state.pessoa.email) && !Validator.isEmail(this.state.pessoa.email)} grid={6} label="Email" name="email" onChange={this.handleChange} value={this.state.pessoa.email} />
										<MultiCombobox help={
											`Aceita Emails: aceita receber emails de comunicado do sistema`
										} appendTo={document.body} defaultLabel="Nenhuma" grid={6} name="flags" label="Opções" value={this.state.pessoa.flags} options={MPessoaJuridica.Flags} onChange={this.handleChange} />
										<MultiSelectEtiqueta grid={12} label="Etiquetas" onSelect={this.handleChange} name="etiquetas" value={this.state.pessoa.etiquetas} />
										<ColorPanel grid={12} label="Cor Primária" name="corPrimaria" value={this.state.pessoa.corPrimaria} onChange={this.handleChange} />
										<ColorPanel grid={12} label="Cor Secundária" name="corSecundaria" value={this.state.pessoa.corSecundaria} onChange={this.handleChange} />
										{UserData.hasRole("XX0C") ? <TextField grid={12} label="Chave de Integração" name="chaveIntegracao" onChange={this.handleChange} value={this.state.pessoa.chaveIntegracao} /> : null}
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel header="Endereços">
							<div className="ui-g">
								<div className="ui-g-2">
									<Label>Tipo</Label>
									<Combobox appendTo={document.body} value={this.state.endereco.tipo} name="tipo" options={MEndereco.Tipos} onChange={this.changeTipoEndereco} />
								</div>
								<div className="ui-g-2">
									<Label>CEP</Label>
									<TextField type="cep" name="cep" onBlur={this.buscarEndereco} onKeyUp={(event) => {
										if (event.keyCode === 13) {
											document.activeElement.blur();
										}
									}} onChange={this.handleChangeEndereco} value={this.state.endereco.cep} />
								</div>
								<div className="ui-g-6">
									<Label>Logradouro</Label>
									<TextField name="logradouro" onChange={this.handleChangeEndereco} value={this.state.endereco.logradouro} />
								</div>
								<div className="ui-g-2">
									<Label>Número</Label>
									<TextField name="numero" onChange={this.handleChangeEndereco} value={this.state.endereco.numero} />
								</div>
								<div className="ui-g-3">
									<Label>Complemento</Label>
									<TextField name="complemento" onChange={this.handleChangeEndereco} value={this.state.endereco.complemento} />
								</div>
								<div className="ui-g-3">
									<Label>Bairro</Label>
									<TextField name="bairro" onChange={this.handleChangeEndereco} value={this.state.endereco.bairro} />
								</div>
								<div className="ui-g-3">
									<Label>Cidade</Label>
									<TextField name="nome" onChange={this.handleChangeEnderecoLocalidade} value={this.state.endereco.localidade.nome} />
								</div>
								<div className="ui-g-3">
									<Label>UF</Label>
									<Combobox appendTo={document.body} name="uf" options={MEndereco.UFs} onChange={this.handleChangeEnderecoLocalidadeSuggestion} value={this.state.endereco.localidade.uf} />
								</div>
							</div>
						</TabPanel>
						<TabPanel 
							disabled={!this.securityDocumento?.view} 
							header="Documentos"
						>
							<PanelContent>
								<DataTable 
									emptyMessage="Nenhum documento cadastrado"
									value={this.state.pessoa.documentos}
									rows={GUI.defaultRows}
									rowsPerPageOptions={GUI.rowsPerPage}
									paginator
									paginatorLeft={this.securityDocumento?.create ? <Button onClick={() => this.editarDocumento()} icon="fa-plus" className="ui-tzm-pagleft-icon" /> : null}
									children={this.detailsDocumentos}
								/>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.pessoa.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/pessoas-juridicas", this.state.pessoa)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.pessoa.id)) || this.state.lockSave} onClick={this.salvarPessoa} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarPessoa} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	handleLoadLogotipo = (event) => {
		this.inputFile.click();
	}

	refInput = (el) => this.inputFile = el;

	loadLogotipo = (event) => {
		const file = event.target.files[0];
		if (file) {
			let reader = new FileReader();
			reader.onloadend = () => {
				let foto = {
					status: "ATIVADO",
					finalidade: "IDENTIFICACAO",
					conteudo: reader.result
				};
				const { pessoa } = this.state;
				pessoa.logotipo = foto;
				this.setState({pessoa});
			};
			reader.readAsDataURL(file);
		}
	}

}

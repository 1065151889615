import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import {default as iconeExcluir, default as iconeRemover} from "../../media/icones/excluir.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {GUI} from "../../utils/Constants";
import {MModeloAgenda} from "../../utils/models/MModeloAgenda";
import {passwordLevels, setupSistemaOptionsFlags} from "../../utils/models/MSetupSistema";
import {MTexto} from "../../utils/models/MTexto";
import {httpGet, httpPost} from "../../utils/Request";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {DatePicker} from "../commons/DatePicker";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {SuggestionBox} from "../commons/SuggestionBox";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {ModeloAgendaService} from "../servicos/ModeloAgendaService";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {SetupPortariaService} from "../servicos/SetupPortariaService";
import {SetupSistemaService} from "../servicos/SetupSistemaService";
import {EditarContaEmail} from "./EditarContaEmail";
import {EditarExpiracao} from "./EditarExpiracao";
import {EditarModeloAgenda} from "../ModeloAgenda/EditarModeloAgenda";
import {EditarPortaria} from "./EditarPortaria";
import {EditarTexto} from "./EditarTexto";
import {AboutDialog} from "./AboutDialog";
import {MAgenda} from "../../utils/models/MAgenda";
import {Fetch} from "../../utils/Fetch";
import {VeiculoFabricanteService} from "../servicos/VeiculoFabricanteService";
import {EditarVeiculoFabricante} from "./EditarVeiculoFabricante";
import {byNome} from "../../utils/SortUtils";
import {TextField} from "../commons/TextField";
import {labelTipoDocumentoObrigatorio, MDocumentoTipo} from "../../utils/models/MDocumento";
import {EditarDocumentoTipo} from "./EditarDocumentoTipo";
import {DocumentoTipoService} from "../servicos/DocumentoTipoService";
import {Confirmacao} from "../commons/Confirmacao";
import {ManutencaoService} from "../servicos/ManutencaoService";
import iconePessoa from "../../media/icones/pessoas.png";
import iconeAgenda from "../../media/icones/agendas.png";
import iconeEmpresa from "../../media/icones/empresas.png";
import iconeVeiculo from "../../media/icones/veiculos.png";
import {SelectButton} from "../commons/SelectButton";
import {optionsTecnologias} from "../../utils/models/MCredencial";
import {Combobox} from "../commons/Combobox";
import {HousekeepingService} from "../servicos/HousekeepingService";
import {PortalCondomino} from "../PortalCondomino";
import {SetupPortalCondominoService} from "../servicos/SetupPortalCondominoService";

const basePath = process.env.REACT_APP_API_URL;

export class Configuracao extends React.Component {

	state = {
		portarias: [],
		setupCondominio: {
			categorias: [],
			categoriasGuarita: [],
			categoriasPortaria: [],
			expiracoes: [],
			textos: [],
			categoriasFacialPortaria: []
		},
		documentosTipo: [],
		activeIndex: 0,
		activeSubIndex: 0,
		firstResult: 0,
		setupSistema: {
			emails: []
		},
		inicio: moment().add(-1, "months").format("YYYY-MM-DD"),
		fim: moment().format("YYYY-MM-DD"),
		loading: 0,
		lockSave: false,
		fabricantes: []
	};

	setupPortariaService = new SetupPortariaService();
	setupCondominioService = new SetupCondominioService();
	setupSistemaService = new SetupSistemaService();
	modeloAgendaService = new ModeloAgendaService();
	etiquetaService = new EtiquetaService();
	veiculoFabricanteService = new VeiculoFabricanteService();
	security = UserData.security("CFG", true);
	documentoTipoService = new DocumentoTipoService();
	manutencaoService = new ManutencaoService();
	housekeepingService = new HousekeepingService();
	setupPortalCondominoService = new SetupPortalCondominoService();

	UNSAFE_componentWillMount() {
		this.listarPortarias();
		this.setupCondominioService.buscar().then((setupCondominio) => {
			setupCondominio.categorias = setupCondominio.categorias.sort((a, b) => a.descricao.localeCompare(b.descricao));
			setupCondominio.categoriasPortaria = setupCondominio.categoriasPortaria.sort((a, b) => a.descricao.localeCompare(b.descricao));
			setupCondominio.categoriasGuarita = setupCondominio.categoriasGuarita.sort((a, b) => a.descricao.localeCompare(b.descricao));
			setupCondominio.categoriasFacialPortaria = setupCondominio.categoriasFacialPortaria.sort((a, b) => a.descricao.localeCompare(b.descricao));
			this.setState({setupCondominio});
		});
		this.setupSistemaService.buscar().then((setupSistema) => this.setState({setupSistema}));
	}

	componentDidMount() {
		this.veiculoFabricanteService.listar(``, {page: 0, size: 1000, sort: "nome"}).then(fabricantes => this.setState({fabricantes}));
		this.setupPortalCondominoService.buscar().then(setupPortalCondomino => this.setState({setupPortalCondomino}));
		this.listarDocumentosTipo();
	}

	detalhesPortarias = [
		<Column style={{width: "*"}} header="Descrição" field="descricao" />,
		<Column style={{width: "*"}} header="Etiquetas" body={(rowData) => rowData.etiquetas != null ? rowData.etiquetas.map(etiqueta => {
			return <EtiquetaChip etiqueta={etiqueta} />
		}) : null} />,
		<Column style={{width: "*"}} header="Modelos de Agenda" body={(portaria) => portaria.modelosAgenda != null ? portaria.modelosAgenda.map(ma => <div key={ma.id}>{ma.descricao}</div>) : null} />,
		<Column style={{width: "5em"}} header="Ações" body={(rowData) => {
			return (
				<div key={rowData.id} style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Portaria" onClick={(event) => this.editarPortaria(rowData)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Portaria" onClick={(event) => this.excluirPortaria(rowData)} /> : null}
				</div>
			);
		}} />
	];

	listarDocumentosTipo = () => {
		this.documentoTipoService.listar("", {page: 0, size: 100, sort: "id"}).then(documentosTipo => this.setState({documentosTipo}));
	}

	listarPortarias = () => {
		this.setupPortariaService.listar("search=descricao~", {page: 0, size: GUI.maxResults}).then((portarias) => this.setState({portarias, firstResult: 0}));
	}

	editarPortaria = (portaria) => {
		if (portaria != null && portaria.id != null) {
			this.setupPortariaService.buscar(portaria).then((portaria) => {
				ModalRealm.showDialog(<EditarPortaria portaria={portaria} key={Math.random()} onModalClose={this.listarPortarias} />);
			});
		} else {
			ModalRealm.showDialog(<EditarPortaria portaria={null} key={Math.random()} onModalClose={this.listarPortarias} />);
		}
	}

	editarContaEmail = (contaEmail) => {
		ModalRealm.showDialog(<EditarContaEmail contaEmail={contaEmail} onModalClose={(contaEmail) => {
			const setupSistema = this.state.setupSistema;
			setupSistema.contasEmail = setupSistema.contasEmail.filter((ce) => ce.id !== contaEmail.id);
			setupSistema.contasEmail.push(contaEmail);
			this.setupSistemaService.salvar(setupSistema).then((setupSistema) => {
				this.setState({setupSistema});
			});
		}} />);
	}

	excluirPortaria = (portaria) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta portaria?", () => this.setupPortariaService.excluir(portaria).then(this.listarPortarias));
	}

	addPortaria = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarPortaria(null)} />;

	addContaEmail = (
		<div>
			<Button className="ui-tzm-pagleft-icon" title="Adicionar Conta de Email" icon="fa-plus" onClick={() => this.editarContaEmail(null)} />
		</div>
	);

	salvarSetupCondominio = () => {
		this.setupCondominioService.salvar(this.state.setupCondominio).then((setupCondominio) => {
			this.setState({setupCondominio});
		});
	}

	salvarSetupSistema = () => {
		this.setupSistemaService.salvar(this.state.setupSistema).then((setupSistema) => {
			this.setState({setupSistema});
		});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	tabChange = (event) => this.setState({activeIndex: event.index});

	tabSubChange = (event) => this.setState({activeSubIndex: event.index});

	handleUpdateSC = (event) => {
		let setupCondominio = this.state.setupCondominio;
		setupCondominio[event.name] = event.value;
		this.setState({setupCondominio});
	}

	handleRemoveSC = (event) => {
		let setupCondominio = this.state.setupCondominio;
		setupCondominio[event.name] = setupCondominio[event.name].filter(e => e.id !== event.value.id);
		this.setState({setupCondominio});
	}

	handleChangeSS = (event) => {
		const setupSistema = this.state.setupSistema;
		setupSistema[event.name] = event.value;
		this.setState({setupSistema});
	}

	handleChange = (event) => this.setState({[event.name]: event.value});

	excluirContaEmail = (contaEmail) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta conta de email?", () => {
			const setupSistema = this.state.setupSistema;
			setupSistema.contasEmail = setupSistema.contasEmail.filter((ce) => ce.id !== contaEmail.id);
			this.setupSistemaService.salvar(setupSistema).then((setupSistema) => this.setState({setupSistema}));
		});
	}

	detailsContas = [
		<Column key={-1} header="Finalidade" field="finalidade" style={{width: "12em"}} />,
		<Column key={0} header="Servidor" field="servidor" style={{width: "*"}} />,
		<Column key={1} header="Porta" field="porta" style={{width: "5em"}} />,
		<Column key={2} header="Email" field="usuario" style={{width: "*"}} />,
		<Column key={3} header="Ações" style={{width: "6em", textAlign: "center"}} body={(conta) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Conta" onClick={() => this.editarContaEmail(conta)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Conta" onClick={() => this.excluirContaEmail(conta)} /> : null}
				</div>
			);
		}} />,
	];

	editarExpiracao = (expiracao) => {
		ModalRealm.showDialog(<EditarExpiracao expiracao={expiracao} onModalClose={(expiracao) => {
			const setupCondominio = this.state.setupCondominio;
			setupCondominio.expiracoes = setupCondominio.expiracoes.filter((e) => e.id !== expiracao.id);
			setupCondominio.expiracoes.push(expiracao);
			this.setupCondominioService.salvar(setupCondominio).then((setupCondominio) => {
				this.setState({setupCondominio});
			});
		}} />);
	}

	reiniciarMailerService = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja reiniciar o serviço de envio de emails? Isto interromperá todos os envios de email pendentes.", () => {
			Fetch.Get(`${basePath}/mailer/reiniciar`).then(() => {
				ModalRealm.showInformacao("info", ["Processo concluído com sucesso!"]);
			});
		});
	}

	consolidarAcessos = () => {
		this.setState({loading: this.state.loading | (1 << 1)});
		Fetch.Get(`${basePath}/consolidacoes-acesso/extrair?inicio=${this.state.inicio}&fim=${this.state.fim}`).then(() => {
			this.setState({loading: this.state.loading & ~(1 << 1)});
			ModalRealm.showInformacao("info", ["Processo concluído com sucesso!"]);
		});
	}

	recarregarClientes = () => {
		httpGet("/frontend/refresh").then(() => ModalRealm.showInformacao("info", ["Processo concluído com sucesso!"]));
	}

	removerHash = () => {
		httpGet("/credenciais/remover-hash").then(() => ModalRealm.showInformacao("info", ["Processo concluído com sucesso!"]));
	}

	atualizarHash = () => {
		httpGet("/credenciais/atualizar-hash").then(() => ModalRealm.showInformacao("info", ["Processo concluído com sucesso!"]));
	}

	intl = Intl.NumberFormat("pt-BR");

	organizarRegistros = () => {
		this.setState({loading: this.state.loading | (1 << 3)});
		let acumulado = 0;
		Fetch.Delete(`${basePath}/housekeeping/excluir-agendas-encerradas`).then((response) => {
			response.json().then((total) => {
				acumulado += total;
				Fetch.Delete(`${basePath}/housekeeping/excluir-bloqueios-encerrados`).then((response) => {
					response.json().then((total) => {
						acumulado += total;
						Fetch.Post(`${basePath}/housekeeping/executar-manutencoes-uteis`).then((response) => {
							response.json().then((total) => {
								acumulado += total;
								ModalRealm.showInformacao("info", [`Processo concluído com sucesso. ${this.intl.format(acumulado)} registros foram organizados e limpos.`]);
								this.setState({loading: this.state.loading & ~(1 << 3)});
							});
						});
					});
				});
			});
		});
	}

	detailsExpiracoes = [
		<Column key={0} header="Etiquetas" body={(expiracao) => expiracao.etiquetas && expiracao.etiquetas.length ? expiracao.etiquetas.map(e => <EtiquetaChip key={e.id} etiqueta={e} />) : "Todos os registros"} />,
		<Column key={1} header="Tempo (Dias)" style={{width: "10em"}} field="dias" />,
		<Column key={2} header="Ações" style={{width: "6em", textAlign: "center"}} body={(expiracao) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Expiração" onClick={() => this.editarExpiracao(expiracao)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Expiração" onClick={() => this.excluirExpiracao(expiracao)} /> : null}
				</div>
			);
		}} />
	];

	footerExpiracao = <Button className="ui-tzm-pagleft-icon" title="Adicionar Expiração" icon="fa-plus" onClick={() => this.editarExpiracao(null)} />

	listarModelosAgenda = (event) => {
		this.modeloAgendaService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((modelosAgenda) => {
			if (modelosAgenda.length === 0) {
				this.setState({modelosAgenda: [{id: -1, descricao: `Criar modelo de agenda ${event.query}`}]});
			} else {
				this.setState({modelosAgenda});
			}
		});
	}

	addModeloAgenda = (event) => {
		if (event.value.id === -1) {
			ModalRealm.showDialog(<EditarModeloAgenda modeloAgenda={{...MModeloAgenda.Modelo(), descricao: event.value.descricao.replace("Criar modelo de agenda ", "")}} onModalClose={(modeloAgenda) => {
				let setupCondominio = this.state.setupCondominio;
				setupCondominio.modelosAgenda.push(modeloAgenda);
				this.setState({setupCondominio});
			}} />);
		} else {
			let setupCondominio = this.state.setupCondominio;
			setupCondominio.modelosAgenda.push(event.value);
			this.setState({setupCondominio});
		}
		this.setState({modeloAgenda: ""});
	}

	columnsTextos = [
		<Column key={0} header="Finalidade" style={{width: "20em"}} body={(t) => MTexto.MapFinalidade[t.finalidade]} />,
		<Column key={1} header="Texto" field="texto" />,
		<Column key={2} header="Ações" style={{textAlign: "center", width: "5em"}} body={(t) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Texto" onClick={() => this.editarTexto(t)} /> : null}
				</div>
			);
		}} />,
	];

	editarFabricanteVeiculo = (fabricante = {
		nome: ""
	}) => {
		ModalRealm.showDialog(<EditarVeiculoFabricante fabricante={fabricante} onModalClose={fabricante => {
			let {fabricantes} = this.state;
			fabricantes = fabricantes.filter(f => f.id !== fabricante.id);
			fabricantes.push(fabricante);
			fabricantes = fabricantes.sort(byNome);
			this.setState({fabricantes});
		}}/>);
	}

	excluirFabricanteVeiculo = (fabricante) => {
		this.veiculoFabricanteService.excluir(fabricante).then(() => {
			let {fabricantes} = this.state;
			fabricantes = fabricantes.filter(f => f.id !== fabricante.id);
			this.setState({fabricantes});
		});
	}

	printVeiculoFabricante = (index) => {
		return (
			<div className="ui-g-3 ui-g-nopad">
				<DataTable paginator rows={100} paginatorLeft={
					!index ? <Button className="ui-tzm-pagleft-icon" icon="fal fa-plus" onClick={() => this.editarFabricanteVeiculo()}/> : null
				} emptyMessage="Nenhum registro encontrado" value={this.state.fabricantes.filter((vf, i) => i % 4 === index)}>
					<Column header="Nome" field="nome"/>
					<Column header="Ações" style={{width: "5em"}} body={vf => (
						<div style={{textAlign: "center"}}>
							{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Fabricante de Veículo" onClick={(event) => this.editarFabricanteVeiculo(vf)} /> : null}
							{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Fabricante de Veículo" onClick={(event) => this.excluirFabricanteVeiculo(vf)} /> : null}
						</div>
					)}/>
				</DataTable>
			</div>
		);
	}

	printDocumentoTipo = index => {
		return (
			<div className="ui-g-6 ui-g-nopad">
				<DataTable paginator rows={100} paginatorLeft={
					!index ? <Button className="ui-tzm-pagleft-icon" icon="fal fa-plus" onClick={() => this.inserirDocumentoTipo()}/> : null
				} emptyMessage="Nenhum registro encontrado" value={this.state.documentosTipo.filter((dt, i) => i % 2 === index)}>
					<Column style={{width: "15em"}} header="Descrição" field="descricao"/>
					<Column style={{width: "5em", textAlign: "center"}} header="Aplicação" field="aplicacoes" body={printAplicacoesDocumento}/>
					<Column header="Campos Obrigatórios" field="flags" body={dt => dt.obrigatorios.map(o => labelTipoDocumentoObrigatorio[o]).join(", ")}/>
					<Column header="Ações" style={{width: "5em"}} body={dt => (
						<div style={{textAlign: "center"}}>
							{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Tipo de Documento" onClick={() => this.editarDocumentoTipo(dt)} /> : null}
							{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Tipo de Documento" onClick={() => this.excluirDocumentoTipo(dt)} /> : null}
						</div>
					)}/>
				</DataTable>
			</div>
		);
	}

	inserirDocumentoTipo = () => this.editarDocumentoTipo(MDocumentoTipo.Modelo());

	editarDocumentoTipo = documentoTipo => {
		ModalRealm.showDialog(<EditarDocumentoTipo documentoTipo={documentoTipo} onModalClose={this.listarDocumentosTipo}/>);
	}

	excluirDocumentoTipo = documentoTipo => {
		ModalRealm.showDialog(<Confirmacao message="Tem certeza de que deseja excluir este tipo de documento?" onYes={() => {
			this.documentoTipoService.excluir(documentoTipo).then(this.listarDocumentosTipo);
		}}/>);
	}

	inicializarManutencaoPeriodica = async () => {
		await this.manutencaoService.inicializarManutencaoPeriodica();
	}

	removerPresencasExpiradas = async () => {
		this.setState({loading: this.state.loading | (1 << 2)});
		this.housekeepingService.removerPresencasExpiradas().then(() => this.setState({loading: this.state.loading & ~(1 << 2)}));
	}

	salvarSetupPortalCondomino = () => {
		this.setupPortalCondominoService.salvar(this.state.setupPortalCondomino).then(setupPortalCondomino => this.setState({setupPortalCondomino}));
	}

	handleChangeSetupPortalCondomino = e => {
		this.setState({setupPortalCondomino: {...this.state.setupPortalCondomino, [e.name]: e.value}});
	}

	render() {
		const tabs = [
			<TabPanel key={0} header="Portaria &amp; Guarita">
				<div className="ui-g">
					<div className="ui-g-12">
						<Label>Portarias</Label>
						<DataTable first={this.state.firstResult} onPage={this.changePage} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginatorLeft={this.addPortaria} emptyMessage="Nenhuma portaria adicionada" value={this.state.portarias} children={this.detalhesPortarias} />
					</div>
					<MultiSelectEtiqueta label="Categorias em Portaria" grid={12} appendTo={null} onRemove={this.handleRemoveSC} onSelect={this.handleUpdateSC} name="categoriasPortaria" value={this.state.setupCondominio.categoriasPortaria} />
					<MultiSelectEtiqueta label="Categorias em Guarita"  grid={12} appendTo={null} onRemove={this.handleRemoveSC} onSelect={this.handleUpdateSC} name="categoriasGuarita" value={this.state.setupCondominio.categoriasGuarita} />
					<MultiSelectEtiqueta label="Categorias com Facial (Portaria)"  grid={12} appendTo={null} onRemove={this.handleRemoveSC} onSelect={this.handleUpdateSC} name="categoriasFacialPortaria" value={this.state.setupCondominio.categoriasFacialPortaria} />
					<MultiSelectEtiqueta label="Categorias em Prestação de Serviços"  grid={12} appendTo={null} onRemove={this.handleRemoveSC} onSelect={this.handleUpdateSC} name="categoriasPrestacaoServicos" value={this.state.setupCondominio.categoriasPrestacaoServicos} />
				</div>
			</TabPanel>,
			<TabPanel key={1} header="Unidades">
				<PanelContent>
					<SuggestionBox label="Modelos de Agenda" grid={12} field="descricao" completeMethod={this.listarModelosAgenda} suggestions={this.state.modelosAgenda} onChange={(e) => this.setState({modeloAgenda: e.value})} onSelect={this.addModeloAgenda} value={this.state.modeloAgenda} />
					<div className="ui-g-12">
						<DataTable emptyMessage="Nenhum modelo de agenda adicionado" paginatorLeft={this.modelosAgendaAddButton} value={this.state.setupCondominio.modelosAgenda} paginator rows={10} children={this.modelosAgendaColumns} />
					</div>
					<MultiSelectEtiqueta label="Categorias em Unidades" grid={10} appendTo={null} onRemove={this.handleRemoveSC} onSelect={this.handleUpdateSC} name="categorias" value={this.state.setupCondominio.categorias} />
					<TextField grid={2} label="Limite de Confirmação" unit="min" value={this.state.setupCondominio.tempoExpiracaoAcessoMonitoravelGuarita} help="Limite de tempo para descartar acessos não confirmados na guarita" onChange={this.handleUpdateSC} name="tempoExpiracaoAcessoMonitoravelGuarita"/>
				</PanelContent>
			</TabPanel>,
			<TabPanel key={2} header="Outras Opções">
				<PanelContent>
					<SelectButton multiple grid={12} name="flags" value={this.state.setupSistema.flags} onChange={this.handleChangeSS} label="Opções" options={setupSistemaOptionsFlags.slice(0, 6)}/>
					<SelectButton style={{marginTop: "-8px"}} multiple grid={12} name="flags" value={this.state.setupSistema.flags} onChange={this.handleChangeSS} options={setupSistemaOptionsFlags.slice(6, 12)}/>
					<SelectButton style={{marginTop: "-8px", width: "33.3%"}} multiple grid={12} name="flags" value={this.state.setupSistema.flags} onChange={this.handleChangeSS} options={setupSistemaOptionsFlags.slice(12, 15)}/>
					<TextField type="numeric" maxValue={365} help="Tempo em que o sistema considera o cadastro da pessoa como inativa e pode ser removida da memória dos equipamentos de acesso" value={this.state.setupSistema.diasInatividadeAcesso} unit="dias" name="diasInatividadeAcesso" label="Inatividade de Acesso" grid={2} onChange={this.handleChangeSS}/>
					<Combobox grid={2} label="Tecnologia Padrão" value={this.state.setupSistema.tecnologiaPadrao} name="tecnologiaPadrao" onChange={this.handleChangeSS} options={optionsTecnologias}/>
					<SelectButton options={passwordLevels} help="Pontuação mínima aceita para senhas de usuários do Safety (de 1 [menos segura] a 5 [mais segura])" value={this.state.setupSistema.scoreMinimoSenha} name="scoreMinimoSenha" label="Nível Mínimo de Senha" grid={4} onChange={this.handleChangeSS}/>
					<div className="ui-g-12">
						<Label help="Determina o intervalo de tempo em que o registro de uma pessoa deve ser atualizado">Configurações de Expiração de Pessoa Física</Label>
						<DataTable rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator paginatorLeft={this.footerExpiracao} emptyMessage="Nenhuma expiração configurada" value={this.state.setupCondominio.expiracoes} children={this.detailsExpiracoes} />
					</div>
					<div className="ui-g-12">
						<Label help="Permite ajustar os textos que o sistema usa para notificar usuários de um evento">Configurações de Textos do Sistema</Label>
						<DataTable emptyMessage="Nenhum texto configurado" paginatorLeft={this.textosAddButton} value={this.state.setupCondominio.textos} paginator rows={10} children={this.columnsTextos} />
					</div>
					<div className="ui-g-12">
						<Label>Contas de Email</Label>
						<DataTable value={this.state.setupSistema.contasEmail} name="emails" paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginatorLeft={this.addContaEmail} children={this.detailsContas} emptyMessage="Nenhuma conta de email configurada" />
					</div>
					<div className="ui-g-12">
						<Label>Fabricantes de Veículos</Label>
						<div className="ui-g">
							{this.printVeiculoFabricante(0)}
							{this.printVeiculoFabricante(1)}
							{this.printVeiculoFabricante(2)}
							{this.printVeiculoFabricante(3)}
						</div>
					</div>
					<div className="ui-g-12">
						<Label>Tipos de Documento</Label>
						<div className="ui-g">
							{this.printDocumentoTipo(0)}
							{this.printDocumentoTipo(1)}
						</div>
					</div>
				</PanelContent>
			</TabPanel>
		];
		if (UserData.hasRole("XX2V")) {
			tabs.push(
				<TabPanel key={3} header="Portal do Condômino">
					<PanelContent>
						<PortalCondomino setup={this.state.setupPortalCondomino} onChangeSetup={this.handleChangeSetupPortalCondomino}/>
					</PanelContent>
				</TabPanel>
			);
		}
		tabs.push(
			<TabPanel key={4} header="Rotinas">
				<PanelContent>
					<div className="ui-g-12"><Label>Rotinas de Manutenção</Label></div>
					<div className="ui-g-12" style={{display: "flex"}}>
						<Action className="rotina-button" key={0} label="Reiniciar Serviço de Emails" title="Reinicia o serviço de envio de email para atualizar os dados de cadastro de contas" icon="fa-sync" onClick={this.reiniciarMailerService} />
						<Action className="rotina-button" key={1} disabled={this.state.loading & (1 << 1)} label={this.state.loading & (1 << 1) ? "Processando..." : "Consolidar Acessos"} icon={this.state.loading & (1 << 1) ? "fa-spinner fa-spin" : "fa-scroll-old"} title="Consolida dados de acesso para apresentação nos gráficos da tela inicial" onClick={this.consolidarAcessos} />
						<Action className="rotina-button" key={2} disabled={!this.state.setupSistema?.flags?.includes("BAIXAR_ACESSOS_SEM_MOVIMENTO_RECENTE") || this.state.loading & (1 << 2)} label={this.state.loading & (1 << 2) ? "Processando..." : "Remover Presenças Expiradas"} title="Posiciona para fora pessoas cujo último acesso foi há mais de 3 dias e a posição dela ainda consta como dentro" icon={this.state.loading & (1 << 2) ? "fa-spinner fa-spin" : "fa-door-open"} onClick={this.removerPresencasExpiradas} />
						<Action className="rotina-button" key={3} disabled={this.state.loading & (1 << 3)} label={this.state.loading & (1 << 3) ? "Processando..." : "Organizar Registros"} icon={this.state.loading & (1 << 3) ? "fa-spinner fa-spin" : "fa-vacuum"} title="Exclui permanentemente agendas temporárias encerradas e expiradas, bloqueios expirados, organiza nomes de pessoas, ..." onClick={this.organizarRegistros} />
						<Action className="rotina-button" icon="fa-robot" onClick={this.recarregarBots} disabled={this.state.loading & (1 << 4)} key={4} label="Recarregar Bots" title="Recarrega a lista de bots mensageiros" />
						<Action className="rotina-button" mode="delete" key={5} label="Atualizar Hash" title="Atualizar Hash" icon="fa-calculator" onClick={this.atualizarHash} />
					</div>
					<div className="ui-g-12" style={{display: "flex"}}>
						<Action icon="fa fa-users" onClick={this.inicializarManutencaoPeriodica} className="rotina-button" key={6} label="Zerar Manutenção Periódica" title="Cria os registros de inicialização das manutenções periódicas" />
						<div className="ui-g-2 ui-g ui-g-nopad">
							<DatePicker label="Início" name="inicio" value={this.state.inicio} onChange={this.handleChange} grid={6} />
							<DatePicker label="Fim" name="fim" value={this.state.fim} onChange={this.handleChange} grid={6} />
						</div>
						<Action disabled={!this.state.setupSistema?.flags?.includes("ARQUIVAR_REGISTROS_DE_ACESSO_HISTORICOS")} className="rotina-button" key={7} icon="fa-boxes" label="Arquivar Logs Acesso" title="Move logs de acesso com mais de um ano para a tabela histórica" onClick={moverLogsAcessoHistoricos}/>
						<Action disabled className="rotina-button" key={8} label="" title="" />
						<Action disabled className="rotina-button" key={9} label="" title="" />
						<Action className="rotina-button" mode="delete" key={10} label="Apagar Hash Duplicado" title="Apagar Hash Duplicado" icon="fa-trash-alt" onClick={this.removerHash} />
					</div>
					<div className="ui-g-12" style={{textAlign: "right", cursor: "pointer"}}>
						<span style={{fontSize: "11px"}} onClick={() => ModalRealm.showDialog(<AboutDialog/>)}>Sobre</span>
					</div>
				</PanelContent>
			</TabPanel>
		);
		return (
			<div>
				<Panel header="Configuração do Sistema">
					<TabView onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						{tabs}
					</TabView>
					<PanelFooter>
						<Action onClick={() => {
							this.salvarSetupCondominio();
							this.salvarSetupSistema();
							this.salvarSetupPortalCondomino();
						}} label="Salvar" icon="fa-save" mode="post" />
					</PanelFooter>
				</Panel>
				<ModalRealm />
				<div className="ui-tzm-pagebottom"/>
			</div>
		);
	}

	recarregarBots = () => {
		httpPost("/messenger-bots/recarregar").then(() => {
			this.setState({loading: this.state.loading & ~(1 << 4)});
			ModalRealm.showInformacao("info", ["Processo concluído com sucesso!"]);
		});
	}

	modelosAgendaColumns = [
		<Column header="Descrição" field="descricao" style={{width: "20em"}} />,
		<Column header="Modelos de Acesso" body={(modeloAgenda) => {
			return modeloAgenda.modelosAcesso.map((ma) => <div key={ma.id}>{ma.descricao}</div>);
		}} style={{width: "20em"}} />,
		<Column header="Opções" body={(modeloAgenda) => {
			return modeloAgenda.flags.concat(modeloAgenda.agendaFlags).map((f) => MModeloAgenda.MapFlags[f] || MAgenda.MapFlags[f]).join(", ");
		}} style={{width: "*"}} />,
		<Column header="Duração" style={{width: "9em"}} body={(modeloAgenda) => {
			switch (Number(modeloAgenda.duracao)) {
				case -1:
					return "Até o final do dia";
				case 0:
					return "Indeterminada";
				default:
					return `${modeloAgenda.duracao} minutos`;
			}
		}} />,
		<Column header="Ações" style={{width: "5em", textAlign: "center"}} body={(modeloAgenda) => {
			return (
				<div>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Modelo de Agenda" onClick={() => this.editarModeloAgenda(modeloAgenda)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Modelo de Agenda" onClick={() => this.removerModeloAgenda(modeloAgenda)} /> : null}
				</div>
			);
		}} />
	];

	editarModeloAgenda = (modeloAgenda) => {
		ModalRealm.showDialog(<EditarModeloAgenda modeloAgenda={modeloAgenda} key={Math.random()} onModalClose={(modeloAgenda) => {
			const setupCondominio = this.state.setupCondominio;
			setupCondominio.modelosAgenda = setupCondominio.modelosAgenda.filter((ma) => ma.id !== modeloAgenda.id);
			setupCondominio.modelosAgenda.push(modeloAgenda);
			this.setState({setupCondominio});
		}} />);
	}

	removerModeloAgenda = (modeloAgenda) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este modelo de agenda?", () => {
			const setupCondominio = this.state.setupCondominio;
			if (modeloAgenda.id) {
				setupCondominio.modelosAgenda = setupCondominio.modelosAgenda.filter((ma) => ma.id !== modeloAgenda.id);
			} else {
				setupCondominio.modelosAgenda = setupCondominio.modelosAgenda.filter((ma) => ma._key !== modeloAgenda._key);
			}
			this.setState({setupCondominio});
		});
	}

	modelosAgendaAddButton = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
		ModalRealm.showDialog(<EditarModeloAgenda modeloAgenda={MModeloAgenda.Modelo()} key={Math.random()} onModalClose={(modeloAgenda) => {
			const setupCondominio = this.state.setupCondominio;
			setupCondominio.modelosAgenda = setupCondominio.modelosAgenda.filter((ma) => ma.id !== modeloAgenda.id);
			setupCondominio.modelosAgenda.push(modeloAgenda);
			this.setState({setupCondominio});
		}} />);
	}} />;

	editarTexto = (texto = MTexto.Modelo()) => {
		ModalRealm.showDialog(<EditarTexto texto={texto} key={Math.random()} onModalClose={(texto) => {
			const {setupCondominio} = this.state;
			setupCondominio.textos = setupCondominio.textos.filter(t => texto.finalidade !== t.finalidade);
			setupCondominio.textos.push(texto);
			this.setState({setupCondominio});
		}} />);
	}

	textosAddButton = <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarTexto()} />;

}

function printAplicacoesDocumento(tipoDocumento) {
	switch (tipoDocumento.aplicacao) {
		case "FISICA": return <img className="ui-action-icon" key={0} src={iconePessoa} alt="" title="Aplicável em Pessoa Física"/>;
		case "JURIDICA": return <img className="ui-action-icon" key={1} src={iconeEmpresa} alt="" title="Aplicável em Pessoa Jurídica (Empresa)"/>;
		case "VEICULO": return <img className="ui-action-icon" key={2} src={iconeVeiculo} alt="" title="Aplicável em Veículo"/>;
		case "AGENDA": return <img className="ui-action-icon" key={2} src={iconeAgenda} alt="" title="Aplicável em Agenda"/>
		default:
			return null;
	}
}

function moverLogsAcessoHistoricos() {
	httpGet("/logs-acesso-historicos/mover").then(() => ModalRealm.showInformacao("info", ["Logs movidos com sucesso!"]));
}
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {SelectEquipamento} from "../../select/SelectEquipamento";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {GUI} from "../../utils/Constants";
import {LocalTime} from "../../utils/DateUtils";
import {MCredencial} from "../../utils/models/MCredencial";
import {MLogAcesso} from "../../utils/models/MLogAcesso";
import {MMonitoramento} from "../../utils/models/MMonitoramento";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {MonitoramentoService} from "../servicos/MonitoramentoService";
import {PeriodoService} from "../servicos/PeriodoService";
import {EditarMonitoramento} from "./EditarMonitoramento";

export class Monitoramento extends React.Component {

	state = {
		monitoramentos: [],
		firstResult: 0
	};

	monitoramentoService = new MonitoramentoService();
	periodoService = new PeriodoService();
	security = UserData.security("MON", true);

	editarMonitoramento = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.monitoramentoService.buscar(rowData).then((monitoramento) => {
				ModalRealm.showDialog(<EditarMonitoramento key={Math.random()} monitoramento={monitoramento} onSave={this.listarMonitoramentos} />);
			});
		} else {
			ModalRealm.showDialog(<EditarMonitoramento key={Math.random()} monitoramento={null} onSave={this.listarMonitoramentos} />);
		}
	}

	excluirMonitoramento = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este monitoramento?", () => this.monitoramentoService.excluir(rowData).then(this.listarMonitoramentos));
	}

	listarMonitoramentos = () => {
		let params = [];
		if (this.state.pessoa) params.push(`pessoas=id:${this.state.pessoa.id}`);
		if (this.state.equipamento) params.push(`equipamentos=id:${this.state.equipamento.id}`);
		this.monitoramentoService.listar(`search=${this.state.excluidos ? "" : ";status:ATIVADO"}${params.length ? `;${params.join("&")}` : ""}`, {page: 0, size: GUI.maxResults}).then((monitoramentos) => this.setState({monitoramentos}));
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} header="Gatilhos" style={{width: "*"}} body={(m) => {
			return (
				<div>
					{m.atividades && m.atividades.length ? (
						<div>
							<b>Atividades</b>
							{m.atividades.map(e => <div>{MLogAcesso.MapAtividades[e]}</div>)}
						</div>
					) : null}
					{m.respostas && m.respostas.length ? (
						<div>
							<b>Respostas</b>
							{m.respostas.map(e => <div>{MLogAcesso.MapRespostas[e]}</div>)}
						</div>
					) : null}
					{m.eventos && m.eventos.length ? (
						<div>
							<b>Eventos</b>
							{m.eventos.map(e => <div>{MOcorrencia.MapEventos[e]}</div>)}
						</div>
					) : null}
					{m.formas && m.formas.length ? (
						<div>
							<b>Formas</b>
							{m.formas.map(e => <div>{MCredencial.MapFormas[e]}</div>)}
						</div>
					) : null}
					<div>
						<b>Período</b>
						{m.periodos && m.periodos.length ? m.periodos.map(p => <div>{`${this.periodoService.simplificarDiasSemana(p) + ", das " + LocalTime.withoutSeconds(p.inicio) + " às " + LocalTime.withoutSeconds(p.fim)}${p.feriados ? " (inclusive feriados)" : ""}`}</div>) : <div>Tempo integral</div>}
					</div>
				</div>
			);
		}} />,
		<Column key={1} header="Monitoráveis" style={{width: "*"}} body={(m) => {
			return (
				<div>
					{m.equipamentos && m.equipamentos.length ? (
						<div>
							<b>Equipamentos</b>
							{m.equipamentos.map(e => <div>{e.descricao}</div>)}
						</div>
					) : null}
					{m.pessoas && m.pessoas.length ? (
						<div>
							<b>Pessoas</b>
							{m.pessoas.map(e => <div>{e.nome}</div>)}
						</div>
					) : null}
					{m.lugares && m.lugares.length ? (
						<div>
							<b>Lugares</b>
							{m.lugares.map(e => <div>{e.descricao}</div>)}
						</div>
					) : null}
					{m.etiquetas && m.etiquetas.length ? (
						<div>
							<b>Etiquetas</b>
							{m.etiquetas.map(e => <div>{e.descricao}</div>)}
						</div>
					) : null}
				</div>
			);
		}} />,
		<Column key={2} header="Resultados" style={{width: "*"}} body={(m) => {
			return (
				<div>
					{m.disparos && m.disparos.length ? (
						<div>
							<b>Disparos</b>
							{m.disparos.map(e => <div>{MMonitoramento.MapDisparos[e]}</div>)}
						</div>
					) : null}
					{m.monitores && m.monitores.length ? (
						<div>
							<b>Monitores</b>
							{m.monitores.map(e => <div>{e.nome}</div>)}
						</div>
					) : null}
				</div>
			);
		}} />,
		<Column key={3} header="Ações" field="acoes" style={{width: "6em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Monitoramento" onClick={(event) => this.editarMonitoramento(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Monitoramento" onClick={(event) => this.excluirMonitoramento(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Monitoramento" onClick={(event) => this.restaurarMonitoramento(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Monitoramento" onClick={(event) => this.eliminarMonitoramento(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/monitoramentos", entidade);
	}

	restaurarMonitoramento = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este monitoramento?", () => {
			if (rowData != null && rowData.id != null) {
				this.monitoramentoService.buscar(rowData).then((monitoramento) => {
					monitoramento.status = "ATIVADO";
					this.monitoramentoService.salvar(monitoramento).then(this.listarMonitoramentos);
				});
			}
		});
	}

	eliminarMonitoramento = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este monitoramento?", () => this.monitoramentoService.eliminar(rowData).then((result) => {
			if (result) this.listarMonitoramentos(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este monitoramento: há outros registros dependentes dele no sistema."]);
		}));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Monitoramentos">
					<PanelContent>
						<SelectPessoaFisica appendTo={null} onChange={this.handleUpdate} name="pessoa" value={this.state.pessoa} label="Pessoa" grid={6} />
						<SelectEquipamento appendTo={null} onChange={this.handleUpdate} name="equipamento" value={this.state.equipamento} label="Equipamento" grid={6} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarMonitoramentos} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.monitoramentos} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Monitoramento" icon="fa-plus" onClick={this.editarMonitoramento} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listMonitoramentos}
					onCtrlN={() => window.location.pathname === "/monitoramentos" && this.security.create && this.editarMonitoramento()}
				/>
				<ModalRealm/>
			</div>
		);
	}

}

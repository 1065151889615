import React from "react";

const websocketPath = process.env.REACT_APP_WSC_URL;

export class SafetyWebSocket extends React.Component {

	state = {
		connected: false,
		failed: false
	};

	componentDidMount() {
		this.connect();
		this.keepAlive = setInterval(() => {
			if (this.state.connected) {
				this.socket.send("OK");
			}
		}, 10000);
	}

	keepAlive = null;

	connect = () => {
		const params = this.props.params != null ? "?" + Object.keys(this.props.params).map(k => `${k}=${this.props.params[k]}`).join("&") : "";
		this.socket = new WebSocket(`${websocketPath}/websocket${this.props.path}/websocket${params}`);
		this.socket.onmessage = this.onMessageReceived;
		this.socket.onopen = () => {
			const {failed} = this.state;
			this.setState({connected: true, failed: false}, () => {
				if (this.props.onConnect) {
					this.props.onConnect(true, failed);
				}
				if (this.props.identifier) {
					this.socket.send(this.props.identifier);
				}
			});
		}
		this.socket.onerror = () => {
			this.setState({failed: true}, () => {
				try {
					this.socket.close();
				} catch (e) {
					console.error(e);
				}
			});
		}
		this.socket.onclose = () => {
			this.setState({connected: false});
			if (this.props.onConnect) {
				this.props.onConnect(false, this.state.failed);
			}
			setTimeout(this.connect, 500);
		};
	}

	onMessageReceived = (message) => {
		if (this.props.onMessage) {
			this.props.onMessage(JSON.parse(message.data));
		} else {
			console.log(message.data);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.keepAlive);
		this.socket.onclose = null;
		this.socket.close();
	}

	render() {
		if (this.state.connected) {
			return null;
		}
		return (
			<div className="tzm-websocket-loading">
				<i className="fa fa-spin fa-spinner" style={{marginRight: "4px"}} /> Conectando
			</div>
		);
	}

}

import React, {Component} from "react";
import {UserData} from "../../utils/UserData";
import {withPage} from "../../Page";
import "./MonitorPresenca.css";
import {Panel} from "primereact/components/panel/Panel";
import {ModalRealm} from "../commons/ModalRealm";
import {byRegistroDesc, isFinalidadeIdentificacao, StringUtils} from "../../utils/StringUtils";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {httpGet} from "../../utils/Request";
import {SafetyWebSocket} from "../../utils/WebSocket";
import moment from "moment";

const MAX_PEOPLE_PER_MONITOR = 37;

export const MonitorPresenca = withPage(class extends Component {

	state = {
		atrasos: {
			"1": []
		},
		index: null,
		keys: [],
		keysIndex: 0,
		lugar: null,
		atencao: "5",
		listIndex: 0,
		counter: 0,
		total: 0,
		atrasados: 0,
		logsPresenca: [],
		logsPresencaUnidade: [],
		pessoa: null
	};

	security = UserData.security("MNA", true);

	componentDidMount() {
		this.props.onPage({name: "title", value: "Monitor de Presenças"});
		this.props.onPage({name: "sidebar", value: -1});
		this.refreshPage = setInterval(() => {
			let {counter} = this.state;
			if (++counter % 10 === 0) {
				let {keys, keysIndex, listIndex} = this.state;
				++keysIndex;
				++listIndex;
				this.setState({index: keys[keysIndex % keys.length], keysIndex, listIndex, counter});
			} else {
				this.setState({counter});
			}
		}, 1000);
		this.listarOcorrencias();
	}

	componentWillUnmount() {
		clearTimeout(this.refreshList);
		clearInterval(this.refreshPage);
		this.props.onPage({name: "title", value: "Sistema de Segurança"});
		this.props.onPage({name: "sidebar", value: 0});
	}

	listarOcorrencias = () => {
		const queryParams = {};
		window.location.search.replace("?", "").split("&").forEach(qv => {
			var keyValue = qv.split("=");
			queryParams[keyValue[0]] = keyValue[1];
		});
		console.log(queryParams);
		this.setState({lugar: queryParams.lugar, atencao: queryParams.atencao || "5"}, () => {
			httpGet(`/monitor-presencas/count?${this.state.lugar ? `&lugares=${this.state.lugar}` : ""}`).then(total => this.setState({total}, () => {
				httpGet(`/monitor-presencas/count-unidade?${this.state.lugar ? `&lugares=${this.state.lugar}` : ""}`).then((logsPresencaUnidade) => {
					const {total} = this.state;
					const contagem = total - logsPresencaUnidade.map(lpu => lpu.contagem).reduce((a, b) => a + b, 0);
					logsPresencaUnidade = logsPresencaUnidade.sort((a, b) => a.etiqueta?.descricao?.localeCompare(b.etiqueta?.descricao));
					if (Number(contagem) > 0) {
						logsPresencaUnidade.push({etiqueta: {descricao: "Outros"}, contagem});
					}
					this.setState({total, logsPresencaUnidade});
				});
			}));
			httpGet(`/monitor-presencas/count-atencao?data=${this.state.atencao}${this.state.lugar ? `&lugares=${this.state.lugar}` : ""}`).then((logsPresenca) => this.setState({logsPresenca}));
			httpGet(`/ocorrencias?search=data>${moment().add(-1, "days").format("YYYY-MM-DDTHH:mm:ss")};evento:PRESENCA;justificativa:null${this.state.lugar ? `&lugar=id:${this.state.lugar}` : ""}`).then((ocorrencias) => {
				const atrasos = {};
				const queryPages = {};
				ocorrencias = ocorrencias.sort((a, b) => a.pessoa.nome.localeCompare(b.pessoa.nome));
				for (const ocorrencia of ocorrencias) {
					if (!ocorrencia.pessoa?.gestor?.id) {
						ocorrencia.pessoa.gestor = {
							id: 0,
							nome: " Gestor não vinculado"
						};
					}
					let index;
					if (queryPages.hasOwnProperty(ocorrencia.pessoa.gestor.id)) {
						index = queryPages[ocorrencia.pessoa.gestor.id];
					} else {
						index = 0;
						queryPages[ocorrencia.pessoa.gestor.id] = 0;
					}
					const hid = `${ocorrencia.pessoa.gestor.nome};${index};${JSON.stringify(ocorrencia.pessoa.gestor)}`;
					if (atrasos.hasOwnProperty(hid)) {
						if (atrasos[hid].length < MAX_PEOPLE_PER_MONITOR) {
							atrasos[hid].push(ocorrencia);
						} else {
							queryPages[ocorrencia.pessoa.gestor.id] += 1;
							atrasos[hid].push(ocorrencia);
						}
					} else {
						atrasos[hid] = [ocorrencia];
					}
				}
				const keys = Object.keys(atrasos).sort((a, b) => a.localeCompare(b));
				this.setState({atrasos, keys, atrasados: ocorrencias.length, index: keys[this.state.keysIndex % keys.length]});
				this.refreshList = setTimeout(this.listarOcorrencias, Math.max(keys.length * 10000, 60000));
			});
		});
	}

	printLogsUnidade = (lpu) => {
		return (
			<div>
				<h1 className={`ui-pattern-${lpu.etiqueta.padrao} ui-pattern-a${lpu.etiqueta.efeito}`} style={{backgroundColor: lpu.etiqueta.fundo, color: lpu.etiqueta.fonte}}>{lpu.etiqueta.descricao}</h1>
				<h2>{lpu.contagem}</h2>
			</div>
		);
	}

	render() {
		return (
			<div className="monitor-presenca-container">
				<div className="monitor-presenca-header">
					<div className="monitor-presenca-semaforo">
						<div className="monitor-presenca-semaforo-item green">{this.state.total - this.state.logsPresenca.length - this.state.atrasados}</div>
						<div className="monitor-presenca-semaforo-item yellow">{this.state.logsPresenca.length}</div>
						<div className="monitor-presenca-semaforo-item red">{this.state.atrasados}</div>
						<div className="monitor-presenca-semaforo-item">{this.state.total}</div>
					</div>
					<Panel header="Unidades" className="monitor-presenca-unidades">
						<div className="monitor-presenca-lpus-container">
							{this.state.logsPresencaUnidade.map(this.printLogsUnidade)}
						</div>
					</Panel>
				</div>
				<div className="monitor-presenca-atrasos">
					{this.printMonitor()}
					{this.state.index ? this.state.atrasos[this.state.index].map(a => a.pessoa).map(this.printAtrasos) : null}
				</div>
				<SafetyWebSocket path="/logs-acesso" onMessage={this.receberLogAcesso}/>
				<ModalRealm />
			</div>
		);
	}

	receberLogAcesso = (logAcesso) => {
		if (logAcesso.pessoa) {
			switch (logAcesso.atividade) {
				case "PASSAGEM":
					httpGet(`/monitor-presencas/count?${this.state.lugar ? `&lugares=${this.state.lugar}` : ""}${this.state.pessoa ? `&monitores=${this.state.pessoa.id}` : ""}`).then(total => this.setState({total}, () => {
						if (logAcesso.destino && logAcesso.destino.tipo !== "EXTERNO" && (!this.state.lugar || logAcesso.destino.id !== Number(this.state.lugar))) {
						} else {
							const {atrasos} = this.state;
							Object.keys(atrasos).forEach(k => atrasos[k] = atrasos[k].filter(a => a.pessoa.id !== logAcesso.pessoa.id));
							const atrasados = Object.keys(atrasos).map(k => atrasos[k].length).reduce((a, b) => a + b, 0);
							this.setState({atrasos, atrasados});
						}
					}));
					break;
				default:
					break;
			}
		}
	}

	printMonitor = () => {
		const pages = Object.keys(this.state.atrasos).length;
		if (this.state.index) {
			const json = this.state.index.split(";")[2];
			if (json) {
				const pessoa = this.state.index !== "0" ? JSON.parse(json) : null;
				return (
					<div key={pessoa.id} className="monitor-presenca-atrasos-item monitor">
						{this.printPessoa(pessoa)}
						<span className="monitor-presenca-counter">{10 - this.state.counter % 10}</span>
						<span className="monitor-presenca-page-counter">Página {(this.state.keysIndex % pages) + 1} de {pages}</span>
					</div>
				);
			}
		}
		return null;
	}

	printPessoa = (pessoa) => {
		let identificacoes = (pessoa.fotos || []).filter(isFinalidadeIdentificacao).sort(byRegistroDesc);
		return (
			<div>
				<img src={identificacoes[0]} alt="" className="ui-tzm-logacesso-foto" />
				<div className="monitor-presenca-atrasos-text">
					<div>{StringUtils.simplificarNome(pessoa.nome)}</div>
					<div>
						{pessoa.telefone ? <span><i className="fal fa-phone" /> {pessoa.telefone}</span> : null}
						{pessoa.celular ? <span> {pessoa.telefone ? " - " : ""}<i className="fal fa-phone" /> {pessoa.celular}</span> : null}
					</div>
					<div>{(pessoa.etiquetas || []).map(e => <EtiquetaChip etiqueta={e} />)}</div>
				</div>
			</div>
		);
	}

	printAtrasos = (pessoa) => {
		return (
			<div key={pessoa.id} className="monitor-presenca-atrasos-item">
				{this.printPessoa(pessoa)}
			</div>
		);
	}

});

import {httpGet, httpPost} from "../../utils/Request";

export class GuaritaService {

	listarAcessos(criterio) {
		return httpPost("/guarita/pessoas", criterio);
	}

	async listarAcessosGerenciaveis(logAcesso) {
		return await httpPost(`/guarita/adicionais`, logAcesso);
	}

	async listarLogsAcessoGerenciaveis() {
		return await httpGet("/guarita/logs-pendentes");
	}

	async listarOutrosAcessos(setor) {
		return await httpGet(`/guarita/outros-acessos/${setor}`);
	}

}

import React from "react";
import {MVeiculo} from "../../utils/models/MVeiculo";
import "./PlacaMercosul.css";
import {If} from "../../utils/If";

export class PlacaMercosul extends React.Component {

	render() {
		return (
			<div className="ui-g ui-g-nopad" style={{borderRadius: "4px", border: "1px solid rgba(0, 0, 0, .2"}}>
				<div className="ui-g-2">
					<Placa veiculo={this.props.veiculo}/>
				</div>
				<div className="ui-g-10" style={{textAlign: "center"}}>
					{this.props.veiculo.fabricante?.nome || "Fabricante"} - {this.props.veiculo.modelo || "Modelo"} {MVeiculo.MapCores[this.props.veiculo.cor]}<br/>
					<div style={{fontSize: "11px"}}>{this.props.veiculo.proprietario?.nome || ""}</div>
					<If condition={this.props.veiculo.carretas?.length}>
						<span style={{fontSize: "9px"}}>Carretas</span> {this.props.veiculo.carretas?.map(c => <div className="tzm-miniplaca">{c.placa}</div>)}
					</If>
				</div>
			</div>
		);
	}

}


export class Placa extends React.Component {

	resolveColor(categoria) {
		switch (categoria) {
			case "PARTICULAR":
				return "#000000";
			case "COMERCIAL":
				return "#c8102e";
			case "OFICIAL":
				return "#0033a0";
			case "DIPLOMATICO":
				return "#f2a900";
			case "ESPECIAL":
				return "#007a53";
			case "COLECAO":
				return "#968f8b";
			default:
				return "#000000";
		}
	}

	resolvePais(placa) {
		if (/[A-Z]{3}[0-9][A-Z][0-9]{2}/g.test(placa)) return "BRASIL";
		if (/[A-Z]{2}[0-9]{3}[A-Z]{2}/g.test(placa)) return "ARGENTINA";
		if (/[A-Z]{3}[0-9]{4}/g.test(placa)) return "URUGUAY";
		if (/[0-9]{3}[A-Z]{4}/g.test(placa)) return "PARAGUAY";
		return "MERCOSUL";
	}

	render() {
		return (
			<div className="placa-mercosul" {...this.props} style={{...this.props.style, borderColor: this.resolveColor(this.props.veiculo.categoria)}}>
				<div className="placa-label-pais">{this.resolvePais(this.props.veiculo.placa)}</div>
				<div className="placa-screw"/>
				<div className="placa-screw right"/>
				<div className="placa-mercosul-content" style={{color: this.resolveColor(this.props.veiculo.categoria)}}>{this.props.veiculo.placa && this.props.veiculo.placa.length ? this.props.veiculo.placa.toUpperCase() : "ABC0000"}</div>
			</div>
		);
	}

}

import saveAs from "file-saver";
import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {MEquipamento} from "../../utils/models/MEquipamento";
import {Action} from "../commons/Action";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {TextField} from "../commons/TextField";
import {labelLogManutencaoTipo, ManutencaoService} from "../servicos/ManutencaoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {ConfirmarEnvioGeral} from "./ConfirmarEnvioGeral";
import {CheckSquare} from "../commons/CheckSquare";
import {MultiCombobox} from "../commons/MultiCombobox";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {Combobox} from "../commons/Combobox";
import {byRegistroDesc, secondsToString, toDateTimeWithSeconds, toTimeWithSeconds} from "../../utils/StringUtils";
import iconeLogs from "../../media/icones/logs.png";
import {Fetch} from "../../utils/Fetch";
import iconSuccess from "../../media/icones/ok.png";
import iconError from "../../media/icones/eliminar.png";
import iconWarning from "../../media/icones/avisos.png";
import iconDispositivo from "../../media/icones/dispositivo.png";
import iconWaiting from "../../media/icones/modelos-acesso.png";
import iconAll from "../../media/icones/todos.png";
import iconStop from "../../media/icones/ctrl_parar.png";
import iconPause from "../../media/icones/ctrl_pausar.png";
import iconResume from "../../media/icones/ctrl_iniciar.png";
import iconTrash from "../../media/icones/excluir.png";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import { UserData } from "../../utils/UserData";

const baseURL = process.env.REACT_APP_API_URL || "";

export class Manutencao extends React.Component {

	state = {
		pessoas: [],
		equipamentos: [],
		tabIndex: 0,
		queryPessoas: "",
		queryEtiquetas: [],
		firstResult: 0,
		manutencoes: [],
		categoria: "ACESSO",
		isLoading: false,
		expandidos: []
	};

	manutencaoService = new ManutencaoService();
	pessoaFisicaService = new PessoaFisicaService();

	componentDidMount() {
		this.listEquipamentos();
		this.listarManutencoes();
	}

	listEquipamentos() {
		// httpGet("/equipamentos", {search: `status:ATIVADO;familia!NENHUMA;categoria:${this.state.categoria}`, page: 0, size: 1000, sort: "descricao"}).then(async equipamentos => {
		this.manutencaoService.listarEquipamentoManutencao({categoria: this.state.categoria}).then(async equipamentos => {
			const queueStatuses = await this.manutencaoService.isAllPaused();
			for (const queueStatus of queueStatuses) {
				equipamentos.filter(e => e.id === queueStatus.id).forEach(e => e._paused = queueStatus.paused);
			}
			this.setState({equipamentos});
		});
	}

	enviarComando = async (metodo, options = {confirm: false, waitFor: true}) => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			if (options.confirm) {
				ModalRealm.showConfirmacao("Tem certeza de que deseja executar este comando?", async () => {
					if (options.waitFor) {
						await this.manutencaoService[metodo](this.state.equipamentos.filter(e => e._selecionado));
					} else {
						this.manutencaoService[metodo](this.state.equipamentos.filter(e => e._selecionado));
					}
					this.tabChange({index: 1});
				});
			} else {
				if (options.waitFor) {
					await this.manutencaoService[metodo](this.state.equipamentos.filter(e => e._selecionado));
				} else {
					this.manutencaoService[metodo](this.state.equipamentos.filter(e => e._selecionado));
				}
				this.tabChange({index: 1});
			}
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	enviarPessoas = (entidade) => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.enviarPessoa({equipamentos: this.state.equipamentos.filter(e => e._selecionado), pessoas: entidade});
			this.tabChange({index: 1});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	coletarFacial = (pessoa) => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.coletarFacial({equipamentos: this.state.equipamentos.filter(e => e._selecionado), pessoas: [pessoa]}).then(() => {
				this.tabChange({index: 1});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	removerPessoas = (entidade) => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.removerPessoa({equipamentos: this.state.equipamentos.filter(e => e._selecionado), pessoas: entidade}).then(() => {
				this.tabChange({index: 1});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	limparMemoria = () => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.limparMemoria({equipamentos: this.state.equipamentos.filter(e => e._selecionado)}).then(() => {
				this.tabChange({index: 1});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	reiniciar = () => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.reboot({equipamentos: this.state.equipamentos.filter(e => e._selecionado)}).then(() => {
				this.tabChange({index: 1});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	ativarModoEmergencia = () => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.ativarModoEmergencia({equipamentos: this.state.equipamentos.filter(e => e._selecionado)}).then(() => {
				this.tabChange({index: 1});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	desativarModoEmergencia = () => {
		if (this.state.equipamentos.some(e => e._selecionado)) {
			this.manutencaoService.desativarModoEmergencia({equipamentos: this.state.equipamentos.filter(e => e._selecionado)}).then(() => {
				this.tabChange({index: 1});
			});
		} else {
			ModalRealm.showInformacao("warn", ["Selecione pelo menos um equipamento para executar a manutenção!"]);
		}
	}

	tabChange = (event) => {
		this.setState({tabIndex: event.index});
	}

	listarManutencoes = () => {
		this.setState({isLoading: true});
		this.manutencaoService.listarLogsManutencao().then(manutencoes => {
			this.setState({manutencoes: manutencoes.sort(byRegistroDesc), isLoading: false});
		});
	}

	manutencaoExpansionTemplate = (rowData) => {
		if (rowData.status !== "PENDENTE") {
			return (
				<div
					className="ui-tzm-terminal-panel">{rowData.status === "SUCESSO" ? `Manutenção executada com sucesso` : rowData.mensagem?.split("#BR#").map(m =>
					<div>{m}</div>)}</div>
			);
		} else return null;
	}

	listarPessoas = () => {
		const query = [];
		if (this.state.queryPessoas?.length) query.push(`pessoas=${this.state.queryPessoas}`);
		if (this.state.queryEtiquetas?.length) query.push(`etiquetas=${this.state.queryEtiquetas.map(e => e.id).join(",")}`);
		this.manutencaoService.listarPessoas(query.join("&")).then(pessoas => {
			const selecionadas = this.state.pessoas?.filter(p => p._selecionado);
			pessoas = pessoas.filter(p => !selecionadas.some(x => x.id === p.id));
			this.setState({pessoas: [...selecionadas, ...pessoas]});
		});
	}

	queryTimeout = null;

	changePage = (event) => this.setState({firstResult: event.first});

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
		if (this.queryTimeout) {
			clearTimeout(this.queryTimeout);
		}
		this.queryTimeout = setTimeout(this.listarPessoas, 800);
	}

	handleChangeItemEquipamento = (event) => {
		const {equipamentos} = this.state;
		equipamentos[event.index][event.name] = event.value;
		this.setState({equipamentos});
	}

	handleChangeItemPessoa = (event) => {
		const {pessoas} = this.state;
		pessoas[event.index][event.name] = event.value;
		this.setState({pessoas});
	}

	toggleEquipamentos = () => {
		const {equipamentos} = this.state;
		const checked = !equipamentos.every(e => e._selecionado);
		equipamentos.forEach(e => e._selecionado = checked && !e._hidden);
		this.setState({equipamentos});
	}

	togglePessoas = () => {
		const {pessoas} = this.state;
		const checked = !pessoas.every(e => e._selecionado);
		pessoas.forEach(e => e._selecionado = checked);
		this.setState({pessoas});
	}

	filterEquipamentos = (event) => {
		let {familias, equipamentos} = this.state;
		if (event.value?.length) {
			familias = event.value;
			equipamentos.forEach(e => {
				e._hidden = !e._selecionado && !familias.includes(e.familia);
			});
		} else {
			familias = [];
			equipamentos.forEach(e => e._hidden = false);
		}
		this.setState({familias, equipamentos});
	}

	queueManutencao = logManutencao => {
		let {manutencoes} = this.state;
		const index = manutencoes.findIndex(m => m.codigo === logManutencao.codigo);
		if (index > -1) {
			manutencoes[index] = logManutencao;
		} else {
			manutencoes.unshift(logManutencao);
			manutencoes = manutencoes.sort(byRegistroDesc);
		}
		this.setState({manutencoes});
	}

	handleChangeCategoria = event => {
		this.setState({categoria: event.value}, this.listEquipamentos);
	}

	websocketConnected = (connected, failed) => {
		if (connected && failed) {
			this.listarManutencoes();
		}
	}

	baixarLogManutencao = arquivo => {
		Fetch.Get(`${baseURL}/arquivos/download/${arquivo.fileName}`, Fetch.BuildHeadersText()).then(response => response.blob().then(blob => {
			const file = new Blob([blob], {type: "text/plain"});
			const data = window.URL.createObjectURL(file);
			saveAs(data, arquivo.fileName);
		}));
	}

	pausarManutencoes = async (e) => {
		const success = await this.manutencaoService.togglePause(e.id);
		if (success) {
			const {equipamentos} = this.state;
			equipamentos.filter(eq => eq.id === e.id).forEach(e => e._paused = !e._paused);
			this.setState({equipamentos});
		}
	}

	interromperManutencoes = async (e, options = {confirm: true}) => {
		if (options.confirm) {
			ModalRealm.showConfirmacao("Tem certeza de que deseja interromper as manutenções deste equipamento?", () => this.runInterromperManutencoes(e));
		} else {
			await this.runInterromperManutencoes(e);
		}
	}

	runInterromperManutencoes = async e => {
		await this.manutencaoService.interromperManutencoes(e.id);
		this.listarManutencoes();
		const {equipamentos} = this.state;
		equipamentos.filter(eq => eq.id === e.id).forEach(e => e._paused = false);
		this.setState({equipamentos});
	}

	limparLogsManutencao = async (e, options = {confirm: true}) => {
		if (options.confirm) {
			ModalRealm.showConfirmacao("Tem certeza de que deseja excluir todos os registros de manutenção deste equipamento?", () => this.runLimparLogsManutencao(e));
		} else {
			await this.runLimparLogsManutencao(e);
		}
	}

	runLimparLogsManutencao = async e => {
		await this.manutencaoService.excluirLogsManutencao(e);
		let {manutencoes} = this.state;
		manutencoes = manutencoes.filter(lm => lm.equipamento?.id !== e.id);
		this.setState({manutencoes});
	}

	pausarTodasManutencoes = async () => {
		for (const e of this.state.equipamentos) {
			await this.pausarManutencoes(e);
		}
	}

	interromperTodasManutencoes = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja interromper todas as manutenções?", async () => {
			for (const e of this.state.equipamentos) {
				await this.interromperManutencoes(e, {confirm: false});
			}
		});
	}

	limparTodosLogsManutencao = () => {
		ModalRealm.showConfirmacao("Tem certeza de que excluir todos os registros de manutenção?", async () => {
			await this.manutencaoService.excluirTodosLogsManutencao();
			this.setState({manutencoes: []});
		});
	}

	manutencoesHeaderColumnGroup = () => {
		const someIsPaused = this.state.equipamentos?.some(e => e._paused);
		return (
			<ColumnGroup>
				<Row>
					<Column style={{width: "2.5em"}} rowSpan={2} expander/>
					<Column rowSpan={2} sortable field="descricao" header="Descrição"/>
					<Column style={{width: "12em"}} rowSpan={2} sortable field="familia" header="Família"/>
					<Column style={{width: "10em"}} rowSpan={2} sortable field="ip" header="IP"/>
					<Column style={{width: "7em"}} field="fila" header="Fila"/>
					<Column style={{width: "6em"}} rowSpan={2} field="pendentes" header="Pendentes"/>
					<Column style={{width: "11.5em"}} rowSpan={2} field="concluidos" header="Concluídos"/>
					<Column style={{width: "4em"}} field="acoes" header="Ações"/>
				</Row>
				<Row>
					<Column header={(
						<div className="manutencoes-interromper-container">
							<div className="manutencoes-interromper" onClick={this.pausarTodasManutencoes}>
								<img src={someIsPaused ? iconResume : iconPause} alt="" title={someIsPaused ? "Continuar Manutenções" : "Suspender Manutenções"}/>
							</div>
							{
								this.state.manutencoes.some(lm => lm.status === "PENDENTE") ? (
									<div className="manutencoes-interromper" onClick={this.interromperTodasManutencoes}>
										<img src={iconStop} alt="" title="Interromper Manutenções"/>
									</div>
								) : null
							}
						</div>
					)}/>
					<Column header={(
						<div>
							{this.state.manutencoes.some(lm => lm.status !== "PENDENTE") ? <img title="Excluir Todos os Registros de Manutenção" className="ui-action-icon" src={iconTrash} alt="" onClick={this.limparTodosLogsManutencao}/> : null}
						</div>
					)}/>
				</Row>
			</ColumnGroup>
		);
	};

	render() {
		return (
			<div>
				<Panel header="Manutenção de Equipamentos">
					<TabView activeIndex={this.state.tabIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados Principais">
							<div className="manutencao-comandos">
								<Action icon="fal fa-clock" disabled={!this.state.equipamentos.some(e => e._selecionado)} mode="post" label="Enviar Relógio" onClick={() => this.enviarComando("enviarDatahora", {confirm: false, waitFor: false})} />
								<Action icon="fal fa-user-plus" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)} label="Enviar Pessoas" onClick={() => {
									if (this.state.pessoas.some(p => p._selecionado)) {
										this.enviarPessoas(this.state.pessoas.filter(p => p._selecionado));
									} else {
										ModalRealm.showDialog(<ConfirmarEnvioGeral mode="enviar" onYes={() => {
											this.enviarComando("enviarPessoas", {confirm: false, waitFor: false});
										}} />);
									}
								}} />
								<Action icon="fal fa-user-times" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado) || !this.state.pessoas.some(e => e._selecionado)} mode="delete" label="Remover Pessoas" onClick={() => {
									if (this.state.pessoas.some(p => p._selecionado)) {
										this.removerPessoas(this.state.pessoas.filter(p => p._selecionado));
									} else {
										this.enviarComando("removerPessoa", {confirm: true, waitFor: true});
									}
								}} />
								<Action icon="fal fa-trash-alt" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)} mode="delete" label="Limpar Memória" onClick={() => {
									if (this.state.equipamentos.some(e => e._selecionado)) {
										ModalRealm.showDialog(<ConfirmarEnvioGeral mode="excluir" onYes={() => {
											this.enviarComando("limparMemoria", {confirm: false, waitFor: false});
										}}/>);
									} else {
										ModalRealm.showInformacao("warn", ["Selecione ao menos um equipamento para executar o procedimento"]);
									}
								}} />
								<Action icon="fal fa-repeat" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)} mode="delete" label="Reiniciar" onClick={() => {
									if (this.state.equipamentos.some(e => e._selecionado)) {
										ModalRealm.showDialog(<ConfirmarEnvioGeral mode="reiniciar" onYes={() => {
											this.enviarComando("reiniciar", {confirm: false, waitFor: false});
										}}/>);
									} else {
										ModalRealm.showInformacao("warn", ["Selecione ao menos um equipamento para executar o procedimento"]);
									}
								}} />																				
								<Action icon="fal fa-table" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)} mode="secondary" label="Buscar Acessos" onClick={() => this.enviarComando("buscarAcessos")} />
								<Action
									disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)}
									mode="secondary"
									label="Buscar Digitais"
									icon="fal fa-fingerprint"
									onClick={() => {
										const equipamentosSelecionados = this.state.equipamentos.filter(e => e._selecionado);
										if (equipamentosSelecionados.length === 1) {
											ModalRealm.showDialog(<ConfirmarEnvioGeral mode="digitais" onYes={() => {
												this.enviarComando("buscarDigitais", {confirm: false, waitFor: true});
											}} />);
										} else {
											ModalRealm.showInformacao("warn", ["Selecione apenas um equipamento para executar o procedimento"]);
										}
									}}
								/>
								<Action mode="secondary" icon="fal fa-user-circle" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)} label="Coletar Facial" onClick={() => {
									if (this.state.pessoas.some(p => p._selecionado)) {
										this.state.pessoas.filter(p => p._selecionado).forEach(this.coletarFacial);
										this.tabChange({index: 1});
									} else {
										ModalRealm.showInformacao("warn", ["Você deve selecionar uma pessoa para fazer a coleta"]);
									}
								}} />
								<Action mode="delete" icon="fal fa-key" disabled={this.state.categoria !== "ACESSO" || !this.state.equipamentos.some(e => e._selecionado)} label="Trocar Senha" onClick={() => {
									if (this.state.equipamentos.some(e => e._selecionado)) {
										ModalRealm.showDialog(<ConfirmarEnvioGeral mode="trocarSenha" onYes={() => {
											this.enviarComando("trocarSenha", {confirm: false, waitFor: false});
										}}/>);
									} else {
										ModalRealm.showInformacao("warn", ["Selecione ao menos um equipamento para executar o procedimento"]);
									}
								}} />
							</div>
							{
								UserData.usuario.login === "admin" ?
									<div className="ui-g">
										<div className="manutencao-comandos">
											<Action icon="far fa-bell" disabled={this.state.categoria !== "ACESSO" 
												|| !this.state.equipamentos.some(e => e._selecionado)} mode="delete" label="Ativar Emergência" onClick={() => {
													if (this.state.equipamentos.some(e => e._selecionado)) {
														ModalRealm.showDialog(<ConfirmarEnvioGeral mode="ativarModoEmergencia" onYes={() => {
															this.enviarComando("ativarModoEmergencia", {confirm: false, waitFor: false});
														}}/>);
													} else {
														ModalRealm.showInformacao("warn", ["Selecione ao menos um equipamento para executar o procedimento"]);
													}
											}} />
											<Action icon="far fa-bell-slash" disabled={this.state.categoria !== "ACESSO" 
												|| !this.state.equipamentos.some(e => e._selecionado)} mode="delete" label="Desativar Emergência" onClick={() => {
													if (this.state.equipamentos.some(e => e._selecionado)) {
														ModalRealm.showDialog(<ConfirmarEnvioGeral mode="desativarModoEmergencia" onYes={() => {
															this.enviarComando("desativarModoEmergencia", {confirm: false, waitFor: false});
														}}/>);
													} else {
														ModalRealm.showInformacao("warn", ["Selecione ao menos um equipamento para executar o procedimento"]);
													}
											}} />
										</div>
									</div>
								:
									null
							}
							
							<div className="ui-g">
								<div className="ui-g-5 ui-g-nopad">
									<div className="ui-g">
										<Combobox grid={12} label="Categoria" name="categoria" value={this.state.categoria} options={MEquipamento.Categorias} onChange={this.handleChangeCategoria}/>
										<div className="ui-g-12">
											<DataTable header="Equipamentos" emptyMessage="Nenhum registro encontrado" value={this.state.equipamentos.filter(e => !e._hidden)}>
												<Column style={{width: "2.25em", textAlign: "center"}} header={
													<CheckSquare checked={this.state.equipamentos.every(e => e._selecionado)} onChange={this.toggleEquipamentos}/>
												} body={(e, i) => (
													<CheckSquare index={this.state.equipamentos.findIndex(d => e.id === d.id)} name="_selecionado" checked={e._selecionado} onChange={this.handleChangeItemEquipamento}/>
												)}/>
												<Column style={{width: "*"}} field="descricao" header="Descrição"/>
												<Column style={{width: "10em"}} field="ip" header="IP"/>
												<Column filter filterElement={
													<MultiCombobox defaultLabel="Todas" name="familias" value={this.state.familias} options={MEquipamento.Familias.filter(f => this.state.equipamentos?.some(e => e.familia === f.value))} onChange={this.filterEquipamentos}/>
												} field="familia" style={{width: "15em"}} header="Família" body={(e) => MEquipamento.MapFamilias[e.familia]}/>
											</DataTable>
										</div>
									</div>
								</div>
								<div className="ui-g-7 ui-g-nopad">
									<div className="ui-g">
										<TextField grid={6} label="Pessoa" name="queryPessoas" value={this.state.queryPessoas} onChange={this.handleChange} placeholder="Pesquise por nome ou qualquer documento" />
										<MultiSelectEtiqueta name="queryEtiquetas" grid={6} label="Etiquetas (em Agenda)" value={this.state.queryEtiquetas} onSelect={this.handleChange} />
										<PanelContent>
											<div className="ui-g-12">
												<DataTable paginator rows={50} rowsPerPageOptions={[10, 20, 50]} emptyMessage="Todas as pessoas com acesso" value={this.state.pessoas}>
													<Column style={{width: "2.25em", textAlign: "center"}} header={
														<CheckSquare checked={this.state.pessoas.every(p => p._selecionado)} onChange={this.togglePessoas}/>
													} body={(p, i) => (
														<CheckSquare index={i.rowIndex} name="_selecionado" checked={p._selecionado} onChange={this.handleChangeItemPessoa}/>
													)}/>
													<Column style={{width: "*"}} field="nome" header="Nome"/>
													<Column style={{width: "10em"}} field="cpf" header="CPF"/>
													<Column style={{width: "9em"}} field="rg" header="RG"/>
													<Column style={{width: "9em"}} field="nis" header="NIS"/>
													<Column style={{width: "10em"}} field="passaporte" header="Outro Documento"/>
												</DataTable>
											</div>
										</PanelContent>
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel header={
							<span onClick={this.listarManutencoes}>
								<i style={{fontSize: "13px"}} className={`fa fa-${this.state.isLoading ? "spinner fa-spin" : "circle"}`}/> Registros de Manutenção
							</span>
						}>
							<DataTable
									expandedRows={this.state.expandidos}
									onRowToggle={e => this.setState({expandidos: e.data})}
									rowExpansionTemplate={e => <TabelaManutencao equipamento={e} manutencoes={this.state.manutencoes} onLogDownload={this.baixarLogManutencao}/>}
									value={this.state.equipamentos}
									rows={30}
									paginator
									headerColumnGroup={this.manutencoesHeaderColumnGroup()}
							>
								<Column expander/>
								<Column field="descricao"/>
								<Column body={e => MEquipamento.MapFamilias[e.familia]}/>
								<Column style={{textAlign: "right"}} field="ip"/>
								<Column style={{textAlign: "center"}} body={e => (
									<div className="manutencoes-interromper-container">
										<div className="manutencoes-interromper" onClick={() => this.pausarManutencoes(e)}>
											<img src={e._paused ? iconResume : iconPause} alt="" title={e._paused ? "Continuar Manutenções" : "Suspender Manutenções"}/>
										</div>
										{
											this.state.manutencoes.some(lm => lm.equipamento.id === e.id && lm.status === "PENDENTE") ? (
												<div className="manutencoes-interromper" onClick={() => this.interromperManutencoes(e)}>
													<img src={iconStop} alt="" title="Interromper Manutenções"/>
												</div>
											) : null
										}
									</div>
								)}/>
								<Column style={{textAlign: "center"}} body={e => printPendentes(this.state.manutencoes, e)}/>
								<Column style={{textAlign: "center"}} body={e => printConcluidos(this.state.manutencoes, e)}/>
								<Column style={{textAlign: "center"}} body={e => (
									<div>
										{this.state.manutencoes?.some(lm => lm.equipamento?.id === e.id && lm.status !== "PENDENTE") ? <img title="Excluir Registros de Manutenção" className="ui-action-icon" src={iconTrash} alt="" onClick={() => this.limparLogsManutencao(e)}/> : null}
									</div>
								)}/>
							</DataTable>
						</TabPanel>
					</TabView>
				</Panel>
				<SafetyWebSocket path="/manutencoes" onConnect={this.websocketConnected} onMessage={this.queueManutencao}/>
				<ModalRealm/>
			</div>
		);
	}
}

function printConcluidos(m, e) {
	const sucessos = m.filter(lm => lm.equipamento?.id === e.id && lm.status === "SUCESSO").length;
	const falhas = m.filter(lm => lm.equipamento?.id === e.id && lm.status === "FALHA").length;
	return (
		<div className="manutencao-counter-concluidos-container">
			{
				sucessos ? (
					<div className="manutencao-counter-concluidos success">
						<img alt="" src={iconSuccess} className="manutencao-gear"/> {sucessos}
					</div>
				) : null
			}
			{
				falhas ? (
					<div className="manutencao-counter-concluidos failure">
						<img alt="" src={iconError} className="manutencao-gear"/> {falhas}
					</div>
				) : null
			}
		</div>
	);
}

function printPendentes(m, e) {
	const pendentes = m.filter(lm => lm.equipamento.id === e.id && lm.status === "PENDENTE").length;
	const isStopping = e._paused && m.some(lm => lm.equipamento?.id === e.id && lm.inicio && lm.status === "PENDENTE");
	if (pendentes) {
		return (
			<div className="manutencao-counter-pendencias">
				<img alt="" src={iconDispositivo} style={{filter: "brightness(10)", animationDuration: isStopping ? "4s" : "2s"}} className={`manutencao-gear ${!e._paused || isStopping ? "fa-spin" : ""}`}/> {pendentes}
			</div>
		);
	}
	return null;
}

class TabelaManutencao extends React.Component {

	state = {
		status: ["ADVERTENCIA", "SUCESSO", "FALHA", "PENDENTE"],
		expanded: []
	}

	toggleStatus = status => this.setState({status});

	tableColumns = [
		<Column header="Inclusão" field="registro" body={lm => toDateTimeWithSeconds(lm.registro)} style={{width: "11em", textAlign: "center"}}/>,
		<Column header="Δt em Fila" field="inicio" body={lm => lm.inicio && `${secondsToString(moment(lm.inicio).diff(lm.registro, "seconds"))}"`} style={{width: "5em", textAlign: "center"}}/>,
		<Column header="Início" field="inicio" body={lm => lm.inicio ? toTimeWithSeconds(lm.inicio) : "Aguardando"} style={{width: "7em", textAlign: "center"}}/>,
		<Column header="Δt" field="fim" body={lm => lm.fim && `${moment(lm.fim).diff(lm.inicio, "seconds")}"`} style={{width: "3em", textAlign: "center"}}/>,
		<Column header="Fim" field="fim" body={lm => lm.fim ? toTimeWithSeconds(lm.fim) : lm.inicio ? "Executando" : ""} style={{width: "7em", textAlign: "center"}}/>,
		<Column header="Status" field="status" style={{width: "4em", textAlign: "center"}} body={lm => {
			switch (lm.status) {
				case "SUCESSO":
					return <img alt="" src={iconSuccess} title="Manutenção executada com sucesso" className="manutencao-icon-table"/>;
				case "FALHA":
					return <img alt="" src={iconError} title="Falha na execução da manutenção - expanda esta linha para mais detalhes" className="manutencao-icon-table"/>;
				case "ADVERTENCIA":
					return <img alt="" src={iconWarning} title="Manutenção concluída - veja arquivo de logs para mais detalhes" className="manutencao-icon-table"/>;
				default:
					if (lm.inicio) {
						return <img alt="" src={iconDispositivo} title="Em execução no momento" className="manutencao-gear fa-spin"/>;
					} else {
						return <img alt="" src={iconWaiting} title="Aguardando na fila" className="manutencao-icon-table"/>;
					}
			}
		}}/>,
		<Column header="Comando" field="tipo" style={{width: "12em"}} body={lm => labelLogManutencaoTipo[lm.tipo]}/>,
		<Column header="Pessoa" field="pessoa" body={lm => lm.pessoa?.nome} style={{width: "*"}}/>
	];

	printLogMensagem = lm => {
		return lm.mensagem;
	}

	render() {
		const processando = this.props.manutencoes?.filter(lm => lm.equipamento.id === this.props.equipamento.id && lm.status === "PENDENTE" && lm.inicio && !lm.fim);
		return (
			<div>
				<DataTable rowClassName={() => ({"manutencao-current": true})} header={
					<div style={{textAlign: "center"}}>
						<span style={{display: "inline-block", transform: "translateY(2px)", fontSize: ".9em", fontWeight: "bold"}}>Manutenções para o equipamento {this.props.equipamento.descricao}</span>
						<div className="button-select-container" style={{float: "right"}}>
							<img title="Todos" alt="" src={iconAll} className={`button-select ${this.state.status.length === 4 ? "selected" : ""}`} onClick={() => this.toggleStatus(["ADVERTENCIA", "SUCESSO", "FALHA", "PENDENTE"])}/>
							<img title="Somente Pendentes" alt="" src={iconWaiting} className={`button-select ${this.state.status[0] === "PENDENTE" ? "selected" : ""}`} onClick={() => this.toggleStatus(["PENDENTE"])}/>
							<img title="Somente Sucessos" alt="" src={iconSuccess} className={`button-select ${this.state.status[0] === "SUCESSO" ? "selected" : ""}`} onClick={() => this.toggleStatus(["SUCESSO"])}/>
							<img title="Somente Falhas" alt="" src={iconError} className={`button-select ${this.state.status[0] === "FALHA" ? "selected" : ""}`} onClick={() => this.toggleStatus(["FALHA"])}/>
						</div>
						<div style={{clear: "both"}}/>
					</div>
				} emptyMessage="Nenhuma manutenção em andamendo" value={processando}>
					<Column style={{width: "2.5em"}}/>
					{this.tableColumns}
					<Column style={{width: "4em"}}/>
				</DataTable>
				<DataTable
					emptyMessage="Nenhuma manutenção encontrada"
					rows={20}
					paginator
					expandedRows={this.state.expanded}
					onRowToggle={e => this.setState({expanded: e.data})}
					rowExpansionTemplate={this.printLogMensagem}
					value={this.props.manutencoes?.filter(lm => this.state.status.includes(lm.status) && lm.equipamento?.id === this.props.equipamento?.id)}
				>
					<Column style={{width: "2.5em"}} expander/>
					{this.tableColumns}
					<Column header="Log" style={{width: "4em"}} body={lm => (
						<div>
							{lm.arquivo ? <img alt="" className="ui-action-icon" src={iconeLogs} title="Baixar Log de Manutenção" onClick={() => this.props.onLogDownload(lm.arquivo)} /> : null}
						</div>
					)}/>
				</DataTable>
			</div>
		);
	}

}

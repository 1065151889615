import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MComunicado extends MEntidade {

  static validarParaEnviar(comunicado) {
    const messages = [];
    if (Validator.isEmpty(comunicado.titulo)) messages.push("O título do email é obrigatório");
    if (Validator.isEmpty(comunicado.conteudo)) messages.push("O conteúdo do email é obrigatório");
    if (!comunicado.destinatarios || !comunicado.destinatarios.length) messages.push("Você precisa adicionar pelo menos um destinatário");
    if (comunicado._anexos && comunicado._anexos.length) {
      comunicado._anexos.forEach((anexo) => {
        if (anexo.size > 10 * 1024 * 1024) {
          messages.push(`O anexo ${anexo.name} excede o tamanho limite de arquivo (${Math.round((anexo.size * 10) / (1024 * 1024)) / 10}MB > 10MB)`);
        }
      });
    }
    return messages;
  }

}

import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeRemover from "../../media/icones/remover.png";
import {SelectPessoa} from "../../select/SelectPessoa";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MContratoTrabalho} from "../../utils/models/MContratoTrabalho";
import {MContratoTrabalhoPeriodo} from "../../utils/models/MContratoTrabalhoPeriodo";
import {MMatrizHorario} from "../../utils/models/MMatrizHorario";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {EditarMatrizHorario} from "../MatrizHorario/EditarMatrizHorario";
import {ContratoTrabalhoService} from "../servicos/ContratoTrabalhoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {MatrizHorarioService} from "../servicos/MatrizHorarioService";

export class EditarContratoTrabalho extends React.Component {

	state = {
		contratoTrabalho: Object.assign(MContratoTrabalho.Modelo(), this.props.contratoTrabalho),
		visible: true,
		activeIndex: 0,
		lockSave: false
	};

	contratoTrabalhoService = new ContratoTrabalhoService();
	localidadeService = new LocalidadeService();
	matrizHorarioService = new MatrizHorarioService();
	security = UserData.security("CTB", true);
	securityMatrizHorario = UserData.security("MTH");

	onClose = () => {
		this.setState({visible: false});
	}

	tabChange = (event) => this.setState({activeIndex: event.index});

	salvarContratoTrabalho = () => {
		if (this.security.edit) {
			let contratoTrabalho = MContratoTrabalho.cleanupBeforeSave(Object.assign({}, this.state.contratoTrabalho));
			let messages = MContratoTrabalho.validarParaSalvar(contratoTrabalho);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (contratoTrabalho.fim) {
					contratoTrabalho.status = "ENCERRADO";
				} else {
					contratoTrabalho.status = "ATIVADO";
				}
				this.setState({lockSave: true});
				this.contratoTrabalhoService.salvar(contratoTrabalho).then((contratoTrabalho) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(contratoTrabalho);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let contratoTrabalho = this.state.contratoTrabalho;
		contratoTrabalho[event.name] = event.value;
		this.setState({contratoTrabalho});
	}

	periodoFooter = (
		<div style={{textAlign: "left"}}>
			<Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => this.editarMatrizHorario(null)} />
		</div>
	);

	editarMatrizHorario = (periodo) => {
		if (periodo && periodo.matrizHorario && periodo.matrizHorario.id) {
			this.matrizHorarioService.buscar(periodo.matrizHorario).then((matrizHorario) => {
				ModalRealm.showDialog(<EditarMatrizHorario key={Math.random()} matrizHorario={matrizHorario} onModalClose={(matrizHorario) => {
					let contratoTrabalho = this.state.contratoTrabalho;
					if (contratoTrabalho.periodos == null) contratoTrabalho.periodos = [];
					if (periodo.id) {
						contratoTrabalho.periodos = contratoTrabalho.periodos.filter(p => p.id !== periodo.id);
					} else {
						contratoTrabalho.periodos = contratoTrabalho.periodos.filter(p => p._key !== periodo._key);
					}
					contratoTrabalho.periodos.push({...periodo, matrizHorario});
					this.setState({contratoTrabalho});
				}} />);
			});
		} else {
			ModalRealm.showDialog(<EditarMatrizHorario key={Math.random()} matrizHorario={null} onModalClose={(matrizHorario) => {
				let contratoTrabalho = this.state.contratoTrabalho;
				if (contratoTrabalho.periodos == null) contratoTrabalho.periodos = [];
				contratoTrabalho.periodos.push({...MContratoTrabalhoPeriodo.Modelo(), matrizHorario});
				this.setState({contratoTrabalho});
			}} />);
		}
	}

	listarMatrizesHorario = (event) => {
		this.matrizHorarioService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((matrizesHorario) => {
			if (matrizesHorario.length === 0) {
				this.setState({matrizesHorario: [{descricao: `Criar matriz de horário ${event.query}`, id: -1}]});
			} else {
				this.setState({matrizesHorario});
			}
		});
	}

	adicionarPeriodo = (event) => {
		if (event.value.id === -1) {
			ModalRealm.showDialog(<EditarMatrizHorario matrizHorario={{...MMatrizHorario.Modelo(), descricao: event.value.descricao.replace("Criar matriz de horário ", "")}} onModalClose={(matrizHorario) => {
				let contratoTrabalho = this.state.contratoTrabalho;
				let contratoTrabalhoPeriodo = {...MContratoTrabalhoPeriodo.Modelo(), matrizHorario};
				contratoTrabalho.periodos.push(contratoTrabalhoPeriodo);
				this.setState({contratoTrabalho});
			}} />);
			this.setState({matrizHorario: ""});
		} else {
			let contratoTrabalho = this.state.contratoTrabalho;
			contratoTrabalho.periodos.push({...MContratoTrabalhoPeriodo.Modelo(), matrizHorario: event.value});
			this.setState({contratoTrabalho, matrizHorario: ""});
		}
	}

	detalhesPeriodo = [
		<Column key={0} header="Descrição" style={{width: "*"}} body={(mh) => mh.matrizHorario.descricao} />,
		<Column key={1} header="Início" style={{width: "8em"}} body={(mh) => <DatePicker appendTo={document.body} onChange={(e) => {
			mh.inicio = e.value;
			this.forceUpdate();
		}} value={mh.inicio} />} />,
		<Column key={1} header="Fim" style={{width: "8em"}} body={(mh) => <DatePicker appendTo={document.body} onChange={(e) => {
			mh.fim = e.value;
			this.forceUpdate();
		}} value={mh.fim} />} />,
		<Column key={3} header="Períodos" style={{width: "18em"}} body={(mh) => mh.matrizHorario.periodos.map(p => <div key={p.id}>{p.inicio ? p.inicio.formatTime() : "Folga"}{p.fim ? ` às ${p.fim.formatTime()}` : ""} em {p.dias.join(", ")}</div>)} />,
		<Column key={4} header="Ações" style={{width: "6em", textAlign: "center"}} body={(mh) => {
			return (
				<div>
					{this.securityMatrizHorario.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Matriz de Horário" onClick={(event) => this.editarMatrizHorario(mh)} /> : null}
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Matriz de Horário" onClick={(event) => this.removerMatrizHorario(mh)} /> : null}
				</div>
			);
		}} />,
	];

	removerMatrizHorario = (periodo) => {
		const contratoTrabalho = this.state.contratoTrabalho;
		contratoTrabalho.periodos = contratoTrabalho.periodos.filter(p => p.matrizHorario.id !== periodo.matrizHorario.id);
		this.setState({contratoTrabalho});
	}

	render() {
		return (
			<Dialog modal header="Contrato de Trabalho" visible={this.state.visible} style={{width: "950px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<Combobox grid={3} options={MContratoTrabalho.Status} label="Status" name="status" onChange={this.handleChange} value={this.state.contratoTrabalho.status} />
								<SelectPessoa required label="Contratante" value={this.state.contratoTrabalho.empresa} name="empresa" onChange={this.handleChange} grid={6} />
								<DatePicker appendTo={document.body} label="Início" required value={this.state.contratoTrabalho.inicio} name="inicio" onChange={this.handleChange} grid={3} />
								<SelectPessoaFisica required label="Contratado" value={this.state.contratoTrabalho.funcionario} name="funcionario" onChange={this.handleChange} grid={7} />
								<TextField grid={2} label="Matrícula" value={this.state.contratoTrabalho.matricula} name="matricula" onChange={this.handleChange} />
								<DatePicker appendTo={document.body} label="Fim" value={this.state.contratoTrabalho.fim} name="fim" onChange={this.handleChange} grid={3} />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Matrizes de Horário">
							<PanelContent>
								<SuggestionBox appendTo={document.body} grid={12} label="Matrizes de Horário" field="descricao" completeMethod={this.listarMatrizesHorario} suggestions={this.state.matrizesHorario} onChange={(e) => this.setState({matrizHorario: e.value})} onSelect={this.adicionarPeriodo} value={this.state.matrizHorario} />
								<div className="ui-g-12">
									<DataTable paginator paginatorLeft={this.periodoFooter} emptyMessage="Nenhuma matriz de horário adicionada" rows={10} value={this.state.contratoTrabalho.periodos} children={this.detalhesPeriodo} />
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.contratoTrabalho.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/contratos-trabalho", this.state.contratoTrabalho)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.contratoTrabalho.id)) || this.state.lockSave} onClick={this.salvarContratoTrabalho} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarContratoTrabalho} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MMessengerBot {

	static Modelo() {
		return {
			descricao: "",
			tipo: "TELEGRAM"
		};
	}

	static Tipos = [
		{label: "Telegram", value: "TELEGRAM"},
		{label: "WhatsApp", value: "WHATSAPP"}
	];

	static MapTipos = {
		TELEGRAM: "Telegram",
		WHATSAPP: "WhatsApp"
	};

	static cleanupBeforeSave(lugar) {
		return MEntidade.cleanupBeforeSave(lugar);
	}

	static validarParaSalvar(lugar) {
		let messages = [];
		if (Validator.isEmpty(lugar.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

}

import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import {MPerfil} from "../../utils/models/MPerfil";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {CheckSquare} from "../commons/CheckSquare";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {PerfilService} from "../servicos/PerfilService";
import {If} from "../../utils/If";

const customFlags = process.env.REACT_APP_CUSTOM_FLAGS || "";

export class EditarPerfil extends React.Component {

	fillNulls = (perfil) => {
		if (!perfil) {
			perfil = MPerfil.Modelo();
		} else if (perfil.tipo == null) {
			perfil.tipo = "NORMAL";
		}
		perfil.roles = perfil.roles.sort((a, b) => MPerfil.NameFor(a.nome).localeCompare(MPerfil.NameFor(b.nome)));
		return perfil;
	}

	state = {
		perfil: this.fillNulls(this.props.perfil),
		visible: true,
		tabIndex: 0,
		lockSave: false
	};

	perfilService = new PerfilService();
	security = UserData.security("PRF", true);

	onClose = () => {
		this.setState({visible: false});
	}

	salvarPerfil = () => {
		if (this.security.edit) {
			let perfil = MPerfil.cleanupBeforeSave(Object.assign({}, this.state.perfil));
			let messages = MPerfil.validarParaSalvar(perfil);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.setState({lockSave: true});
				this.perfilService.salvar(this.state.perfil).then((perfil) => {
					this.setState({lockSave: false});
					if (this.props.onModalClose) {
						this.props.onModalClose(perfil);
					}
					this.onClose();
				}).catch(() => this.setState({lockSave: false}));
			}
		}
	}

	handleChange = (event) => {
		let perfil = this.state.perfil;
		perfil[event.name] = event.value;
		this.setState({perfil});
	}

	handleCheck = (nome, property, value) => {
		let perfil = this.state.perfil;
		perfil.roles.filter(r => r.nome === nome).forEach(r => r[property] = !r[property]);
		this.setState({perfil});
	}

	enableRole = (nome) => {
		let perfil = this.state.perfil;
		perfil.roles.filter(r => r.nome === nome).forEach(r => {r.visualizar = r.criar = r.editar = r.excluir = !r.visualizar});
		this.setState({perfil});
	}

	toggleProperty = (property) => {
		let perfil = this.state.perfil;
		perfil.roles.forEach(r => r[property] = !(r[property]));
		this.setState({perfil});
	}

	detalhesPerfil = [
		<Column key={1} header="Descrição" style={{width: "*"}} body={(rowData) => {
			return (
				<div style={{cursor: "pointer"}} onClick={() => {
					let perfil = this.state.perfil;
					perfil.roles.filter(r => !r.nome.startsWith("XX") && r.nome === rowData.nome).forEach(r => {
						r.visualizar = !r.visualizar;
						r.criar = !r.criar;
						r.editar = !r.editar;
						r.remover = !r.remover;
					});
					this.setState({perfil});
				}}>{MPerfil.NameFor(rowData.nome)}</div>
			);
		}} />,
		<Column key={2} header={<span style={{fontSize: "12px", cursor: "pointer"}} onClick={() => this.toggleProperty("visualizar")}>Menu</span>} style={{width: "3.5em"}} body={(rowData) => {
			return <div key={1} style={{textAlign: "center", height: "24px"}}><CheckSquare onChange={(e) => this.handleCheck(rowData.nome, "visualizar", e.value)} checked={rowData.visualizar} /></div>;
		}} />,
		<Column key={3} header={<span style={{fontSize: "12px", cursor: "pointer"}} onClick={() => this.toggleProperty("criar")}>Criar</span>} style={{width: "3.5em"}} body={(rowData) => {
			return <div key={2} style={{textAlign: "center", height: "24px"}}><CheckSquare onChange={(e) => this.handleCheck(rowData.nome, "criar", e.value)} checked={rowData.criar} /></div>;
		}} />,
		<Column key={4} header={<span style={{fontSize: "12px", cursor: "pointer"}} onClick={() => this.toggleProperty("editar")}>Editar</span>} style={{width: "3.5em"}} body={(rowData) => {
			return <div key={3} style={{textAlign: "center", height: "24px"}}><CheckSquare onChange={(e) => this.handleCheck(rowData.nome, "editar", e.value)} checked={rowData.editar} /></div>;
		}} />,
		<Column key={5} header={<span style={{fontSize: "12px", cursor: "pointer"}} onClick={() => this.toggleProperty("remover")}>Excluir</span>} style={{width: "3.5em"}} body={(rowData) => {
			return <div key={4} style={{textAlign: "center", height: "24px"}}><CheckSquare onChange={(e) => this.handleCheck(rowData.nome, "remover", e.value)} checked={rowData.remover} /></div>;
		}} />
	];

	tabChange = (event) => this.setState({tabIndex: event.index});

	render() {
		const roles = this.state.perfil.roles.filter(r => !r.nome.startsWith("XX"));
		return (
			<Dialog modal header="Perfil" visible={this.state.visible} style={{width: "1100px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12" activeIndex={this.state.tabIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados Principais">
							<PanelContent>
								<TextField grid={12} label="Descrição" required name="descricao" onChange={this.handleChange} value={this.state.perfil.descricao} />
								<div className="ui-g-12">
									<Label>Permissões</Label>
									<div className="ui-g">
										<div className="ui-g-4 ui-g-nopad">
											<DataTable value={roles.filter((role, index) => index % 3 === 0)} children={this.detalhesPerfil} />
										</div>
										<div className="ui-g-4 ui-g-nopad">
											<DataTable style={{marginLeft: "-2px"}} value={roles.filter((role, index) => index % 3 === 1)} children={this.detalhesPerfil} />
										</div>
										<div className="ui-g-4 ui-g-nopad">
											<DataTable style={{marginLeft: "-2px"}} value={roles.filter((role, index) => index % 3 === 2)} children={this.detalhesPerfil} />
										</div>
									</div>
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Relatórios">
							<PanelContent>
								<div className="ui-g-12">
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXA" && r.visualizar)} onChange={event => this.handleCheckXX("A", event)} name="visualizar" label="101 - Relatório de Credenciais Não Devolvidas"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXA" && r.criar)} onChange={event => this.handleCheckXX("A", event)} name="criar" label="102 - Relatório de Presença por Lugar"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXA" && r.editar)} onChange={event => this.handleCheckXX("A", event)} name="editar" label="103 - Relatório de Acessos Ativos por Unidade"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXA" && r.remover)} onChange={event => this.handleCheckXX("A", event)} name="remover" label="104 - Relatório de Detalhamento de Acesso"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXB" && r.visualizar)} onChange={event => this.handleCheckXX("B", event)} name="visualizar" label="105 - Relatório de Atividade de Acesso por Pessoa"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXB" && r.criar)} onChange={event => this.handleCheckXX("B", event)} name="criar" label="106 - Relatório de Ocorrências de Segurança"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXB" && r.editar)} onChange={event => this.handleCheckXX("B", event)} name="editar" label="107 - Relatório de Reservas de Espaço"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXB" && r.remover)} onChange={event => this.handleCheckXX("B", event)} name="remover" label="108 - Extrato de Movimentação de Conta de Crédito"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXC" && r.visualizar)} onChange={event => this.handleCheckXX("C", event)} name="visualizar" label="109 - Relatório de Permissões de Acesso por Equipamento"/>
									<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXC" && r.criar)} onChange={event => this.handleCheckXX("C", event)} name="criar" label="110 - Relatório de Documentos a Expirar"/>
								</div>
								<If condition={customFlags.split(",").includes("CACIQUE")}>
									<div className="ui-g-12">
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXV" && r.visualizar)} onChange={event => this.handleCheckXX("V", event)} name="visualizar" label="151 - Relatório de Refeições de Visitantes por Centro de Custo"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXV" && r.criar)} onChange={event => this.handleCheckXX("V", event)} name="criar" label="152 - Relatório de Refeições de Terceiros por Empresa"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXV" && r.editar)} onChange={event => this.handleCheckXX("V", event)} name="editar" label="153 - Relatório de Refeições por Período"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXV" && r.remover)} onChange={event => this.handleCheckXX("V", event)} name="remover" label="154 - Relatório de Refeições de Terceiros"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXW" && r.visualizar)} onChange={event => this.handleCheckXX("W", event)} name="visualizar" label="155 - Relatório de Refeições de Terceiros por Período"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXW" && r.criar)} onChange={event => this.handleCheckXX("W", event)} name="criar" label="156 - Relatório de Detalhamento de Refeições de Visitantes por Centro de Custo"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XXW" && r.editar)} onChange={event => this.handleCheckXX("W", event)} name="editar" label="157 - Relatório de Plano de Evacuação"/>
									</div>
								</If>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Opções Adicionais">
							<PanelContent>
								<TextField grid={2} type="numeric" value={this.state.perfil.reservaDiasAntecedencia} name="reservaDiasAntecedencia" onChange={this.handleChange} label="Máximo de Antecedência" />
								<TextField grid={2} type="numeric" value={this.state.perfil.reservaQuantidadeMaxima} name="reservaQuantidadeMaxima" onChange={this.handleChange} label="Máximo de Reservas Ativas" />
								<TextField grid={8} value={this.state.perfil.paginaInicial} name="paginaInicial" onChange={this.handleChange} label="Página Inicial" />
								<div className="ui-g-12">
									<div className="ui-g-3 ui-g-nopad">
										<Label>Portaria</Label>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX0" && r.visualizar)} onChange={event => this.handleCheckXX("0", event)} name="visualizar" label="Modo de emergência"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX1" && r.criar)} onChange={event => this.handleCheckXX("1", event)} name="criar" label="Acesos adicionais"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX1" && r.editar)} onChange={event => this.handleCheckXX("1", event)} name="editar" label="Buscar veículo automaticamente"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX0" && r.remover)} onChange={event => this.handleCheckXX("0", event)} name="remover" label="Habilitar impressão de etiquetas"/>
									</div>
									<div className="ui-g-3 ui-g-nopad">
										<Label>Agenda</Label>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX0" && r.editar)} onChange={event => this.handleCheckXX("0", event)} name="editar" label="Autorizações de acesso"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX1" && r.remover)} onChange={event => this.handleCheckXX("1", event)} name="remover" label="Validar Empresa da Agenda"/>
									</div>
									<div className="ui-g-3 ui-g-nopad">
										<Label>Geral</Label>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX0" && r.criar)} onChange={event => this.handleCheckXX("0", event)} name="criar" label="Chave de integração"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX1" && r.visualizar)} onChange={event => this.handleCheckXX("1", event)} name="visualizar" label="Painel de Controle de Acesso"/>
										<CheckSquare style={{marginTop: "-4px"}} checked={this.state.perfil.roles.some(r => r.nome === "XX2" && r.visualizar)} onChange={event => this.handleCheckXX("2", event)} name="visualizar" label="Portal do Condômino"/>
									</div>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.perfil.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/perfis", this.state.perfil)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.perfil.id)) || this.state.lockSave} onClick={this.salvarPerfil} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarPerfil} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

	handleCheckXX = (prefix, event) => {
		const {perfil} = this.state;
		perfil.roles.filter(r => r.nome === "XX" + prefix).forEach(r => {
			r[event.name] = !r[event.name];
		});
		this.setState({perfil});
	}

}

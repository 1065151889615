import React from "react";

export class PanelContent extends React.Component {

	render() {
		return (
			<div {...this.props} className={`ui-g ${this.props.className || ""}`} />
		);
	}

}

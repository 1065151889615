import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MBotoeira} from "../../utils/models/MEquipamento";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";

export class EditarBotoeira extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			botoeira: this.fillNulls(props.botoeira),
			visible: true
		};
	}

	fillNulls = (botoeira) => {
		if (!botoeira) {
			botoeira = MBotoeira.Modelo();
		}
		return botoeira;
	}

	onClose = () => {
		this.setState({visible: false});
	}

	salvarBotoeira = () => {
		let botoeira = this.state.botoeira;
		Object.keys(botoeira).forEach((property) => (botoeira[property] === "") && delete botoeira[property]);
		if (this.props.onModalClose) {
			this.props.onModalClose(this.state.botoeira);
		}
		this.onClose();
	}

	handleChange = (event) => {
		let botoeira = this.state.botoeira;
		botoeira[event.name] = event.value;
		this.setState({botoeira});
	}

	render() {
		return (
			<Dialog modal header="GPIO" visible={this.state.visible} style={{width: "450px"}} onHide={this.onClose}>
				<DialogContent>
					<TextField grid={3} label="Porta" name="porta" value={this.state.botoeira.porta} type="number" onChange={this.handleChange}/>
					<TextField grid={3} label="Valor" name="valor" value={this.state.botoeira.valor} type="number" onChange={this.handleChange}/>
					<Combobox label="Finalidade" grid={6} appendTo={document.body} options={MBotoeira.Finalidades} name="finalidade" onChange={this.handleChange} value={this.state.botoeira.finalidade}/>
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.salvarBotoeira} icon="fa-save"/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarBotoeira} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

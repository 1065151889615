import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import {MTexto} from "../../utils/models/MTexto";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";

export class EditarTexto extends React.Component {

	state = {
		texto: Object.assign(MTexto.Modelo(), this.props.texto),
		visible: true
	};

	onClose = () => {
		this.setState({visible: false});
	}

	salvarTexto = () => {
		if (this.props.onModalClose) {
			this.props.onModalClose(this.state.texto);
		}
		this.onClose();
	}

	handleChange = (event) => {
		const {texto} = this.state;
		texto[event.name] = event.value;
		this.setState({texto});
	}

	render() {
		return (
			<Dialog modal header="Texto" visible={this.state.visible} style={{width: "500px"}} onHide={this.onClose}>
				<DialogContent>
					<Combobox grid={12} label="Finalidade" value={this.state.texto.finalidade} onChange={this.handleChange} name="finalidade" options={MTexto.MFinalidade} />
					<TextField grid={12} label="Texto" value={this.state.texto.texto} onChange={this.handleChange} name="texto" />
				</DialogContent>
				<DialogFooter>
					<Action mode="post" label="Salvar" onClick={this.salvarTexto} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarTexto} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

import {httpGet} from "../../utils/Request";

export class GraficoService {

	acessosPorHora(dias = 1) {
		return httpGet(`/graficos/acessos-por-hora/${dias}`);
	}

	acessosPorDia(dias = 30) {
		return httpGet(`/graficos/acessos-por-dia/${dias}`);
	}

	atividadeMensal(dias = 30) {
		return httpGet(`/graficos/atividade-mensal/${dias}`);
	}

	acessosNegados(dias = 30) {
		return httpGet(`/graficos/acessos-negados/${dias}`);
	}

	pessoas(dias = 30) {
		return httpGet(`/graficos/pessoas/${dias}`);
	}

}

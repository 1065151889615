import React, {Component} from "react";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Combobox} from "../commons/Combobox";
import handImage from "../../media/hand.jpg";
import "./ColetarDigital.css";
import {ModalRealm} from "../commons/ModalRealm";
import {uuidv4} from "../../utils/UUIDUtils";
import classNames from "classnames";

export class ColetarDigital extends Component {

	state = {
		visible: true,
		leitores: [],
		message: {
			status: "NENHUM",
			texto: "Selecione o leitor e inicie a coleta"
		},
		coleta: null,
		credencial: null
	};

	familias = {
		"NEOKOROS": "Neokoros",
		"FUTRONIC": "Futronic",
		"CONTROLID": "ControliD"
	};

	headers = {
		"Content-Type": "application/json"
	};

	componentDidMount() {
		fetch("http://localhost:7277/leitores").then(response => {
			if (response.status === 200) {
				response.json().then(leitores => {
					if (leitores.length) {
						const message = {
							status: "NENHUM",
							texto: leitores.length === 1 ? "Clique no dedo que deseja coletar" : "Selecione o leitor e clique no dedo que deseja coletar"
						}
						this.setState({
							message,
							leitor: leitores.length === 1 ? leitores[0] : null,
							leitores: leitores.map(l => ({label: `${this.familias[l.familia]} ${l.identificador}`, value: l}))
						});
					} else {
						ModalRealm.showInformacao("warn", ["Nenhum leitor detectado. Verifique a conexão e a instalação dos drivers necessários."]);
						this.onClose();
					}
				});
			}
		}).catch(error => {
			console.error(error);
			ModalRealm.showInformacao("warn", ["O serviço de coleta de digitais não está instalado no seu computador."]);
		});
	}

	onClose = () => this.setState({visible: false});

	handleChange = event => this.setState({[event.name]: event.value});

	coletarDigital = auxiliar => {
		if (!this.state.leitor) {
			ModalRealm.showInformacao("warn", ["Nenhum leitor de digitais selecionado"]);
			return;
		}
		const coleta = {
			id: uuidv4(),
			codigo: this.props.pessoa.id,
			auxiliar,
			leitor: this.state.leitor
		};
		fetch("http://localhost:7277/leitores/coletar", {method: "POST", headers: this.headers, body: JSON.stringify(coleta)}).then(response => {
			response.json().then(message => {
				if (message.status === "SUCESSO") {
					this.setState({coleta, message: {status: "PENDENTE", texto: "Posicione o dedo a ser coletado no sensor"}});
				} else {
					ModalRealm.showInformacao("error", [message.texto])
				}
			});
		});
	}

	cancelarColeta = () => {
		fetch("http://localhost:7277/leitores/cancelar", {method: "POST", headers: this.headers, body: JSON.stringify(this.state.coleta)}).then(response => {
			if (response.status === 200) {
				const message = {
					status: "NENHUM",
					texto: "Clique no dedo que deseja coletar"
				};
				this.setState({coleta: null, message});
			}
		});
	}

	dedoClass = index => {
		return classNames({
			"botao-dedo": true,
			[`d${index}`]: true,
			"active": this.state.coleta?.auxiliar === index,
			"exists": this.state.credencial?.itens?.some(ci => ci.numero === index),
			"success": this.state.credencial?.itens?.some(ci => ci._success)
		});
	}

	render() {
		return (
			<Dialog key={this.props.key} closable={false} modal header={`Coleta de digital para ${this.props.pessoa.nome}`} visible={this.state.visible} style={{width: "850px"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-8 ui-g-nopad">
						<div className="ui-g">
							<Combobox disabled={this.state.coleta} grid={8} label="Leitor" name="leitor" options={this.state.leitores} value={this.state.leitor} onChange={this.handleChange}/>
							<div className="ui-g-4" style={{textAlign: "center"}}>
								<Action label="Cancelar" disabled={!this.state.coleta} onClick={this.cancelarColeta}/>
							</div>
							<div className="ui-g-12" style={{position: "relative", textAlign: "center"}}>
								<img src={handImage} alt=""/>
								<img src={handImage} style={{transform: "scaleX(-1)"}} alt=""/>
								<div className="hand-name left">Mão Esquerda</div>
								<div className="hand-name right">Mão Direita</div>
								<div className={this.dedoClass(0)} onClick={() => this.coletarDigital(0)}/>
								<div className={this.dedoClass(1)} onClick={() => this.coletarDigital(1)}/>
								<div className={this.dedoClass(2)} onClick={() => this.coletarDigital(2)}/>
								<div className={this.dedoClass(3)} onClick={() => this.coletarDigital(3)}/>
								<div className={this.dedoClass(4)} onClick={() => this.coletarDigital(4)}/>
								<div className={this.dedoClass(5)} onClick={() => this.coletarDigital(5)}/>
								<div className={this.dedoClass(6)} onClick={() => this.coletarDigital(6)}/>
								<div className={this.dedoClass(7)} onClick={() => this.coletarDigital(7)}/>
								<div className={this.dedoClass(8)} onClick={() => this.coletarDigital(8)}/>
								<div className={this.dedoClass(9)} onClick={() => this.coletarDigital(9)}/>
								<div className={`botao-dedo-mensagem ${this.state.message.status}`}>{this.state.message.texto}</div>
							</div>
						</div>
					</div>
					<div className="ui-g-4 ui-g-nopad">
						<div/>
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}

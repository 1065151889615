import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {GUI} from "../../utils/Constants";
import {MAnimal} from "../../utils/models/MAnimal";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Combobox} from "../commons/Combobox";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {AnimalService} from "../servicos/AnimalService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EditarAnimal} from "./EditarAnimal";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";

export class Animal extends React.Component {

	state = {
		animais: [],
		nome: "",
		raca: "",
		cor: "",
		especie: null,
		firstResult: 0
	};

	animalService = new AnimalService();
	security = UserData.security("ANI", true);

	editarAnimal = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.animalService.buscar(rowData).then((animal) => {
				ModalRealm.showDialog(<EditarAnimal key={Math.random()} animal={animal} onModalClose={this.listarAnimais} />);
			});
		} else {
			ModalRealm.showDialog(<EditarAnimal key={Math.random()} animal={null} onModalClose={this.listarAnimais} />);
		}
	}

	restaurarAnimal = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar o registro este animal?", () => {
			if (rowData != null && rowData.id != null) {
				this.animalService.buscar(rowData).then((animal) => {
					animal.status = "ATIVADO";
					this.animalService.salvar(animal).then(this.listarAnimais);
				});
			}
		});
	}

	eliminarAnimal = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este animal?", () => this.animalService.eliminar(rowData).then((result) => {
			if (result) this.listarAnimais(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar o registro este animal: há outros registros dependentes dele no sistema."]);
		}));
	}

	columns = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key="foto" header="Foto" style={{width: "88px", textAlign: "center"}} body={(animal) => {
			if (animal.fotos) {
				let identificacoes = animal.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc);
				if (identificacoes.length) {
					return <img className="logs-acesso-v2-foto" style={{float: "none", verticalAlign: "top"}} src={toSrc(identificacoes[0], true)} alt=""/>;
				}
			}
			return <span className="logs-acesso-v2-foto sem-foto"/>;
		}} />,
		<Column key="dados" header="Dados" style={{width: "15em"}} body={(animal) => {
			return (
				<div>
					<div>{animal.nome}</div>
					<div>{animal.raca}</div>
					<div>{animal.cor}</div>
				</div>
			);
		}} />,
		<Column key="especie" header="Espécie" style={{width: "10em"}} body={(animal) => MAnimal.MapEspecies[animal.especie]} />,
		<Column key="etiquetas" header="Etiquetas" style={{textAlign: "center", width: "15em"}} body={(animal) => animal.etiquetas ? animal.etiquetas.map(e => <EtiquetaChip etiqueta={e} />) : null} />,
		<Column key="responsavel" header="Responsável" style={{width: "*"}} body={(animal) => {
			const telefones = [];
			if (animal.responsavel) {
				if (animal.responsavel.telefone) telefones.push(animal.responsavel.telefone);
				if (animal.responsavel.celular) telefones.push(animal.responsavel.celular);
			}
			return animal.responsavel ? <div><div>{animal.responsavel.nome}</div><div>{telefones.length ? telefones.join(", ") : ""}</div></div> : "";
		}} />,
		<Column key="acoes" field="acoes" header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Animal" onClick={(event) => this.editarAnimal(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Animal" onClick={(event) => this.excluirAnimal(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Animal" onClick={(event) => this.restaurarAnimal(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Animal" onClick={(event) => this.eliminarAnimal(rowData)} /> : null}
				</div>
			);
		}} />
	];

	listarAnimais = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		const params = [];
		if (this.state.nome) params.push(`nome~${this.state.nome}`);
		if (this.state.raca) params.push(`raca~${this.state.raca}`);
		if (this.state.cor) params.push(`cor~${this.state.cor}`);
		if (this.state.especie) params.push(`especie:${this.state.especie}`);
		this.queryTimeout = setTimeout(() => {
			this.animalService.listar(`search=${params.join(";")}${this.state.excluidos ? "" : ";status:ATIVADO"}`, {page: 0, size: GUI.maxResults}).then((animais) => this.setState({animais, firstResult: 0}));
		}, 50);
	}

	excluirAnimal = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este animal?", () => this.animalService.excluir(rowData).then(() => this.listarAnimais()));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/animais", entidade);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Animais de Estimação">
					<PanelContent className="ui-g">
						<TextField grid={3} label="Nome" autofocus name="nome" value={this.state.nome} onChange={this.handleUpdate} />
						<TextField grid={3} label="Raça" name="raca" value={this.state.raca} onChange={this.handleUpdate} />
						<TextField grid={3} label="Cor" name="cor" value={this.state.cor} onChange={this.handleUpdate} />
						<Combobox options={[{label: "Todos", value: null}, ...MAnimal.Especies]} grid={3} label="Espécie" name="especie" value={this.state.especie} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarAnimais} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<div>
					<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.animais} paginator rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} children={this.columns} />
					<ActionFloat>
						{this.security.create ? <ActionFloat.Button mode="post" title="Novo Animal de Estimação" icon="fa-plus" onClick={this.editarAnimal} /> : null}
					</ActionFloat>
					<Shortcut
						onEnter={this.listarAnimais}
						onCtrlN={() => window.location.pathname === "/animais" && this.security.create && this.editarAnimal()}
						onCtrlE={() => window.location.pathname === "/animais" && this.state.animais.length === 1 && this.security.edit && this.editarAnimal(this.state.animais[0])}
					/>
					<ModalRealm/>
				</div>
			</div>
		);
	}

}

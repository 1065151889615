export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
export const DAYS_OF_WEEKS = [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ];
export const DAYS_OF_WEEKS_TRANSLATION = {
	'SUNDAY': 'Domingo',
	'MONDAY': 'Segunda-feira',
	'TUESDAY': 'Terça-feira',
	'WEDNESDAY': 'Quarta-feira',
	'THURSDAY': 'Quinta-feira',
	'FRIDAY': 'Sexta-feira',
	'SATURDAY': 'Sábado'
};
export const DAYS_OF_WEEKS_ABREV = { 'SUNDAY': 'Dom', 'MONDAY': 'Seg', 'TUESDAY': 'Ter', 'WEDNESDAY': 'Qua', 'THURSDAY': 'Qui', 'FRIDAY': 'Sex', 'SATURDAY': 'Sáb' };
export const SIZE = 10;

export const PERMISSION_VIEW = 'VISUALIZAR';
export const PERMISSION_EDIT = 'EDITAR';
export const PERMISSION_REMOVE = 'REMOVER';
export const PERMISSION_CREATE = 'CRIAR';

export const STATUS = ['ATIVADO', 'PENDENTE', 'EXPIRADO', 'BLOQUEADO', 'CORROMPIDO', 'CONCLUIDO'];

export const PERIODO = ['DAY', 'WEEK', 'MONTH', 'BIMESTER', 'SEMESTER'];

export const COLORS = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'];

export class GUI {

	static maxSuggestion = 10;

	static maxResults = 100;

	static defaultRows = 25;

	static rowsPerPage = [10, 25, 50, 100];

}

import React from "react";

export class HelpUnidade extends React.Component {

	render() {
		return (
			<div>
                <p>Unidade é o mecanismo utilizado pelo Safety para determinar uma propriedade de um lugar, separando em blocos e vinculando os cadastros de agendas e pessoas relacionadas ao local.</p>
				<p>Esta tela permite realizar a criação, a edição e a consulta de uma unidade que permitem vincular agendas e pessoas.</p>
				<h1>Como utilizar a unidade?</h1>
				<p>
					Cada unidade é separada em grupo(bloco ou pavimento) que independente da quantidade de unidades no interior, é possível encontrá-las em um padrão de ordem alfabética ou numérica.
				</p>
				<h2>Filtros de Busca</h2>
				<ul>
				Diferente de outras telas do sistema, os nomes de grupos se encontram de início no quadro 'Geral', bastando clicar em um deles, para filtrar suas Unidades.</ul>
				<h2>Como criar ou alterar uma unidade?</h2>
                <li>
				<em>1</em>. Para criar uma nova unidade em um grupo, basta clicar no campo de '+' do lado do nome do grupo. Caso queira editar uma unidade existente, clique na unidade específica. Em ambos os casos, irá abrir uma tela menor.
                Nessa tela será possível inserir o contato principal da unidade, e trocar suas opções. Também na janela de Unidades é possível criar ou alterar agendas de unidades, para isto basta selecionar a guia de qual tipo de agenda será criada para a unidade, e clicar no botão "+".
				</li>
                <li>
				<em>2</em>. Será aberta a janela de Agenda já incluindo nas etiquetas a unidade e a categoria de acesso, caso necessário complete com a validade e veículo, adicione etiquetas como de outras unidades se for preciso. 
				</li>
                <li>
				<em>3</em>. Na aba de pessoas inclua ou altere quais pessoas serão vinculadas a essa agenda.
				</li>
                <li>
				<em>4</em>. Na aba Modelos de Acesso inclua os modelos necessários, e clique em salvar.
				</li>
			</div>
		);
	}
}
